import axios, { AxiosResponse, responseEncoding } from 'axios';
import { apiGet, apiPost, getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import { GET_CLEARANCE_DATA, GET_CLEARANCE_ACTION, POST_ADD_CLEARANCE_LEVEL, POST_DELETE_CLEARANCE_LEVEL, POST_UPDATE_CLEARANCE_LEVEL,
GET_ALL_CLEARANCE_LEVELS, GET_FILTERED_CLEARANCE_LEVELS } from './levelAPIPath';
import usermgtStore from '../usermgtStore';
import { levelSliceActions } from '../levelSlice';
import { usermgtSliceActions } from '../usermgtSlice';
import { toast } from "react-toastify";
import { ConfirmationToastContentStore } from '../../../Container/ConfirmationToastContentStore';
import { ClearanceData, DeleteClearanceLevels, Action } from 'userful-chronos-app-common-js/dist/models/usermgt/clearanceLevel';


function formatName(inputName) {
    const formattedName = inputName.split('_');
    const secondName = formattedName[1].charAt(0).toUpperCase() + formattedName[1].slice(1)
    const joinedWord = `${formattedName[0]} ${secondName}`
    return joinedWord;
}

const errorHandler = (err) => {
    console.debug("Error calling init app api ");
    console.debug(err);
}

const getError = (response: any) => {
    return ({
        success: false,
        arg: response.data.arg ? response.data.arg : "",
        errorCode: response.data.errorCode ? response.data.errorCode : "",
        moduleID: response.data.moduleID ? response.data.moduleID : "",
        severity: response.data.severity ? response.data.severity : '',
        msg: response.data.unlocalizedMsg ? response.data.unlocalizedMsg : ""
    }
    );
}


export const checkReponseIsValid = (name: string, response: AxiosResponse): boolean => {
    if (response.status >= 400) {
        console.log(`${name} error: ${response.status}`);
        return false;
    }
    if (!response.data || response.data.severity !== "SUCCESS") {
        console.log(`${name} empty response`);
        return false;
    }
    return true;
}

// all clearance levels from server
export const getClearanceLevelsFromServerThatUserHasAccess = (token: string) => {

    const t1 = getGlobalStates().host + GET_ALL_CLEARANCE_LEVELS;

    apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            return;
        }
        // console.debug(`list all clearance levels`, response.data.arg);
        usermgtStore.dispatch(levelSliceActions.setAllClearanceLevels(response.data.arg));
    }).catch(() => console.warn("failed"));
}

// only those clearance levels that user has access to
export const getClearanceLevelsFromServer = (token: string) => {

    const t1 = getGlobalStates().host + GET_CLEARANCE_DATA;

    apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            return;
        }
        // console.debug(`receive authorize`, response.data);
        usermgtStore.dispatch(levelSliceActions.setClearanceLevelsData(response.data.arg));
        usermgtStore.dispatch(usermgtSliceActions.setInitBool(true));
    }).catch(() => console.warn("failed"));
}

// this gets the clearance levels based on user has access to the apps and then those apps are part of which group 
export const getFilteredClearanceLevelsFromServer = (token: string) => {

    const t1 = getGlobalStates().host + GET_FILTERED_CLEARANCE_LEVELS;

    apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            return;
        }
        // console.debug(`receive authorize`, response.data);
        usermgtStore.dispatch(levelSliceActions.recieveFilteredClearanceLevelsData(response.data.arg));
    }).catch(() => console.warn("failed"));
}

export const getClearanceActionsFromServer = (token: string) => {

    const t1 = getGlobalStates().host + GET_CLEARANCE_ACTION;

    apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            return;
        }
        console.debug(`receive authorize`, response.data);
        usermgtStore.dispatch(levelSliceActions.recieveClearanceActionsData(response.data.arg));
        usermgtStore.dispatch(usermgtSliceActions.setInitBool(true));
    }).catch(() => console.warn("failed"));
}

export const addLevel = (levelData: ClearanceData[], token: string) => {

    const t1 = getGlobalStates().host + POST_ADD_CLEARANCE_LEVEL;

    apiPost(t1, levelData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(levelSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(levelSliceActions.setClearanceLevelsData(response.data.arg));
            usermgtStore.dispatch(levelSliceActions.altLoadState("SUCCESS"));
            const customDisplay_edit = ConfirmationToastContentStore(formatName(levelData[0].clearanceLevel.name), "Save");
            const customDisplay_execute =  ConfirmationToastContentStore(formatName(levelData[1].clearanceLevel.name), "Save");
            toast(customDisplay_edit, { containerId: 'confirmationContainer' });
            toast(customDisplay_execute, { containerId: 'confirmationContainer' });

        }).catch((err) => {
            usermgtStore.dispatch(levelSliceActions.altLoadState("FAILED"));
            console.warn("failed to add level");
        });

}

export const editLevel = (levelData: ClearanceData, token: string) => {

    const t1 = getGlobalStates().host + POST_UPDATE_CLEARANCE_LEVEL;

    apiPost(t1, levelData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(levelSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(levelSliceActions.setClearanceLevelsData(response.data.arg));
            usermgtStore.dispatch(levelSliceActions.altLoadState("SUCCESS"));
            const customDisplay = ConfirmationToastContentStore(formatName(levelData.clearanceLevel.name), "Edit");
            toast(customDisplay, { containerId: 'confirmationContainer' });
        }).catch(() => {
            console.warn("failed to edit level");
            usermgtStore.dispatch(levelSliceActions.altLoadState("FAILED"));
        });

}


export const deleteLevel = (levelData: DeleteClearanceLevels, token: string) => {

    const t1 = getGlobalStates().host + POST_DELETE_CLEARANCE_LEVEL;

    apiPost(t1, levelData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(levelSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(levelSliceActions.setClearanceLevelsData(response.data.arg));
            usermgtStore.dispatch(levelSliceActions.altLoadState("SUCCESS"));
        }).catch(() => console.warn("failed to delete level"));

}