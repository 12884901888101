"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appIDMatches = void 0;
var _SAMMsgDefinations = require("../../../message/messageTypeDefinitions/sam/SAMMsgDefinations");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _sam = require("../../functions/sam");
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var appIDMatches = exports.appIDMatches = function appIDMatches(appIDs, data) {
  if (!data.orgData.appIDs.valid) {
    return true;
  }
  var _iterator = _createForOfIteratorHelper(data.orgData.appIDs.appIDs),
    _step;
  try {
    var _loop = function _loop() {
        var appID = _step.value;
        if (appIDs.findIndex(function (item) {
          return item.value === appID.value;
        }) >= 0) {
          return {
            v: true
          };
        }
      },
      _ret;
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      _ret = _loop();
      if (_ret) return _ret.v;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return false;
};
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_LIST_SOURCE_SPECS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_LIST_SOURCE_SPECS, (0, _sam.getStandaloneSourceSpecs)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_QUERY_SOURCE_ASSETS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_QUERY_SOURCE_ASSETS, (0, _sam.getStandaloneAssets)().filter(function (data) {
    return appIDMatches(payload.appIDsQuery.appIDs, data);
  }));
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_LIST_SOURCE_ASSETS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_LIST_SOURCE_ASSETS, (0, _sam.getStandaloneAssets)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_QUERY_SOURCE_DEFS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_QUERY_SOURCE_DEFS, (0, _sam.getStandaloneSources)().filter(function (data) {
    return appIDMatches(payload.appIDsQuery.appIDs, data);
  }));
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_LIST_SOURCE_DEFS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_LIST_SOURCE_DEFS, (0, _sam.getStandaloneSources)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_CREATE_SOURCE_DEF, function (payload) {
  var source = (0, _sam.addSource)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.NOTIFY_ADD_SOURCE_DEF, source);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_UPDATE_SOURCE_DEF, function (payload) {
  var source = (0, _sam.updateSource)(payload);
  var response = {
    itemID: source.id.value,
    usageData: []
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_UPDATE_SOURCE_DEF, response);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.NOTIFY_UPDATE_SOURCE_DEF, source);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_FORCE_UPDATE_SOURCE_DEF, function (payload) {
  var source = (0, _sam.updateSource)(payload);
  var response = {
    itemID: source.id.value,
    usageData: []
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_UPDATE_SOURCE_DEF, response);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.NOTIFY_UPDATE_SOURCE_DEF, source);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_REMOVE_SOURCE_DEF, function (payload) {
  var source = (0, _sam.removeSource)(payload);
  var response = {
    itemID: source.id.value,
    usageData: []
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_REMOVE_SOURCE_DEF, response);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.NOTIFY_REMOVE_SOURCE_DEF, source);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_FORCE_REMOVE_SOURCE_DEF, function (payload) {
  var source = (0, _sam.removeSource)(payload);
  var response = {
    itemID: source.id.value,
    usageData: []
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_REMOVE_SOURCE_DEF, response);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.NOTIFY_REMOVE_SOURCE_DEF, source);
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/sam/upload', function (method, data) {
  if (method === 'post') {
    (0, _StandaloneMsgHandler.respondFeatureNotAvailable)();
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)(null, "Not available")
    };
  }
});