import React from "react";
import { CdmVideoWallDest, CdmVideoWallDestDisplay } from "userful-chronos-app-common-js/dist/models/cdm/CdmVideoWall";
import { GraphicPointPct, GraphicSizePct } from "userful-chronos-app-common-js/dist/models/common";
import { prettyPrintStationLabel } from "userful-chronos-app-common-js/dist/models/display";
import "./VideoWallWidget.scss";
import { useTranslation } from "react-i18next";

interface IProps {
     videoWall: CdmVideoWallDest;
     className?: string;
     maxWidth?: number; // parent card width minus padding
     maxHeight?: number; // parent card height minus padding
}

const DEFAULT_WIDGET_WIDTH = 75;
const DEFAULT_WIDGET_HEIGHT = 42;

const getXTranslate = (centerPosition: GraphicPointPct) => centerPosition.x - 50;
const getYTranslate = (centerPosition: GraphicPointPct) => centerPosition.y - 50;
const getTranslate = (display: CdmVideoWallDestDisplay) =>
     `translate3d(${getXTranslate(display.positionInfo.center)}%, ${getYTranslate(display.positionInfo.center)}%, 0)`;
const getXScale = (size: GraphicSizePct) => size.width / 100;
const getYScale = (size: GraphicSizePct) => size.height / 100;
const getScale = (display: CdmVideoWallDestDisplay) =>
     `scale3d(${getXScale(display.positionInfo.size)}, ${getYScale(display.positionInfo.size)}, 1)`;
const getRotate = (display: CdmVideoWallDestDisplay) => `rotate(${display.positionInfo.rotation.radians}rad)`;

const calculatePositionStyle = (display: CdmVideoWallDestDisplay): React.CSSProperties => {
     return {
          width: "100%",
          height: "100%",
          transform: `${getTranslate(display)} ${getScale(display)}`,
          transformOrigin: "center",
     };
};

function calcLowestXScale(videoWall: CdmVideoWallDest) {
     let lowestXScale = 1;
     videoWall.displays.forEach((display) => {
          const xScale = getXScale(display.positionInfo.size);
          lowestXScale = Math.min(lowestXScale, xScale);
     });
     return lowestXScale;
}

function calcLowestYScale(videoWall: CdmVideoWallDest) {
     let lowestYScale = 1;
     videoWall.displays.forEach((display) => {
          const yScale = getYScale(display.positionInfo.size);
          lowestYScale = Math.min(lowestYScale, yScale);
     });
     return lowestYScale;
}

const calcScale = (xScale, yScale, maxWidth, maxHeight): { x: number, y: number } => {
     if (xScale === yScale) {
          return { x: 1, y: 1 };
     }
     if (!maxWidth || !maxHeight) {
          return xScale > yScale ?
               { x: yScale / xScale, y: 1 } : { x: 1, y: xScale / yScale };
     }
     if (xScale > yScale) { // grid video wall is portrait
          const minScale = yScale / xScale;
          let magnifyFactor = maxHeight / DEFAULT_WIDGET_HEIGHT;// magnifying is limited by height
          const xFactor = minScale * magnifyFactor; // check if scale makes widget too wide, then scale it down accordingly
          const possibleWidth = Math.max(xFactor * DEFAULT_WIDGET_WIDTH, maxWidth);
          magnifyFactor = magnifyFactor * (maxWidth / possibleWidth);
          return { x: minScale * magnifyFactor, y: magnifyFactor };
     } else { // grid video wall is landscape
          const minScale = xScale / yScale;
          let magnifyFactor = maxWidth / DEFAULT_WIDGET_WIDTH; // magnifying is limited by width
          const yFactor = minScale * magnifyFactor; // check if scale makes widget too high, then scale it down accordingly
          const possibleHeight = Math.max(yFactor * DEFAULT_WIDGET_HEIGHT, maxHeight);
          magnifyFactor = magnifyFactor * (maxHeight / possibleHeight);
          return { x: magnifyFactor, y: minScale * magnifyFactor };
     }



}

const getOfflineStyle = (display: CdmVideoWallDestDisplay): string => {
     return display.availability === "NOT_CONNECTED" ? "offline" : "";
};

export default function VideoWallWidget({ videoWall, className, maxWidth = 0, maxHeight = 0 }: IProps) {
     const { t } = useTranslation();
     const lowestXScale = calcLowestXScale(videoWall);
     const lowestYScale = calcLowestYScale(videoWall);
     const scale = calcScale(lowestXScale, lowestYScale, maxWidth, maxHeight);
     const widgetScale = `scale3d(${scale.x}, ${scale.y}, 1)`;

     return (
          <div className={`videoWallWidget ${className || ""}`} style={{ transform: widgetScale }}>
               {videoWall.displays.length < 1 && (
                    <div className="noDisplay">
                         {/* <FontAwesomeIcon icon={faBlockBrick} /> */}
                         <div style={{ fontSize: "13px" }}>
                              No displays
                         </div>
                    </div>
               )}
               {videoWall.displays.map((display) => (
                    <div
                         className={`videoWallDisplay ${getOfflineStyle(display)}`}
                         style={calculatePositionStyle(display)}
                         key={`video-wall-display-${display.id.value}`}
                    >
                         <img src="./assets/monitor.svg" style={{ transform: getRotate(display) }} />
                         {display.endPoint.type === "ONBOARD" ? (
                              <div className="display-number onboard">{t("CommonUI.displayCommon.displayTypeOnboard")}</div>
                         ) : (
                              <div className="display-number">{prettyPrintStationLabel(display.endPoint.label)}</div>
                         )}
                    </div>
               ))}
          </div>
     );
}
