import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./SmartText.scss";
import { ISmartText } from "./smartTextUtils";

interface IProps {
     smartTexts: ISmartText;
}

export default function SmartText({ smartTexts }: IProps) {
     const { t } = useTranslation();

     return (
          <div className="smartTextWrapper">

               {smartTexts.overlayText ?
                    <OverlayTrigger
                         placement="top"
                         overlay={<Tooltip>{t(smartTexts.overlayText)}</Tooltip>}
                    >
                         <div>
                              {t(`${smartTexts.text}`)}
                              <strong style={{ fontWeight: 600, color: "#D13E2C", wordBreak:'break-all' }}>{` `}{smartTexts.name}</strong>
                              ?
                         </div>
                    </OverlayTrigger>
                    :
                    <div>
                         {t(`${smartTexts.text}`)}
                         <strong style={{ fontWeight: 600, color: "#D13E2C", wordBreak:'break-all' }}>{` `}{smartTexts.name}</strong>
                         ?{` `}
                         {smartTexts.text2 && t(smartTexts.text2)}
                    </div>}


          </div>
     );
}
