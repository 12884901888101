"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestUpdateMapperLayoutStreams = exports.requestUpdateMapperLayoutMappingsCollection = exports.requestUpdateMapperLayoutMapping = exports.requestUpdateMapperLayoutInsets = exports.requestUpdateMapperLayoutHeader = exports.requestUpdateMapperLayoutGrid = exports.requestUpdateMapperLayoutGeometry = exports.requestUpdateMapperLayoutCanvasSize = exports.requestRemoveMapperLayoutMappingsCollection = exports.requestRemoveMapperLayoutMapping = exports.requestRemoveMapperLayout = exports.requestListMapperLayoutMappingsCollections = exports.requestListMapperLayoutMapping = exports.requestCreateMapperLayoutMappingsCollection = exports.requestCreateMapperLayoutMapping = exports.requestCreateMapperLayoutFromMapperLayout = exports.requestCreateMapperLayout = exports.requestApplyMapperLayoutToMapperLayout = exports.requestApplyMapperLayoutTo = exports.requestApplyMapperLayoutMappingsCollection = exports.requestApplyMapperLayoutMapping = void 0;
var _globalStates = require("../../../globalstates/globalStates");
var _msgTypes = require("./msgTypes");
var requestCreateMapperLayout = exports.requestCreateMapperLayout = function requestCreateMapperLayout(mapperLayoutCreator) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_CREATE_MAPPER_LAYOUT, mapperLayoutCreator);
};
var requestRemoveMapperLayout = exports.requestRemoveMapperLayout = function requestRemoveMapperLayout(mapperLayoutID) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_REMOVE_MAPPER_LAYOUT, mapperLayoutID);
};
var requestUpdateMapperLayoutHeader = exports.requestUpdateMapperLayoutHeader = function requestUpdateMapperLayoutHeader(mapperLayoutHeader) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_HEADER, mapperLayoutHeader);
};
var requestUpdateMapperLayoutGeometry = exports.requestUpdateMapperLayoutGeometry = function requestUpdateMapperLayoutGeometry(mapperLayoutDeployData) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_GEOMETRY, mapperLayoutDeployData);
};
var requestApplyMapperLayoutTo = exports.requestApplyMapperLayoutTo = function requestApplyMapperLayoutTo(mapperLayoutDeployData) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_APPLY_MAPPER_LAYOUT_TO_OTHER_MAPPER, mapperLayoutDeployData);
};
var requestApplyMapperLayoutToMapperLayout = exports.requestApplyMapperLayoutToMapperLayout = function requestApplyMapperLayoutToMapperLayout(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_APPLY_MAPPER_LAYOUT_TO_OTHER_MAPPER_LAYOUT, data);
};
var requestCreateMapperLayoutFromMapperLayout = exports.requestCreateMapperLayoutFromMapperLayout = function requestCreateMapperLayoutFromMapperLayout(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_CREATE_MAPPER_LAYOUT_FROM_MAPPER_LAYOUT, data);
};
var requestUpdateMapperLayoutCanvasSize = exports.requestUpdateMapperLayoutCanvasSize = function requestUpdateMapperLayoutCanvasSize(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_CANVAS_SIZE, data);
};
var requestUpdateMapperLayoutGrid = exports.requestUpdateMapperLayoutGrid = function requestUpdateMapperLayoutGrid(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_GRID, data);
};
var requestUpdateMapperLayoutInsets = exports.requestUpdateMapperLayoutInsets = function requestUpdateMapperLayoutInsets(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_INSETS, data);
};
var requestUpdateMapperLayoutStreams = exports.requestUpdateMapperLayoutStreams = function requestUpdateMapperLayoutStreams(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_STREAMS, data);
};
var requestListMapperLayoutMapping = exports.requestListMapperLayoutMapping = function requestListMapperLayoutMapping() {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_LIST_MAPPER_LAYOUT_MAPPINGS);
};
var requestCreateMapperLayoutMapping = exports.requestCreateMapperLayoutMapping = function requestCreateMapperLayoutMapping(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_CREATE_MAPPER_LAYOUT_MAPPING, data);
};
var requestRemoveMapperLayoutMapping = exports.requestRemoveMapperLayoutMapping = function requestRemoveMapperLayoutMapping(id) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_REMOVE_MAPPER_LAYOUT_MAPPING, id);
};
var requestUpdateMapperLayoutMapping = exports.requestUpdateMapperLayoutMapping = function requestUpdateMapperLayoutMapping(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_MAPPING, data);
};
var requestApplyMapperLayoutMapping = exports.requestApplyMapperLayoutMapping = function requestApplyMapperLayoutMapping(id) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_APPLY_MAPPING_LAYOUT_MAPPING, id);
};
var requestListMapperLayoutMappingsCollections = exports.requestListMapperLayoutMappingsCollections = function requestListMapperLayoutMappingsCollections() {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_LIST_MAPPER_LAYOUT_MAPPINGS_COLLECTIONS);
};
var requestCreateMapperLayoutMappingsCollection = exports.requestCreateMapperLayoutMappingsCollection = function requestCreateMapperLayoutMappingsCollection(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_CREATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, data);
};
var requestRemoveMapperLayoutMappingsCollection = exports.requestRemoveMapperLayoutMappingsCollection = function requestRemoveMapperLayoutMappingsCollection(id) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_REMOVE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, id);
};
var requestUpdateMapperLayoutMappingsCollection = exports.requestUpdateMapperLayoutMappingsCollection = function requestUpdateMapperLayoutMappingsCollection(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, data);
};
var requestApplyMapperLayoutMappingsCollection = exports.requestApplyMapperLayoutMappingsCollection = function requestApplyMapperLayoutMappingsCollection(id) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_APPLY_MAPPING_LAYOUT_MAPPINGS_COLLECTION, id);
};