import { NOT_SET_VALUE, PositionInfoPct, StringID, STRINGID_NOT_SET } from "userful-chronos-app-common-js/dist/models/common";
import { InsetComp, InsetCompVideoData } from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";

export const createVideoData = (positionInfo?: PositionInfoPct): InsetCompVideoData[] => {
    if (!positionInfo) {
        return [];
    }
    return [
        {
            id: STRINGID_NOT_SET,
            mapperID: STRINGID_NOT_SET,
            sceneID: STRINGID_NOT_SET,
            position: positionInfo,
            visibility: "VISIBLE",
            movable: "ACTIVE",
            showSourceName: "INVISIBLE",
            textOverlay: {
                text: "",
                visibility: "INVISIBLE",
                config: {
                    colour: "ORANGE",
                    font: {
                        fontName: "Sans"
                    },
                    style: {
                        styleName: "Bold"
                    },
                    textSize: 32,
                    shadow: false,
                    outline: true,
                    outlineColour: "BLACK",
                    xpos: 0.05,
                    ypos: 0.05
                }
            },
        }
    ]
}

export const createPlaceholderInsets = (insetID: StringID, itemID: StringID, type: 'playlist' | 'source', positionInfo?: PositionInfoPct, insetName?: string, insetDescription?: string): InsetComp => {
    return {
        id: insetID,
        name: insetName ? insetName : "",
        description: insetDescription ? insetDescription : "",
        sceneID: STRINGID_NOT_SET,
        playList: {
            id: STRINGID_NOT_SET,
            name: "",
            description: "",
            sceneID: STRINGID_NOT_SET,
            createdFrom: type === 'playlist' ? { ...itemID } : STRINGID_NOT_SET,
            behaviour: {
                playTimes: { times: 0 },
                startBehaviour: 'AUTO_START'
            },
            items: [{
                id: STRINGID_NOT_SET,
                name: "",
                description: "",
                createdFrom: STRINGID_NOT_SET,
                behaviour: {
                    activationDuration: { millis: 0 },
                    preStartDuration: { millis: 0 },
                    playDuration: { millis: 0 },
                    repeatConfig: {
                        times: { times: -1 },
                        duration: { millis: 0 },
                    },
                    persistenceType: 'KEEP_RUNNING',
                },
                source: {
                    id: STRINGID_NOT_SET,
                    name: "",
                    description: "",
                    sceneID: STRINGID_NOT_SET,
                    createdFrom: type === 'source' ? { ...itemID } : STRINGID_NOT_SET,
                    type: { value: NOT_SET_VALUE, name: NOT_SET_VALUE },
                    params: [],
                    displayResolution: { width: 1920, height: 1080 },
                    outputType: 'VIDEO_AND_AUDIO',
                    controlMode: 'NONE',
                    scaling: 'INSIDE',
                    sharingMode: 'ALWAYS_SHARE_SOURCE'
                }
            }],
        },
        audioData: [],
        videoData: createVideoData(positionInfo),
    }
}