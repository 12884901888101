import { RequestMessageDefinition, RespondMessageDefinition } from "userful-chronos-app-common-js/dist/message/messageModel";

const VIDEOWALL = 2101;


export const REQUEST_LIST_VIDEOWALLS = new RequestMessageDefinition(VIDEOWALL, 3);

export const NOTIFY_VIDEOWALL_CREATED = new RespondMessageDefinition(VIDEOWALL, 0);
export const NOTIFY_VIDEOWALL_REMOVED = new RespondMessageDefinition(VIDEOWALL, 1);
export const NOTIFY_VIDEOWALL_UPDATED = new RespondMessageDefinition(VIDEOWALL, 2);
export const RESPONSE_LIST_VIDEOWALLS = new RespondMessageDefinition(VIDEOWALL, 3);
