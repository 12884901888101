import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    ServicenowPassportHeader, ServicenowPassportID, ServicenowPassport, ServicenowLoadState, ServicenowPassportFormState, 
    ServicenowPassportDefault,
    ServicenowColumns, ServicenowColumnsRequest, ServicenowChoiceCount, ServicenowDataRequest
} from 'userful-chronos-app-common-js/dist/models/integration/servicenow';
import {
    requestListPassports, requestAddPassport, requestUpdatePassport, requestRemovePassport, requestUpdatePassportWithOAuth, requestColumns,
    requestEmbedData
} from './msgs/servicenowMsgSender';

const initialState: {
    servicenowPassports: ServicenowPassport[];
    loadState: ServicenowLoadState;
    passport: ServicenowPassport;
    copyPassport: ServicenowPassport;
    formType: ServicenowPassportFormState;
    openForm: boolean;
    authCode: string;
    columns: ServicenowColumns[],
    embedData: ServicenowChoiceCount[],
    plotOverlay: boolean
} = {
    servicenowPassports: [],
    loadState: 'RENDER',
    passport: ServicenowPassportDefault(),
    copyPassport: ServicenowPassportDefault(),
    formType: 'Create',
    openForm: false,
    authCode: "",
    columns: [], 
    embedData: [],
    plotOverlay: false
};

export const servicenowSlice = createSlice({
    name: 'servicenowSlice',
    initialState,
    reducers: {

        setPassports: (state, action: PayloadAction<ServicenowPassport[]>) => {
            state.servicenowPassports = action.payload;
        },
        addOrUpdatePassport: (state, action: PayloadAction<ServicenowPassport>) => {
            const foundIndex = state.servicenowPassports.findIndex(item => item.id.value === action.payload.id.value);
            if (foundIndex < 0) {
                state.servicenowPassports = [
                    ...state.servicenowPassports,
                    { ...action.payload },
                ];
            } else {
                state.servicenowPassports = [
                    ...state.servicenowPassports.slice(0, foundIndex),
                    { ...action.payload },
                    ...state.servicenowPassports.slice(foundIndex + 1),
                ];
            }
        },
        removePassport: (state, action: PayloadAction<ServicenowPassportID>) => {
            state.servicenowPassports = state.servicenowPassports.filter(item => item.id.value !== action.payload.value);
        },
        getPassportsFromServer: (state, action: PayloadAction<{}>) => {
            requestListPassports();
        },
        addPassportToServer: (state, action: PayloadAction<ServicenowPassportHeader>) => {
            requestAddPassport(action.payload);
        },
        updatePassportToServerWithOAuth: (state, action: PayloadAction<ServicenowPassportHeader>) => {
            requestUpdatePassportWithOAuth(action.payload);
        },
        updatePassportToServer: (state, action: PayloadAction<ServicenowPassportHeader>) => {
            requestUpdatePassport(action.payload);
        },
        removePassportToServer: (state, action: PayloadAction<ServicenowPassportID>) => {
            requestRemovePassport(action.payload);
        },
        changeLoadState: (state, action: PayloadAction<ServicenowLoadState>) => {
            state.loadState = action.payload;
        },
        setPassport: (state, action: PayloadAction<ServicenowPassport>) => {
            state.passport = action.payload;
        },
        setCopyPassport: (state, action: PayloadAction<ServicenowPassport>) => {
            state.copyPassport = action.payload;
        },
        setFormType: (state, action: PayloadAction<ServicenowPassportFormState>) => {
            state.formType = action.payload;
        },
        setOpenForm: (state, action: PayloadAction<boolean>) => {
            state.openForm = action.payload;
        },
        setAuthCode: (state, action: PayloadAction<string>) => {
            state.authCode = action.payload;
        },
        getColumns: (state, action: PayloadAction<ServicenowColumnsRequest>) => {
            requestColumns(action.payload);
        },
        setColumns: (state, action: PayloadAction<ServicenowColumns[]>) => {
            state.columns = action.payload;
        },
        getEmbedData: (state, action: PayloadAction<ServicenowDataRequest>) => {
            requestEmbedData(action.payload);
        },
        setEmbedData: (state, action: PayloadAction<ServicenowChoiceCount[]>) => {
            state.embedData = action.payload;
        },
        setPlotOverlay: (state, action: PayloadAction<boolean>) => {
            state.plotOverlay = action.payload;
        }
    },
})

export const servicenowActions = servicenowSlice.actions;

export default servicenowSlice.reducer

