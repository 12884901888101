"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPOND_LICENSE_DETAIL = exports.REQUEST_UPLOAD_LICENSE = exports.REQUEST_UPDATE_LICENSED_DISPLAYS = exports.REQUEST_UPDATE_LICENSE = exports.REQUEST_GET_LICENSE = exports.REQUEST_ACTIVATE_LICENSE = void 0;
var _messageModel = require("../../messageModel");
var LICENSE = 2006;
var REQUEST_GET_LICENSE = exports.REQUEST_GET_LICENSE = new _messageModel.RequestMessageDefinition(LICENSE, 0);
var REQUEST_UPDATE_LICENSE = exports.REQUEST_UPDATE_LICENSE = new _messageModel.RequestMessageDefinition(LICENSE, 1);
var REQUEST_ACTIVATE_LICENSE = exports.REQUEST_ACTIVATE_LICENSE = new _messageModel.RequestMessageDefinition(LICENSE, 2);
var REQUEST_UPDATE_LICENSED_DISPLAYS = exports.REQUEST_UPDATE_LICENSED_DISPLAYS = new _messageModel.RequestMessageDefinition(LICENSE, 3);
var REQUEST_UPLOAD_LICENSE = exports.REQUEST_UPLOAD_LICENSE = new _messageModel.RequestMessageDefinition(LICENSE, 4);
var RESPOND_LICENSE_DETAIL = exports.RESPOND_LICENSE_DETAIL = new _messageModel.RespondMessageDefinition(LICENSE, 0);