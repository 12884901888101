import React, { MouseEventHandler, useState } from 'react';
import { Badge } from 'react-bootstrap';
import "./EllipsisAvatars.scss";

interface IProps {
    usernames: Array<string>,
}

const ELLIPSIS_THRESHOLD = 5;

// I can't find a 3rd party library for this "thing"
export default function EllipsisAvatars(props: IProps) {
    return (
        <div className="avatarsContainer">
            <div className="avatarIconsContainer">
                {
                    props.usernames.slice(0, ELLIPSIS_THRESHOLD).reverse().map(user =>
                        <Badge title={user} key={`ellipsis-avatar-icon-${user}`}>{user.charAt(0).toUpperCase()}</Badge>
                    )
                }
            </div>
            {
                props.usernames.length > ELLIPSIS_THRESHOLD &&
                <div className="avatarsEllipsis">
                    &nbsp;...&nbsp;&nbsp;
                    +{props.usernames.length - ELLIPSIS_THRESHOLD}
                </div>
            }
        </div>
    )
}