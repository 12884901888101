export interface UserPermission {
    isConstellationCreator?: boolean;
    isConstellationManager?: boolean;
    isConstellationViewer?: boolean;
    isConstellationSuppressor?: boolean;
    isUserViewer?: boolean;
    isUserManager?: boolean;
    isUserCreator?: boolean;
    isUserSuppressor?: boolean;
    isClusterManager?: boolean;
    isClusterViewer?: boolean;
    isClusterSuppressor?: boolean;
    isClusterSetup?: boolean;
}

export const initUserPermission = (): UserPermission => {
    return {
        isConstellationCreator: false,
        isConstellationManager: false,
        isConstellationViewer: false,
        isConstellationSuppressor: false,
        isUserViewer: false,
        isUserManager: false,
        isUserCreator: false,
        isUserSuppressor: false,
        isClusterManager: false,
        isClusterViewer: false,
        isClusterSuppressor: false,
        isClusterSetup: false,
    };
};

export const hasAtLeastOnePermission = (permission: UserPermission) => {
    for (const property in permission) {
        if (permission[property]) return true;
    }

    return false;
};
