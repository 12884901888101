import { faTriangleExclamation, faMultiply, faTrashAlt, faUserSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { KeycloakUser } from "../../../model/CarbonExternalModels";
import { apiDisableUser, apiEnableUser } from "../../../messages/api/gcmUserManagement";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { UserPermission } from "../../../model/CarbonPermissionModels";

interface IProps {
    user: KeycloakUser;
    permissions: UserPermission;
}

export default function UserDisable(props: IProps) {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    if (props.user.userProfile.userName === "admin" || !props.permissions.isUserCreator) return null;

    return (
        <>
            <Dropdown.Item
                className="dropdownItem"
                onClick={() => {
                    setShowConfirmation(true);
                }}
            >
                <div className="icon-container">
                    <FontAwesomeIcon icon={faUserSlash} />
                </div>
                <div className="textInside">{props.user.userProfile.enabled ? "Disable" : "Enable"} User</div>
            </Dropdown.Item>

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faTriangleExclamation} />
                                <div className="title">{props.user.userProfile.enabled ? "Disable" : "Enable"} User</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            {`Are you sure you want to ${props.user.userProfile.enabled ? "disable" : "enable"} ${props.user.userProfile.userName} ?`}
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                                if (props.user.userProfile.enabled) apiDisableUser(getGlobalStates().keycloak.token, props.user.id)
                                if (!props.user.userProfile.enabled) apiEnableUser(getGlobalStates().keycloak.token, props.user.id)
                            }}
                        >
                            {props.user.userProfile.enabled ? "Disable" : "Enable"}
                        </button>

                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
