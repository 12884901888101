import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppView, ITenant, TAppStatus, TCluster, TNavigationStatus, TTableView } from "./appUtils";
import { TUserGroupTab } from "../userManagement/userManagementUtils";
import { initUserPermission, UserPermission } from "../../model/CarbonPermissionModels";
import { PairingCodeConsumed } from "../../model/CarbonExternalModels";

interface IState {
    tenant: ITenant;
    appView: IAppView;
    permission: UserPermission;
    appStatus: TAppStatus;
    navigationStatus: TNavigationStatus;
    clusterView: TTableView;
    userGroupTab: TUserGroupTab;
    consumedCode: PairingCodeConsumed;
    clusterTabsView: TCluster;
    logout: boolean;
}

const initialState: IState = {
    tenant: { id: { value: "" }, name: "", description: "" },
    permission: initUserPermission(),
    appView: { id: { value: "NOT_DEFINED" }, type: "CONSTELLATIONS" },
    appStatus: "IDLE",
    navigationStatus: "SHOWN",
    clusterView: "CARDS",
    userGroupTab: "Roles",
    clusterTabsView: "OVERVIEW",
    consumedCode: undefined,
    logout: false
};

export const appSlice = createSlice({
    name: "appSlice",
    initialState,
    reducers: {
        setTenant: (state: IState, action: PayloadAction<ITenant>) => {
            state.tenant = action.payload;
        },
        setClusterTabsView: (state: IState, action: PayloadAction<TCluster>) => {
            state.clusterTabsView = action.payload;
        },
        setPermissions: (state: IState, action: PayloadAction<UserPermission>) => {
            state.permission = action.payload;
        },
        setAppView: (state: IState, action: PayloadAction<IAppView>) => {
            state.appView = action.payload;

            if (action.payload.type !== "USER_GROUP") state.userGroupTab = "Roles";
        },
        setNavigationStatus: (state: IState, action: PayloadAction<TNavigationStatus>) => {
            state.navigationStatus = action.payload;
        },
        setAppStatus: (state: IState, action: PayloadAction<TAppStatus>) => {
            state.appStatus = action.payload;
        },
        setUserGroupTabs: (state, action: PayloadAction<TUserGroupTab>) => {
            state.userGroupTab = action.payload;
        },
        setClusterView: (state, action: PayloadAction<TTableView>) => {
            state.clusterView = action.payload;
        },
        setPairingCodeConsumed: (state, action: PayloadAction<PairingCodeConsumed>) => {
            state.consumedCode = action.payload;
        },
        setLogout: (state, action: PayloadAction<boolean>) => {
            state.logout = action.payload;
        }
    }
});

export const AppSliceActions = appSlice.actions;

export default appSlice.reducer;
