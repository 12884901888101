"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unregisterMsgHandler = exports.unregisterErrorMsgHandler2 = exports.registerMsgHandler = exports.registerHideErrorPopup = exports.registerErrorMsgHandlerByMsgDefinition = exports.registerErrorMsgHandler2 = exports.registerErrorMsgHandler = exports.pushErrorToMessageRegistery = exports.handleMsg = void 0;
var _uuid = require("uuid");
var errorRegistry = [];
var errorRegistry2 = {};
var errorRegisteryByMsgDefinition = new Map();
var hideErrorPopupRegistery = [];
var registry = new Map();
var registerMsgHandler = exports.registerMsgHandler = function registerMsgHandler(msgDef, handler) {
  var handlers = registry.has(msgDef.toString()) ? registry.get(msgDef.toString()) : new Map();
  var handlerID = (0, _uuid.v4)();
  handlers.set(handlerID, handler);
  registry.set(msgDef.toString(), handlers);
  return handlerID;
};
var unregisterMsgHandler = exports.unregisterMsgHandler = function unregisterMsgHandler(msgDef, handlerID) {
  var handlers = registry.has(msgDef.toString()) ? registry.get(msgDef.toString()) : new Map();
  handlers["delete"](handlerID);
  registry.set(msgDef.toString(), handlers);
};
var registerErrorMsgHandler = exports.registerErrorMsgHandler = function registerErrorMsgHandler(handler) {
  return errorRegistry.push(handler);
};
var registerErrorMsgHandler2 = exports.registerErrorMsgHandler2 = function registerErrorMsgHandler2(handler) {
  var handlerID = (0, _uuid.v4)();
  errorRegistry2[handlerID] = handler;
  return handlerID;
};
var unregisterErrorMsgHandler2 = exports.unregisterErrorMsgHandler2 = function unregisterErrorMsgHandler2(handlerID) {
  delete errorRegistry2[handlerID];
};
var registerErrorMsgHandlerByMsgDefinition = exports.registerErrorMsgHandlerByMsgDefinition = function registerErrorMsgHandlerByMsgDefinition(msgDef, handler) {
  var handlers = errorRegisteryByMsgDefinition.has(msgDef.toString()) ? errorRegisteryByMsgDefinition.get(msgDef.toString()) : new Map();
  var handlerID = (0, _uuid.v4)();
  handlers.set(handlerID, handler);
  errorRegisteryByMsgDefinition.set(msgDef.toString(), handlers);
  return handlerID;
};
var registerHideErrorPopup = exports.registerHideErrorPopup = function registerHideErrorPopup(msgDef) {
  hideErrorPopupRegistery.push(msgDef.toString());
};
var pushErrorToMessageRegistery = exports.pushErrorToMessageRegistery = function pushErrorToMessageRegistery(error) {
  Object.values(errorRegistry2).forEach(function (handler) {
    try {
      handler(error);
    } catch (e) {
      console.error(e);
    }
  });
};
var handleMsg = exports.handleMsg = function handleMsg(msg) {
  if (msg.msgType === 999) {
    // 999 are error messages
    errorRegistry.forEach(function (handler) {
      var errorData = JSON.parse(msg.payload);
      handler(errorData);
      console.debug("Error:", errorData['msg'], errorData['throwableClassName']);
    });
    return;
  }
  var isErrorMsg = false;
  try {
    var decodedMsg = JSON.parse(msg.payload);
    if (decodedMsg.success !== undefined && decodedMsg.success !== null && decodedMsg.moduleID !== undefined) {
      try {
        msg.payload = JSON.stringify(decodedMsg.arg);
      } catch (_unused) {
        msg.payload = decodedMsg.arg;
      }
      isErrorMsg = !decodedMsg.success;
      if (isErrorMsg) {
        if (errorRegisteryByMsgDefinition.has(msg.getDefinition().toString())) {
          errorRegisteryByMsgDefinition.get(msg.getDefinition().toString()).forEach(function (handler) {
            try {
              handler(decodedMsg);
            } catch (e) {
              console.error(e);
            }
          });
        }
        if (!hideErrorPopupRegistery.includes(msg.getDefinition().toString())) {
          Object.values(errorRegistry2).forEach(function (handler) {
            try {
              handler(decodedMsg);
            } catch (e) {
              console.error(e);
            }
          });
        }
        console.debug("Error:", decodedMsg.msg, msg, decodedMsg);
      }
    }
  } catch (e) {
    console.debug(e);
  }
  if (!registry.has(msg.getDefinition().toString()) || isErrorMsg) {
    return;
  }
  registry.get(msg.getDefinition().toString()).forEach(function (handler) {
    try {
      handler(msg.payload);
    } catch (e) {
      console.error(e);
    }
  });
};