import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

interface IProps {
    filename: string;
    progress: number;
}

export default function UploadFileProgress(props: IProps) {
    const { t } = useTranslation();

    return (<div className='uploadFileProgressContainer'>
        <div className="uploadFileProgressLabel">
            <span>{t('SapphireApp.CreateMeetingRoom.uploading')} {props.filename}:</span> <span>{`${props.progress}%`}</span>
        </div>
        <ProgressBar now={props.progress} animated />
    </div>);
}