import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from "react-redux";
import audioDestinationsSlice from "../destinations/audioDestinationsSlice";
import destinationGroupsSlice from "../destinations/destinationGroupsSlice";
import displayDestinationsSlice from "../destinations/displayDestinationsSlice";
import videowallDestinationsSlice from "../destinations/videowallDestinationsSlice";
import assetSlice from "../sourceassets/assetSlice";
import playlistSlice from "../sourceassets/playlistSlice";
import uclientsSlice from "../displays/uclientsSlice";
import sourceSlice from "../sourceassets/sourceSlice";
import mappingGroupsSlice from "./mappingGroupsSlice";
import operationsCentersSlice from "../operationCenter/operationsCentersSlice";
import layoutsSlice from "../layouts/layoutsSlice";
import uConductSlice from "../uconduct/uConductSlice";
import mapperCollectionsSlice from "./mapperCollectionsSlice";
import destinationMappingSlice from "./destinationMappingSlice";

export const MappingGroupContext = React.createContext(null);

const mappingStore = configureStore({
    reducer: {
        mappingGroupStore: mappingGroupsSlice,
        assetStore: assetSlice,
        sourceStore: sourceSlice,
        playlistStore: playlistSlice,
        displaysStore: displayDestinationsSlice,
        audiosStore: audioDestinationsSlice,
        videowallsStore: videowallDestinationsSlice,
        destinationGroupsStore: destinationGroupsSlice,
        operationsCentersStore: operationsCentersSlice,
        uclientsStore: uclientsSlice,
        layoutsStore: layoutsSlice,
        uConductViewStore: uConductSlice,
        mapperCollectionsStore: mapperCollectionsSlice,
        destinationMappingStore: destinationMappingSlice,
    },
});
export default mappingStore;

export type MappingRootState = ReturnType<typeof mappingStore.getState>;
export type MappingDispatch = typeof mappingStore.dispatch;
export const useMappingSelector: TypedUseSelectorHook<MappingRootState> = createSelectorHook(MappingGroupContext);
export const useMappingDispatch = createDispatchHook<MappingDispatch>(MappingGroupContext);
