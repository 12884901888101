import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import configStore, { ConfigContext } from './configStore';

interface IProps {
  appID?: string;
  children: ReactNode,
}

function ConfigProvider(props: IProps) {

  return (
    <Provider store={configStore} context={ConfigContext}>
      {props.children}
    </Provider>
  );
}

export default ConfigProvider;