export const path = "/kc";
export const authorize_saml_path = `${path}/auth/saml`;
export const keycloak_path = `${path}/auth/status`;

export const GET_SAML_INTEGRATIONS = `${authorize_saml_path}`;
export const PUT_SAML_INTEGRATIONS = `${authorize_saml_path}`;
export const POST_SAML_INTEGRATIONS = `${authorize_saml_path}`;
export const DELETE_SAML_INTEGRATIONS = `${authorize_saml_path}`;
export const GET_SYNC_USERS = `${authorize_saml_path}/syncUsers`;

export const GET_KEYCLOAK_STATUS = `${keycloak_path}`;