import { faPlus, faMultiply, faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Dropdown, Offcanvas, Modal } from "react-bootstrap";
import { objectEqual } from "../../common/functions/objectComparison";
import { CreateKeycloakGroup, KeycloakGroup, UpdateKeycloakGroup, UserfulGroup } from "../../model/CarbonExternalModels";
import { selectUserGroups, useCarbonSelector } from "../../store/carbonStore";
import { initKeycloakGroup } from "../../store/userManagement/userManagementUtils";

import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { apiCreateUserGroup, apiUpdateUserGroup } from "../../messages/api/gcmUserManagement";
import { UserPermission } from "../../model/CarbonPermissionModels";

interface IProps {
    type: "PRIMARY" | "SECONDARY" | "DROPDOWN";
    group?: KeycloakGroup;
    permissions: UserPermission;
}

export default function GroupForm(props: IProps) {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [registerChange, setRegisterChange] = useState<boolean>(false);

    const [originalGroup, setOriginalGroup] = useState<KeycloakGroup>(props.group ? props.group : initKeycloakGroup());
    const [currentGroup, setCurrentGroup] = useState<KeycloakGroup>(originalGroup);

    // Data
    const groups: { [id: string]: UserfulGroup } = useCarbonSelector(selectUserGroups);
    const allGroups: KeycloakGroup[] = [];

    for (const property in groups) {
        allGroups.push(groups[property].group);
    }

    const foundChanges = !objectEqual(originalGroup, currentGroup);
    const nameError =
        (allGroups.find((c) => c.name === currentGroup.name) && currentGroup.name !== originalGroup.name) || currentGroup.name.length === 0
            ? "Please make sure the name is unique and is not empty."
            : "";

    useEffect(() => {
        if (props.group) setOriginalGroup(props.group);
    }, [props.group]);

    useEffect(() => {
        setCurrentGroup(originalGroup);
    }, [originalGroup]);

    function handleNameChange(event) {
        setRegisterChange(true);
        setCurrentGroup({ ...currentGroup, name: event.target.value });
    }
    function handleDescriptionChange(event) {
        setRegisterChange(true);
        setCurrentGroup({ ...currentGroup, description: event.target.value });
    }
    const onFormShow = () => {
        setShowForm(true);
    };
    const onFormHide = () => {
        if (foundChanges) {
            setShowConfirmation(true);
        } else {
            setShowForm(false);
            onRestore();
        }
    };
    const onRestore = () => {
        setRegisterChange(false);
        if (props.group) {
            setOriginalGroup(props.group);
            setCurrentGroup(props.group);
        } else {
            setOriginalGroup(initKeycloakGroup());
        }
    };

    const onCreate = () => {
        if (props.group) {
            const updateKeycloakGroup: UpdateKeycloakGroup = {
                id: currentGroup.id,
                displayName: currentGroup.displayName,
                description: currentGroup.description,
                members: currentGroup.members,
                name: currentGroup.name,
                realmRoles: currentGroup.roles,
            };

            console.warn(updateKeycloakGroup);

            apiUpdateUserGroup(getGlobalStates().keycloak.token, updateKeycloakGroup);
        } else {
            const createKeycloakGroup: CreateKeycloakGroup = {
                name: currentGroup.name,
                description: currentGroup.description,
                displayName: currentGroup.name,
                members: [],
                realmRoles: [],
            };

            apiCreateUserGroup(getGlobalStates().keycloak.token, createKeycloakGroup);
        }
        onRestore();
        setShowForm(false);
    };

    if (!props.permissions.isUserCreator) return null;

    return (
        <>
            {props.type === "PRIMARY" && (
                <button className="createButton" onClick={(e) => onFormShow()}>
                    <FontAwesomeIcon icon={faPlus} />
                    Create Permission Set
                </button>
            )}
            {props.type === "SECONDARY" && (
                <button className="secondaryButton" onClick={(e) => onFormShow()}>
                    Create Permission Set
                </button>
            )}
            {props.type === "DROPDOWN" && (
                <Dropdown.Item className="dropdownItem" onClick={(e) => onFormShow()}>
                     <div className="icon-container">
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                    <div className="textInside">Create Create Permission Set</div>
                </Dropdown.Item>
            )}

            <Offcanvas show={showForm} onHide={onFormHide} placement="end">
                <div className="userfulFormBody noselect">
                    <div className="userfulFormHeader">
                        <div className="userfulFormRow">
                            <h1 className="userfulFormHeaderTitle">{props.group ? "Edit Permission Set" : "Create Permission Set"}</h1>
                            <button className="userfulIconButton" onClick={onFormHide}>
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="userfulFormRow">
                            <h3 className="userfulFormHeaderDescription">
                                Permission set allow you to assign a set of permissions to a group of users.
                            </h3>
                        </div>
                    </div>
                    <div className="userfulFormContents">
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <h4 className="userfulFormContentsTitle">Permission Set Details</h4>
                            <h5 className="userfulFormContentsDescription">Details relating to this permission set.</h5>
                        </div>
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Name</h5>
                                <h6>{currentGroup.name.length}/60</h6>
                            </div>
                            <input
                                className="userfulInputField"
                                placeholder="Enter name here..."
                                type="text"
                                maxLength={60}
                                value={currentGroup.name}
                                onChange={handleNameChange}
                            />
                            {nameError && registerChange && <div className="errorText">{nameError}</div>}
                        </div>
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Description</h5>
                                <h6>{currentGroup.description.length}/200</h6>
                            </div>
                            <textarea
                                className="uTextarea"
                                placeholder="Enter description here..."
                                maxLength={200}
                                value={currentGroup.description}
                                onChange={handleDescriptionChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="userfulFormFooter">
                    <button className="secondaryUserfulButton" onClick={onFormHide}>
                        Cancel
                    </button>
                    <button
                        className="primaryUserfulButton"
                        onClick={onCreate}
                        disabled={nameError.length > 0 || !foundChanges}
                    >
                        {props.group ? "Save Changes" : "Create"}
                    </button>
                </div>
            </Offcanvas>

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon className="userfulIcon" icon={faTriangleExclamation} />
                                <div className="title">Are you sure you want to leave?</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            Are you sure you want to leave without saving your changes? Any unsaved modifications will
                            be lost.
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowForm(false);
                                setShowConfirmation(false);
                                onRestore();
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
