import React from "react";
import { IUFormHeader } from "../uFormUtils";
import MultiStateToggleSlider from "../../Widgets/MultiStateToggle/MultiStateToggle";
import { IMultiStateToggleChoice } from "../../Widgets/MultiStateToggle/multiStateToggleUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { ReactElement } from "react";
import { faMultiply } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import ClickButton from "../../Widgets/Buttons/ClickButton";
import { closeButton } from "../../Widgets/Buttons/Presets/buttonPresets";
import UFormConfirmationDialog from "../UFormConfirmationDialog/UFormConfirmationDialog";
import { useUFormContext } from "../Context/UFormContext";

interface IProps {
     header: IUFormHeader;
     onClose(e): void;
}

export default function UFormHeader(props: IProps) {
     const { t } = useTranslation();

     const {
          state: {
               formConditions: { equivalenceConfirmed },
          },
     } = useUFormContext();

     return (
          <div className="uformHeader">
               <div className="uformHeaderMain">
                    <div className="mainText">{t(props.header.mainText)}</div>
                    {props.header.hideCloseButton ? null : !equivalenceConfirmed ? (
                         <UFormConfirmationDialog onConfirm={props.onClose} trigger={{ trigger: closeButton }} />
                    ) : (
                         <ClickButton button={closeButton} onClick={props.onClose} />
                    )}
               </div>
               <div className="uformHeaderDescription">{t(props.header.description)}</div>
          </div>
     );
}
