import { AxiosResponse } from 'axios';
import { apiGet, apiPost, getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import { GET_USER_FEDERATION_DATA, POST_CREATE_USER_FEDERATION, POST_UPDATE_USER_FEDERATION, POST_DELETE_USER_FEDERATION, POST_SYNC_USER_FEDERATION } from './ldapAPIPath';
import usermgtStore from '../usermgtStore';
import { usermgtSliceActions } from '../usermgtSlice';
import { ldapSliceActions } from '../ldapSlice';
import { AddLdapUserFederation, UpdateLdapUserFederation, UserFederationID, SyncLdapUser } from 'userful-chronos-app-common-js/dist/models/usermgt/ldap';
import { toast } from "react-toastify";
import { ConfirmationToastContentStore } from '../../../Container/ConfirmationToastContentStore';

const errorHandler = (err) => {
    console.debug("Error calling init app api ");
    console.debug(err);
}

const getError = (response: any) => {
    return ({
        success: false,
        arg: response.data.arg ? response.data.arg : "",
        errorCode: response.data.errorCode ? response.data.errorCode : "",
        moduleID: response.data.moduleID ? response.data.moduleID : "",
        severity: response.data.severity ? response.data.severity : '',
        msg: response.data.unlocalizedMsg ? response.data.unlocalizedMsg : ""
    }
    );
}


export const checkReponseIsValid = (name: string, response: AxiosResponse): boolean => {
    if (response.status >= 400) {
        console.log(`${name} error: ${response.status}`);
        return false;
    }
    if (!response.data || response.data.severity !== "SUCCESS") {
        console.log(`${name} empty response`);
        return false;
    }
    return true;
}

export const getFederationDataFromServer = (token: string) => {

    const t1 = getGlobalStates().host + GET_USER_FEDERATION_DATA;

    apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(ldapSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(ldapSliceActions.setFederationData(response.data.arg));
        usermgtStore.dispatch(ldapSliceActions.setLoadBool(false));
        usermgtStore.dispatch(ldapSliceActions.setSaveBool(false));
        console.debug(`receive authorize`, response.data);
    }).catch(() => console.warn("failed"));
}

export const addFederationData = (token: string, data: AddLdapUserFederation) => {

    const t1 = getGlobalStates().host + POST_CREATE_USER_FEDERATION;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(ldapSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(ldapSliceActions.setFederationData(response.data.arg));
        usermgtStore.dispatch(ldapSliceActions.setLoadBool(false));
        usermgtStore.dispatch(ldapSliceActions.setOverlay(false));
        usermgtStore.dispatch(ldapSliceActions.setSaveBool(false));
        console.debug(`receive authorize`, response.data);
        const getLDAP = response.data.arg.ldaps.filter((ldap) => ldap.userFederation.name === data.name)[0];
        const syncResult = getLDAP.error === false ? getLDAP.syncResult.status : getLDAP.errorMassage;
        const customDisplay = ConfirmationToastContentStore(data.name, "Custom", syncResult);
        toast(customDisplay, { containerId: 'confirmationContainer' });
    }).catch(() => console.warn("failed"));
}

export const updateFederationData = (token: string, data: UpdateLdapUserFederation) => {

    const t1 = getGlobalStates().host + POST_UPDATE_USER_FEDERATION;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(ldapSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(ldapSliceActions.setFederationData(response.data.arg));
        usermgtStore.dispatch(ldapSliceActions.setLoadBool(false));
        usermgtStore.dispatch(ldapSliceActions.setOverlay(false));
        usermgtStore.dispatch(ldapSliceActions.setSaveBool(false));
        console.debug(`updateFederationData`, response.data.arg);
        const getLDAP = response.data.arg.ldaps.filter((ldap) => ldap.userFederation.name === data.name)[0];
        const syncResult = getLDAP.error === false ? getLDAP.syncResult.status : getLDAP.errorMassage;
        const customDisplay = ConfirmationToastContentStore(data.name, "Custom", syncResult);
        toast(customDisplay, { containerId: 'confirmationContainer' });
    }).catch(() => console.warn("failed"));
}

export const deleteFederationData = (token: string, id: UserFederationID) => {

    const t1 = getGlobalStates().host + POST_DELETE_USER_FEDERATION;

    apiPost(t1, id, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(ldapSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(ldapSliceActions.setFederationData(response.data.arg));
        usermgtStore.dispatch(ldapSliceActions.setLoadBool(false));
        usermgtStore.dispatch(ldapSliceActions.setSaveBool(false));
        console.debug(`receive authorize`, response.data);
    }).catch(() => console.warn("failed"));
}

export const syncFederationData = (token: string, data: SyncLdapUser) => {

    const t1 = getGlobalStates().host + POST_SYNC_USER_FEDERATION;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(ldapSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(ldapSliceActions.setFederationData(response.data.arg));
        usermgtStore.dispatch(ldapSliceActions.setLoadBool(false));
        usermgtStore.dispatch(ldapSliceActions.setSaveBool(false));
        console.debug(`receive authorize`, response.data);
    }).catch(() => console.warn("failed"));
}