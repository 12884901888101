import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import {
    getSAMLDataFromServer, addSAMLDataToServer, updateSAMLDataToServer, deleteSAMLDataToServer, syncSAMLDataToServer, getFailoverStatusFromServer
} from './msgs/samlAPICallers';
import {
    UpdateSaml, DeleteSaml,
    AddSaml, SamlProvider, SyncSaml
} from 'userful-chronos-app-common-js/dist/models/usermgt/saml';

export interface InitialStateSAML {
    samlData: SamlProvider[];
    loadBool: boolean;
    saveBool: boolean;
    overlay: boolean;
    failoverStatus: boolean;
}

const initialStateSAML: InitialStateSAML = {
    samlData: [],
    loadBool: true,
    saveBool: false,
    overlay: false,
    failoverStatus: false

};

export const samlSlice = createSlice({
    name: 'samlSlice',
    initialState: initialStateSAML,
    reducers: {
        getSAMLDataFromServer: (state, action: PayloadAction<{}>) => {
            getSAMLDataFromServer(getGlobalStates().keycloak.token);
        },
        setSAMLData: (state, action: PayloadAction<SamlProvider[]>) => {
            state.samlData = action.payload;
        },
        setLoadBool: (state, action: PayloadAction<boolean>) => {
            state.loadBool = action.payload;
        },
        addSAMLDataToServer: (state, action: PayloadAction<AddSaml>) => {
            state.saveBool = true;
            addSAMLDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        addSAMLData: (state, action: PayloadAction<SamlProvider>) => {
            const newSAML = { ...action.payload };
            const index = state.samlData.findIndex((ele) => ele.id.value === newSAML.id.value);
            if (index !== -1) {
                state.samlData[index] = newSAML;
            }
            else {
                state.samlData = [...state.samlData, action.payload];
            }
        },
        updateSAMLDataToServer: (state, action: PayloadAction<UpdateSaml>) => {
            state.saveBool = true;
            updateSAMLDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        deleteSAMLDataToServer: (state, action: PayloadAction<DeleteSaml>) => {
            state.saveBool = true;
            deleteSAMLDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        removeSAMLData: (state, action: PayloadAction<DeleteSaml>) => {
            state.samlData = state.samlData.filter((ele) => ele.id.value !== action.payload.id.value);
        },
        syncSAMLDataToServer: (state, action: PayloadAction<SyncSaml>) => {
            state.saveBool = true;
            syncSAMLDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        setSaveBool: (state, action: PayloadAction<boolean>) => {
            state.saveBool = action.payload;
        },
        setOverlay: (state, action: PayloadAction<boolean>) => {
            state.overlay = action.payload;
        },
        setFailoverStatus: (state, action: PayloadAction<boolean>) => {
            state.failoverStatus = action.payload;
        },
        getFailoverStatus: (state, action: PayloadAction<{}>) => {
            getFailoverStatusFromServer(getGlobalStates().keycloak.token);
        },

    }
})

export const samlSliceActions = samlSlice.actions;

export default samlSlice.reducer;