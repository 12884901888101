import {
    RequestMessageDefinition,
    RespondMessageDefinition,
} from "userful-chronos-app-common-js/dist/message/messageModel";

// CATEGORIES:

const INSET = 3000;
const SNAPSHOT = 3004;
const SOURCE_DEF = 3005;
const MAPPER = 3015;
const GRID = 2301;
const CR_MANAGER = 2400;
const SURFACE = 3011;

// MAPPER

export const SEND_UPDATE_MAPPER_GRID = new RequestMessageDefinition(MAPPER, 9);
// export const SEND_UPDATE_MAPPER_DESTINATIONS = new RequestMessageDefinition(MAPPER, 5);

export const RECEIVE_UPDATE_MAPPER_INSETS = new RequestMessageDefinition(MAPPER, 4);
// export const RECEIVE_UPDATE_MAPPER_DESTINATIONS = new RequestMessageDefinition(MAPPER, 5);

// CONTROL ROOM

export const RECEIVE_CONTROL_ROOM = new RespondMessageDefinition(10, 1);

export const SEND_CONTROL_ROOM_ADDED = new RequestMessageDefinition(CR_MANAGER, 0);
// export const SEND_CONTROL_ROOM_UPDATED = new RequestMessageDefinition(CR_MANAGER, 1);
export const SEND_CONTROL_ROOM_REMOVED = new RequestMessageDefinition(CR_MANAGER, 2);

export const REQUEST_LIST_CONTROL_ROOMS = new RequestMessageDefinition(CR_MANAGER, 4);

export const RECEIVE_CONTROL_ROOM_ADDED = new RespondMessageDefinition(CR_MANAGER, 0);
// export const RECEIVE_CONTROL_ROOM_UPDATED = new RespondMessageDefinition(CR_MANAGER, 1);
export const RECEIVE_CONTROL_ROOM_REMOVED = new RespondMessageDefinition(CR_MANAGER, 2);

export const RESPONSE_LIST_CONTROL_ROOMS = new RespondMessageDefinition(CR_MANAGER, 4);

// SURFACE
// export const SEND_LIST_SURFACES = new RequestMessageDefinition(SURFACE, 6);
// export const RECEIVE_LIST_SURFACES = new RespondMessageDefinition(SURFACE, 6);

// GRID

// export const RECEIVE_GRID = new RespondMessageDefinition(GRID, 0);
export const RECEIVE_GRID = new RespondMessageDefinition(MAPPER, 9);
export const SEND_GRID = new RequestMessageDefinition(GRID, 0);

// INSET

// export const SEND_CREATE_INSET = new RequestMessageDefinition(INSET, 11);
// export const SEND_REMOVE_INSET = new RequestMessageDefinition(INSET, 1);
export const SEND_UPDATE_INSET_GEOMETRY = new RequestMessageDefinition(INSET, 2);
export const SEND_UPDATE_INSET_AUDIO_SETTINGS = new RequestMessageDefinition(INSET, 3);
// export const SEND_UPDATE_INSET_TEXT_OVERLAY = new RequestMessageDefinition(INSET, 4);
export const SEND_UPDATE_INSET_HEADER = new RequestMessageDefinition(INSET, 5);
export const SEND_UPDATE_INSET_VISIBILITY = new RequestMessageDefinition(INSET, 6);
// export const SEND_UPDATE_INSET_TEXT_OVERLAY_VISIBILITY = new RequestMessageDefinition(INSET, 7);
// export const SEND_LIST_INSETS = new RequestMessageDefinition(INSET, 8);
// export const SEND_LIST_INSETS_FOR_SCENES = new RequestMessageDefinition(INSET, 9);
export const SEND_UPDATE_INSET_MOVABLE = new RequestMessageDefinition(INSET, 10);

export const SEND_UPDATE_MULTI_INSET_GEOMETRY = new RequestMessageDefinition(INSET, 17);
export const SEND_UPDATE_MULTI_INSET_AUDIO_SETTINGS = new RequestMessageDefinition(INSET, 18);
export const SEND_UPDATE_MULTI_INSET_TEXT_OVERLAY = new RequestMessageDefinition(INSET, 19);
export const SEND_UPDATE_MULTI_INSET_VISIBILITY = new RequestMessageDefinition(INSET, 20);
export const SEND_UPDATE_MULTI_INSET_TEXT_OVERLAY_VISIBILITY = new RequestMessageDefinition(INSET, 21);
export const SEND_UPDATE_MULTI_INSET_FROZEN = new RequestMessageDefinition(INSET, 22);

export const RECEIVE_CREATE_INSET = new RespondMessageDefinition(INSET, 0);
export const RECEIVE_REMOVE_INSET = new RespondMessageDefinition(INSET, 1);
export const RECEIVE_UPDATE_INSET_GEOMETRY = new RespondMessageDefinition(INSET, 2);
export const RECEIVE_UPDATE_INSET_AUDIO_SETTINGS = new RespondMessageDefinition(INSET, 3);
// export const RECEIVE_UPDATE_INSET_TEXT_OVERLAY = new RespondMessageDefinition(INSET, 4);
export const RECEIVE_UPDATE_INSET_HEADER = new RespondMessageDefinition(INSET, 5);
export const RECEIVE_UPDATE_INSET_VISIBILITY = new RespondMessageDefinition(INSET, 6);
// export const RECEIVE_UPDATE_INSET_TEXT_OVERLAY_VISIBILITY = new RespondMessageDefinition(INSET, 7);
// export const RECEIVE_LIST_INSETS = new RespondMessageDefinition(INSET, 8);
// export const RECEIVE_LIST_INSETS_FOR_SCENES = new RespondMessageDefinition(INSET, 9);
export const RECEIVE_UPDATE_INSET_MOVABLE = new RespondMessageDefinition(INSET, 10);

// SOURCE_DEF

// export const SEND_CREATE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 0);
// export const SEND_UPDATE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 1);
// export const SEND_DELETE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 2);

// export const RECEIVE_CREATE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 1);
// export const RECEIVE_UPDATE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 2);
// export const RECEIVE_DELETE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 3);

// SNAPSHOT

export const SEND_SNAPSHOT_CREATE = new RespondMessageDefinition(SNAPSHOT, 0);
export const SEND_SNAPSHOT_REMOVE = new RespondMessageDefinition(SNAPSHOT, 1);
export const SEND_SNAPSHOT_UPDATE = new RespondMessageDefinition(SNAPSHOT, 2);
export const SEND_SNAPSHOT_LOAD = new RespondMessageDefinition(SNAPSHOT, 3);
export const SEND_SNAPSHOT_SAVE = new RespondMessageDefinition(SNAPSHOT, 4);

export const RECEIVE_SNAPSHOT_CREATED = new RespondMessageDefinition(SNAPSHOT, 0);
export const RECEIVE_SNAPSHOT_REMOVED = new RespondMessageDefinition(SNAPSHOT, 1);
export const RECEIVE_SNAPSHOT_UPDATED = new RespondMessageDefinition(SNAPSHOT, 2);
export const RECEIVE_SNAPSHOT_SAVED = new RespondMessageDefinition(SNAPSHOT, 3);
export const RECEIVE_SNAPSHOT_LOAD_STARTED = new RespondMessageDefinition(SNAPSHOT, 4);
export const RECEIVE_SNAPSHOT_LOAD_COMPLETE = new RespondMessageDefinition(SNAPSHOT, 5);
export const RECEIVE_SNAPSHOT_LOAD_FAILED = new RespondMessageDefinition(SNAPSHOT, 6);
