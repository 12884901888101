"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_UPDATE_SOURCE_DEF = exports.RESPONSE_UPDATE_PLAYLIST_DEF = exports.RESPONSE_REMOVE_SOURCE_DEF = exports.RESPONSE_REMOVE_PLAYLIST_DEF = exports.RESPONSE_QUERY_SOURCE_DEFS = exports.RESPONSE_QUERY_SOURCE_ASSETS = exports.RESPONSE_QUERY_PLAYLIST_DEFS = exports.RESPONSE_LIST_SOURCE_SPECS = exports.RESPONSE_LIST_SOURCE_DEFS = exports.RESPONSE_LIST_SOURCE_ASSETS = exports.RESPONSE_LIST_PLAYLIST_DEFS = exports.RESPONSE_FIND_SOURCE_DEF = exports.RESPONSE_FIND_PLAYLIST_DEF = exports.REQUEST_UPDATE_SOURCE_DEF = exports.REQUEST_UPDATE_SOURCE_ASSET = exports.REQUEST_UPDATE_PLAYLIST_DEF = exports.REQUEST_REMOVE_SOURCE_DEF = exports.REQUEST_REMOVE_SOURCE_ASSET = exports.REQUEST_REMOVE_PLAYLIST_DEF = exports.REQUEST_QUERY_SOURCE_DEFS = exports.REQUEST_QUERY_SOURCE_ASSETS = exports.REQUEST_QUERY_PLAYLIST_DEFS = exports.REQUEST_LIST_SOURCE_SPECS = exports.REQUEST_LIST_SOURCE_DEFS = exports.REQUEST_LIST_SOURCE_ASSETS = exports.REQUEST_LIST_PLAYLIST_DEFS = exports.REQUEST_FORCE_UPDATE_SOURCE_DEF = exports.REQUEST_FORCE_UPDATE_PLAYLIST_DEF = exports.REQUEST_FORCE_REMOVE_SOURCE_DEF = exports.REQUEST_FORCE_REMOVE_PLAYLIST_DEF = exports.REQUEST_FIND_SOURCE_DEF = exports.REQUEST_FIND_PLAYLIST_DEF = exports.REQUEST_CREATE_SOURCE_DEF = exports.REQUEST_CREATE_SOURCE_ASSET = exports.REQUEST_CREATE_PLAYLIST_DEF = exports.NOTIFY_UPDATE_SOURCE_SPEC = exports.NOTIFY_UPDATE_SOURCE_DEF = exports.NOTIFY_UPDATE_SOURCE_ASSET = exports.NOTIFY_UPDATE_PLAYLIST_DEF = exports.NOTIFY_REMOVE_SOURCE_DEF = exports.NOTIFY_REMOVE_SOURCE_ASSET = exports.NOTIFY_REMOVE_PLAYLIST_DEF = exports.NOTIFY_ADD_SOURCE_DEF = exports.NOTIFY_ADD_SOURCE_ASSET = exports.NOTIFY_ADD_PLAYLIST_DEF = void 0;
var _messageModel = require("../../messageModel");
var SAM_ASSET = 4000;
var SAM_ASSET_QUERY = 4001;
var SAM_PLAYLIST_DEF = 4002;
var SAM_PLAYLIST_DEF_QUERY = 4003;
var SAM_SOURCE_DEF = 4004;
var SAM_SOURCE_DEF_QUERY = 4005;
var SAM_SOURCE_SPEC = 4006;

//assets
var REQUEST_QUERY_SOURCE_ASSETS = exports.REQUEST_QUERY_SOURCE_ASSETS = new _messageModel.RequestMessageDefinition(SAM_ASSET_QUERY, 0);
var RESPONSE_QUERY_SOURCE_ASSETS = exports.RESPONSE_QUERY_SOURCE_ASSETS = new _messageModel.RespondMessageDefinition(SAM_ASSET_QUERY, 0);
var REQUEST_LIST_SOURCE_ASSETS = exports.REQUEST_LIST_SOURCE_ASSETS = new _messageModel.RequestMessageDefinition(SAM_ASSET, 0);
var REQUEST_CREATE_SOURCE_ASSET = exports.REQUEST_CREATE_SOURCE_ASSET = new _messageModel.RequestMessageDefinition(SAM_ASSET, 1);
var REQUEST_UPDATE_SOURCE_ASSET = exports.REQUEST_UPDATE_SOURCE_ASSET = new _messageModel.RequestMessageDefinition(SAM_ASSET, 2);
var REQUEST_REMOVE_SOURCE_ASSET = exports.REQUEST_REMOVE_SOURCE_ASSET = new _messageModel.RequestMessageDefinition(SAM_ASSET, 3);
var RESPONSE_LIST_SOURCE_ASSETS = exports.RESPONSE_LIST_SOURCE_ASSETS = new _messageModel.RespondMessageDefinition(SAM_ASSET, 0);
var NOTIFY_ADD_SOURCE_ASSET = exports.NOTIFY_ADD_SOURCE_ASSET = new _messageModel.RespondMessageDefinition(SAM_ASSET, 1);
var NOTIFY_UPDATE_SOURCE_ASSET = exports.NOTIFY_UPDATE_SOURCE_ASSET = new _messageModel.RespondMessageDefinition(SAM_ASSET, 2);
var NOTIFY_REMOVE_SOURCE_ASSET = exports.NOTIFY_REMOVE_SOURCE_ASSET = new _messageModel.RespondMessageDefinition(SAM_ASSET, 3);

//playlists
var REQUEST_QUERY_PLAYLIST_DEFS = exports.REQUEST_QUERY_PLAYLIST_DEFS = new _messageModel.RequestMessageDefinition(SAM_PLAYLIST_DEF_QUERY, 0);
var RESPONSE_QUERY_PLAYLIST_DEFS = exports.RESPONSE_QUERY_PLAYLIST_DEFS = new _messageModel.RespondMessageDefinition(SAM_PLAYLIST_DEF_QUERY, 0);
var REQUEST_LIST_PLAYLIST_DEFS = exports.REQUEST_LIST_PLAYLIST_DEFS = new _messageModel.RequestMessageDefinition(SAM_PLAYLIST_DEF, 0);
var REQUEST_CREATE_PLAYLIST_DEF = exports.REQUEST_CREATE_PLAYLIST_DEF = new _messageModel.RequestMessageDefinition(SAM_PLAYLIST_DEF, 1);
var REQUEST_UPDATE_PLAYLIST_DEF = exports.REQUEST_UPDATE_PLAYLIST_DEF = new _messageModel.RequestMessageDefinition(SAM_PLAYLIST_DEF, 2);
var REQUEST_REMOVE_PLAYLIST_DEF = exports.REQUEST_REMOVE_PLAYLIST_DEF = new _messageModel.RequestMessageDefinition(SAM_PLAYLIST_DEF, 3);
var REQUEST_FIND_PLAYLIST_DEF = exports.REQUEST_FIND_PLAYLIST_DEF = new _messageModel.RequestMessageDefinition(SAM_PLAYLIST_DEF, 4);
var REQUEST_FORCE_UPDATE_PLAYLIST_DEF = exports.REQUEST_FORCE_UPDATE_PLAYLIST_DEF = new _messageModel.RequestMessageDefinition(SAM_PLAYLIST_DEF, 5);
var REQUEST_FORCE_REMOVE_PLAYLIST_DEF = exports.REQUEST_FORCE_REMOVE_PLAYLIST_DEF = new _messageModel.RequestMessageDefinition(SAM_PLAYLIST_DEF, 6);
var RESPONSE_LIST_PLAYLIST_DEFS = exports.RESPONSE_LIST_PLAYLIST_DEFS = new _messageModel.RespondMessageDefinition(SAM_PLAYLIST_DEF, 0);
var RESPONSE_FIND_PLAYLIST_DEF = exports.RESPONSE_FIND_PLAYLIST_DEF = new _messageModel.RespondMessageDefinition(SAM_PLAYLIST_DEF, 1);
var NOTIFY_ADD_PLAYLIST_DEF = exports.NOTIFY_ADD_PLAYLIST_DEF = new _messageModel.RespondMessageDefinition(SAM_PLAYLIST_DEF, 2);
var NOTIFY_UPDATE_PLAYLIST_DEF = exports.NOTIFY_UPDATE_PLAYLIST_DEF = new _messageModel.RespondMessageDefinition(SAM_PLAYLIST_DEF, 3);
var NOTIFY_REMOVE_PLAYLIST_DEF = exports.NOTIFY_REMOVE_PLAYLIST_DEF = new _messageModel.RespondMessageDefinition(SAM_PLAYLIST_DEF, 4);
var RESPONSE_UPDATE_PLAYLIST_DEF = exports.RESPONSE_UPDATE_PLAYLIST_DEF = new _messageModel.RespondMessageDefinition(SAM_PLAYLIST_DEF, 5);
var RESPONSE_REMOVE_PLAYLIST_DEF = exports.RESPONSE_REMOVE_PLAYLIST_DEF = new _messageModel.RespondMessageDefinition(SAM_PLAYLIST_DEF, 6);

//source
var REQUEST_QUERY_SOURCE_DEFS = exports.REQUEST_QUERY_SOURCE_DEFS = new _messageModel.RequestMessageDefinition(SAM_SOURCE_DEF_QUERY, 0);
var RESPONSE_QUERY_SOURCE_DEFS = exports.RESPONSE_QUERY_SOURCE_DEFS = new _messageModel.RespondMessageDefinition(SAM_SOURCE_DEF_QUERY, 0);
var REQUEST_LIST_SOURCE_DEFS = exports.REQUEST_LIST_SOURCE_DEFS = new _messageModel.RequestMessageDefinition(SAM_SOURCE_DEF, 0);
var REQUEST_CREATE_SOURCE_DEF = exports.REQUEST_CREATE_SOURCE_DEF = new _messageModel.RequestMessageDefinition(SAM_SOURCE_DEF, 1);
var REQUEST_UPDATE_SOURCE_DEF = exports.REQUEST_UPDATE_SOURCE_DEF = new _messageModel.RequestMessageDefinition(SAM_SOURCE_DEF, 2);
var REQUEST_REMOVE_SOURCE_DEF = exports.REQUEST_REMOVE_SOURCE_DEF = new _messageModel.RequestMessageDefinition(SAM_SOURCE_DEF, 3);
var REQUEST_FIND_SOURCE_DEF = exports.REQUEST_FIND_SOURCE_DEF = new _messageModel.RequestMessageDefinition(SAM_SOURCE_DEF, 4);
var REQUEST_FORCE_UPDATE_SOURCE_DEF = exports.REQUEST_FORCE_UPDATE_SOURCE_DEF = new _messageModel.RequestMessageDefinition(SAM_SOURCE_DEF, 5);
var REQUEST_FORCE_REMOVE_SOURCE_DEF = exports.REQUEST_FORCE_REMOVE_SOURCE_DEF = new _messageModel.RequestMessageDefinition(SAM_SOURCE_DEF, 6);
var RESPONSE_LIST_SOURCE_DEFS = exports.RESPONSE_LIST_SOURCE_DEFS = new _messageModel.RespondMessageDefinition(SAM_SOURCE_DEF, 0);
var RESPONSE_FIND_SOURCE_DEF = exports.RESPONSE_FIND_SOURCE_DEF = new _messageModel.RespondMessageDefinition(SAM_SOURCE_DEF, 1);
var NOTIFY_ADD_SOURCE_DEF = exports.NOTIFY_ADD_SOURCE_DEF = new _messageModel.RespondMessageDefinition(SAM_SOURCE_DEF, 2);
var NOTIFY_UPDATE_SOURCE_DEF = exports.NOTIFY_UPDATE_SOURCE_DEF = new _messageModel.RespondMessageDefinition(SAM_SOURCE_DEF, 3);
var NOTIFY_REMOVE_SOURCE_DEF = exports.NOTIFY_REMOVE_SOURCE_DEF = new _messageModel.RespondMessageDefinition(SAM_SOURCE_DEF, 4);
var RESPONSE_UPDATE_SOURCE_DEF = exports.RESPONSE_UPDATE_SOURCE_DEF = new _messageModel.RespondMessageDefinition(SAM_SOURCE_DEF, 5);
var RESPONSE_REMOVE_SOURCE_DEF = exports.RESPONSE_REMOVE_SOURCE_DEF = new _messageModel.RespondMessageDefinition(SAM_SOURCE_DEF, 6);
var REQUEST_LIST_SOURCE_SPECS = exports.REQUEST_LIST_SOURCE_SPECS = new _messageModel.RequestMessageDefinition(SAM_SOURCE_SPEC, 0);
var RESPONSE_LIST_SOURCE_SPECS = exports.RESPONSE_LIST_SOURCE_SPECS = new _messageModel.RespondMessageDefinition(SAM_SOURCE_SPEC, 0);
var NOTIFY_UPDATE_SOURCE_SPEC = exports.NOTIFY_UPDATE_SOURCE_SPEC = new _messageModel.RespondMessageDefinition(SAM_SOURCE_SPEC, 1);