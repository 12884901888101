import Form from 'react-bootstrap/Form';
import './UPasswordWidget.scss';
import { InputGroup } from 'react-bootstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import { IUFormField } from "../uFormUtils";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from 'react';

interface IProps {
    temporaryText?: string;
    id?: string;
    key: string;
    field: IUFormField;
    forceValue?: string | number | boolean | undefined;
    forceError?: string;
    onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

function UPasswordWidget(props: IProps) {

    const { t } = useTranslation();

    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*#?&-]).{8,}$/;
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const onSubmitForm = (event: any) => {
        event.preventDefault();
    }

    let wholeValue = useUFormContext().state.fieldState[props.field.key];
    const value = wholeValue[t(props.field.title)];
    const confirmValue = wholeValue[t(props.field.confirmTitle)];

    let checkBool = wholeValue[props.field.checkTitle];

    let error = useUFormContext().state.fieldErrors[props.field.key];
    const { onFieldChange } = useUFormContext();

    const onTextChange = (e) => {

        let updatedValue = {}
        if (e.target.name === props.field.checkTitle)
            updatedValue = { ...wholeValue, [e.target.name]: e.target.checked };
        else {
            updatedValue = { ...wholeValue, [e.target.name]: e.target.value };
            if (e.target.name === t(props.field.title))
                updatedValue = { ...updatedValue, [`${t(props.field.title)} Error`]: (e.target.value.length === 0 || !passwordRegex.test(e.target.value)) }

            if (e.target.name === t(props.field.confirmTitle))
                updatedValue = { ...updatedValue, [`${t(props.field.confirmTitle)} Error`]: (e.target.value.length === 0 || (e.target.value.length > 0 && e.target.value !== updatedValue[`${t(props.field.title)}`])) }

        }
        if (props.onItemChange) props.onItemChange({ [props.field.key]: updatedValue }, props.field);
        else onFieldChange({ [props.field.key]: updatedValue }, props.field);

    }

    return (
        <>
            <div className='userManagementTextWidget' style={{ width: props.field.width }}>
                <div className='userManagementTextWidgetHeader'>
                    <div className='userManagementTextWidgetHeaderOne'>
                        <div className='problemCanvasTitleOne'>
                            <div style={{ color: "#D13E2C" }}> *</div>
                            {t(props.field.title)}
                        </div>
                    </div>
                    {props.field.displayCheck && <div className="checkTemporary">
                        <Form.Check
                            aria-label="tempPassword"
                            id="tempPassword"
                            onChange={(e) => onTextChange(e)}
                            name={props.field.checkTitle}
                            checked={checkBool} />
                        <div className="checkTemporaryText">{t(`${props.field.temporaryText}`)}</div>
                    </div>}
                </div>

                <Form className='formcontrolPasswordWidget' onSubmit={onSubmitForm}>
                    <Form.Group className='form-group'>
                        <InputGroup>
                            <Form.Control
                                as="input"
                                value={value.current}
                                onChange={(e) => onTextChange(e)}
                                name={t(props.field.title)}
                                autoComplete="off"
                                maxLength={200}
                                isInvalid={wholeValue[`${t(props.field.title)} Error`] && value !== ""}
                                id={props.id || ''}
                                width={props.field.width}
                                type={showPassword ? 'text' : 'password'} />
                            <InputGroup.Text onClick={toggleShowPassword}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </InputGroup.Text>
                        </InputGroup>
                        {(value !== "" && wholeValue[`${t(props.field.title)} Error`]) && <div className="invalidInputOne"> {t('UserManagement.passwordError')}</div>}

                    </Form.Group>
                </Form>

            </div >

            {/* This is for comfirm password */}

            <div className='userManagementTextWidget' style={{ width: props.field.width }}>
                <div className='userManagementTextWidgetHeader'>
                    <div className='userManagementTextWidgetHeaderOne'>
                        <div className='problemCanvasTitleOne'>
                            <div style={{ color: "#D13E2C" }}> *</div>
                            {t(props.field.confirmTitle)}
                        </div>
                    </div>
                </div>

                <Form className='formcontrolPasswordWidget' onSubmit={onSubmitForm}>
                    <Form.Group className='form-group'>
                        <InputGroup>
                            <Form.Control
                                as="input"
                                value={confirmValue.current}
                                onChange={(e) => onTextChange(e)}
                                name={t(props.field.confirmTitle)}
                                isInvalid={wholeValue[`${t(props.field.confirmTitle)} Error`] && confirmValue !== ""}
                                autoComplete="off"
                                maxLength={200}
                                id={props.id || ''}
                                width={props.field.width}
                                type={showConfirmPassword ? 'text' : 'password'} />
                            <InputGroup.Text onClick={toggleShowConfirmPassword}>
                                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                            </InputGroup.Text>
                        </InputGroup>
                        {(confirmValue !== "" && wholeValue[`${t(props.field.confirmTitle)} Error`]) && <div className="invalidInputOne"> {t('UserManagement.confirmPasswordError')}</div>}

                    </Form.Group>
                </Form>

            </div >
        </>
    );
}

export default UPasswordWidget;