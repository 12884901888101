import { HttpsSettings } from 'userful-chronos-app-common-js/dist/models/config/httpsSettings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { applyHttpsSettingsToServer, getHttpsErrors, HttpsSettingsError, initHttpsSettings, sanitizeHttpsSettings } from './httpsUtils';
import { AppStatus } from 'userful-chronos-app-common-js/dist/models/postLoginData';
import { ServiceReply } from 'userful-chronos-app-common-js/dist/models/common';


const initialState: {
    initialSettings: HttpsSettings;
    settings: HttpsSettings;
    errors: HttpsSettingsError;
    setupError: ServiceReply<any>;
} = {
    initialSettings: { ...initHttpsSettings() },
    settings: { ...initHttpsSettings() },
    errors: {},
    setupError: null,
};

export const httpsSlice = createSlice({
    name: 'httpsSlice',
    initialState,
    reducers: {
        initSettings: (state, action: PayloadAction<AppStatus | null>) => {
            const initSetts = initHttpsSettings(action.payload);
            state.settings = { ...initSetts };
            state.initialSettings = { ...initSetts }
            state.errors = {};
        },
        updateSettings: (state, action: PayloadAction<Partial<HttpsSettings>>) => {
            state.settings = {
                ...state.settings,
                ...action.payload,
            }
            state.errors = getHttpsErrors(state.settings);
        },
        sanitizeSettings: (state) => {
            state.settings = sanitizeHttpsSettings(state.settings);
            state.errors = getHttpsErrors(state.settings);
        },
        toggleAutoGenerateCert: (state) => {
            state.settings = {
                ...state.settings,
                selfSigned: !state.settings.selfSigned,
            }
        },
        applySettingsToServer: (state, action: PayloadAction<string>) => {
            state.settings = sanitizeHttpsSettings(state.settings);
            state.errors = getHttpsErrors(state.settings);
            if (state.errors.cert || state.errors.frontEndUrl || state.errors.key) {
                console.log('Https settings has errors');
                return;
            }
            applyHttpsSettingsToServer(state.settings, action.payload);
        },
        clearErrors: (state) => {
            state.errors = {};
            state.setupError = null;
        },
    },
})

export const httpsActions = httpsSlice.actions;

export default httpsSlice.reducer

