"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SYSTEM_SCHEDULABLES = exports.SCHEDULES = exports.EVENTS = void 0;
var EVENTS = exports.EVENTS = [];
var SCHEDULES = exports.SCHEDULES = [];
var SYSTEM_SCHEDULABLES = exports.SYSTEM_SCHEDULABLES = [{
  id: {
    value: "reboot",
    type: "SYS_OPS"
  },
  name: "reboot",
  description: "Reboot system.",
  type: "SYS_OPS"
}, {
  id: {
    value: "check-updates",
    type: "SYS_OPS"
  },
  name: "check-updates",
  description: "Checks for system updates.",
  type: "SYS_OPS"
}, {
  id: {
    value: "broadcast-message",
    type: "SYS_OPS"
  },
  name: "broadcast-message",
  description: "Displays a broadcast message.",
  type: "SYS_OPS"
}, {
  id: {
    value: "shutdown",
    type: "SYS_OPS"
  },
  name: "shutdown",
  description: "Shuts down system.",
  type: "SYS_OPS"
}];