import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { IConstellationInfo } from "../../../../model/CarbonExternalModels";
import { faTriangleExclamation, faMultiply, faTrashCan, faXmarkCircle } from "@fortawesome/pro-regular-svg-icons";
import CreateConstellation from "../CreateConstellation";
import { apiDeleteConstellation } from "../../../../messages/api/gcmAPICallers";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import { Button } from "userful-chronos-common-ui/dist";

interface IProps {
    constellation: IConstellationInfo;
}

export default function ConstellationDropdown(props: IProps) {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    if (!permissions.isConstellationSuppressor && !permissions.isConstellationCreator) return null;

    return (
        <>
            <Dropdown className="operationCenterDropdown">
                <Dropdown.Toggle bsPrefix="standardSwitch iconButton" as={Button} >
                    <FontAwesomeIcon icon={faEllipsis} />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdownMenuSwitch">
                    <CreateConstellation
                        type={"DROPDOWN"}
                        constellation={props.constellation.definition}
                        permissions={permissions}
                    />
                    {permissions.isConstellationSuppressor && (
                        <Dropdown.Item
                            className="dropdownItem"
                            onClick={() => {
                                setShowConfirmation(true);
                            }}
                        >
                            <div className="iconInside">
                                <FontAwesomeIcon icon={faTrashCan} />
                            </div>
                            <div className="textInside">Delete</div>
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon className="userfulIcon" icon={faTriangleExclamation} />
                                <div className="title">Delete Constellation</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            {props.constellation.cluster.length === 0 && (
                                <div>
                                    Are you sure you want to delete <b>{props.constellation.definition.name}</b> ?
                                </div>
                            )}
                            {props.constellation.cluster.length > 0 && (
                                <div>
                                    <div>
                                        Cannot delete <b>{props.constellation.definition.name}</b> !
                                    </div>
                                    <div>Please make sure you have unpaired all clusters first.</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        {props.constellation.cluster.length === 0 && (
                            <button
                                className="warningUserfulButton"
                                onClick={(e) => {
                                    setShowConfirmation(false);
                                    apiDeleteConstellation(
                                        getGlobalStates().keycloak.token,
                                        props.constellation.definition.id
                                    );
                                }}
                            >
                                Delete
                            </button>
                        )}
                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
