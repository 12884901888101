import React, { useState, createContext, useContext } from 'react';
import { LoadingWidget } from './LoadingWidget';
import { FullSreenLoadingWidget } from './FullScreenLoadingWidget';

export const LOADING_COMPONENTS: any = {
    LOADING_WIDGET: LoadingWidget,
    LOADING_FULLSCREEN_WIDGET: FullSreenLoadingWidget,
};

export type LoadingProps = {
    text?: string,
    timeOut?: number,
    variant?: string,
    sm?: boolean,
}

type GlobalLoadingContextType = {
    showLoading: (loadingType: string, loadingProps?: LoadingProps, onShowBusyCallback?: Function, onHideLoadingCallback?: Function) => void;
    hideLoading: () => void;
    store: any;
};

const initalState: GlobalLoadingContextType = {
    showLoading: () => { },
    hideLoading: () => { },
    store: {},
};

const GlobalLoadingContext = createContext(initalState);
export const useGlobalLoadingContext = () => useContext(GlobalLoadingContext);

interface Props {
    children: React.ReactNode;
  }

export const GlobalLoading: React.FC<Props> = ({ children }) => {

    const [store, setStore] = useState<{ loadingType: string, loadingProps: any }>();
    const { loadingType, loadingProps } = store || {};

    const showLoading = (loadingType: string, loadingProps: any = {}, onTimeOutCallback) => {
        const handleTimeOut = () => {
            onTimeOutCallback();
            hideLoading();
        }
        setStore({
            ...store,
            loadingType,
            loadingProps,
        });
        setTimeout(handleTimeOut, loadingProps.timeOut || 10000);
    };



    const hideLoading = () => {
        setStore({
            ...store,
            loadingType: null,
            loadingProps: {},
        });
    };

    const renderComponent = () => {
        const LoadingWidgetComponent = LOADING_COMPONENTS[loadingType];
        if (!loadingType || !LoadingWidgetComponent) {
            return null;
        }
        return <LoadingWidgetComponent id="global-loading-widget" {...loadingProps} />;
    };

    return (
        <GlobalLoadingContext.Provider value={{ store, showLoading, hideLoading }}>
            {renderComponent()}
            {children}
        </GlobalLoadingContext.Provider>
    );
};


