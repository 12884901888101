"use strict";

var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _usersmanagement = require("../../functions/usersmanagement");
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/usd/clearancedata', function (method, data) {
  if (method === 'get') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.getClearances)())
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/usd/clearanceaction', function (method, data) {
  if (method === 'get') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.getClearanceActions)())
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/usd/clearancedata/add', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.addLevel)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/usd/clearancedata/update', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.editLevel)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/usd/clearancedata/delete', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.deleteLevel)(data))
    };
  }
});