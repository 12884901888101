"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GWTAUDIO_MUTED = exports.GWTAUDIO_100 = void 0;
var GWTAUDIO_MUTED = exports.GWTAUDIO_MUTED = {
  audioVolume: {
    level: 0,
    muted: true
  },
  audioOffset: {
    millis: 0
  }
};
var GWTAUDIO_100 = exports.GWTAUDIO_100 = {
  audioVolume: {
    level: 100,
    muted: false
  },
  audioOffset: {
    millis: 0
  }
};