import { IStorageInfo, TUnit, IMemoryInfo, IGPUInfo, ICPUInfo } from "../../model/CarbonExternalModels";

export function formatStorage(storageArray: IStorageInfo[]): string {
    const convertToHighestUnit = (value: number, unit: TUnit): string => {
        let newValue = value;
        let newUnit = unit;

        while (newValue >= 1024 && newUnit !== "TB") {
            newValue /= 1024;
            switch (newUnit) {
                case "KB":
                    newUnit = "MB";
                    break;
                case "MB":
                    newUnit = "GB";
                    break;
                case "GB":
                    newUnit = "TB";
                    break;
                default:
                    break;
            }
        }

        return `${Math.ceil(newValue)} ${newUnit}`;
    };

    const formattedStorageArray = storageArray.map((storageInfo) => {
        const totalFormatted = convertToHighestUnit(storageInfo.total, storageInfo.unit);
        const percentage = Math.round(((storageInfo.total - storageInfo.available) / storageInfo.total) * 100);
        return `${totalFormatted} · ${percentage}% usage`;
    });

    return formattedStorageArray.join(", "); // Adjust separator as needed
}

export function formatMemory(obj: IMemoryInfo): string {
    const convertToHighestUnit = (value: number, unit: TUnit): string => {
        let newValue = value;
        let newUnit = unit;

        while (newValue >= 1024 && newUnit !== "TB") {
            newValue /= 1024;
            switch (newUnit) {
                case "KB":
                    newUnit = "MB";
                    break;
                case "MB":
                    newUnit = "GB";
                    break;
                case "GB":
                    newUnit = "TB";
                    break;
                default:
                    break;
            }
        }

        return `${Math.ceil(newValue)} ${newUnit}`;
    };

    const totalFormatted = convertToHighestUnit(obj.total, obj.unit);
    const percentage = Math.round(((obj.total - obj.free) / obj.total) * 100);

    return `${totalFormatted} · ${percentage}% usage`;
}

export function formatGPU(gpuArray: IGPUInfo[]): string {
    const convertToHighestUnit = (value: number, unit: TUnit): string => {
        switch (unit) {
            case "CORE":
            case "BYTES":
                if (value >= 1024 * 1024 * 1024) {
                    return `${Math.ceil(value / (1024 * 1024 * 1024))} TB`;
                } else if (value >= 1024 * 1024) {
                    return `${Math.ceil(value / (1024 * 1024))} GB`;
                } else if (value >= 1024 * 1024) {
                    return `${Math.ceil(value / 1024)} MB`;
                } else {
                    return `${Math.ceil(value)} KB`;
                }
            case "GB":
                if (value >= 1024) {
                    return `${Math.ceil(value / 1024)} TB`;
                } else {
                    return `${Math.ceil(value)} GB`;
                }
            case "TB":
                return `${Math.ceil(value)} TB`;
            default:
                return `${value} ${unit}`;
        }
    };

    const formattedGPUArray = gpuArray.map((gpuInfo) => {
        const totalFormatted = convertToHighestUnit(gpuInfo.total, gpuInfo.unit);
        const percentage = Math.round((gpuInfo.used / gpuInfo.total) * 100);
        return `${gpuArray[0].deviceName} · ${percentage}% usage`;
    });

    return formattedGPUArray.join(", "); // Adjust separator as needed
}

export function getGPUUsage(gpuArray: IGPUInfo[]): number {

    let total = 0;

    for (let i = 0; i < gpuArray.length; i++) {
        const gpuInfo = gpuArray[i];

        const percentage = (gpuInfo.used / gpuInfo.total) * 100

        total += percentage;
    }

    total /= gpuArray.length;

    return total;
}

export function getCPUUsage(cpuInfo: ICPUInfo): number {

    const percentage = (cpuInfo.used / cpuInfo.size) * 100;

    return percentage;
}

export function formatCPU(obj: ICPUInfo): string {
    // console.warn(obj);
    const convertToHighestUnit = (value: number, unit: TUnit): string => {
        switch (unit) {
            case "CORE":
                return `${Math.ceil(value)} CORE`;
            case "BYTES":
                if (value >= 1024 * 1024 * 1024) {
                    return `${Math.ceil(value / (1024 * 1024 * 1024))} TB`;
                } else if (value >= 1024 * 1024) {
                    return `${Math.ceil(value / (1024 * 1024))} GB`;
                } else if (value >= 1024 * 1024) {
                    return `${Math.ceil(value / 1024)} MB`;
                } else {
                    return `${Math.ceil(value)} KB`;
                }
            case "GB":
                if (value >= 1024) {
                    return `${Math.ceil(value / 1024)} TB`;
                } else {
                    return `${Math.ceil(value)} GB`;
                }
            case "TB":
                return `${Math.ceil(value)} TB`;
            default:
                return `${value} ${unit}`;
        }
    };

    const totalFormatted = convertToHighestUnit(obj.size, obj.unit);
    const percentage = Math.round(obj.used / 10);

    return `${totalFormatted} · ${percentage}% usage`;
}

export function formatTimestamp(timestamp: number): string {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
    };

    const formattedDate = date.toLocaleDateString('en-US', options);

    // Replace comma with a hyphen to match the desired format
    return formattedDate.replace(',', ' -');
}

export function formatElapsedTime(timestamp: number, currentTime: number): string {
    const now = Math.floor(currentTime / 1000); // Current time in seconds
    const elapsed = now - (timestamp / 1000);

    const weeks = Math.floor(elapsed / (7 * 24 * 3600));
    const days = Math.floor((elapsed % (7 * 24 * 3600)) / (24 * 3600));
    const hours = Math.floor((elapsed % (24 * 3600)) / 3600);
    const minutes = Math.floor((elapsed % 3600) / 60);
    const seconds = elapsed % 60;

    // Construct the formatted time string
    const formattedTime = `${weeks} weeks, ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;

    return formattedTime;
}
