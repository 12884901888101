import React from "react";
import { IUFormFooter } from "../uFormUtils";
import Button from "../../Button/Button";
import { useTranslation } from "react-i18next";
import ClickButton from "../../Widgets/Buttons/ClickButton";
import { cancelButton } from "../../Widgets/Buttons/Presets/buttonPresets";
import UFormConfirmationDialog from "../UFormConfirmationDialog/UFormConfirmationDialog";
import { useUFormContext } from "../Context/UFormContext";

interface IProps {
     footer: IUFormFooter;
     onConfirm(): void;
     onCancel(): void;
     onDelete?(): void;
     disableDelete?: boolean;
}

export default function UFormFooter(props: IProps) {
     const { t } = useTranslation();

     const {
          state: {
               formConditions: { equivalenceConfirmed, affirmationAllowed },
          },
     } = useUFormContext();

     return (


          <div className="uformFooter">
               {props.footer.cancelText ? (
                    !equivalenceConfirmed ? (
                         <UFormConfirmationDialog onConfirm={props.onCancel} trigger={{ trigger: cancelButton }} />
                    ) : (
                         <ClickButton button={cancelButton} onClick={(e) => props.onCancel()} />
                    )
               ) : null}
               {props.footer.discardChanges ? (
                    !equivalenceConfirmed ? (
                         <ClickButton
                              button={{
                                   title: "Shared.discardChanges",
                                   hideOverlay: true,
                                   variant: "SECONDARY",
                              }}
                              onClick={(e) => props.onCancel()}
                         />
                    ) : (
                         <ClickButton
                              button={{
                                   title: "Shared.discardChanges",
                                   hideOverlay: true,
                                   variant: "SECONDARY",
                              }}
                              disabled
                         />
                    )
               ) : null}
               <Button
                    variant="primary"
                    disabled={!affirmationAllowed}
                    onClick={(e) => {
                         if (affirmationAllowed) props.onConfirm();
                    }}
               >
                    <span>{t(props.footer.confirmText)}</span>
               </Button>
          </div>

     );
}
