import React, { useState } from "react";
import { IUFormField } from "../uFormUtils";
import "./UFormFields.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";

interface IProps {
     field: IUFormField;
     forceValue?: string | number | boolean | undefined;
     forceError?: string;
     onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

export default function UCheckbox({ field, forceValue, onItemChange }: IProps) {
     const { t } = useTranslation();

     let value = useUFormContext().state.fieldState[field.key];
     if (forceValue) value = forceValue;
     const { onFieldChange } = useUFormContext();

     return (
          <div className="fieldRowWrapper" onClick={(e) => {
               if (onItemChange) onItemChange({ [field.key]: !value }, field);
               else onFieldChange({ [field.key]: !value }, field);
          }} style={{ cursor: "pointer" }}>
               <div
                    className={`checkbox ${value ? "checked" : null}`}
                    onClick={(e) => {
                         if (onItemChange) onItemChange({ [field.key]: !value }, field);
                         else onFieldChange({ [field.key]: !value }, field);
                    }}
                    id={field.id || ''}
               >
                    {value ? <FontAwesomeIcon icon={faCheck} /> : null}
               </div>
               <div className="fieldColumnWrapper shortGap">
                    <div className="mainText">{t(field.title)}</div>
                    {field.description && <div className="secondaryText">{t(field.description)}</div>}
               </div>
          </div>
     );
}
