import { UserfulSearchBar } from "userful-chronos-common-ui/dist";
import { KeycloakGroup, KeycloakUser, UserfulGroup, UserfulUser } from "../../model/CarbonExternalModels";
import {
    useCarbonDispatch,
    useCarbonSelector,
    selectFiltersVisibility,
    selectUserGroupFilters,
    selectSearchInput,
} from "../../store/carbonStore";
import { filterUserGroups } from "../../store/filters/filtersManager";
import { FiltersActions } from "../../store/filters/filtersSlice";
import { ITable } from "../../common/components/UserfulTable/userfulTableInterface";
import { convertKeycloakGroupToTable } from "../../common/components/UserfulTable/UserfulTableConverter";
import UserfulTable from "../../common/components/UserfulTable/UserfulTable";
import { AppSliceActions } from "../../store/app/appSlice";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import GroupForm from "./UserGroupForm";
import EmptyPlaceholder from "../../common/components/placeholders/EmptyPlaceholder";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";

interface IProps {
    groups: { [id: string]: UserfulGroup };
    users: { [id: string]: UserfulUser };
}

export default function UserGroupsTable(props: IProps) {
    const dispatch = useCarbonDispatch();

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    // console.warn(props.groups);

    // Visibility
    const filtersVisibility = useCarbonSelector(selectFiltersVisibility);

    // Filtering
    const constellationFilters = useCarbonSelector(selectUserGroupFilters);
    const searchInput = useCarbonSelector(selectSearchInput);

    // Data
    const allGroups: KeycloakGroup[] = [];
    for (const property in props.groups) {
        allGroups.push(props.groups[property].group);
    }
    const allUsers: KeycloakUser[] = [];
    for (const property in props.users) {
        allUsers.push(props.users[property].user);
    }
    const filteredUserGroups = filterUserGroups(constellationFilters, searchInput.userGroups, allGroups);
    const table: ITable = convertKeycloakGroupToTable(filteredUserGroups, allUsers);

    // Functions
    const onSearchBarChange = function (input: string) {
        dispatch(FiltersActions.setSearchInput({ ...searchInput, userGroups: input }));
    };

    // Renders
    const insertBody = () => {
        if (allGroups.length > 0) {
            if (filteredUserGroups.length > 0) {
                return null;
            } else {
                return (
                    <div className="center-container">
                        <EmptyPlaceholder
                            img="./images/notFound.png"
                            title={"No Permission Sets Found"}
                            description={"Please refine your search/filter criteria and try again."}
                        />
                    </div>
                );
            }
        } else {
            return (
                <div className="center-container">
                    <EmptyPlaceholder
                        img="./images/noRoomImage.png"
                        title={"No Permission Sets Available"}
                        description={"There are no available permission sets."}
                    />
                </div>
            );
        }
    };

    return (
        <div className="tableWrapper">
            {allGroups.length > 0 && (
                <>
                    <div className="tableHeader">
                        <div className="horizontalWrapper">
                            <div className="cluster normalGap">
                                {/* <button className="iconButton">
                                    <FontAwesomeIcon icon={faBarsFilter} />
                                </button> */}
                                <UserfulSearchBar forceValue={searchInput.userGroups} onChange={onSearchBarChange} />
                            </div>
                            <div className="cluster normalGap">
                                <GroupForm type={"PRIMARY"} permissions={permissions}/>
                            </div>
                        </div>
                        {filtersVisibility.userGroups && <div className="horizontalWrapper"></div>}
                    </div>
                </>
            )}
            <div className="tableBodyWrapper">
                {
                    <div className="constellationCardsWrapper">
                        <UserfulTable
                            table={table}
                            onRowClick={(id: StringID) => {
                                dispatch(AppSliceActions.setAppView({ type: "USER_GROUP", id: id }));
                            }}
                            replace={insertBody()}
                            stretch={filteredUserGroups.length === 0}
                        />
                    </div>
                }
            </div>
        </div>
    );
}
