import React, { ReactNode, useMemo } from "react";
import { useSetState } from "react-use";
import { initIUFormState, IUFormDragAndDrop, IUFormFieldState, Position, UFormContext } from "./UFormContext";
import { IUFormField, IUFormFieldCollection, updateIUFormRef } from "../uFormUtils";
import { updateIUForm } from "../uFormUpdate";

interface IProps {
    children: ReactNode;
    affirmationAllowedByDefault?: boolean;
}

export default function UFormProvider({ children, affirmationAllowedByDefault }: IProps) {
    const [state, setState] = useSetState(initIUFormState(affirmationAllowedByDefault));

    const onAffirm = () => {
        setState({ form: updateIUFormRef(state.fieldState, state.form) });
        setState({ refresh: { type: "CONFIRM", count: state.refresh.count++ } });
    };

    const onTerminate = () => {
        setState({ refresh: { type: "CANCEL", count: state.refresh.count++ } });
    };

    const onDragAndDrop = (dragAndDrop: IUFormDragAndDrop) => {
        // console.log("onDragAndDrop");

        setState({ dragAndDrop: dragAndDrop });
    };

    const onClick = () => {
        setState({ click: !state.click });
    };

    const onFieldChange = (
        givenFieldState: IUFormFieldState,
        field?: IUFormField | IUFormFieldCollection,
        mousePosition?: Position
    ) => {
        // console.log("onFieldChange");

        const { updatedFormConditions, updatedFieldState, updatedFieldErrors } = updateIUForm(
            givenFieldState,
            state.fieldState,
            state.fieldErrors,
            state.form
        );

        if (mousePosition)
            setState({
                formConditions: updatedFormConditions,
                fieldState: updatedFieldState,
                fieldErrors: updatedFieldErrors,
                dragAndDrop: { ...state.dragAndDrop, mouseDownPosition: mousePosition },
            });
        else
            setState({
                formConditions: updatedFormConditions,
                fieldState: updatedFieldState,
                fieldErrors: updatedFieldErrors,
            });
    };

    const value = useMemo(
        () => ({
            state,
            setState,
            onTerminate,
            onAffirm,
            onFieldChange,
            onDragAndDrop,
            onClick,
        }),
        [state, setState]
    );

    return (
        <UFormContext.Provider value={value}>
            <>{children}</>
        </UFormContext.Provider>
    );
}
