"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sanitizeInitialData = void 0;
var _CdmAudio = require("../../models/cdm/CdmAudio");
var _common = require("../../models/common");
var _MapperCollectionComp = require("../../models/mapping/MapperCollectionComp");
var _MappingGroups = require("../../models/mapping/MappingGroups");
var _TextOverlay = require("../../models/mapping/TextOverlay");
var _SAMUtils = require("../../models/sam/SAMUtils");
var _uclientGiada = require("../../models/uclient/uclientGiada");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// static data may be saved in older format
var sanitizeInitialData = exports.sanitizeInitialData = function sanitizeInitialData(data) {
  if (!data) {
    return null;
  }
  var sanitized = _objectSpread(_objectSpread({}, data), {}, {
    uclients: data.uclients.map(function (i) {
      return _objectSpread(_objectSpread({}, initUClient()), i);
    }),
    mixerMappers: data.mixerMappers.map(function (i) {
      return _objectSpread(_objectSpread({}, createEmptyMixerMapper()), i);
    }),
    edgeMappers: data.edgeMappers.map(function (i) {
      return _objectSpread(_objectSpread({}, createEmptyEdgeMapper()), i);
    }),
    passiveMappers: data.passiveMappers.map(function (i) {
      return _objectSpread(_objectSpread({}, (0, _MappingGroups.initPassiveMapper)()), i);
    }),
    mapperCollections: data.mapperCollections.map(function (i) {
      return _objectSpread(_objectSpread({}, (0, _MapperCollectionComp.initMapperCollectionComp)()), i);
    }),
    sources: data.sources.map(function (i) {
      return _objectSpread(_objectSpread({}, (0, _SAMUtils.initSamSource)()), i);
    }),
    playlists: data.playlists.map(function (i) {
      return _objectSpread(_objectSpread({}, (0, _SAMUtils.initSamPlaylist)()), i);
    })
  });
  return sanitized;
};
var initUClient = function initUClient() {
  return {
    id: _common.STRINGID_NOT_SET,
    name: "U-".concat(0),
    description: "",
    ownedBy: _common.STRINGID_NOT_SET,
    activeOn: _common.STRINGID_NOT_SET,
    ownedBySystem: _common.STRINGID_NOT_SET,
    activeOnSystem: _common.STRINGID_NOT_SET,
    stationLabel: _common.STRINGID_NOT_SET,
    lockedTo: _common.STRINGID_NOT_SET,
    lockStatus: "LOCKED",
    currentResolution: {
      width: 1920,
      height: 1080
    },
    rotation: {
      radians: 0
    },
    colourCalibrationData: {
      red: 10000,
      green: 10000,
      blue: 10000,
      brightness: 32768,
      contrast: 32768
    },
    physicalData: {
      area: {
        width: {
          lengthMM: 885.5207457185421,
          unit: "IN"
        },
        height: {
          lengthMM: 498.10541946667996,
          unit: "IN"
        }
      }
    },
    stationIDVisibility: "INVISIBLE",
    latency: {
      millis: 250
    },
    lockTaskMode: true,
    managedBy: _common.STRINGID_NOT_SET,
    audioSettings: {
      audioOffset: {
        offset: 0
      },
      audioVolume: {
        level: 0,
        muted: true
      }
    },
    timeZone: {
      value: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    giadaGrid: _uclientGiada.GIADA_NOT_SET,
    provisioningType: 'MAPPING',
    transientData: {
      id: _common.STRINGID_NOT_SET,
      name: "U-".concat(0),
      location: "",
      origin: _common.STRINGID_NOT_SET,
      hardwareData: {
        modelName: "rk3399-all",
        serialNumber: "00E04C023DA8",
        manufacturer: "rockchip",
        firmwareVersion: "WB-BOX-WT399TR110-UF001-K-210708",
        platformName: "Android 7.1.2",
        appVersion: {
          version: "3.1.0.19"
        }
      },
      networkData: {
        addressInfo: {
          dhcpEnabled: true,
          ipAddress: {
            value: "192.168.134.200"
          },
          mask: {
            value: "255.255.255.0"
          }
        },
        gatewayAddress: {
          value: ""
        },
        macAddress: {
          value: "00:e0:4c:02:3d:a8"
        }
      },
      systemInfo: {
        systemID: _common.STRINGID_NOT_SET,
        previousSystemID: _common.STRINGID_NOT_SET,
        hostName: _common.NOT_SET_VALUE
      },
      connectionStatus: {
        connected: true,
        wiredConnection: true,
        hasNTP: true,
        connectingToHost: true,
        connectionStatus: "CONNECTED"
      },
      pairingCode: {
        value: ""
      },
      deviceResolution: {
        width: 1920,
        height: 1080
      },
      displayData: {
        id: {
          value: "seat-userful-uclient-android-00E04C023DA8"
        },
        displayID: {
          value: ":2"
        },
        displayResolutionData: {
          effective: {
            width: -1,
            height: -1
          },
          "native": {
            width: 1920,
            height: 1080
          },
          current: {
            width: 1920,
            height: 1080
          },
          availableResolutions: [{
            width: 1920,
            height: 1080
          }, {
            width: 1280,
            height: 720
          }, {
            width: 854,
            height: 480
          }]
        },
        displayColourData: {
          colourDepth: {
            colourDepth: 24
          },
          availableColourDepths: [{
            colourDepth: 24
          }],
          currentColourCalibrationData: {
            red: 10000,
            green: 10000,
            blue: 10000,
            brightness: 32768,
            contrast: 32768
          }
        },
        displayMonitorData: {
          monitorDiagonal: {
            lengthMM: 0,
            unit: "NONE"
          },
          monitorTypeString: " "
        }
      }
    }
  };
};
var createEmptyMixerMapper = function createEmptyMixerMapper() {
  return _objectSpread(_objectSpread({}, (0, _MappingGroups.initMappingGroup)()), {}, {
    gpuID: _MappingGroups.GPUID_NOT_SET,
    groups: [],
    textOverlay: _objectSpread({}, _TextOverlay.TEXTOVERLAY_DEFAULT),
    createdTime: 0,
    updatedTime: 0,
    sceneID: _common.STRINGID_NOT_SET,
    insets: [],
    destinations: [],
    streams: [],
    mixerPlayerActivationStatus: 'ACTIVE',
    origin: _objectSpread({}, _MappingGroups.NOT_SET_PASSIVE_MAPPER_ORIGIN)
  });
};
var createEmptyEdgeMapper = function createEmptyEdgeMapper() {
  return _objectSpread(_objectSpread({}, (0, _MappingGroups.initEdgeMappingGroup)()), {}, {
    groups: [],
    createdTime: 0,
    updatedTime: 0,
    sceneID: _common.STRINGID_NOT_SET,
    destinations: [],
    inset: createPlaceholderInsetComp(),
    origin: _objectSpread({}, _MappingGroups.NOT_SET_PASSIVE_MAPPER_ORIGIN)
  });
};
var createPlaceholderInsetComp = function createPlaceholderInsetComp() {
  return {
    id: _common.STRINGID_NOT_SET,
    name: "",
    description: "",
    sceneID: _common.STRINGID_NOT_SET,
    playList: {
      id: _common.STRINGID_NOT_SET,
      name: "",
      description: "",
      sceneID: _common.STRINGID_NOT_SET,
      createdFrom: _common.STRINGID_NOT_SET,
      behaviour: {
        playTimes: {
          times: 0
        },
        startBehaviour: 'AUTO_START'
      },
      items: [{
        id: _common.STRINGID_NOT_SET,
        name: "",
        description: "",
        createdFrom: _common.STRINGID_NOT_SET,
        behaviour: {
          activationDuration: {
            millis: 0
          },
          preStartDuration: {
            millis: 0
          },
          playDuration: {
            millis: 0
          },
          repeatConfig: {
            times: {
              times: -1
            },
            duration: {
              millis: 0
            }
          },
          persistenceType: 'KEEP_RUNNING'
        },
        source: {
          id: _common.STRINGID_NOT_SET,
          name: "",
          description: "",
          sceneID: _common.STRINGID_NOT_SET,
          createdFrom: _common.STRINGID_NOT_SET,
          type: {
            value: _common.NOT_SET_VALUE,
            name: _common.NOT_SET_VALUE
          },
          params: [],
          displayResolution: {
            width: 1920,
            height: 1080
          },
          outputType: 'VIDEO_AND_AUDIO',
          controlMode: 'NONE',
          scaling: 'INSIDE',
          sharingMode: 'ALLOW_SHARING_SOURCE'
        }
      }]
    },
    audioData: [{
      id: _common.STRINGID_NOT_SET,
      mapperID: _common.STRINGID_NOT_SET,
      sceneID: _common.STRINGID_NOT_SET,
      visibility: "VISIBLE",
      audioSettings: _CdmAudio.GWTAUDIO_MUTED
    }],
    videoData: [{
      id: _common.STRINGID_NOT_SET,
      mapperID: _common.STRINGID_NOT_SET,
      sceneID: _common.STRINGID_NOT_SET,
      position: _common.POSITION_FULL,
      visibility: "VISIBLE",
      movable: "ACTIVE",
      showSourceName: "INVISIBLE",
      textOverlay: {
        text: "",
        visibility: "INVISIBLE",
        config: {
          colour: "ORANGE",
          font: {
            fontName: "Sans"
          },
          style: {
            styleName: "Bold"
          },
          textSize: 32,
          shadow: false,
          outline: true,
          outlineColour: "BLACK",
          xpos: 0.05,
          ypos: 0.05
        }
      }
    }]
  };
};