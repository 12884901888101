import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

import {
    REQUEST_LIST_PASSPORTS, REQUEST_ADD_PASSPORT, REQUEST_UPDATE_PASSPORT_WITH_OAUTH, REQUEST_UPDATE_PASSPORT, REQUEST_REMOVE_PASSPORT,
    REQUEST_CODE_CHALLENGE, REQUEST_LIST_REPORT, REQUEST_LIST_WORKSPACE
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/powerbiMsgDefinitions';
import { PowerbiPassportHeader, PowerbiPassportUpdate, PowerbiPassportID, EmbedURLRequest } from 'userful-chronos-app-common-js/dist/models/integration/powerbi';
import './powerbiMsgReciever';

export const requestListPassports = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_PASSPORTS);
};

export const requestAddPassport = (payload: PowerbiPassportHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_PASSPORT, payload);
};

export const requestUpdatePassportWithOAuth = (payload: PowerbiPassportHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PASSPORT_WITH_OAUTH, payload);
};

export const requestUpdatePassport = (payload: PowerbiPassportUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PASSPORT, payload);
};

export const requestRemovePassport = (payload: PowerbiPassportID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_PASSPORT, payload);
};

export const requestCodeChallenge = (payload: PowerbiPassportID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CODE_CHALLENGE, payload);
};

export const requestListWorkspaces = (payload: PowerbiPassportID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_WORKSPACE, payload);
};

export const requestListReports = (payload: EmbedURLRequest) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_REPORT, payload);
};