import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ClearanceData, ClearanceLevelID, Action, ClearanceLevel, DeleteClearanceLevels } from 'userful-chronos-app-common-js/dist/models/usermgt/clearanceLevel';
import { getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import { ErrorType } from 'userful-chronos-app-common-js/dist/models/usermgt/usermgt';
import { InitState } from 'userful-chronos-app-common-js/dist/models/usermgt/usermgtForm';
import { StringID } from 'userful-chronos-app-common-js/dist/models/common';
import {
    getClearanceLevelsFromServer, addLevel, editLevel, deleteLevel, getClearanceActionsFromServer,
    getFilteredClearanceLevelsFromServer, getClearanceLevelsFromServerThatUserHasAccess
} from './msgs/levelCallers';
import _ from 'lodash';
export interface InitialStateLevel {
    levelData: ClearanceData[]
    error: ErrorType<any>;
    errorBool: boolean;
    initBool: boolean;
    loadState: InitState;
    selectedItems: StringID[];
    actions: Action[];
    clearanceLevelsThatUserHasAccess: ClearanceLevel[];
    filteredClearanceLevels: ClearanceLevel[];
}

const initialStateUser: InitialStateLevel = {
    levelData: [],
    error: null,
    errorBool: false,
    initBool: false,
    // state to show loading on save or edit
    loadState: "RENDER",
    selectedItems: [],
    actions: [],
    clearanceLevelsThatUserHasAccess: [],
    filteredClearanceLevels: []
};

export const levelSlice = createSlice({
    name: 'levelSlice',
    initialState: initialStateUser,
    reducers: {
        getClearanceLevelsFromServer: (state, action: PayloadAction<{}>) => {
            getClearanceLevelsFromServer(getGlobalStates().keycloak.token);
        },
        getClearanceActionsFromServer: (state, action: PayloadAction<{}>) => {
            getClearanceActionsFromServer(getGlobalStates().keycloak.token);
        },
        getFilteredClearanceLevelsFromServer: (state, action: PayloadAction<{}>) => {
            getFilteredClearanceLevelsFromServer(getGlobalStates().keycloak.token);
        },
        getClearanceLevelsFromServerThatUserHasAccess: (state, action: PayloadAction<{}>) => {
            getClearanceLevelsFromServerThatUserHasAccess(getGlobalStates().keycloak.token);
        },
        setClearanceLevelsData: (state, action: PayloadAction<ClearanceData[]>) => {
            // update only if there is a change
            if (!_.isEqual(state.levelData, action.payload))
                state.levelData = action.payload;
        },
        recieveClearanceActionsData: (state, action: PayloadAction<Action[]>) => {
            state.actions = action.payload;
        },
        recieveFilteredClearanceLevelsData: (state, action: PayloadAction<ClearanceLevel[]>) => {
            // update only if there is a change
            if (!_.isEqual(state.filteredClearanceLevels, action.payload))
                state.filteredClearanceLevels = action.payload;
        },
        addLevel: (state, action: PayloadAction<ClearanceData[]>) => {
            addLevel(action.payload, getGlobalStates().keycloak.token);
        },
        editLevel: (state, action: PayloadAction<ClearanceData>) => {
            editLevel(action.payload, getGlobalStates().keycloak.token);
        },
        deleteLevel: (state, action: PayloadAction<DeleteClearanceLevels>) => {
            deleteLevel(action.payload, getGlobalStates().keycloak.token);
        },
        changeError: (state, action: PayloadAction<ErrorType<any>>) => {
            state.error = action.payload;
        },
        changeErrorBool: (state, action: PayloadAction<boolean>) => {
            state.errorBool = action.payload;
        },
        setInitBool: (state, action: PayloadAction<boolean>) => {
            state.initBool = action.payload;
        },
        altLoadState: (state, action: PayloadAction<InitState>) => {
            state.loadState = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<Array<StringID>>) => {
            state.selectedItems = [...action.payload];
        },
        setAllClearanceLevels: (state, action: PayloadAction<ClearanceLevel[]>) => {
            // update only if there is a change
            if (!_.isEqual(state.clearanceLevelsThatUserHasAccess, action.payload))
                state.clearanceLevelsThatUserHasAccess = action.payload;
        }
    }
})

export const levelSliceActions = levelSlice.actions;

export default levelSlice.reducer