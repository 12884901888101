
import "./InputVolume.scss";
import React, { LegacyRef, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh, faVolumeLow, faVolumeMedium, faVolumeSlash, faCircleQuestion as faRegularCircleQuestion, faCheckCircle, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import UserfulTooltip from "../Widgets/UserfulTooltip";
import Button from "../Button/Button";

interface IProps {
    onChange?(value: { value: number, isMuted: boolean }): void;
    minValue?: number;
    maxValue?: number;
    error?: boolean | undefined;
    success?: boolean | undefined;
    filedTitle?: React.ReactNode | undefined;
    help?: React.ReactNode | undefined;
    postChildren?: React.ReactNode | undefined;
    preChildren?: React.ReactNode | undefined;
    forceValue?: string;
}

const InputVolume = (props: IProps) => {
    const [value, setValue] = useState(50);
    const [isMuted, setIsMuted] = useState(false);

    const insertTitleOrHelp = props.filedTitle || props.help;
    const insertPreChildren = props.preChildren || insertTitleOrHelp;

    useEffect(() => {
        if (props.onChange) props.onChange({ value: value, isMuted: isMuted });
    }, [value, isMuted])

    const handleChange = (event) => {
        // console.warn("handleChange: " + isMuted)
        if (!isMuted) {
            setValue(event.target.value);
        }
    };

    const handleMuteToggle = () => {
        setIsMuted(!isMuted);
    };

    let icon = faVolumeLow;
    if (isMuted) icon = faVolumeSlash;
    else if (value > 75) icon = faVolumeHigh;
    else if (value > 25) icon = faVolumeMedium;


    return (
        <>
            {/* <input type="range" className="userful-range-input-field" onChange={(e) => handleChange(e)} value={value} id={` ${props.error ? "input-field-error" : ""}${props.success ? "input-field-success" : ""}`} {...props} ref={ref} /> */}
            <div className="input-field-main-wrapper">
                {insertPreChildren && <div className={`input-field-header ${props.error ? "input-field-error" : ""}${props.success ? "input-field-success" : ""}`}>
                    {insertTitleOrHelp && <div className={`input-field-title-container`}>
                        {props.filedTitle && <p>{props.filedTitle}</p>}{props.help && <UserfulTooltip title={props.help}><FontAwesomeIcon icon={faRegularCircleQuestion} /></UserfulTooltip>}</div>}
                    {props.preChildren}
                </div>}
                <div className="userful-range-input-wrapper">
                    <div className="userful-range-input-container">
                        <input type="range" className="userful-range-input-field" onChange={(e) => handleChange(e)} value={value} id={` ${props.error ? "input-field-error" : ""}${props.success ? "input-field-success" : ""}`} />
                    </div>
                    <Button variant="secondary" iconOnly onClick={handleMuteToggle}><FontAwesomeIcon icon={icon} /></Button>
                </div>
                {
                    props.postChildren && <div className={`input-field-container ${props.error ? "input-field-error" : ""}${props.success ? "input-field-success" : ""}`}>
                        {props.error && <FontAwesomeIcon icon={faCircleQuestion} />}
                        {props.success && <FontAwesomeIcon icon={faCheckCircle} />}
                        {props.postChildren}
                    </div>
                }</div>
        </>
    );
}

export default InputVolume;