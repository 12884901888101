import { apiPost, getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import { ServiceReply, UIReply } from 'userful-chronos-app-common-js/dist/models/common';
import { HttpsSettings } from 'userful-chronos-app-common-js/dist/models/config/httpsSettings';
import { AppStatus } from 'userful-chronos-app-common-js/dist/models/postLoginData';
import { getRootPath } from "userful-chronos-app-common-js/dist/routing";
import { pushErrorToMessageRegistery } from "userful-chronos-app-common-js/dist/message/messageRegistery";

export const initHttpsSettings = (appStatus?: AppStatus): HttpsSettings => {
    const frontEndUrl = getGlobalStates().host + getRootPath();
    const enableSSL = appStatus ? appStatus.appCertStatus.certStatus.sslEnabled : frontEndUrl.toLowerCase().startsWith('https');
    const selfSigned = appStatus ? appStatus.appCertStatus.certStatus.selfSigned : false;
    return {
        key: appStatus?.appCertStatus.certStatus.key || '',
        cert: appStatus?.appCertStatus.certStatus.cert || '',
        enableSSL,
        frontEndUrl,
        selfSigned,
        organization: appStatus?.keycloakStatus.organization || '',
    }
}

const UPDATE_SETTINGS_URI = '/init/setup/auth/update';
const INITIAL_SETUP_URI = '/init/setup/auth/setup';

export interface HttpsSettingsError {
    enableSSL?: string;
    frontEndUrl?: string;
    cert?: string;
    key?: string;
}


export const sanitizeHttpsSettings = (newSettings: HttpsSettings): HttpsSettings => {
    if (!newSettings.enableSSL) {
        return {
            ...newSettings,
            frontEndUrl: newSettings.frontEndUrl.replace('https://', 'http://'),
        }
    } else {
        const url = newSettings.frontEndUrl.replace('http://', 'https://');
        return {
            ...newSettings,
            frontEndUrl: url.startsWith('https://') ? url : `https://${url}`,
        }
    }
}
const IP_TEST = /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;

export const getHttpsErrors = (newSettings: HttpsSettings): HttpsSettingsError => {
    if (!newSettings.enableSSL) {
        return {};
    } else {
        let urlError = '';
        try {
            const url = new URL(newSettings.frontEndUrl);
            if (IP_TEST.test(url.hostname) || url.hostname.toLowerCase() === "localhost") {
                urlError = "CommonUI.Config.Https.urlError";
            }
        } catch (err) {
            urlError = "CommonUI.Config.Https.urlMissing";
        }
        return {
            frontEndUrl: urlError,
            cert: !newSettings.selfSigned && !newSettings.cert ? 'CommonUI.Config.Https.certMissing' : '',
            key: !newSettings.selfSigned && !newSettings.key ? 'CommonUI.Config.Https.keyMissing' : '',
        }
    }
}

// connection is immediately cut off after sending the https settings, so this evil is necessary ...
const redirectToNewURLAfterTimeout = (url: string) => {
    setTimeout(() => {
        window.location.replace(url);
    }, 2000);
}
const redirectToNewURLAfterTimeoutOnError = (url: string) => {
    setTimeout(() => {
        window.location.replace(url);
    }, 5000);
}

export const applyHttpsSettingsToServer = (settings: HttpsSettings, initialSetupToken: string) => {
    if (initialSetupToken) {
        apiPost(getGlobalStates().host + INITIAL_SETUP_URI, settings, {
            headers: {
                'Authorization': `Bearer ${initialSetupToken}`,
            },
        }).then((data) => {
            const result = data.data as ServiceReply<any>;
            if (result.severity !== 'SUCCESS') {
                console.warn('FAIL!', result);
                pushErrorToMessageRegistery({
                    ...result,
                    msg: result.unlocalizedMsg,
                    success: false,
                } as unknown as UIReply<any>);
            } else {
                console.log('Redirecting to new url ...');
                redirectToNewURLAfterTimeout(settings.frontEndUrl);
            }
        }).catch((e) => {
            console.warn('Redirecting to new url ...', e);
            redirectToNewURLAfterTimeoutOnError(settings.frontEndUrl);
        });
        return;
    }

    apiPost(getGlobalStates().host + UPDATE_SETTINGS_URI, {
        ...settings,
        cert: settings.cert,
        key: settings.key
    }, {
        headers: {
            'Authorization': `Bearer ${getGlobalStates().keycloak.token}`,
        },
    }).then(() => {
        console.log('Redirecting to new url ...');
        redirectToNewURLAfterTimeout(settings.frontEndUrl);
    }).catch(() => {
        console.log('Redirecting to new url ...');
        redirectToNewURLAfterTimeoutOnError(settings.frontEndUrl);
    });
}