import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './uploadFile.scss';
import { apiPost, getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { toast } from "react-toastify";
import UploadFileProgress from "./UploadFileProgress";
import { initSamFileUploadMetaData } from "userful-chronos-app-common-js/dist/models/sam/SAMUtils";
import { faArrowUpFromLine } from "@fortawesome/pro-light-svg-icons";
import useBackgroundUtils from "../BackgourndUtils";
import { useTranslation } from 'react-i18next';
import { APP_IDS } from "userful-chronos-app-common-js/dist/models/common";


type Iprops = {
}

const uploadProgressOptions = {
    type: toast.TYPE.INFO,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
    newestOnTop: true,
    closeOnClick: false,
    draggable: false,
}


export default function UploadFile(props: Iprops) {
    const { t } = useTranslation();

    const UPLOAD_URL = `${getGlobalStates().host}/sam/upload`;

    const fileElmRef = useRef<HTMLInputElement>();
    const selectedFileRef = useRef<File | undefined>();
    const metaDataRef = useRef(initSamFileUploadMetaData(APP_IDS.Spaces));
    const filenameRef = useRef<string>();

    const {doesFileNameExist} = useBackgroundUtils();

    const handleBackgroundSelect = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        metaDataRef.current = {
            ...initSamFileUploadMetaData(APP_IDS.Spaces),
            name: file.name,
            description: `Uploaded on ${new Date().toLocaleString()}`,
        };
        
        filenameRef.current = file.name;
        while (doesFileNameExist(filenameRef.current)) {
            filenameRef.current = "new_" + filenameRef.current;
        } 
        selectedFileRef.current = file;

        if (fileElmRef.current) {
            fileElmRef.current.value = "";
        }
        upload();

    };

    const upload = () => {
        const filename = filenameRef.current;
        const formData = new FormData();
        formData.append('file', selectedFileRef.current);
        formData.append('fileName', filename);
        formData.append('metadata', JSON.stringify(metaDataRef.current));
        const toastId = toast(<UploadFileProgress progress={0} filename={filename} />, { ...uploadProgressOptions, autoClose: false });
        apiPost(UPLOAD_URL, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${getGlobalStates().keycloak.token}`,
            },
            onUploadProgress: (progressEvent) => {
                const progress = (Math.floor(progressEvent.loaded / progressEvent.total * 100));
                toast.update(toastId, {
                    render: <UploadFileProgress progress={progress} filename={filename} />
                });
            },
        }).then(response => {
            if (response.status < 300) {
                toast.update(toastId, {
                    type: toast.TYPE.SUCCESS,
                    render: t('Sources&Destinations.Sources.UploadFile.successMsg', { filename })
                });
            } else {
                toast.update(toastId, {
                    type: toast.TYPE.ERROR,
                    render: t('Sources&Destinations.Sources.UploadFile.failMsg', { filename })
                });
            }
        }).catch(err => {
            toast.update(toastId, {
                type: toast.TYPE.ERROR,
                render: `${t('SapphireApp.CreateMeetingRoom.uploadError')} ${filenameRef.current}: ${err.message}`
            });
        })

    };

    return (
        <>
            <div className='bgImgOverlay'>
                <label className='bgImgLabel'>
                    <div className='iconWrapper'>
                        <FontAwesomeIcon icon={faArrowUpFromLine} />
                    </div>
                    <div className='text'>{t('SapphireApp.CreateMeetingRoom.BackgroundButton')}</div>
                </label>
                <input
                    ref={fileElmRef}
                    type="file"
                    id="myfile"
                    name="backgroundImg"
                    className='bgImgUpload'
                    title=" "
                    onChange={handleBackgroundSelect}
                />
            </div>

        </>
    );
}