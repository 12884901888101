import { faCheck, faMinus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { KeycloakGroup, KeycloakUser } from "../../../model/CarbonExternalModels";
import { selectUsers, useCarbonSelector } from "../../../store/carbonStore";
import { UserfulSearchBar } from "userful-chronos-common-ui/dist";
import { useState } from "react";
import { UserPermission } from "../../../model/CarbonPermissionModels";

interface IProps {
    group: KeycloakGroup;
    onFieldChange(data): void;
    permissions: UserPermission;
}

export default function UserGroupUsers({ group, onFieldChange, permissions }: IProps) {
    const [searchValue, setSearchValue] = useState("");

    const canChange = group.editable && permissions.isUserCreator;

    const users = useCarbonSelector(selectUsers);
    const allUsers: KeycloakUser[] = [];
    for (const property in users) {
        allUsers.push(users[property].user);
    }

    const selectAll = group.members.length === allUsers.length;
    const atLeastOneSelected = group.members.length > 0;

    const onSearchBarChange = function (input: string) {
        setSearchValue(input);
    };

    const getFilteredUsers = function (allUsers: KeycloakUser[], input: string) {
        const tempUsers: KeycloakUser[] = [];

        for (let i = 0; i < allUsers.length; i++) {
            const name = allUsers[i].userProfile.userName;

            if (name.toLocaleLowerCase().includes(input.toLocaleLowerCase())) tempUsers.push(allUsers[i]);
        }

        return tempUsers;
    };

    const filteredUsers: KeycloakUser[] = getFilteredUsers(allUsers, searchValue);

    const toggleSelectAll = (e) => {
        const ids: StringID[] = [];

        if (!atLeastOneSelected) {
            for (let i = 0; i < allUsers.length; i++) {
                ids.push(allUsers[i].id);
            }
        }
        if (canChange) onFieldChange({ members: ids });
    };

    const toggleSelect = (id: StringID) => {
        const ids: StringID[] = group.members.map((i) => i);

        const foundIndex = ids.findIndex((i) => i.value === id.value);

        if (foundIndex >= 0) {
            ids.splice(foundIndex, 1);
        } else {
            ids.push(id);
        }

        if (canChange) onFieldChange({ members: ids });
    };

    return (
        <div className="userfulFormBody">
            <div className="userfulFormHeader">
                <div className="userfulFormRow">
                    <h1 className="userfulFormHeaderTitle">User List</h1>
                </div>
                <div className="userfulFormRow">
                    <h3 className="userfulFormHeaderDescription">
                        A list of all registered users that can be added to this permissions set.
                    </h3>
                </div>
            </div>
            <div className="container longGap">
                <UserfulSearchBar forceValue={searchValue} onChange={onSearchBarChange} />
                <div
                    className={`selectAllUsers  ${canChange ? "" : "disabled"}`}
                    onClick={toggleSelectAll}
                    style={{ cursor: "pointer" }}
                >
                    <div
                        className={`checkbox ${selectAll || atLeastOneSelected ? "checked" : null}  ${
                            canChange ? "" : "disabled"
                        }`}
                    >
                        {selectAll ? <FontAwesomeIcon icon={faCheck} /> : null}
                        {atLeastOneSelected && !selectAll ? <FontAwesomeIcon icon={faMinus} /> : null}
                    </div>
                    <div className="fieldColumnWrapper shortGap">
                        <div className="mainText">Select All</div>
                    </div>
                </div>
                <div className="usersContainer">
                    {filteredUsers.map((u) => {
                        const selected = group.members.find((user) => user.value === u.id.value);
                        // const canEdit = u.attributes

                        return (
                            <div
                                key={u.id.value}
                                className={`userContainer ${selected ? "userContainerActive" : ""}  ${
                                    canChange ? "" : "disabled"
                                }`}
                                onClick={(e) => {
                                    toggleSelect(u.id);
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <div
                                    className={`checkbox ${selected ? "checked" : null}  ${
                                        canChange ? "" : "disabled"
                                    }`}
                                >
                                    {selected ? <FontAwesomeIcon icon={faCheck} /> : null}
                                </div>
                                <div className="circleTag">{u.userProfile.userName[0].toUpperCase()}</div>
                                <div className="fieldColumnWrapper shortGap">
                                    <div className="mainText">{u.userProfile.userName}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
