import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

import {
    REQUEST_UCLIENT_SHUTDOWN, REQUEST_LIST_UCLIENTS, REQUEST_LIST_UCLIENTS_FOR_VE, REQUEST_UCLIENT_REBOOT, REQUEST_UPDATE_LOCK_STATUS,
    REQUEST_UCLIENT_UPDATE_APP, REQUEST_START_CONSOLE_LOG, REQUEST_STOP_CONSOLE_LOG, REQUEST_START_ANDROID_LOG,
    REQUEST_STOP_ANDROID_LOG, REQUEST_EVENT_LOG, REQUEST_FIRMWARE_CONFIG, REQUEST_FIRMWARE_UPDATE, REQUEST_UCLIENT_APP_VERSION,
    REQUEST_ANDROID_LOG_FILE, REQUEST_UPDATE_SETTINGS, REQUEST_UPDATE_RESOLUTION, REQUEST_GET_UCLIENT_PROTOCOL_DEFAULTS,
    REQUEST_SET_UCLIENT_PROTOCOL_DEFAULTS, REQUEST_UPDATE_UCLIENT_TIMEZONE,
    REQUEST_UPDATE_UCLIENT_LOCK_TASK_MODE, REQUEST_SET_UCLIENT_LED_RESOLUTION,
    REQUEST_UNSET_UCLIENT_LED_RESOLUTION, REQUEST_POWER_ON_DISPLAY, REQUEST_POWER_OFF_DISPLAY, REQUEST_UCLIENT_STORAGE_DATA, REQUEST_UCLIENT_FILES_DATA, REQUEST_UCLIENT_CLEAN_STORAGE, REQUEST_UCLIENT_REMOVE_FILE, REQUEST_UPDATE_UCLIENT_GIADA_GRID, REQUEST_UPDATE_UCLIENT_PROVISIONING_TYPE
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/UClientsMsgDefinations";
import { UClientSettings } from "userful-chronos-app-common-js/dist/models/uclient-setting";
import { Resolution } from "userful-chronos-app-common-js/dist/models/display";
import { SinkProtocolEncodingData } from 'userful-chronos-app-common-js/dist/models/mapping/MappingGroups';
import { UClientGiadaGrid } from "userful-chronos-app-common-js/dist/models/uclient/uclientGiada";
import { UClientStorageFileMsg } from "userful-chronos-app-common-js/dist/models/uclient/uclientStorage";
import { UClientProvisioningType } from "userful-chronos-app-common-js/dist/models/uclient";


export const requestListUC = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_UCLIENTS);
};

export const requestListUCForVE = (veID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_UCLIENTS_FOR_VE, veID);
};

export const requestResetUC = (seatID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UCLIENT_REBOOT, seatID);
};

export const requestPowerOnUCDisplay = (seatID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_POWER_ON_DISPLAY, seatID);
};

export const requestPowerOffUCDisplay = (seatID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_POWER_OFF_DISPLAY, seatID);
};

export const requestUpdateUCResolution = (seatID: StringID, resolution: Resolution, isCustom: boolean) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_RESOLUTION, { id: seatID, resolution });
    if (isCustom) {
        getGlobalStates().eventBus.sendMsg(REQUEST_SET_UCLIENT_LED_RESOLUTION, { id: seatID, resolution });
    } else {
        getGlobalStates().eventBus.sendMsg(REQUEST_UNSET_UCLIENT_LED_RESOLUTION, seatID);
    }
};


export const requestLockUC = (seatID: StringID, veID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_LOCK_STATUS, {
        id: seatID,
        lockStatus: "LOCKED",
        lockedTo: veID
    });
}

export const requestUnlockUC = (seatID: StringID, veID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_LOCK_STATUS, {
        id: seatID,
        lockStatus: "UNLOCKED",
        lockedTo: veID
    });
};

export const requestUClientShutdown = (seatID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UCLIENT_SHUTDOWN, seatID);
}

export const requestUClientUpdateApp = (seatID: StringID) => {

    getGlobalStates().eventBus.sendMsg(REQUEST_UCLIENT_UPDATE_APP, seatID);
}

export const requestUClientStartConsoleLog = (seatID: StringID, lastLines: number) => {

    getGlobalStates().eventBus.sendMsg(REQUEST_START_CONSOLE_LOG, {
        seatID: seatID,
        lastLines: lastLines
    });
}

export const requestUClientStopConsoleLog = (seatID: StringID) => {

    getGlobalStates().eventBus.sendMsg(REQUEST_STOP_CONSOLE_LOG, seatID);
}

export const requestUClientStartAndroidLog = (seatID: StringID, tag: string, logLevel: string, lastLines: number) => {

    getGlobalStates().eventBus.sendMsg(REQUEST_START_ANDROID_LOG, {
        seatID: seatID,
        tag: tag,
        logLevel: logLevel,
        lastLines: lastLines
    });
}

export const requestUClientStopAndroidLog = (seatID: StringID) => {

    getGlobalStates().eventBus.sendMsg(REQUEST_STOP_ANDROID_LOG, seatID);
}


export const requestUClientEventLog = (seatID: StringID, fromDate: Date, toDate: Date) => {

    getGlobalStates().eventBus.sendMsg(REQUEST_EVENT_LOG, {
        seatID,
        fromDate,
        toDate
    });
}

export const requestFirmwareUpdate = (seatIDs: StringID[]) => {

    getGlobalStates().eventBus.sendMsg(REQUEST_FIRMWARE_UPDATE, { seats: seatIDs });
}

export const requestFirmwareConfig = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_FIRMWARE_CONFIG);
};

export const requestRequiredAPPVersion = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UCLIENT_APP_VERSION);
};

export const requestAndroidLogFile = (seatID: StringID) => {

    getGlobalStates().eventBus.sendMsg(REQUEST_ANDROID_LOG_FILE, seatID);
}
export const requestUpdateUClientSettings = (seatID: StringID, settings: UClientSettings) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_SETTINGS, { seatID, settings });
};

export const requestGetUClientProtocolDefault = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_GET_UCLIENT_PROTOCOL_DEFAULTS, {});
}

export const requestSetUClientProtocolDefault = (data: SinkProtocolEncodingData) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_UCLIENT_PROTOCOL_DEFAULTS, data);

}
export const requestUpdateUClientLockTaskMode = (seatID: StringID, lockTaskMode: boolean) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_UCLIENT_LOCK_TASK_MODE, { id: seatID, lockTaskMode, });
};

export const requestUpdateUClientTimeZone = (seatID: StringID, timezone: string) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_UCLIENT_TIMEZONE, { id: seatID, timeZone: { value: timezone }, });
};

export const requestUpdateUClientGiadaGrid = (seatID: StringID, update: UClientGiadaGrid) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_UCLIENT_GIADA_GRID, { id: seatID, update, });
}

export const requestUpdateUClientProvisioningType = (seatID: StringID, type: UClientProvisioningType) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_UCLIENT_PROVISIONING_TYPE, { id: seatID, type, });
};

export const requestStorageData = (seatID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UCLIENT_STORAGE_DATA, seatID);
}
export const requestCleanStorage = (seatID: StringID, deviceName: string) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UCLIENT_CLEAN_STORAGE, {
        id: seatID,
        deviceName,
    });
}