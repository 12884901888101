"use strict";

var _msgTypes = require("../../../models/layouts/messages/msgTypes");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _mapperLayout = require("../../functions/mapperLayout");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_LIST_MAPPER_LAYOUTS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.RESPONSE_LIST_MAPPER_LAYOUTS, (0, _mapperLayout.getStandaloneMapperLayout)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_CREATE_MAPPER_LAYOUT, function (payload) {
  var result = (0, _mapperLayout.createLayout)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.RESPONSE_CREATE_MAPPER_LAYOUT, result, !result && "Failed to create layout");
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_REMOVE_MAPPER_LAYOUT, function (payload) {
  var result = (0, _mapperLayout.removeLayout)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.RESPONSE_REMOVE_MAPPER_LAYOUT, result, !result && "Failed to remove layout");
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_HEADER, function (payload) {
  var result = (0, _mapperLayout.updateLayoutHeader)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.RESPONSE_UPDATE_MAPPER_LAYOUT_HEADER, result, !result && "Failed to update layout header");
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_UPDATE_MAPPER_LAYOUT_GEOMETRY, function (payload) {
  var result = (0, _mapperLayout.updateLayoutGeometry)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.RESPONSE_UPDATE_MAPPER_LAYOUT_GEOMETRY, result, !result && "Failed to update layout geometry");
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_APPLY_MAPPER_LAYOUT, function (payload) {
  var result = (0, _mapperLayout.applyLayoutToOriginalMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.RESPONSE_APPLY_MAPPER_LAYOUT, result, !result && "Failed to apply layout", 2000);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_msgTypes.REQUEST_APPLY_MAPPER_LAYOUT_TO_OTHER_MAPPER, function (payload) {
  var result = (0, _mapperLayout.applyLayoutToMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_msgTypes.RESPONSE_APPLY_MAPPER_LAYOUT, result, !result && "Failed to apply layout", 2000);
});