import React, { useEffect, useState } from "react";
import { IUFormContentGroup } from "../uFormUtils";
import UFormBlock from "../UFormBlock/UFormBlock";

interface IProps {
     contentGroup: IUFormContentGroup;
}

export default function UFormContentGroup({ contentGroup }: IProps) {
     const [choice, setChoice] = useState(0);

     if (contentGroup)
          return (
               <div className="blocksWrapper">
                    {choice >= 0 &&
                         contentGroup.blocks.map((block) => {
                              if (!block) return null;
                              else if (block.hide) return null;
                              return <UFormBlock key={"block:" + block.title} block={block} />;
                         })}
               </div>
          );
     return null;
}
