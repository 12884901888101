import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

import { MapperDestinationMapping, MapperDestinationMappingsCollection } from 'userful-chronos-app-common-js/dist/models/mapping/DestinationMapping';

export interface VideoWallsState {
    destinationMappings: MapperDestinationMapping[];
    destinationMappingsCollections: MapperDestinationMappingsCollection[];
}

const initialState: VideoWallsState = {
    destinationMappings: [],
    destinationMappingsCollections: [],
};

const destinationsSlice = createSlice({
    name: "destinationsSlice",
    initialState,
    reducers: {
        setMapperDestinationMappings(state: VideoWallsState, action: PayloadAction<MapperDestinationMapping[]>) {
            state.destinationMappings = [...action.payload];
        },
        addMapperDestinationMapping(state: VideoWallsState, action: PayloadAction<MapperDestinationMapping>) {
            state.destinationMappings = [...state.destinationMappings, { ...action.payload }];
        },
        updateMapperDestinationMapping(state: VideoWallsState, action: PayloadAction<MapperDestinationMapping>) {
            const index = state.destinationMappings.findIndex((l) => l.id.value === action.payload.id.value);
            if (index >= 0) {
                state.destinationMappings = [...state.destinationMappings.slice(0, index), { ...action.payload }, ...state.destinationMappings.slice(index + 1)];
            }
        },
        removeMapperDestinationMapping(state: VideoWallsState, action: PayloadAction<StringID>) {
            state.destinationMappings = state.destinationMappings.filter(l => l.id.value !== action.payload.value);
        },

        setMapperDestinationMappingsCollections(state: VideoWallsState, action: PayloadAction<MapperDestinationMappingsCollection[]>) {
            state.destinationMappingsCollections = [...action.payload];
        },
        addMapperDestinationMappingsCollection(state: VideoWallsState, action: PayloadAction<MapperDestinationMappingsCollection>) {
            state.destinationMappingsCollections = [...state.destinationMappingsCollections, { ...action.payload }];
        },
        updateMapperDestinationMappingsCollection(state: VideoWallsState, action: PayloadAction<MapperDestinationMappingsCollection>) {
            const index = state.destinationMappingsCollections.findIndex((l) => l.id.value === action.payload.id.value);
            if (index >= 0) {
                state.destinationMappingsCollections = [...state.destinationMappingsCollections.slice(0, index), { ...action.payload }, ...state.destinationMappingsCollections.slice(index + 1)];
            }
        },
        removeMapperDestinationMappingsCollection(state: VideoWallsState, action: PayloadAction<StringID>) {
            state.destinationMappingsCollections = state.destinationMappingsCollections.filter(l => l.id.value !== action.payload.value);
        },
    },
});
export const destinationSliceActions = destinationsSlice.actions;

export default destinationsSlice.reducer;
