import { faAngleDown, faUserGroup } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { KeycloakGroup } from "../../model/CarbonExternalModels";
import { AppSliceActions } from "../../store/app/appSlice";
import { selectUserGroups, useCarbonDispatch, useCarbonSelector } from "../../store/carbonStore";
import UserGroupForm from "./UserGroupForm";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import { Button } from "userful-chronos-common-ui/dist";

interface IProps {
    group: KeycloakGroup;
}

export default function UserGroupDropdown(props: IProps) {
    const dispatch = useCarbonDispatch();

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    const groups = useCarbonSelector(selectUserGroups);
    const allGroup: KeycloakGroup[] = [];
    for (const property in groups) {
        allGroup.push(groups[property].group);
    }

    return (
        <Dropdown className="operationCenterDropdown">
            <Dropdown.Toggle bsPrefix="dropdownButtonSwitch" id="dropdownToggle" as={Button}>
                <div className="textInside">{props.group.name}</div>
                <FontAwesomeIcon icon={faAngleDown} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdownMenuSwitch" onKeyDown={(e) => e.stopPropagation()} onKeyUp={(e) => e.stopPropagation()}>
                {allGroup.length > 1 && (
                    <>
                        {allGroup.map((c) => {
                            if (c.id.value !== props.group.id.value) {
                                return (
                                    <Dropdown.Item
                                        key={c.id.value}
                                        className="dropdownItem"
                                        onClick={() => {
                                            dispatch(
                                                AppSliceActions.setAppView({
                                                    type: "USER_GROUP",
                                                    id: c.id,
                                                })
                                            );
                                        }}
                                    >
                                        <div className="icon-container">
                                            <FontAwesomeIcon className="blue" icon={faUserGroup} />
                                        </div>
                                        <div className="textInside">{c.name}</div>
                                    </Dropdown.Item>
                                );
                            }
                            return null;
                        })}
                        <hr />
                    </>
                )}
                <UserGroupForm type={"DROPDOWN"} permissions={permissions} />
            </Dropdown.Menu>
        </Dropdown>
    );
}
