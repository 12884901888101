import React from 'react';
import './FormCustomSwitch.scss';

interface IProps {
    name: string;
    description: string;
    hideDescription?: boolean;
    subStyle?: boolean;
}

function FieldHeader(props: IProps) {

    return (
        <div className="fieldHeaderDiv">
            <div className="headDiv" style={{ fontWeight: props.subStyle && '500' }}>
                {props.name}
            </div>
            {!props.hideDescription && <div className="descriptionDiv">
                {props.description}
            </div>}
        </div>
    );
}

export default FieldHeader;