import { useCallback } from "react";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { SamSourceAsset } from "userful-chronos-app-common-js/dist/models/sam/SAMAsset";
import { useSourceAssetSelector } from "userful-chronos-common-store/dist/sourceassets";
import { useMappingSelector } from "userful-chronos-common-store/dist/mapping";
import { APP_IDS } from "userful-chronos-app-common-js/dist/models/common";


export default function useBackgroundUtils() {
    const VIDEO_TYPES = ["mp4", "avi", "mov", "qt", "mts", "m2ts", "ts", "wmv", "mkv", "flv", "vob", "rmvb", "asf", "m4v", "3gp", "3g2"];
    const CONTENT_DIR_MAPPINGS = [["/var/source-content/", "/source-content/"]];
    const DEFAULT_CONTENT_URL_PATH = "/source-content/";
    
    const sapphireSources = useMappingSelector(state => state.sourceStore.sources).filter(source => source.type.value === 'Sapphire_Spaces_-_Beta');
    const sapphireAssets = useSourceAssetSelector((store) => store.assetStore.assets.filter(asset => asset.orgData.appIDs.appIDs.find(appID => appID.value === APP_IDS.Spaces)));

    const getAssetURL = useCallback((asset:SamSourceAsset) => {
        const assetFilePath:string = asset.assetData["filePath"];
        let assetURLPath = assetFilePath;
        if (assetURLPath.indexOf("/") < 0) {
            // This is just a file name.
            assetURLPath = DEFAULT_CONTENT_URL_PATH + assetURLPath;
        } else {
            for (const dirMapping of CONTENT_DIR_MAPPINGS) {
                const fileDir = dirMapping[0];
                const urlDir = dirMapping[1];
                if (assetFilePath.startsWith(fileDir)) {
                    assetURLPath = assetFilePath.replace(fileDir, urlDir);
                    break;
                }
            }
        }
        return assetURLPath;
    }, []);

    const getAssetFullURL = useCallback((asset:SamSourceAsset) => {
        return `${getGlobalStates().host}/${getAssetURL(asset)}`;
    }, []);

    const isAssetVideo = useCallback((asset:SamSourceAsset) => {
        const assetFilePath:string = asset.assetData["filePath"];
        const ext:string = assetFilePath.substring(assetFilePath.lastIndexOf(".") + 1);
        //console.log("ext=" + ext);
        if (VIDEO_TYPES.includes(ext)) {
            return true;
        }
        return false
    }, []);

    const isBackGroundInUsed = useCallback((asset:SamSourceAsset) => {
        const assetURL = getAssetURL(asset);
        for (const sapphireSource of sapphireSources) {
            const background = sapphireSource.params.find(parm => parm.name==="Background");
            if (background && background.value === assetURL) {
                return true;
            }
        }
        return false;
    },[getAssetURL, sapphireSources]);

    const doesFileNameExist = useCallback((fileName:string) => {
        for (const asset of sapphireAssets) {
            const assetFilePath:string = asset.assetData["filePath"];
            const assetFilePathParts = assetFilePath.split("/");
            if (assetFilePathParts) {
                const assetFileName:string = assetFilePathParts[assetFilePathParts.length - 1];
                console.log("assetFileName:", assetFileName);
                if (assetFileName === fileName) {
                    return true;
                }
            }
        }
        return false;
    },[sapphireAssets]);

    return {getAssetURL, getAssetFullURL, isAssetVideo, isBackGroundInUsed, doesFileNameExist};
}