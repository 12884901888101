"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_LIST_ZERO_CLIENTS_FOR_VE = exports.RESPONSE_LIST_ZERO_CLIENTS = exports.RESPONSE_LATENCY = exports.REQUEST_UPDATE_ZERO_CLIENT_ID_VISIBILITY = exports.REQUEST_UPDATE_STATION_ID_ZERO_CLIENT = exports.REQUEST_UPDATE_ROTATION = exports.REQUEST_UPDATE_RESOLUTION = exports.REQUEST_UPDATE_PHYSICAL_DATA = exports.REQUEST_UPDATE_NETWORK_SETTINGS = exports.REQUEST_UPDATE_LOCK_STATUS = exports.REQUEST_UPDATE_IDENTITY_STATUS = exports.REQUEST_UPDATE_DETAILS = exports.REQUEST_UPDATE_COLOUR_CALIBRATION = exports.REQUEST_RESET = exports.REQUEST_REMOVE = exports.REQUEST_LIST_ZERO_CLIENTS_FOR_VE = exports.REQUEST_LIST_ZERO_CLIENTS = exports.REQUEST_LATENCY = exports.NOTIFY_ZERO_CLIENT_UPDATED = exports.NOTIFY_ZERO_CLIENT_REMOVED = exports.NOTIFY_ZERO_CLIENT_ADDED = exports.NOTIFY_UPDATE_STATION_ID_VISIBILITY = exports.NOTIFY_UPDATE_STATION_ID = exports.NOTIFY_UPDATE_ROTATION = exports.NOTIFY_UPDATE_RESOLUTION = exports.NOTIFY_UPDATE_PHYSICAL_DATA = exports.NOTIFY_UPDATE_NETWORK_SETTINGS = exports.NOTIFY_UPDATE_LOCK_STATUS = exports.NOTIFY_UPDATE_IDENTITY_STATUS = exports.NOTIFY_UPDATE_COLOUR_CALIBRATION = exports.NOTIFY_UDPATE_DETAILS = exports.NOTIFY_RESET = void 0;
var _messageModel = require("../../messageModel");
var ZERO_CLIENT = 3010;
var REQUEST_LIST_ZERO_CLIENTS = exports.REQUEST_LIST_ZERO_CLIENTS = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 0);
var REQUEST_LIST_ZERO_CLIENTS_FOR_VE = exports.REQUEST_LIST_ZERO_CLIENTS_FOR_VE = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 1);
var REQUEST_REMOVE = exports.REQUEST_REMOVE = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 2);
var REQUEST_LATENCY = exports.REQUEST_LATENCY = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 3);
var REQUEST_RESET = exports.REQUEST_RESET = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 4);
var REQUEST_UPDATE_DETAILS = exports.REQUEST_UPDATE_DETAILS = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 5);
var REQUEST_UPDATE_IDENTITY_STATUS = exports.REQUEST_UPDATE_IDENTITY_STATUS = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 6);
var REQUEST_UPDATE_LOCK_STATUS = exports.REQUEST_UPDATE_LOCK_STATUS = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 7);
var REQUEST_UPDATE_STATION_ID_ZERO_CLIENT = exports.REQUEST_UPDATE_STATION_ID_ZERO_CLIENT = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 8);
var REQUEST_UPDATE_RESOLUTION = exports.REQUEST_UPDATE_RESOLUTION = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 9);
var REQUEST_UPDATE_ROTATION = exports.REQUEST_UPDATE_ROTATION = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 10);
var REQUEST_UPDATE_COLOUR_CALIBRATION = exports.REQUEST_UPDATE_COLOUR_CALIBRATION = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 11);
var REQUEST_UPDATE_PHYSICAL_DATA = exports.REQUEST_UPDATE_PHYSICAL_DATA = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 12);
var REQUEST_UPDATE_ZERO_CLIENT_ID_VISIBILITY = exports.REQUEST_UPDATE_ZERO_CLIENT_ID_VISIBILITY = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 13);
var REQUEST_UPDATE_NETWORK_SETTINGS = exports.REQUEST_UPDATE_NETWORK_SETTINGS = new _messageModel.RequestMessageDefinition(ZERO_CLIENT, 14);
var NOTIFY_ZERO_CLIENT_ADDED = exports.NOTIFY_ZERO_CLIENT_ADDED = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 0);
var NOTIFY_ZERO_CLIENT_UPDATED = exports.NOTIFY_ZERO_CLIENT_UPDATED = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 1);
var NOTIFY_ZERO_CLIENT_REMOVED = exports.NOTIFY_ZERO_CLIENT_REMOVED = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 2);
var RESPONSE_LIST_ZERO_CLIENTS = exports.RESPONSE_LIST_ZERO_CLIENTS = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 3);
var RESPONSE_LIST_ZERO_CLIENTS_FOR_VE = exports.RESPONSE_LIST_ZERO_CLIENTS_FOR_VE = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 4);
var RESPONSE_LATENCY = exports.RESPONSE_LATENCY = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 5);
var NOTIFY_RESET = exports.NOTIFY_RESET = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 6);
var NOTIFY_UDPATE_DETAILS = exports.NOTIFY_UDPATE_DETAILS = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 7);
var NOTIFY_UPDATE_IDENTITY_STATUS = exports.NOTIFY_UPDATE_IDENTITY_STATUS = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 8);
var NOTIFY_UPDATE_LOCK_STATUS = exports.NOTIFY_UPDATE_LOCK_STATUS = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 9);
var NOTIFY_UPDATE_STATION_ID = exports.NOTIFY_UPDATE_STATION_ID = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 10);
var NOTIFY_UPDATE_RESOLUTION = exports.NOTIFY_UPDATE_RESOLUTION = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 11);
var NOTIFY_UPDATE_ROTATION = exports.NOTIFY_UPDATE_ROTATION = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 12);
var NOTIFY_UPDATE_COLOUR_CALIBRATION = exports.NOTIFY_UPDATE_COLOUR_CALIBRATION = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 13);
var NOTIFY_UPDATE_PHYSICAL_DATA = exports.NOTIFY_UPDATE_PHYSICAL_DATA = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 14);
var NOTIFY_UPDATE_STATION_ID_VISIBILITY = exports.NOTIFY_UPDATE_STATION_ID_VISIBILITY = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 15);
var NOTIFY_UPDATE_NETWORK_SETTINGS = exports.NOTIFY_UPDATE_NETWORK_SETTINGS = new _messageModel.RespondMessageDefinition(ZERO_CLIENT, 16);