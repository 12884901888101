import React, { useState, createContext, useContext } from 'react';
import { CreateModal } from './CreateModal';
import { DeleteModal } from './DeleteModal';

export const MODAL_COMPONENTS: any = {
    CREATE_MODAL: CreateModal,
    DELETE_MODAL: DeleteModal,
    // [MODAL_TYPES.UPDATE_MODAL]: UpdateModal
};

type ModalProps = {
    title: string,
    content: any,
    confirmBtn: string,
    cancelBtn: string,
    icon: string,
    backdrop: boolean,
    center: boolean
}

type GlobalModalContextType = {
    showModal: (modalType: string, modalProps?: ModalProps, onShowCallback?: Function, onHideCallback?: Function) => void;
    hideModal: () => void;
    store: any;
};

const initalState: GlobalModalContextType = {
    showModal: () => { },
    hideModal: () => { },
    store: {},
};

const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

interface Props {
    children: React.ReactNode;
  }  

export const GlobalModal: React.FC<Props> = ({ children }) => {
    const [store, setStore] = useState<{ modalType: string, modalProps: any, onShowCallback?: Function, onHideCallback?: Function }>();
    const { modalType, modalProps, onHideCallback } = store || {};

    const showModal = (modalType: string, modalProps: any = {}, onShowCallback, onHideCallback) => {
        setStore({
            ...store,
            modalType,
            modalProps,
            onShowCallback,
            onHideCallback
        });
    };

    const hideModal = () => {
        setStore({
            ...store,
            modalType: null,
            modalProps: {},
        });
        onHideCallback();
    };

    const renderComponent = () => {
        const ModalComponent = MODAL_COMPONENTS[modalType];
        if (!modalType || !ModalComponent) {
            return null;
        }
        return <ModalComponent id="global-modal" {...modalProps} />;
    };

    return (
        <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
            {renderComponent()}
            {children}
        </GlobalModalContext.Provider>
    );
};
