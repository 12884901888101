"use strict";

var _controlRoomMsgDefinitions = require("../../../message/messageTypeDefinitions/controlroom/controlRoomMsgDefinitions");
var _MappingMsgDefinations = require("../../../message/messageTypeDefinitions/mapping/MappingMsgDefinations");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _controlRoom = require("../../functions/controlRoom");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_controlRoomMsgDefinitions.SEND_CONTROL_ROOM_ADDED, function (payload) {
  var controlRoom = (0, _controlRoom.createControlRoom)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_CREATE_MAPPER, controlRoom.configData);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_controlRoomMsgDefinitions.RECEIVE_CONTROL_ROOM_ADDED, controlRoom);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_controlRoomMsgDefinitions.SEND_CONTROL_ROOM_REMOVED, function (payload) {
  var controlRoom = (0, _controlRoom.removeControlRoom)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_REMOVE_MAPPER, controlRoom.mapperID);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_controlRoomMsgDefinitions.RECEIVE_CONTROL_ROOM_REMOVED, controlRoom.id);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_controlRoomMsgDefinitions.SEND_UPDATE_INSET_VISIBILITY, function (payload) {
  var result = (0, _controlRoom.updateInsetVisibility)(payload);
  if (result) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_controlRoomMsgDefinitions.RECEIVE_UPDATE_INSET_VISIBILITY, payload);
  }
});