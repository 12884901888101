import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { initAuthenticationState, AuthenticationContext } from "userful-chronos-common-store/dist/auth";
import { useMount, useSetState } from 'react-use';
import { MessageDefinition } from "userful-chronos-app-common-js/dist/message/messageModel";
import { getGlobalStates, setGlobalStateData, reSetGlobalStateData, initPostLogin, requestInitStatus, setEventBus, setSystemID, apiPost, apiGet } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { AxiosResponse } from 'axios';
import Keycloak from 'keycloak-js';
import { ChronosEventBus2 } from "userful-chronos-app-common-js/dist/eventbus/eventbus2";
import { InitAppStatus } from "userful-chronos-app-common-js/dist/models/preLoginData";
import { REQUEST_POST_LOGIN } from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/request/login';
import { RESPOND_POST_LOGIN } from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/respond/login';
import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { AppUser } from "userful-chronos-app-common-js/dist/models/postLoginData";
import { KeycloakUser } from "userful-chronos-app-common-js/dist/models/keycloak-user";
import { userPermissions, getUserRoles } from 'userful-chronos-app-common-js/dist/authenticataion/roles';
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { redirectUriFor, ROUTE_PATH } from 'userful-chronos-app-common-js/dist/routing';
import InitLoading from "../Widgets/InitLoading/InitLoading";
import { GET_CLEARANCE_DATA, GET_CLEARANCE_ACTION, Action, ClearanceLevelID, ClearanceLevel, ClearanceData, ClearanceActionsAndClearanceData } from "userful-chronos-app-common-js/dist/models/usermgt/clearanceLevel"

const GET_AETHER_ID = "/aether/open/systemID";
const AUTHORIZE_USER = "/aether/auth/user/authorize";
const UN_AUTHORIZE_USER = "/aether/auth/user/unauthorize";

export const authUser = (token: string, keycloakUser: KeycloakUser) =>
    apiPost(`${getGlobalStates().host}${AUTHORIZE_USER}`, keycloakUser, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    })

export const unAuthUser = (token: string, keycloakUser: KeycloakUser) =>
    apiPost(`${getGlobalStates().host}${UN_AUTHORIZE_USER}`, keycloakUser, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    })

const getSystemID = () => apiGet(`${getGlobalStates().host}${GET_AETHER_ID}`);

export const loadClearanceData = (token: string) => {

    const t1 = getGlobalStates().host + GET_CLEARANCE_DATA;

    return apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
}

export const loadClearanceActions = (token: string) => {

    const t1 = getGlobalStates().host + GET_CLEARANCE_ACTION;

    return apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
}

export const checkReponseIsValid = (name: string, response: AxiosResponse): boolean => {
    if (response.status >= 400) {
        console.log(`${name} error: ${response.status}`);
        return false;
    }
    if (!response.data || response.data.severity !== "SUCCESS") {
        console.log(`${name} empty response`);
        return false;
    }
    return true;
}

interface IProps {
    children: ReactNode;
    authenticate?: boolean;
}

export const AuthenticationProvider: React.FC<IProps> = ({ children, authenticate = true }) => {
    const [showChildren, setShowChildren] = useState(!authenticate);
    const [loggingOut, setLoggingOut] = useState(false);

    const [state, setState] = useSetState({
        ...initAuthenticationState,
    });
    const tryLoginInterval = useRef(null);
    const tryLoginAtInterval = () => {
        clearInterval(tryLoginInterval.current);
        tryLoginInterval.current = setInterval(login, 3000);
    }
    const getPostLoginDataInterval = useRef(null);
    const getPostLoginDataTask = () => {
        clearInterval(getPostLoginDataInterval.current);
        getPostLoginDataInterval.current = setInterval(() => {
            sendMsg(REQUEST_POST_LOGIN, {
                clientAppName: getGlobalStates().appID.value,
            });
        }, 2000);
    }

    const pendoInitialized = useRef(false);

    useEffect(() => {
        if (showChildren && authenticate && !tryLoginInterval.current && !isUserAuthorized()) {
            authenticateUser();
        }
    }, [authenticate])

    useMount(() => {
        registerMsgHandler(RESPOND_POST_LOGIN, (result) => {
            console.debug('Received post login data');
            clearInterval(getPostLoginDataInterval.current);
            const data = JSON.parse(result);
            setState({ postLoginData: data });
            initPostLogin(data);
        });
        authenticateUser();
    });

    useEffect(() => {
        const { connected, postLoginData, canReconnect } = state;
        const authorized = isUserAuthorized();
        if (authorized) {
            clearInterval(tryLoginInterval.current);
            tryLoginInterval.current = null;
        }
        if (postLoginData && !showChildren) {
            setShowChildren(true);
        }
        if (authorized && !connected) {
            if (canReconnect) {
                connectEventbus();
            } else {
                authenticateUser();
            }
        }
    }, [state, showChildren]);


    const initPendo = (userData: AppUser, keycloak: Keycloak) => {
        if (!pendoInitialized.current) {
            // @ts-ignore
            if (!window.pendo) {
                console.warn("pendo not available!")
                return;
            }
            const whatfixUserID = `${getGlobalStates().systemID}:${userData.userName}`;
            window.sessionStorage.setItem("whatfixUserID", whatfixUserID);
            window.sessionStorage.setItem("roles", getUserRoles(keycloak).join(","));
            const pendoData = {
                visitor: {
                    id: whatfixUserID, // Required if user is logged in
                    // userEmail: keycloak.tokenParsed?.email || '',
                    // fullName: `${keycloak.tokenParsed?.given_name || ""}${keycloak.tokenParsed?.family_name? ` ${keycloak.tokenParsed?.family_name}` : "" }`,
                    roles: getUserRoles(keycloak),
                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                    platform: "Infinity",
                    platformVerion: userData.branding.appInfo.productVersion,
                    organizationName: userData.organization,
                },

                account: {
                    id: getGlobalStates().systemID,   // Highly recommended, required if using Pendo Feedback
                    // name:         // Optional
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional
                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                    platformVerion: userData.branding.appInfo.productVersion,
                },
            };
            // @ts-ignore
            window.pendo.initialize(pendoData);
            pendoInitialized.current = true;
            console.info("pendo initialized");
        }
    }

    const authenticateUser = () => {
        if (getGlobalStates().keycloak && getGlobalStates().keycloak.authenticated) {
            const keycloak = getGlobalStates().keycloak;
            console.debug(`Keycloak user = ${keycloak.tokenParsed.preferred_username}`);
            authorizeUser(keycloak);
        } else if (authenticate) {
            console.debug("authentication required");
            login();
            tryLoginAtInterval();
        }
    }

    const isUserAuthorized = () => {
        const { keycloak, userData } = state;

        if (keycloak === null || !keycloak.authenticated || userData === null) {
            return false;
        }

        if (userData.userID === null || userData.userID.value !== keycloak.tokenParsed.sub ||
            userData.userSessionID === null || userData.userSessionID !== keycloak.tokenParsed.sid) {
            return false;
        }

        return true;
    }

    const reconnect = () => {
        if (!state.keycloak || !state.keycloak.authenticated) {
            handleUserNotAuthorized();
        } else {
            setState({ connected: false });
        }
    }

    const connectEventbus = () => {
        const { userData } = state;
        console.debug("connectEventbus - userId = ", userData.userID.value + ", userSessionId = " + userData.userSessionID + ", appId = " + getGlobalStates().appID.value);
        const eventbus = new ChronosEventBus2(userData.systemID.value, userData.userID.value, userData.userSessionID);
        eventbus.get().then(ev => {
            setEventBus(ev);
            setState({
                eventbus: ev,
                connected: true,
            });
            ev.connected = true;
            ev.sendMsg(REQUEST_POST_LOGIN, {
                clientAppName: getGlobalStates().appID.value,
            });
            getPostLoginDataTask();
            ev.onClose = (e) => {
                ev.connected = false;
                setTimeout(reconnect, 500);
            };
        }).catch(e => {
            console.error("Error connecting eventbus", e);
            setTimeout(authenticateUser, 5000);
        });
    }

    const handleUserfulVersion = (versionString: string) => {
        try {
            const versionParts = versionString.split('.');
            if (versionParts.length > 0) {
                globalThis.userfulVersionMajor = Number(versionParts[0]);
            }
            if (versionParts.length === 2) {
                globalThis.userfulVersionMinor = Number(versionParts[1]);
            } else if (versionParts.length > 2) {
                globalThis.userfulVersionMinor = Number(`${versionParts[1]}.${versionParts[2]}`);
            }
            globalThis.userfulVersion = versionString;
        } catch (e) {
            console.debug("Cannot parse userful version", e);
        }
    }

    const loadClearanceDataFromServer = async (keycloak: Keycloak) => {
        try {
            const actionsResponse = await loadClearanceActions(keycloak.token);
            const clearanceDataResponse = await loadClearanceData(keycloak.token);

            const actionsIsValid = checkReponseIsValid('authorize', actionsResponse);
            const clearanceDataIsValid = checkReponseIsValid('authorize', clearanceDataResponse);

            const actions = actionsIsValid ? actionsResponse.data.arg : [];
            const clearanceData = clearanceDataIsValid ? clearanceDataResponse.data.arg : [];

            return { actions, clearanceData } as ClearanceActionsAndClearanceData;
        } catch (error) {
            console.warn("failed", error);
            return { actions: [], clearanceData: [] } as ClearanceActionsAndClearanceData;
        }
    };


    const handleUserAuthorized = (userData: AppUser, keycloak: Keycloak) => {
        clearInterval(tryLoginInterval.current);
        tryLoginInterval.current = null;
        console.debug(`Authorized user = ${userData.userName}`);
        initPendo(userData, keycloak);
        loadClearanceDataFromServer(keycloak).then((data) => {
            const { actions, clearanceData } = data as ClearanceActionsAndClearanceData;
            const userSecurityPermissions = keycloak.idTokenParsed.AccessPermission || [];
            const userSecurityDataPermissions = clearanceData.filter(d => userSecurityPermissions.includes(d.clearanceLevel.name))
            const permissions = userPermissions(userData.capabilities, userSecurityDataPermissions, keycloak);
            setGlobalStateData(userData, permissions, keycloak, actions, clearanceData);
            setState({
                keycloak: keycloak,
                canReconnect: true,
                connected: false,
                userData: userData,
                actions: actions,
                clearanceData: clearanceData,
                userPermission: permissions
            });

            handleUserfulVersion(userData.branding.appInfo.productVersion);

            console.debug(permissions);
        });
    }

    const handleUserNotAuthorized = () => {
        reSetGlobalStateData();
        setState({
            keycloak: null,
            canReconnect: true,
            connected: false,
            userData: null,
            userPermission: {},
        });

        clearInterval(tryLoginInterval.current);
        tryLoginInterval.current = null;
        setTimeout(() => authenticateUser(), 5000);
    }

    const authorizeUser = (keycloak: Keycloak) => {
        const keycloakUser = {
            userID: keycloak.tokenParsed.sub,
            userName: keycloak.tokenParsed.preferred_username,
            userSessionID: keycloak.tokenParsed.sid
        } as KeycloakUser;
        authUser(keycloak.token, keycloakUser).then((response) => {
            if (response.status >= 400) {
                console.log('Error authorizing user: ' + response.status);
                handleUserNotAuthorized();
            } else if (!response.data) {
                console.log('Error authorizing user: empty response');
                handleUserNotAuthorized();
            } else if (response.data.severity !== "SUCCESS") {
                console.log(`authorization failure`, response.data.unlocalizedMsg);
                handleUserNotAuthorized();
            } else {
                const userData = response.data.arg as AppUser;
                handleUserAuthorized(userData, keycloak);
                console.debug(`Authorized user = ${keycloak.tokenParsed.preferred_username}`);
            }
        }).catch(e => {
            console.error("Error authorizing user ", e);
            handleUserNotAuthorized();
        })
    }

    const handleKeycloakInit = (keycloak: Keycloak) => {
        if (!keycloak.authenticated) {
            handleUserNotAuthorized();
        } else {
            console.debug(`Authenticated user = ${keycloak.tokenParsed.preferred_username}`);
            authorizeUser(keycloak);
        }
    }

    const initKeycloak = (initStatus: InitAppStatus) => {
        console.debug(`initKeycloak`);
        const redirectUri = redirectUriFor(getGlobalStates().appID.value);
        const keycloakConfig = {
            url: `${getGlobalStates().host}${initStatus.keycloakAuthPath}`,
            realm: initStatus.realmName,
            clientId: initStatus.clientID,
        } as Keycloak.KeycloakConfig;
        const keycloak = new Keycloak(keycloakConfig);
        keycloak.onAuthSuccess = () => {
            console.debug("onAuthSuccess");
        }
        keycloak.onAuthRefreshSuccess = () => {
            console.debug("onAuthRefreshSuccess");
        }
        keycloak.onAuthRefreshError = () => {
            console.debug("onAuthRefreshError");
        }
        keycloak.init({
            onLoad: "login-required",
            redirectUri: redirectUri,
            enableLogging: true,
            checkLoginIframe: true,
            checkLoginIframeInterval: 5,
            flow: "hybrid"
        }).then(() => {
            handleKeycloakInit(keycloak)
        }
        ).catch(e => {
            console.error("Error initializing keycloak", e);
        });
    }

    const handleReceiveInitStatus = (response: AxiosResponse) => {
        if (response.status >= 400) {
            console.log('Error getting service-info: ' + response.status);
        } else if (!response.data) {
            console.log('Error getting service-info: empty response');
        } else if (response.data.severity !== "SUCCESS") {
            console.log(`handleReceiveInitStatus failure`, response.data.unlocalizedMsg);
        } else {
            const initStatus = response.data.arg as InitAppStatus;
            if (initStatus.setupRequired) {
                window.location.replace(ROUTE_PATH.ADMIN.SETUP);
            } else {
                console.debug(`handleReceiveInitStatus`, initStatus);
                initKeycloak(initStatus);
            }
        }
    }

    const login = () => {
        console.debug(`login`);
        if (!getGlobalStates().isReady) {
            console.log('GlobalStates not initialized: ', getGlobalStates());
        } else {
            processLogin();
        }
    };

    const processLogin = () => {
        getSystemID().then(response => {
            if (response.status >= 400) {
                console.error('Cannot login. Error getting systemID: ' + response.status);
            } else if (!response.data) {
                console.error('Cannot login. Error getting systemID: empty response');
            } else {
                const systemID = response.data as StringID;
                setSystemID(systemID.value);
                requestInitStatus().then(handleReceiveInitStatus).catch(e => {
                    console.error("Cannot login. Error retrieving service info", e);
                });
            }
        }).catch(e => {
            console.error("Cannot login. Error retrieving systemID", e);
        })
    };

    const processKeycloakLogout = () => {
        let kc = state.keycloak;
        cleanupUserStateOnKeycloakLogout();
        console.debug('Logout from keycloak');
        kc.logout({
            redirectUri: window.location.href
        }).then((success) => {
            console.debug('User successfullul un-authenticated');
            cleanupUserStateOnKeycloakLogout();
        }).catch(e => {
            console.warn("Error un-authenticating the user", e);
            cleanupUserStateOnKeycloakLogout();
        });
    };

    const cleanupUserStateOnKeycloakLogout = () => {
        reSetGlobalStateData();
        setState({
            keycloak: null,
            canReconnect: true,
            userData: null,
            userPermission: {},
        });

        clearInterval(tryLoginInterval.current);
        tryLoginInterval.current = null;
    }

    const processAetherLogout = () => {
        console.debug('Logout from aether');
        const keycloakUser = {
            userID: state.userData.userID.value,
            userName: state.userData.userName,
            userSessionID: state.userData.userSessionID
        } as KeycloakUser;
        unAuthUser(state.keycloak.token, keycloakUser).then((response) => {
            processKeycloakLogout();
        }).catch(e => {
            console.warn("Error un-authorizing the user", e);
            processKeycloakLogout();
        })
    }

    const logout = () => {
        setLoggingOut(true);
        localStorage.removeItem('authData');
        if (!state.keycloak || !state.keycloak.authenticated) {
            handleUserNotAuthorized();
        } else {
            if (isUserAuthorized()) {
                processAetherLogout();
            } else {
                processKeycloakLogout();
            }
        }
    }

    const sendMsg = (msgDef: MessageDefinition, msg?: Object | string | number | boolean) => {
        const eventbus = getGlobalStates().eventBus;
        if (!eventbus) {
            console.info('Cannot send msg due to eventbus not open');
        } else {
            eventbus.sendMsg(msgDef, msg);
        }
    }

    const value = useMemo(
        () => ({
            state,
            setState,
            login,
            logout,
            sendMsg,
        }),
        [state, setState],
    );


    return <AuthenticationContext.Provider value={value}>
        <>
            {showChildren && !loggingOut ? children : <InitLoading />}
        </>
    </AuthenticationContext.Provider>;
}
