import React, { ReactNode, useEffect } from "react";
import { Provider } from "react-redux";
import integrationStore, { IntegrationContext } from "./integrationStore";
import "./msgs/ClientMsgReceiver";
import "./msgs/HeliosMsgReceiver";
import "./msgs/OmnisMsgReceiver";
import "./msgs/LEDActionsMsgReceiver";
import { requestListControllers } from "./msgs/MsgSender";
import { requestListHeliosControllers } from "./msgs/HeliosMsgSender";
import { requestListOmnisControllers } from "./msgs/OmnisMsgSender";
import { requestListServers } from "./msgs/epicMsgSender";
import { requestListLEDAction } from "./msgs/LEDActionsMsgSender";
import { requestListPassports } from "./msgs/powerbiMsgSender";
import { requestListPassports as requestTableauPassports } from "./msgs/tableauMsgSender";
import { requestListPassports as requestListPassportsEpic } from "./msgs/epicMsgSender";
import { requestListPassports as requestListServicenowPassports} from "./msgs/servicenowMsgSender";
interface IProps {
    children: ReactNode;
}

function IntegrationProvider(props: IProps) {
    useEffect(() => {
        requestListControllers();
        requestListHeliosControllers();
        requestListOmnisControllers();
        requestListServers();
        requestListLEDAction();
        requestListPassports();
        requestTableauPassports();
        requestListPassportsEpic();
        requestListServicenowPassports();
    }, []);

    return (
        <Provider store={integrationStore} context={IntegrationContext}>
            {props.children}
        </Provider>
    );
}

export default IntegrationProvider;
