import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { UConductContainerComp, UConductContainerCompHeader, UConductViewComp, UConductViewCompHeader, UConductWorkspaceComp, UConductWorkspaceCompContainers, UConductWorkspaceCompHeader } from "userful-chronos-app-common-js/dist/models/uconduct/View";
import "./msgs/uConductViewMsgReceiver";

export interface VideoWallsState {
    uConductViews: Array<UConductViewComp>;
    uConductContainers: Array<UConductContainerComp>;
    uConductWorkspaces: Array<UConductWorkspaceComp>;
}


const initialState: VideoWallsState = {
    uConductViews: [],
    uConductContainers: [],
    uConductWorkspaces: [],
};

const uConductViewsSlice = createSlice({
    name: "uConductViewsSlice",
    initialState,
    reducers: {
        setUConductViews(state: VideoWallsState, action: PayloadAction<UConductViewComp[]>) {
            state.uConductViews = [...action.payload];
        },
        addUConductView(state: VideoWallsState, action: PayloadAction<UConductViewComp>) {
            state.uConductViews = [...state.uConductViews, { ...action.payload }];
        },
        updateUConductViewHeader(state: VideoWallsState, action: PayloadAction<UConductViewCompHeader>) {
            const updateValue = { ...action.payload };
            const existingIndex = state.uConductViews.findIndex((item) => item.id.value === updateValue.id.value);

            if (existingIndex >= 0) {
                state.uConductViews[existingIndex].name = updateValue.name;
                state.uConductViews[existingIndex].description = updateValue.description;
            }
        },
        updateUConductViewGeometry(state: VideoWallsState, action: PayloadAction<UConductViewComp>) {
            const updateValue = { ...action.payload };
            const existingIndex = state.uConductViews.findIndex((item) => item.id.value === updateValue.id.value);

            if (existingIndex >= 0) {
                state.uConductViews[existingIndex].insetIDs = updateValue.insetIDs;
            }
        },
        removeUConductView(state: VideoWallsState, action: PayloadAction<StringID>) {
            const existingIndex = state.uConductViews.findIndex((item) => item.id.value === action.payload.value);
            if (existingIndex >= 0) {
                state.uConductViews = state.uConductViews.filter((item) => item.id.value !== action.payload.value);
            }
        },
        setUConductContainers(state: VideoWallsState, action: PayloadAction<UConductContainerComp[]>) {
            state.uConductContainers = [...action.payload];
        },
        addUConductContainer(state: VideoWallsState, action: PayloadAction<UConductContainerComp>) {
            state.uConductContainers = [...state.uConductContainers, { ...action.payload }];
        },
        updateUConductContainer(state: VideoWallsState, action: PayloadAction<UConductContainerComp>) {
            const updateValue = { ...action.payload };
            const existingIndex = state.uConductContainers.findIndex((item) => item.id.value === updateValue.id.value);

            if (existingIndex >= 0) {
                state.uConductContainers[existingIndex] = updateValue;
            }
        },
        removeUConductContainer(state: VideoWallsState, action: PayloadAction<StringID>) {
            const existingIndex = state.uConductContainers.findIndex((item) => item.id.value === action.payload.value);
            if (existingIndex >= 0) {
                state.uConductContainers = state.uConductContainers.filter((item) => item.id.value !== action.payload.value);
            }
        },
        setUConductWorkspaces(state: VideoWallsState, action: PayloadAction<UConductWorkspaceComp[]>) {
            state.uConductWorkspaces = [...action.payload];
        },
        addUConductWorkspace(state: VideoWallsState, action: PayloadAction<UConductWorkspaceComp>) {
            state.uConductWorkspaces = [...state.uConductWorkspaces, { ...action.payload }];
        },
        removeUConductWorkspace(state: VideoWallsState, action: PayloadAction<StringID>) {
            const existingIndex = state.uConductWorkspaces.findIndex((item) => item.id.value === action.payload.value);
            if (existingIndex >= 0) {
                state.uConductWorkspaces = state.uConductWorkspaces.filter((item) => item.id.value !== action.payload.value);
            }
        },
        updateUConductWorkspaceHeader(state: VideoWallsState, action: PayloadAction<UConductWorkspaceCompHeader>) {
            const updateValue = { ...action.payload };
            const existingIndex = state.uConductWorkspaces.findIndex((item) => item.id.value === updateValue.id.value);

            if (existingIndex >= 0) {
                state.uConductWorkspaces[existingIndex].name = updateValue.name;
                state.uConductWorkspaces[existingIndex].description = updateValue.description;
            }
        },
        updateUConductWorkspaceContainers(state: VideoWallsState, action: PayloadAction<UConductWorkspaceCompContainers>) {
            const update = { ...action.payload };
            const foundWorkspace = state.uConductWorkspaces.find((item) => item.id.value === update.id.value);

            if (foundWorkspace) {
                for (let i = 0; i < update.addContainerIDs.length; i++) {
                    const foundItem = foundWorkspace.containerIDs.find((item) => item.value === update.addContainerIDs[i].value);

                    if (!foundItem) foundWorkspace.containerIDs.push(update.addContainerIDs[i]);
                }

                for (let i = 0; i < update.removeContainerIDs.length; i++) {
                    const foundItemIndex = foundWorkspace.containerIDs.findIndex((item) => item.value === update.removeContainerIDs[i].value);

                    if (foundItemIndex >= 0) { 
                        foundWorkspace.containerIDs.splice(foundItemIndex, 1);
                    }
                }
            }

        }
    },
});

export const uConductViewsActions = uConductViewsSlice.actions;

export default uConductViewsSlice.reducer;
