import { KeycloakGroup, KeycloakUser } from "../../model/CarbonExternalModels";

export const initKeycloakUser = (): KeycloakUser => {
    return {
        id: { value: "NOT_SET" },
        attributes: {},
        groups: [],
        lastAccess: 0,
        federationLink: "",
        updated: "",
        samlFederatedIdentityLink: "",
        requiredActions: [],
        userProfile: {
            email: "",
            enabled: false,
            firstName: "",
            lastName: "",
            userName: "",
        },
        mfaEnable: true,
    };
};

export const initKeycloakGroup = (): KeycloakGroup => {
    return {
        id: { value: "NOT_SET" },
        attributes: {},
        displayName: "",
        path: "",
        editable: true,
        members: [],
        roles: [],
        description: "",
        name: "",
    };
};

export type TUserGroupTab = "Roles" | "Users" | "Details";
