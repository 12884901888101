import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortByName } from './sourceAssetsUtils';

import { ChoppedAsset, createStatusFromChoppingRequest, SamChoppingOperationStatus, SamChoppingRequest, SamChoppingResponse } from 'userful-chronos-app-common-js/dist/models/sam/chop/SamChop';
import { StringID } from 'userful-chronos-app-common-js/dist/models/common';
import { cancelChop, requestChop } from './msgs/choppedAssetMsgSender';
import { ConfirmationToastContentStore } from '../../Container/ConfirmationToastContentStore';
import { toast } from "react-toastify";
import { stat } from 'fs';


const initialState: {
    choppedAssets: ChoppedAsset[],
    selectedChoppedAssets: StringID[],
    currentChoppingRequests: SamChoppingOperationStatus[];
    pendingRequests: SamChoppingRequest[];
    ready: boolean;
} = {
    choppedAssets: [],
    selectedChoppedAssets: [],
    currentChoppingRequests: [],
    pendingRequests: [],
    ready: false,
};

export const choppedAssetSlice = createSlice({
    name: 'choppedAssetSlice',
    initialState,
    reducers: {
        setChoppedAssests: (state, action: PayloadAction<ChoppedAsset[]>) => {
            state.ready = true;
            state.choppedAssets = sortByName(action.payload);
        },

        setCurrentChoppingRequest: (state, action: PayloadAction<SamChoppingOperationStatus[]>) => {
            state.currentChoppingRequests = [...action.payload];
            state.pendingRequests = action.payload.map(i => ({
                id: i.id,
                name: i.name,
                description: i.description,
                videoWallID: null,
                assetID: null,
                imageDuration: null,
                gpuID: null,
                tolerance: null,
            }))
        },

        setPendingRequests: (state, action: PayloadAction<SamChoppingRequest[]>) => {
            state.pendingRequests = [...action.payload];
        },

        setSelectedChoppedAssests: (state, action: PayloadAction<StringID[]>) => {
            state.ready = true;
            state.selectedChoppedAssets = [...action.payload];
        },

        addChoppedAsset: (state, action: PayloadAction<ChoppedAsset>) => {
            const idValue = action.payload.id.value;
            const existingIndex = state.choppedAssets.findIndex(item => item.id.value === idValue);
            if (existingIndex < 0) {
                state.choppedAssets = sortByName([
                    ...state.choppedAssets,
                    { ...action.payload },
                ]);
            }
        },

        updateChoppedAsset: (state, action: PayloadAction<ChoppedAsset>) => {
            const idValue = action.payload.id.value;
            const existingIndex = state.choppedAssets.findIndex(item => item.id.value === idValue);
            if (existingIndex >= 0) {
                state.choppedAssets = sortByName([
                    ...state.choppedAssets.slice(0, existingIndex),
                    { ...action.payload },
                    ...state.choppedAssets.slice(existingIndex + 1),
                ])
            }
        },

        deleteAsset: (state, action: PayloadAction<StringID>) => {
            state.choppedAssets = sortByName(state.choppedAssets.filter(item => item.id.value !== action.payload.value));
        },

        requestRemoveAsset: (state, action: PayloadAction<StringID>) => {
            // not implemented
        },


        receivedChoppingResult: (state, action: PayloadAction<SamChoppingResponse>) => {
            const foundExisting = state.currentChoppingRequests.find(i => i.id.value === action.payload.id.value);
            if (foundExisting) {
                if (!state.pendingRequests.find(i => i.id.value === action.payload.id.value)) {
                    state.pendingRequests = [
                        {
                            id: foundExisting.id,
                            name: foundExisting.name,
                            description: foundExisting.description,
                            videoWallID: null,
                            assetID: null,
                            imageDuration: null,
                            gpuID: null,
                            tolerance: null,
                        },
                        ...state.pendingRequests,
                    ]
                }
            }
            state.currentChoppingRequests = state.currentChoppingRequests.filter(item => item.id.value !== action.payload.id.value);
            const customDisplay = ConfirmationToastContentStore(action.payload.name, "Save");
            toast(customDisplay, { containerId: 'confirmationContainer' });
        },

        receivedChoppingStarted: (state, action: PayloadAction<SamChoppingRequest>) => {
            const foundIndex = state.currentChoppingRequests.findIndex(i => i.id.value === action.payload.id.value);
            if (foundIndex >= 0) {
                state.currentChoppingRequests = [
                    ...state.currentChoppingRequests.slice(0, foundIndex),
                    { ...state.currentChoppingRequests[foundIndex], activation: 'ACTIVE' },
                    ...state.currentChoppingRequests.slice(foundIndex + 1),
                ];
            } else {
                state.currentChoppingRequests = [
                    ...state.currentChoppingRequests,
                    { ...action.payload, activation: 'ACTIVE' },
                ];
            }
        },

        startChopping: (state, action: PayloadAction<SamChoppingRequest>) => {
            state.currentChoppingRequests = [
                ...state.currentChoppingRequests,
                createStatusFromChoppingRequest(action.payload),
            ]
            state.pendingRequests = [
                ...state.pendingRequests,
                {...action.payload},
            ]
            requestChop({ ...action.payload });
        },

        cancelCopping: (state, action: PayloadAction<StringID>) => {
            cancelChop({ ...action.payload });
            state.currentChoppingRequests = state.currentChoppingRequests.filter(item => item.id.value !== action.payload.value);
            state.pendingRequests = state.pendingRequests.filter(item => item.id.value !== action.payload.value);
        },

    },
})

export const choppedAssetActions = choppedAssetSlice.actions;

export default choppedAssetSlice.reducer