"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.respondStandaloneRequest = exports.respondFeatureNotAvailable = exports.registerStandaloneMsgHandler = exports.registerStandaloneAPIHandler = exports.handleReceivingStandaloneRequest = exports.handleReceivingStandaloneAPIRequest = exports.createUIReply = void 0;
var _globalStates = require("../../globalstates/globalStates");
var _messageModel = require("../../message/messageModel");
var _messageRegistery = require("../../message/messageRegistery");
var _store = require("../data/store");
;
var registry = new Map();
var apiRegistry = new Map();
var registerStandaloneMsgHandler = exports.registerStandaloneMsgHandler = function registerStandaloneMsgHandler(msgDef, handler) {
  if (!(0, _globalStates.isStandalone)()) {
    return;
  }
  if (registry.has(msgDef.toString())) {
    console.warn("!!!!!!!!! Standalone handler for ".concat(msgDef.toString(), " already exist !!!!!!!!!"));
  }
  registry.set(msgDef.toString(), handler);
};
var registerStandaloneAPIHandler = exports.registerStandaloneAPIHandler = function registerStandaloneAPIHandler(apiPath, handler) {
  if (!(0, _globalStates.isStandalone)()) {
    return;
  }
  if (apiRegistry.has(apiPath)) {
    console.warn("!!!!!!!!! Standalone API handler for ".concat(apiPath, " already exist !!!!!!!!!"));
  }
  apiRegistry.set(apiPath, handler);
};
var respondFeatureNotAvailable = exports.respondFeatureNotAvailable = function respondFeatureNotAvailable() {
  respondStandaloneRequest(new _messageModel.RespondMessageDefinition(-1, -1), null, "Not available");
};
var handleReceivingStandaloneRequest = exports.handleReceivingStandaloneRequest = function handleReceivingStandaloneRequest(msgDef, msg) {
  if (!(0, _globalStates.isStandalone)()) {
    return;
  }
  if (!registry.has(msgDef.toString())) {
    console.error("Missing standalone handler for", msgDef, msg);
    return;
  }
  (0, _store.awaitInitDataReady)().then(function () {
    console.warn("Received standalone request ", msgDef, msg);
    var handler = registry.get(msgDef.toString());
    try {
      handler(msg);
    } catch (e) {
      console.error(e);
    }
  });
};
var handleReceivingStandaloneAPIRequest = exports.handleReceivingStandaloneAPIRequest = function handleReceivingStandaloneAPIRequest(method, apiPath, msg) {
  if (!(0, _globalStates.isStandalone)()) {
    return;
  }
  if (!apiRegistry.has(apiPath)) {
    console.error("Missing standalone API handler for ".concat(method, ":"), apiPath, msg);
    return createAxiosPromise({
      error: {
        success: false,
        arg: null,
        params: [],
        children: [],
        errorCode: 1,
        moduleID: "NA",
        severity: 'FATAL',
        msg: "Feature not available in demo version",
        unlocalizedMsg: "Feature not available in demo version"
      }
    });
  }
  console.warn("Received standalone API request ", apiPath, msg);
  var handler = apiRegistry.get(apiPath);
  try {
    return (0, _store.awaitInitDataReady)().then(function () {
      return createAxiosPromise(handler(method, msg));
    });
  } catch (e) {
    console.error(e);
    return (0, _store.awaitInitDataReady)().then(function () {
      return createAxiosPromise({
        error: e
      });
    });
  }
};
var createAxiosPromise = function createAxiosPromise(msg) {
  var response = msg.error ? {
    data: msg.error,
    status: 400,
    statusText: "Failed",
    headers: {},
    config: {}
  } : {
    data: msg.response,
    status: 200,
    statusText: "success",
    headers: {},
    config: {}
  };
  return Promise.resolve(response);
};
var createUIReply = exports.createUIReply = function createUIReply(data, error) {
  return {
    success: error ? false : true,
    arg: data,
    moduleID: '',
    errorCode: 0,
    severity: error ? 'FATAL' : 'SUCCESS',
    msg: error,
    params: [],
    children: []
  };
};
var respondStandaloneRequest = exports.respondStandaloneRequest = function respondStandaloneRequest(msgDef, msg, error, delay) {
  if (!(0, _globalStates.isStandalone)()) {
    return;
  }
  var uiReply = createUIReply(msg, error);
  var payload = JSON.stringify(uiReply);
  setTimeout(function () {
    console.debug("Respond standalone request:", msgDef, payload);
    var message = new _messageModel.Message(msgDef, payload);
    (0, _messageRegistery.handleMsg)(message);
  }, delay || 1);
};