import React, { useState } from "react";
import { IUFormField } from "../uFormUtils";
import "./UFormFields.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import { generateUUID } from 'userful-chronos-app-common-js/dist/utils';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { faMinus } from '@fortawesome/pro-regular-svg-icons';

interface IProps {
    field: IUFormField;
    forceValue?: string | number | boolean | undefined;
    forceError?: string;
    onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

type SelectAllType = "FALSE" | "TRUE" | "MINUS";

export default function UCardSelector({ field, forceValue, onItemChange }: IProps) {
    const { t } = useTranslation();

    let selectAll: SelectAllType = "FALSE";
    let value = useUFormContext().state.fieldState[field.key];
    if (forceValue) { value = forceValue; }
    const { onFieldChange } = useUFormContext();

    if (value && field.cardDef && field.cardDef.multiSelect) {
        if (value.length === field.items.length)
            selectAll = "TRUE";
        else if (value.length === 0)
            selectAll = "FALSE";
        else
            selectAll = "MINUS";
    }

    const insertCheckbox = () => {
        if (field.cardDef && field.cardDef.multiSelect) {
            return (<div className="fieldRowWrapper" onClick={(e) => {

                // console.log(selectAll);
                if (selectAll === "FALSE") {
                    const values = field.items.map((item) => item.value);

                    // console.log(values);
                    if (onItemChange) onItemChange({ [field.key]: values }, field);
                    else onFieldChange({ [field.key]: values }, field);
                } else {

                    if (onItemChange) onItemChange({ [field.key]: [] }, field);
                    else onFieldChange({ [field.key]: [] }, field);
                }
            }} style={{ cursor: "pointer" }}>
                <div
                    className={`checkbox ${selectAll !== "FALSE" ? "checked" : null}`}
                >
                    {selectAll !== "FALSE" ? selectAll === "TRUE" ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faMinus} /> : null}
                </div>
                <div className="fieldColumnWrapper shortGap">
                    <div className="mainText">{t("Shared.selectAll")}</div>
                </div>
            </div>)
        }
        return null;
    }

    return (
        <div className="fieldColumnWrapper longGap" >
            {insertCheckbox()}
            <div className="fieldRowWrapper flexWrap" >

                {field.cardDef.enableUpload &&
                    <div onClick={(e) => {

                    }} key={field.title + "_" + "UPLOAD"} className={"uploadCard"}>

                    </div>
                }

                {field.items && field.items.map((item) => {
                    if (field.cardDef && field.cardDef.size === "REGULAR") {
                        return (
                            <div onClick={(e) => {
                                if (onItemChange) onItemChange({ [field.key]: item.value }, field);
                                else onFieldChange({ [field.key]: item.value }, field);
                            }} key={field.title + "_" + item.label} className={`selectionCard ${item.value === value ? "selectionCard-active" : ""}`}>
                                <div className="imageWrapper">
                                    <img src={item.img} alt={item.img} />
                                </div>
                                {field.cardDef.hideTitle ? null :
                                    <div className="textWrapper">
                                        <div className="text">{t(item.label)}</div>
                                    </div>}
                            </div>)
                    }
                    else if (field.cardDef && field.cardDef.size === "SMALL") {
                        return (
                            <OverlayTrigger
                                key={field.title + "_" + item.label}
                                placement="top"
                                overlay={<Tooltip>{t(item.label)}</Tooltip>}
                            >
                                <div onClick={(e) => {
                                    if (field.cardDef && field.cardDef.multiSelect) {
                                        const foundIndex = value.findIndex((s) => s === item.value);

                                        if (foundIndex >= 0) {
                                            value.splice(foundIndex, 1);
                                        }
                                        else {
                                            value.push(item.value);
                                        }

                                        if (onItemChange) onItemChange({ [field.key]: value }, field);
                                        else onFieldChange({ [field.key]: value }, field);
                                    }
                                    else {
                                        if (onItemChange) onItemChange({ [field.key]: item.value }, field);
                                        else onFieldChange({ [field.key]: item.value }, field);
                                    }
                                }} className={`selectionCardSmall ${field.cardDef && field.cardDef.multiSelect ? value.findIndex((s) => s === item.value) >= 0 ? "selectionCardSmall-active" : "" : item.value === value ? "selectionCardSmall-active" : ""}`}>
                                    <img src={item.img} alt={item.img} />
                                    <div className="absolutePosition">
                                        {field.cardDef && field.cardDef.multiSelect ? value.findIndex((s) => s === item.value) >= 0 ? <div
                                            className="checkbox checked"
                                        >
                                            <FontAwesomeIcon icon={faCheck} />
                                        </div> : null : null}</div>
                                </div></OverlayTrigger>)
                    }
                    else {
                        return null;
                    }
                })}
            </div>
        </div >
    );
}
