"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUserData = exports.setSystemID = exports.setKeycloak = exports.setGlobalStateData = exports.setGcmGlobalStateData = exports.setEventBus = exports.requestInitStatus = exports.reSetGlobalStateData = exports.reSetGcmGlobalStateData = exports.isUserAuthorized = exports.isStandalone = exports.isDevEnvironment = exports.initPostLogin = exports.initGlobalStates = exports.getGlobalStates = exports.getAuthAppStatus = exports.apiPut = exports.apiPost = exports.apiPatch = exports.apiGet = exports.apiDelete = exports.GET_INIT_STATUS = void 0;
var _sessionMode = require("../session/sessionMode");
var _axios = _interopRequireDefault(require("axios"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
var devHostURL = "";
var devHostSystemID = "";
var standaloneMode = false;
var DEV_HOSTS = ['localhost:3000', 'localhost:3001', 'localhost:3002', 'localhost:3003', 'localhost:3004', 'localhost:3005'];
var IS_RUNNING_IN_NODE = typeof window === 'undefined';
console.debug('Node environment = ' + IS_RUNNING_IN_NODE);
var isDevEnvironment = exports.isDevEnvironment = function isDevEnvironment() {
  return IS_RUNNING_IN_NODE || DEV_HOSTS.includes(window.location.host);
};
var isStandalone = exports.isStandalone = function isStandalone() {
  return standaloneMode;
};
try {
  if (isDevEnvironment()) {
    console.log("Loading DevServerConf");
  }
  var config = require('./DevServerConf');
  devHostURL = config.devHostURL;
  devHostSystemID = config.devHostSystemID;
} catch (_unused) {
  if (isDevEnvironment()) {
    console.debug("Failed to load DevServerConf. Please copied from the template.");
  }
}
try {
  var _config = require('./StandaloneConfig');
  standaloneMode = _config.isStandalone;
  console.info("Standalone mode = " + standaloneMode);
  if (standaloneMode) {
    Promise.resolve().then(function () {
      return _interopRequireWildcard(require('../standalone/msgHandlers/standaloneHandlerIndex'));
    }).then(function () {});
  }
} catch (_unused2) {}
;
var globalStates = {
  isReady: false,
  loggedIn: false,
  sessionID: null,
  appID: null,
  overrideAppID: null,
  eventBus: null,
  host: IS_RUNNING_IN_NODE ? "" : window.location.protocol + "//" + window.location.host,
  hostWidthoutProtocol: IS_RUNNING_IN_NODE ? "" : window.location.host,
  preLoginData: null,
  postLoginData: null,
  systemID: "",
  isDevEnvironment: false,
  userData: null,
  userPermission: null,
  gcmUserPermission: null,
  keycloak: null,
  actions: [],
  clearanceData: []
};
var initGlobalStates = exports.initGlobalStates = function initGlobalStates(appID, host, defaultAetherID, overrideAppID) {
  console.log("initGlobalStates");
  if (!globalStates.isReady) {
    globalStates.systemID = devHostSystemID || defaultAetherID || '';
    globalStates.appID = new _sessionMode.AppID(appID);
    globalStates.overrideAppID = overrideAppID;
    globalStates.sessionID = globalStates.appID.createNewSessionID();
    globalStates.isDevEnvironment = isDevEnvironment();
    if (globalStates.isDevEnvironment) {
      if (devHostURL) {
        console.log("using devHostURL=" + devHostURL);
        globalStates.host = devHostURL;
        globalStates.hostWidthoutProtocol = devHostURL.replace("http://", '').replace("https://", '');
      } else if (host) {
        console.log("using host=" + devHostURL);
        globalStates.host = host;
        globalStates.hostWidthoutProtocol = host.replace("http://", '').replace("https://", '');
      }
    }
    globalStates.isReady = true;
  }
  return globalStates;
};
var setEventBus = exports.setEventBus = function setEventBus(eventbus2) {
  globalStates.eventBus = eventbus2;
};
var getGlobalStates = exports.getGlobalStates = function getGlobalStates() {
  return _objectSpread({}, globalStates);
};
var initPostLogin = exports.initPostLogin = function initPostLogin(data) {
  globalStates.postLoginData = data;
  globalStates.loggedIn = true;
};
var setKeycloak = exports.setKeycloak = function setKeycloak(keycloak) {
  globalStates.keycloak = keycloak;
};
var setSystemID = exports.setSystemID = function setSystemID(id) {
  globalStates.systemID = id;
};
var setUserData = exports.setUserData = function setUserData(data) {
  globalStates.userData = data;
};
var reSetGlobalStateData = exports.reSetGlobalStateData = function reSetGlobalStateData() {
  globalStates.userData = null;
  globalStates.keycloak = null;
  globalStates.systemID = null;
  globalStates.userPermission = {};
};
var setGlobalStateData = exports.setGlobalStateData = function setGlobalStateData(userData, userPermission, keycloak, actions, clearanceData) {
  globalStates.userData = userData;
  globalStates.keycloak = keycloak;
  globalStates.systemID = userData.systemID.value;
  globalStates.userPermission = userPermission;
  globalStates.actions = actions;
  globalStates.clearanceData = clearanceData;
};
var reSetGcmGlobalStateData = exports.reSetGcmGlobalStateData = function reSetGcmGlobalStateData() {
  globalStates.userData = null;
  globalStates.keycloak = null;
  globalStates.systemID = null;
  globalStates.gcmUserPermission = {};
};
var setGcmGlobalStateData = exports.setGcmGlobalStateData = function setGcmGlobalStateData(userData, gcmUserPermission, keycloak) {
  globalStates.userData = userData;
  globalStates.keycloak = keycloak;
  globalStates.systemID = userData.systemID != null ? userData.systemID.value : keycloak.clientId;
  globalStates.gcmUserPermission = gcmUserPermission;
};
var isUserAuthorized = exports.isUserAuthorized = function isUserAuthorized() {
  if (isStandalone()) {
    return true;
  }
  var keycloak = globalStates.keycloak,
    userData = globalStates.userData;
  if (keycloak === null || !keycloak.authenticated || userData === null) {
    return false;
  }
  if (userData.userID === null || userData.userID.value !== keycloak.tokenParsed.sub || userData.userSessionID === null || userData.userSessionID !== keycloak.tokenParsed.sid) {
    return false;
  }
  return true;
};
var apiGet = exports.apiGet = function apiGet() {
  for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
    params[_key] = arguments[_key];
  }
  if (isStandalone()) {
    var _params$;
    var path = params[0].replace(getGlobalStates().host, '');
    var handleReceivingStandaloneAPIRequest = require('../standalone/eventbus/StandaloneMsgHandler').handleReceivingStandaloneAPIRequest;
    return handleReceivingStandaloneAPIRequest('get', path, (_params$ = params[1]) === null || _params$ === void 0 ? void 0 : _params$.data);
  }
  return _axios["default"].get.apply(_axios["default"], params);
};
var apiPost = exports.apiPost = function apiPost() {
  for (var _len2 = arguments.length, params = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    params[_key2] = arguments[_key2];
  }
  if (isStandalone()) {
    var path = params[0].replace(getGlobalStates().host, '');
    var handleReceivingStandaloneAPIRequest = require('../standalone/eventbus/StandaloneMsgHandler').handleReceivingStandaloneAPIRequest;
    return handleReceivingStandaloneAPIRequest('post', path, params[1]);
  }
  return _axios["default"].post.apply(_axios["default"], params);
};
var apiPut = exports.apiPut = function apiPut() {
  for (var _len3 = arguments.length, params = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
    params[_key3] = arguments[_key3];
  }
  if (isStandalone()) {
    var path = params[0].replace(getGlobalStates().host, '');
    var handleReceivingStandaloneAPIRequest = require('../standalone/eventbus/StandaloneMsgHandler').handleReceivingStandaloneAPIRequest;
    return handleReceivingStandaloneAPIRequest('put', path, params[1]);
  }
  return _axios["default"].put.apply(_axios["default"], params);
};
var apiDelete = exports.apiDelete = function apiDelete() {
  for (var _len4 = arguments.length, params = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
    params[_key4] = arguments[_key4];
  }
  if (isStandalone()) {
    var path = params[0].replace(getGlobalStates().host, '');
    var handleReceivingStandaloneAPIRequest = require('../standalone/eventbus/StandaloneMsgHandler').handleReceivingStandaloneAPIRequest;
    return handleReceivingStandaloneAPIRequest('delete', path, params[1]);
  }
  return _axios["default"]["delete"].apply(_axios["default"], params);
};
var apiPatch = exports.apiPatch = function apiPatch() {
  for (var _len5 = arguments.length, params = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
    params[_key5] = arguments[_key5];
  }
  if (isStandalone()) {
    var path = params[0].replace(getGlobalStates().host, '');
    var handleReceivingStandaloneAPIRequest = require('../standalone/eventbus/StandaloneMsgHandler').handleReceivingStandaloneAPIRequest;
    return handleReceivingStandaloneAPIRequest('patch', path, params[1]);
  }
  return _axios["default"].patch.apply(_axios["default"], params);
};
var GET_INIT_STATUS = exports.GET_INIT_STATUS = "/init/setup/open/status";
var requestInitStatus = exports.requestInitStatus = function requestInitStatus() {
  return apiGet("".concat(globalStates.host).concat(GET_INIT_STATUS));
};
var GET_AUTH_STATUS = "/init/setup/auth/status";
var getAuthAppStatus = exports.getAuthAppStatus = function getAuthAppStatus(token) {
  return apiGet("".concat(getGlobalStates().host).concat(GET_AUTH_STATUS), {
    headers: {
      'Authorization': "Bearer ".concat(token)
    }
  });
};