import Form from 'react-bootstrap/Form';
import './PasswordWidget.scss';
import { InputGroup } from 'react-bootstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';

interface IProps {
    title: string;
    formValue: string;
    onChange: (e: any) => void;
    keys: string;
    invalidState: boolean;
    invalidText: string;
    displayCheck: boolean;
    temporaryText?: string;
    onChangeCheck?: (e: any) => void;
    checkBool?: boolean;
    width: number;
    disabled?: boolean;
    onBlur: (e: any) => void
    id?: string;
    showHelpInfo?: boolean;
    helpText?: string;
    passwordPlaceholder: string;
}

function PasswordWidget(props: IProps) {

    const renderTooltipQuestionTwo = (props) => (
        <Tooltip id="question-tooltip" {...props} style={{ position: "absolute", left: "-50%" }}>
            {props.text}
        </Tooltip>
    );

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onSubmitForm = (event: any) => {
        event.preventDefault();
    }

    return (
        <div className='userManagementTextWidget' style={{ width: props.width }}>
            <div className='userManagementTextWidgetHeader'>
                <div className='userManagementTextWidgetHeaderOne'>
                    <div className='problemCanvasTitleOne'>
                        {props.title}
                    </div>
                    {props.showHelpInfo && <OverlayTrigger placement='top' overlay={renderTooltipQuestionTwo({ text: props.helpText })} >
                        <div>
                            <FontAwesomeIcon icon={faCircleQuestion} className='problemCanvasQuestionIconOne' />
                        </div>
                    </OverlayTrigger>}
                </div>
                {props.displayCheck && <div className="checkTemporary">
                    <Form.Check
                        aria-label="tempPassword"
                        id="tempPassword"
                        onChange={(e) => props.onChangeCheck ? props.onChangeCheck(e) : ""}
                        name="temporary"
                        checked={props.checkBool}
                        disabled={props.disabled} />
                    <div className="checkTemporaryText">{props.temporaryText}</div>
                </div>}
            </div>

            <Form className='formcontrolPasswordWidget' onSubmit={onSubmitForm}>
                <Form.Group className='form-group'>
                    <InputGroup>
                        <Form.Control
                            as="input"
                            value={props.formValue}
                            onChange={(e) => props.onChange(e)}
                            name={props.keys}
                            isInvalid={props.invalidState}
                            autoComplete="off"
                            maxLength={200}
                            id={props.id || ''}
                            width={props.width}
                            type={showPassword ? 'text' : 'password'}
                            placeholder={props.passwordPlaceholder}
                            onBlur={(e) => props.onBlur(e)} />
                        <InputGroup.Text onClick={toggleShowPassword}>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </InputGroup.Text>
                    </InputGroup>
                    {props.invalidState && <div className="invalidInputOne"> {props.invalidText}</div>}

                </Form.Group>
            </Form>

        </div >
    );
}

export default PasswordWidget;