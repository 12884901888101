import React, { useRef, useState } from 'react';
import { Form, Overlay, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import BackGroundFormGroup from './background/BackGroundFormGroup';

interface IProps {
    input: { background: string, solidBackground: string } & any;
    setInput: (input: { background?: string, solidBackground?: string }) => void;
}

export default function MeetingRoomBgSelect(props: IProps) {
    const { t } = useTranslation();

    const [useSolidBg, setUseSolidBg] = useState(!!props.input.solidBackground);
    const [showColorPicker, setShowColorPicker] = useState(false);

    const handleBgSelect = (e) => {
        props.setInput({
            solidBackground: '',
            [e.target.name]: (e.target.type === "checkbox") ? e.target.checked : e.target.value,
        });
    };

    const handleOptionChange = (value: boolean) => {
        setUseSolidBg(value);
        if (value) {
            props.setInput({ // default value
                solidBackground: props.input.solidBackground || "#234fdc",
                background: '',
            });
        } else {
            props.setInput({ // default value
                solidBackground: "",
                background: '',
            });
        }
    }

    const handleSolidBgChange = (value) => {
        props.setInput({
            background: '',
            solidBackground: value,
        });
    }

    const target = useRef(null);
    const container = useRef(null);

    return <>
        {useSolidBg && showColorPicker && <div className='colorSelection' >
            <div style={{ position: "fixed", inset: 0 }} onClick={() => setShowColorPicker(false)} />
            <SketchPicker
                color={props.input.solidBackground}
                onChangeComplete={(color) => handleSolidBgChange(color.hex)} />
        </div>}
        <Form.Group className="input-wrapper bgSelectionWrapper" controlId="formSourceMessage" id="meeting-room-upload-background-input" ref={container}>
            <div className="bgSelectionHeaderText">
                <div className="info-heading">{t('SapphireApp.CreateMeetingRoom.BackgroundHeader')}</div>
                <span className="heading2">Select an image or video to use as a placeholder while no one is sharing content.</span>
            </div>

            <div ref={target}>
                <Form.Check
                    inline
                    label="Solid Color"
                    type="radio"
                    id="meetingroom-solid-bg-select"
                    checked={useSolidBg}
                    onChange={() => handleOptionChange(true)}
                />
            </div>
            <div>
                <Form.Check
                    inline
                    label="Image/Video (jpg, png, mp4, gif)"
                    type="radio"
                    id="meetingroom-bg-select"
                    checked={!useSolidBg}
                    onChange={() => handleOptionChange(false)}
                />
            </div>
            {!useSolidBg && <BackGroundFormGroup
                name="background"
                value={props.input.background}
                onChange={handleBgSelect}
            />}
            {useSolidBg && <div className="solidBgPreview bgImgOverlay"
                style={{ background: props.input.solidBackground }}
                onClick={() => setShowColorPicker(!showColorPicker)} />}
        </Form.Group>
    </>;
}
