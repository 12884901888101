import React, { LegacyRef } from "react";
import "./Button.scss";

interface IProps {
    variant: TButtonVariant | string;
    children?: React.ReactNode | undefined;
    iconOnly?: boolean | undefined;
    active?: boolean | undefined;
}

export type TButtonVariant = "primary" | "secondary" | "danger" | "success" | "warning" | "dark" | "ghost";

// eslint-disable-next-line react/display-name
const Button = React.forwardRef((props: IProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, ref: LegacyRef<HTMLButtonElement>) => {
    // eslint-disable-next-line react/prop-types
    const className = `userful-button ${props.className || ''} ${props.variant? props.variant + "-button" : ""}${props.iconOnly? " icon-button": ""}${props.active? " userful-active-button" : ""}`
    return (
        <button {...props} className={className} ref={ref}>
            {props.children}
        </button>
    );
});
export default Button;