import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import {
    getMicrosoftDataFromServer, addMicrosoftDataToServer, updateMicrosoftDataToServer, deleteMicrosoftDataToServer, 
    syncMicrosoftDataToServer, getFailoverStatusFromServer
} from './msgs/identityProviderAPICallers';
import {
    MicrosoftIdentityProvider, UpdateMicrosoftProvider, DeleteMicrosoftProvider,
    AddMicrosoftProvider, SyncMicrosoft,
    MicrosoftProviderStatus,
    MicrosoftData
} from 'userful-chronos-app-common-js/dist/models/usermgt/identityProviders';

export interface InitialStateMicrosoft {
    microsoftData: MicrosoftData;
    loadBool: boolean;
    saveBool: boolean;
    overlay: boolean;
    failoverStatus: boolean;
}

const initialStateMicrosoft: InitialStateMicrosoft = {
    microsoftData: {microsoftProvider: []},
    loadBool: true,
    saveBool: false,
    overlay: false,
    failoverStatus: false
};

export const identityProviderSlice = createSlice({
    name: 'identityProviderSlice',
    initialState: initialStateMicrosoft,
    reducers: {
        getMicrosoftDataFromServer: (state, action: PayloadAction<{}>) => {
            getMicrosoftDataFromServer(getGlobalStates().keycloak.token);
        },
        setMicrosoftData: (state, action: PayloadAction<MicrosoftData>) => {
            state.microsoftData = action.payload;
        },
        setLoadBool: (state, action: PayloadAction<boolean>) => {
            state.loadBool = action.payload;
        },
        addMicrosoftDataToServer: (state, action: PayloadAction<AddMicrosoftProvider>) => {
            state.saveBool = true;
            addMicrosoftDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        // addMicrosoftData: (state, action: PayloadAction<MicrosoftProviderStatus>) => {
        //     const newMicrosoft= { ...action.payload };
        //     const index = state.microsoftData.microsoftProvider.findIndex((ele) => ele.microsoftProvider.id.value === newMicrosoft.microsoftProvider.id.value);
        //     if (index !== -1) {
        //         state.microsoftData[index] = newMicrosoft;
        //     }
        //     else {
        //         state.microsoftData = [...state.microsoftData, action.payload];
        //     }
        // },
        updateMicrosoftDataToServer: (state, action: PayloadAction<UpdateMicrosoftProvider>) => {
            state.saveBool = true;
            updateMicrosoftDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        deleteMicrosoftDataToServer: (state, action: PayloadAction<DeleteMicrosoftProvider>) => {
            state.saveBool = true;
            deleteMicrosoftDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        removeMicrosoftData: (state, action: PayloadAction<DeleteMicrosoftProvider>) => {
            state.microsoftData = {...state.microsoftData, microsoftProvider: state.microsoftData.microsoftProvider.filter((ele) => ele.microsoftProvider.id.value !== action.payload.id.value)};
        },
        syncMicrosoftDataToServer: (state, action: PayloadAction<SyncMicrosoft>) => {
            state.saveBool = true;
            syncMicrosoftDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        setSaveBool: (state, action: PayloadAction<boolean>) => {
            state.saveBool = action.payload;
        },
        setOverlay: (state, action: PayloadAction<boolean>) => {
            state.overlay = action.payload;
        },
        setFailoverStatus: (state, action: PayloadAction<boolean>) => {
            state.failoverStatus = action.payload;
        },
        getFailoverStatus: (state, action: PayloadAction<{}>) => {
            getFailoverStatusFromServer(getGlobalStates().keycloak.token);
        },

    }
})

export const identityProviderSliceActions = identityProviderSlice.actions;

export default identityProviderSlice.reducer;