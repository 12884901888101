import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import {
    NOTIFY_FILE_CHOPPING_OPERATION_COMPLETE,
    NOTIFY_FILE_CHOPPING_OPERATION_STARTED, RESPONSE_LIST_CHOPPED_ASSETS,
    RESPONSE_LIST_FILE_CHOPPING_OPERATIONS
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/sam/chop/SAMChopMsgDefinitions';
import {
    ChoppedAsset, SamChoppingOperationStatus,
    SamChoppingRequest, SamChoppingResponse
} from "userful-chronos-app-common-js/dist/models/sam/chop/SamChop";
import choppedAssetStore from "../choppedAssetStore";
import { choppedAssetActions } from "../choppedAssetSlice";

// 
registerMsgHandler(NOTIFY_FILE_CHOPPING_OPERATION_COMPLETE, (payload) => {
    console.debug('receive NOTIFY_FILE_CHOPPING_OPERATION_COMPLETE');
    const data = JSON.parse(payload) as SamChoppingResponse;
    choppedAssetStore.dispatch(choppedAssetActions.receivedChoppingResult(data));
})

registerMsgHandler(RESPONSE_LIST_CHOPPED_ASSETS, (payload) => {
    console.debug('receive RESPONSE_LIST_CHOPPED_ASSETS');
    const data = JSON.parse(payload) as ChoppedAsset[];
    choppedAssetStore.dispatch(choppedAssetActions.setChoppedAssests(data));
})

registerMsgHandler(RESPONSE_LIST_FILE_CHOPPING_OPERATIONS, (payload) => {
    console.debug('receive RESPONSE_LIST_FILE_CHOPPING_OPERATIONS');
    const data = JSON.parse(payload) as SamChoppingOperationStatus[];
    choppedAssetStore.dispatch(choppedAssetActions.setCurrentChoppingRequest(data));
})


registerMsgHandler(NOTIFY_FILE_CHOPPING_OPERATION_STARTED, (payload) => {
    console.debug('receive NOTIFY_FILE_CHOPPING_OPERATION_STARTED');
    const data = JSON.parse(payload) as SamChoppingRequest;
    choppedAssetStore.dispatch(choppedAssetActions.receivedChoppingStarted(data));
})
