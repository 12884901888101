import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NavBar.scss';
import { getAppFromAppID, getPreviousAppFromAppID } from './HeaderAppConfig';
import { useTranslation } from 'react-i18next';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
interface IProps {
    currentApp: string,
}

export default function NavBarTitle(props: IProps) {
    const { t } = useTranslation();
    const gotoLink = (hasLink: boolean, url: string) => {
        if (hasLink && url) {
            window.location.replace(url);
        }
    }

    const titleContainer = (app, hasLink) => {
        const icon = app.titleIcon;
        const title = app.name || t(app.title);

        return (
            <>
                <div className='userful-main-header-chevron-container'>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div className={`userful-main-header-breadcrumb-container ${hasLink ? 'active' : ''}`} onClick={() => gotoLink(hasLink, app.url)}>
                    {icon}
                    <p>{title}</p>
                </div>
            </>

        );
    }
    const prevApp = getPreviousAppFromAppID(props.currentApp);
    const app = getAppFromAppID(props.currentApp);
    return <>
        {prevApp &&
            titleContainer(prevApp, true)}
        {app ?
            titleContainer(app, false) : (<div></div>)}
    </>;
}

