"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.radianToDegree = exports.isIDNotSet = exports.getSecondsFromDuration = exports.getRectangleTopLeft = exports.getMsFromDuration = exports.getDurationFromSeconds = exports.getDurationFromMs = exports.degreeToRadian = exports.convertToTopLeftPositionPct = exports.ZORDER_NOT_SET = exports.ZORDER_MIN = exports.ZORDER_MAX = exports.ZORDER_DEFAULT = exports.STRINGID_NOT_SET = exports.QUARTER_SCALING = exports.POSITION_TOP_RIGHT_QUADRANT = exports.POSITION_TOP_LEFT_QUADRANT = exports.POSITION_FULL = exports.POSITION_CENTER_HALF_SIZE = exports.POSITION_BOTTOM_RIGHT_QUADRANT = exports.POSITION_BOTTOM_LEFT_QUADRANT = exports.NOT_SET_VALUE = exports.HALF_SCALING = exports.FULL_SCALING = exports.EMPTY_USERFUL_SECURITY_DATA = exports.APP_NAMES = exports.APP_IDS = exports.ANGLE_DEGREES_90 = exports.ANGLE_DEGREES_270 = exports.ANGLE_DEGREES_180 = exports.ANGLE_DEGREES_0 = exports.ALPHA_TRANSPARENT = exports.ALPHA_OPAQUE = exports.ALPHA_DEFAULT = void 0;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var NOT_SET_VALUE = exports.NOT_SET_VALUE = "NOT_SET";
var STRINGID_NOT_SET = exports.STRINGID_NOT_SET = {
  value: NOT_SET_VALUE
};
var isIDNotSet = exports.isIDNotSet = function isIDNotSet(value) {
  return !value || value.value === NOT_SET_VALUE;
};
var EMPTY_USERFUL_SECURITY_DATA = exports.EMPTY_USERFUL_SECURITY_DATA = function EMPTY_USERFUL_SECURITY_DATA() {
  return {
    id: STRINGID_NOT_SET,
    userID: NOT_SET_VALUE,
    userName: NOT_SET_VALUE,
    securityDataSet: false,
    clearanceLevelPermissions: [],
    userClearanceLevelPermissions: []
  };
};

// epoch seconds

var getSecondsFromDuration = exports.getSecondsFromDuration = function getSecondsFromDuration(durationValue) {
  if (!durationValue) {
    return 0;
  }
  if (isNaN(durationValue)) {
    return _moment["default"].duration(durationValue).asSeconds();
  }
  return Number(durationValue);
};
var getMsFromDuration = exports.getMsFromDuration = function getMsFromDuration(durationValue) {
  if (!durationValue) {
    return 0;
  }
  if (isNaN(durationValue)) {
    return _moment["default"].duration(durationValue).asMilliseconds();
  }
  return Number(durationValue) * 1000;
};
var getDurationFromMs = exports.getDurationFromMs = function getDurationFromMs(ms) {
  return _moment["default"].duration(Number(ms), 'milliseconds').toISOString();
};
var getDurationFromSeconds = exports.getDurationFromSeconds = function getDurationFromSeconds(s) {
  return _moment["default"].duration(Number(s), 'seconds').toISOString();
};
var degreeToRadian = exports.degreeToRadian = function degreeToRadian(value) {
  return value * Math.PI / 180;
};
var radianToDegree = exports.radianToDegree = function radianToDegree(value) {
  return value * 180 / Math.PI;
};
var ANGLE_DEGREES_0 = exports.ANGLE_DEGREES_0 = {
  radians: degreeToRadian(0.0)
};
var ANGLE_DEGREES_90 = exports.ANGLE_DEGREES_90 = {
  radians: degreeToRadian(90.0)
};
var ANGLE_DEGREES_270 = exports.ANGLE_DEGREES_270 = {
  radians: degreeToRadian(270.0)
};
var ANGLE_DEGREES_180 = exports.ANGLE_DEGREES_180 = {
  radians: degreeToRadian(180.0)
};
var getRectangleTopLeft = exports.getRectangleTopLeft = function getRectangleTopLeft(data) {
  return {
    x: data.center.x - data.size.width / 2,
    y: data.center.y - data.size.height / 2
  };
};
var HALF_SCALING = exports.HALF_SCALING = 50.0;
var FULL_SCALING = exports.FULL_SCALING = 100.0;
var QUARTER_SCALING = exports.QUARTER_SCALING = 25.0;
var ZORDER_NOT_SET = exports.ZORDER_NOT_SET = {
  value: 0
};
var ZORDER_DEFAULT = exports.ZORDER_DEFAULT = {
  value: 1
};
var ZORDER_MIN = exports.ZORDER_MIN = {
  value: Number.MIN_SAFE_INTEGER
};
var ZORDER_MAX = exports.ZORDER_MAX = {
  value: Number.MAX_SAFE_INTEGER
};
var ALPHA_OPAQUE = exports.ALPHA_OPAQUE = {
  value: 1.0
};
var ALPHA_TRANSPARENT = exports.ALPHA_TRANSPARENT = {
  value: 0.0
};
var ALPHA_DEFAULT = exports.ALPHA_DEFAULT = ALPHA_OPAQUE;
var convertToTopLeftPositionPct = exports.convertToTopLeftPositionPct = function convertToTopLeftPositionPct(centerPostion) {
  return {
    x: centerPostion.center.x - centerPostion.size.width / 2,
    y: centerPostion.center.y - centerPostion.size.height / 2
  };
};
var POSITION_FULL = exports.POSITION_FULL = {
  center: {
    x: HALF_SCALING,
    y: HALF_SCALING
  },
  size: {
    width: FULL_SCALING,
    height: FULL_SCALING
  },
  rotation: ANGLE_DEGREES_0,
  zorder: ZORDER_DEFAULT,
  alpha: ALPHA_DEFAULT
};
var POSITION_TOP_LEFT_QUADRANT = exports.POSITION_TOP_LEFT_QUADRANT = {
  center: {
    x: QUARTER_SCALING,
    y: QUARTER_SCALING
  },
  size: {
    width: HALF_SCALING,
    height: HALF_SCALING
  },
  rotation: ANGLE_DEGREES_0,
  zorder: ZORDER_DEFAULT,
  alpha: ALPHA_DEFAULT
};
var POSITION_TOP_RIGHT_QUADRANT = exports.POSITION_TOP_RIGHT_QUADRANT = {
  center: {
    x: QUARTER_SCALING + HALF_SCALING,
    y: QUARTER_SCALING
  },
  size: {
    width: HALF_SCALING,
    height: HALF_SCALING
  },
  rotation: ANGLE_DEGREES_0,
  zorder: ZORDER_DEFAULT,
  alpha: ALPHA_DEFAULT
};
var POSITION_BOTTOM_LEFT_QUADRANT = exports.POSITION_BOTTOM_LEFT_QUADRANT = {
  center: {
    x: QUARTER_SCALING,
    y: QUARTER_SCALING + HALF_SCALING
  },
  size: {
    width: HALF_SCALING,
    height: HALF_SCALING
  },
  rotation: ANGLE_DEGREES_0,
  zorder: ZORDER_DEFAULT,
  alpha: ALPHA_DEFAULT
};
var POSITION_BOTTOM_RIGHT_QUADRANT = exports.POSITION_BOTTOM_RIGHT_QUADRANT = {
  center: {
    x: QUARTER_SCALING + HALF_SCALING,
    y: QUARTER_SCALING + HALF_SCALING
  },
  size: {
    width: HALF_SCALING,
    height: HALF_SCALING
  },
  rotation: ANGLE_DEGREES_0,
  zorder: ZORDER_DEFAULT,
  alpha: ALPHA_DEFAULT
};
var POSITION_CENTER_HALF_SIZE = exports.POSITION_CENTER_HALF_SIZE = {
  center: {
    x: HALF_SCALING,
    y: HALF_SCALING
  },
  size: {
    width: HALF_SCALING,
    height: HALF_SCALING
  },
  rotation: ANGLE_DEGREES_0,
  zorder: ZORDER_DEFAULT,
  alpha: ALPHA_DEFAULT
};
// app name to ID
var APP_IDS = exports.APP_IDS = {
  Decisions: "ControlRoom",
  Engage: "Engage",
  Trends: "Trends",
  Artistic: "Artistic",
  Spaces: "Spaces",
  Remote: "Remote",
  Switch: "Launcher",
  Calibration: "Calibration",
  Launcher: "Launcher",
  Config: "Config",
  GCM: "GCM"
};

// app ID to name
var APP_NAMES = exports.APP_NAMES = {
  ControlRoom: "Decisions",
  Engage: "Engage",
  Trends: "Trends",
  Artistic: "Artistic",
  Spaces: "Spaces",
  Remote: "Remote",
  Switch: "uControl",
  Launcher: "uControl",
  GCM: "Userful Global Cluster Manager"
};

// SUCCESS,		// The operation succeeded.
// COMPOSITE,		// This severity depends on the severities of the child responses.
// INFO,			// Contains information about an abnormal but non-fatal issue.
// WARN,			// Warning of some issue or inconsistency that may cause a problem but might be recovered.
// FATAL,			// A fatal error that cannot be recovered.
// CHILD,			// The response severity should be obtained from the child.
// UNKNOWN;		// Unknown.