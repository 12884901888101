import React, { ReactNode, useEffect } from 'react';
import { Provider } from 'react-redux';
import { createSamOrgQueryForApp } from 'userful-chronos-app-common-js/dist/models/sam/SAMUtils';
import './msgs/ClientMsgReceiver';
import { setAppIDForSamReceiver } from './msgs/ClientMsgReceiver';
import { requestListPlayListDefs, requestListSourceAssets, requestListSourceDefs, requestListSourceSpecs, requestQueryPlaylistDefsAssets, requestQuerySourceDefs } from './msgs/MsgSender';
import sourceAssetStore, { SourceAssetContext } from './sourceAssetStore';

interface IProps {
  appID?: string;
  children: ReactNode,
}

function SourceAssetsProvider(props: IProps) {
  useEffect(() => {
    requestListSourceSpecs();
    requestListSourceAssets();
    if (props.appID) {
      setAppIDForSamReceiver(props.appID);
      const samOrgQuery = createSamOrgQueryForApp(props.appID);
      requestQuerySourceDefs(samOrgQuery);
      requestQueryPlaylistDefsAssets(samOrgQuery);
    } else {
      requestListSourceDefs();
      requestListPlayListDefs();
    }
  }, []);

  return (
    <Provider store={sourceAssetStore} context={SourceAssetContext}>
      {props.children}
    </Provider>
  );
}

export default SourceAssetsProvider;