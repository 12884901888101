"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSamSourceIconPath = exports.createUpdateDataFromSourceDef = void 0;
var _globalStates = require("../../globalstates/globalStates");
var _routing = require("../../routing");
var _common = require("../common");
var _uuid = require("uuid");
var rootPath = (0, _routing.getRootPath)();
var getSamSourceIconPath = exports.getSamSourceIconPath = function getSamSourceIconPath(source, defaultImage) {
  if (!source || (0, _globalStates.getGlobalStates)().isDevEnvironment) {
    return defaultImage || "./source.png";
  }
  var iconParam = source.params.find(function (param) {
    return param.name === "Icon";
  });
  if (!iconParam) {
    return defaultImage || "./source.png";
  }
  var filePath = iconParam.value.split("/");
  var fileName = filePath[filePath.length - 1];
  var pluginDir = filePath[filePath.length - 3];
  return "".concat(rootPath, "html/icons/").concat(pluginDir, "/").concat(fileName);
};
var createUpdateDataFromSourceDef = exports.createUpdateDataFromSourceDef = function createUpdateDataFromSourceDef(source) {
  var assets = source.assets.map(function (item) {
    return {
      paramName: item.paramName,
      sourceAssets: item.sourceAssets.map(function (asset) {
        return {
          value: asset.value || asset.id.value
        };
      })
    };
  });
  return {
    id: source.id,
    name: source.name,
    description: source.description,
    orgData: source.orgData,
    type: source.type,
    params: source.params,
    displayResolution: source.displayResolution,
    outputType: source.outputType,
    controlMode: source.controlMode,
    assets: assets,
    scaling: source.scaling,
    userfulSecurityData: source.userfulSecurityData || (0, _common.EMPTY_USERFUL_SECURITY_DATA)(),
    sharingMode: source.sharingMode || 'ALWAYS_SHARE_SOURCE',
    appInstanceID: source.appInstanceID || {
      value: (0, _uuid.v4)(),
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    }
  };
};