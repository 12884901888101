import React, { useState, useEffect } from "react";
import { IUFormField, IUFormFieldCollection } from "../uFormUtils";
import UFieldTitle from "./UFieldTitle/UFieldTitle";
import ClickButton from "../../Widgets/Buttons/ClickButton";
import "./UFormFields.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faHexagonExclamation } from "@fortawesome/pro-solid-svg-icons";
import { faMinus, faPlus, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { minusButton, plusButton } from "../../Widgets/Buttons/Presets/buttonPresets";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface IProps {
     field: IUFormField;
     forceValue?: string | number | boolean | undefined;
     forceError?: string;
     onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
     onValueChange?: (value) => void;
     hidePlaceholder?: boolean;
}

export default function UInput({ field, onItemChange, forceValue, forceError, onValueChange, hidePlaceholder }: IProps) {
     const { t } = useTranslation();

     // const [refresh, setRefresh] = useState(false);
     let value = useUFormContext().state.fieldState[field.key];
     let error = useUFormContext().state.fieldErrors[field.key];
     const { onFieldChange } = useUFormContext();

     if (forceValue != undefined) {
          value = forceValue;
     }

     if (forceError != undefined) {
          error = forceError;
     }

     // useEffect(() => {
     //      setRefresh(!refresh)
     // }, [forceValue, forceError]);

     const length = (value ? value + "" : "").length;

     const onInputChange = (e) => {
          const targetValue: string = e.target.value;
          if (onValueChange) onValueChange(targetValue);
          if (onItemChange) onItemChange({ [field.key]: targetValue }, field);
          else onFieldChange({ [field.key]: targetValue }, field);
     };

     const onMinusButtonClick = (e) => {
          if (field.inputType !== "number") {
               return;
          }
          const currentValue = Number(value) || Number(field.min) || 0;
          const step = field.step || 1;
          if (field.min || field.min === 0) {
               if (currentValue - step < field.min) {
                    return;
               }
          }
          if (onValueChange) onValueChange(currentValue - step);
          if (onItemChange) onItemChange({ [field.key]: currentValue - step }, field);
          else onFieldChange({ [field.key]: currentValue - step }, field);
     };

     const onPlusButtonClick = (e) => {
          if (field.inputType !== "number") {
               return;
          }
          const currentValue = Number(value) || Number(field.min) || 0;
          const step = field.step || 1;
          if (field.max) {
               if (currentValue + step > field.max) {
                    return;
               }
          }
          if (onValueChange) onValueChange(currentValue + step);
          if (onItemChange) onItemChange({ [field.key]: currentValue + step }, field);
          else onFieldChange({ [field.key]: currentValue + step }, field);
     };

     return (
          <div className="fieldColumnWrapper">
               <UFieldTitle
                    title={field.title}
                    help={field.help}
                    required={field.required}
                    length={length}
                    maxLength={field.maxLength}
                    minLength={field.minLength}
               />
               {field.numberPlusMinusButton ? (
                    <div className="fieldColumnWrapper shortGap">
                         <div className="fieldRowWrapper">
                              <div className="numberInputWrapper">
                                   <button className={`iconButtonWrapper ${(field.min || field.min === 0) && (+value - 1) < field.min ? "disabledButton" : ""}`} onClick={onMinusButtonClick} >
                                        <FontAwesomeIcon icon={faMinus} />
                                   </button>
                                   <input
                                        className={`uInput ${error && error.length > 0 ? "errorBorder" : null}`}
                                        onChange={onInputChange}
                                        type={field.inputType}
                                        value={forceValue ? forceValue : value ? value : value === 0 ? value : ""}
                                        placeholder={hidePlaceholder? undefined :t("Shared.Enter") + " " + t(field.title) + " " + t("Shared.Here")}
                                        minLength={field.minLength}
                                        maxLength={field.maxLength}
                                        min={field.min}
                                        max={field.max}
                                        disabled={field.disabled}
                                        id={field.id || ''}
                                   />
                                   <button className={`iconButtonWrapper  ${field.max && (+value + 1) > field.max ? "disabledButton" : ""}`} onClick={onPlusButtonClick} >
                                        <FontAwesomeIcon icon={faPlus} />
                                   </button>
                              </div>
                         </div>
                         {error && error.length > 0 && (
                              <div className="fieldRowWrapper shortGap">
                                   <FontAwesomeIcon className="errorIcon" icon={faCircleQuestion} />
                                   <div className="errorMsg">{t(error)}</div>
                              </div>)}
                    </div>
               ) : field.copyOnClick ? (
                    <OverlayTrigger key={"toggleButton"} placement="top" overlay={<Tooltip>{t("Shared.copyLink")}</Tooltip>}>
                         <button className="inputButton" onClick={(e) => {
                              const text = document.getElementById("copyLinkText").innerHTML;
                              const copyContent = async () => {
                                   try {
                                        await navigator.clipboard.writeText(text);

                                   } catch (err) {

                                   }
                              }
                              copyContent();
                         }} >
                              <div id="copyLinkText" className="copyLinkText">{forceValue ? forceValue : value ? value : ""}</div>
                              <FontAwesomeIcon icon={faCopy} />
                         </button>
                    </OverlayTrigger>
               ) : (
                    <div className={`fieldColumnWrapper shortGap `}>
                         <input
                              className={`uInput ${error && error.length > 0 ? "errorBorder" : null}`}
                              style={field.copyOnClick && { cursor: "pointer" }}
                              onChange={onInputChange}
                              type={field.inputType}
                              value={forceValue ? forceValue : field.disabled && value ? t(value) : value ? value : ""}
                              placeholder={hidePlaceholder? undefined : t("Shared.Enter") + " " + t(field.title) + " " + t("Shared.Here")}
                              minLength={field.minLength}
                              maxLength={field.maxLength}
                              min={field.min}
                              max={field.max}
                              disabled={field.disabled}
                              id={field.id || ''}
                         />
                         {error && error.length > 0 && (
                              <div className="fieldRowWrapper shortGap">
                                   <FontAwesomeIcon className="errorIcon" icon={faCircleQuestion} />
                                   <div className="errorMsg">{t(error)}</div>
                              </div>
                         )}
                    </div>
               )}
          </div>
     );
}
