"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterTemporaryGroupVideos = exports.filterTemporaryGroupVideoWalls = exports.filterTemporaryGroupAudios = void 0;
// these are useless to the UI
var filterTemporaryGroupVideoWalls = exports.filterTemporaryGroupVideoWalls = function filterTemporaryGroupVideoWalls(videoWalls, groups) {
  return videoWalls.filter(function (videoWall) {
    return groups.findIndex(function (group) {
      return group.videoWalls.findIndex(function (groupItem) {
        return groupItem.id.value === videoWall.id.value;
      }) >= 0;
    }) < 0;
  });
};
var filterTemporaryGroupVideos = exports.filterTemporaryGroupVideos = function filterTemporaryGroupVideos(videos, groups) {
  return videos.filter(function (video) {
    return groups.findIndex(function (group) {
      return group.video.findIndex(function (groupItem) {
        return groupItem.id.value === video.id.value;
      }) >= 0;
    }) < 0;
  });
};
var filterTemporaryGroupAudios = exports.filterTemporaryGroupAudios = function filterTemporaryGroupAudios(audios, groups) {
  return audios.filter(function (audio) {
    return groups.findIndex(function (group) {
      return group.audio.findIndex(function (groupItem) {
        return groupItem.id.value === audio.id.value;
      }) >= 0;
    }) < 0;
  });
};