import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { CdmDestinationSet } from "userful-chronos-app-common-js/dist/models/cdm/CdmGroup";
import { CdmPhysicalVideoDest } from "userful-chronos-app-common-js/dist/models/cdm/CdmPhysical";
import { CdmVideoWallDest } from "userful-chronos-app-common-js/dist/models/cdm/CdmVideoWall";
import {
    NOTIFY_PHYSICAL_VIDEO_DEST_ADDED, NOTIFY_PHYSICAL_VIDEO_DEST_REMOVED, NOTIFY_PHYSICAL_VIDEO_DEST_UPDATED,
    NOTIFY_VIDEOWALL_DEST_ADDED, NOTIFY_VIDEOWALL_DEST_REMOVED, NOTIFY_VIDEOWALL_DEST_UPDATED,
    RESPONSE_LIST_DESTINATION_SET, RESPONSE_QUERY_DESTINATION_SET
} from "../../destinations/msgs/CDMMsgDefinations";
import { displayDestinationsSliceActions } from "../../destinations/displayDestinationsSlice";
import { videowallDestinationsSliceActions } from "../../destinations/videowallDestinationsSlice";
import mappingStore from "../mappingStore";
import { updateCdmItemMatchesAppID } from "../StoreFilters";
import { setReceivedInitialDestinations, setReceivedInitialDestinationsQuery } from "../../InitialDataStates";
import { UclientData } from "userful-chronos-app-common-js/dist/models/uclient";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { RESPONSE_LIST_UCLIENTS, RESPONSE_LIST_UCLIENTS_FOR_VE, NOTIFY_UCLIENT_ADDED, NOTIFY_UCLIENT_UPDATED, NOTIFY_UCLIENT_REMOVED } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/UClientsMsgDefinations";
import { uclientsSliceActions } from "../../displays/uclientsSlice";
import { audioDestinationsSliceActions } from "../../destinations/audioDestinationsSlice";
import { destinationGroupsSliceActions } from "../../destinations/destinationGroupsSlice";

let appID = null;
export const setAppIDForMappingCdmReceiver = (appIDValue: string) => {
    appID = appIDValue;
}

registerMsgHandler(RESPONSE_LIST_DESTINATION_SET, (payload) => {
    setReceivedInitialDestinations();
    if (appID) {
        return;
    }
    const data = JSON.parse(payload) as CdmDestinationSet;
    console.debug(`receive RESPONSE_LIST_DESTINATION_SET. ${data.physicalVideo.length}|${data.physicalAudio.length}|${data.videoWalls.length}|${data.groups.length}`);
    mappingStore.dispatch(displayDestinationsSliceActions.setDisplayDestinations(data.physicalVideo));
    mappingStore.dispatch(videowallDestinationsSliceActions.setVideoWallDestinations(data.videoWalls));
    mappingStore.dispatch(destinationGroupsSliceActions.setDestinationGroups(data.groups));
    mappingStore.dispatch(audioDestinationsSliceActions.setAudioDestinations(data.physicalAudio));
});

registerMsgHandler(RESPONSE_QUERY_DESTINATION_SET, (payload) => {
    setReceivedInitialDestinationsQuery();
    const data = JSON.parse(payload) as CdmDestinationSet;
    console.debug(`receive RESPONSE_QUERY_DESTINATION_SET. ${data.physicalVideo.length}|${data.physicalAudio.length}|${data.videoWalls.length}|${data.groups.length}`);
    mappingStore.dispatch(displayDestinationsSliceActions.setDisplayDestinations(data.physicalVideo));
    mappingStore.dispatch(videowallDestinationsSliceActions.setVideoWallDestinations(data.videoWalls));
    mappingStore.dispatch(destinationGroupsSliceActions.setDestinationGroups(data.groups));
    mappingStore.dispatch(audioDestinationsSliceActions.setAudioDestinations(data.physicalAudio));
});


registerMsgHandler(NOTIFY_PHYSICAL_VIDEO_DEST_ADDED, (payload) => {
    console.debug('receive NOTIFY_PHYSICAL_VIDEO_DEST_ADDED');
    const data = JSON.parse(payload) as CdmPhysicalVideoDest;
    if (!updateCdmItemMatchesAppID(data, appID)) {
        console.debug('No appID match');
        return;
    }
    mappingStore.dispatch(displayDestinationsSliceActions.addOrUpdateDisplayDestination(data));
})
registerMsgHandler(NOTIFY_PHYSICAL_VIDEO_DEST_UPDATED, (payload) => {
    console.debug('receive NOTIFY_PHYSICAL_VIDEO_DEST_UPDATED');
    const data = JSON.parse(payload) as CdmPhysicalVideoDest;
    if (!updateCdmItemMatchesAppID(data, appID)) {
        console.debug('No appID match');
        mappingStore.dispatch(displayDestinationsSliceActions.removeDisplayDestination(data));
        return;
    }
    mappingStore.dispatch(displayDestinationsSliceActions.addOrUpdateDisplayDestination(data));
})
registerMsgHandler(NOTIFY_PHYSICAL_VIDEO_DEST_REMOVED, (payload) => {
    console.debug('receive NOTIFY_PHYSICAL_VIDEO_DEST_REMOVED');
    const data = JSON.parse(payload) as CdmPhysicalVideoDest;
    mappingStore.dispatch(displayDestinationsSliceActions.removeDisplayDestination(data));
})



registerMsgHandler(NOTIFY_VIDEOWALL_DEST_ADDED, (payload) => {
    console.debug('receive NOTIFY_VIDEOWALL_DEST_ADDED');
    const data = JSON.parse(payload) as CdmVideoWallDest;
    if (!updateCdmItemMatchesAppID(data, appID)) {
        console.debug('No appID match');
        return;
    }
    mappingStore.dispatch(videowallDestinationsSliceActions.addOrUpdateVideoWallDestination(data));
})
registerMsgHandler(NOTIFY_VIDEOWALL_DEST_UPDATED, (payload) => {
    console.debug('receive NOTIFY_VIDEOWALL_DEST_UPDATED');
    const data = JSON.parse(payload) as CdmVideoWallDest;
    if (!updateCdmItemMatchesAppID(data, appID)) {
        console.debug('No appID match');
        mappingStore.dispatch(videowallDestinationsSliceActions.removeVideoWallDestination(data));
        return;
    }
    mappingStore.dispatch(videowallDestinationsSliceActions.addOrUpdateVideoWallDestination(data));
})
registerMsgHandler(NOTIFY_VIDEOWALL_DEST_REMOVED, (payload) => {
    console.debug('receive NOTIFY_VIDEOWALL_DEST_REMOVED');
    const data = JSON.parse(payload) as CdmVideoWallDest;
    mappingStore.dispatch(videowallDestinationsSliceActions.removeVideoWallDestination(data));
})

registerMsgHandler(RESPONSE_LIST_UCLIENTS, (payload) => {
    console.debug("Receive RESPONSE_LIST_UCLIENTS");
    const data = JSON.parse(payload) as Array<UclientData>;
    mappingStore.dispatch(uclientsSliceActions.setDisplays(data));
});

registerMsgHandler(RESPONSE_LIST_UCLIENTS_FOR_VE, (payload) => {
    const data = JSON.parse(payload) as Array<UclientData>;
    console.debug("Receive RESPONSE_LIST_UCLIENTS_FOR_VE");
    mappingStore.dispatch(uclientsSliceActions.setDisplaysForVE(data));
});

registerMsgHandler(NOTIFY_UCLIENT_ADDED, (payload) => {
    console.debug("Receive NOTIFY_UCLIENT_ADDED");
    const data = JSON.parse(payload) as UclientData;
    mappingStore.dispatch(uclientsSliceActions.addOrUpdateDisplays(data));
});

registerMsgHandler(NOTIFY_UCLIENT_UPDATED, (payload) => {
    console.debug("Receive NOTIFY_UCLIENT_UPDATED");
    const data = JSON.parse(payload) as UclientData;
    mappingStore.dispatch(uclientsSliceActions.addOrUpdateDisplays(data));
});

registerMsgHandler(NOTIFY_UCLIENT_REMOVED, (payload) => {
    console.debug("Receive NOTIFY_UCLIENT_REMOVED");
    const data = JSON.parse(payload) as StringID;
    mappingStore.dispatch(uclientsSliceActions.deleteDisplay(data));
});