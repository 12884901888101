import { faBars, faBorderAll, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserfulSearchBar, UserfulTooltip } from "userful-chronos-common-ui/dist";
import { IClusterInfo, IConstellationInfo, ClusterDeviceInfo } from "../../../model/CarbonExternalModels";
import { useCarbonDispatch, useCarbonSelector, selectFiltersVisibility, selectSearchInput, selectDevicesFilters, selectClusterView } from "../../../store/carbonStore";
import { FiltersActions } from "../../../store/filters/filtersSlice";
import { filterDevices } from "../../../store/filters/filtersManager";
import EmptyPlaceholder from "../../../common/components/placeholders/EmptyPlaceholder";
import { AppSliceActions } from "../../../store/app/appSlice";
import { convertClusterDeviceInfoToTable } from "../../../common/components/UserfulTable/UserfulTableConverter";
import { ITable } from "../../../common/components/UserfulTable/userfulTableInterface";
import UserfulTable from "../../../common/components/UserfulTable/UserfulTable";

import DevicesCard from "../cards/DevicesCard";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

interface IProps {
    foundObject: { cluster: IClusterInfo; constellation: IConstellationInfo };
}

export default function DevicesTable(props: IProps) {
    const dispatch = useCarbonDispatch();

    const constellation: IConstellationInfo = props.foundObject.constellation;

    const allDeviceData: ClusterDeviceInfo[] = props.foundObject.cluster.clusterDeviceInfo;

    // Visibility
    const filtersVisibility = useCarbonSelector(selectFiltersVisibility);
    const clusterView = useCarbonSelector(selectClusterView);

    // Filtering
    const deviceFilters = useCarbonSelector(selectDevicesFilters);
    const searchInput = useCarbonSelector(selectSearchInput);

    const filteredDevices = filterDevices(deviceFilters, searchInput.devices, allDeviceData);

    // Functions
    const onSearchBarChange = function (input: string) {
        dispatch(FiltersActions.setSearchInput({ ...searchInput, devices: input }));
    };

    const table: ITable = convertClusterDeviceInfoToTable(filteredDevices);

    // Renders
    const insertBody = () => {
        if (allDeviceData.length > 0) {
            if (filteredDevices.length > 0) {
                return (
                    <div className="constellationCardsWrapper">
                        {filteredDevices.map((d) => {
                            return <DevicesCard key={d.id.value} device={d} />;
                        })}
                    </div>
                );
            } else {
                return <EmptyPlaceholder img="./images/notFound.png" title={"No Devices Found"} description={"Please refine your search/filter criteria and try again."} />;
            }
        } else {
            return (
                <EmptyPlaceholder
                    img="./images/noRoomImage.png"
                    title={"No Devices Assigned"}
                    description={
                        "There are currently no assigned servers in this constellation. To connect a new server, simply click the 'Pairing Code' button. This will generate a unique code that you can use to pair and connect a server to this constellation."
                    }
                />
            );
        }
    };

    return (
        <div className="tableWrapper">
            <div className="tableHeader">
                <div className="horizontalWrapper">
                    <div className="cluster normalGap">
                        {/* <button className="iconButton">
                            <FontAwesomeIcon icon={faBarsFilter} />
                        </button> */}
                        <UserfulSearchBar forceValue={searchInput.devices} onChange={onSearchBarChange} />
                        {/* <UserfulTooltip placement="bottom" title="Refresh Data">
                            <button
                                className="iconButton"
                                onClick={(e) => {
                                    apiGetConstellations(getGlobalStates().keycloak.token);
                                }}
                            >
                                <FontAwesomeIcon icon={faRefresh} />
                            </button>
                        </UserfulTooltip> */}
                    </div>
                    <div className="cluster normalGap">
                        <div className="multiStateWrapper">
                            <UserfulTooltip title={"Table View"}>
                                <div
                                    className={`mSbutton ${clusterView === "TABLE" ? "active" : ""}`}
                                    onClick={(e) => {
                                        dispatch(AppSliceActions.setClusterView("TABLE"));
                                    }}
                                >
                                    <FontAwesomeIcon icon={faBars} className="mSicon" />
                                </div>
                            </UserfulTooltip>

                            <UserfulTooltip title={"Card View"}>
                                <div
                                    className={`mSbutton ${clusterView === "CARDS" ? "active" : ""}`}
                                    onClick={(e) => {
                                        dispatch(AppSliceActions.setClusterView("CARDS"));
                                    }}
                                >
                                    <FontAwesomeIcon icon={faBorderAll} className="mSicon" />
                                </div>
                            </UserfulTooltip>
                        </div>
                    </div>
                </div>
                {filtersVisibility.constellations && <div className="horizontalWrapper"></div>}
            </div>


            <div className="tableBodyWrapper" style={{ height: "calc(100vh - 168px)" }}>
                {/* <div className="tableSubHeader">
                    <div className="tableSubHeaderGroup">
                        <div
                            className="currentTextSelectable"
                            onClick={(e) => {
                                dispatch(
                                    AppSliceActions.setAppView({
                                        type: "CONSTELLATIONS",
                                        id: { value: "NOT_SET" },
                                    })
                                );
                            }}
                        >
                            Index
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                        <div
                            className="currentTextSelectable"
                            onClick={(e) => {
                                dispatch(
                                    AppSliceActions.setAppView({
                                        type: "CONSTELLATION",
                                        id: constellation.definition.id,
                                    })
                                );
                            }}
                        >
                            {constellation.definition.name}
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                        <div className="currentTextSelected">Aether Manager</div>
                    </div>
                </div> */}
                <div className="constellationCardsWrapper">
                    {clusterView === "TABLE" && <UserfulTable replace={filteredDevices.length === 0 && insertBody()} table={table} onRowClick={(id: StringID) => {
                        dispatch(AppSliceActions.setAppView({ type: "DEVICE", id: id }));
                    }}   stretch={filteredDevices.length === 0}/>}
                    {clusterView === "CARDS" && insertBody()}
                </div>
            </div>
        </div>
    );
}
