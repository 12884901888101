"use strict";

var _rubyMsgDefinitions = require("../../../message/messageTypeDefinitions/ruby/rubyMsgDefinitions");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _channels = require("../../functions/channels");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_rubyMsgDefinitions.REQUEST_LIST_CHANNELS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_rubyMsgDefinitions.RESPOND_LIST_CHANNELS, (0, _channels.getStandaloneChannels)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_rubyMsgDefinitions.REQUEST_ADD_CHANNEL, function (payload) {
  var result = (0, _channels.addChannel)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_rubyMsgDefinitions.NOTIFY_CHANNEL_ADDED, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_rubyMsgDefinitions.REQUEST_UPDATE_CHANNEL, function (payload) {
  var result = (0, _channels.updateChannelHeader)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_rubyMsgDefinitions.NOTIFY_CHANNEL_UPDATED, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_rubyMsgDefinitions.REQUEST_REMOVE_CHANNEL, function (payload) {
  var result = (0, _channels.removeChannel)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_rubyMsgDefinitions.NOTIFY_CHANNEL_DELETED, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_rubyMsgDefinitions.REQUEST_ADD_OR_UPDATE_CHANNEL_SOURCE, function (payload) {
  var result = (0, _channels.addOrUpdateChannelSource)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_rubyMsgDefinitions.NOTIFY_CHANNEL_UPDATED, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_rubyMsgDefinitions.REQUEST_REMOVE_CHANNEL_SOURCE, function (payload) {
  var result = (0, _channels.removeChannelSource)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_rubyMsgDefinitions.NOTIFY_CHANNEL_UPDATED, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_rubyMsgDefinitions.REQUEST_ADD_OR_UPDATE_CHANNEL_USER, function (payload) {
  var result = (0, _channels.addOrUpdateChannelUser)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_rubyMsgDefinitions.NOTIFY_CHANNEL_UPDATED, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_rubyMsgDefinitions.REQUEST_REMOVE_CHANNEL_USER, function (payload) {
  var result = (0, _channels.removeChannelUser)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_rubyMsgDefinitions.NOTIFY_CHANNEL_UPDATED, result);
});