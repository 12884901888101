"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStandaloneDataStore = exports.standaloneDatainitialized = exports.resetStandaloneDataStore = exports.getStandaloneDataStore = exports.clearStandaloneDataStore = exports.awaitInitDataReady = void 0;
var _standalone = require("../../models/standalone/standalone");
var _initialValue = require("./initialValue");
var _lzString = _interopRequireDefault(require("lz-string"));
var _globalStates = require("../../globalstates/globalStates");
var _storeUtils = require("./storeUtils");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var dataReady;
var promise = new Promise(function (resolve) {
  return dataReady = resolve;
});
var ready = false;
var persist = (0, _globalStates.isStandalone)();
var standaloneDatainitialized = exports.standaloneDatainitialized = function standaloneDatainitialized(data) {
  if (data) {
    Object.assign(standaloneData, (0, _storeUtils.sanitizeInitialData)(data));
  }
  ready = true;
  dataReady();
  promise = new Promise(function (resolve) {
    return dataReady = resolve;
  });
};
var standaloneData = (0, _storeUtils.sanitizeInitialData)((0, _initialValue.getInitialStandaloneData)());
var updateStandaloneDataStore = exports.updateStandaloneDataStore = function updateStandaloneDataStore(update) {
  (0, _standalone.updateStandaloneData)(standaloneData, update);
};
var getStandaloneDataStore = exports.getStandaloneDataStore = function getStandaloneDataStore() {
  return standaloneData;
};
if (window) {
  window.onbeforeunload = function () {
    if (!persist) {
      return;
    }
    var standaloneDataString = JSON.stringify(getStandaloneDataStore());
    console.warn("Uncompressed size is " + standaloneDataString.length);
    var compressed = _lzString["default"].compressToUTF16(standaloneDataString);
    console.warn("Compressed size is " + compressed.length);
    localStorage.setItem("standaloneData", compressed);
  };
}
var resetStandaloneDataStore = exports.resetStandaloneDataStore = function resetStandaloneDataStore() {
  persist = false;
  if (localStorage) {
    localStorage.removeItem("standaloneData");
  }
  if (window) {
    window.location.reload();
  }
};
var clearStandaloneDataStore = exports.clearStandaloneDataStore = function clearStandaloneDataStore() {
  if (localStorage) {
    localStorage.removeItem("standaloneData");
  }
  Object.assign(standaloneData, {});
};
var awaitInitDataReady = exports.awaitInitDataReady = function awaitInitDataReady() {
  if (ready) {
    return Promise.resolve(true);
  }
  return promise;
};