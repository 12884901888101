import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { MapperLayout, MapperLayoutMapping, MapperLayoutMappingsCollection } from "userful-chronos-app-common-js/dist/models/layouts/GlobalLayouts.js";

export interface VideoWallsState {
    layouts: Array<MapperLayout>;
    layoutMappings: MapperLayoutMapping[];
    layoutMappingsCollections: MapperLayoutMappingsCollection[];
}

const initialState: VideoWallsState = {
    layouts: [],
    layoutMappings: [],
    layoutMappingsCollections: [],
};

const layoutsSlice = createSlice({
    name: "layoutsSlice",
    initialState,
    reducers: {
        setMapperLayouts(state: VideoWallsState, action: PayloadAction<MapperLayout[]>) {
            state.layouts = [...action.payload];
        },
        addMapperLayout(state: VideoWallsState, action: PayloadAction<MapperLayout>) {
            state.layouts = [...state.layouts, { ...action.payload }];
        },
        updateMapperLayout(state: VideoWallsState, action: PayloadAction<MapperLayout>) {
            const mapperLayout = action.payload;

            const index = state.layouts.findIndex((l) => l.id.value === mapperLayout.id.value);

            if (index >= 0) {
                state.layouts[index] = mapperLayout;
            }
        },
        removeMapperLayout(state: VideoWallsState, action: PayloadAction<StringID>) {
            const mapperLayoutID = action.payload;

            const index = state.layouts.findIndex((l) => l.id.value === mapperLayoutID.value);

            if (index >= 0) {
                state.layouts.splice(index, 1);
            }
        },

        setMapperLayoutMappings(state: VideoWallsState, action: PayloadAction<MapperLayoutMapping[]>) {
            state.layoutMappings = [...action.payload];
        },
        addMapperLayoutMapping(state: VideoWallsState, action: PayloadAction<MapperLayoutMapping>) {
            state.layoutMappings = [...state.layoutMappings, { ...action.payload }];
        },
        updateMapperLayoutMapping(state: VideoWallsState, action: PayloadAction<MapperLayoutMapping>) {
            const index = state.layoutMappings.findIndex((l) => l.id.value === action.payload.id.value);
            if (index >= 0) {
                state.layoutMappings = [...state.layoutMappings.slice(0, index), {...action.payload}, ...state.layoutMappings.slice(index + 1)];
            }
        },
        removeMapperLayoutMapping(state: VideoWallsState, action: PayloadAction<StringID>) {
            state.layoutMappings = state.layoutMappings.filter(l => l.id.value !== action.payload.value);
        },

        setMapperLayoutMappingsCollections(state: VideoWallsState, action: PayloadAction<MapperLayoutMappingsCollection[]>) {
            state.layoutMappingsCollections = [...action.payload];
        },
        addMapperLayoutMappingsCollection(state: VideoWallsState, action: PayloadAction<MapperLayoutMappingsCollection>) {
            state.layoutMappingsCollections = [...state.layoutMappingsCollections, { ...action.payload }];
        },
        updateMapperLayoutMappingsCollection(state: VideoWallsState, action: PayloadAction<MapperLayoutMappingsCollection>) {
            const index = state.layoutMappingsCollections.findIndex((l) => l.id.value === action.payload.id.value);
            if (index >= 0) {
                state.layoutMappingsCollections = [...state.layoutMappingsCollections.slice(0, index), {...action.payload}, ...state.layoutMappingsCollections.slice(index + 1)];
            }
        },
        removeMapperLayoutMappingsCollection(state: VideoWallsState, action: PayloadAction<StringID>) {
            state.layoutMappingsCollections = state.layoutMappingsCollections.filter(l => l.id.value !== action.payload.value);
        },
    },
});

export const { setMapperLayouts, addMapperLayout, updateMapperLayout, removeMapperLayout } = layoutsSlice.actions;
export const layoutSliceActions = layoutsSlice.actions;

export default layoutsSlice.reducer;
