export const objectEqual = (objectA: object, objectB: object, ignoreType?: boolean): boolean => {
    // Check if either objectA or objectB is null
    if (objectA === null || objectB === null) {
        return false;
    }

    for (const property in objectA) {
        // Check if the property is not null in both objects
        if (objectA[property] !== null && objectB.hasOwnProperty(property) && objectB[property] !== null) {
            if (typeof objectA[property] === "object") {
                if (!objectEqual(objectA[property], objectB[property])) return false;
            } else {
                if (!ignoreType)
                    if (objectA[property] !== objectB[property]) return false;
                    else if (objectA[property] != objectB[property]) return false;
            }
        } else return false;
    }
    for (const property in objectB) {
        // Check if the property is not null in both objects
        if (objectB[property] !== null && objectA.hasOwnProperty(property) && objectA[property] !== null) {
            if (typeof objectB[property] === "object") {
                if (!objectEqual(objectB[property], objectA[property])) return false;
            } else {
                if (!ignoreType)
                    if (objectB[property] !== objectA[property]) return false;
                    else if (objectB[property] != objectA[property]) return false;
            }
        } else return false;
    }
    return true;
};

export function isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
}

export function deepCopy(obj) {
    if (!isObject(obj)) {
        return obj; // If it's not an object, return the original element
    }

    let copy = {};
    for (let key in obj) {
        if (isObject(obj[key])) {
            copy[key] = deepCopy(obj[key]); // Recursively deep copy object properties
        } else {
            copy[key] = obj[key]; // Copy non-object properties directly
        }
    }
    return copy;
}

export function synchronizeArrays(arrayA, arrayB) {
    const returnArray = [];
    for (let i = 0; i < arrayB.length; i++) {
        const element = arrayB[i];
        const indexInA = arrayA.indexOf(element);

        console.log(element);
        console.log(indexInA);

        if (indexInA === -1) {
            // Element in B not present in A, add it to A
            const copiedElement = deepCopy(element);
            console.log(copiedElement);
            returnArray.push(copiedElement);
        } else {
            returnArray.push(deepCopy(element));
        }
    }

    return returnArray;

    // for (let i = arrayA.length - 1; i >= 0; i--) {
    //     if (!arrayB.includes(arrayA[i])) {
    //         arrayA.splice(i, 1);
    //     }
    // }
}
