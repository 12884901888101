import React from "react";
import { IUFormBlock, IUFormField, IUFormFieldCollection } from "../uFormUtils";
import UInput from "../UFormFields/UInput";
import UDropdown from "../UFormFields/UDropdown";
import UTextarea from "../UFormFields/UTextarea";
import UFieldsCollection from "../UFormFields/UFieldsCollection";
import UCheckbox from "../UFormFields/UCheckbox";
import URadioButton from "../UFormFields/URadioButton";
import { useTranslation } from "react-i18next";
import UToggle from "../UFormFields/UToggle";
import UCardSelector from '../UFormFields/UCardSelector';
import UUpload from "../UFormFields/UUpload";
import UColorPicker from "../UFormFields/UColorPicker";
import UTags from "../UFormFields/UTags";
import UPasswordWidget from "../UFormFields/UPasswordWidget";
import UMultiSelect from "../UFormFields/UMultiSelect";
import UniquePasswordWidget from '../UFormFields/UniquePasswordWidget';
import UPermissions from "../UFormFields/UPermissions";
interface IProps {
     block: IUFormBlock;
}

export default function UFormBlock({ block }: IProps) {
     const { t } = useTranslation();

     const insertUField = (field: IUFormField) => {
          if (field) {
               if (field.hide) return null;

               switch (field.type) {
                    case "INPUT":
                         return <UInput key={"field:" + field.key + ":I"} field={field} />;
                    case "TEXTAREA":
                         return <UTextarea key={"field:" + field.key + ":T"} field={field} />;
                    case "DROPDOWN":
                         return <UDropdown key={"field:" + field.key + ":D"} field={field} />;
                    case "CHECKBOX":
                         return <UCheckbox key={"field:" + field.key + ":C"} field={field} />;
                    case "RADIO_BUTTON":
                         return <URadioButton key={"field:" + field.key + ":RB"} field={field} />;
                    case "TOGGLE":
                         return <UToggle key={"field:" + field.key + ":TO"} field={field} />;
                    case "CARD_SELECTOR":
                         return <UCardSelector key={"field:" + field.key + ":CS"} field={field} />;
                    case "UPLOAD":
                         return <UUpload key={"field:" + field.key + ":UP"} field={field} />;
                    case "COLOR_PICKER":
                         return <UColorPicker key={"field:" + field.key + ":CP"} field={field} />;
                    case "TAGS":
                         return <UTags key={"field:" + field.key + ":TG"} field={field} />;
                    case "PASSWORD":
                         return <UPasswordWidget key={"field:" + field.key + ":P"} field={field} />;
                    case "MULTISELECT":
                         return <UMultiSelect key={"field:" + field.key + ":MS"} field={field} />;
                    case "UniquePassword":
                         return <UniquePasswordWidget key={"field:" + field.key + ":UPW"} field={field} />;
                    case "PERMISSIONS":
                         return <UPermissions key={"field:" + field.key + ":PER"} field={field} />;
                    default:
                         return null;
               }
          }
          return null;
     };
     if (block)
          return (
               <div className="uformBlock">
                    {!block.hideTitle ? (
                         <div className="uformBlockInfo">
                              <div className="uformBlockInfoTitle">{t(block.title)}</div>
                              <div className="uformBlockInfoDesc">{t(block.description)}</div>
                         </div>
                    ) : null}
                    {block.fields &&
                         block.fields.map((field) => {
                              if (!field) return null;
                              else if (field.hide) return null;
                              else if (field.type === "COMPLEX")
                                   return <UFieldsCollection key={"collection:" + field.key} fields={field} />;
                              return insertUField(field);
                         })}
               </div>
          );
     return null;
}
