import { configureStore } from '@reduxjs/toolkit'
import React from 'react'
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux'
import httpsSlice from './httpsSlice'

export const ConfigContext = React.createContext(null);

const configStore = configureStore({
  reducer: {
      httpsStore: httpsSlice,
  },
})
export default configStore;

export type State = ReturnType<typeof configStore.getState>;
export type Dispatch = typeof configStore.dispatch;
export const useConfigSelector: TypedUseSelectorHook<State> = createSelectorHook(ConfigContext);
export const useConfigDispatch = createDispatchHook<Dispatch>(ConfigContext)
