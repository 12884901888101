import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import './MappingGroupLoading.scss';
import { useTranslation } from 'react-i18next';

export default function MappingGroupLoading() {
    const { t } = useTranslation();

    return (
        <div className="mappingGroupLoading">
            <FontAwesomeIcon icon={faCircleNotch} spin/>
            {t('CommonUI.loading')}
        </div>
    );
}