import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DropdownButton.scss";
import { IUDropdownButton } from "./buttonsUtils";
import { useTranslation } from "react-i18next";

interface IProps {
    button: IUDropdownButton;
    onClick?(e): void;
}

export default function DropdownButton({ button, onClick }: IProps) {
    const { t } = useTranslation();

    return (
        <div
            className={`dropdownButton ${button.className || ''}`}
            onClick={(e) => {
                if (onClick) onClick(e);
                if (button.onClick) button.onClick(e);
            }}
        >
            <div className="dropdownButtonWrapper">
                <div className="dropdownButtonIcon"><FontAwesomeIcon icon={button.icon} /></div>
                <div className="dropdownButtonText">{t(button.title)}</div>
            </div>
            {button.shortcut &&
                <div className="dropdownButtonShortcut">
                    <div className="dropdownButtonShortcutText">{button.shortcut}</div>
                </div>}
        </div>)

}
