import React from "react";
import "./InitLoading.scss";
import { useTranslation } from "react-i18next";

interface IProps {
    msg?: string;
    fullscreen?: boolean;
}

const InitLoading = ({ msg, fullscreen = true }: IProps) => {
    const { t } = useTranslation();

    return (
        <div className={`wrapper-loading ${fullscreen ? "" : "not-fullscreen"}`}>
            <div className="loading">
                <svg className="ip" viewBox="0 0 256 128" width="256px" height="128px" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="0%" stopColor="#000000" />
                            <stop offset="25%" stopColor="#c2c2c2" />
                            <stop offset="50%" stopColor="#636363" />
                            <stop offset="75%" stopColor="#c2c2c2" />
                            <stop offset="100%" stopColor="#000000" />
                        </linearGradient>
                        <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                            <stop offset="0%" stopColor="#000000" />
                            <stop offset="25%" stopColor="#c2c2c2" />
                            <stop offset="50%" stopColor="#636363" />
                            <stop offset="75%" stopColor="#c2c2c2" />
                            <stop offset="100%" stopColor="#000000" />
                        </linearGradient>
                    </defs>
                    <g fill="none" strokeLinecap="round" strokeWidth="18">
                        <g className="ip__track">
                            <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
                            <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
                        </g>
                        <g strokeDasharray="200 656">
                            <path className="ip__worm1" stroke="url(#grad1)" strokeDashoffset="0" d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
                            <path className="ip__worm2" stroke="url(#grad2)" strokeDashoffset="358" d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
                        </g>
                    </g>
                </svg>
                <div className="loadingText">
                    <h1 className="loading-main-text">{msg ? msg : t("CommonUI.loading")}</h1>
                    <h3 className="loading-secondary-text">{t("CommonUI.loading2")}</h3>
                </div>
            </div>
        </div>
    );
};

export default InitLoading;
