import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import UserfulTooltip from "../Widgets/UserfulTooltip";

interface IProps {
    filename: string;
    progress: number;
}

export default function UploadFileProgress(props: IProps) {
    const { t } = useTranslation();

    return (
        <div className='uploadFileProgressContainer'>
            <UserfulTooltip placement='top'
                title={`${t('Sources&Destinations.Sources.UploadFile.uploading')} ${props.filename}`}>
                <div className="uploadFileProgressLabel">
                    <span>{t('Sources&Destinations.Sources.UploadFile.uploading')} {props.filename}:</span> <span>{`${props.progress}%`}</span>
                </div>
            </UserfulTooltip>
            <ProgressBar now={props.progress} animated />
        </div>
    );

}