import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StringID } from 'userful-chronos-app-common-js/dist/models/common';
import {
    NovastarController, NovastarControllerHeader, NovastarDisplaySetMode,
    NovastarOutputBrightnessAllAdjustment, NovastarOutputColorTemperatureAllAjustment,
    NovastarOutputGammaAllAdjustment,
    NovastarSelectInputSource
} from 'userful-chronos-app-common-js/dist/models/integration/novastar';
import {
    requestAddController, requestRemoveController,
    requestUpdateBrightness, requestUpdateColorTemp,
    requestUpdateControllerHeader, requestUpdateDisplayMode,
    requestUpdateGamma, requestUpdateInput
} from './msgs/MsgSender';

const initialState: {
    controllers: NovastarController[];
    selectedControllers: StringID[];
    selectedController: NovastarController;
    ready: boolean;
} = {
    controllers: [],
    selectedControllers: [],
    selectedController: null,
    ready: false,
};

export const novastarSlice = createSlice({
    name: 'novastarSlice',
    initialState,
    reducers: {
        setControllers: (state, action: PayloadAction<NovastarController[]>) => {
            state.controllers = [...action.payload];
            state.ready = true;
        },
        setSelectedControllers: (state, action: PayloadAction<StringID[]>) => {
            state.selectedControllers = [...action.payload];
        },
        setSelectedController: (state, action: PayloadAction<NovastarController>) => {
            state.selectedController = action.payload;
        },
        addOrUpdateController: (state, action: PayloadAction<NovastarController>) => {
            const foundIndex = state.controllers.findIndex(item => item.id.value === action.payload.id.value);
            if (foundIndex < 0) {
                state.controllers = [
                    ...state.controllers,
                    { ...action.payload },
                ];
            } else {
                state.controllers = [
                    ...state.controllers.slice(0, foundIndex),
                    { ...action.payload },
                    ...state.controllers.slice(foundIndex + 1),
                ];
            }
            if (state.selectedController?.id.value === action.payload.id.value) {
                state.selectedController = { ...action.payload };
            }
        },
        removeController: (state, action: PayloadAction<StringID>) => {
            state.controllers = state.controllers.filter(item => item.id.value !== action.payload.value);
            if (state.selectedController?.id.value === action.payload.value) {
                state.selectedController = null;
            }
        },

        addControllerToServer: (state, action: PayloadAction<NovastarControllerHeader>) => {
            requestAddController(action.payload);
        },
        updateControllerHeaderToServer: (state, action: PayloadAction<NovastarControllerHeader>) => {
            requestUpdateControllerHeader(action.payload);
        },
        removeControllerToServer: (state, action: PayloadAction<StringID>) => {
            requestRemoveController(action.payload);
        },
        updateInputToServer: (state, action: PayloadAction<NovastarSelectInputSource>) => {
            requestUpdateInput(action.payload);
        },
        updateBrightnessToServer: (state, action: PayloadAction<NovastarOutputBrightnessAllAdjustment>) => {
            requestUpdateBrightness(action.payload);
        },
        updateColorTempToServer: (state, action: PayloadAction<NovastarOutputColorTemperatureAllAjustment>) => {
            requestUpdateColorTemp(action.payload);
        },
        updateGammaToServer: (state, action: PayloadAction<NovastarOutputGammaAllAdjustment>) => {
            requestUpdateGamma(action.payload);
        },
        updateDisplayModeToServer: (state, action: PayloadAction<NovastarDisplaySetMode>) => {
            requestUpdateDisplayMode(action.payload);
        },
    },
})

export const novastarActions = novastarSlice.actions;

export default novastarSlice.reducer

