import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormCheck, Dropdown } from 'react-bootstrap';
import { useTable, useSortBy, usePagination, useGlobalFilter, useRowSelect } from 'react-table';
import Search from './Search';
import CustomSearch from './CustomSearch';
import './table.scss';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faSortUp, faSortDown, faSort } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PageSelector from '../pagination/PageSelector';
import UserfulTooltip from '../Widgets/UserfulTooltip';

interface IProps {
    columns: Array<object>,
    data: Array<any>,
    id: string,
    pageSize?: number,
    search?: boolean,
    customSearch?: boolean,
    selectBox?: boolean,
    noData?: JSX.Element,
    minHeight?: string,
    noPagination?: boolean
    getSearchValue?: Function,
    searchValue?: string,
    setSearchValue?: Function,
    hideSpanPages?: boolean,
    maxPageOptions?: number,
    onCellClick?: Function
    hiddenColumns?: string[],
    onSelectionChange?: (values: any[]) => void,
}

export const TableExactFilter = (rows, id, filterValue) =>
    rows.filter((row) => filterValue === '' || row.values[id] === filterValue);

export default function UserfulReactTable(props: IProps) {

    const { t } = useTranslation();


    const [activePage, setActivePage] = useState(1);
    // for pagination
    const defaultItemsPerPage = 15;
    const maxPageOptions = props.maxPageOptions || 5;
    const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const startIndex = currentPage * itemsPerPage; // inclusive
    const endIndex = startIndex + itemsPerPage; // exclusive

    const handlePageSizeSelect = (value) => {
        setItemsPerPage(Number(value));
        setCurrentPage(0);
    }

    const IndeterminateCheckbox = React.forwardRef(
        (props, ref: any) => <FormCheck type="checkbox" {...props} ref={ref} />
    )

    const {
        getTableProps, //table props from react-table
        getTableBodyProps, //table body props from react-table
        headerGroups, // headerGroups, if table has groupings
        rows, // rows for the table based on the data passed
        prepareRow,  // prepare the row (this function needs to be called for each row before getting the row props)
        // setFilter
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Global Filter
        preGlobalFilteredRows,
        setGlobalFilter,
        // Multiple Selection
        selectedFlatRows,
        state: { pageIndex, pageSize: pageSize, globalFilter, sortBy, selectedRowIds }
    } = useTable(
        {
            columns: props.columns,
            data: props.data,
            initialState: { pageSize: props.pageSize || 15, hiddenColumns: props.hiddenColumns || [] },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            if (props.selectBox === undefined || props.selectBox) {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: `${props.id}-row-selection`,
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div className='tableCheckboxHeader'>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} onChange={e => {
                                    getToggleAllRowsSelectedProps().onChange(e);
                                    handleRowSelect();
                                }} />
                            </div>
                        ),
                        width: 32,
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onChange={e => {
                                    row.getToggleRowSelectedProps().onChange(e);
                                    handleRowSelect();
                                }} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
        },

    );

    const handleRowSelect = () => {
        if (props.onSelectionChange && selectedFlatRows) {
            props.onSelectionChange(selectedFlatRows.map(row => row.original));
        }
    };

    if (props.noData && props.data.length < 1) {
        return props.noData;
    }

    const pageContent = () => {
        if (rows.length < 1) {
            return props.noData || [];
        }
        if (rows.length < itemsPerPage) {
            return rows;
        }
        return (rows.slice(startIndex, endIndex));
    };

    const pageStart = Math.min(rows.length, startIndex + 1);
    const pageEnd = Math.min(rows.length, endIndex);
    const totalPages: number = Math.ceil(rows.length / itemsPerPage);

    const pagination = () => (
        <div className="paginationFooter">
            <div className="sizeSelector">
                <Dropdown onSelect={handlePageSizeSelect}>
                    <Dropdown.Toggle variant="light" id="pagination-size-dropdown" className="displayFontHeavy">
                        {itemsPerPage} {t('CommonUI.perPage')} <FontAwesomeIcon icon={faAngleDown} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {defaultItemsPerPage < 5 &&
                            <Dropdown.Item eventKey={defaultItemsPerPage}>{defaultItemsPerPage} {t('CommonUI.perPage')}</Dropdown.Item>
                        }
                        <Dropdown.Item eventKey={15}>15 {t('CommonUI.perPage')}</Dropdown.Item>
                        <Dropdown.Item eventKey={30}>30 {t('CommonUI.perPage')}</Dropdown.Item>
                        <Dropdown.Item eventKey={40}>40 {t('CommonUI.perPage')}</Dropdown.Item>
                        <Dropdown.Item eventKey={50}>50 {t('CommonUI.perPage')}</Dropdown.Item>
                        <Dropdown.Item eventKey={100}>100 {t('CommonUI.perPage')}</Dropdown.Item>
                        <Dropdown.Item eventKey={300}>300 {t('CommonUI.perPage')}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {!props.hideSpanPages && <span>{pageStart} to {pageEnd} of {rows.length}</span>}
            </div>
            <div className="pageSelector">
                <PageSelector
                    totalPages={totalPages}
                    currentPageIndex={currentPage}
                    onPageSelect={setCurrentPage}
                    maxPageOptions={maxPageOptions}
                />
            </div>
        </div>
    );


    const getActivePage = (value) => {
        const activePage = value > 0 ? value : 0;
        gotoPage(activePage);
        setActivePage(activePage);
    }

    const y = pageSize * (pageIndex + 1);
    const recordFrom = y - pageSize + 1;
    const recordTo = y > rows.length ? rows.length : y;

    useEffect(() => {
        // Check if the current active page exceeds the new total number of pages
        const totalPages = Math.floor(rows.length / itemsPerPage);
        const newCurrentPage = Math.min(currentPage, totalPages);

        // Update the active page if necessary
        if (currentPage !== newCurrentPage) {
            setActivePage(newCurrentPage);
            setCurrentPage(newCurrentPage);
            gotoPage(newCurrentPage);
        }

        if (props.pageSize) {
            setPageSize(props.pageSize);
        }
    }, [rows, itemsPerPage, props.pageSize]);

    return (
        <div>
            {(props.search === undefined || props.search) && <div style={{ width: "402px" }} className="optionalSearchClassName">
                {props.customSearch ?
                    <CustomSearch
                        getSearchValue={props.getSearchValue}
                        searchValue={props.searchValue}
                        setSearchValue={props.setSearchValue}
                    /> :
                    <Search
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                }
            </div>}
            <div id={`${props.id}-table-overall-div`} className='table-wrapper' style={{ minHeight: `calc(100vh - ${props.minHeight})` }}>
                <table id={`${props.id}-table`} {...getTableProps()} style={{ width: '100%' }}>
                    <thead>
                        {headerGroups.map((headerGroup, rowIndex) => (
                            <tr key={`react-table-${props.id}-header-${rowIndex}`}>
                                {headerGroup.headers.map((column, colIndex) => (
                                    <th
                                        key={`react-table-${props.id}-header-${rowIndex}-${colIndex}`}
                                        {...column.getHeaderProps({
                                            ...column.getSortByToggleProps(),
                                            title: ' ',
                                            style: {
                                                minWidth: column.minWidth === 0 ? undefined : column.minWidth,
                                                width: column.width === 0 ? undefined : column.width,
                                                maxWidth: column.maxWidth === 0 ? undefined : column.maxWidth,
                                            },
                                        }
                                        )}
                                    >
                                        <div
                                            style={{ display: "flex" }}
                                        >
                                            {column.render('Header')}
                                            {
                                                !(column.id === `${props.id}-row-selection` || column.id === 'actions') && !column.disableSortBy &&
                                                <UserfulTooltip title={column.disableSortBy ? "" : t('CommonUI.toggleSort')}>
                                                    <span className='sort-icon'>
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <FontAwesomeIcon icon={faSortUp} />
                                                            ) : (
                                                                <FontAwesomeIcon icon={faSortDown} />
                                                            )
                                                        ) : (
                                                            <FontAwesomeIcon icon={faSort} />
                                                        )}
                                                    </span>
                                                </UserfulTooltip>
                                            }
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>

                        {pageContent()?.map((row, rowIndex) => {
                            prepareRow(row)
                            return (
                                <tr
                                    key={`react-table-${props.id}-row-${rowIndex}`}
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map((cell, colIndex) => {
                                        return (
                                            <td
                                                key={`react-table-${props.id}-data-${rowIndex}-${colIndex}`}
                                                {...cell.getCellProps({
                                                    style: {
                                                        minWidth: cell.column.minWidth === 0 ? undefined : cell.column.minWidth,
                                                        width: cell.column.width === 0 ? undefined : cell.column.width,
                                                        maxWidth: cell.column.maxWidth === 0 ? undefined : cell.column.maxWidth,
                                                    },
                                                })}
                                                onClick={() => props.onCellClick && props.onCellClick(cell.value, cell.column)}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {!props.noPagination && pagination()}
            </div>
        </div >
    );
}