"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stringToResolution = exports.resolutionToString = exports.prettyPrintStationLabel = exports.getDisplayTypeFromSeatID = exports.DEFAULT_RESOLUTION = void 0;
var _common = require("./common");
var prettyPrintStationLabel = exports.prettyPrintStationLabel = function prettyPrintStationLabel(stationLabel) {
  if (!stationLabel || !stationLabel.value || stationLabel.value === _common.NOT_SET_VALUE || stationLabel.value === "".concat(_common.NOT_SET_VALUE, "-") || stationLabel.value === "NOT_REQUIRED") {
    return "";
  }
  if (stationLabel.value.startsWith("".concat(_common.NOT_SET_VALUE, "-"))) {
    return stationLabel.value.replace("".concat(_common.NOT_SET_VALUE, "-"), "");
  }
  if (stationLabel.value.startsWith("".concat(_common.NOT_SET_VALUE, "_"))) {
    return stationLabel.value.replace("".concat(_common.NOT_SET_VALUE, "_"), "");
  }
  return stationLabel.value;
};
var DEFAULT_RESOLUTION = exports.DEFAULT_RESOLUTION = {
  width: 1920,
  height: 1080
};
var resolutionToString = exports.resolutionToString = function resolutionToString(value) {
  return "".concat(value.width, "x").concat(value.height);
};
var stringToResolution = exports.stringToResolution = function stringToResolution(value) {
  try {
    var parts = value.split("x");
    return {
      width: Number(parts[0]),
      height: Number(parts[1])
    };
  } catch (e) {
    console.debug("cannot parse " + value);
  }
  return DEFAULT_RESOLUTION;
};
var getDisplayTypeFromSeatID = exports.getDisplayTypeFromSeatID = function getDisplayTypeFromSeatID(seatID) {
  if (seatID.value.startsWith("seat-usb-platform-nh")) {
    return "ZERO_CLIENT";
  }
  if (seatID.value.startsWith("seat-userful-uclient")) {
    return "UCLIENT";
  }
  if (seatID.value.startsWith("seat")) {
    return "ONBOARD";
  }
  return "ZERO_CLIENT";
};