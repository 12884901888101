"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateChannelHeader = exports.removeChannelUser = exports.removeChannelSource = exports.removeChannel = exports.getStandaloneChannels = exports.addOrUpdateChannelUser = exports.addOrUpdateChannelSource = exports.addChannel = void 0;
var _store = require("../data/store");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var getStandaloneChannels = exports.getStandaloneChannels = function getStandaloneChannels() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().channels);
};
var addChannel = exports.addChannel = function addChannel(item) {
  var channels = getStandaloneChannels();
  channels.push(item);
  (0, _store.updateStandaloneDataStore)({
    channels: channels
  });
  return item;
};
var updateChannelHeader = exports.updateChannelHeader = function updateChannelHeader(item) {
  var channels = getStandaloneChannels();
  var foundIndex = channels.findIndex(function (c) {
    return c.id.value === item.id.value;
  });
  if (foundIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, channels[foundIndex]), {}, {
      name: item.name,
      description: item.description,
      authRequired: item.authRequired
    });
    channels.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      channels: channels
    });
  }
  return item;
};
var removeChannel = exports.removeChannel = function removeChannel(id) {
  var channels = getStandaloneChannels();
  var foundIndex = channels.findIndex(function (c) {
    return c.id.value === id.value;
  });
  if (foundIndex >= 0) {
    var result = channels.splice(foundIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      channels: channels
    });
    return result;
  }
  return null;
};
var addOrUpdateChannelUser = exports.addOrUpdateChannelUser = function addOrUpdateChannelUser(request) {
  var channels = getStandaloneChannels();
  var foundIndex = channels.findIndex(function (c) {
    return c.id.value === request.id.value;
  });
  if (foundIndex >= 0) {
    var users = _toConsumableArray(channels[foundIndex].users);
    var foundUserIndex = users.findIndex(function (u) {
      return u.id.value === request.user.id.value;
    });
    if (foundUserIndex) {
      users.splice(foundUserIndex, 1, request.user);
    } else {
      users.push(request.user);
    }
    var update = _objectSpread(_objectSpread({}, channels[foundIndex]), {}, {
      users: users
    });
    channels.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      channels: channels
    });
    return update;
  }
  return null;
};
var addOrUpdateChannelSource = exports.addOrUpdateChannelSource = function addOrUpdateChannelSource(request) {
  var channels = getStandaloneChannels();
  var foundIndex = channels.findIndex(function (c) {
    return c.id.value === request.id.value;
  });
  if (foundIndex >= 0) {
    var sources = _toConsumableArray(channels[foundIndex].sources);
    var foundSourceIndex = sources.findIndex(function (u) {
      return u.id.value === request.source.id.value;
    });
    if (foundSourceIndex >= 0) {
      sources.splice(foundSourceIndex, 1, request.source);
    } else {
      sources.push(request.source);
    }
    var update = _objectSpread(_objectSpread({}, channels[foundIndex]), {}, {
      sources: sources
    });
    channels.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      channels: channels
    });
    return update;
  }
  return null;
};
var removeChannelUser = exports.removeChannelUser = function removeChannelUser(id) {
  var channels = getStandaloneChannels();
  var foundIndex = channels.findIndex(function (c) {
    return c.users.findIndex(function (u) {
      return u.id.value === id.value;
    }) >= 0;
  });
  if (foundIndex >= 0) {
    var users = _toConsumableArray(channels[foundIndex].users).filter(function (u) {
      return u.id.value !== id.value;
    });
    var update = _objectSpread(_objectSpread({}, channels[foundIndex]), {}, {
      users: users
    });
    channels.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      channels: channels
    });
    return update;
  }
  return null;
};
var removeChannelSource = exports.removeChannelSource = function removeChannelSource(id) {
  var channels = getStandaloneChannels();
  var foundIndex = channels.findIndex(function (c) {
    return c.sources.findIndex(function (s) {
      return s.id.value === id.value;
    }) >= 0;
  });
  if (foundIndex >= 0) {
    var sources = _toConsumableArray(channels[foundIndex].sources).filter(function (s) {
      return s.id.value !== id.value;
    });
    var update = _objectSpread(_objectSpread({}, channels[foundIndex]), {}, {
      sources: sources
    });
    channels.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      channels: channels
    });
    return update;
  }
  return null;
};