import './FormHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';

interface IProps {
    handleClose: Function;
    name: string;
    description: string;
}

function FormHeader(props: IProps) {

    return (
        <div className='overallHeaderDiv'>
            <div className='overallHeaderInnerDiv'>
                <div className='headerDiv'>
                    {props.name}
                </div>
                <div className='textDiv'>
                    {props.description}
                </div>
            </div>
            <div className='userCloseDiv' onClick={() => props.handleClose()}>
                <div className='userCloseInnerDiv'>
                    <FontAwesomeIcon icon={faXmark} className='userCloseInnerDivIcon' />
                </div>
            </div>
        </div>
    );
}

export default FormHeader;