import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AssignUsersToGroup, UserManagementData, AddKeycloakUser, UpdateKeycloakUser, KeycloakUserPasswordReset, KeycloakUserID, KeycloakGroupID, AssignGroupsToUser, CopyGroup, DisplayEditPage, UpdateKeycloakGroup } from 'userful-chronos-app-common-js/dist/models/usermgt/usermgt';
import { assignUsersToGroup, getUserManagementDataFromServer, addUser, editUser, resetPassword, deleteUser, disableUser, assignGroupsToUser, getLoginEvents, getAdminEvents, enableUser, addGroup, deletegroup, copyGroup, updateGroup } from './msgs/apiCallers';
import { getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import { KeycloakAdminEvent, KeycloakLoginEvent } from 'userful-chronos-app-common-js/dist/models/usermgt/usermgt';
import { ErrorType, AddKeycloakGroup } from 'userful-chronos-app-common-js/dist/models/usermgt/usermgt';
import { InitState } from 'userful-chronos-app-common-js/dist/models/usermgt/usermgtForm';
import { StringID } from 'userful-chronos-app-common-js/dist/models/common';
import { EventLogRequest } from 'userful-chronos-app-common-js/dist/models/monitoring/Logs';

export interface InitialStateUser {
    umgData: UserManagementData | null,
    loginEvents: Array<KeycloakLoginEvent>;
    adminEvents: Array<KeycloakAdminEvent>;
    error: ErrorType<any>;
    errorBool: boolean;
    displayEditPage: DisplayEditPage;
    initBool: boolean;
    loadState: InitState;
    selectedItems: StringID[];
    selectedGroups: StringID[];
}

const initialStateUser: InitialStateUser = {
    umgData: { users: [], roles: [], groups: [] },
    loginEvents: [],
    adminEvents: [],
    error: null,
    errorBool: false,
    displayEditPage: { display: false, editGroup: null, copyEditGroup: null },
    initBool: false,
    // state to show loading on save or edit
    loadState: "RENDER",
    selectedItems: [],
    selectedGroups: []
};

export const usermgtSlice = createSlice({
    name: 'userSlice',
    initialState: initialStateUser,
    reducers: {
        getUserManagementDataFromServer: (state, action: PayloadAction<{}>) => {
            getUserManagementDataFromServer(getGlobalStates().keycloak.token);
        },
        recieveUseMangementData: (state, action: PayloadAction<UserManagementData>) => {
            state.umgData = action.payload;
        },
        addUser: (state, action: PayloadAction<AddKeycloakUser>) => {
            addUser(action.payload, getGlobalStates().keycloak.token);
        },
        editUser: (state, action: PayloadAction<UpdateKeycloakUser>) => {
            editUser(action.payload, getGlobalStates().keycloak.token);
        },
        resetPassword: (state, action: PayloadAction<KeycloakUserPasswordReset>) => {
            resetPassword(action.payload, getGlobalStates().keycloak.token);
        },
        deleteUser: (state, action: PayloadAction<KeycloakUserID>) => {
            deleteUser(action.payload, getGlobalStates().keycloak.token);
        },
        disableUser: (state, action: PayloadAction<KeycloakUserID>) => {
            disableUser(action.payload, getGlobalStates().keycloak.token);
        },
        enableUser: (state, action: PayloadAction<KeycloakUserID>) => {
            enableUser(action.payload, getGlobalStates().keycloak.token);
        },
        assignGroupsToUser: (state, action: PayloadAction<AssignGroupsToUser>) => {
            assignGroupsToUser(action.payload, getGlobalStates().keycloak.token);
        },
        getLoginEventsFromServer: (state, action: PayloadAction<EventLogRequest>) => {
            getLoginEvents(action.payload, getGlobalStates().keycloak.token);
        },
        recieveLoginEvents: (state, action: PayloadAction<Array<KeycloakLoginEvent>>) => {
            state.loginEvents = action.payload;
        },
        getAdminEventsFromServer: (state, action: PayloadAction<EventLogRequest>) => {
            getAdminEvents(action.payload, getGlobalStates().keycloak.token);
        },
        recieveAdminEvents: (state, action: PayloadAction<Array<KeycloakAdminEvent>>) => {
            state.adminEvents = action.payload;
        },
        changeError: (state, action: PayloadAction<ErrorType<any>>) => {
            state.error = action.payload;
        },
        changeErrorBool: (state, action: PayloadAction<boolean>) => {
            state.errorBool = action.payload;
        },
        addGroup: (state, action: PayloadAction<AddKeycloakGroup>) => {
            addGroup(action.payload, getGlobalStates().keycloak.token);
        },
        deleteGroup: (state, action: PayloadAction<KeycloakGroupID>) => {
            deletegroup(action.payload, getGlobalStates().keycloak.token);
        },
        copyGroup: (state, action: PayloadAction<CopyGroup>) => {
            copyGroup(action.payload, getGlobalStates().keycloak.token);
        },
        assignUsersToGroup: (state, action: PayloadAction<AssignUsersToGroup>) => {
            assignUsersToGroup(action.payload, getGlobalStates().keycloak.token);
        },
        setDisplayEditPage: (state, action: PayloadAction<DisplayEditPage>) => {
            state.displayEditPage = action.payload;
        },
        updateGroup: (state, action: PayloadAction<UpdateKeycloakGroup>) => {
            updateGroup(action.payload, getGlobalStates().keycloak.token);
        },
        afterSaveDisplayEditPage: (state) => {
            state.displayEditPage = { ...state.displayEditPage, copyEditGroup: { ...state.displayEditPage.editGroup } };
        },
        setInitBool: (state, action: PayloadAction<boolean>) => {
            state.initBool = action.payload;
        },
        altLoadState: (state, action: PayloadAction<InitState>) => {
            state.loadState = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<Array<StringID>>) => {
            state.selectedItems = [...action.payload];
        },
        setSelectedGroups: (state, action: PayloadAction<Array<StringID>>) => {
            state.selectedGroups = [...action.payload];
        },
    }
})

export const usermgtSliceActions = usermgtSlice.actions;

export default usermgtSlice.reducer