import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StringID } from 'userful-chronos-app-common-js/dist/models/common';
import { Resolution } from 'userful-chronos-app-common-js/dist/models/display';
import { isUClientLocked, isUClientOffline, UclientData, UClientProvisioningType } from 'userful-chronos-app-common-js/dist/models/uclient';
import { UClientSettings } from 'userful-chronos-app-common-js/dist/models/uclient-setting';
import { displayHasSameIDOrVEAsUpdates, expectUCStatus, getVEIDForClient } from './displayDispatchUtils';
import { sortUClients } from './displaysUtils';
import {
    requestLockUC, requestResetUC, requestUnlockUC, requestUClientShutdown, requestUClientUpdateApp,
    requestFirmwareUpdate, requestUpdateUClientSettings, requestUpdateUCResolution, requestGetUClientProtocolDefault,
    requestSetUClientProtocolDefault, requestUpdateUClientLockTaskMode, requestPowerOnUCDisplay,
    requestPowerOffUCDisplay, requestUpdateUClientTimeZone, requestCleanStorage, requestStorageData, requestUpdateUClientGiadaGrid, requestUpdateUClientProvisioningType
} from './msgs/UClientsMsgSender';
import { SinkProtocolEncodingData } from 'userful-chronos-app-common-js/dist/models/mapping/MappingGroups';
import { UClientStorageDataMsg } from 'userful-chronos-app-common-js/dist/models/uclient/uclientStorage';
import { requestHideUClientStationIDs, requestShowUClientStationIDs } from 'userful-chronos-app-common-js/dist/message/commonMsgSenders/displayMsgSender';
import { UClientGiadaGrid } from 'userful-chronos-app-common-js/dist/models/uclient/uclientGiada';

let updating: { [key: string]: Array<Function>; } = {};

const initialState: {
    displays: Array<{
        data: UclientData,
        storage: UClientStorageDataMsg,
        updating: boolean,
    }>;
    selectedDisplays: StringID[];
    showIDDisplays: StringID[];
    ready: boolean;
    videoEncodingCodec: SinkProtocolEncodingData;
    copyVideoEncodingCodec: SinkProtocolEncodingData;
    currentData: StringID, // for opening settings for edit
    currentAndroidLog: StringID,
    currentConsoleLog: StringID,
    currentEventLog: StringID,
} = {
    displays: [],
    showIDDisplays: [],
    ready: false,
    currentData: null,
    currentAndroidLog: null,
    currentConsoleLog: null,
    currentEventLog: null,
    videoEncodingCodec: {
        audioEncodingAndroid: {
            "@type": "aac",
            maxBitRate: { bps: 128 }
        },
        videoEncodingAndroid: {
            type: 'H_265',
            maxBitRate: { bps: 8000 },
            '@type': 'h26x'

        },
        audioEncodingWebos: {
            "@type": "aac",
            maxBitRate: { bps: 128 }
        },
        videoEncodingWebos: {
            type: 'H_264',
            maxBitRate: { bps: 8000 },
            '@type': 'h26x'

        }
    },
    copyVideoEncodingCodec: {

        audioEncodingAndroid: {
            "@type": "aac",
            maxBitRate: { bps: 128 }
        },
        videoEncodingAndroid: {
            type: 'H_265',
            maxBitRate: { bps: 8000 },
            '@type': 'h26x'

        },
        audioEncodingWebos: {
            "@type": "aac",
            maxBitRate: { bps: 128 }
        },
        videoEncodingWebos: {
            type: 'H_264',
            maxBitRate: { bps: 8000 },
            '@type': 'h26x'


        },
    },
    selectedDisplays: [],
};

const setDisplayToUpdating = (state, index: number) => {
    state.displays = [
        ...state.displays.slice(0, index),
        { ...state.displays[index], updating: true },
        ...state.displays.slice(index + 1),
    ]
}

const setDisplayToNotUpdating = (state, index: number) => {
    state.displays = [
        ...state.displays.slice(0, index),
        { ...state.displays[index], updating: false },
        ...state.displays.slice(index + 1),
    ]
}

const isAllSettingsGetUpdated = (ucData: UclientData, settings: UClientSettings): boolean => {
    if (settings.nameSetting) {
        if (settings.nameSetting.name !== ucData.transientData.name) {
            return false;
        }
    }

    if (settings.locationSetting) {
        if (settings.locationSetting.location !== ucData.transientData.location) {
            return false;
        }
    }

    if (settings.networkSetting) {
        const networkSetting = settings.networkSetting;
        if (networkSetting.dhcpEnabled) {
            if (!ucData.transientData.networkData.addressInfo.dhcpEnabled) {
                return false;
            }
        } else {
            if (ucData.transientData.networkData.addressInfo.dhcpEnabled
                || networkSetting.ipAddress !== ucData.transientData.networkData.addressInfo.ipAddress.value
                || networkSetting.ipAddress !== ucData.transientData.networkData.addressInfo.ipAddress.value) {
                return false;
            }
        }
    }

    return true;
};

export const uclientsSlice = createSlice({
    name: 'uclientsSlice',
    initialState,
    reducers: {
        setDisplays: (state, action: PayloadAction<Array<UclientData>>) => {
            if (state.displays.length > 0) {
                return;
            }
            state.displays = sortUClients(action.payload.map(item => ({ data: item, updating: false, storage: null })));
            state.showIDDisplays = action.payload.filter(d => d.stationIDVisibility === 'VISIBLE' && isUClientLocked(d) && !isUClientOffline(d)).map(d => d.id);
            updating = {};
            state.ready = true;
        },
        setSelectedDisplays: (state, action: PayloadAction<Array<StringID>>) => {
            state.selectedDisplays = [...action.payload];
        },
        setDisplaysForVE: (state, action: PayloadAction<Array<UclientData>>) => {
            if (state.displays.length > 0) {
                return;
            }
            const filteredDisplays = state.displays.filter(display => !displayHasSameIDOrVEAsUpdates(display.data, action.payload));
            state.displays = sortUClients([...filteredDisplays, ...action.payload.map(item => ({ data: item, updating: false, storage: null }))]);
            state.showIDDisplays = action.payload.filter(d => d.stationIDVisibility === 'VISIBLE' && isUClientLocked(d) && !isUClientOffline(d)).map(d => d.id);
            updating = {};
            state.ready = true;
        },
        setCurrentData: (state, action: PayloadAction<StringID>) => {
            state.currentData = action.payload;
        },
        setAndroidLog: (state, action: PayloadAction<StringID>) => {
            state.currentAndroidLog = action.payload;
        },
        setCurrentConsoleLog: (state, action: PayloadAction<StringID>) => {
            state.currentConsoleLog = action.payload;
        },
        setCurrentEventLog: (state, action: PayloadAction<StringID>) => {
            state.currentEventLog = action.payload;
        },

        addOrUpdateDisplays: (state, action: PayloadAction<UclientData>) => {
            const updateIDValue = action.payload.id.value;
            const existingIndex = state.displays.findIndex(item => item.data.id.value === updateIDValue);
            if (existingIndex >= 0) {
                // handle updating status
                if (updating[updateIDValue]
                    && updating[updateIDValue][0](action.payload)) {
                    if (updating[updateIDValue].length > 1) {
                        updating[updateIDValue] = updating[updateIDValue].slice(1);
                    } else {
                        delete updating[updateIDValue];
                    }
                }
                const updateData = {
                    data: action.payload,
                    updating: updating.hasOwnProperty(updateIDValue),
                    storage: isUClientLocked(action.payload) ? state.displays[existingIndex].storage : null,
                };
                state.displays = [
                    ...state.displays.slice(0, existingIndex),
                    updateData,
                    ...state.displays.slice(existingIndex + 1),
                ];

            } else {
                state.displays = sortUClients([
                    ...state.displays,
                    { data: action.payload, updating: false, storage: null },
                ])
            }
            if (isUClientLocked(action.payload) && !isUClientOffline(action.payload)) {
                if (action.payload.stationIDVisibility === 'VISIBLE') {
                    state.showIDDisplays = [...state.showIDDisplays, action.payload.id];
                } else {
                    state.showIDDisplays = state.showIDDisplays.filter(a => a.value !== action.payload.id.value);
                }
            } else {
                state.showIDDisplays = state.showIDDisplays.filter(a => a.value !== action.payload.id.value);
            }
        },

        receiveUClientStorageData: (state, action: PayloadAction<UClientStorageDataMsg>) => {
            const updateIDValue = action.payload.id.value;
            const existingIndex = state.displays.findIndex(item => item.data.id.value === updateIDValue);
            if (existingIndex >= 0) {
                state.displays = [
                    ...state.displays.slice(0, existingIndex),
                    { ...state.displays[existingIndex], storage: { ...action.payload } },
                    ...state.displays.slice(existingIndex + 1),
                ];
            }
        },

        clearUpdatingStatus: (state, action: PayloadAction<string[]>) => {
            for(const seat of action.payload) {
                const existingIndex = state.displays.findIndex(item => item.data.id.value === seat);
                if (existingIndex >= 0) {
                    setDisplayToNotUpdating(state, existingIndex);
                }
            }
        },

        requestCleanStorage: (state, action: PayloadAction<{
            id: StringID,
            deviceName: string,
        }>) => {
            requestCleanStorage(action.payload.id, action.payload.deviceName);
            setTimeout(() => requestStorageData(action.payload.id), 1000);
        },


        deleteDisplay: (state, action: PayloadAction<StringID>) => {
            state.displays = state.displays.filter(item => item.data.id.value !== action.payload.value);
            delete updating[action.payload.value];
        },

        requestResetDisplay: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);
            if (existingIndex >= 0) {
                const connected = state.displays[existingIndex].data.lockStatus === 'LOCKED';
                updating[action.payload.value] = connected ?
                    expectUCStatus(['CONNECTED']) : expectUCStatus(['FREE', 'ORPHAN']);
                setDisplayToUpdating(state, existingIndex);
                requestResetUC(action.payload);
            }
        },

        requestPowerOnDisplay: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);
            if (existingIndex >= 0) {
                requestPowerOnUCDisplay(action.payload);
            }
        },

        requestPowerOffDisplay: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);
            if (existingIndex >= 0) {
                requestPowerOffUCDisplay(action.payload);
            }
        },

        requestLockDisplay: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);
            if (existingIndex >= 0) {
                updating[action.payload.value] = expectUCStatus(['CONNECTED']);
                setDisplayToUpdating(state, existingIndex);
                requestLockUC(action.payload, getVEIDForClient(state.displays[existingIndex].data));
            }
        },


        requestUpdateDisplayResolution: (state, action: PayloadAction<{
            id: StringID,
            resolution: Resolution,
            isCustom: boolean,
        }>) => {
            requestUpdateUCResolution(action.payload.id, action.payload.resolution, action.payload.isCustom);
        },

        requestUnlockDisplay: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);
            if (existingIndex >= 0) {
                updating[action.payload.value] = expectUCStatus(['FREE']);
                setDisplayToUpdating(state, existingIndex);
                requestUnlockUC(action.payload, getVEIDForClient(state.displays[existingIndex].data));
            }
        },

        requestShutdownApp: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);

            if (existingIndex >= 0) {
                updating[action.payload.value] = [(data: UclientData) => isUClientOffline(data)];
                setDisplayToUpdating(state, existingIndex);
                requestUClientShutdown(action.payload);
            }

        },

        requestUClientUpdateApp: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);
            if (existingIndex >= 0) {
                const connected = state.displays[existingIndex].data.lockStatus === 'LOCKED';
                updating[action.payload.value] = connected ?
                    expectUCStatus(['CONNECTED']) : expectUCStatus(['FREE', 'ORPHAN']);
                setDisplayToUpdating(state, existingIndex);
                requestUClientUpdateApp(action.payload);
            }
        },

        requestUClientUpdateFirmware: (state, action: PayloadAction<StringID[]>) => {
            for (const seatID of action.payload) {
                const existingIndex = state.displays.findIndex(item => item.data.id.value === seatID.value);
                if (existingIndex >= 0) {
                    const connected = state.displays[existingIndex].data.lockStatus === 'LOCKED';
                    updating[seatID.value] = connected ?
                        expectUCStatus(['CONNECTED']) : expectUCStatus(['FREE', 'ORPHAN']);
                    setDisplayToUpdating(state, existingIndex);

                }
            }
            requestFirmwareUpdate(action.payload);
        },

        requestUpdateSettings: (state, action: PayloadAction<{ id: StringID, settings: UClientSettings }>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                updating[action.payload.id.value] = [((ucData: UclientData) => isAllSettingsGetUpdated(ucData, action.payload.settings))];
                setDisplayToUpdating(state, existingIndex)
                requestUpdateUClientSettings(action.payload.id, action.payload.settings);
            }
        },

        requestGetUClientProtocolDefault: () => {
            requestGetUClientProtocolDefault();
        },

        setVideoEncodingCodec: (state, action: PayloadAction<SinkProtocolEncodingData>) => {
            state.videoEncodingCodec = action.payload;
        },

        setCopyVideoEncodingCodec: (state, action: PayloadAction<SinkProtocolEncodingData>) => {
            state.copyVideoEncodingCodec = action.payload;
        },
        requestSetVideoEncodingToServer: (state, action: PayloadAction<SinkProtocolEncodingData>) => {
            requestSetUClientProtocolDefault(action.payload);
        },
        discardChangesToVideoCodec: (state, action: PayloadAction<{}>) => {
            state.videoEncodingCodec = state.copyVideoEncodingCodec;
        },
        showStationID: (state, action: PayloadAction<StringID[]>) => {
            const toShow: StringID[] = [];
            if (action.payload) {
                toShow.push(...action.payload.filter(i => state.showIDDisplays.findIndex(s => s.value === i.value) < 0));
            } else {
                // show ID on all displays
                const allDisplays = state.displays.filter(d => isUClientLocked(d.data) && !isUClientOffline(d.data)).map(d => d.data.id);
                toShow.push(...allDisplays.filter(i => state.showIDDisplays.findIndex(s => s.value === i.value) < 0));
            }
            state.showIDDisplays = [...state.showIDDisplays, ...toShow];
            for (const seat of toShow) {
                const foundLabel = state.displays.find(d => isUClientLocked(d.data) && !isUClientOffline(d.data) && d.data.id.value === seat.value)?.data.stationLabel;
                if (foundLabel) {
                    requestShowUClientStationIDs([{ seatID: seat, stationLabel: foundLabel }]);
                }
            }
        },
        hideStationID: (state, action: PayloadAction<StringID[]>) => {
            const toHide: StringID[] = [];
            if (action.payload) {
                toHide.push(...action.payload.filter(i => state.showIDDisplays.findIndex(s => s.value === i.value) >= 0));
            } else {
                // hide ID on all displays
                toHide.push(...state.showIDDisplays);
            }
            state.showIDDisplays = state.showIDDisplays.filter(i => toHide.findIndex(s => s.value === i.value) < 0);
            for (const seat of toHide) {
                const foundLabel = state.displays.find(d => isUClientLocked(d.data) && !isUClientOffline(d.data) && d.data.id.value === seat.value)?.data.stationLabel;
                if (foundLabel) {
                    requestHideUClientStationIDs([{ seatID: seat, stationLabel: foundLabel }]);
                }
            }
        },
        requestSetLockTaskMode: (state, action: PayloadAction<{ id: StringID, value: boolean }>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                const updated: UclientData = {
                    ...state.displays[existingIndex].data,
                    lockTaskMode: action.payload.value,
                }
                state.displays = [
                    ...state.displays.slice(0, existingIndex),
                    { ...state.displays[existingIndex], data: updated },
                    ...state.displays.slice(existingIndex + 1),
                ];
                requestUpdateUClientLockTaskMode(action.payload.id, action.payload.value);
            }
        },
        requestUpdateTimeZone: (state, action: PayloadAction<{ id: StringID, value: string }>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                const updated: UclientData = {
                    ...state.displays[existingIndex].data,
                    timeZone: { value: action.payload.value },
                }
                state.displays = [
                    ...state.displays.slice(0, existingIndex),
                    { ...state.displays[existingIndex], data: updated },
                    ...state.displays.slice(existingIndex + 1),
                ];
                requestUpdateUClientTimeZone(action.payload.id, action.payload.value);
            }
        },
        requestToggleProvisioningType: (state, action: PayloadAction<{ id: StringID, value: UClientProvisioningType }>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                const updatedType = action.payload.value;
                const updated: UclientData = {
                    ...state.displays[existingIndex].data,
                    provisioningType: updatedType,
                }
                state.displays = [
                    ...state.displays.slice(0, existingIndex),
                    { ...state.displays[existingIndex], data: updated },
                    ...state.displays.slice(existingIndex + 1),
                ];
                requestUpdateUClientProvisioningType(action.payload.id, updatedType);
            }
        }
        ,
        requestUpdateGiadaGrid: (state, action: PayloadAction<{ id: StringID, update: UClientGiadaGrid }>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                const updated: UclientData = {
                    ...state.displays[existingIndex].data,
                    giadaGrid: action.payload.update,
                }
                state.displays = [
                    ...state.displays.slice(0, existingIndex),
                    { ...state.displays[existingIndex], data: updated },
                    ...state.displays.slice(existingIndex + 1),
                ];
                requestUpdateUClientGiadaGrid(action.payload.id, action.payload.update);
            }
        }
    },
})


export const uclientsSliceActions = uclientsSlice.actions;

export default uclientsSlice.reducer
