import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    PowerbiPassportHeader, PowerbiPassportID, PowerBIPassport, PowerBILoadState, EmbedURLRequest,
    PowerBIPassportDefault, PowerBIPassportFormState, PowerbiPassportUpdate, PowerBIPassportAuthCode, PowerBICodeChallenge, PowerBIWorkspace, PowerbiReport
} from 'userful-chronos-app-common-js/dist/models/integration/powerbi';
import {
    requestListPassports, requestAddPassport, requestUpdatePassport, requestRemovePassport, requestUpdatePassportWithOAuth,
    requestCodeChallenge, requestListWorkspaces, requestListReports
} from './msgs/powerbiMsgSender';

const initialState: {
    powerbiPassports: PowerBIPassport[];
    loadState: PowerBILoadState;
    passport: PowerBIPassport;
    copyPassport: PowerBIPassport;
    formType: PowerBIPassportFormState;
    openForm: boolean;
    authCode: string;
    codeChallenge: string;
    powerBIWorkspaces: PowerBIWorkspace[],
    powerbiReports: PowerbiReport[]
} = {
    powerbiPassports: [],
    loadState: 'RENDER',
    passport: PowerBIPassportDefault(),
    copyPassport: PowerBIPassportDefault(),
    formType: 'Create',
    openForm: false,
    authCode: "",
    codeChallenge: "",
    powerBIWorkspaces: [],
    powerbiReports: []
};

export const powerbiSlice = createSlice({
    name: 'powerbiSlice',
    initialState,
    reducers: {

        setPassports: (state, action: PayloadAction<PowerBIPassport[]>) => {
            state.powerbiPassports = action.payload;
        },
        addOrUpdatePassport: (state, action: PayloadAction<PowerBIPassport>) => {
            const foundIndex = state.powerbiPassports.findIndex(item => item.id.value === action.payload.id.value);
            if (foundIndex < 0) {
                state.powerbiPassports = [
                    ...state.powerbiPassports,
                    { ...action.payload },
                ];
            } else {
                state.powerbiPassports = [
                    ...state.powerbiPassports.slice(0, foundIndex),
                    { ...action.payload },
                    ...state.powerbiPassports.slice(foundIndex + 1),
                ];
            }
        },
        removePassport: (state, action: PayloadAction<PowerbiPassportID>) => {
            state.powerbiPassports = state.powerbiPassports.filter(item => item.id.value !== action.payload.value);
        },
        getPassportsFromServer: (state, action: PayloadAction<{}>) => {
            requestListPassports();
        },
        addPassportToServer: (state, action: PayloadAction<PowerbiPassportHeader>) => {
            requestAddPassport(action.payload);
        },
        updatePassportToServerWithOAuth: (state, action: PayloadAction<PowerbiPassportHeader>) => {
            requestUpdatePassportWithOAuth(action.payload);
        },
        updatePassportToServer: (state, action: PayloadAction<PowerbiPassportUpdate>) => {
            requestUpdatePassport(action.payload);
        },
        removePassportToServer: (state, action: PayloadAction<PowerbiPassportID>) => {
            requestRemovePassport(action.payload);
        },
        changeLoadState: (state, action: PayloadAction<PowerBILoadState>) => {
            state.loadState = action.payload;
        },
        setPassport: (state, action: PayloadAction<PowerBIPassport>) => {
            state.passport = action.payload;
        },
        setCopyPassport: (state, action: PayloadAction<PowerBIPassport>) => {
            state.copyPassport = action.payload;
        },
        setFormType: (state, action: PayloadAction<PowerBIPassportFormState>) => {
            state.formType = action.payload;
        },
        setOpenForm: (state, action: PayloadAction<boolean>) => {
            state.openForm = action.payload;
        },
        setAuthCode: (state, action: PayloadAction<string>) => {
            state.authCode = action.payload;
        },
        requestCodeChallenge: (state, action: PayloadAction<PowerbiPassportID>) => {
            requestCodeChallenge(action.payload);
        },
        requestWorkspaces: (state, action: PayloadAction<PowerbiPassportID>) => {
            requestListWorkspaces(action.payload);
        },
        requestReports: (state, action: PayloadAction<EmbedURLRequest>) => {
            requestListReports(action.payload);
        },
        setCodeChallenge: (state, action: PayloadAction<string>) => {
            state.codeChallenge = action.payload;
        },
        setWorkspaces: (state, action: PayloadAction<PowerBIWorkspace[]>) => {
            state.powerBIWorkspaces = action.payload;
        },
        setReports: (state, action: PayloadAction<PowerbiReport[]>) => {
            state.powerbiReports = action.payload;
        },
    },
})

export const powerbiActions = powerbiSlice.actions;

export default powerbiSlice.reducer

