import { faChevronDown, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { IAppView, TNavigationStatus } from "../../store/app/appUtils";
import {
    useCarbonSelector,
    selectAppView,
    selectAppNavigationStatus,
    useCarbonDispatch,
} from "../../store/carbonStore";

import "./Navigation.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useState } from "react";
import { AppSliceActions } from "../../store/app/appSlice";
import { faArrowsRotate, faCalendarLines, faChartNetwork, faSparkles, faUserGear, faUserGroup, faUserLock } from "@fortawesome/pro-duotone-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";

interface IProps { }

export default function Navigation(props: IProps) {
    const [userManagementShow, setUserManagementShow] = useState<boolean>(true);
    const [constellationManagementShow, setConstellationManagementShow] = useState<boolean>(true);

    const dispatch = useCarbonDispatch();

    const appView: IAppView = useCarbonSelector(selectAppView);

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    const navigationStatus: TNavigationStatus = useCarbonSelector(selectAppNavigationStatus);

    if (navigationStatus === "HIDDEN") return null;

    const activeConstellations =
        appView.type === "CONSTELLATIONS" || appView.type === "CONSTELLATION" || appView.type === "CLUSTER" || appView.type === "DEVICE";
    const activeUsers = appView.type === "USER" || appView.type === "USERS";
    const activeUserGroups = appView.type === "USER_GROUP" || appView.type === "USER_GROUPS";

    return (
        <div className="navigationWrapper">
            {permissions.isConstellationViewer && (
                <div
                    className={`navigationItem`}
                    onClick={(e) => setConstellationManagementShow(!constellationManagementShow)}
                >
                    <div className={`navigationInsideItem`}>
                        <FontAwesomeIcon icon={faSparkles} />
                        <p>Constellation Management</p>
                    </div>
                    <div className="navigationInsideItem">
                        <FontAwesomeIcon icon={constellationManagementShow ? faChevronDown : faChevronRight} />
                    </div>
                </div>
            )}
            {permissions.isConstellationViewer && constellationManagementShow && (
                <>
                    <div
                        className={`navigationSubItem ${activeConstellations ? "navigationItemActive" : ""}`}
                        onClick={(e) => {
                            dispatch(AppSliceActions.setAppView({ type: "CONSTELLATIONS", id: { value: "NOT_DEFINED" } }));
                        }}
                    >
                        <div className={`navigationInsideItem ${activeConstellations ? "navigationInsideItemActive" : ""}`}>
                            <FontAwesomeIcon icon={faSparkles} />
                            <p>Constellation List</p>
                        </div>
                    </div>
                    {permissions.isClusterSetup && (
                        <div
                            className={`navigationSubItem  ${appView.type === "BACKUP_AND_RESTORE" ? "navigationItemActive" : ""}`}
                            onClick={(e) => {
                                dispatch(AppSliceActions.setAppView({ type: "BACKUP_AND_RESTORE", id: { value: "NOT_DEFINED" } }));
                            }}
                        >
                            <div className={`navigationInsideItem  ${appView.type === "BACKUP_AND_RESTORE" ? "navigationInsideItemActive" : ""}`}>
                                <FontAwesomeIcon icon={faArrowsRotate} />
                                <p>Backup & Restore</p>
                            </div>
                        </div>)}
                    {/* <div
                        className={`navigationSubItem`}
                        onClick={(e) => {
                            dispatch(AppSliceActions.setAppView({ type: "USER_GROUPS", id: { value: "NOT_DEFINED" } }));
                        }}
                    >
                        <div className={`navigationInsideItem `}>
                            <FontAwesomeIcon icon={faCalendarLines} />
                            <p>Schedule</p>
                        </div>
                    </div> */}
                </>
            )}
            {/* {permissions.isConstellationViewer && (
                <div
                    className={`navigationItem`}
                >
                    <div className={`navigationInsideItem`}>
                        <FontAwesomeIcon icon={faChartNetwork} />
                        <p>Endpoint Monitoring</p>
                    </div>
                </div>
            )} */}
            {permissions.isUserViewer && (
                <div className="navigationItem" onClick={(e) => setUserManagementShow(!userManagementShow)}>
                    <div className="navigationInsideItem">
                        <FontAwesomeIcon icon={faUserGear} />
                        <p>User Management</p>
                    </div>
                    <div className="navigationInsideItem">
                        <FontAwesomeIcon icon={userManagementShow ? faChevronDown : faChevronRight} />
                    </div>
                </div>
            )}
            {permissions.isUserViewer && userManagementShow && (
                <>
                    <div
                        className={`navigationSubItem ${activeUsers ? "navigationItemActive" : ""}`}
                        onClick={(e) => {
                            dispatch(AppSliceActions.setAppView({ type: "USERS", id: { value: "NOT_DEFINED" } }));
                        }}
                    >
                        <div className={`navigationInsideItem ${activeUsers ? "navigationInsideItemActive" : ""}`}>
                            <FontAwesomeIcon icon={faUser} />
                            <p>Users</p>
                        </div>
                    </div>
                    <div
                        className={`navigationSubItem ${activeUserGroups ? "navigationItemActive" : ""}`}
                        onClick={(e) => {
                            dispatch(AppSliceActions.setAppView({ type: "USER_GROUPS", id: { value: "NOT_DEFINED" } }));
                        }}
                    >
                        <div className={`navigationInsideItem ${activeUserGroups ? "navigationInsideItemActive" : ""}`}>
                            <FontAwesomeIcon icon={faUserLock} />
                            <p>Permission Sets</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
