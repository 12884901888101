import React from "react";
import "./UserfulTable.scss";
import { ITable } from "./userfulTableInterface";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { UserfulTooltip } from "userful-chronos-common-ui/dist";

interface IProps {
    table: ITable;
    replace?: React.ReactNode;
    onRowClick?(id: StringID): void;
    nonInteractive?: boolean;
    stretch?: boolean;
}

export default function UserfulTable(props: IProps) {

    function hasChildren(node: React.ReactNode): boolean {
        if (node === null || node === undefined) {
            return false;
        }

        if (typeof node === 'boolean') {
            return false;
        }

        if (typeof node === 'string' || typeof node === 'number') {
            return node !== '';
        }

        if (Array.isArray(node)) {
            return node.some(hasChildren);
        }

        if (React.isValidElement(node)) {
            return !!node.props.children;
        }

        return false;
    }

    return (
        <table className="userfulTable" style={{ height: props.stretch ? "100%" : "auto" }}>
            <thead>
                <tr>
                    {props.table.columns.map((c, index) => (
                        <UserfulTooltip title={c.header}>
                            <td
                                key={"header_" + index}
                                style={{ width: c.width ? c.width : "100%", minWidth: c.minWidth ? c.minWidth : c.width }}
                            >
                                <p className="limit">{c.header}</p>
                            </td></UserfulTooltip>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.table.data.map((d, index) => {


                    return (
                        <tr
                            className={`${props.nonInteractive ? "nonInteractive" : ""}`}
                            style={{ zIndex: d.zIndex ? d.zIndex : "" }}
                            key={"row_" + index}
                            onClick={(e) => {
                                if (props.onRowClick) props.onRowClick(d.id);
                            }}
                        >
                            {d.columns.map((c, cIndex) => {

                                return <>
                                    {!c.hideTooltip && hasChildren(c.component) ? <UserfulTooltip title={c.component}>
                                        <td
                                            className={`${c.limit ? "limit" : ""}`}
                                            key={"column_" + cIndex}

                                            style={{
                                                width: props.table.columns[cIndex].width
                                                    ? props.table.columns[cIndex].width
                                                    : "100%",
                                                minWidth: props.table.columns[cIndex].minWidth
                                                    ? props.table.columns[cIndex].minWidth
                                                    : props.table.columns[cIndex].width,

                                            }}
                                        >
                                            {c.component}
                                        </td>
                                    </UserfulTooltip> :
                                        <td
                                            className={`${c.limit ? "limit" : ""}`}
                                            key={"column_u_" + cIndex}

                                            style={{
                                                width: props.table.columns[cIndex].width
                                                    ? props.table.columns[cIndex].width
                                                    : "100%",
                                                minWidth: props.table.columns[cIndex].minWidth
                                                    ? props.table.columns[cIndex].minWidth
                                                    : props.table.columns[cIndex].width,

                                            }}
                                        >
                                            {c.component}
                                        </td>
                                    }
                                </>
                            })}
                        </tr>
                    );
                })}
                {props.replace ? props.replace : null}
            </tbody>
        </table>
    );
}
