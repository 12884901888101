import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faClose, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UIReply } from 'userful-chronos-app-common-js/dist/models/common';
import { isStandalone } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import StandaloneFeatureNotAvailable from '../../Widgets/StandaloneFeatureNotAvailable';
import Button from '../../Button/Button';
import { ROUTE_PATH } from 'userful-chronos-app-common-js/dist/routing';

interface IProps {
    error: UIReply<any>;
    handleClose: () => void;
}

export default function ({ error, handleClose }: IProps) {
    const { t } = useTranslation();

    const sourceName = error.params.find(p => p.name === 'source-name')?.value;
    const filePaths = error.params.find(p => p.name === 'file-path')?.value;
    const seatID = error.params.find(p => p.name === 'seat-id')?.value;

    const goToChopping = () => {
        window.location.replace(`${ROUTE_PATH.ADMIN.SOURCES_DESTINATIONS}?gotovideowallprocessor=true&choppingassets=${encodeURIComponent(filePaths)}&choppingseat=${encodeURIComponent(seatID)}`);
    }

    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                show={!!error}
            >
                <Modal.Header>
                    <div>
                        {!isStandalone() && <>
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: 'var(--userful-orange)', marginRight: '8px' }} />
                            {t('Sources&Destinations.AssetManager.Chopping.missingChopTitle')}
                        </>}
                    </div>
                    <div className='custom-close-btn' onClick={handleClose}>
                        <FontAwesomeIcon icon={faClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <StandaloneFeatureNotAvailable>
                        {t('Sources&Destinations.AssetManager.Chopping.missingChopDesc1', { sourceName })}
                        <br />
                        <br />
                        {t('Sources&Destinations.AssetManager.Chopping.missingChopDesc2')}
                    </StandaloneFeatureNotAvailable>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('Shared.cancel')}
                    </Button>
                    <Button variant="primary" onClick={goToChopping}>
                        {t('Sources&Destinations.AssetManager.Chopping.goToChopping')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}