import { RespondMessageDefinition } from "userful-chronos-app-common-js/dist/message/messageModel";


const CARBON = 3500;
const CARBON_BACKUP = 3501;
const GCM_CLUSTER_CODE = 3502;

export const NOTIFY_CLUSTER_INFO_UPDATED = new RespondMessageDefinition(CARBON, 0);
export const NOTIFY_CLUSTER_UNPAIR = new RespondMessageDefinition(CARBON, 1);
export const NOTIFY_CLUSTER_DASHBOARD_UPDATE = new RespondMessageDefinition(CARBON, 2);

export const NOTIFY_CLUSTER_BACKUP_CREATED = new RespondMessageDefinition(CARBON_BACKUP, 0);
export const NOTIFY_CLUSTER_BACKUP_DELETED = new RespondMessageDefinition(CARBON_BACKUP, 1);

export const NOTIFY_CLUSTER_CODE_CONSUMED = new RespondMessageDefinition(GCM_CLUSTER_CODE, 0);