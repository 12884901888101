import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Button.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IUButton } from "./buttonsUtils";
import { useTranslation } from "react-i18next";

interface IProps {
    button: IUButton;
    active?: boolean;
    onClick?(e): void;
    value?: BooleanType;
    disabled?: boolean;
}

export type BooleanType = "TRUE" | "FALSE";

export default function ToggleButton({ button, onClick, value, disabled, ...props }: IProps) {
    const { t } = useTranslation();

    const [active, setActive] = useState(false);
    const controlledByActive = props.active !== undefined;
    const controlledByValue = value !== undefined;
    const buttonActive = controlledByActive ? props.active : controlledByValue ? value === "TRUE" : active;

    const insertIcon = () => {
        return buttonActive ? (
            <FontAwesomeIcon icon={button.icon} />
        ) : button.secondIcon ? (
            <FontAwesomeIcon icon={button.secondIcon} />
        ) : (
            <FontAwesomeIcon icon={button.icon} />
        );
    };

    if (disabled) {
        return (
            <div
                className={`BUTTON ${button.variant ? button.variant : "SECONDARY"} DISABLED ${button.className || ''}`}
                style={button.fullWidth && { width: "100%" }}
            >
                {button.icon ? <FontAwesomeIcon icon={button.icon} /> : null}
                {!button.hideTitle && <div className="TEXT">{t(button.title)}</div>}
            </div>
        );
    }

    if (button.static)
        return (
            <div className={`STATIC ${button.className || ''}`}>
                <FontAwesomeIcon
                    className={`STATIC-ICON ${button.variant ? button.variant : "SECONDARY"}`}
                    icon={button.icon}
                />
            </div>
        );

    return button.hideOverlay ? (
        <div
            className={`BUTTON ${button.variant ? button.variant : "SECONDARY"} ${buttonActive ? "ACTIVE" : null} ${button.className || ''}`}
            onClick={(e) => {
                setActive(!active);
                if (onClick) onClick(e);
                if (button.onClick) button.onClick(e);
            }}
        >
            {button.icon ? <FontAwesomeIcon icon={button.icon} /> : null}
            {!button.hideTitle && <div className="TEXT">{t(button.title)}</div>}
        </div>
    ) : (
        <OverlayTrigger key={"toggleButton"} placement="top" overlay={<Tooltip>{t(button.title)}</Tooltip>}>
            <div
                className={`BUTTON ${button.variant ? button.variant : "SECONDARY"}  ${buttonActive ? "ACTIVE" : null} ${button.className || ''}`}
                onClick={(e) => {
                    setActive(!active);
                    if (onClick) onClick(e);
                    if (button.onClick) button.onClick(e);
                }}
            >
                {insertIcon()}
                {!button.hideTitle && <div className="TEXT">{t(button.title)}</div>}
            </div>
        </OverlayTrigger>
    );
}
