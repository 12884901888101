"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_UPDATE_SELECT_INPUT = exports.RESPONSE_UPDATE_GAMMA_ALL = exports.RESPONSE_UPDATE_DISPLAY_MODE = exports.RESPONSE_UPDATE_CONTROLLER = exports.RESPONSE_UPDATE_COLOR_TEMPERATURE_ALL = exports.RESPONSE_UPDATE_BRIGHTNESS_ALL = exports.RESPONSE_REMOVE_CONTROLLER = exports.RESPONSE_LIST_CONTROLLERS = exports.RESPONSE_ADD_CONTROLLER = exports.REQUEST_UPDATE_SELECT_INPUT = exports.REQUEST_UPDATE_GAMMA_ALL = exports.REQUEST_UPDATE_DISPLAY_MODE = exports.REQUEST_UPDATE_CONTROLLER = exports.REQUEST_UPDATE_COLOR_TEMPERATURE_ALL = exports.REQUEST_UPDATE_BRIGHTNESS_ALL = exports.REQUEST_REMOVE_CONTROLLER = exports.REQUEST_LIST_CONTROLLERS = exports.REQUEST_ADD_CONTROLLER = exports.NOTIFY_CONTROLLER_UPDATE = void 0;
var _messageModel = require("../../messageModel");
var CONTROLLER = 6000;
var ACTIONS_DISPLAY = 6001;
var ACTIONS_OUTPUT = 6002;
var ACTIONS_INPUT = 6003;
var REQUEST_LIST_CONTROLLERS = exports.REQUEST_LIST_CONTROLLERS = new _messageModel.RequestMessageDefinition(CONTROLLER, 0);
var REQUEST_ADD_CONTROLLER = exports.REQUEST_ADD_CONTROLLER = new _messageModel.RequestMessageDefinition(CONTROLLER, 1);
var REQUEST_UPDATE_CONTROLLER = exports.REQUEST_UPDATE_CONTROLLER = new _messageModel.RequestMessageDefinition(CONTROLLER, 2);
var REQUEST_REMOVE_CONTROLLER = exports.REQUEST_REMOVE_CONTROLLER = new _messageModel.RequestMessageDefinition(CONTROLLER, 3);
var RESPONSE_LIST_CONTROLLERS = exports.RESPONSE_LIST_CONTROLLERS = new _messageModel.RespondMessageDefinition(CONTROLLER, 0);
var RESPONSE_ADD_CONTROLLER = exports.RESPONSE_ADD_CONTROLLER = new _messageModel.RespondMessageDefinition(CONTROLLER, 1);
var RESPONSE_UPDATE_CONTROLLER = exports.RESPONSE_UPDATE_CONTROLLER = new _messageModel.RespondMessageDefinition(CONTROLLER, 2);
var RESPONSE_REMOVE_CONTROLLER = exports.RESPONSE_REMOVE_CONTROLLER = new _messageModel.RespondMessageDefinition(CONTROLLER, 3);
var NOTIFY_CONTROLLER_UPDATE = exports.NOTIFY_CONTROLLER_UPDATE = new _messageModel.RespondMessageDefinition(CONTROLLER, 4);
var REQUEST_UPDATE_DISPLAY_MODE = exports.REQUEST_UPDATE_DISPLAY_MODE = new _messageModel.RequestMessageDefinition(ACTIONS_DISPLAY, 0);
var RESPONSE_UPDATE_DISPLAY_MODE = exports.RESPONSE_UPDATE_DISPLAY_MODE = new _messageModel.RespondMessageDefinition(ACTIONS_DISPLAY, 0);
var REQUEST_UPDATE_BRIGHTNESS_ALL = exports.REQUEST_UPDATE_BRIGHTNESS_ALL = new _messageModel.RespondMessageDefinition(ACTIONS_OUTPUT, 3);
var REQUEST_UPDATE_COLOR_TEMPERATURE_ALL = exports.REQUEST_UPDATE_COLOR_TEMPERATURE_ALL = new _messageModel.RespondMessageDefinition(ACTIONS_OUTPUT, 5);
var REQUEST_UPDATE_GAMMA_ALL = exports.REQUEST_UPDATE_GAMMA_ALL = new _messageModel.RespondMessageDefinition(ACTIONS_OUTPUT, 7);
var RESPONSE_UPDATE_BRIGHTNESS_ALL = exports.RESPONSE_UPDATE_BRIGHTNESS_ALL = new _messageModel.RespondMessageDefinition(ACTIONS_OUTPUT, 3);
var RESPONSE_UPDATE_COLOR_TEMPERATURE_ALL = exports.RESPONSE_UPDATE_COLOR_TEMPERATURE_ALL = new _messageModel.RespondMessageDefinition(ACTIONS_OUTPUT, 5);
var RESPONSE_UPDATE_GAMMA_ALL = exports.RESPONSE_UPDATE_GAMMA_ALL = new _messageModel.RespondMessageDefinition(ACTIONS_OUTPUT, 7);
var REQUEST_UPDATE_SELECT_INPUT = exports.REQUEST_UPDATE_SELECT_INPUT = new _messageModel.RequestMessageDefinition(ACTIONS_INPUT, 4);
var RESPONSE_UPDATE_SELECT_INPUT = exports.RESPONSE_UPDATE_SELECT_INPUT = new _messageModel.RespondMessageDefinition(ACTIONS_INPUT, 4);