import { faAngleDown, faCircleNodes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { ClusterDeviceInfo, IClusterInfo, IConstellationInfo, IDeviceInfo } from "../../../../model/CarbonExternalModels";
import { AppSliceActions } from "../../../../store/app/appSlice";
import { useCarbonDispatch } from "../../../../store/carbonStore";
import { Button } from "userful-chronos-common-ui/dist";

interface IProps {
    foundObject: { cluster: IClusterInfo; constellation: IConstellationInfo, device: ClusterDeviceInfo };
}

export default function DevicesDropdown({ foundObject }: IProps) {
    const dispatch = useCarbonDispatch();

    const allDevices: ClusterDeviceInfo[] = foundObject.cluster.clusterDeviceInfo;


    return (
        <Dropdown className="operationCenterDropdown">
            <Dropdown.Toggle
                bsPrefix="dropdownButtonSwitch"
                as={Button}
                id="dropdownToggle"
            >
                <div className="textInside">{foundObject.device.name}</div>
                <FontAwesomeIcon icon={faAngleDown} />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="dropdownMenuSwitch"
                onKeyDown={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.stopPropagation()}
            >
                {allDevices.length > 1 && (
                    <>
                        {allDevices.map((c) => {
                            if (c.id.value !== foundObject.device.id.value) {
                                return (
                                    <Dropdown.Item
                                        key={c.id.value}
                                        className="dropdownItem"
                                        onClick={() => {
                                            dispatch(
                                                AppSliceActions.setAppView({
                                                    type: "DEVICE",
                                                    id: c.id,
                                                })
                                            );
                                        }}
                                    >
                                        <div className="icon-container">
                                            <FontAwesomeIcon icon={faCircleNodes} />
                                        </div>
                                        <div className="textInside">{c.name}</div>
                                    </Dropdown.Item>
                                );
                            }
                            return null;
                        })}

                    </>
                )}
                {allDevices.length === 1 && <Dropdown.Item
                    className="dropdownItem"
                    disabled
                >
                    <div className="icon-container">
                        <FontAwesomeIcon icon={faCircleNodes} />
                    </div>
                    <div className="textInside">No other devices</div>
                </Dropdown.Item>}
            </Dropdown.Menu>
        </Dropdown>
    );
}
