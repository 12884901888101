import React, { useState } from "react";
import { IUFormField } from "../uFormUtils";
import UFieldTitle from "./UFieldTitle/UFieldTitle";
import "./UFormFields.scss";
import { useTranslation } from "react-i18next";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";

interface IProps {
     field: IUFormField;
     forceValue?: string | number | boolean | undefined;
     forceError?: string;
     onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

export default function UTextarea({ field, forceValue, onItemChange }: IProps) {
     const { t } = useTranslation();

     const [length, setLength] = useState(0);

     const value = useUFormContext().state.fieldState[field.key];
     const { onFieldChange } = useUFormContext();

     const onTextareaChange = (e) => {
          const targetValue: string = e.target.value;
          setLength(targetValue.length);
          if (onItemChange) onItemChange({ [field.key]: targetValue }, field);
          else onFieldChange({ [field.key]: targetValue }, field);
     };

     return (
          <div className="fieldColumnWrapper">
               <UFieldTitle
                    title={field.title}
                    required={field.required}
                    length={length}
                    help={field.help}
                    maxLength={field.maxLength}
                    minLength={field.minLength}
               />
               <textarea
                    className="uTextarea"
                    onChange={onTextareaChange}
                    value={forceValue ? forceValue + "" : value ? value + "" : ""}
                    placeholder={t("Shared.Enter") + " " + t(field.title) + " " + t("Shared.Here")}
                    minLength={field.minLength}
                    maxLength={field.maxLength}
                    disabled={field.disabled}
                    id={field.id || ''}
               />
          </div>
     );
}
