import React, { useState, useEffect } from "react";
import { IUFormField } from "../uFormUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { IUFormDragAndDrop, IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import { Dropdown } from "react-bootstrap";
import { faChevronDown, faFile, faFiles } from "@fortawesome/pro-regular-svg-icons";

import { removeAssetButton, removeAllAssetButton } from "../../Widgets/Buttons/Presets/buttonPresets";
import { generateUUID } from "userful-chronos-app-common-js/dist/utils";
import UploadFile from "../../UploadFile/UploadFile";
import { SamSourceAsset } from "userful-chronos-app-common-js/dist/models/sam/SAMAsset";
import { useSourceAssetSelector } from "userful-chronos-common-store/dist/sourceassets";
import "./UDropdown.scss";
import "./UFormFields.scss";
import ClickButton from "../../Widgets/Buttons/ClickButton";

interface IProps {
    field: IUFormField;
    forceValue?: string | number | boolean | undefined;
    forceError?: string;
    onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

export default function UUpload({ field, forceValue, onItemChange }: IProps) {
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState("");
    const [inputClick, setInputClick] = useState(false);
    const [show, setShow] = useState(false);

    let value = useUFormContext().state.fieldState[field.key];
    if (forceValue) value = forceValue;
    const { onFieldChange } = useUFormContext();

    const existingAssets: SamSourceAsset[] = useSourceAssetSelector((state) => state.assetStore.assets);

    const dragAndDrop: IUFormDragAndDrop = useUFormContext().state.dragAndDrop;
    const onDragAndDrop = useUFormContext().onDragAndDrop;

    useEffect(() => {
        // onMouseMove();
    }, [dragAndDrop]);

    const onInputChange = (e) => {
        setInputValue(e.target.value);
        setShow(true);
    };

    const onInputClick = (e) => {
        setInputValue("");
        setInputClick(true);
        setShow(true);
    };

    const onMouseMove = () => {
        // if (dragAndDrop.target) {
        //     const target = value.findIndex((component) => component.elementID === dragAndDrop.target);

        //     if (target >= 0) {
        //         if (dragAndDrop.currentMousePosition.y - dragAndDrop.mouseDownPosition.y >= 56) {
        //             if (target !== value.length - 1) {
        //                 [value[target], value[target + 1]] = [value[target + 1], value[target]];

        //                 if (onItemChange) onItemChange({ [field.key]: value }, field);
        //                 else onFieldChange({ [field.key]: value }, field, { ...dragAndDrop.mouseDownPosition, y: dragAndDrop.mouseDownPosition.y + 44 });
        //             }
        //         } else if (dragAndDrop.currentMousePosition.y - dragAndDrop.mouseDownPosition.y <= -20) {
        //             if (target !== 0) {
        //                 [value[target], value[target - 1]] = [value[target - 1], value[target]];

        //                 if (onItemChange) onItemChange({ [field.key]: value }, field);
        //                 else onFieldChange({ [field.key]: value }, field, { ...dragAndDrop.mouseDownPosition, y: dragAndDrop.currentMousePosition.y });

        //                 onDragAndDrop({ ...dragAndDrop, target: dragAndDrop.target, mouseDownPosition: { ...dragAndDrop.mouseDownPosition, y: dragAndDrop.mouseDownPosition.y - 44 } });
        //             }
        //         }
        //     }
        // }
    };

    return (
        <div
            className="uUpload"
            // onMouseUp={(e) => {
            //     onDragAndDrop({ ...dragAndDrop, target: null });
            // }}
        >
            <div className="fieldColumnWrapper longGap">
                <UploadFile
                    hideTitle={true}
                    inSource={true}
                    updateSourceAsset={(id: string) => {
                        value.push({ id: { value: id }, elementID: generateUUID() });

                        if (onItemChange) onItemChange({ [field.key]: value }, field);
                        else onFieldChange({ [field.key]: value }, field);
                    }}
                />
                <div className="orContainer">{t("Shared.or")}</div>

                <Dropdown bsPrefix="UFormFullWidthWrapper" autoClose={"outside"}>
                    <Dropdown.Toggle bsPrefix="uformDropdownButtonWithInput" disabled={field.disabled} id={field.id || "dropdownToggle"}>
                        <input
                            className="uInput"
                            onChange={onInputChange}
                            onClick={onInputClick}
                            type="text"
                            value={inputValue}
                            placeholder={t("Shared.choose") + " " + t(field.title)}
                            disabled={field.disabled}
                            style={{ cursor: "pointer" }}
                        />
                        <div className="relativeItem">
                            <FontAwesomeIcon className="UFormDropdownIcon" icon={faChevronDown} onClick={onInputClick} />
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="uformDropdownMenu" show={show}>
                        {existingAssets
                            ? existingAssets.map((asset: SamSourceAsset, index) => {
                                  if (asset.type === field.sourceAssetType && asset.name.includes(inputValue)) {
                                      return (
                                          <Dropdown.Item
                                              key={field.title + "_" + asset.id.value}
                                              className="uformDropdownItem"
                                              onClick={(e) => {
                                                  value.push({ id: { value: asset.id.value }, elementID: generateUUID() });

                                                  if (onItemChange) onItemChange({ [field.key]: value }, field);
                                                  else onFieldChange({ [field.key]: value }, field);
                                              }}
                                          >
                                              <div className="textInside">{asset.name}</div>
                                          </Dropdown.Item>
                                      );
                                  }
                              })
                            : null}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {value.length >= 1 ? (
                <div className="assetsWrapper">
                    <div className="uformBlockInfo">
                        <div className="uformBlockInfoTitle">{t("Shared.assets")}</div>
                        <div className="uformBlockInfoDesc">{t("Shared.assetsDescription")}</div>
                    </div>
                    <div
                        className="assetsListWrapper"
                        id="assetsListWrapper"
                        // onMouseMove={(e) => {
                        //     if (dragAndDrop.target) {
                        //         onDragAndDrop({ ...dragAndDrop, currentMousePosition: { ...dragAndDrop.currentMousePosition, y: e.clientY } });
                        //     }
                        // }}
                    >
                        {value.map((component, index) => {
                            const foundAsset = existingAssets.find((asset) => asset.id.value === component.id.value);

                            let selected = false;
                            if (dragAndDrop.target) selected = dragAndDrop.target === component.elementID;

                            if (foundAsset) {
                                return (
                                    <div
                                        className={`assetContainer ${selected ? "assetSelected" : ""}`}
                                        key={component.elementID + "_assetItem"}
                                        onMouseDown={(e) => {
                                            const element = document.getElementById("uForm");

                                            onDragAndDrop({ ...dragAndDrop, target: component.elementID, mouseDownPosition: { ...dragAndDrop.mouseDownPosition, y: e.currentTarget.offsetTop - element.scrollTop } });
                                        }}
                                    >
                                        <div className="assetInnerContainer">
                                            <div className="assetSVG">
                                                <FontAwesomeIcon icon={faFile} />
                                            </div>
                                            <div className="assetText noselect">{foundAsset.name}</div>
                                        </div>
                                        <ClickButton
                                            button={removeAssetButton}
                                            onClick={(e) => {
                                                value.splice(index, 1);

                                                if (onItemChange) onItemChange({ [field.key]: value }, field);
                                                else onFieldChange({ [field.key]: value }, field);
                                            }}
                                        />
                                    </div>
                                );
                            }
                        })}
                        <div className="assetAllContainer">
                            <div className="assetText">{t("Shared.totalAssets") + " (" + value.length + ")"}</div>
                            <ClickButton
                                button={removeAllAssetButton}
                                onClick={(e) => {
                                    if (onItemChange) onItemChange({ [field.key]: [] }, field);
                                    else onFieldChange({ [field.key]: [] }, field);
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
