"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUser = exports.updateGroup = exports.resetPassword = exports.getUserManagementData = exports.getUserExternAuth = exports.getOpenStatus = exports.getLoginEvents = exports.getClearances = exports.getClearanceActions = exports.getAuthSuccessMsg = exports.getAuthStatus = exports.getAdminEvents = exports.enableOrDisableUser = exports.editLevel = exports.deletegroup = exports.deleteUser = exports.deleteLevel = exports.copyGroup = exports.assignUsersToGroup = exports.assignGroupToUser = exports.addUser = exports.addLevel = exports.addGroup = void 0;
var _routing = require("../../routing");
var _store = require("../data/store");
var _uuid = require("uuid");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var getUserManagementData = exports.getUserManagementData = function getUserManagementData() {
  return JSON.parse(JSON.stringify((0, _store.getStandaloneDataStore)().userManagementData));
};
var getUserExternAuth = exports.getUserExternAuth = function getUserExternAuth() {
  return _objectSpread({}, (0, _store.getStandaloneDataStore)().userExternalAuthData);
};
var getLoginEvents = exports.getLoginEvents = function getLoginEvents() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().loginEvents);
};
var getAdminEvents = exports.getAdminEvents = function getAdminEvents() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().adminEvents);
};
var getClearances = exports.getClearances = function getClearances() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().clearances);
};
var getClearanceActions = exports.getClearanceActions = function getClearanceActions() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().clearanceActions);
};
var getAuthSuccessMsg = exports.getAuthSuccessMsg = function getAuthSuccessMsg() {
  return {
    accessToken: {
      token: "token-1",
      resetPasswordAction: false,
      validPassword: true
    }
  };
};
var getOpenStatus = exports.getOpenStatus = function getOpenStatus() {
  return {
    clientID: "userful-chronos-aether",
    realmName: "userful",
    keycloakAuthPath: "/auth",
    setupRequired: false
  };
};
var getAuthStatus = exports.getAuthStatus = function getAuthStatus() {
  return {
    systemID: {
      value: (0, _store.getStandaloneDataStore)().systemID
    },
    certStatus: {
      status: "ACTIVE",
      sslEnabled: false,
      selfSigned: false,
      serverName: (0, _store.getStandaloneDataStore)().systemID
    },
    keycloakStatus: {
      clientID: "userful-chronos-aether",
      realmName: "userful",
      setupRequired: false,
      keycloakAuthPath: "/auth",
      keycloakSetupStatus: {
        frontEndUrl: window.location.host + (0, _routing.getRootPath)(),
        webOrigins: ["*"],
        keycloakHostName: window.location.host + (0, _routing.getRootPath)()
      }
    }
  };
};
var addUser = exports.addUser = function addUser(user) {
  var userManagementData = getUserManagementData();
  var userID = {
    value: (0, _uuid.v4)()
  };
  userManagementData.users.push(_objectSpread(_objectSpread({}, user), {}, {
    id: userID,
    requiredActions: [],
    federationLink: user.federationLink || null
  }));
  var _iterator = _createForOfIteratorHelper(userManagementData.groups),
    _step;
  try {
    var _loop = function _loop() {
      var group = _step.value;
      var isAddGroup = user.groups.find(function (g) {
        return g.value === group.id.value;
      });
      if (isAddGroup) {
        group.members.push(userID);
      }
    };
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      _loop();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  (0, _store.updateStandaloneDataStore)({
    userManagementData: userManagementData
  });
  return userManagementData;
};
var resetPassword = exports.resetPassword = function resetPassword(data) {
  return getUserManagementData();
};
var updateUser = exports.updateUser = function updateUser(update) {
  var userManagementData = getUserManagementData();
  var foundIndex = userManagementData.users.findIndex(function (u) {
    return u.id.value === update.id.value;
  });
  if (foundIndex >= 0) {
    var updatedUser = _objectSpread(_objectSpread({}, userManagementData.users[foundIndex]), update);
    userManagementData.users.splice(foundIndex, 1, updatedUser);
    var _iterator2 = _createForOfIteratorHelper(userManagementData.groups),
      _step2;
    try {
      var _loop2 = function _loop2() {
        var group = _step2.value;
        var isAddGroup = update.groups.find(function (g) {
          return g.value === group.id.value;
        });
        if (isAddGroup) {
          if (group.members.findIndex(function (m) {
            return m.value === update.id.value;
          }) < 0) {
            group.members.push(update.id);
          }
        } else {
          group.members = group.members.filter(function (item) {
            return item.value !== update.id.value;
          });
        }
      };
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        _loop2();
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
    (0, _store.updateStandaloneDataStore)({
      userManagementData: userManagementData
    });
  }
  return userManagementData;
};
var deleteUser = exports.deleteUser = function deleteUser(id) {
  var userManagementData = getUserManagementData();
  var foundIndex = userManagementData.users.findIndex(function (u) {
    return u.id.value === id.value;
  });
  if (foundIndex >= 0) {
    userManagementData.users.splice(foundIndex, 1)[0];
    var _iterator3 = _createForOfIteratorHelper(userManagementData.groups),
      _step3;
    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var group = _step3.value;
        group.members = group.members.filter(function (item) {
          return item.value !== id.value;
        });
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }
    (0, _store.updateStandaloneDataStore)({
      userManagementData: userManagementData
    });
  }
  return userManagementData;
};
var enableOrDisableUser = exports.enableOrDisableUser = function enableOrDisableUser(id, enable) {
  var userManagementData = getUserManagementData();
  var foundIndex = userManagementData.users.findIndex(function (u) {
    return u.id.value === id.value;
  });
  if (foundIndex >= 0) {
    var updatedUser = _objectSpread(_objectSpread({}, userManagementData.users[foundIndex]), {}, {
      userProfile: _objectSpread(_objectSpread({}, userManagementData.users[foundIndex].userProfile), {}, {
        enabled: enable
      })
    });
    userManagementData.users.splice(foundIndex, 1, updatedUser);
    (0, _store.updateStandaloneDataStore)({
      userManagementData: userManagementData
    });
  }
  return userManagementData;
};
var assignGroupToUser = exports.assignGroupToUser = function assignGroupToUser(update) {
  var userManagementData = getUserManagementData();
  var foundIndex = userManagementData.users.findIndex(function (u) {
    return u.id.value === update.userID.value;
  });
  if (foundIndex >= 0) {
    var updatedUser = _objectSpread(_objectSpread({}, userManagementData.users[foundIndex]), {}, {
      groups: update.groups
    });
    userManagementData.users.splice(foundIndex, 1, updatedUser);
    var _iterator4 = _createForOfIteratorHelper(userManagementData.groups),
      _step4;
    try {
      var _loop3 = function _loop3() {
        var group = _step4.value;
        var isAddGroup = update.groups.find(function (g) {
          return g.value === group.id.value;
        });
        if (isAddGroup) {
          if (group.members.findIndex(function (m) {
            return m.value === update.userID.value;
          }) < 0) {
            group.members.push(update.userID);
          }
        } else {
          group.members = group.members.filter(function (item) {
            return item.value !== update.userID.value;
          });
        }
      };
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        _loop3();
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }
    (0, _store.updateStandaloneDataStore)({
      userManagementData: userManagementData
    });
  }
  return userManagementData;
};
var addGroup = exports.addGroup = function addGroup(group) {
  var userManagementData = getUserManagementData();
  userManagementData.groups.push({
    id: {
      value: (0, _uuid.v4)()
    },
    name: group.name,
    path: '',
    editable: true,
    members: group.groupData.members,
    roles: group.groupData.realmRoles,
    displayName: group.displayName,
    description: group.description,
    attributes: group.groupData.attributes
  });
  (0, _store.updateStandaloneDataStore)({
    userManagementData: userManagementData
  });
  return userManagementData;
};
var deletegroup = exports.deletegroup = function deletegroup(groupID) {
  var userManagementData = getUserManagementData();
  var foundIndex = userManagementData.groups.findIndex(function (u) {
    return u.id.value === groupID.value;
  });
  if (foundIndex >= 0) {
    userManagementData.groups.splice(foundIndex, 1)[0];
    var _iterator5 = _createForOfIteratorHelper(userManagementData.users),
      _step5;
    try {
      for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
        var user = _step5.value;
        user.groups = user.groups.filter(function (g) {
          return g.value !== groupID.value;
        });
      }
    } catch (err) {
      _iterator5.e(err);
    } finally {
      _iterator5.f();
    }
    (0, _store.updateStandaloneDataStore)({
      userManagementData: userManagementData
    });
  }
  return userManagementData;
};
var copyGroup = exports.copyGroup = function copyGroup(data) {
  var userManagementData = getUserManagementData();
  var foundGroup = userManagementData.groups.find(function (u) {
    return u.id.value === data.id.value;
  });
  if (foundGroup) {
    userManagementData.groups.push({
      id: {
        value: (0, _uuid.v4)()
      },
      name: data.name,
      path: '',
      editable: true,
      members: [],
      roles: foundGroup.roles,
      displayName: data.displayName,
      description: data.description,
      attributes: foundGroup.attributes
    });
    (0, _store.updateStandaloneDataStore)({
      userManagementData: userManagementData
    });
  }
  return userManagementData;
};
var assignUsersToGroup = exports.assignUsersToGroup = function assignUsersToGroup(data) {
  var userManagementData = getUserManagementData();
  var foundGroupIndex = userManagementData.groups.findIndex(function (u) {
    return u.id.value === data.groupID.value;
  });
  if (foundGroupIndex >= 0) {
    var updatedGroup = _objectSpread(_objectSpread({}, userManagementData.groups[foundGroupIndex]), {}, {
      members: data.users
    });
    userManagementData.groups.splice(foundGroupIndex, 1, updatedGroup);
    var _iterator6 = _createForOfIteratorHelper(userManagementData.users),
      _step6;
    try {
      var _loop4 = function _loop4() {
        var user = _step6.value;
        var isAddGroup = data.users.find(function (u) {
          return u.value === user.id.value;
        });
        if (isAddGroup) {
          if (user.groups.findIndex(function (m) {
            return m.value === data.groupID.value;
          }) < 0) {
            user.groups.push(data.groupID);
          }
        } else {
          user.groups = user.groups.filter(function (item) {
            return item.value !== data.groupID.value;
          });
        }
      };
      for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
        _loop4();
      }
    } catch (err) {
      _iterator6.e(err);
    } finally {
      _iterator6.f();
    }
    (0, _store.updateStandaloneDataStore)({
      userManagementData: userManagementData
    });
  }
  return userManagementData;
};
var updateGroup = exports.updateGroup = function updateGroup(data) {
  var userManagementData = getUserManagementData();
  var foundIndex = userManagementData.groups.findIndex(function (u) {
    return u.id.value === data.id.value;
  });
  if (foundIndex >= 0) {
    var foundGroup = userManagementData.groups[foundIndex];
    var updatedGroup = _objectSpread(_objectSpread({}, foundGroup), {}, {
      displayName: data.displayName,
      description: data.description,
      roles: data.groupData.realmRoles,
      members: data.groupData.members,
      attributes: data.groupData.attributes
    });
    userManagementData.groups.splice(foundIndex, 1, updatedGroup);
    var _iterator7 = _createForOfIteratorHelper(userManagementData.users),
      _step7;
    try {
      var _loop5 = function _loop5() {
        var user = _step7.value;
        var isAddGroup = data.groupData.members.find(function (u) {
          return u.value === user.id.value;
        });
        if (isAddGroup) {
          if (user.groups.findIndex(function (m) {
            return m.value === data.id.value;
          }) < 0) {
            user.groups.push(data.id);
          }
        } else {
          user.groups = user.groups.filter(function (item) {
            return item.value !== data.id.value;
          });
        }
      };
      for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
        _loop5();
      }
    } catch (err) {
      _iterator7.e(err);
    } finally {
      _iterator7.f();
    }
    (0, _store.updateStandaloneDataStore)({
      userManagementData: userManagementData
    });
  }
  return userManagementData;
};
var addLevel = exports.addLevel = function addLevel(levelData) {
  var clearances = getClearances();
  var _iterator8 = _createForOfIteratorHelper(levelData),
    _step8;
  try {
    for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
      var data = _step8.value;
      var id = {
        value: (0, _uuid.v4)()
      };
      clearances.push(_objectSpread(_objectSpread({}, data), {}, {
        id: id
      }));
    }
  } catch (err) {
    _iterator8.e(err);
  } finally {
    _iterator8.f();
  }
  (0, _store.updateStandaloneDataStore)({
    clearances: clearances
  });
  return clearances;
};
var editLevel = exports.editLevel = function editLevel(levelData) {
  var clearances = getClearances();
  var foundIndex = clearances.findIndex(function (u) {
    return u.id.value === levelData.id.value;
  });
  if (foundIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, clearances[foundIndex]), levelData);
    clearances.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      clearances: clearances
    });
  }
  return clearances;
};
var deleteLevel = exports.deleteLevel = function deleteLevel(id) {
  var clearances = getClearances();
  var foundIndex = clearances.findIndex(function (u) {
    return u.id.value === id.value;
  });
  if (foundIndex >= 0) {
    clearances.splice(foundIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      clearances: clearances
    });
  }
  return clearances;
};