"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_UPDATE_SCHEDULE = exports.RESPONSE_UPDATE_EVENT = exports.RESPONSE_SCHEDULE_EVENT = exports.RESPONSE_REMOVE_SCHEDULE = exports.RESPONSE_REMOVE_EVENT = exports.RESPONSE_LIST_SCHEDULES = exports.RESPONSE_LIST_EVENTS = exports.RESPONSE_CREATE_SCHEDULE = exports.RESPONSE_CREATE_EVENT = exports.RESPONSE_CREATE_AND_SCHEDULE_EVENT = exports.RESPONSE_CANCEL_EVENT = exports.RESPOND_QUERY_SCHEDULABLES = exports.RESPOND_LIST_SCHEDULABLES = exports.REQUEST_UPDATE_SCHEDULE = exports.REQUEST_UPDATE_EVENT = exports.REQUEST_SCHEDULE_EVENT = exports.REQUEST_REMOVE_SCHEDULE = exports.REQUEST_REMOVE_EVENT = exports.REQUEST_QUERY_SCHEDULABLES = exports.REQUEST_LIST_SCHEDULES = exports.REQUEST_LIST_SCHEDULABLES = exports.REQUEST_LIST_EVENTS = exports.REQUEST_CREATE_SCHEDULE = exports.REQUEST_CREATE_EVENT = exports.REQUEST_CREATE_AND_SCHEDULE_EVENT = exports.REQUEST_CANCEL_EVENT = exports.NOTIFY_SCHEDULE_UPDATED = exports.NOTIFY_SCHEDULE_REMOVED = exports.NOTIFY_SCHEDULE_CREATED = exports.NOTIFY_SCHEDULABLE_UPDATED = exports.NOTIFY_SCHEDULABLE_REMOVED = exports.NOTIFY_SCHEDULABLE_CREATED = exports.NOTIFY_EVENT_UPDATED = exports.NOTIFY_EVENT_SCHEDULED = exports.NOTIFY_EVENT_REMOVED = exports.NOTIFY_EVENT_CREATED = exports.NOTIFY_EVENT_CANCELLED = void 0;
var _messageModel = require("../../messageModel");
var SCHED_SCHEDULE = 4800;
var SCHED_EVENT = 4801;
var SCHED_SCHEDULABLE = 4802;

//Schedule
var REQUEST_LIST_SCHEDULES = exports.REQUEST_LIST_SCHEDULES = new _messageModel.RequestMessageDefinition(SCHED_SCHEDULE, 0);
var REQUEST_CREATE_SCHEDULE = exports.REQUEST_CREATE_SCHEDULE = new _messageModel.RequestMessageDefinition(SCHED_SCHEDULE, 1);
var REQUEST_UPDATE_SCHEDULE = exports.REQUEST_UPDATE_SCHEDULE = new _messageModel.RequestMessageDefinition(SCHED_SCHEDULE, 2);
var REQUEST_REMOVE_SCHEDULE = exports.REQUEST_REMOVE_SCHEDULE = new _messageModel.RequestMessageDefinition(SCHED_SCHEDULE, 3);
var RESPONSE_LIST_SCHEDULES = exports.RESPONSE_LIST_SCHEDULES = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULE, 0);
var RESPONSE_CREATE_SCHEDULE = exports.RESPONSE_CREATE_SCHEDULE = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULE, 1);
var RESPONSE_UPDATE_SCHEDULE = exports.RESPONSE_UPDATE_SCHEDULE = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULE, 2);
var RESPONSE_REMOVE_SCHEDULE = exports.RESPONSE_REMOVE_SCHEDULE = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULE, 3);
var NOTIFY_SCHEDULE_CREATED = exports.NOTIFY_SCHEDULE_CREATED = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULE, 4);
var NOTIFY_SCHEDULE_UPDATED = exports.NOTIFY_SCHEDULE_UPDATED = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULE, 5);
var NOTIFY_SCHEDULE_REMOVED = exports.NOTIFY_SCHEDULE_REMOVED = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULE, 6);

//Events
var REQUEST_LIST_EVENTS = exports.REQUEST_LIST_EVENTS = new _messageModel.RequestMessageDefinition(SCHED_EVENT, 0);
var REQUEST_CREATE_EVENT = exports.REQUEST_CREATE_EVENT = new _messageModel.RequestMessageDefinition(SCHED_EVENT, 1);
var REQUEST_UPDATE_EVENT = exports.REQUEST_UPDATE_EVENT = new _messageModel.RequestMessageDefinition(SCHED_EVENT, 2);
var REQUEST_REMOVE_EVENT = exports.REQUEST_REMOVE_EVENT = new _messageModel.RequestMessageDefinition(SCHED_EVENT, 3);
var REQUEST_SCHEDULE_EVENT = exports.REQUEST_SCHEDULE_EVENT = new _messageModel.RequestMessageDefinition(SCHED_EVENT, 4);
var REQUEST_CANCEL_EVENT = exports.REQUEST_CANCEL_EVENT = new _messageModel.RequestMessageDefinition(SCHED_EVENT, 5);
var REQUEST_CREATE_AND_SCHEDULE_EVENT = exports.REQUEST_CREATE_AND_SCHEDULE_EVENT = new _messageModel.RequestMessageDefinition(SCHED_EVENT, 6);
var RESPONSE_LIST_EVENTS = exports.RESPONSE_LIST_EVENTS = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 0);
var RESPONSE_CREATE_EVENT = exports.RESPONSE_CREATE_EVENT = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 1);
var RESPONSE_UPDATE_EVENT = exports.RESPONSE_UPDATE_EVENT = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 2);
var RESPONSE_REMOVE_EVENT = exports.RESPONSE_REMOVE_EVENT = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 3);
var RESPONSE_SCHEDULE_EVENT = exports.RESPONSE_SCHEDULE_EVENT = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 4);
var RESPONSE_CANCEL_EVENT = exports.RESPONSE_CANCEL_EVENT = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 5);
var RESPONSE_CREATE_AND_SCHEDULE_EVENT = exports.RESPONSE_CREATE_AND_SCHEDULE_EVENT = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 6);
var NOTIFY_EVENT_CREATED = exports.NOTIFY_EVENT_CREATED = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 7);
var NOTIFY_EVENT_UPDATED = exports.NOTIFY_EVENT_UPDATED = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 8);
var NOTIFY_EVENT_REMOVED = exports.NOTIFY_EVENT_REMOVED = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 9);
var NOTIFY_EVENT_SCHEDULED = exports.NOTIFY_EVENT_SCHEDULED = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 10);
var NOTIFY_EVENT_CANCELLED = exports.NOTIFY_EVENT_CANCELLED = new _messageModel.RespondMessageDefinition(SCHED_EVENT, 11);

//Schedulable
var REQUEST_QUERY_SCHEDULABLES = exports.REQUEST_QUERY_SCHEDULABLES = new _messageModel.RequestMessageDefinition(SCHED_SCHEDULABLE, 0);
var REQUEST_LIST_SCHEDULABLES = exports.REQUEST_LIST_SCHEDULABLES = new _messageModel.RequestMessageDefinition(SCHED_SCHEDULABLE, 1);
var RESPOND_QUERY_SCHEDULABLES = exports.RESPOND_QUERY_SCHEDULABLES = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULABLE, 0);
var NOTIFY_SCHEDULABLE_CREATED = exports.NOTIFY_SCHEDULABLE_CREATED = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULABLE, 1);
var NOTIFY_SCHEDULABLE_UPDATED = exports.NOTIFY_SCHEDULABLE_UPDATED = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULABLE, 2);
var NOTIFY_SCHEDULABLE_REMOVED = exports.NOTIFY_SCHEDULABLE_REMOVED = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULABLE, 3);
var RESPOND_LIST_SCHEDULABLES = exports.RESPOND_LIST_SCHEDULABLES = new _messageModel.RespondMessageDefinition(SCHED_SCHEDULABLE, 4);