"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_ACCESS_TOKEN_RESPONSE = exports.SECURITY_SERVICE_DESC_RESPONSE = exports.RESPOND_PRE_LOGIN = exports.RESPOND_POST_LOGIN = exports.RESPOND_DISCONNECT = exports.NOTIFY_SERVER_STATUS = void 0;
var _messageModel = require("../../messageModel");
var RESPOND_PRE_LOGIN = exports.RESPOND_PRE_LOGIN = new _messageModel.RespondMessageDefinition(10, 0);
var RESPOND_POST_LOGIN = exports.RESPOND_POST_LOGIN = new _messageModel.RespondMessageDefinition(10, 1);
var RESPOND_DISCONNECT = exports.RESPOND_DISCONNECT = new _messageModel.RespondMessageDefinition(10, 2);
var NOTIFY_SERVER_STATUS = exports.NOTIFY_SERVER_STATUS = new _messageModel.RespondMessageDefinition(10, 3);
var SECURITY_SERVICE_DESC_RESPONSE = exports.SECURITY_SERVICE_DESC_RESPONSE = new _messageModel.RespondMessageDefinition(63, 0);
var SET_ACCESS_TOKEN_RESPONSE = exports.SET_ACCESS_TOKEN_RESPONSE = new _messageModel.RespondMessageDefinition(63, 1);