import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { APP_IDS, StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
     AppInstanceID,
     MapperCompDestinationsUpdate,
     MapperCompHeaderUpdate,
     EdgeMapperComp,
     EdgeInsetCompUpdater,
} from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";
import { mappingGroupsSliceActions } from "../mappingGroupsSlice";
import mappingStore from "../mappingStore";
import {

     RESPONSE_LIST_EDGE_MAPPERS,
     NOTIFY_CREATE_EDGE_MAPPER,
     NOTIFY_UPDATE_EDGE_MAPPER,
     NOTIFY_UPDATE_EDGE_MAPPER_HEADER,
     NOTIFY_UPDATE_EDGE_MAPPER_DESTINATIONS,
     NOTIFY_REMOVE_EDGE_MAPPER,
     NOTIFY_EDGE_MAPPER_INSET_UPDATED,
     NOTIFY_EDGE_MAPPER_INSET_REMOVED,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/mapping/MappingMsgDefinations";

let appID = null;
export const setAppIDForMappingReceiver = (appIDValue: string) => {
     appID = appIDValue;
};

const RESTRICTED_APPIDS = [
     APP_IDS.Artistic,
     APP_IDS.Decisions,
     APP_IDS.Engage,
     APP_IDS.Remote,
     APP_IDS.Spaces,
     APP_IDS.Trends,
     APP_IDS.Switch,
];

const appIDMatch = (instanceID: AppInstanceID) => instanceID.appID.value === appID || !RESTRICTED_APPIDS.includes(instanceID.appID.value);

registerMsgHandler(RESPONSE_LIST_EDGE_MAPPERS, (payload) => {
     console.debug("receive RESPONSE_LIST_EDGE_MAPPERS " + appID);
     const data = JSON.parse(payload) as EdgeMapperComp[];
     mappingStore.dispatch(
          mappingGroupsSliceActions.setMappingGroups(appID ? data.filter((item) => appIDMatch(item.appInstanceID)) : data)
     );
});

registerMsgHandler(NOTIFY_CREATE_EDGE_MAPPER, (payload) => {
     console.debug("receive NOTIFY_CREATE_EDGE_MAPPER");
     const data = JSON.parse(payload) as EdgeMapperComp;
     if (appID && !appIDMatch(data.appInstanceID)) {
          return;
     }
     mappingStore.dispatch(mappingGroupsSliceActions.addMappingGroup(data));
});

registerMsgHandler(NOTIFY_UPDATE_EDGE_MAPPER, (payload) => {
     console.debug("receive NOTIFY_UPDATE_EDGE_MAPPER");
     const data = JSON.parse(payload) as EdgeMapperComp;
     if (appID && !appIDMatch(data.appInstanceID)) {
          return;
     }
     mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroup(data));
});

registerMsgHandler(NOTIFY_UPDATE_EDGE_MAPPER_HEADER, (payload) => {
     console.debug("receive NOTIFY_UPDATE_EDGE_MAPPER_HEADER");
     const data = JSON.parse(payload) as MapperCompHeaderUpdate;
     mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroupHeader(data));
});

registerMsgHandler(NOTIFY_UPDATE_EDGE_MAPPER_DESTINATIONS, (payload) => {
     console.debug("receive NOTIFY_UPDATE_EDGE_MAPPER_DESTINATIONS");
     const data = JSON.parse(payload) as MapperCompDestinationsUpdate;
     mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroupDestinations(data));
});

registerMsgHandler(NOTIFY_EDGE_MAPPER_INSET_UPDATED, (payload) => {
     console.debug("receive NOTIFY_EDGE_MAPPER_INSET_UPDATED");
     const data = JSON.parse(payload) as EdgeInsetCompUpdater;
     mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroupInsetID(data));
});

registerMsgHandler(NOTIFY_EDGE_MAPPER_INSET_REMOVED, (payload) => {
     console.debug("receive NOTIFY_EDGE_MAPPER_INSET_REMOVED");
     const data = JSON.parse(payload) as StringID; // mapper ID
});

registerMsgHandler(NOTIFY_REMOVE_EDGE_MAPPER, (payload) => {
     console.debug("receive NOTIFY_REMOVE_EDGE_MAPPER");
     const data = JSON.parse(payload) as StringID;
     mappingStore.dispatch(mappingGroupsSliceActions.removeMappingGroup(data));
});
