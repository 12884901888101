import { DisplayClientData, prettyPrintStationLabel } from "userful-chronos-app-common-js/dist/models/display";
import { UClientStorageDataMsg } from "userful-chronos-app-common-js/dist/models/uclient/uclientStorage";

export function sortDisplays<T extends DisplayClientData> (displays: Array<{
    data: T;
    updating: boolean;
}>): Array<{
    data: T
    updating: boolean;
}> {
    const sorted = [...displays];
    sorted.sort((a, b) => {
        if (a.data.name || b.data.name) {
            return a.data.name.localeCompare(b.data.name);
        }
        const labelA = prettyPrintStationLabel(a.data.stationLabel);
        const labelB = prettyPrintStationLabel(b.data.stationLabel);
        return labelA.localeCompare(labelB, undefined, {numeric: true, sensitivity: 'base' });
    })
    return sorted;
};

export function sortUClients<T extends DisplayClientData> (displays: Array<{
    data: T;
    updating: boolean;
    storage: UClientStorageDataMsg,
}>): Array<{
    data: T
    updating: boolean;
    storage: UClientStorageDataMsg,
}> {
    const sorted = [...displays];
    sorted.sort((a, b) => {
        if (a.data.name || b.data.name) {
            return a.data.name.localeCompare(b.data.name);
        }
        const labelA = prettyPrintStationLabel(a.data.stationLabel);
        const labelB = prettyPrintStationLabel(b.data.stationLabel);
        return labelA.localeCompare(labelB, undefined, {numeric: true, sensitivity: 'base' });
    })
    return sorted;
};
