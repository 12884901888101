import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    ControlRoomInitData,
    SnapshotComp,
    SnapshotLoadData,
} from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";
import "./msgs/operationsCentersMsgReceiver.ts";
export interface VideoWallsState {
    operationsCenters: Array<ControlRoomInitData>;
}

const initialState: VideoWallsState = {
    operationsCenters: [],
};

const operationsCentersSlice = createSlice({
    name: "operationsCentersSlice",
    initialState,
    reducers: {
        setOperationsCenters(state: VideoWallsState, action: PayloadAction<ControlRoomInitData[]>) {
            state.operationsCenters = [...action.payload];
        },
        addOperationsCenters(state: VideoWallsState, action: PayloadAction<ControlRoomInitData>) {
            state.operationsCenters = [...state.operationsCenters, { ...action.payload }];
        },
        updateOperationsCenters(state: VideoWallsState, action: PayloadAction<ControlRoomInitData>) {
            const updateValue = { ...action.payload };
            const existingIndex = state.operationsCenters.findIndex((item) => item.id.value === updateValue.id.value);
            if (existingIndex >= 0) {
                state.operationsCenters = [
                    ...state.operationsCenters.slice(0, existingIndex),
                    updateValue,
                    ...state.operationsCenters.slice(existingIndex + 1),
                ];
            } else {
                state.operationsCenters = [...state.operationsCenters, updateValue];
            }
        },
        removeOperationsCenters(state: VideoWallsState, action: PayloadAction<StringID>) {
            const existingIndex = state.operationsCenters.findIndex((item) => item.id.value === action.payload.value);
            if (existingIndex >= 0) {
                state.operationsCenters = state.operationsCenters.filter(
                    (item) => item.id.value !== action.payload.value
                );
            }
        },
        addOperationsCentersLayout(state: VideoWallsState, action: PayloadAction<SnapshotComp>) {
            const existingIndex = state.operationsCenters.findIndex(
                (item) => item.configData.sceneID.value === action.payload.sceneID.value
            );
            if (existingIndex >= 0) {
                const foundIndex = state.operationsCenters[existingIndex].snapshots.findIndex((s) => s.current);

                if (foundIndex >= 0) {
                    state.operationsCenters[existingIndex].snapshots[foundIndex].current = false;
                }

                state.operationsCenters[existingIndex].snapshots.push(action.payload);
            }
        },
        removeOperationsCentersLayout(state: VideoWallsState, action: PayloadAction<StringID>) {
            for (let i = 0; i < state.operationsCenters.length; i++) {
                const foundIndex = state.operationsCenters[i].snapshots.findIndex(
                    (s) => s.id.value === action.payload.value
                );

                if (foundIndex >= 0) {
                    state.operationsCenters[i].snapshots.splice(foundIndex, 1);
                }
            }
        },
        updateOperationsCentersLayout(state: VideoWallsState, action: PayloadAction<SnapshotComp>) {
            const existingIndex = state.operationsCenters.findIndex(
                (item) => item.configData.sceneID.value === action.payload.sceneID.value
            );
            if (existingIndex >= 0) {
                const foundIndex = state.operationsCenters[existingIndex].snapshots.findIndex(
                    (s) => s.id.value === action.payload.id.value
                );

                if (foundIndex >= 0) {
                    state.operationsCenters[existingIndex].snapshots[foundIndex] = action.payload;
                }
            }
        },
        updateCurrentOperationsCentersLayout(state: VideoWallsState, action: PayloadAction<SnapshotLoadData>) {
            const existingIndex = state.operationsCenters.findIndex(
                (item) => item.configData.sceneID.value === action.payload.sceneID.value
            );

            if (existingIndex >= 0) {
                const foundIndex = state.operationsCenters[existingIndex].snapshots.findIndex((s) => s.current);

                if (foundIndex >= 0) {
                    if (
                        state.operationsCenters[existingIndex].snapshots[foundIndex].id.value !==
                        action.payload.id.value
                    ) {
                        state.operationsCenters[existingIndex].snapshots[foundIndex].current = false;
                    }
                }

                const index = state.operationsCenters[existingIndex].snapshots.findIndex(
                    (s) => s.id.value === action.payload.id.value
                );

                if (index >= 0) {
                    state.operationsCenters[existingIndex].snapshots[index].current = true;
                }
            }
        },
    },
});

export const {
    setOperationsCenters,
    addOperationsCenters,
    updateOperationsCenters,
    removeOperationsCenters,
    addOperationsCentersLayout,
    removeOperationsCentersLayout,
    updateOperationsCentersLayout,
    updateCurrentOperationsCentersLayout,
} = operationsCentersSlice.actions;

export default operationsCentersSlice.reducer;
