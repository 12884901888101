import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { requestCreateSourceDef, requestRemoveSourceDef, requestUpdateSourceDef } from './msgs/MsgSender';
import { createUpdateDataFromSourceDef, SamSourceDef, SamSourceDefCreator } from 'userful-chronos-app-common-js/dist/models/sam/SAMSource';
import { SamSourceSpec } from 'userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec';
import { UrlAssetData } from 'userful-chronos-app-common-js/dist/models/sam/SAMAsset';
import { createEmeraldSource, createWebBrowserSourceFromURL, createSapphireSource, createPowerBiSource, PowerBiData } from 'userful-chronos-app-common-js/dist/models/sam/SAMUtils';
import { Resolution } from 'userful-chronos-app-common-js/dist/models/display';
import { sortByName } from './sourceAssetsUtils';
import { SamUsageDataResponse } from 'userful-chronos-app-common-js/dist/models/sam/Common';
import { ConfirmationToastContentStore } from '../../Container/ConfirmationToastContentStore';
import { toast } from "react-toastify";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

const initialState: {
    sources: Array<SamSourceDef>;
    sourceSpecs: Array<SamSourceSpec>;
    pendingUpdateSource: SamUsageDataResponse & { receivedResponse: boolean }, // expected responses when updating/deleteing
    pendingDeleteSource: SamUsageDataResponse & { receivedResponse: boolean },
    ready: boolean;
    expectedConfirmation: string | null;

} = {
    sources: [],
    sourceSpecs: [],
    ready: false,
    pendingUpdateSource: null,
    pendingDeleteSource: null,
    expectedConfirmation: null
};

export const sourceSlice = createSlice({
    name: 'sourceSlice',
    initialState,
    reducers: {
        addOrUpdateSourceToServer: (state, action: PayloadAction<SamSourceDefCreator>) => {
            const existingIndex = state.sources.findIndex(item => item.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                state.pendingUpdateSource = { itemID: action.payload.id.value, usageData: [], receivedResponse: false };
                requestUpdateSourceDef(action.payload, false);
            } else {
                requestCreateSourceDef(action.payload);
                state.pendingUpdateSource = { itemID: action.payload.id.value, usageData: [], receivedResponse: true };
            }
            state.expectedConfirmation = action.payload.id.value;
        },
        forceUpdateSourceToServer: (state, action: PayloadAction<SamSourceDefCreator>) => {
            state.pendingUpdateSource = null;
            requestUpdateSourceDef(action.payload, true);
        },
        deleteSourceToServer: (state, action: PayloadAction<string>) => {
            state.pendingDeleteSource = { itemID: action.payload, usageData: [], receivedResponse: false };
            requestRemoveSourceDef({
                id: {
                    value: action.payload
                },
                appInstanceID: {
                    value: action.payload,
                    appID: {
                        value: getGlobalStates().appID.value,
                    }
                }
            }, false);
        },
        forceDeleteSourceToServer: (state, action: PayloadAction<string>) => {
            state.pendingDeleteSource = null;
            requestRemoveSourceDef({
                id: {
                    value: action.payload
                },
                appInstanceID: {
                    value: action.payload,
                    appID: {
                        value: getGlobalStates().appID.value,
                    }
                }

            }, true);
        },
        clearPendingUpdateOrDelete: (state) => {
            state.pendingDeleteSource = null;
            state.pendingUpdateSource = null;
        },
        receiveUpdateOrDeletePlaylistResponse: (state, action: PayloadAction<SamUsageDataResponse>) => {
            if (state.pendingDeleteSource && state.pendingDeleteSource.itemID === action.payload.itemID) {
                state.pendingDeleteSource = { ...action.payload, receivedResponse: true };
            } else if (state.pendingUpdateSource && state.pendingUpdateSource.itemID === action.payload.itemID) {
                state.pendingUpdateSource = { ...action.payload, receivedResponse: true };
            }
        },
        deleteSourceWithURLToServer: (state, action: PayloadAction<string>) => {
            let foundSource = state.sources.find(source =>
                source.assets.findIndex(asset =>
                    asset.sourceAssets.findIndex(item => (item.assetData as UrlAssetData).url === action.payload) >= 0) >= 0);
            if (!foundSource) {
                foundSource = state.sources.find(source => source.params.findIndex(param => param.value === action.payload) >= 0);
            }
            if (foundSource) {
                console.debug('Removing source using url');
                requestRemoveSourceDef({
                    id: {value: foundSource.id.value},
                    appInstanceID: {
                        value: getGlobalStates().systemID,
                        appID: {
                            value: getGlobalStates().appID.value,
                        }
                    }
                }, true);
            }
        },

        createEmeraldSource: (state, action: PayloadAction<{
            name?: string;
            description?: string;
            appID?: string;
        }>) => {
            const source = createEmeraldSource({
                name: action.payload.name,
                description: action.payload.description,
                appID: action.payload.appID,
            })
            requestCreateSourceDef(source)
        },

        createSourceFromURL: (state, action: PayloadAction<{
            name: string;
            description: string;
            url: string;
            appID?: string;
        }>) => {
            const source = createWebBrowserSourceFromURL({
                name: action.payload.name,
                description: action.payload.description,
                url: action.payload.url,
                appID: action.payload.appID,
            });
            requestCreateSourceDef(source);
        },

        updateSourceNameWithURL: (state, action: PayloadAction<{
            newName: string;
            url: string;
        }>) => {
            const foundSource = state.sources.find(source => source.params.findIndex(param => param.value === action.payload.url) >= 0);
            if (foundSource) {
                const updateData: SamSourceDefCreator = {
                    ...createUpdateDataFromSourceDef(foundSource),
                    name: action.payload.newName,
                }
                requestUpdateSourceDef(updateData, true);

            }
        },

        createPowerBiSource: (state, action: PayloadAction<{
            data: PowerBiData,
            appID?: string;
            displayResolution: Resolution,
        }>) => {
            const source = createPowerBiSource({
                id: action.payload.data.id,
                name: action.payload.data.name,
                description: action.payload.data.description,
                email: action.payload.data.email,
                password: action.payload.data.password,
                clientID: action.payload.data.clientID,
                reportURL: action.payload.data.reportURL,
            }, action.payload.displayResolution, action.payload.appID);
            requestCreateSourceDef(source);
        },

        createSapphireSource: (state, action: PayloadAction<{
            id?: string;
            name: string;
            description?: string;
            link: string;
            airgapped: boolean;
            roomosdevice: string;
            webexAuthToken:string;
            globalStateLink:string;
            message: string;
            background?: string;
            solidBackground?: string;
            width: number;
            height: number;
            appID?: string;
            simplified: boolean;
            webexuniqueid?: string;
            turnServerID: string;
            turnServerUrl: string;
            turnServerUsername: string;
            turnServerPassword: string;
        }>) => {
            const source = createSapphireSource({
                id: action.payload.id,
                name: action.payload.name,
                description: action.payload.description,
                link: action.payload.link,
                airgapped: action.payload.airgapped,
                roomosdevice: action.payload.roomosdevice,
                webexAuthToken:action.payload.webexAuthToken,
                globalStateLink:action.payload.globalStateLink,
                message: action.payload.message,
                background: action.payload?.background,
                solidBackground: action.payload?.solidBackground,
                width: action.payload.width,
                height: action.payload.height,
                appID: action.payload.appID,
                simplified: action.payload.simplified,
                webexuniqueid:action.payload.webexuniqueid,
                turnServerID: action.payload.turnServerID,
                turnServerUrl: action.payload.turnServerUrl,
                turnServerUsername: action.payload.turnServerUsername,
                turnServerPassword: action.payload.turnServerPassword,
            })
            requestCreateSourceDef(source)
        },

        setSources: (state, action: PayloadAction<Array<SamSourceDef>>) => {
            state.ready = true;
            console.debug(`Got ${action.payload.length} sources`);
            state.sources = sortByName(action.payload);
        },

        setSourceSpecs: (state, action: PayloadAction<Array<SamSourceSpec>>) => {
            console.debug(`Got ${action.payload.length} sourcespecs`);
            state.sourceSpecs = [...action.payload];
        },

        addSource: (state, action: PayloadAction<SamSourceDef>) => {
            const existingIndex = state.sources.findIndex(item => item.id.value === action.payload.id.value);
            if (existingIndex < 0) {
                state.sources = sortByName([
                    ...state.sources,
                    { ...action.payload },
                ]);
                if (state.expectedConfirmation === action.payload.id.value) {
                    const customDisplay = ConfirmationToastContentStore(action.payload.name, "Save");
                    toast(customDisplay, { containerId: 'confirmationContainer' });
                    state.expectedConfirmation = null;
                }
            }
        },

        updateSource: (state, action: PayloadAction<SamSourceDef>) => {
            const existingIndex = state.sources.findIndex(item => item.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                state.sources = sortByName([
                    ...state.sources.slice(0, existingIndex),
                    { ...action.payload },
                    ...state.sources.slice(existingIndex + 1),
                ]);
                if (state.expectedConfirmation === action.payload.id.value) {
                    const customDisplay = ConfirmationToastContentStore(action.payload.name, "Edit");
                    toast(customDisplay, { containerId: 'confirmationContainer' });
                    state.expectedConfirmation = null;
                }
            }
        },

        addOrUpdateSourceSpec: (state, action: PayloadAction<SamSourceSpec>) => {
            const existingIndex = state.sourceSpecs.findIndex(item => item.header.id.value === action.payload.header.id.value);
            if (existingIndex >= 0) {
                state.sourceSpecs = [
                    ...state.sourceSpecs.slice(0, existingIndex),
                    { ...action.payload },
                    ...state.sourceSpecs.slice(existingIndex + 1),
                ]
            } else {
                state.sourceSpecs = [
                    ...state.sourceSpecs,
                    { ...action.payload },
                ]
            }
        },

        deleteSource: (state, action: PayloadAction<string>) => {
            state.sources = sortByName(state.sources.filter(item => item.id.value !== action.payload));
        },
    },
})

export const sourceSliceActions = sourceSlice.actions;

export default sourceSlice.reducer

