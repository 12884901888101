import React, { ReactNode, useEffect } from 'react';
import { Provider } from 'react-redux';
import usermgtStore, { UsermgtContext } from './usermgtStore';
import { getClearanceLevelsFromServer, getClearanceLevelsFromServerThatUserHasAccess, getFilteredClearanceLevelsFromServer } from './msgs/levelCallers';
import { getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import { getUserManagementDataFromServer } from './msgs/apiCallers';
interface IProps {
    appID?: string;
    children: ReactNode,
}

function UsermgtProvider(props: IProps) {

    // fetching clearance level data after evvery 5 seconds
    useEffect(() => {
        getClearanceLevelsFromServer(getGlobalStates().keycloak?.token);
        getClearanceLevelsFromServerThatUserHasAccess(getGlobalStates().keycloak?.token);
        getFilteredClearanceLevelsFromServer(getGlobalStates().keycloak?.token);
        const allLevels = setInterval(() =>
            getClearanceLevelsFromServer(getGlobalStates().keycloak?.token), 5000);
        const levelsThatUserHasAccess = setInterval(() =>
            getClearanceLevelsFromServerThatUserHasAccess(getGlobalStates().keycloak?.token), 5000);
        const filteredLevels = setInterval(() =>
            getFilteredClearanceLevelsFromServer(getGlobalStates().keycloak?.token), 5000);

        return () => {
            clearInterval(allLevels);
            clearInterval(levelsThatUserHasAccess);
            clearInterval(filteredLevels);
        }
    }, []);
    // fetching user management data only once.
    // fething all the data every 5 seconds will be heavy process
    useEffect(() => {
        getUserManagementDataFromServer(getGlobalStates().keycloak?.token);
    }, []);


    return (
        <Provider store={usermgtStore} context={UsermgtContext}>
            {props.children}
        </Provider>
    );
}

export default UsermgtProvider;