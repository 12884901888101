import { faMagnifyingGlass, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import { useState } from "react";
import "./UserfulSearchBar.scss";
import React from "react";

interface IProps {
    onChange: Function;
    forceValue?: string;
    placeholder?: string;
    width?: number;
}

/**
 * SearchBar
 * @param props onChange, function; whenever user types anything into the input field, onChange will return the string containing value of the input field.
 * @returns React.Component
 */
function UserfulSearchBar(props: IProps ) {
    const [input, setInput] = useState("");

    let value = input;
    if (props.forceValue !== undefined) value = props.forceValue;

    const onChange = (e) => {
        const value = e.target.value;
        props.onChange(value);
        setInput(value);
    };

    return (
        <div style={{width: props.width ? props.width : ""}} className="searchBar" >
            <FontAwesomeIcon icon={faMagnifyingGlass} className="searchBarIcon" />
            <input className="searchBarInput " type="text" value={value} onChange={onChange} placeholder = {props.placeholder || "Search..."}/>
            {value.length > 0 && (
                <FontAwesomeIcon
                    icon={faXmark}
                    className="searchBarIconClick"
                    onClick={(e) => {
                        props.onChange("");
                        setInput("");
                    }}
                />
            )}
        </div>
    );
}

export default UserfulSearchBar;
