import I18nextConfig from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const i18next_DEBUG = "i18ndebug";
let softwareVersion = "1.1029-dev";
try {
    const { version } = require("./i18version");
    if (version) {
        softwareVersion = version;
    }
} catch {}
console.log(`Software Version: ${softwareVersion}`);

let showDebug = false;
try {
    const urlParams = new URLSearchParams(window.location.search);
    const debugFlag = urlParams.get(i18next_DEBUG);
    showDebug = debugFlag === "true" || debugFlag === "t";
} catch (e) {}

if (!I18nextConfig.isInitialized) {
    I18nextConfig.use(initReactI18next)
        .use(ChainedBackend)
        .use(LanguageDetector)
        .init({
            fallbackLng: "en",
            load: "languageOnly",
            interpolation: { escapeValue: false },
            backend: {
                backends: [LocalStorageBackend, HttpBackend],
                backendOptions: [
                    {
                        // change these version numbers after translation has been updated to refresh cache
                        defaultVersion: softwareVersion || "unknown version",
                    },
                    {
                        loadPath: `./assets/i18n/{{lng}}.json`,
                    },
                ],
            },

            detection: {
                order: ["querystring", "navigator"],
                lookupQuerystring: "lang",
                caches: [],
                excludeCacheFor: [],
            },
            react: {
                useSuspense: false,
            },
            debug: showDebug,
        });
}
export default I18nextConfig;
