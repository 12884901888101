import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import {
    NOTIFY_CONTROLLER_UPDATE,
    RESPONSE_ADD_CONTROLLER,
    RESPONSE_LIST_CONTROLLERS,
    RESPONSE_REMOVE_CONTROLLER,
    RESPONSE_UPDATE_BRIGHTNESS_ALL,
    RESPONSE_UPDATE_COLOR_TEMPERATURE_ALL,
    RESPONSE_UPDATE_CONTROLLER,
    RESPONSE_UPDATE_DISPLAY_MODE,
    RESPONSE_UPDATE_GAMMA_ALL,
    RESPONSE_UPDATE_SELECT_INPUT,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/novastarMsgDefinitions";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    NovastarController,
    NovastarControllerHeader,
    NovastarDisplaySetMode,
    NovastarOutputBrightnessAllAdjustment,
    NovastarOutputColorTemperatureAllAjustment,
    NovastarOutputGammaAllAdjustment,
    NovastarSelectInputSource,
} from "userful-chronos-app-common-js/dist/models/integration/novastar";
import integrationStore from "../integrationStore";
import { novastarActions } from "../novastarSlice";

//
registerMsgHandler(RESPONSE_LIST_CONTROLLERS, (payload) => {
    console.debug("receive RESPONSE_LIST_CONTROLLERS");
    const data = JSON.parse(payload) as NovastarController[];
    integrationStore.dispatch(novastarActions.setControllers(data));
});

registerMsgHandler(RESPONSE_ADD_CONTROLLER, (payload) => {
    console.debug("receive RESPONSE_ADD_CONTROLLER");
    const data = JSON.parse(payload) as NovastarControllerHeader;
});
registerMsgHandler(RESPONSE_REMOVE_CONTROLLER, (payload) => {
    console.debug("receive RESPONSE_REMOVE_CONTROLLER");
    const data = JSON.parse(payload) as StringID;
    integrationStore.dispatch(novastarActions.removeController(data));
});
registerMsgHandler(RESPONSE_UPDATE_CONTROLLER, (payload) => {
    console.debug("receive RESPONSE_UPDATE_CONTROLLER");
    const data = JSON.parse(payload) as NovastarControllerHeader;
});
registerMsgHandler(NOTIFY_CONTROLLER_UPDATE, (payload) => {
    console.debug("receive NOTIFY_CONTROLLER_UPDATE");
    const data = JSON.parse(payload) as NovastarController;
    integrationStore.dispatch(novastarActions.addOrUpdateController(data));
});
registerMsgHandler(RESPONSE_UPDATE_DISPLAY_MODE, (payload) => {
    console.debug("receive RESPONSE_UPDATE_DISPLAY_MODE");
    const data = JSON.parse(payload) as NovastarDisplaySetMode;
});
registerMsgHandler(RESPONSE_UPDATE_BRIGHTNESS_ALL, (payload) => {
    console.debug("receive RESPONSE_UPDATE_BRIGHTNESS_ALL");
    const data = JSON.parse(payload) as NovastarOutputBrightnessAllAdjustment;
});
registerMsgHandler(RESPONSE_UPDATE_COLOR_TEMPERATURE_ALL, (payload) => {
    console.debug("receive RESPONSE_UPDATE_COLOR_TEMPERATURE_ALL");
    const data = JSON.parse(payload) as NovastarOutputColorTemperatureAllAjustment;
});
registerMsgHandler(RESPONSE_UPDATE_GAMMA_ALL, (payload) => {
    console.debug("receive RESPONSE_UPDATE_GAMMA_ALL");
    const data = JSON.parse(payload) as NovastarOutputGammaAllAdjustment;
});
registerMsgHandler(RESPONSE_UPDATE_SELECT_INPUT, (payload) => {
    console.debug("receive RESPONSE_UPDATE_SELECT_INPUT");
    const data = JSON.parse(payload) as NovastarSelectInputSource;
});
