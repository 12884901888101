"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NOTIFY_NOTIFICATION = exports.NOTIFY_CAPACITY_LIMIT = void 0;
var _messageModel = require("../../messageModel");
var CAPACITY_NOTIFICATION = 1800;
var ERROR_NOTIFICATION = 3025;
var NOTIFY_CAPACITY_LIMIT = exports.NOTIFY_CAPACITY_LIMIT = new _messageModel.RespondMessageDefinition(CAPACITY_NOTIFICATION, 0);
var NOTIFY_NOTIFICATION = exports.NOTIFY_NOTIFICATION = new _messageModel.RespondMessageDefinition(ERROR_NOTIFICATION, 2);