import { faAngleDown, faAngleRight, faFolderOpen, faTv } from "@fortawesome/free-solid-svg-icons";
import { faFolders, faGrid, faSpeaker } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { CdmDestinationGroup } from "userful-chronos-app-common-js/dist/models/cdm/CdmGroup";
import { CdmPhysicalAudioDest, CdmPhysicalVideoDest } from "userful-chronos-app-common-js/dist/models/cdm/CdmPhysical";
import { CdmVideoWallDest } from "userful-chronos-app-common-js/dist/models/cdm/CdmVideoWall";
import { prettyPrintStationLabel } from "userful-chronos-app-common-js/dist/models/display";
import {
     groupHasVideoWallDestination,
     groupHasVideoDestination,
     groupHasAudioDestination,
} from "userful-chronos-app-common-js/dist/models/cdm/DestinationsUtils";
import "./DestinationGroupWidget.scss";
import VideoWallWidget from "../VideoWall/VideoWallWidget";

interface IProps {
     group: CdmDestinationGroup;
     videoWalls: CdmVideoWallDest[];
     videos: CdmPhysicalVideoDest[];
     audios: CdmPhysicalAudioDest[];
     defaultShow?: boolean;
     className?: string;
}

export default function DestinationGroupWidget({ group, className, defaultShow, ...props }: IProps) {
     const videoWalls = props.videoWalls.filter((videowall) => groupHasVideoWallDestination(group, videowall));
     const videos = props.videos.filter((video) => groupHasVideoDestination(group, video));
     const audios = props.audios.filter((audio) => groupHasAudioDestination(group, audio));

     const [show, setShow] = useState(defaultShow || false);

     return (
          <div className={`destinationGroupWidget ${className || ""}`}>
               <div onClick={() => setShow(!show)} className="destinationGroupSummary">
                    {!defaultShow && (
                         <>
                              <FontAwesomeIcon icon={show ? faFolderOpen : faFolders} />:{" "}
                         </>
                    )}
                    <FontAwesomeIcon icon={faTv} /> {`x${videos.length} `}
                    <FontAwesomeIcon icon={faGrid} /> {`x${videoWalls.length} `}
                    <FontAwesomeIcon icon={faSpeaker} /> {`x${audios.length} `}
                    {!defaultShow && <FontAwesomeIcon icon={show ? faAngleDown : faAngleRight} />}
               </div>
               <Collapse in={show}>
                    <div>
                         <div className="destinationGroupCollapse">
                              {videos.map((item, index) => (
                                   <div className="display stackedDisplays" key={`dest-group-item-${item.id.value}`}>
                                        <img src="./assets/monitor.svg" />
                                        <div className="display-number">{prettyPrintStationLabel(item.endPoint.label)}</div>
                                   </div>
                              ))}
                              <br />
                              {videoWalls.map((item) => (
                                   <div className="stackedVideoWalls" key={`dest-group-item-${item.id.value}`}>
                                        <VideoWallWidget videoWall={item} />
                                   </div>
                              ))}
                         </div>
                    </div>
               </Collapse>
          </div>
     );
}
