import { SourceClearanceTable } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import React from "react";
import Form from "react-bootstrap/Form";
import UserfulReactTable2 from "../table/v2/UserfulReactTable2";
import { ColumnDef, sortingFns } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import "./SourcePermissionTable.scss";

interface IProps {
    data: SourceClearanceTable[];
    onChange: Function;
}

export function sortArray(data: any, key: string): any {
    return [...data].sort((a, b) => {
        if (a[key] < b[key]) {
            return -1;
        } else if (a[key] > b[key]) {
            return 1;
        } else {
            return 0;
        }
    });
}

const SourcePermissionTable = (props: IProps) => {
    const { t } = useTranslation();

    const tableData = sortArray(props.data, "name");

    const columns = React.useMemo<ColumnDef<SourceClearanceTable>[]>(() => {
        const result: ColumnDef<SourceClearanceTable, any>[] = [
            {
                header: () => <div className="flex">{t("CommonUI.Sources.Name")}</div>,
                accessorFn: (row) => {
                    return row.name;
                },
                sortingFn: sortingFns.alphanumeric,
                id: "name",
                size: 50,
                maxSize: 50,
                enableResizing: true,
            },

            {
                header: () => <div className="flex">{t("CommonUI.Sources.Edit")}</div>,
                accessorFn: (row) => {
                    return row;
                },
                enableSorting: false,
                id: "edit",
                cell: (info) => {
                    const row = info.getValue();

                    // if (row.edit === null)
                    //     return <></>
                    return (
                        <Form.Check
                            aria-label="name"
                            id={`${row.name}-edit`}
                            onChange={(e) => props.onChange(e.target.checked, row.edit.id.value)}
                            name={`${row.name}-edit`}
                            checked={row?.edit?.check}
                            disabled={row.edit === null}
                            key={`${row.name}-edit`}
                        />
                    );
                },
            },

            {
                header: () => <div className="flex">{t("CommonUI.Sources.Execute")}</div>,
                accessorFn: (row) => {
                    return row;
                },
                enableSorting: false,
                id: "execute",
                cell: (info) => {
                    const row = info.getValue();

                    // if (row.execute === null)
                    //     return <></>
                    return (
                        <Form.Check
                            aria-label="name"
                            id={`${row.name}-execute`}
                            onChange={(e) => props.onChange(e.target.checked, row.execute.id.value)}
                            name={`${row.name}-execute`}
                            checked={row?.execute?.check}
                            disabled={row.execute === null}
                            key={`${row.name}-execute`}
                        />
                    );
                },
            },
        ];

        return result;
    }, []);

    return (
        <div className="source-permission-table-wrapper">
            <div className="permissions-table-group-actions"></div>
            <UserfulReactTable2
                data={tableData}
                columns={columns}
                id="permissions-table"
                selectBox={false}
                hideSpanPages={true}
                maxPageOptions={3}
            />
        </div>
    );
};

export default SourcePermissionTable;
