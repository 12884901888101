"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestZeroClientUpdateStationID = exports.requestUClientUpdateStationID = exports.requestUClientUpdateDetails = exports.requestUClientUpdateApp = exports.requestUClientShutdown = exports.requestUClientReboot = exports.requestToggleZClientsStationIDs = exports.requestToggleUClientsStationIDs = exports.requestShowZClientStationIDs = exports.requestShowUClientStationIDs = exports.requestHideZClientStationIDs = exports.requestHideUClientStationIDs = void 0;
var _globalStates = require("../../globalstates/globalStates");
var _UClientsMsgDefinations = require("../messageTypeDefinitions/displays/UClientsMsgDefinations");
var _ZeroClientsMsgDefinations = require("../messageTypeDefinitions/displays/ZeroClientsMsgDefinations");
var requestShowZClientStationIDs = exports.requestShowZClientStationIDs = function requestShowZClientStationIDs(data) {
  requestToggleZClientsStationIDs(data.map(function (item) {
    return {
      id: item.seatID,
      stationLabel: item.stationLabel,
      visibility: 'VISIBLE'
    };
  }));
};
var requestHideZClientStationIDs = exports.requestHideZClientStationIDs = function requestHideZClientStationIDs(data) {
  requestToggleZClientsStationIDs(data.map(function (item) {
    return {
      id: item.seatID,
      stationLabel: item.stationLabel,
      visibility: 'INVISIBLE'
    };
  }));
};
var requestToggleZClientsStationIDs = exports.requestToggleZClientsStationIDs = function requestToggleZClientsStationIDs(data) {
  data.forEach(function (item) {
    (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_ZeroClientsMsgDefinations.REQUEST_UPDATE_ZERO_CLIENT_ID_VISIBILITY, item);
  });
};
var requestShowUClientStationIDs = exports.requestShowUClientStationIDs = function requestShowUClientStationIDs(data) {
  requestToggleUClientsStationIDs(data.map(function (item) {
    return {
      id: item.seatID,
      stationLabel: item.stationLabel,
      visibility: 'VISIBLE'
    };
  }));
};
var requestHideUClientStationIDs = exports.requestHideUClientStationIDs = function requestHideUClientStationIDs(data) {
  requestToggleUClientsStationIDs(data.map(function (item) {
    return {
      id: item.seatID,
      stationLabel: item.stationLabel,
      visibility: 'INVISIBLE'
    };
  }));
};
var requestToggleUClientsStationIDs = exports.requestToggleUClientsStationIDs = function requestToggleUClientsStationIDs(data) {
  var sent = {};
  data.forEach(function (item) {
    if (!sent[item.id.value]) {
      // array may contain duplicates
      sent[item.id.value] = true;
      (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_UClientsMsgDefinations.REQUEST_UPDATE_UCLIENT_ID_VISIBILITY, item);
    }
  });
};
var requestUClientUpdateDetails = exports.requestUClientUpdateDetails = function requestUClientUpdateDetails(seatID) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_UClientsMsgDefinations.REQUEST_UPDATE_DETAILS, seatID);
};
var requestUClientReboot = exports.requestUClientReboot = function requestUClientReboot(seatID) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_UClientsMsgDefinations.REQUEST_UCLIENT_REBOOT, seatID);
};
var requestUClientShutdown = exports.requestUClientShutdown = function requestUClientShutdown(seatID) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_UClientsMsgDefinations.REQUEST_UCLIENT_SHUTDOWN, seatID);
};
var requestUClientUpdateApp = exports.requestUClientUpdateApp = function requestUClientUpdateApp(seatID) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_UClientsMsgDefinations.REQUEST_UCLIENT_UPDATE_APP, seatID);
};
var requestUClientUpdateStationID = exports.requestUClientUpdateStationID = function requestUClientUpdateStationID(seatID, stationLabel) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_UClientsMsgDefinations.REQUEST_UPDATE_STATION_ID, {
    id: seatID,
    stationLabel: stationLabel
  });
};
var requestZeroClientUpdateStationID = exports.requestZeroClientUpdateStationID = function requestZeroClientUpdateStationID(seatID, stationLabel) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_ZeroClientsMsgDefinations.REQUEST_UPDATE_STATION_ID_ZERO_CLIENT, {
    id: seatID,
    stationLabel: stationLabel
  });
};