import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IClusterDashboard, IConstellationInfo } from "../../model/CarbonExternalModels";

interface IState {
    constellations: { [id: string]: IConstellationInfo };
    pairingCode: string;
    dashboards: { [id: string]: IClusterDashboard };
}

const initialState: IState = {
    constellations: {},
    pairingCode: "",
    dashboards: {}
};

export const constellationsSlice = createSlice({
    name: "constellationsSlice",
    initialState,
    reducers: {
        // Internal Management
        setConstellations: (state, action: PayloadAction<{ [id: string]: IConstellationInfo }>) => {
            state.constellations = action.payload;
        },
        addConstellation: (state, action: PayloadAction<IConstellationInfo>) => {
            const constellation: IConstellationInfo = action.payload;
            state.constellations[constellation.definition.id.value] = constellation;
        },
        updateConstellationHeader: (state, action: PayloadAction<IConstellationInfo>) => {
            // const constellation: IConstellationInfo = action.payload;
            // const foundConstellation = state.constellations[constellation.definition.id.value];
        },
        removeConstellation: (state, action: PayloadAction<string>) => {
            const id: string = action.payload;

            const foundConstellation = state.constellations[id];

            if (foundConstellation) {
                delete state.constellations[id];
            }
        },
        setPairingCode: (state: IState, action: PayloadAction<string>) => {
            state.pairingCode = action.payload;
        },
        addOrUpdateDashboards: (state, action: PayloadAction<IClusterDashboard[]>) => {
            const dashboards: IClusterDashboard[] = action.payload;

            for (let i = 0; i < dashboards.length; i++) {

                state.dashboards[dashboards[i].id?.value] = dashboards[i];
            }
        },
    },
});

export const ConstellationsActions = constellationsSlice.actions;

export default constellationsSlice.reducer;
