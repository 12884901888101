import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DestinationIDSet } from "userful-chronos-app-common-js/dist/models/cdm/CdmGroup";
import { PositionInfoPct, StringID, STRINGID_NOT_SET, UpdateSecurityData, UserfulSecurityData } from "userful-chronos-app-common-js/dist/models/common";
import {
     createDefaultMapperInsetCreatorConfig,
     createPlaylistMapperInset,
     createSourceMapperInset,
     InsetComp,
     MapperCompCreator,
     MapperCompDestinationsUpdate,
     MapperCompHeaderUpdate,
     MapperCompInsetsUpdate,
     MapperCompInsetsUpdateResponse,
     MapperCompLogicalGridUpdate,
     MapperCompSurface,
     MixerMapperComp,
     MapperCompLatencyUpdate,
     EdgeMapperComp,
     AnyMapper,
     isEdgeMapper,
     EdgeMapperCompCreator,
     isEdgeMapperCreator,
     createEdgeMapperInsetCreator,
     EMPTY_DESTINATION_IDS,
     EdgeInsetCompUpdater,
     MapperSettingsUpdate,
} from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";
import {
     requestCreateMappingGroup,
     requestRemoveMappingGroup,
     requestUpdateMappingGroupDestinations,
     requestUpdateMappingGroupHeader,
     requestUpdateMappingGroupInsetPosition,
     requestUpdateMappingGroupSources,
     requestUpdateMappingGroupLatency,
     requestUpdateEdgeMappingGroupDestinations,
     requestCreateEdgeMappingGroup,
     requestUpdateEdgeMappingGroupHeader,
     requestUpdateEdgeMappingGroupSources,
     requestRemoteEdgeMappingGroupSources,
     requestRemoveEdgeMappingGroup,
     requestUpdateEdgeMappingGroupSecurityData,
     requestUpdateMappingGroupSecurityData,
     requestUpdateMappingGroupSettings
} from "./msgs/MsgSender";
import { createPlaceholderInsets } from "./mappingStoreUtils";
import {
     addDestinationsToMappingGroupAtIndex,
     combineDestinationIDSet,
     combineDestinations,
     deleteDestinations,
     destinationIDSetHasID,
     destinationIDSetToMapperCompSurface,
     EMPTY_DESTINATIONS,
     flattenDestinationIDSet,
     insetCoveredByPosition,
     insetHasPosition,
     NOT_SET_INSET,
     removeDestinationsFromMappingGroupAtIndex,
     splitDestinationIDSet,
     updateMappingGroupAtIndex,
} from "./mappingSliceUtils";
import { TextOverlay } from "userful-chronos-app-common-js/dist/models/mapping/TextOverlay";
import { removeItemFromArray, equalFunctionForStringID } from "userful-chronos-app-common-js/dist/utils";
import { ConfirmationToastContentStore } from '../../Container/ConfirmationToastContentStore';
import { toast } from "react-toastify";


const initialState: {
     mappingGroups: AnyMapper[];
     ready: boolean;
     maxMappingGroups: number;
     maxMappingGroupsError: number;
     pendingAddDestinationRequest: MapperCompDestinationsUpdate;
     pendingMoveDestinationsRequest;
     pendingInsetRequest: {
          inset: StringID;
          mappingGroupID: StringID;
     }[];
     busyMapper: MapperCompDestinationsUpdate[];
     busyMapperUpdates: StringID[];
     busyDestinations: StringID[];
     edgePlayerMode: boolean;
     combinedPlayerMode: boolean;
     expectedConfirmation: string | null;
} = {
     mappingGroups: [],
     ready: false,
     maxMappingGroups: 0,
     maxMappingGroupsError: 0,
     pendingAddDestinationRequest: null,
     pendingMoveDestinationsRequest: {}, // key: destination ID, value: update destination request
     busyMapper: [],
     busyMapperUpdates: [],
     busyDestinations: [],
     edgePlayerMode: false,
     combinedPlayerMode: false,
     pendingInsetRequest: [],
     // this is for showing toast notifications only once
     expectedConfirmation: null

};

let pendingDeleteDestinations = null;
let pendingDeleteDestinationsTimeout = null;

// returns destination IDS that have been removed
const findInProgressPendingMoveRequests = (moveRequests: object, update: MapperCompDestinationsUpdate): string[] => {
     return Object.keys(moveRequests).filter(item => destinationIDSetHasID(update.removeDestinations, item));
}
// returns destination IDS that have been added
const findCompletePendingMoveRequests = (moveRequests: object, update: MapperCompDestinationsUpdate): string[] => {
     return Object.keys(moveRequests).filter(item => destinationIDSetHasID(update.addDestinations, item))
          .filter(item => moveRequests[item].id.value === update.id.value);
}

const isMapperIDEdgeMapper = (state, id: StringID): boolean => {
     const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === id.value);
     if (existingIndex < 0) {
          return false;
     }
     return isEdgeMapper(state.mappingGroups[existingIndex]);
}

export const mappingGroupsSlice = createSlice({
     name: "mappingGroupsSlice",
     initialState,
     reducers: {
          setEdgePlayerMode: (state, action: PayloadAction<boolean>) => {
               state.ready = false;
               state.combinedPlayerMode = false;
               state.edgePlayerMode = action.payload;
          },
          setCombinedPlayerMode: (state, action: PayloadAction<boolean>) => {
               state.ready = false;
               state.combinedPlayerMode = true;
          },
          setMaxMappingGroups: (state, action: PayloadAction<number>) => {
               state.maxMappingGroups = action.payload;
          },
          clearMappingGroups: (state) => {
               state.ready = false;
               state.mappingGroups = [];
               state.pendingAddDestinationRequest = null;
               state.pendingMoveDestinationsRequest = {};
               state.busyMapper = [];
               state.busyMapperUpdates = [];
          },
          setMappingGroups: (state, action: PayloadAction<AnyMapper[]>) => {
               if (action.payload.length > 0 && isEdgeMapper(action.payload[0]) !== state.edgePlayerMode && !state.combinedPlayerMode) {
                    return;
               }
               state.ready = true;
               if (!state.combinedPlayerMode) {
                    state.mappingGroups = action.payload.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
               } else {
                    // can receive both edge and mixer player
                    const additionalData = action.payload.filter(item => state.mappingGroups.findIndex(m => m.id.value === item.id.value) < 0);
                    state.mappingGroups = [...state.mappingGroups, ...additionalData].sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
               }
          },
          addMappingGroup: (state, action: PayloadAction<AnyMapper>) => {
               if (isEdgeMapper(action.payload) !== state.edgePlayerMode && !state.combinedPlayerMode) {
                    return;
               }
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex < 0) {
                    state.mappingGroups = [
                         ...state.mappingGroups,
                         { ...action.payload },
                    ];
               }
          },
          updateMappingGroup: (state, action: PayloadAction<AnyMapper>) => {
               if (isEdgeMapper(action.payload) !== state.edgePlayerMode && !state.combinedPlayerMode) {
                    return;
               }
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const update: AnyMapper = {
                         ...state.mappingGroups[existingIndex],
                         ...action.payload,
                         destinations: state.mappingGroups[existingIndex].destinations,
                    }
                    updateMappingGroupAtIndex(state, update, existingIndex);
                    if (state.expectedConfirmation === action.payload.id.value) {
                         const customDisplay = ConfirmationToastContentStore(action.payload.name, "Edit");
                         toast(customDisplay, { containerId: 'confirmationContainer' });
                         state.expectedConfirmation = null;
                    }
                    if (state.expectedConfirmation === action.payload.id.value) {
                         const customDisplay = ConfirmationToastContentStore(action.payload.name, "Save");
                         toast(customDisplay, { containerId: 'confirmationContainer' });
                         state.expectedConfirmation = null;
                    }
               }
          },
          updateMappingGroupTextOverlay: (
               state,
               action: PayloadAction<{
                    textOverlay: TextOverlay;
                    groupID: StringID;
               }>
          ) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.groupID.value);
               if (existingIndex >= 0) {
                    const update = {
                         ...state.mappingGroups[existingIndex],
                         textOverlay: { ...action.payload.textOverlay },
                    } as MixerMapperComp;
                    updateMappingGroupAtIndex(state, update, existingIndex);
               }
          },
          updateMappingGroupHeader: (state, action: PayloadAction<MapperCompHeaderUpdate>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const update = {
                         ...state.mappingGroups[existingIndex],
                         name: action.payload.name,
                         description: action.payload.description,
                    } as MixerMapperComp;
                    updateMappingGroupAtIndex(state, update, existingIndex);
                    if (state.expectedConfirmation === action.payload.id.value) {
                         const customDisplay = ConfirmationToastContentStore(update.name, "Edit");
                         toast(customDisplay, { containerId: 'confirmationContainer' });
                         state.expectedConfirmation = null;
                    }
               }
          },
          updateMappingGroupSecurityData: (state, action: PayloadAction<UpdateSecurityData>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const update = {
                         ...state.mappingGroups[existingIndex],
                         userfulSecurityData: action.payload.userfulSecurityData,
                    } as MixerMapperComp;
                    updateMappingGroupAtIndex(state, update, existingIndex);
                    if (state.expectedConfirmation === action.payload.id.value) {
                         const customDisplay = ConfirmationToastContentStore(update.name, "Edit");
                         toast(customDisplay, { containerId: 'confirmationContainer' });
                         state.expectedConfirmation = null;
                    }
               }
          },
          updateMappingGroupSettings: (state, action: PayloadAction<MapperSettingsUpdate>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const update = {
                         ...state.mappingGroups[existingIndex],
                         // gpuID: action.payload.gpuID, // don't update GPU from backend message, it's not correct, rely on UPDATE_MAPPER msg instead
                         canvasSize: action.payload.canvasSize,
                         frameRate: action.payload.frameRate,
                         provisioningStrategy: action.payload.behaviourStrategy
                    } as MixerMapperComp;
                    updateMappingGroupAtIndex(state, update, existingIndex);
                    if (state.expectedConfirmation === action.payload.id.value) {
                         const customDisplay = ConfirmationToastContentStore(update.name, "Edit");
                         toast(customDisplay, { containerId: 'confirmationContainer' });
                         state.expectedConfirmation = null;
                    }
               }
          },
          updateMappingGroupDestinations: (state, action: PayloadAction<MapperCompDestinationsUpdate>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const mapper = state.mappingGroups[existingIndex];
                    const deletedSurfaces = destinationIDSetToMapperCompSurface(action.payload.removeDestinations);
                    const addedSurfaces = destinationIDSetToMapperCompSurface(action.payload.addDestinations);
                    const updatedDestinations = combineDestinations(
                         deleteDestinations(mapper.destinations, deletedSurfaces),
                         addedSurfaces
                    );
                    const update = {
                         ...state.mappingGroups[existingIndex],
                         destinations: updatedDestinations,
                    } as MixerMapperComp;
                    updateMappingGroupAtIndex(state, update, existingIndex);
                    findInProgressPendingMoveRequests(state.pendingMoveDestinationsRequest, action.payload).forEach(destID => {
                         // destination removed, can add
                         if (isMapperIDEdgeMapper(state, state.pendingMoveDestinationsRequest[destID].id)) {
                              requestUpdateEdgeMappingGroupDestinations(state.pendingMoveDestinationsRequest[destID]);
                         } else {
                              requestUpdateMappingGroupDestinations(state.pendingMoveDestinationsRequest[destID]);
                         }
                    });
                    findCompletePendingMoveRequests(state.pendingMoveDestinationsRequest, action.payload).forEach(destID => {
                         // destination added, clear state
                         const { [destID]: _, ...rest } = state.pendingMoveDestinationsRequest;
                         state.pendingMoveDestinationsRequest = rest;
                    });
               }
               state.busyMapper = state.busyMapper.filter(item => item.id.value !== action.payload.id.value);
          },
          removeBusyDestinations: (state, action: PayloadAction<MapperCompDestinationsUpdate>) => {
               state.busyDestinations = state.busyDestinations.filter(d => !destinationIDSetHasID(action.payload.addDestinations, d.value)
                    && !destinationIDSetHasID(action.payload.removeDestinations, d.value));
          },
          updateMappingGroupInsetID: (state, action: PayloadAction<EdgeInsetCompUpdater>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               const mapper = state.mappingGroups[existingIndex] as EdgeMapperComp;
               if (!isEdgeMapper(mapper)) {
                    console.info("Found mixer mapper, cannot use updateMappingGroupInsetID method!!!");
                    return;
               }
               state.pendingInsetRequest = state.pendingInsetRequest.filter(({ mappingGroupID }) =>
                    action.payload.id.value !== mappingGroupID.value);
               if (state.expectedConfirmation === action.payload.id.value) {
                    const customDisplay = ConfirmationToastContentStore(mapper.name, "Edit");
                    toast(customDisplay, { containerId: 'confirmationContainer' });
                    state.expectedConfirmation = null;
               }

          },
          updateMappingGroupInsetIDs: (state, action: PayloadAction<MapperCompInsetsUpdateResponse>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               const mapper = state.mappingGroups[existingIndex] as MixerMapperComp;
               if (isEdgeMapper(mapper)) {
                    console.info("Found edge mapper, cannot use updateMappingGroupInsetIDs method!!!");
                    return;
               }
               if (existingIndex >= 0) {
                    const insetIDs: Array<StringID> = [...mapper.insets.map((i) => i.id)];
                    action.payload.removedInsets.forEach((insetID) => removeItemFromArray(insetIDs, insetID, equalFunctionForStringID));
                    insetIDs.push(...action.payload.addedInsets.map((inset) => inset.id));
                    // console.log("updateMappingGroupInsetIDs - insetIDs=", insetIDs);
                    const update = {
                         ...state.mappingGroups[existingIndex],
                    } as MixerMapperComp;
                    updateMappingGroupAtIndex(state, update, existingIndex);
               }

               state.pendingInsetRequest = state.pendingInsetRequest.filter(({ inset, mappingGroupID }) =>
                    action.payload.addedInsets.findIndex(item => item.id.value === inset.value) < 0 ||
                    mappingGroupID.value !== action.payload.id.value);
               if (state.expectedConfirmation === action.payload.id.value) {
                    const customDisplay = ConfirmationToastContentStore(mapper.name, "Edit");
                    toast(customDisplay, { containerId: 'confirmationContainer' });
                    state.expectedConfirmation = null;
               }

          },

          updateMappingGroupLogicalGrid: (state, action: PayloadAction<MapperCompLogicalGridUpdate>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const update = {
                         ...state.mappingGroups[existingIndex],
                         logicalGridData: action.payload.gridData,
                    } as MixerMapperComp;
                    updateMappingGroupAtIndex(state, update, existingIndex);
               }
          },

          removeMappingGroup: (state, action: PayloadAction<StringID>) => {
               state.mappingGroups = state.mappingGroups.filter((channel) => channel.id.value !== action.payload.value);
          },

          resetPendingInset: (state) => {
               for (const pendingInsetRequest of state.pendingInsetRequest) {
                    const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === pendingInsetRequest.mappingGroupID.value);
                    if (existingIndex >= 0) {
                         if (isEdgeMapper(state.mappingGroups[existingIndex])) {
                              const mapper = state.mappingGroups[existingIndex] as EdgeMapperComp;
                              updateMappingGroupAtIndex(
                                   state,
                                   {
                                        ...mapper,
                                        inset: createPlaceholderInsets(STRINGID_NOT_SET, STRINGID_NOT_SET, 'source'),
                                   },
                                   existingIndex
                              );
                         } else {
                              const mapper = state.mappingGroups[existingIndex] as MixerMapperComp;
                              updateMappingGroupAtIndex(
                                   state,
                                   {
                                        ...mapper,
                                        insets: mapper.insets.filter((inset) => inset.id.value !== pendingInsetRequest.inset.value),
                                   },
                                   existingIndex
                              );
                         }
                    }
               }
               state.pendingInsetRequest = [];
          },
          resetPendingDest: (state) => {
               for (const pending of state.busyMapper) {
                    const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === pending.id.value);
                    removeDestinationsFromMappingGroupAtIndex(
                         state,
                         pending.addDestinations,
                         existingIndex,
                    );
               }
               state.pendingMoveDestinationsRequest = {};
               state.busyMapper = [];
               state.busyDestinations = [];
               state.busyMapperUpdates = [];
          },

          createMappingGroupToServer: (state, action: PayloadAction<MapperCompCreator | EdgeMapperCompCreator>) => {
               if (state.maxMappingGroups > 0 && state.mappingGroups.length >= state.maxMappingGroups) {
                    // limit exceeded
                    state.maxMappingGroupsError = state.maxMappingGroups;
                    return;
               }
               if (isEdgeMapperCreator(action.payload)) {
                    requestCreateEdgeMappingGroup(action.payload as EdgeMapperCompCreator);
               } else {
                    requestCreateMappingGroup(action.payload as MapperCompCreator);
               }
               state.expectedConfirmation = action.payload.id.value;
          },

          updateMappingGroupHeaderToServer: (state, action: PayloadAction<MapperCompHeaderUpdate>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const mapper = state.mappingGroups[existingIndex];
                    if (isEdgeMapper(mapper)) {
                         requestUpdateEdgeMappingGroupHeader(action.payload);
                    }
                    else {
                         requestUpdateMappingGroupHeader(action.payload);
                    }
               }
               state.expectedConfirmation = action.payload.id.value;
          },

          updateMappingGroupSecurityDataToServer: (state, action: PayloadAction<UpdateSecurityData>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const mapper = state.mappingGroups[existingIndex];
                    if (isEdgeMapper(mapper)) {
                         requestUpdateEdgeMappingGroupSecurityData(action.payload);
                    }
                    else {
                         requestUpdateMappingGroupSecurityData(action.payload);
                    }
               }
               state.expectedConfirmation = action.payload.id.value;
               mappingGroupsSlice.caseReducers.updateMappingGroupSecurityData(state, action);
          },

          updateMappingGroupSettingsToServer: (state, action: PayloadAction<MapperSettingsUpdate>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const mapper = state.mappingGroups[existingIndex];
                    if (!isEdgeMapper(mapper)) {
                         state.busyMapperUpdates = [...state.busyMapperUpdates, action.payload.id];
                         requestUpdateMappingGroupSettings(action.payload);
                    }

               }
               // state.expectedConfirmation = action.payload.id.value;
               mappingGroupsSlice.caseReducers.updateMappingGroupSettings(state, action);
          },

          updateMappingGroupLatency: (state, action: PayloadAction<MapperCompLatencyUpdate>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const update = {
                         ...state.mappingGroups[existingIndex],
                         uclientLatency: action.payload.latency,
                    } as MixerMapperComp;
                    updateMappingGroupAtIndex(state, update, existingIndex);

                    if (state.expectedConfirmation === action.payload.id.value) {
                         const customDisplay = ConfirmationToastContentStore(update.name, "Edit");
                         toast(customDisplay, { containerId: 'confirmationContainer' });
                         state.expectedConfirmation = null;
                    }
               }

          },

          updateMappingGroupLatencyToServer: (state, action: PayloadAction<MapperCompLatencyUpdate>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    requestUpdateMappingGroupLatency(action.payload);
               }
               state.expectedConfirmation = action.payload.id.value;
          },

          /**
           * set one source (full screen) for the mapping group
           * @param state
           * @param action
           */
          setSourceToServer: (
               state,
               action: PayloadAction<{
                    mappingGroupID: StringID;
                    itemID: StringID;
                    itemName: string;
                    itemDescription: string;
                    type: "playlist" | "source";
                    positionInfo?: PositionInfoPct;
               }>
          ) => {
               const edgeInsetCreator = createEdgeMapperInsetCreator(action.payload.itemID, action.payload.itemName, action.payload.itemDescription)
               const createInsetData = createDefaultMapperInsetCreatorConfig(action.payload.itemName, action.payload.itemDescription, action.payload.positionInfo);
               const addInsets = [];
               if (action.payload.itemID) {
                    addInsets.push(
                         action.payload.type === "playlist"
                              ? createPlaylistMapperInset(action.payload.itemID, createInsetData)
                              : createSourceMapperInset(action.payload.itemID, createInsetData)
                    );
               }
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.mappingGroupID.value);
               if (existingIndex >= 0) {
                    const mapper = state.mappingGroups[existingIndex] as MixerMapperComp;
                    state.pendingInsetRequest = [...state.pendingInsetRequest, {
                         inset: createInsetData.id,
                         mappingGroupID: action.payload.mappingGroupID,
                    }];
                    if (isEdgeMapper(mapper)) {
                         const updatedInset = createPlaceholderInsets(edgeInsetCreator.id, action.payload.itemID, action.payload.type, null, action.payload.itemName, action.payload.itemDescription);

                         updateMappingGroupAtIndex(
                              state,
                              {
                                   ...mapper,
                                   inset: updatedInset,
                              },
                              existingIndex
                         ); // update UI first
                         requestUpdateEdgeMappingGroupSources({
                              id: action.payload.mappingGroupID,
                              inset: edgeInsetCreator,
                         })
                         return;
                    }
                    // remove inset with the exact same position
                    const insetsToRemove =
                         action.payload.positionInfo ? mapper.insets.filter((inset) => insetCoveredByPosition(inset, action.payload.positionInfo)) : [];
                    const updatedInsets = action.payload.positionInfo
                         ? [
                              ...mapper.insets.filter((inset) => insetsToRemove.findIndex(i => i.id.value === inset.id.value) < 0),
                              createPlaceholderInsets(
                                   createInsetData.id,
                                   action.payload.itemID,
                                   action.payload.type,
                                   action.payload.positionInfo,
                                   action.payload.itemName,
                                   action.payload.itemDescription
                              ),
                         ]
                         : [createPlaceholderInsets(createInsetData.id, action.payload.itemID, action.payload.type, null, action.payload.itemName, action.payload.itemDescription)];
                    updateMappingGroupAtIndex(
                         state,
                         {
                              ...mapper,
                              insets: updatedInsets,
                         },
                         existingIndex
                    ); // update UI first

                    const removeInsets = action.payload.positionInfo
                         ? insetsToRemove.map(i => i.id)
                         : mapper.insets.map((inset) => inset.id);
                    requestUpdateMappingGroupSources({
                         id: action.payload.mappingGroupID,
                         addInsets,
                         removeInsets,
                    });
               } else {
                    // cannot find in current
                    if (state.edgePlayerMode) {
                         // slice in edgePlayerMode, so request is for mixer mapper
                         requestUpdateMappingGroupSources({
                              id: action.payload.mappingGroupID,
                              addInsets,
                              removeInsets: [],
                         });
                    } else {
                         // slice in mixer mapper mode, so request is for edge mapper
                         requestUpdateEdgeMappingGroupSources({
                              id: action.payload.mappingGroupID,
                              inset: edgeInsetCreator,
                         })
                    }
               }
          },

          deleteMappingGroupSourceToServer: (
               state,
               action: PayloadAction<{
                    id: StringID;
                    mappingGroupID: StringID;
               }>
          ) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.mappingGroupID.value);
               if (existingIndex >= 0) {
                    const mapper = state.mappingGroups[existingIndex] as MixerMapperComp;
                    if (isEdgeMapper(mapper)) {
                         requestRemoteEdgeMappingGroupSources(mapper.id);
                         return;
                    }
                    updateMappingGroupAtIndex(
                         state,
                         {
                              ...mapper,
                              insets: mapper.insets.filter((inset) => inset.id.value !== action.payload.id.value),
                         },
                         existingIndex
                    ); // update UI first

                    const removeInsets = [action.payload.id];
                    const addInsets = [];
                    requestUpdateMappingGroupSources({
                         id: action.payload.mappingGroupID,
                         addInsets,
                         removeInsets,
                    });
               }
          },

          deleteAllMappingGroupSourcesToServer: (
               state,
               action: PayloadAction<{
                    mappingGroupID: StringID;
               }>
          ) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.mappingGroupID.value);
               if (existingIndex >= 0) {
                    const mapper = state.mappingGroups[existingIndex] as MixerMapperComp;
                    if (isEdgeMapper(mapper)) {
                         updateMappingGroupAtIndex(
                              state,
                              {
                                   ...mapper,
                                   inset: NOT_SET_INSET,
                              },
                              existingIndex
                         ); // update UI first
                         requestRemoteEdgeMappingGroupSources(mapper.id);
                         return;
                    }
                    updateMappingGroupAtIndex(
                         state,
                         {
                              ...mapper,
                              insets: [],
                         },
                         existingIndex
                    ); // update UI first

                    const removeInsets = mapper.insets.map((inset) => inset.id);
                    const addInsets = [];
                    requestUpdateMappingGroupSources({
                         id: action.payload.mappingGroupID,
                         addInsets,
                         removeInsets,
                    });
               }
          },

          updateSourcesToServer: (state, action: PayloadAction<MapperCompInsetsUpdate>) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    requestUpdateMappingGroupSources(action.payload);
               }
               state.expectedConfirmation = action.payload.id.value;
          },

          updateInsetGeometryToServer: (
               state,
               action: PayloadAction<{
                    id: StringID;
                    groupID: StringID;
                    positionInfo: PositionInfoPct;
               }>
          ) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.groupID.value);
               if (existingIndex >= 0) {
                    const mapper = state.mappingGroups[existingIndex] as MixerMapperComp;
                    if (isEdgeMapper(mapper)) {
                         console.info("Found edge mapper, cannot use setSourceToServer method!!!");
                         return;
                    }
                    const insetIndex = mapper.insets.findIndex((item) => item.id.value === action.payload.id.value);
                    if (insetIndex >= 0 && mapper.insets[insetIndex].videoData.length > 0) {
                         const inset = mapper.insets[insetIndex];
                         if (insetHasPosition(inset, action.payload.positionInfo)) {
                              return;
                         }
                         // dragging over an existing inset should replace it
                         const insetsToRemove = mapper.insets.filter(item => item.id.value !== inset.id.value && insetCoveredByPosition(item, action.payload.positionInfo));
                         if (insetsToRemove.length > 0) {
                              requestUpdateMappingGroupSources({
                                   id: action.payload.groupID,
                                   addInsets: [],
                                   removeInsets: insetsToRemove.map(i => i.id),
                              });
                         }

                         const insetsUpdate: InsetComp[] = [
                              ...mapper.insets.slice(0, insetIndex).filter(item => insetsToRemove.findIndex(i => i.id.value === item.id.value) < 0),
                              {
                                   ...inset,
                                   videoData: [
                                        {
                                             ...inset.videoData[0],
                                             position: { ...action.payload.positionInfo },
                                        },
                                   ],
                              },
                              ...mapper.insets.slice(insetIndex + 1),
                         ];
                         updateMappingGroupAtIndex(
                              state,
                              {
                                   ...mapper,
                                   insets: insetsUpdate,
                              },
                              existingIndex
                         ); // update UI first
                         requestUpdateMappingGroupInsetPosition(
                              inset.id,
                              inset.videoData[0].id,
                              mapper.id,
                              action.payload.positionInfo
                         );
                    }
               }
          },

          addOrUpdateDestinationsToServer: (
               state,
               action: PayloadAction<{
                    toMappingGroupID: StringID;
                    fromMappingGroupID?: StringID;
                    destinations: DestinationIDSet;
                    destinationInUse: boolean;
                    removeDestinations?: DestinationIDSet;
               }>
          ) => {
               // update the UI state first
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.toMappingGroupID.value);
               if (existingIndex >= 0) {
                    const toRemove: DestinationIDSet = action.payload.removeDestinations ? { ...action.payload.removeDestinations } : EMPTY_DESTINATIONS;
                    addDestinationsToMappingGroupAtIndex(state, action.payload.destinations, existingIndex);
                    // removeDestinationsFromMappingGroupAtIndex(state, toRemove, existingIndex);
                    const addRequest: MapperCompDestinationsUpdate = {
                         id: action.payload.toMappingGroupID,
                         addDestinations: action.payload.destinations,
                         removeDestinations: toRemove,
                    };
                    if (action.payload.removeDestinations) {
                         state.busyMapper = [...state.busyMapper, addRequest];
                    }
                    if (action.payload.fromMappingGroupID) {
                         const fromIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.fromMappingGroupID.value);
                         if (fromIndex >= 0) {
                              removeDestinationsFromMappingGroupAtIndex(state, action.payload.destinations, fromIndex);
                              // can't remove a destination, then add it immediate to another group, so ...
                              // need to split the update into multiple requests since backend is async
                              const moveRequests = splitDestinationIDSet(addRequest.addDestinations);
                              const updateMoveRequests = {};
                              for (const moveRequest of moveRequests) {
                                   updateMoveRequests[moveRequest.id.value] = {
                                        id: addRequest.id,
                                        addDestinations: moveRequest.destinationIDs,
                                        removeDestinations: EMPTY_DESTINATIONS,
                                   };
                              }
                              state.pendingMoveDestinationsRequest = {
                                   ...state.pendingMoveDestinationsRequest,
                                   ...updateMoveRequests,
                              };
                              if (isEdgeMapper(state.mappingGroups[fromIndex])) {
                                   requestUpdateEdgeMappingGroupDestinations({
                                        id: action.payload.fromMappingGroupID,
                                        addDestinations: EMPTY_DESTINATIONS,
                                        removeDestinations: action.payload.destinations,
                                   });
                              } else {
                                   requestUpdateMappingGroupDestinations({
                                        id: action.payload.fromMappingGroupID,
                                        addDestinations: EMPTY_DESTINATIONS,
                                        removeDestinations: action.payload.destinations,
                                   });
                              }
                              return;
                         }
                    }
                    if (action.payload.destinationInUse) {
                         // for in use destinations, show confirmation
                         state.pendingAddDestinationRequest = addRequest;
                    } else {
                         state.busyDestinations = [...state.busyDestinations, ...flattenDestinationIDSet(addRequest.addDestinations)];
                         if (isMapperIDEdgeMapper(state, addRequest.id)) {
                              requestUpdateEdgeMappingGroupDestinations(addRequest);
                         } else {
                              requestUpdateMappingGroupDestinations(addRequest);
                         }
                    }
               } else {
                    const addRequest = {
                         id: action.payload.toMappingGroupID,
                         addDestinations: action.payload.destinations,
                         removeDestinations: EMPTY_DESTINATION_IDS,
                    };
                    // handle mapper not found
                    if (state.edgePlayerMode) {
                         // request is mixer mapper 
                         requestUpdateMappingGroupDestinations(addRequest);
                    } else {
                         // request is edge mapper
                         requestUpdateEdgeMappingGroupDestinations(addRequest);
                    }
               }
          },
          confirmAddInUseDestination: (state) => {
               if (state.pendingAddDestinationRequest) {
                    state.busyDestinations = [...state.busyDestinations, ...flattenDestinationIDSet(state.pendingAddDestinationRequest.addDestinations)];
                    if (isMapperIDEdgeMapper(state, state.pendingAddDestinationRequest.id)) {
                         requestUpdateEdgeMappingGroupDestinations(state.pendingAddDestinationRequest);
                    } else {
                         requestUpdateMappingGroupDestinations(state.pendingAddDestinationRequest);
                    }
                    state.pendingAddDestinationRequest = null;
               }
          },
          cancelAddInUseDestination: (state) => {
               if (state.pendingAddDestinationRequest) {
                    // revert the UI state
                    const existingIndex = state.mappingGroups.findIndex(
                         (item) => item.id.value === state.pendingAddDestinationRequest.id.value
                    );
                    if (existingIndex >= 0) {
                         removeDestinationsFromMappingGroupAtIndex(
                              state,
                              state.pendingAddDestinationRequest.addDestinations,
                              existingIndex
                         );
                         addDestinationsToMappingGroupAtIndex(state, state.pendingAddDestinationRequest.removeDestinations, existingIndex);
                    }
                    state.busyMapper = state.busyMapper.filter(item => item.id.value !== state.pendingAddDestinationRequest.id.value);
                    state.pendingAddDestinationRequest = null;
               }
          },
          removeDestinationToServer: (
               state,
               action: PayloadAction<{
                    mappingGroupID: StringID;
                    destinations: DestinationIDSet;
               }>
          ) => {
               const existingIndex = state.mappingGroups.findIndex((item) => item.id.value === action.payload.mappingGroupID.value);
               if (existingIndex >= 0) {
                    state.busyDestinations = [...state.busyDestinations, ...flattenDestinationIDSet(action.payload.destinations)];
                    removeDestinationsFromMappingGroupAtIndex(state, action.payload.destinations, existingIndex);
                    if (isEdgeMapper(state.mappingGroups[existingIndex])) {
                         requestUpdateEdgeMappingGroupDestinations({
                              id: action.payload.mappingGroupID,
                              addDestinations: EMPTY_DESTINATIONS,
                              removeDestinations: { ...action.payload.destinations },
                         });
                         return;
                    }
                    if (pendingDeleteDestinationsTimeout) {
                         clearTimeout(pendingDeleteDestinationsTimeout);
                    }
                    pendingDeleteDestinations = combineDestinationIDSet(pendingDeleteDestinations, action.payload.destinations);
                    pendingDeleteDestinationsTimeout = setTimeout(() => {
                         requestUpdateMappingGroupDestinations({
                              id: action.payload.mappingGroupID,
                              addDestinations: EMPTY_DESTINATIONS,
                              removeDestinations: { ...pendingDeleteDestinations },
                         });
                         pendingDeleteDestinationsTimeout = null;
                         pendingDeleteDestinations = null;
                    }, 500);
               }
          },

          deleteMappingGroupToServer: (state, action: PayloadAction<StringID>) => {
               if (isMapperIDEdgeMapper(state, action.payload)) {
                    requestRemoveEdgeMappingGroup(action.payload);
               } else {
                    requestRemoveMappingGroup(action.payload);
               }
          },

          clearMaxMappingGroupsError: (state) => {
               state.maxMappingGroupsError = 0;
          },
     },
});

export const mappingGroupsSliceActions = mappingGroupsSlice.actions;

export default mappingGroupsSlice.reducer;
