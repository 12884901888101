import Form from 'react-bootstrap/Form';
import React from 'react';
import './StaticValueCopyField.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import UserfulTooltip from '../UserfulTooltip';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';


interface IProps {
    value: string;
    hoverOverText: string;
    width?: string;
    handleCopy: Function;
    style?: any;
}
function StaticValueCopyField(props: IProps) {
    const { t } = useTranslation();

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            event.preventDefault();

        }
    }

    return (
        <div className="displayStaticFieldInRow">

            <Form className='staticValueCopyField' onSubmit={e => e.preventDefault()} onKeyDown={handleKeyDown}>

                <Form.Group className='form-group'>
                    <Form.Control as="input"
                        value={props.value}
                        disabled={true}
                        style={{ width: props.width }}
                    />
                </Form.Group>
            </Form>
            <UserfulTooltip title={props.hoverOverText}>
                {/* on copy is called if copy operation is successful */}
                <CopyToClipboard text={props.value}
                    onCopy={() => props.handleCopy()}>
                    <div className="copyDiv" onClick={(e) => { }}>
                        <FontAwesomeIcon icon={faCopy} className="copyIcon" />
                    </div>
                </CopyToClipboard>

            </UserfulTooltip>
        </div>
    );

}

export default StaticValueCopyField;