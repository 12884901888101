
import { Form } from "react-bootstrap";
import React, { useState } from 'react';
import { useSourceAssetSelector } from "userful-chronos-common-store/dist/sourceassets";
import UploadFile from "./upload/UploadFile";
import BackGround from "./BackGroud";
import { useTranslation } from 'react-i18next';
import { APP_IDS } from "userful-chronos-app-common-js/dist/models/common";

interface IProps {
    name: string;
    value: string;
    onChange: (e: any) => void;
}

export default function BackGroundFormGroup(props: IProps) {
    const { t } = useTranslation();

    const sapphireAssets = useSourceAssetSelector((store) => store.assetStore.assets
        .filter(asset => asset.type === "MEDIA_FILE" && asset.orgData.appIDs.appIDs.find(appID => appID.value === APP_IDS.Spaces)));

    const handleBackgroundSelected = (value: string) => {
        props.onChange({ target: { name: props.name, value: value } });
    }

    return (

        <Form.Group className="input-wrapper" controlId="formSourceMessage" id="meeting-room-upload-background-input" style ={{marginTop:"36px"}}>
            {/* <div style={{ marginBottom: '16px' }}>
                <div className="info-heading">{t("SapphireApp.CreateMeetingRoom.BackgroundHeader")}</div>
                <span className="heading2">{t("SapphireApp.CreateMeetingRoom.BackgroundHeaderDescription")}</span>
            </div> */}
            <div className='bgImgCollection'>
                <UploadFile />
                {sapphireAssets.map((asset, index) =>
                    <BackGround key={asset.id.value} asset={asset} selectedValue={props.value} onSelected={handleBackgroundSelected} />
                )}
            </div>
        </Form.Group>
    )
}
