import React, { useEffect, useState } from "react";
import { IUFormField, IUFormFieldCollection, IUFormFieldEventReturn } from "../uFormUtils";
import UDropdown from "./UDropdown";
import UInput from "./UInput";
import UTextarea from "./UTextarea";
import UPasswordWidget from "./UPasswordWidget";
import ToggleButton from "../../Widgets/Buttons/ToggleButton";
import ClickButton from "../../Widgets/Buttons/ClickButton";
import { faCheck, faLinkSimple, faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import UCheckbox from "./UCheckbox";
import URadioButton from "./URadioButton";
import { preserveRatioButton, swapButton } from "../../Widgets/Buttons/Presets/buttonPresets";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import UToggle from "./UToggle";
import UCardSelector from "./UCardSelector";
import UTags from "./UTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UMultiSelect from "./UMultiSelect";
import UFieldsCollectionInserter from "./UFieldsCollectionInserter";

interface IProps {
    fields: IUFormFieldCollection;
}

export default function UFieldsCollection({ fields }: IProps) {
    const { t } = useTranslation();

    const value = useUFormContext().state.fieldState[fields.key];
    const error = useUFormContext().state.fieldErrors[fields.key];
    const { onFieldChange } = useUFormContext();

    const [preserveRatio, setPreserveRatio] = useState<boolean>(false);
    const [independentCropping, setIndependentCropping] = useState<boolean>(true);
    const [items, setItems] = useState<IUFormField[]>(fields.items);
    const [aspectRatio, setAspectRatio] = useState<number>(1);

    useEffect(() => {
        // console.warn(preserveRatio);
        if (preserveRatio) {
            const arr = [];
            for (const property in value) {
                arr.push(value[property]);
            }
            setAspectRatio(arr[0] / arr[1]);
        }
    }, [preserveRatio]);

    const onItemChange = (fieldState: IUFormFieldState, field: IUFormField) => {
        // console.warn(items.length === 2, preserveRatio)
        if (items.length === 2 && preserveRatio) {
            const returnFieldState: IUFormFieldState = { ...value };
            const targetValue = fieldState[field.key];
            let count = 0;

            for (const property in returnFieldState) {
                // console.warn(property === field.key)
                if (property === field.key) {
                    returnFieldState[property] = targetValue;
                } else {
                    // console.warn(count)
                    if (count === 0) {
                        returnFieldState[property] = targetValue * aspectRatio;
                    } else {
                        returnFieldState[property] = targetValue / aspectRatio;
                    }
                }
                count++;
            }
            onFieldChange({ [fields.key]: returnFieldState }, fields);
        } else if (items.length === 4 && !independentCropping) {
            const returnFieldState: IUFormFieldState = { ...value };
            const targetValue = fieldState[field.key];

            for (const property in returnFieldState) {
                returnFieldState[property] = targetValue;
            }

            onFieldChange({ [fields.key]: returnFieldState }, fields);
        } else {
            const returnFieldState: IUFormFieldState = { ...value };

            for (const property in returnFieldState) {
                if (fieldState.hasOwnProperty(property)) {
                    returnFieldState[property] = fieldState[property];
                }
            }

            onFieldChange({ [fields.key]: returnFieldState }, fields);
        }
    };

    const onSwapButtonClick = (e) => {
        const returnFieldState: IUFormFieldState = { ...value };
        const properties: string[] = [];

        for (const property in returnFieldState) {
            properties.push(property);
        }
        swap(returnFieldState, properties[0], returnFieldState, properties[1]);

        // setAspectRatio(+properties[1] / +properties[0]);

        onFieldChange({ [fields.key]: returnFieldState }, fields);
    };

    function swap(obj1, key1, obj2, key2) {
        [obj1[key1], obj2[key2]] = [obj2[key2], obj1[key1]];
    }

    const onPreserveRatioButtonClick = (e) => {

        setPreserveRatio(!preserveRatio);
    };


    if (items.length <= 0) return null;
    else if (items.length === 1) return <UFieldsCollectionInserter field={items[0]} error={error} value={value} onItemChange={onItemChange} />;
    else if (items.length === 2) {
        return (
            <div className="fieldRowWrapper">
                <UFieldsCollectionInserter field={items[0]} error={error} value={value} onItemChange={onItemChange} />
                {fields.numberPreserveRatioButton ? (
                    <div className="elementDown">
                        <ToggleButton button={preserveRatioButton} onClick={(e) => onPreserveRatioButtonClick(e)} />
                    </div>
                ) : null}
                <UFieldsCollectionInserter field={items[1]} error={error} value={value} onItemChange={onItemChange} />
                {fields.numberSwapValuesButton ? (
                    <div className="elementDown">
                        <ClickButton button={swapButton} onClick={onSwapButtonClick} />
                    </div>
                ) : null}
            </div>
        );
    } else if (items.length === 4) {
        return (
            <div className="fieldColumnWrapper longGap">
                <div
                    className="fieldRowWrapper"
                    onClick={(e) => {
                        setIndependentCropping(!independentCropping);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <div className={`checkbox ${independentCropping ? "checked" : null}`}>
                        {independentCropping ? <FontAwesomeIcon icon={faCheck} /> : null}
                    </div>
                    <div className="fieldColumnWrapper shortGap">
                        <div className="mainText">{t("Shared.independentCropping")}</div>
                    </div>
                </div>
                <div className="fieldRowWrapper">
                    <UFieldsCollectionInserter field={items[0]} error={error} value={value} onItemChange={onItemChange} />
                    <UFieldsCollectionInserter field={items[1]} error={error} value={value} onItemChange={onItemChange} />
                </div>
                <div className="fieldRowWrapper">
                    <UFieldsCollectionInserter field={items[2]} error={error} value={value} onItemChange={onItemChange} />
                    <UFieldsCollectionInserter field={items[3]} error={error} value={value} onItemChange={onItemChange} />
                </div>
            </div>
        );
    } else return <div className="fieldRowWrapper">{items.map((field) => <UFieldsCollectionInserter key={field.key} field={field} error={error} value={value} onItemChange={onItemChange} />)}</div>;
}
