import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMount } from 'react-use';
import { registerErrorMsgHandler2 } from 'userful-chronos-app-common-js/dist/message/messageRegistery';
import Button from '../Button/Button';
import "./ErrorOverlay.scss";
import { useTranslation } from 'react-i18next';
import { UIReply } from 'userful-chronos-app-common-js/dist/models/common';
import i18next from 'i18next';
import StandaloneFeatureNotAvailable from '../Widgets/StandaloneFeatureNotAvailable';
import { getGlobalStates, isStandalone } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import SCHAE5 from './specialErrors/SCHAE5';
import UCSTO1 from './specialErrors/UCSTO1';
import UCSTO2 from './specialErrors/UCSTO2';

const HDMI_DEVICE_ERROR = "Decklinkvideosrc failed to acquire input";

interface IProps {
    disabled?: boolean
}

export default function ({ disabled }: IProps) {
    const { t } = useTranslation();

    const [error, setError] = useState<UIReply<any>>(null);

    useMount(() => {
        if (!disabled) {
            registerErrorMsgHandler2((data) => {
                if (!getGlobalStates().eventBus.rebooting) {
                    setError(data);
                }
            });
        }
    })

    const handleClose = () => setError(null);
    if (disabled || !error) {
        return null;
    }

    const getTranslatedErrorMsg = (error: UIReply<any>) => {
        if (!error) {
            return '';
        }
        if (error.msg?.includes(HDMI_DEVICE_ERROR)) {
            return t(`Error.error.HDMI_DEVICE_ERROR`);
        }
        const errorParamsObj = {};
        for (const param of error.params) {
            errorParamsObj[param.name] = param.value;
        }
        if (i18next.exists(`Error.error.${error.moduleID}-${error.errorCode}`)) {
            return t(`Error.error.${error.moduleID}-${error.errorCode}`, errorParamsObj);
        }
        console.debug(`Error msg translation does not exist for ${error.moduleID}-${error.errorCode}`);
        return error.msg;
    }

    if (error && error.moduleID === 'SCHAE' && error.errorCode === 5) {
        return <SCHAE5 error={error} handleClose={handleClose} />
    }

    if (error && error.moduleID === 'UCSTO' && error.errorCode === 1) {
        return <UCSTO1 error={error} handleClose={handleClose} />
    }
    if (error && error.moduleID === 'UCSTO' && error.errorCode === 2) {
        return <UCSTO2 error={error} handleClose={handleClose} />
    }
    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                show={!!error}
            >
                <Modal.Header>
                    <div>
                        {!isStandalone() && <><FontAwesomeIcon icon={faCircleExclamation} style={{ color: ' #D13E2C', marginRight: '8px' }} />{t('Shared.error')}</>}
                    </div>
                    <div className='custom-close-btn' onClick={handleClose}>
                        <FontAwesomeIcon icon={faClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <StandaloneFeatureNotAvailable>
                        {t('Error.errorDescription')}
                        <br />
                        <span>{t('Error.errorCode')}{`${error.moduleID}-${error.errorCode}`}</span>
                        <br />
                        <span>{getTranslatedErrorMsg(error)}</span>
                    </StandaloneFeatureNotAvailable>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('Shared.ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}