"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.path = exports.initClearanceLevel = exports.clearanceLevelKeys = exports.auth_path = exports.POST_UPDATE_CLEARANCE_LEVEL = exports.POST_DELETE_CLEARANCE_LEVEL = exports.POST_ADD_CLEARANCE_LEVEL = exports.GET_CLEARANCE_DATA = exports.GET_CLEARANCE_ACTION = exports.ALLOWED_ACTIONS = void 0;
var clearanceLevelKeys = exports.clearanceLevelKeys = [{
  key: "CreateLevel",
  params: ['name', 'description', 'usergroups']
}, {
  key: "EditLevel",
  params: ['name', 'description', 'usergroups']
}];
var path = exports.path = "/kc";
var auth_path = exports.auth_path = "".concat(path, "/auth/usd");
var GET_CLEARANCE_DATA = exports.GET_CLEARANCE_DATA = "".concat(auth_path, "/clearancedata");
var GET_CLEARANCE_ACTION = exports.GET_CLEARANCE_ACTION = "".concat(auth_path, "/clearanceaction");
var POST_ADD_CLEARANCE_LEVEL = exports.POST_ADD_CLEARANCE_LEVEL = "".concat(auth_path, "/clearancedata/add");
var POST_UPDATE_CLEARANCE_LEVEL = exports.POST_UPDATE_CLEARANCE_LEVEL = "".concat(auth_path, "/clearancedata/update");
var POST_DELETE_CLEARANCE_LEVEL = exports.POST_DELETE_CLEARANCE_LEVEL = "".concat(auth_path, "/clearancedata/delete");
var initClearanceLevel = exports.initClearanceLevel = {
  clearanceLevel: {
    id: {
      value: "NOT_SET"
    },
    name: "",
    description: "",
    actions: [],
    editable: true
  },
  groups: [],
  users: []
};
var ALLOWED_ACTIONS = exports.ALLOWED_ACTIONS = ['execute', 'edit'];