"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LICENSE = void 0;
var _common = require("./common");
var LICENSE = exports.LICENSE = {
  ID: _common.STANDALONE_SYSTEMID,
  _id: "standalone-license",
  displays: 99,
  activationDate: 1663017487510,
  expiryDate: 4290130799999,
  maxDisplaysPerVE: 9999,
  maxTotalDisplays: 99999999,
  licenseTier: {
    tierName: "Demo License",
    allowedApps: [{
      name: "Ruby",
      ID: ["Ruby"],
      properties: {}
    }, {
      name: "Sapphire",
      ID: ["Sapphire"],
      properties: {}
    }, {
      name: "Amethyst",
      ID: ["Amethyst"],
      properties: {}
    }, {
      name: "Emerald",
      ID: ["Emerald"],
      properties: {
        maxStreams: 10
      }
    }, {
      name: "Topaz",
      ID: ["Topaz"],
      properties: {}
    }, {
      name: "Diamond",
      ID: ["ControlRoom", "ControlRoomMgr"],
      properties: {}
    }],
    allowedSource: ["HDMI Capture", "Persistent Session", "Program Runner", "Shelf-Vision", "Metrics TV", "V4L Capture", "Display5 Player", "Picture-In-Picture", "uClient Local Browser", "RTSP Streamer", "RTP Streamer", "Emerald Signage", "VNC Viewer", "Multi-Window", "System Login", "Autologin", "RSS Reader", "Control Room", "LED Controller Layout", "Network Streamer", "PADS4 Viewer", "Signage Player", "Web Browser", "Remote Desktop"],
    allowedGridVideoWallLayouts: [],
    maxGridVideoWallRows: 0,
    maxGridVideoWallColumns: 0,
    maxDisplaysPerVideoWall: 0,
    maxCPUs: 6,
    maxGPUs: 4,
    failover: true,
    maxVideoWalls: 30,
    supportedGPUs: ["RTX A4500", "NVIDIA", "RTX A5000", "RTX A4000", "RTX A6000", "RTX A2000", "GTX 1050", "RTX A5500", "RTX A3000", "Quadro P2000", "Quadro P6000", "Quadro", "RTX 6000", "RTX 3000", "RTX 4000", "RTX"]
  },
  licenseTerm: "SUBSCRIPTION",
  subscriptionLength: 999,
  allowConversion: false,
  buyoutVersion: "",
  issueDate: 1663017462515,
  activationCode: "DEMO1",
  customerID: null,
  maxTotalGPUs: 2,
  maxTotalCPUs: 1,
  failure: "",
  supportTier: "Basic",
  supportLength: 6,
  supportExpiry: 4290130799999,
  gcm: true,
  gcmLength: 6,
  gcmExpiry: 4290130799999
};