import React, { LegacyRef } from "react";
import "./Textarea.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { faCircleQuestion as faRegularCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import UserfulTooltip from "../Widgets/UserfulTooltip";

interface IProps {
    error?: boolean | undefined;
    success?: boolean | undefined;
    filedTitle?: React.ReactNode | undefined;
    help?: React.ReactNode | undefined;
    postChildren?: React.ReactNode | undefined;
    preChildren?: React.ReactNode | undefined;
    maxHeight?: number | undefined;
}

const Textarea = React.forwardRef((props: IProps & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, ref: LegacyRef<HTMLTextAreaElement>) => {

    const insertTitleOrHelp = props.filedTitle || props.help;
    const insertPreChildren = props.preChildren || insertTitleOrHelp;

    return (
        <div className="textarea-field-wrapper">
            {insertPreChildren && <div className={`textarea-field-header ${props.error ? "textarea-field-error" : ""}${props.success ? "textarea-field-success" : ""}`}>
                {insertTitleOrHelp && <div className={`textarea-field-title-container`}>
                    {props.filedTitle && <p>{props.filedTitle}</p>}{props.help && <UserfulTooltip title={props.help}><FontAwesomeIcon icon={faRegularCircleQuestion} /></UserfulTooltip>}</div>}
                {props.preChildren}
            </div>}
            <textarea className="userful-textarea-field" id={`${props.error ? "textarea-field-error" : ""}${props.success ? "textarea-field-success" : ""}`} {...props} ref={ref} style={{ maxHeight: props.maxHeight ? props.maxHeight : "" }} />
            {props.postChildren && <div className={`textarea-field-container ${props.error ? "textarea-field-error" : ""}${props.success ? "textarea-field-success" : ""}`}>
                {props.error && <FontAwesomeIcon icon={faCircleQuestion} />}
                {props.success && <FontAwesomeIcon icon={faCheckCircle} />}
                {props.postChildren}
            </div>}

        </div>
    );
});
export default Textarea;