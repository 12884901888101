"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStationID = exports.updateResolution = exports.updateLockTaskMode = exports.updateLockStatus = exports.updateDetails = exports.getStandaloneZeroClients = exports.getStandaloneUClients = exports.getStandaloneOnboards = exports.findDisplayBySeatID = exports.createUClient = void 0;
var _display = require("../../models/display");
var _displays = require("../data/default/displays");
var _store = require("../data/store");
var _uuid = require("uuid");
var _uclientGiada = require("../../models/uclient/uclientGiada");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var getStandaloneUClients = exports.getStandaloneUClients = function getStandaloneUClients() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().uclients);
};
var getStandaloneZeroClients = exports.getStandaloneZeroClients = function getStandaloneZeroClients() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().zeroClients);
};
var getStandaloneOnboards = exports.getStandaloneOnboards = function getStandaloneOnboards() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().onboards);
};
var findDisplayBySeatID = exports.findDisplayBySeatID = function findDisplayBySeatID(id) {
  return getStandaloneUClients().find(function (d) {
    return d.id.value === id.value;
  }) || getStandaloneZeroClients().find(function (d) {
    return d.id.value === id.value;
  }) || getStandaloneOnboards().find(function (d) {
    return d.id.value === id.value;
  }) || _displays.UNSET_DISPLAY;
};
var pickStationID = function pickStationID(preferedStationID) {
  var existingStationIDs = getStandaloneUClients().map(function (u) {
    return (0, _display.prettyPrintStationLabel)(u.stationLabel);
  });
  var stationID = preferedStationID;
  while (existingStationIDs.includes("".concat(stationID))) {
    stationID = stationID + 1;
  }
  return "".concat(stationID);
};
var createUClient = exports.createUClient = function createUClient(preferedStationID) {
  var systemID = (0, _store.getStandaloneDataStore)().systemID;
  var stationID = pickStationID(preferedStationID);
  var seatID = "seat-userful-uclient-android-".concat((0, _uuid.v4)());
  var uclients = getStandaloneUClients();
  var newClient = {
    id: {
      value: seatID
    },
    giadaGrid: _uclientGiada.GIADA_NOT_SET,
    name: "U-".concat(stationID),
    description: "",
    ownedBy: {
      value: systemID
    },
    activeOn: {
      value: systemID
    },
    ownedBySystem: {
      value: systemID
    },
    activeOnSystem: {
      value: systemID
    },
    stationLabel: {
      value: stationID
    },
    lockedTo: {
      value: systemID
    },
    lockStatus: "LOCKED",
    currentResolution: {
      width: 1920,
      height: 1080
    },
    rotation: {
      radians: 0
    },
    colourCalibrationData: {
      red: 10000,
      green: 10000,
      blue: 10000,
      brightness: 32768,
      contrast: 32768
    },
    physicalData: {
      area: {
        width: {
          lengthMM: 885.5207457185421,
          unit: "IN"
        },
        height: {
          lengthMM: 498.10541946667996,
          unit: "IN"
        }
      }
    },
    stationIDVisibility: "INVISIBLE",
    latency: {
      millis: 250
    },
    lockTaskMode: true,
    managedBy: {
      value: systemID
    },
    audioSettings: {
      audioOffset: {
        offset: 0
      },
      audioVolume: {
        level: 0,
        muted: true
      }
    },
    timeZone: {
      value: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    provisioningType: 'MAPPING',
    transientData: {
      id: {
        value: seatID
      },
      name: "U-".concat(stationID),
      location: "",
      origin: {
        value: systemID
      },
      hardwareData: {
        modelName: "rk3399-all",
        serialNumber: "00E04C023DA8",
        manufacturer: "rockchip",
        firmwareVersion: "WB-BOX-WT399TR110-UF001-K-210708",
        platformName: "Android 7.1.2",
        appVersion: {
          version: "3.1.0.19"
        }
      },
      networkData: {
        addressInfo: {
          dhcpEnabled: true,
          ipAddress: {
            value: "192.168.134.200"
          },
          mask: {
            value: "255.255.255.0"
          }
        },
        gatewayAddress: {
          value: ""
        },
        macAddress: {
          value: "00:e0:4c:02:3d:a8"
        }
      },
      systemInfo: {
        systemID: {
          value: systemID
        },
        previousSystemID: {
          value: "NOT_SET"
        },
        hostName: systemID
      },
      connectionStatus: {
        connected: true,
        wiredConnection: true,
        hasNTP: true,
        connectingToHost: true,
        connectionStatus: "CONNECTED"
      },
      pairingCode: {
        value: ""
      },
      deviceResolution: {
        width: 1920,
        height: 1080
      },
      displayData: {
        id: {
          value: "seat-userful-uclient-android-00E04C023DA8"
        },
        displayID: {
          value: ":2"
        },
        displayResolutionData: {
          effective: {
            width: -1,
            height: -1
          },
          "native": {
            width: 1920,
            height: 1080
          },
          current: {
            width: 1920,
            height: 1080
          },
          availableResolutions: [{
            width: 1920,
            height: 1080
          }, {
            width: 1280,
            height: 720
          }, {
            width: 854,
            height: 480
          }]
        },
        displayColourData: {
          colourDepth: {
            colourDepth: 24
          },
          availableColourDepths: [{
            colourDepth: 24
          }],
          currentColourCalibrationData: {
            red: 10000,
            green: 10000,
            blue: 10000,
            brightness: 32768,
            contrast: 32768
          }
        },
        displayMonitorData: {
          monitorDiagonal: {
            lengthMM: 0,
            unit: "NONE"
          },
          monitorTypeString: " "
        }
      }
    }
  };
  uclients.push(newClient);
  (0, _store.updateStandaloneDataStore)({
    uclients: uclients
  });
  return newClient;
};
var updateLockStatus = exports.updateLockStatus = function updateLockStatus(data) {
  var uclients = getStandaloneUClients();
  var foundIndex = uclients.findIndex(function (item) {
    return item.id.value === data.id.value;
  });
  if (foundIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, uclients[foundIndex]), {}, {
      lockStatus: data.lockStatus,
      transientData: _objectSpread(_objectSpread({}, uclients[foundIndex].transientData), {}, {
        connectionStatus: _objectSpread(_objectSpread({}, uclients[foundIndex].transientData.connectionStatus), {}, {
          connectionStatus: data.lockStatus === 'LOCKED' ? 'CONNECTED' : 'FREE'
        })
      })
    });
    uclients.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      uclients: uclients
    });
    return update;
  }
  return null;
};
var updateStationID = exports.updateStationID = function updateStationID(data) {
  var uclients = getStandaloneUClients();
  var foundIndex = uclients.findIndex(function (item) {
    return item.id.value === data.id.value;
  });
  var foundReplacedIDIndex = uclients.findIndex(function (item) {
    return (0, _display.prettyPrintStationLabel)(item.stationLabel) === (0, _display.prettyPrintStationLabel)(data.stationLabel);
  });
  if (foundIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, uclients[foundIndex]), {}, {
      stationLabel: data.stationLabel
    });
    uclients.splice(foundIndex, 1, update);
    var other = null;
    if (foundReplacedIDIndex >= 0) {
      var stationID = pickStationID(1);
      other = _objectSpread(_objectSpread({}, uclients[foundReplacedIDIndex]), {}, {
        stationLabel: {
          value: stationID
        }
      });
      uclients.splice(foundReplacedIDIndex, 1, other);
    }
    (0, _store.updateStandaloneDataStore)({
      uclients: uclients
    });
    return {
      result: update,
      other: other
    };
  }
  return null;
};
var updateDetails = exports.updateDetails = function updateDetails(data) {
  var uclients = getStandaloneUClients();
  var foundIndex = uclients.findIndex(function (item) {
    return item.id.value === data.seatID.value;
  });
  if (foundIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, uclients[foundIndex]), {}, {
      name: data.settings.nameSetting.name,
      description: data.settings.locationSetting.location,
      transientData: _objectSpread(_objectSpread({}, uclients[foundIndex].transientData), {}, {
        name: data.settings.nameSetting.name,
        location: data.settings.locationSetting.location
      })
    });
    uclients.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      uclients: uclients
    });
    return update;
  }
  return null;
};
var updateResolution = exports.updateResolution = function updateResolution(data) {
  var uclients = getStandaloneUClients();
  var foundIndex = uclients.findIndex(function (item) {
    return item.id.value === data.id.value;
  });
  if (foundIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, uclients[foundIndex]), {}, {
      currentResolution: data.resolution
    });
    uclients.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      uclients: uclients
    });
    return update;
  }
  return null;
};
var updateLockTaskMode = exports.updateLockTaskMode = function updateLockTaskMode(data) {
  var uclients = getStandaloneUClients();
  var foundIndex = uclients.findIndex(function (item) {
    return item.id.value === data.id.value;
  });
  if (foundIndex >= 0) {
    var update = _objectSpread(_objectSpread({}, uclients[foundIndex]), {}, {
      lockTaskMode: data.lockTaskMode
    });
    uclients.splice(foundIndex, 1, update);
    (0, _store.updateStandaloneDataStore)({
      uclients: uclients
    });
    return update;
  }
  return null;
};