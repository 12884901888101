import { faCircleExclamation, faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UIReply } from 'userful-chronos-app-common-js/dist/models/common';
import { isStandalone } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import StandaloneFeatureNotAvailable from '../../Widgets/StandaloneFeatureNotAvailable';
import Button from '../../Button/Button';

interface IProps {
    error: UIReply<any>;
    handleClose: () => void;
}

export default function ({ error, handleClose }: IProps) {
    const { t } = useTranslation();

    const seatID = error?.params?.find(p => p.name === 'seat-id')?.value;
    const name = error?.params?.find(p => p.name === 'name')?.value;

    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                show={!!error}
            >
                <Modal.Header>
                    <div>
                        {!isStandalone() && <>
                            <FontAwesomeIcon icon={faCircleExclamation} style={{ color: 'var(--userful-alert)', marginRight: '8px' }} />
                            {t('Error.uclientStorage.title')}
                        </>}
                    </div>
                    <div className='custom-close-btn' onClick={handleClose}>
                        <FontAwesomeIcon icon={faClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <StandaloneFeatureNotAvailable>
                        <div className='uclientStorageErrorBody'>
                            <div className='errorDesc'>
                                {t('Error.uclientStorage.desc', { name: name || seatID })}
                            </div>
                            <div className='errorStep'>
                                <div>1.</div>
                                {t('Error.uclientStorage.solution1')}
                            </div>
                            <div className='errorStep'>
                                <div>2.</div>
                                {t('Error.uclientStorage.solution2')}
                            </div>
                            <div className='errorDisclaimer'>
                                <FontAwesomeIcon icon={faCircleExclamation} style={{ marginRight: '8px' }} />
                                {t('Error.uclientStorage.solution3')}
                            </div>
                        </div>
                    </StandaloneFeatureNotAvailable>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('Shared.dismiss')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}