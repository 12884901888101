import React from "react";
import { Form } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { HasOrgData, SamOrgData, Tag } from "userful-chronos-app-common-js/dist/models/sam/Common";
import { useTranslation } from "react-i18next";
import { AppsConfig } from "./formUtils";
import "./SamOrgForm.scss";

interface IProps {
     data: HasOrgData;
     onChange: (update: SamOrgData) => void;
     hideApps?: string[];
     tags?: Tag[];
     disabled?: boolean;
}

export default function TagForm(props: IProps) {
     const customStyles = {
          option: (provided, state) => ({
               ...provided,
               color: "#1F2747",
               maxHeight: "176px",
               height: "32px",
               padding: "0px 16px",
               background: state.isSelected ? "#F3F4F5" : state.isFocused ? "#F3F4F5" : undefined,
               display: "flex",
               alignItems: "center",
               "&:active": {
                    background: "#F3F4F5",
               },
          }),
          control: (base, state) => ({
               // none of react-select's styles are passed to <Control />
               ...base,
               width: "100%",
               // height: '36px',
               color: "#1F2747",
               background: " #FFFFFF",
               boxShadow: "inset 0px 2px 4px rgba(31, 39, 71, 0.1)",
               borderRadius: "8px",
               transition: "none",
               display: "flex",
               border: state.menuIsOpen ? "1px solid #6C89E8" : "1px solid #C6C8CE",
               "&:hover": {
                    border: "1px solid #4B5168",
               },
          }),
          valueContainer: (base, state) => ({
               ...base,
               padding: "8px !important",
               gap:'4px !important'
          }),
          multiValue: (base, state) => ({
               ...base,
               display: "inline-flex",
               height: "24px",
               padding: "0px 0px 0px 8px",
               justifyContent: "center",
               alignItems: "center",
               flexShrink: "0",
               borderRadius: "8px",
               backgroundColor: "#EAEBEE",
               fontSize: "12px !important",
               fontWeight: "500",
               color: "#4B5168",
               overflow: "hidden",
               flexDirection: "row",
               gap: "4px",
               margin:"unset !important"
          }),
          multiValueLabel: (base, state) => ({
               ...base,
               padding: "unset",
               paddingLeft: "unset",
               fontSize: "12px !important",
               fontWeight: "500 !important",
               color: "#4B5168 !important",
          }),
          multiValueRemove: (base, state) => ({
               ...base,
               display: "flex",
               width: "24px",
               height: "24px",
               padding: "6px",
               justifyContent: "center",
               alignItems: "center",
               '>svg': {
                    fontSize:'12px'
               }
          })
     };

     const allApps = props.hideApps ? AppsConfig.filter(item => !props.hideApps.includes(item.id)) : [...AppsConfig];

     const handleTagChange = (newValue) => {
          const updatedTags = newValue.map(
               (item) =>
               ({
                    name: item.label,
                    value: item.value,
                    type: "STRING",
               } as Tag)
          );
          props.onChange({
               ...props.data.orgData,
               tagsSet: {
                    valid: true,
                    tags: updatedTags,
               },
          });
     };

     const tagDefaultOptions = props.data.orgData.tagsSet.tags.map((item) => ({
          value: item.value,
          label: item.name,
     }));

     const tagOptions =
          props.tags &&
          props.tags.map((item) => ({
               value: item.value,
               label: item.name,
          }));

     const uniqueTags = [];

     const unique =
          tagOptions &&
          tagOptions.filter((ele) => {
               const isDuplicate = uniqueTags.includes(ele.value);
               if (!isDuplicate) {
                    uniqueTags.push(ele.value);
                    return true;
               } else return false;
          });

     const { t } = useTranslation();

     return (
          <div className="samOrgTagContainer input-wrapper">
               <div className="hero-text-wrapper">
                    <Form.Group className="input-wrapper" controlId="tagSelection">
                         <div>
                              <div className="info-heading">{t("CommonUI.Sources.Tags")}</div>
                              {/* <span className="heading2">{t("CommonUI.Sources.TagsHelp")}</span> */}
                         </div>
                    </Form.Group>
                    <CreatableSelect
                         styles={customStyles}
                         isMulti
                         onChange={handleTagChange}
                         defaultValue={tagDefaultOptions}
                         options={unique}
                         menuPosition="fixed"
                         isDisabled={props.disabled}
                         components={{ IndicatorSeparator: () => null }}
                    />
               </div>
          </div>
     );
}
