import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    UserfulGroup,
    KeycloakRole,
    UserfulUser,
} from "../../model/CarbonExternalModels";
import carbonStore from "../carbonStore";
import { userManagementActions } from "./userManagementSlice";
import { TSliceManagementAction } from "../constellations/constellationsManager";
import { apiDeleteUser, apiDeleteUserGroup } from "../../messages/api/gcmUserManagement";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { AppSliceActions } from "../app/appSlice";

// Roles
export const setRoles = (roles: KeycloakRole[], type: TSliceManagementAction) => {
    const rolesObject: { [id: string]: KeycloakRole } = {};

    for (let i = 0; i < roles.length; i++) {
        const role: KeycloakRole = roles[i];
        rolesObject[role.id.value] = role;
    }

    if (type === "EXTERNAL") carbonStore.dispatch(userManagementActions.setRoles(rolesObject));
};
export const addRole = (role: KeycloakRole, type: TSliceManagementAction) => {
    if (type === "EXTERNAL") carbonStore.dispatch(userManagementActions.addORUpdateRole(role));
};
export const updateRole = (role: KeycloakRole, type: TSliceManagementAction) => {
    if (type === "EXTERNAL") carbonStore.dispatch(userManagementActions.addORUpdateRole(role));
};
export const deleteRole = (roleID: StringID, type: TSliceManagementAction) => {
    if (type === "INTERNAL") {
        carbonStore.dispatch(userManagementActions.removeRole(roleID));
        // apiDeleteUser(getGlobalStates().keycloak.token, roleID);
    }
};
// Users
export const setUsers = (users: UserfulUser[], type: TSliceManagementAction) => {
    const usersObject: { [id: string]: UserfulUser } = {};

    for (let i = 0; i < users.length; i++) {
        const user: UserfulUser = users[i];
        usersObject[user.id.value] = user;
    }

    if (type === "EXTERNAL") carbonStore.dispatch(userManagementActions.setUsers(usersObject));
};
export const addUser = (user: UserfulUser, type: TSliceManagementAction) => {
    if (type === "EXTERNAL") carbonStore.dispatch(userManagementActions.addORUpdateUser(user));
};
export const updateUser = (user: UserfulUser, type: TSliceManagementAction) => {
    if (type === "EXTERNAL") carbonStore.dispatch(userManagementActions.addORUpdateUser(user));
};
export const deleteUser = (userID: StringID, type: TSliceManagementAction) => {
    if (type === "INTERNAL") {
        carbonStore.dispatch(userManagementActions.removeUser(userID));
        apiDeleteUser(getGlobalStates().keycloak.token, userID);
    }
};
// Users Groups
export const setUserGroups = (userGroups: UserfulGroup[], type: TSliceManagementAction) => {
    const userGroupsObject: { [id: string]: UserfulGroup } = {};

    for (let i = 0; i < userGroups.length; i++) {
        const userGroup: UserfulGroup = userGroups[i];
        userGroupsObject[userGroup.id.value] = userGroup;
    }

    if (type === "EXTERNAL") carbonStore.dispatch(userManagementActions.setUserGroups(userGroupsObject));
};
export const addUserGroup = (userGroup: UserfulGroup, type: TSliceManagementAction) => {
    if (type === "EXTERNAL") {
        carbonStore.dispatch(userManagementActions.addORUpdateUserGroup(userGroup));
        carbonStore.dispatch(AppSliceActions.setAppView({ type: "USER_GROUP", id: userGroup.id }));
    }
};
export const updateUserGroup = (userGroup: UserfulGroup, type: TSliceManagementAction) => {
    if (type === "EXTERNAL") carbonStore.dispatch(userManagementActions.addORUpdateUserGroup(userGroup));
};
export const deleteUserGroup = (userGroupID: StringID, type: TSliceManagementAction) => {
    if (type === "INTERNAL") {
        carbonStore.dispatch(AppSliceActions.setAppView({ type: "USER_GROUPS", id: { value: "NOT_SET" } }));
        carbonStore.dispatch(userManagementActions.removeUserGroup(userGroupID));
        
        apiDeleteUserGroup(getGlobalStates().keycloak.token, userGroupID);
    }
};
