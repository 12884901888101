import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

interface IProps {
    globalFilter: any;
    setGlobalFilter: any;
    placeholder?: string;
    className?: string;
}

// Define a default UI for filtering
export default function SearchV2({ globalFilter, setGlobalFilter, placeholder, className }: IProps) {
    const { t } = useTranslation();

    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    const handleClearSearch = () => {
        setGlobalFilter(undefined);
        setValue("");
    };

    return (
        <div className={`table-search app-search ${className || ''}`}>
            <img alt="search icon" src="./assets/search.svg" className="search-icon" />
            <input
                className="search-input"
                type="input"
                placeholder={placeholder || t("CommonUI.SearchPlaceholder")}
                style={{ width: "402px" }}
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
            {value !== undefined && value !== "" && (
                <div className="clearIconWrapper" onClick={handleClearSearch}>
                    <FontAwesomeIcon icon={faX} className="clear" />
                </div>
            )}
        </div>
    );
}
