import React, { ReactNode, useEffect } from "react";
import { Provider } from "react-redux";
import { createSamOrgQueryForApp } from "userful-chronos-app-common-js/dist/models/sam/SAMUtils";
import {
    requestListPlayListDefs,
    requestListSourceDefs,
    requestListSourceSpecs,
    requestQueryPlaylistDefsAssets,
    requestQuerySourceDefs,
} from "../sourceassets/msgs/MsgSender";
import mappingStore, { MappingGroupContext } from "./mappingStore";
import "./msgs/MappingSamMsgReceiver";
import { setAppIDForMappingSamReceiver } from "./msgs/MappingSamMsgReceiver";
import "./msgs/MappingCdmMsgReceiver";
import "./msgs/EdgeMappingMsgReceiver";
import { requestListEdgeMappingGroups, requestListMapperLayouts, requestListMappingGroups } from "./msgs/MsgSender";
import { requestListDestinations } from "../destinations/msgs/MsgSender";
import { setAppIDForMappingReceiver } from "./msgs/EdgeMappingMsgReceiver";
import "./msgs/MapperCollectionReceiver";
import { mappingGroupsSliceActions } from "./mappingGroupsSlice";
import { requestListUC } from "../displays/msgs/UClientsMsgSender";
import { requestListMapperCollection } from "./msgs/MapperCollectionSender";

import {
    requestListMapperLayoutMapping,
    requestListMapperLayoutMappingsCollections,
} from "userful-chronos-app-common-js/dist/models/layouts/messages/MapperLayoutMsgSender";
import "../layouts/msgs/layoutsMsgReceiver";
import "./msgs/DestinationMappingMsgReceiver";
import {
    requestListMapperDestinationMapping,
    requestListMapperDestinationMappingsCollections,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/mapping/DestinationsMappingMsgSender";

interface IProps {
    appID?: string;
    children: ReactNode;
}

// keeps both edge and mixer mapping groups
function CombinedMappingGroupsProvider(props: IProps) {
    useEffect(() => {
        mappingStore.dispatch(mappingGroupsSliceActions.setCombinedPlayerMode(true));
        mappingStore.dispatch(mappingGroupsSliceActions.clearMappingGroups());

        requestListSourceSpecs();
        requestListUC();
        requestListMapperCollection();
        requestListMapperLayouts();
        requestListMapperLayoutMapping();
        requestListMapperLayoutMappingsCollections();
        requestListMapperDestinationMapping();
        requestListMapperDestinationMappingsCollections();

        if (props.appID) {
            setAppIDForMappingSamReceiver(props.appID);
            setAppIDForMappingReceiver(props.appID);
            const samOrgQuery = createSamOrgQueryForApp(props.appID);
            requestQuerySourceDefs(samOrgQuery);
            requestQueryPlaylistDefsAssets(samOrgQuery);
            requestListEdgeMappingGroups();
            requestListMappingGroups(true);
        } else {
            requestListSourceDefs();
            requestListPlayListDefs();
            requestListEdgeMappingGroups();
            requestListMappingGroups(true);
        }
        requestListDestinations();
        requestListSourceSpecs();
    }, []);

    return (
        <Provider store={mappingStore} context={MappingGroupContext}>
            {props.children}
        </Provider>
    );
}

export default CombinedMappingGroupsProvider;
