"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isZeroClientOrphaned = exports.isZeroClientOffline = exports.isZeroClientLockedByOtherHost = exports.isZeroClientLocked = exports.isZeroClientError = exports.canLockZeroClient = void 0;
var isZeroClientOffline = exports.isZeroClientOffline = function isZeroClientOffline(zc) {
  return !zc.transientData || !zc.transientData.usbcData;
};
var canLockZeroClient = exports.canLockZeroClient = function canLockZeroClient(zc) {
  if (isZeroClientOffline(zc)) {
    return false;
  }
  var status = zc.transientData.usbcData.status;
  return status === 'FREE' || status === 'LOCKED_NOT_CONNECTED' && zc.lockStatus === 'UNLOCKED';
};
var isZeroClientOrphaned = exports.isZeroClientOrphaned = function isZeroClientOrphaned(zc) {
  if (isZeroClientOffline(zc)) {
    return false;
  }
  var status = zc.transientData.usbcData.status;
  return status === 'LOCKED_NOT_CONNECTED' && zc.lockStatus === 'UNLOCKED';
};
var isZeroClientLocked = exports.isZeroClientLocked = function isZeroClientLocked(zc) {
  if (isZeroClientOffline(zc)) {
    return false;
  }
  var status = zc.transientData.usbcData.status;
  return status === 'CONNECTED' || (status === 'LOCKED_NOT_CONNECTED' || status === 'UNKNOWN' || status === 'NO_DEVICE') && zc.lockStatus === 'LOCKED';
};
var isZeroClientLockedByOtherHost = exports.isZeroClientLockedByOtherHost = function isZeroClientLockedByOtherHost(zc) {
  if (isZeroClientOffline(zc)) {
    return true;
  }
  var status = zc.transientData.usbcData.status;
  return status === 'OCCUPY' || status === 'PCLINK';
};
var isZeroClientError = exports.isZeroClientError = function isZeroClientError(zc) {
  if (isZeroClientOffline(zc)) {
    return false;
  }
  var status = zc.transientData.usbcData.status;
  return status === 'DIFF_SEG' || status === 'UNSUPPORTED';
};