import { createContext, useContext } from "react";
import { ChronosEventBus2 } from "userful-chronos-app-common-js/dist/eventbus/eventbus2";
import { MessageDefinition } from "userful-chronos-app-common-js/dist/message/messageModel";
import Keycloak from 'keycloak-js';
import { AppUser } from "userful-chronos-app-common-js/dist/models/postLoginData";
import { UserPermission } from 'userful-chronos-app-common-js/dist/models/user-permission';
import { Action, ClearanceData } from "userful-chronos-app-common-js/dist/models/usermgt/clearanceLevel";

export interface AuthenticationState {
    keycloak: Keycloak;
    eventbus: ChronosEventBus2;
    connected: boolean;
    userData: AppUser;
    postLoginData: any;
    canReconnect: boolean;
    userPermission: UserPermission;
    showUnAuthorize: boolean;
    actions: Action[],
    clearanceData: ClearanceData[]
}

export const initAuthenticationState: AuthenticationState = {
    connected: false,
    eventbus: null,
    keycloak: null,
    userData: null,
    postLoginData: null,
    canReconnect: true,
    userPermission: null,
    showUnAuthorize: false,
    actions: [],
    clearanceData: []
};

interface ContextProps {
    setState: (patch: Partial<AuthenticationState> | ((previousState: AuthenticationState) => Partial<AuthenticationState>)) => void;
    state: AuthenticationState;
    login: () => void;
    logout: () => void;
    sendMsg: (msgDef: MessageDefinition, msg?: Object | string | number | boolean) => void;
}


export const AuthenticationContext = createContext<ContextProps>({
    state: initAuthenticationState,
    setState: () => undefined,
    login: () => undefined,
    logout: () => undefined,
    sendMsg: () => undefined,
});
AuthenticationContext.displayName = 'AuthenticationContext';

export function useAuthenticationContext(): ContextProps {
    const context = useContext(AuthenticationContext);

    if (!context) {
        throw new Error('useAuthenticationContext must be used within a AuthenticationProvider');
    }

    return context;
}

