import { Bounce, Id, Slide, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import React from 'react';
import './ConfirmationToast.scss';
import './ConfirmationToastContent.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMultiply } from '@fortawesome/pro-regular-svg-icons';
import {ToastPosition } from 'react-toastify';

interface IProps {
    text?: string;
    name?: string;
    onClick?: Function;
}

export const customClose = ({ closeToast }) => {

    return (
        <div className="wholeDivConfirmation">
            <div className="customCloseButtonConfirmation">
                <div className="iconDiv" onClick={closeToast}>
                    <FontAwesomeIcon icon={faMultiply} className="iconClose" />
                </div>
            </div>
        </div>
    );
}

const ConfirmationToast = (props: IProps) => {

    const { t } = useTranslation();


    return <ToastContainer
        enableMultiContainer
        transition={Slide}
        containerId="confirmationContainer"
        className="confirmationContainer"
        icon={false}
        position=  "bottom-center" 
        autoClose={5000}
        hideProgressBar={true}
        closeButton={customClose}
    />;
};

export default ConfirmationToast;

export const ConfirmToastContainerOptions = {
    enableMultiContainer: true,
    transition: Slide,
    containerId: 'confirmationContainer',
    className: 'confirmationContainer',
    icon: false,
    position: 'bottom-center' as ToastPosition,
    autoClose: 5000,
    hideProgressBar: true,
    closeButton: customClose,
};
