import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

import {
    REQUEST_ADD_CONTROLLER,
    REQUEST_LIST_CONTROLLERS,
    REQUEST_REMOVE_CONTROLLER,
    REQUEST_UPDATE_BRIGHTNESS_ALL,
    REQUEST_UPDATE_COLOR_TEMPERATURE_ALL,
    REQUEST_UPDATE_CONTROLLER,
    REQUEST_UPDATE_DISPLAY_MODE,
    REQUEST_UPDATE_GAMMA_ALL,
    REQUEST_UPDATE_SELECT_INPUT,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/novastarMsgDefinitions";
import {
    NovastarControllerHeader,
    NovastarSelectInputSource,
    NovastarOutputBrightnessAllAdjustment,
    NovastarOutputColorTemperatureAllAjustment,
    NovastarOutputGammaAllAdjustment,
    NovastarDisplaySetMode,
} from "userful-chronos-app-common-js/dist/models/integration/novastar";

export const requestListControllers = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_CONTROLLERS);
};

export const requestAddController = (data: NovastarControllerHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_CONTROLLER, data);
};

export const requestUpdateControllerHeader = (data: NovastarControllerHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_CONTROLLER, data);
};

export const requestRemoveController = (data: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_CONTROLLER, data);
};

export const requestUpdateInput = (data: NovastarSelectInputSource) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_SELECT_INPUT, data);
};

export const requestUpdateBrightness = (data: NovastarOutputBrightnessAllAdjustment) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_BRIGHTNESS_ALL, data);
};

export const requestUpdateColorTemp = (data: NovastarOutputColorTemperatureAllAjustment) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_COLOR_TEMPERATURE_ALL, data);
};

export const requestUpdateGamma = (data: NovastarOutputGammaAllAdjustment) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_GAMMA_ALL, data);
};

export const requestUpdateDisplayMode = (data: NovastarDisplaySetMode) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_DISPLAY_MODE, data);
};
