import React from "react";
import { Spinner } from "react-bootstrap";
import { LoadingProps, useGlobalLoadingContext } from "./GlobalLoading";
import './loader.scss';

export const LoadingWidget = (props: LoadingProps) => {

    const handleModalToggle = () => {
        // hideModal();
    };

    return (
        <div className="loader-wrapper loader">
            <Spinner animation="border" role="status" size={props.sm ? "sm" : undefined} variant={props.variant}>
                <span>{props.text}</span>
            </Spinner>
        </div>
    );
};