"use strict";

var _SAMMsgDefinations = require("../../../message/messageTypeDefinitions/sam/SAMMsgDefinations");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _sam = require("../../functions/sam");
var _sources = require("./sources");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_QUERY_PLAYLIST_DEFS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_QUERY_PLAYLIST_DEFS, (0, _sam.getStandalonePlaylists)().filter(function (data) {
    return (0, _sources.appIDMatches)(payload.appIDsQuery.appIDs, data);
  }));
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_LIST_PLAYLIST_DEFS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_LIST_PLAYLIST_DEFS, (0, _sam.getStandalonePlaylists)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_CREATE_PLAYLIST_DEF, function (payload) {
  var playlist = (0, _sam.addPlaylist)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.NOTIFY_ADD_PLAYLIST_DEF, playlist);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_UPDATE_PLAYLIST_DEF, function (payload) {
  var playlist = (0, _sam.updatePlaylist)(payload);
  var response = {
    itemID: playlist.id.value,
    usageData: []
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_UPDATE_PLAYLIST_DEF, response);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.NOTIFY_UPDATE_PLAYLIST_DEF, playlist);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_FORCE_UPDATE_PLAYLIST_DEF, function (payload) {
  var playlist = (0, _sam.updatePlaylist)(payload);
  var response = {
    itemID: playlist.id.value,
    usageData: []
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_UPDATE_PLAYLIST_DEF, response);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.NOTIFY_UPDATE_PLAYLIST_DEF, playlist);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_REMOVE_PLAYLIST_DEF, function (payload) {
  var playlist = (0, _sam.removePlaylist)(payload);
  var response = {
    itemID: playlist.id.value,
    usageData: []
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_REMOVE_PLAYLIST_DEF, response);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.NOTIFY_REMOVE_PLAYLIST_DEF, playlist);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_SAMMsgDefinations.REQUEST_FORCE_REMOVE_PLAYLIST_DEF, function (payload) {
  var playlist = (0, _sam.removePlaylist)(payload);
  var response = {
    itemID: playlist.id.value,
    usageData: []
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.RESPONSE_REMOVE_PLAYLIST_DEF, response);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_SAMMsgDefinations.NOTIFY_REMOVE_PLAYLIST_DEF, playlist);
});