import { Form } from "react-bootstrap";
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUsermgtSelector } from "userful-chronos-common-store/dist/usermgt";
import { ClearanceData, ClearanceLevel } from "userful-chronos-app-common-js/dist/models/usermgt/clearanceLevel";
import { ClearanceLevelID, StringID, UserfulSecurityData } from "userful-chronos-app-common-js/dist/models/common";
import { SourceClearanceTable } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { getSourceLevelData, getLevelTabledata } from "./source/SourceFormUtil";
import SourcePermissionTable from "./SourcePermissionTable";
interface IProps {
    // list of clearance level permissions that content has
    data: ClearanceLevelID[];
    onChange: Function;
    hideHeader?: boolean;
    noMarginTop?:boolean;
    isSourceOrDestination?:boolean;
}

const SourcePermissionsForm = (props: IProps) => {
    const { t } = useTranslation();
    // this is list of all the clearance levels
    const levelData: ClearanceLevel[] = useUsermgtSelector((state) => props.isSourceOrDestination ? state.levelStore.clearanceLevelsThatUserHasAccess : state.levelStore.filteredClearanceLevels);
    // getLevelTableData reads through the list of all clearance levels and creates a table with clearance level name and then
    // also finds the edit and execute and see if it will be checked orunchecked
    const tableDataRef = useRef(getLevelTabledata(levelData, props.data));
    const [tableData, setTableData] = useState(getLevelTabledata(levelData, props.data));

    useEffect(() => {
        tableDataRef.current = getLevelTabledata(levelData, props.data);
        setTableData(getLevelTabledata(levelData, props.data));
    }, [levelData, props.data]);

    return (
        <>
            {!props.hideHeader && (
                <Form.Group className="input-wrapper" controlId="permissionSelection" style={{ marginTop: `${props.noMarginTop ? '' : '36px'}` }}>
                    <div>
                        <div className="info-heading">{t("Shared.UserPermissions")}</div>
                        <span className="heading2">{t("Shared.UserPermissionsDes")}</span>
                    </div>
                </Form.Group>
            )}
            <SourcePermissionTable
                data={tableData}
                onChange={(checkBool, value) => {
                    props.onChange(checkBool, value);
                }}
                
            />
        </>
    );
};

export default SourcePermissionsForm;
