import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
     MapperCollectionComp,
} from "userful-chronos-app-common-js/dist/models/mapping/MapperCollectionComp";

const initialState: {
     mapperCollections: MapperCollectionComp[];
     ready: boolean;
} = {
     mapperCollections: [],
     ready: false,
};

export const mapperCollectionsSlice = createSlice({
     name: "mapperCollectionsSlice",
     initialState,
     reducers: {
          setMapperCollections: (state, action: PayloadAction<MapperCollectionComp[]>) => {
               state.ready = true;
               state.mapperCollections = [...action.payload];
          },
          addMapperCollection: (state, action: PayloadAction<MapperCollectionComp>) => {
               const existingIndex = state.mapperCollections.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex < 0) {
                    state.mapperCollections = [
                         ...state.mapperCollections,
                         { ...action.payload },
                    ];
               }
          },
          updateMapperCollection: (state, action: PayloadAction<MapperCollectionComp>) => {
               const existingIndex = state.mapperCollections.findIndex((item) => item.id.value === action.payload.id.value);
               if (existingIndex >= 0) {
                    const update: MapperCollectionComp = {
                         ...state.mapperCollections[existingIndex],
                         ...action.payload,
                    }
                    state.mapperCollections = [
                         ...state.mapperCollections.slice(0, existingIndex),
                         update,
                         ...state.mapperCollections.slice(existingIndex + 1)];
               }
          },
          removeMapperCollection: (state, action: PayloadAction<StringID>) => {
               state.mapperCollections = state.mapperCollections.filter(m => m.id.value !== action.payload.value);
          },
     },
});

export const mapperCollectionsActions = mapperCollectionsSlice.actions;

export default mapperCollectionsSlice.reducer;
