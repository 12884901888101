import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import { REQUEST_CANCEL_FILE_CHOPPING_OPERATION, REQUEST_LIST_CHOPPED_ASSETS, REQUEST_LIST_FILE_CHOPPING_OPERATIONS, REQUEST_START_FILE_CHOPPING_OPERATION, RESPONSE_LIST_FILE_CHOPPING_OPERATIONS } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/sam/chop/SAMChopMsgDefinitions";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { SamChoppingRequest } from "userful-chronos-app-common-js/dist/models/sam/chop/SamChop";

export const requestChop = (request: SamChoppingRequest) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_START_FILE_CHOPPING_OPERATION, request);
}

export const cancelChop = (request: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CANCEL_FILE_CHOPPING_OPERATION, request);
}

export const requestListChoppedAssets = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_CHOPPED_ASSETS);
}


export const requestListCurrentChoppingRequests = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_FILE_CHOPPING_OPERATIONS);
}