import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import integrationStore from "../integrationStore";
import { LEDAction } from "userful-chronos-app-common-js/dist/models/integration/action";
import { ledActionActions } from "../ledActionsSlice";
import {
    NOTIFY_LED_ACTION_CREATED, NOTIFY_LED_ACTION_REMOVED,
    NOTIFY_LED_ACTION_UPDATED, RESPONSE_LIST_LED_ACTIONS
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/ledActionMsgDefinitions";

registerMsgHandler(RESPONSE_LIST_LED_ACTIONS, (payload) => {
    console.debug('Receive RESPONSE_LIST_LED_ACTIONS');
    const data = JSON.parse(payload) as Array<LEDAction>;
    integrationStore.dispatch(ledActionActions.setActions(data));
})

registerMsgHandler(NOTIFY_LED_ACTION_CREATED, (payload) => {
    const data = JSON.parse(payload) as LEDAction;
    console.debug("Receive NOTIFY_LED_ACTION_CREATED");
    integrationStore.dispatch(ledActionActions.addOrUpdateActions(data));
});

registerMsgHandler(NOTIFY_LED_ACTION_UPDATED, (payload) => {
    const data = JSON.parse(payload) as LEDAction;
    console.debug("Receive NOTIFY_LED_ACTION_UPDATED");
    integrationStore.dispatch(ledActionActions.addOrUpdateActions(data));
});

registerMsgHandler(NOTIFY_LED_ACTION_REMOVED, (payload) => {
    const data = JSON.parse(payload) as StringID;
    console.debug("Receive NOTIFY_LED_ACTION_REMOVED");
    integrationStore.dispatch(ledActionActions.deleteAction(data));
});

