import React from 'react';
import './TextWidget.scss';
import Form from 'react-bootstrap/Form';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';


interface IProps {
    title: string;
    placeholder: string;
    keys: string;
    formValue: string | number;
    invalidState: boolean;
    invalidText: string;
    width: number;
    showHelpInfo?: boolean;
    helpText?: string;
    textField?: any;
    displayLength?: boolean;
    maxCharAllowed?: number;
    onChange: (e: any) => void;
    disabled?: boolean;
    onBlur: (e: any) => void
    id?: string;
    text?: string;

}

function TextWidget(props: IProps) {

    const renderTooltipQuestionTwo = (props) => (
        <Tooltip id="question-tooltip" {...props} style={{ position: "absolute", left: "-50%" }}>
            {props.text}
        </Tooltip>
    );

    const onSubmitForm = (event: any) => {
        event.preventDefault();
    }

    return (

        <div className='userManagementTextWidget' style={{ width: props.width }}>
            <div className='userManagementTextWidgetHeader'>
                <div className='userManagementTextWidgetHeaderOne'>
                    <div className='problemCanvasTitleOne'>
                        {props.title}
                    </div>
                    {props.showHelpInfo && <OverlayTrigger placement='top' overlay={renderTooltipQuestionTwo({ text: props.helpText })} >
                        <div>
                            <FontAwesomeIcon icon={faCircleQuestion} className='problemCanvasQuestionIconOne' />
                        </div>
                    </OverlayTrigger>}
                </div>

                {props.displayLength && <div>
                    {props.formValue.toString().length}/{props.maxCharAllowed}
                </div>}
            </div>


            <Form className='formcontrolTextWidget' onSubmit={onSubmitForm}>
                <Form.Group className='form-group'>
                    <Form.Control
                        maxLength={props.maxCharAllowed}
                        as={props.textField ? props.textField : "input"}
                        placeholder={props.placeholder}
                        value={props.formValue}
                        onChange={(e) => props.onChange(e)}
                        name={props.keys}
                        isInvalid={props.invalidState}
                        width={props.width}
                        disabled={props.disabled}
                        id={props.id || ''}
                        onBlur={(e) => props.onBlur(e)}
                        className={props.textField === "textarea" ? "custom-textarea-height" : "custom-control-height"} />
                    <Form.Control.Feedback type='invalid' className='invalidInputOne'>
                        {props.invalidText}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>

        </div >
    );
}

export default TextWidget;
