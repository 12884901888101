import store from '../store/carbonStore';
import { BackUpKey } from "../model/backupRestore";
import { carbonBackupActions } from '../store/carbon-backup-slice';

import { AxiosResponse } from 'axios';
import { apiPost } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';

export const currentURL = process.env.REACT_APP_URL || window.location.origin;

const defaultPath = "/api/secure/constellation";
const backupPath = defaultPath + "/cluster/backup";

export const getError = (response: any) => {
    return ({
        success: false,
        arg: response.data.arg ? response.data.arg : "",
        errorCode: response.data.errorCode ? response.data.errorCode : "",
        moduleID: response.data.moduleID ? response.data.moduleID : "",
        severity: response.data.severity ? response.data.severity : '',
        msg: response.data.unlocalizedMsg ? response.data.unlocalizedMsg : ""
    });
}

export const checkReponseIsValid = (name: string, response: AxiosResponse): boolean => {

    if (response.status >= 200 && response.status < 300) {
        if (response.data.success)
            return true;
    }
    return false;
}

export const checkAuthFail = (response: AxiosResponse) => {
    if (response?.status === 403)
        return false;
    return true;
}

export const requestBackupDownload = (data: BackUpKey, token: string) => {
    try {
        const download = currentURL + backupPath + "/download";
        const downloadURL = `${download}/${data.value}`;

        const fileName = `${data.value.replaceAll("/", "_")}.zip`;
        console.log("downloading: " + fileName);

        const link = document.createElement('a');
        link.href = downloadURL;
        link.addEventListener('click', (event) => {
            event.preventDefault();

            const request = new XMLHttpRequest();
            request.open('GET', link.href);
            request.setRequestHeader('Authorization', `Bearer ${token}`);
            request.responseType = 'blob';

            request.timeout = 3600000; // TODO: Set a 1h timeout - get the value from the backend

            request.ontimeout = () => {
                console.error('Download Request timed out after 1h');
            };

            request.onloadend = () => {
                console.info('Download Request completed');
                store.dispatch(carbonBackupActions.removeDownloadInProgresID(data));
            };
        
            request.onload = () => {
                document.body.removeChild(link);
                if (request.status === 200) {
                    const url = window.URL.createObjectURL(request.response);
                    const tempLink = document.createElement('a');
                    tempLink.href = url;
                    tempLink.download = fileName;
                    tempLink.click();
                    window.URL.revokeObjectURL(url);
                } else {
                    console.error('Download failed:', request.statusText);
                }
            };

            store.dispatch(carbonBackupActions.addDownloadInProgresID(data));
            request.send();
        });

        document.body.appendChild(link);
        link.click();

    } catch(err) {
        console.error("Download Request failed", err); 

        store.dispatch(carbonBackupActions.removeDownloadInProgresID(data));
    }
}


// download the cluster backup
export const requestClusterBackupDownload = (data: BackUpKey, token: string) => {

    const t1 = currentURL + backupPath + "/download/url";

    store.dispatch(carbonBackupActions.addDownloadInProgresID(data));
    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkAuthFail(response)) {
            store.dispatch(carbonBackupActions.removeDownloadInProgresID(data));
            return;
        }
        if (response.status < 300) {
            const downloadURL = `${response.data.arg.preSignedUrl}`;
            console.log("downloading from " + downloadURL);
            const link = document.createElement('a');
            document.body.appendChild(link);
            link.href = downloadURL;
            link.click();
            document.body.removeChild(link);
            store.dispatch(carbonBackupActions.removeDownloadInProgresID(data));
        } else {
            console.error('Invalid response data');
            store.dispatch(carbonBackupActions.removeDownloadInProgresID(data));
        }
    }).catch((err) => {
        console.warn("downloading failed");
        store.dispatch(carbonBackupActions.removeDownloadInProgresID(data));
        if (!checkAuthFail(err.response)) {
            return;
        }
    });
}