import { ServicenowColumns, ServicenowColumnsRequest, ServicenowPassport } from "userful-chronos-app-common-js/dist/models/integration/servicenow";

export const GetListPassportForDropdown = (passports: ServicenowPassport[]): Array<{ value: string, label: string, id: string }> => {

    const list = passports.map((passport: ServicenowPassport) => {
        return {
            value: passport.name,
            label: passport.name,
            id: passport.id.value
        }

    })

    return list;

}


export const GetSelectedPassport = (passports: ServicenowPassport[], id: string): { value: string, label: string, id: string } => {
    const selected = passports.filter((passport) => passport.id.value === id);
    if (selected.length === 0) {
        return null
    }

    return {
        value: selected[0].name,
        label: selected[0].name,
        id: selected[0].id.value
    }
}

export const GetColumnRequestData    = (value: string) : ServicenowColumnsRequest => {
    return ({
        id: {value: value},
        tableName: 'incident'
    })
}

export const GetListColumnsForDropdown = (columns: ServicenowColumns[]): Array<{ value: string, label: string, id: string }> => {

    const list = columns.map((column: ServicenowColumns) => {
        return {
            value: column.name,
            label: column.name,
            id: column.name
        }

    })

    return list;

}

export const GetCodedListColumns = (): Array<{ value: string, label: string, id: string }> => {

    return [{
        value:"state",
        label:"State",
        id:"state"
    },
    {
        value:"severity",
        label:"Severity",
        id:"severity"
    },
    {
        value:"category",
        label:"Category",
        id:"category"
    }
];

}

export const GetSelectedColumn = (id: string): { value: string, label: string, id: string } => {

    if(id == null)  
        return null;

    return GetCodedListColumns().find((item) => item.id === id);
}

export const GetStartAndEndDate = () => {
    const today = new Date(); // Today's date (start date)
    const startDate = today.getTime(); // Get the timestamp for today's date
  
    const oneMonthBefore = new Date(); // Create a new Date object for the end date
    oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1); // Subtract one month
    const endDate = oneMonthBefore.getTime(); // Get the timestamp for one month before
  
    return {
      startDate: new Date(startDate), // Return the start date as a Date object
      endDate: new Date(endDate), // Return the end date as a Date object
    };
  }

export const getDate = (params): [Date, Date] => {
    const defaultValue = params.filter((para) => para.name === "Servicenow date")[0].value;

    // Parse the default value to set the initial dateRange
    if (defaultValue) {
        const [startDateStr, endDateStr] = defaultValue.split('*');
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        return [startDate, endDate];
    }

    return [new Date(), new Date()];
}