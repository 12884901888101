// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{

    --userful-short-small-size: 2px;
    --userful-short-mid-size: 8px;
    --userful-short-large-size: 12px;

    --userful-standard-size: 16px;
    --userful-standard-font-size: 14px;
    --userful-standard-icon-size: var(--userful-standard-size);
    --userful-standard-padding-size: var(--userful-standard-size);
    --userful-standard-border-radius-size: var(--userful-short-mid-size);
    --userful-standard-gap-size: var(--userful-short-mid-size);

    --userful-button-size: 36px;
    --userful-input-size: 36px;

    --userful-light-font-weight: 400;
    --userful-normal-font-weight: 600;
    
}`, "",{"version":3,"sources":["webpack://./src/Container/UserfulSizes.css"],"names":[],"mappings":"AAAA;;IAEI,+BAA+B;IAC/B,6BAA6B;IAC7B,gCAAgC;;IAEhC,6BAA6B;IAC7B,kCAAkC;IAClC,0DAA0D;IAC1D,6DAA6D;IAC7D,oEAAoE;IACpE,0DAA0D;;IAE1D,2BAA2B;IAC3B,0BAA0B;;IAE1B,gCAAgC;IAChC,iCAAiC;;AAErC","sourcesContent":[":root{\n\n    --userful-short-small-size: 2px;\n    --userful-short-mid-size: 8px;\n    --userful-short-large-size: 12px;\n\n    --userful-standard-size: 16px;\n    --userful-standard-font-size: 14px;\n    --userful-standard-icon-size: var(--userful-standard-size);\n    --userful-standard-padding-size: var(--userful-standard-size);\n    --userful-standard-border-radius-size: var(--userful-short-mid-size);\n    --userful-standard-gap-size: var(--userful-short-mid-size);\n\n    --userful-button-size: 36px;\n    --userful-input-size: 36px;\n\n    --userful-light-font-weight: 400;\n    --userful-normal-font-weight: 600;\n    \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
