import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Button.scss";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IUButton } from "./buttonsUtils";
import { useTranslation } from "react-i18next";
import UserfulTooltip from "../UserfulTooltip";

interface IProps {
    button: IUButton;
    onClick?(e): void;
    disabled?: boolean;
}

export default function ClickButton({ button, onClick, disabled }: IProps) {
    const { t } = useTranslation();

     if (disabled) {
          return (
               <div className={`BUTTON ${button.variant ? button.variant : "SECONDARY"} DISABLED ${button.className || ''}`} style={button.fullWidth && { width: "100%" }}>
                    {button.icon ? <FontAwesomeIcon icon={button.icon} /> : null}
                    {!button.hideTitle && <div className="TEXT">{t(button.title)}</div>}
               </div>);
     }

    if (button.static)
        return (
            <div className={`STATIC ${button.className || ''}`} style={button.fullWidth && { width: "100%" }}>
                <FontAwesomeIcon className={`STATIC-ICON ${button.variant ? button.variant : "SECONDARY"}`} icon={button.icon} />
            </div>
        );

    if (button.variant === "ONCARD") {
        return (
            <UserfulTooltip placement="top" title={t(button.title)}>
                <div
                    className={`cardButton ${button.className || ''}`}
                    onClick={(e) => {
                        if (onClick) onClick(e);
                        if (button.onClick) button.onClick(e);
                    }}
                >
                    {button.icon ? <FontAwesomeIcon icon={button.icon} /> : null}
                </div>
            </UserfulTooltip>
        );
    }

    if (button.variant === "NEW-ICON") {
        return (
            <UserfulTooltip placement="top" title={t(button.title)}>
                <div
                    className={`activeDestinationButton ${button.className || ''}`}
                    onClick={(e) => {
                        if (onClick) onClick(e);
                        if (button.onClick) button.onClick(e);
                    }}
                >
                    {button.icon ? <FontAwesomeIcon icon={button.icon} /> : null}
                </div>
            </UserfulTooltip>
        );
    }

    return button.hideOverlay ? (
        <div
            style={button.fullWidth && { width: "100%" }}
            className={`BUTTON ${button.variant ? button.variant : "SECONDARY"} ${button.className || ''}`}
            onClick={(e) => {
                if (onClick) onClick(e);
                if (button.onClick) button.onClick(e);
            }}
        >
            {button.icon ? <FontAwesomeIcon icon={button.icon} /> : null}
            {!button.hideTitle && <div className="TEXT">{t(button.title)}</div>}
        </div>
    ) : (
        <OverlayTrigger key={"toggleButton"} placement="top" overlay={<Tooltip>{t(button.title)}</Tooltip>}>
            <div
                style={button.fullWidth && { width: "100%" }}
                className={`BUTTON ${button.variant ? button.variant : "SECONDARY"} ${button.className || ''}`}
                onClick={(e) => {
                    if (onClick) onClick(e);
                    if (button.onClick) button.onClick(e);
                }}
            >
                {button.icon ? <FontAwesomeIcon icon={button.icon} /> : null}
                {!button.hideTitle && <div className="TEXT">{t(button.title)}</div>}
            </div>
        </OverlayTrigger>
    );
}
