import { faUpload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import './HttpsForm.scss';
import Switch from 'react-switch';
import { httpsActions, useConfigDispatch, useConfigSelector } from 'userful-chronos-common-store/dist/config';

interface IProps {
    onSubmit?: (e: FormEvent) => void;
}

export default function HttpsForm(props: IProps) {

    const { t } = useTranslation();

    const dispatch = useConfigDispatch();
    const settings = useConfigSelector(state => state.httpsStore.settings);
    const errors = useConfigSelector(state => state.httpsStore.errors);

    const [keyfocus, setKeyFocus] = useState(false);
    const [certFocus, setCertFocus] = useState(false);

    const keyRef = useRef<HTMLInputElement>(null);
    const certRef = useRef<HTMLInputElement>(null);

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.name === 'key' && keyRef.current) {
            setKeyFocus(true);
            setTimeout(() => keyRef.current.select(), 100);
        } else if (certRef.current) {
            setCertFocus(true)
            setTimeout(() => certRef.current.select(), 100);
        }
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.name === 'key') {
            setKeyFocus(false);
        } else {
            setCertFocus(false)
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(httpsActions.updateSettings({
            [e.target.name]: e.target.value,
        }))
    }

    const onToggleEnableSSL = () => {
        dispatch(httpsActions.updateSettings({
            enableSSL: !settings.enableSSL,
        }))
        dispatch(httpsActions.sanitizeSettings());
    }

    const onToggleAutoGenerateCert = () => {
        dispatch(httpsActions.toggleAutoGenerateCert());
        dispatch(httpsActions.sanitizeSettings());
    }

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (fileEvent) => {
                dispatch(httpsActions.updateSettings({
                    [e.target.name]: fileEvent.target.result,
                }));
                dispatch(httpsActions.sanitizeSettings());
                if (e.target.name === 'key' && keyRef.current) {
                    keyRef.current.focus();
                    setTimeout(() => keyRef.current.select(), 100);
                } else if (certRef.current) {
                    certRef.current.focus();
                    setTimeout(() => certRef.current.select(), 100);
                }
            }
            reader.onerror = (error) => console.log('Upload file failed ', error);
            reader.readAsText(file, "UTF-8");
        }
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (props.onSubmit) {
            props.onSubmit(e);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    }

    const getShowValue = (value: string, focused: boolean): string => focused || !value ? value : "******";


    return (
        <>
            <Form className="httpsContainer" onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
                <div>
                    {t('InitialSetup.frontendURLDescription')}
                </div>

                <Form.Group className="keyForm" controlId="url">
                    <Form.Label className="publicKeyLabel">{t('CommonUI.Config.Https.url')}</Form.Label>
                    <Form.Control
                        type="input"
                        placeholder={t('CommonUI.Config.Https.urlPlaceholder')}
                        value={settings.frontEndUrl}
                        isInvalid={!!errors.frontEndUrl}
                        name="frontEndUrl"
                        onChange={onChange}
                        autoFocus
                    />
                    <Form.Control.Feedback type="invalid">{t(errors.frontEndUrl)}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="keyForm" controlId="organization">
                    <Form.Label className="publicKeyLabel">{t('CommonUI.Config.organization')}</Form.Label>
                    <Form.Control
                        type="input"
                        value={settings.organization}
                        name="organization"
                        onChange={onChange}
                        autoFocus
                    />
                </Form.Group>

                <div className="overallHTTPContainer">
                    <div className={`httpContainerHeaderDiv ${settings.enableSSL && 'conditionalDisplayOverallNoBorder'}`} onClick={onToggleEnableSSL}>
                        <div className="httpContainerHead">
                            <div className="httpContainerHeadText">
                                {t('InitialSetup.httpHead')}
                            </div>
                            <div className='httpContainerDesText'>
                                {t('InitialSetup.httpDes')}
                            </div>
                        </div>
                        <div className="custom-toggle-switch-wrapper">
                            <Switch
                                onChange={onToggleEnableSSL}
                                checked={settings.enableSSL}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                height={16}
                                width={32}
                                onHandleColor='#FFFFFF'
                                onColor='#23A866'
                                offColor='#C6C8CE'
                                offHandleColor='#FFFFFF'
                                className="toggle-switch"
                            />
                        </div>
                    </div>

                    {settings.enableSSL && <div className="overallHTTPCertDiv">

                        <div className="selfSignedCertDiv">
                            <Form.Check
                                aria-label="selfSigned"
                                id="selfSigned"
                                onChange={onToggleAutoGenerateCert}
                                name="selfSigned"
                                checked={settings.selfSigned}
                                onClick={(e) => e.stopPropagation()}
                            />
                            <div className="selfSignedCertTexDiv">
                                <div className="selfSignedCertHead">
                                    {t('InitialSetup.certHead')}
                                </div>
                                <div className="selfSignedCertDes">
                                    {t('InitialSetup.certDes')}
                                </div>
                            </div>

                        </div>


                        {!settings.selfSigned && <>
                            <div className="publicKeyDiv">
                                <Form.Group className="keyForm" controlId="cert">
                                    <Form.Label className="publicKeyLabel">{t('CommonUI.Config.Https.cert')}</Form.Label>
                                    <div className="inputUpload">
                                        <Form.Control
                                            as="input"
                                            name="cert"
                                            value={getShowValue(settings.cert, certFocus)}
                                            isInvalid={!!errors.cert}
                                            onChange={onChange}
                                            ref={certRef}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            disabled={!settings.enableSSL || settings.selfSigned}
                                        />
                                        <label className='httpsFileUpload'>
                                            <input
                                                type="file"
                                                name="cert"
                                                onChange={handleFileUpload}
                                                disabled={!settings.enableSSL || settings.selfSigned}
                                            />
                                            <FontAwesomeIcon icon={faUpload} />
                                        </label>
                                    </div>
                                    <Form.Control.Feedback type="invalid">{t(errors.cert)}</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="publicKeyDiv">
                                <Form.Group className="keyForm" controlId="key">
                                    <Form.Label className="publicKeyLabel">{t('CommonUI.Config.Https.key')}</Form.Label>
                                    <div className="inputUpload">
                                        <Form.Control
                                            as="input"
                                            name="key"
                                            value={getShowValue(settings.key, keyfocus)}
                                            isInvalid={!!errors.key}
                                            onChange={onChange}
                                            ref={keyRef}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            disabled={!settings.enableSSL || settings.selfSigned}
                                        />
                                        <label className='httpsFileUpload'>
                                            <input
                                                type="file"
                                                name="key"
                                                onChange={handleFileUpload}
                                                disabled={!settings.enableSSL || settings.selfSigned}
                                            />
                                            <FontAwesomeIcon icon={faUpload} />
                                        </label>
                                    </div>
                                    <Form.Control.Feedback type="invalid">{t(errors.key)}</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </>}

                    </div>}
                </div >
            </Form >
        </>
    );
}