import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { LoadingProps, useGlobalLoadingContext } from "./GlobalLoading";
import './loader.scss';

export const FullSreenLoadingWidget = (props: LoadingProps) => {
    return (
        <div className="fullscreen-loader-wrapper loader">
            <div className="loader">
                <Spinner animation="border" role="status" size={props.sm ? "sm" : undefined} variant={props.variant}/>
                <span className="text">{props.text}</span>
            </div>
        </div>
    );
};