import { createContext, useContext } from "react";

import { MessageDefinition } from "userful-chronos-app-common-js/dist/message/messageModel";
import Keycloak from "keycloak-js";
import { AppUser } from "userful-chronos-app-common-js/dist/models/postLoginData";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import { Action, ClearanceData } from "userful-chronos-app-common-js/dist/models/usermgt/clearanceLevel";
//import { ChronosEventBus2 } from "../messages/webSocket/eventbus";
import { ChronosEventBus2 } from "userful-chronos-app-common-js/dist/eventbus/eventbus2";

export interface AuthenticationState {
    keycloak: Keycloak;
    eventbus: ChronosEventBus2;
    connected: boolean;
    userData: AppUser;
    postLoginData: any;
    canReconnect: boolean;
    gcmUserPermission: GcmUserPermission;
    showUnAuthorize: boolean;
    actions: Action[];
    clearanceData: ClearanceData[];
}

export const initAuthenticationState: AuthenticationState = {
    connected: false,
    eventbus: null,
    keycloak: null,
    userData: null,
    postLoginData: null,
    canReconnect: true,
    gcmUserPermission: null,
    showUnAuthorize: false,
    actions: [],
    clearanceData: [],
};

interface ContextProps {
    setState: (
        patch: Partial<AuthenticationState> | ((previousState: AuthenticationState) => Partial<AuthenticationState>)
    ) => void;
    state: AuthenticationState;
    login: () => void;
    logout: () => void;
    sendMsg: (msgDef: MessageDefinition, msg?: Object | string | number | boolean) => void;
}

export const AuthenticationContext = createContext<ContextProps>({
    state: initAuthenticationState,
    setState: () => undefined,
    login: () => undefined,
    logout: () => undefined,
    sendMsg: () => undefined,
});
AuthenticationContext.displayName = "AuthenticationContext";

export function useAuthenticationContext(): ContextProps {
    const context = useContext(AuthenticationContext);

    if (!context) {
        throw new Error("useAuthenticationContext must be used within a AuthenticationProvider");
    }

    return context;
}
