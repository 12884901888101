"use strict";

var _scheduleMsgs = require("../../../message/messageTypeDefinitions/schedule/scheduleMsgs");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _schedules = require("../../functions/schedules");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_scheduleMsgs.REQUEST_LIST_SCHEDULES, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.RESPONSE_LIST_SCHEDULES, (0, _schedules.getStandaloneSchedules)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_scheduleMsgs.REQUEST_LIST_EVENTS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.RESPONSE_LIST_EVENTS, (0, _schedules.getStandaloneEvents)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_scheduleMsgs.REQUEST_REMOVE_EVENT, function (payload) {
  var event = (0, _schedules.removeEvent)(payload);
  var schedule = (0, _schedules.removeScheduleFromEvent)(event);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.RESPONSE_REMOVE_EVENT, event.id);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.NOTIFY_EVENT_REMOVED, event);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.NOTIFY_SCHEDULE_REMOVED, schedule);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_scheduleMsgs.REQUEST_CREATE_AND_SCHEDULE_EVENT, function (payload) {
  var _createAndScheduleEve = (0, _schedules.createAndScheduleEvent)(payload),
    event = _createAndScheduleEve.event,
    schedule = _createAndScheduleEve.schedule;
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.NOTIFY_EVENT_CREATED, event);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.NOTIFY_SCHEDULE_CREATED, schedule);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.NOTIFY_EVENT_SCHEDULED, event);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_scheduleMsgs.REQUEST_LIST_SCHEDULABLES, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.RESPOND_LIST_SCHEDULABLES, (0, _schedules.queryStandaloneSchedulables)());
});