"use strict";

var _MappingMsgDefinations = require("../../../message/messageTypeDefinitions/mapping/MappingMsgDefinations");
var _scheduleMsgs = require("../../../message/messageTypeDefinitions/schedule/scheduleMsgs");
var _MappingGroups = require("../../../models/mapping/MappingGroups");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _mappers = require("../../functions/mappers");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_LIST_PASSIVE_MAPPERS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPONSE_LIST_PASSIVE_MAPPERS, (0, _mappers.getStandalonePassiveMappers)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_CREATE_PASSIVE_MAPPER, function (payload) {
  var result = (0, _mappers.addPassiveMapper)(payload);
  var schedulable = {
    id: {
      value: result.id.value,
      type: 'MAPPER'
    },
    name: result.name,
    description: result.description,
    type: "MAPPER"
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPONSE_CREATE_PASSIVE_MAPPER, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.NOTIFY_SCHEDULABLE_CREATED, schedulable);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_UPDATE_PASSIVE_MAPPER, function (payload) {
  var result = (0, _mappers.updatePassiveMapper)(payload);
  var schedulable = {
    id: {
      value: result.id.value,
      type: 'MAPPER'
    },
    name: result.name,
    description: result.description,
    type: "MAPPER"
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPONSE_UPDATE_PASSIVE_MAPPER, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.NOTIFY_SCHEDULABLE_UPDATED, schedulable);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_REMOVE_PASSIVE_MAPPER, function (payload) {
  var result = (0, _mappers.removePassiveMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPONSE_REMOVE_PASSIVE_MAPPER, payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_scheduleMsgs.NOTIFY_SCHEDULABLE_REMOVED, {
    value: result === null || result === void 0 ? void 0 : result.id.value,
    type: "MAPPER"
  });
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_START_PASSIVE_MAPPER, function (payload) {
  var result = (0, _mappers.startPassiveMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPONSE_START_PASSIVE_MAPPER, result);
  if ((0, _MappingGroups.isEdgeMapper)(result)) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_CREATE_EDGE_MAPPER, result);
  } else {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_CREATE_MAPPER, result);
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_STOP_PASSIVE_MAPPER, function (payload) {
  var result = (0, _mappers.stopPassiveMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPONSE_STOP_PASSIVE_MAPPER, result.id);
  if ((0, _MappingGroups.isEdgeMapper)(result)) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_REMOVE_EDGE_MAPPER, payload, null, 1000);
  } else {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_REMOVE_MAPPER, payload, null, 1000);
  }
});