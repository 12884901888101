import React, { useState } from "react";
import "./UFieldTitle.scss";
import { useTranslation } from "react-i18next";
import HelpInfo from "../../../Widgets/HelpInfo/HelpInfo";

interface IProps {
     title: string;
     help?: string;
     required?: boolean;
     length?: number | string;
     maxLength?: number;
     minLength?: number;
}

export default function UFieldTitle(props: IProps) {
     const { t } = useTranslation();

     return (
          <div className="uformTitle">
               <div className="uTitle">
                    <div style={{ color: "#D13E2C" }}></div>

                    {" " + t(props.title) + " "}

                    {props.help &&
                         <HelpInfo className="sourceTagHelp" title={t(props.help)} />}

               </div>
               {props.maxLength ? (
                    <div className="uMax">
                         {props.length}/{props.maxLength}
                    </div>
               ) : null}
          </div>
     );
}
