"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initEpicForm = exports.frameRateOptionsForEpicSourceForm = exports.epicThumbnailImages = exports.allIntegrationNames = exports.NOT_SET = exports.EpicServerHeaderDefault = exports.EpicPassportOptions = exports.DefaultBasicCredentials = void 0;
var _uuid = require("uuid");
var allIntegrationNames = exports.allIntegrationNames = ["Power BI", "Tableau", "Epic Systems", "Servicenow", "RoomOS"];
var NOT_SET = exports.NOT_SET = "NOT_SET";
// default functions

var DefaultBasicCredentials = exports.DefaultBasicCredentials = {
  username: NOT_SET,
  password: NOT_SET
};
var EpicServerHeaderDefault = exports.EpicServerHeaderDefault = function EpicServerHeaderDefault() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    isSandbox: false,
    usePasswordAuthentication: false,
    credentials: DefaultBasicCredentials,
    address: {
      value: ""
    }
  };
};
var frameRateOptionsForEpicSourceForm = exports.frameRateOptionsForEpicSourceForm = [{
  value: "0.1",
  label: "0.1",
  id: "0.1"
}, {
  value: "0.5",
  label: "0.5",
  id: "0.5"
}, {
  value: "1",
  label: "1",
  id: "1"
}, {
  value: "5",
  label: "5",
  id: "5"
}, {
  value: "10",
  label: "10",
  id: "10"
}, {
  value: "25",
  label: "25",
  id: "25"
}, {
  value: "30",
  label: "30",
  id: "30"
}, {
  value: "45",
  label: "45",
  id: "45"
}, {
  value: "60",
  label: "60",
  id: "60"
}];
var epicThumbnailImages = exports.epicThumbnailImages = [{
  value: "/usr/share/userful-plugin-epic/icons/epic-thumbnail.png",
  fileName: "epic-thumbnail.png"
}, {
  value: "/usr/share/userful-plugin-epic/icons/system-logo.png",
  fileName: "system-logo.png"
}];
var initEpicForm = exports.initEpicForm = function initEpicForm() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    isSandbox: false,
    usePasswordAuthentication: false,
    credentials: DefaultBasicCredentials,
    address: {
      value: ""
    },
    type: "Department Wait Time",
    instanceID: "",
    totalBeds: 0,
    occupiedBeds: 0
  };
};
var EpicPassportOptions = exports.EpicPassportOptions = [{
  value: 'Department Wait Time',
  label: 'Department Wait Time',
  id: 'Department Wait Time'
}, {
  value: 'Bed Occupancy Rate',
  label: 'Bed Occupancy Rate',
  id: 'Bed Occupancy Rate'
}];