import { UConductContainerComp, UConductViewComp, UConductViewCompHeader, UConductWorkspaceComp, UConductWorkspaceCompContainers, UConductWorkspaceCompHeader } from "userful-chronos-app-common-js/dist/models/uconduct/View";
import mappingStore from "../../mapping/mappingStore";

import { NOTIFY_CREATE_UCONDUCT_CONTAINER, NOTIFY_CREATE_UCONDUCT_WORKSPACE, NOTIFY_REMOVE_UCONDUCT_CONTAINER, NOTIFY_REMOVE_UCONDUCT_WORKSPACE, NOTIFY_UPDATE_UCONDUCT_CONTAINER, NOTIFY_UPDATE_UCONDUCT_WORKSPACE_CONTAINERS, NOTIFY_UPDATE_UCONDUCT_WORKSPACE_HEADER, RESPONSE_CREATE_UCONDUCT_VIEW, RESPONSE_LIST_UCONDUCT_CONTAINERS, RESPONSE_LIST_UCONDUCT_VIEWS, RESPONSE_LIST_UCONDUCT_WORKSPACES, RESPONSE_LOAD_UCONDUCT_VIEW, RESPONSE_REMOVE_UCONDUCT_VIEW, RESPONSE_UPDATE_UCONDUCT_VIEW_GEOMETRY, RESPONSE_UPDATE_UCONDUCT_VIEW_HEADER } from "./msgTypes";
import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { uConductViewsActions } from "../uConductSlice";

// VIEW
registerMsgHandler(RESPONSE_LIST_UCONDUCT_VIEWS, (payload) => {
    console.debug("RESPONSE_LIST_UCONDUCT_VIEWS")
    const views = JSON.parse(payload) as UConductViewComp[];

    mappingStore.dispatch(uConductViewsActions.setUConductViews(views));
});
registerMsgHandler(RESPONSE_CREATE_UCONDUCT_VIEW, (payload) => {
    console.debug("RESPONSE_CREATE_UCONDUCT_VIEW")
    const view = JSON.parse(payload) as UConductViewComp;

    mappingStore.dispatch(uConductViewsActions.addUConductView(view));
});
registerMsgHandler(RESPONSE_REMOVE_UCONDUCT_VIEW, (payload) => {
    const id = JSON.parse(payload) as StringID;

    mappingStore.dispatch(uConductViewsActions.removeUConductView(id));
});
registerMsgHandler(RESPONSE_UPDATE_UCONDUCT_VIEW_HEADER, (payload) => {
    const view = JSON.parse(payload) as UConductViewCompHeader;

    mappingStore.dispatch(uConductViewsActions.updateUConductViewHeader(view));
});
registerMsgHandler(RESPONSE_UPDATE_UCONDUCT_VIEW_GEOMETRY, (payload) => {
    const view= JSON.parse(payload) as UConductViewComp;

    mappingStore.dispatch(uConductViewsActions.updateUConductViewGeometry(view));
});
registerMsgHandler(RESPONSE_LOAD_UCONDUCT_VIEW, (payload) => {
    const views = JSON.parse(payload) as UConductViewComp[];
});

// CONTAINERS
registerMsgHandler(RESPONSE_LIST_UCONDUCT_CONTAINERS, (payload) => {
    console.debug("RESPONSE_LIST_UCONDUCT_CONTAINERS")
    const containers = JSON.parse(payload) as UConductContainerComp[];

    mappingStore.dispatch(uConductViewsActions.setUConductContainers(containers));
});
registerMsgHandler(NOTIFY_CREATE_UCONDUCT_CONTAINER, (payload) => {
    console.debug("NOTIFY_CREATE_UCONDUCT_CONTAINER")
    const container = JSON.parse(payload) as UConductContainerComp;

    mappingStore.dispatch(uConductViewsActions.addUConductContainer(container));
});
registerMsgHandler(NOTIFY_REMOVE_UCONDUCT_CONTAINER, (payload) => {
    console.debug("NOTIFY_REMOVE_UCONDUCT_CONTAINER")
    const id = JSON.parse(payload) as StringID;

    mappingStore.dispatch(uConductViewsActions.removeUConductContainer(id));
});
registerMsgHandler(NOTIFY_UPDATE_UCONDUCT_CONTAINER, (payload) => {
    const container = JSON.parse(payload) as UConductContainerComp;

    mappingStore.dispatch(uConductViewsActions.updateUConductContainer(container));
});

// WORKSPACES
registerMsgHandler(RESPONSE_LIST_UCONDUCT_WORKSPACES, (payload) => {
    console.debug("RESPONSE_LIST_UCONDUCT_WORKSPACES")
    const workspaces = JSON.parse(payload) as UConductWorkspaceComp[];

    mappingStore.dispatch(uConductViewsActions.setUConductWorkspaces(workspaces));
});
registerMsgHandler(NOTIFY_CREATE_UCONDUCT_WORKSPACE, (payload) => {
    console.debug("NOTIFY_CREATE_UCONDUCT_WORKSPACE")
    const workspace = JSON.parse(payload) as UConductWorkspaceComp;

    mappingStore.dispatch(uConductViewsActions.addUConductWorkspace(workspace));
});
registerMsgHandler(NOTIFY_REMOVE_UCONDUCT_WORKSPACE, (payload) => {
    console.debug("NOTIFY_REMOVE_UCONDUCT_WORKSPACE")
    const id = JSON.parse(payload) as StringID;

    mappingStore.dispatch(uConductViewsActions.removeUConductWorkspace(id));
});
registerMsgHandler(NOTIFY_UPDATE_UCONDUCT_WORKSPACE_HEADER, (payload) => {
    console.debug("NOTIFY_UPDATE_UCONDUCT_WORKSPACE_HEADER")
    const workspace = JSON.parse(payload) as UConductWorkspaceCompHeader;

    mappingStore.dispatch(uConductViewsActions.updateUConductWorkspaceHeader(workspace));
});
registerMsgHandler(NOTIFY_UPDATE_UCONDUCT_WORKSPACE_CONTAINERS, (payload) => {
    console.debug("NOTIFY_UPDATE_UCONDUCT_WORKSPACE_CONTAINERS")
    const workspace= JSON.parse(payload) as UConductWorkspaceCompContainers;

    mappingStore.dispatch(uConductViewsActions.updateUConductWorkspaceContainers(workspace));
});
