import React, { ReactNode } from "react";
import "./Placeholders.scss";

interface IProps {
    title: string;
    description: string;
    img?: string;
    additionalComponents?: ReactNode;
}

export default function EmptyPlaceholder(props: IProps) {
    return (
        <div className="emptyPlaceholderWrapper">
            {props.img && <img src={props.img} alt={props.title} />}
            <div className="emptyPlaceholderColumn">
                <div className="emptyPlaceholderTitle">{props.title}</div>
                <div className="emptyPlaceholderDescription">{props.description}</div>
            </div>
            {props.additionalComponents !== null && props.additionalComponents}
        </div>
    );
}
