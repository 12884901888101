import React from 'react';
//@ts-ignore 
const CustomToggleClient = React.forwardRef(({ children, onClick, className }, ref) => (
	<div
		href="#"
		onClick={(e) => {
			e.preventDefault();
			onClick && onClick(e);
		}}
		className={className}

		//@ts-ignore 
		ref={ref}
	>
		{children}
	</div>
));

// added to remove 'react/display-name' warning
CustomToggleClient.displayName = 'CustomToggleClient';

export default CustomToggleClient;
//@ts-ignore 
export const CustomSelectToggle = React.forwardRef(({ children, onClick, className }, ref) => (
	<div
		href=""
		onClick={(e) => {
			e.preventDefault();
			onClick && onClick(e);
		}}
		className={className}
		//@ts-ignore 
		ref={ref}
	>
		{children}
	</div>
));

// added to remove 'react/display-name' warning
CustomSelectToggle.displayName = 'CustomSelectToggle'; 





















// type CustomToggleProps = {
//     children?: React.ReactNode;
//     onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {};
//     className?: string;
// }

// const CustomToggle = React.forwardRef(
//     (props: CustomToggleProps, ref: React.Ref<HTMLDivElement>) => (
// 	<div
// 		// href="/#"
// 		onClick={(e) => {
// 			e.preventDefault();
// 			props.onClick(e);
// 		}}
// 		className={props.className}
// 		ref={ref}
// 	>
// 		{props.children}
// 	</div>
// ));

// export default CustomToggle;


// type CustomSelectToggleProps = {
//     children?: React.ReactNode;
//     onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {};
//     className?: string;
// }

// export const CustomSelectToggle = React.forwardRef((props: CustomSelectToggleProps, ref: React.Ref<HTMLDivElement>) => (
// 	<div
// 		onClick={(e) => {
// 			e.preventDefault();
// 			props.onClick(e);
// 		}}
// 		className={props.className}
// 		ref={ref}
// 	>
// 		{props.children}
// 	</div>
// ));
