import axios from "axios";
import { checkResponseIsValid, currentURL, getError } from "./gcmAPICallers";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    UserfulUser,
    CreateKeycloakUser,
    UpdateKeycloakUser,
    AssignKeycloakGroupsToKeycloakUser,
    KeycloakUserProfileUserIDPassword,
    UserfulGroup,
    CreateKeycloakGroup,
    CopyKeycloakGroup,
    UpdateKeycloakGroup,
    AssignKeycloakUsersToKeycloakGroup,
    UserMgmtData,
} from "../../model/CarbonExternalModels";
import { AppSliceActions } from "../../store/app/appSlice";
import carbonStore from "../../store/carbonStore";
import {
    setUsers,
    updateUser,
    addUser,
    setUserGroups,
    updateUserGroup,
    addUserGroup,
    setRoles,
} from "../../store/userManagement/userManagementManager";
import { toast } from "react-toastify";
import { useAuthenticationContext } from "../../auth/AuthenticationContext";

const defaultPath = "/api/secure/usermgmt";

export const apiGetUserManagement = (token: string) => {
    const t1 = currentURL + defaultPath;

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .get(t1, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserMgmtData = response.data.arg;

            console.warn(data);

            setRoles(data.roles, "EXTERNAL");
            setUsers(data.users, "EXTERNAL");
            setUserGroups(data.groups, "EXTERNAL");

            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            
            console.warn("failed", err);

            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};
export const apiGetUsers = (token: string) => {
    const t1 = currentURL + defaultPath + "/users";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .get(t1, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulUser[] = response.data.arg;

            setUsers(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};
export const apiGetUser = (token: string, userID: StringID) => {
    if (userID) {
        const t1 = currentURL + defaultPath + "/user/" + userID.value;

        carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

        axios
            .get(t1, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (!checkResponseIsValid("authorize", response)) {
                    const errorMessage = getError(response);
                    console.error("failed", errorMessage);

                    return;
                }
                console.debug(`receive authorize`, response.data);

                const data: UserfulUser = response.data.arg;

                updateUser(data, "EXTERNAL");
                carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    carbonStore.dispatch(AppSliceActions.setLogout(true));
                }
                console.warn("failed", err);

                carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
            });
    }
};
export const apiCreateUser = (token: string, user: CreateKeycloakUser) => {
    const t1 = currentURL + defaultPath + "/user/create";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));


    axios
        .post(t1, user, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulUser = response.data.arg;

            addUser(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));

            toast('❌ User Creation Failed', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });

        });
};
export const apiUpdateUser = (token: string, user: UpdateKeycloakUser) => {
    const t1 = currentURL + defaultPath + "/user/update";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, user, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulUser = response.data.arg;

            updateUser(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));

            toast('❌ User Update Failed', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};
export const apiDeleteUser = (token: string, userID: StringID) => {
    const t1 = currentURL + defaultPath + "/user/delete";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, userID, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));

                return;
            }
            console.debug(`receive authorize`, response.data);

            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));

            apiGetUsers(token);
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));

            toast('❌ User Delete Failed', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};
export const apiEnableUser = (token: string, userID: StringID) => {
    const t1 = currentURL + defaultPath + "/user/enable";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, userID, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulUser = response.data.arg;

            updateUser(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};
export const apiDisableUser = (token: string, userID: StringID) => {
    const t1 = currentURL + defaultPath + "/user/disable";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, userID, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulUser = response.data.arg;

            updateUser(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};
export const apiAssignGroupsToUser = (token: string, groupUser: AssignKeycloakGroupsToKeycloakUser) => {
    const t1 = currentURL + defaultPath + "/user/group";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, groupUser, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulUser = response.data.arg;

            updateUser(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};
export const apiPWDUser = (token: string, credential: KeycloakUserProfileUserIDPassword) => {
    const t1 = currentURL + defaultPath + "/user/pwd";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, credential, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulUser = response.data.arg;
            // console.warn(data);

            // updateUser(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};

export const apiGetUserGroups = (token: string) => {
    const t1 = currentURL + defaultPath + "/groups";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .get(t1, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulGroup[] = response.data.arg;

            setUserGroups(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};
export const apiGetUserGroup = (token: string, groupID: StringID) => {
    if (groupID) {
        const t1 = currentURL + defaultPath + "/group/" + groupID.value;

        carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

        axios
            .get(t1, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (!checkResponseIsValid("authorize", response)) {
                    const errorMessage = getError(response);
                    console.error("failed", errorMessage);

                    return;
                }
                console.debug(`receive authorize`, response.data);

                const data: UserfulGroup = response.data.arg;

                updateUserGroup(data, "EXTERNAL");
                carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    carbonStore.dispatch(AppSliceActions.setLogout(true));
                }
                console.warn("failed", err);
                toast('❌ ' + err.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",

                });
                carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
            });
    }
};
export const apiCreateUserGroup = (token: string, group: CreateKeycloakGroup) => {
    const t1 = currentURL + defaultPath + "/group/create";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, group, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulGroup = response.data.arg;

            addUserGroup(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));

            toast('❌ Permission Set Creation Failed', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};
export const apiCopyUserGroup = (token: string, group: CopyKeycloakGroup) => {
    const t1 = currentURL + defaultPath + "/group/copy";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, group, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulGroup = response.data.arg;

            updateUserGroup(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};
export const apiUpdateUserGroup = (token: string, group: UpdateKeycloakGroup) => {
    const t1 = currentURL + defaultPath + "/group/update";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, group, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulGroup = response.data.arg;

            updateUserGroup(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));

            toast('❌ Permission Set Update Failed', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};
export const apiDeleteUserGroup = (token: string, group: StringID) => {
    const t1 = currentURL + defaultPath + "/group/delete";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, group, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));

                return;
            }
            console.debug(`receive authorize`, response.data);

            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
            
            apiGetUserGroups(token);
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));

            toast('❌ Permission Set Delete Failed', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};
export const apiAssignUserToUserGroup = (token: string, group: AssignKeycloakUsersToKeycloakGroup) => {
    const t1 = currentURL + defaultPath + "/group/user";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, group, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            console.debug(`receive authorize`, response.data);

            const data: UserfulGroup = response.data.arg;

            updateUserGroup(data, "EXTERNAL");
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err.response.status === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};
