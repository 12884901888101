interface SearchOptions {
    exactMatch: boolean;
    caseSensitive: boolean;
}

const stringMatch = (target: any, value: string | number, options?: SearchOptions): boolean => {
    if (typeof target !== "string" || !isNaN(Number(target))) {
        // don't match numbers.
        return false;
    }
    const compareTarget = options && options.caseSensitive ? String(target) : String(target).toLocaleLowerCase();
    const compareValue = options && options.caseSensitive ? String(value) : String(value).toLocaleLowerCase();
    if (options && options.exactMatch) {
        return compareTarget === compareValue;
    }
    return compareTarget.includes(compareValue);
}

const numberMatch = (target: any, value: string | number): boolean => {
    if (isNaN(Number(target)) || isNaN(Number(value))) {
        return false;
    }
    return Number(target) === Number(value);
}

const __objectHasValue = (target: any, value: string | number, callstack: any[], options?: SearchOptions): boolean => {
    if (value === null || value === undefined || value === '') {
        return true;
    }
    if (typeof target !== "object" || callstack.includes(target)) {
        return false;
    }
    callstack.push(target);
    for (const item of Object.values(target)) {
        if (stringMatch(item, value, options) ||
            numberMatch(item, value) ||
            __arrayHasValue(item, value, callstack, options) ||
            __objectHasValue(item, value, callstack, options)) {
            return true;
        }
    }
    return false;
}

export const objectHasValue = (target: object, value: string | number, options?: SearchOptions): boolean => {
    return __objectHasValue(target, value, [], options);
}

const __arrayHasValue = (target: any, value: string | number, callstack: any[], options?: SearchOptions): boolean => {
    if (!Array.isArray(target)) {
        return false;
    }
    const targets = target as any[];
    for (const item of targets) {
        if (stringMatch(item, value, options) ||
            numberMatch(item, value) ||
            __arrayHasValue(item, value, callstack, options) ||
            __objectHasValue(item, value, callstack, options)) {
            return true;
        }
    }
    return false;
}

export const arrayHasValue = (target: any[], value: string | number, options?: SearchOptions): boolean => {
    return __arrayHasValue(target, value, [], options);
}

export default function filterArrayBySearchValue(target: any[], value: string | number, options?: SearchOptions) {
    return target.filter(item =>
        stringMatch(item, value, options) ||
        numberMatch(item, value) ||
        __arrayHasValue(item, value, [], options) ||
        __objectHasValue(item, value, [], options));
}