import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import { StringID, DeleteSourcePayload } from "userful-chronos-app-common-js/dist/models/common";
import { SamOrgQuery, SamSourceAsset } from "userful-chronos-app-common-js/dist/models/sam/SAMAsset";
import { SamPlayListDef } from "userful-chronos-app-common-js/dist/models/sam/SAMPlaylist";
import { SamSourceDefCreator } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { hasReceivedInitialSAMPlaylist, hasReceivedInitialSAMPlaylistQuery, hasReceivedInitialSAMSource, hasReceivedInitialSAMSourceQuery, hasReceivedInitialSourceSpecs, hasReceviedInitialSAMAssets, hasReceviedInitialSAMAssetsQuery } from "../../InitialDataStates";
import { REQUEST_CREATE_PLAYLIST_DEF, REQUEST_CREATE_SOURCE_ASSET, REQUEST_CREATE_SOURCE_DEF, REQUEST_FIND_PLAYLIST_DEF, REQUEST_FIND_SOURCE_DEF, REQUEST_FORCE_REMOVE_PLAYLIST_DEF, REQUEST_FORCE_REMOVE_SOURCE_DEF, REQUEST_FORCE_UPDATE_PLAYLIST_DEF, REQUEST_FORCE_UPDATE_SOURCE_DEF, REQUEST_LIST_PLAYLIST_DEFS, REQUEST_LIST_SOURCE_ASSETS, REQUEST_LIST_SOURCE_DEFS, REQUEST_LIST_SOURCE_SPECS, REQUEST_QUERY_PLAYLIST_DEFS, REQUEST_QUERY_SOURCE_ASSETS, REQUEST_QUERY_SOURCE_DEFS, REQUEST_REMOVE_PLAYLIST_DEF, REQUEST_REMOVE_SOURCE_ASSET, REQUEST_REMOVE_SOURCE_DEF, REQUEST_UPDATE_PLAYLIST_DEF, REQUEST_UPDATE_SOURCE_ASSET, REQUEST_UPDATE_SOURCE_DEF } from "./SAMMsgDefinations";

// 
export const requestQuerySourceAssets = (payload: SamOrgQuery, forceUpdate?: boolean) => {
    if (hasReceviedInitialSAMAssetsQuery() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_QUERY_SOURCE_ASSETS, payload);
}
export const requestCreateSourceAssets = (payload: SamSourceAsset) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CREATE_SOURCE_ASSET, payload);
}
export const requestUpdateSourceAssets = (payload: SamSourceAsset) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_SOURCE_ASSET, payload);
}
export const requestRemoveSourceAssets = (payload: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_SOURCE_ASSET, payload);
}
export const requestListSourceAssets = (forceUpdate?: boolean) => {
    if (hasReceviedInitialSAMAssets() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_SOURCE_ASSETS);
}

// 
export const requestQueryPlaylistDefsAssets = (payload: SamOrgQuery, forceUpdate?: boolean) => {
    if (hasReceivedInitialSAMPlaylistQuery() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_QUERY_PLAYLIST_DEFS, payload);
}
export const requestCreatePlayListDef = (payload: SamPlayListDef) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CREATE_PLAYLIST_DEF, payload);
}
export const requestUpdatePlayListDef = (payload: SamPlayListDef, force: boolean) => {
    getGlobalStates().eventBus.sendMsg(force? REQUEST_FORCE_UPDATE_PLAYLIST_DEF : REQUEST_UPDATE_PLAYLIST_DEF, payload);
}
export const requestRemovePlayListDef = (payload: StringID, force: boolean) => {
    getGlobalStates().eventBus.sendMsg(force? REQUEST_FORCE_REMOVE_PLAYLIST_DEF : REQUEST_REMOVE_PLAYLIST_DEF, payload);
}
export const requestListPlayListDefs = (forceUpdate?: boolean) => {
    if (hasReceivedInitialSAMPlaylist() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_PLAYLIST_DEFS);
}
export const requestFindPlayListDef = (payload: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_FIND_PLAYLIST_DEF, payload);
}

// 
export const requestQuerySourceDefs = (payload: SamOrgQuery, forceUpdate?: boolean) => {
    if (hasReceivedInitialSAMSourceQuery() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_QUERY_SOURCE_DEFS, payload);
}
export const requestCreateSourceDef = (payload: SamSourceDefCreator) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CREATE_SOURCE_DEF, payload);
}
export const requestUpdateSourceDef = (payload: SamSourceDefCreator, force: boolean) => {
    getGlobalStates().eventBus.sendMsg(force? REQUEST_FORCE_UPDATE_SOURCE_DEF : REQUEST_UPDATE_SOURCE_DEF, payload);

}
export const requestRemoveSourceDef = (payload: DeleteSourcePayload, force: boolean) => {
    getGlobalStates().eventBus.sendMsg(force? REQUEST_FORCE_REMOVE_SOURCE_DEF : REQUEST_REMOVE_SOURCE_DEF, payload);
}
export const requestListSourceDefs = (forceUpdate?: boolean) => {
    if (hasReceivedInitialSAMSource() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_SOURCE_DEFS);
}
export const requestFindSourceDef = (payload: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_FIND_SOURCE_DEF, payload);
}

// 
export const requestListSourceSpecs = (forceUpdate?: boolean) => {
    if (hasReceivedInitialSourceSpecs() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_SOURCE_SPECS);
}