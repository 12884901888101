"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInitialStandaloneData = void 0;
var _auth = require("./default/auth");
var _channels = require("./default/channels");
var _common = require("./default/common");
var _displays = require("./default/displays");
var _license = require("./default/license");
var _mappers = require("./default/mappers");
var _sam = require("./default/sam");
var _schedules = require("./default/schedules");
var _videoEngines = require("./default/videoEngines");
var _videoWalls = require("./default/videoWalls");
var _lzString = _interopRequireDefault(require("lz-string"));
var _axios = _interopRequireDefault(require("axios"));
var _store = require("./store");
var _globalStates = require("../../globalstates/globalStates");
var _usersmanagement = require("./default/usersmanagement");
var _standaloneVersion = require("../standaloneVersion");
var _routing = require("../../routing");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var DATA_PATH = 'data/standalone_data.json';
var DEFAULT = {
  systemID: _common.STANDALONE_SYSTEMID,
  version: _standaloneVersion.STANDALONE_VERSION,
  userData: _auth.STANDALONE_USERDATA,
  permissions: _auth.STANDALONE_PERMISSIONS,
  keycloak: _auth.STANDALONE_KEYCLOAK,
  uclients: _displays.UCLIENTS_DATA,
  zeroClients: _displays.ZEROCLIENTS_DATA,
  onboards: _displays.ONBOARDS_DATA,
  uclientFirmwareConfig: _displays.UCLIENT_FIRMWARE_CONFIG,
  uclientAppVersin: _displays.UCLIENT_APP_VERSION,
  license: _license.LICENSE,
  mixerMappers: _mappers.MIXER_MAPPERS,
  edgeMappers: _mappers.EDGE_MAPPERS,
  passiveMappers: _mappers.PASSIVE_MAPPERS,
  controlRooms: _mappers.CONTROL_ROOM,
  snapshots: _mappers.SNAP_SHOTS,
  mapperCollections: _mappers.MAPPER_COLLECTIONS,
  globalLayouts: [],
  sourceAssets: _sam.SOURCE_ASSETS,
  sourceSpecs: _sam.SOURCE_SPECS,
  sources: _sam.SOURCES,
  playlists: _sam.PLAYLISTS,
  videoWalls: _videoWalls.VIDEO_WALLS,
  videoEngines: _videoEngines.VIDEO_ENGINES,
  scheduleEvents: _schedules.EVENTS,
  schedules: _schedules.SCHEDULES,
  systemSchedulables: _schedules.SYSTEM_SCHEDULABLES,
  channels: _channels.CHANNELS,
  userManagementData: _usersmanagement.STANDALONE_USERMANAGEMENTDATA,
  userExternalAuthData: _usersmanagement.STANDALONE_EXTERNAL_AUTH_DATA,
  loginEvents: _usersmanagement.STANDALONE_LOGIN_EVENTS,
  adminEvents: _usersmanagement.STANDALONE_ADMIN_EVENTS,
  clearances: _auth.STANDALONE_CLEARANCES,
  clearanceActions: _auth.STANDALONE_CLEARANCE_ACTIONS
};
var getInitialStandaloneData = exports.getInitialStandaloneData = function getInitialStandaloneData() {
  if (!(0, _globalStates.isStandalone)()) {
    return null;
  }
  try {
    if (localStorage) {
      var standaloneData = _lzString["default"].decompressFromUTF16(localStorage.getItem("standaloneData"));
      if (standaloneData) {
        var data = JSON.parse(standaloneData);
        if (dataIsValid(data)) {
          console.warn("Initialized from local storage");
          (0, _store.standaloneDatainitialized)();
          return _objectSpread(_objectSpread({}, DEFAULT), data);
        } else {
          localStorage.removeItem("standaloneData");
        }
      }
    }
  } catch (e) {
    console.warn(e);
  }
  _axios["default"].get((0, _routing.getRootPath)() + DATA_PATH).then(function (result) {
    if (result && result.data) {
      try {
        var _data = result.data;
        if (dataIsValid(_data)) {
          console.warn("Initialized from live data");
          (0, _store.standaloneDatainitialized)(_objectSpread(_objectSpread({}, DEFAULT), _data));
          return;
        }
      } catch (e) {
        console.warn(e);
      }
    }
    (0, _store.standaloneDatainitialized)();
  })["catch"](function () {
    return (0, _store.standaloneDatainitialized)();
  });
  return _objectSpread({}, DEFAULT);
};
var dataIsValid = function dataIsValid(data) {
  return data.version && data.version === _standaloneVersion.STANDALONE_VERSION && data.systemID && data.uclients && data.videoEngines && data.permissions;
};