import React, { useEffect, useState } from "react";
import { IUFormBody } from "../uFormUtils";
import { IMultiStateToggleChoice } from "../../Widgets/MultiStateToggle/multiStateToggleUtils";
import MultiStateToggle from "../../Widgets/MultiStateToggle/MultiStateToggle";
import UFormContentGroup from "../UFormContentGroup/UFormContentGroup";
import { useUFormContext } from "../Context/UFormContext";

interface IProps {
     body: IUFormBody;
}

export default function UFormBody({ body }: IProps) {
     const [choice, setChoice] = useState(0);
     const [choices, setChoices] = useState([]);

     const fieldState = useUFormContext().state.fieldState;

     useEffect(() => {
          const choices: IMultiStateToggleChoice[] = [];

          // Populate toggle choices:
          body.options.map((option) => {
               if (option.hideController) {
                    if (fieldState[option.hideController.fieldKey]) {

                         for (let i = 0; i < option.hideController.failureValues.length; i++) {
                              if (option.hideController.failureValues[i] === fieldState[option.hideController.fieldKey]) {
                                   choices.push({ name: option.title, disabled: true });
                                   return;
                              }
                         }
                    }
               }
               choices.push({ name: option.title });
          });
          setChoices(choices);
     }, [fieldState]);

     const onMultiStateToggleChange = (choice: IMultiStateToggleChoice, index: number) => {
          setChoice(index);
     };

     if (body)
          return (
               <div className="uformBody">
                    {!body.hideMenu && (
                         <MultiStateToggle choices={choices} onChange={onMultiStateToggleChange} options={{ hideOverlay: true }} />
                    )}
                    {choice >= 0 &&
                         body.options[choice].contentGroups.map((contentGroup) => {
                              if (!contentGroup) return null;
                              else if (contentGroup.hide) return null;
                              return <UFormContentGroup key={"contentGroup:" + contentGroup.title} contentGroup={contentGroup} />;
                         })}
               </div>
          );
     return null;
}
