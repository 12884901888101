import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import {
    REQUEST_CREATE_LED_ACTION, REQUEST_LIST_LED_ACTIONS,
    REQUEST_REMOVE_LED_ACTION, REQUEST_UPDATE_LED_ACTION
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/ledActionMsgDefinitions";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

import { LEDAction } from "userful-chronos-app-common-js/dist/models/integration/action";


export const requestListLEDAction = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_LED_ACTIONS);
};

export const requestCreateLEDAction = (data: LEDAction) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CREATE_LED_ACTION, data);
};

export const requestUpdateLEDAction = (data: LEDAction) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_LED_ACTION, data);
};

export const requestRemoveLEDAction = (data: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_LED_ACTION, data);
};