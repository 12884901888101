import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { useGlobalModalContext } from "./GlobalModal";
import Button from '../Button/Button';
import './modal.scss';
import { faClose } from "@fortawesome/free-solid-svg-icons";

export const CreateModal = () => {
    const { hideModal, store } = useGlobalModalContext();
    const { modalProps, onShowCallback } = store || {};
    const { title, confirmBtn, cancelBtn, icon, content, backdrop, center } = modalProps || {};

    const handleModalToggle = () => {
        hideModal();
    };

    const handleConfirm = () => {
        onShowCallback();
        hideModal();
    };



    return (
        <Modal
            show={true}
            onHide={handleModalToggle}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={backdrop}
            centered={center}
        >
            <Modal.Header>
                <div>
                    <FontAwesomeIcon icon={icon} style={{ color: ' #D13E2C', marginRight: '8px' }} />{title ? title : "Create"}
                </div>
                <div className='custom-close-btn' onClick={handleModalToggle} >
                    <FontAwesomeIcon icon={faClose} />
                </div>
            </Modal.Header>
            <Modal.Body>
                {content}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalToggle}>
                    {cancelBtn || "Cancel"}
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                    {confirmBtn || "Ok"}
                </Button>
                {/* <button className="secondary-btn" type='button' onClick={handleModalToggle}>{cancelBtn || "Cancel"}</button>
                <button className="primary-btn" type='button' onClick={handleConfirm}>{confirmBtn || "Ok"}</button> */}
            </Modal.Footer>
        </Modal>
    );
};