import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { SamUsageDataResponse } from "userful-chronos-app-common-js/dist/models/sam/Common";
import { SamSourceAsset } from "userful-chronos-app-common-js/dist/models/sam/SAMAsset";
import { SamPlayListDef } from "userful-chronos-app-common-js/dist/models/sam/SAMPlaylist";
import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { SamSourceSpec } from "userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec";
import { setReceivedInitialSAMPlaylist, setReceivedInitialSAMPlaylistQuery, setReceivedInitialSAMSource, setReceivedInitialSAMSourceQuery, setReceivedInitialSourceSpecs, setReceviedInitialSAMAssets, setReceviedInitialSAMAssetsQuery } from "../../InitialDataStates";
import { filterAppExclusiveSource, updateSamItemMatchesAppID } from "../../mapping/StoreFilters";
import { assetSliceActions } from "../assetSlice";
import { playlistSliceActions } from "../playlistSlice";
import sourceAssetStore from "../sourceAssetStore";
import { sourceSliceActions } from "../sourceSlice";
import { isSourceAllowedInCurrentApp } from 'userful-chronos-app-common-js/dist/models/sam/AppSourceTypeFilter';

import {
    NOTIFY_ADD_PLAYLIST_DEF, NOTIFY_ADD_SOURCE_ASSET,
    NOTIFY_ADD_SOURCE_DEF, NOTIFY_REMOVE_PLAYLIST_DEF,
    NOTIFY_REMOVE_SOURCE_ASSET, NOTIFY_REMOVE_SOURCE_DEF,
    NOTIFY_UPDATE_PLAYLIST_DEF, NOTIFY_UPDATE_SOURCE_ASSET,
    NOTIFY_UPDATE_SOURCE_DEF, NOTIFY_UPDATE_SOURCE_SPEC,
    RESPONSE_QUERY_SOURCE_DEFS, RESPONSE_FIND_PLAYLIST_DEF,
    RESPONSE_FIND_SOURCE_DEF, RESPONSE_LIST_PLAYLIST_DEFS,
    RESPONSE_LIST_SOURCE_ASSETS, RESPONSE_LIST_SOURCE_DEFS,
    RESPONSE_LIST_SOURCE_SPECS, RESPONSE_QUERY_PLAYLIST_DEFS,
    RESPONSE_QUERY_SOURCE_ASSETS, RESPONSE_REMOVE_PLAYLIST_DEF,
    RESPONSE_REMOVE_SOURCE_DEF, RESPONSE_UPDATE_PLAYLIST_DEF,
    RESPONSE_UPDATE_SOURCE_DEF
} from "./SAMMsgDefinations";

let appID = null;
export const setAppIDForSamReceiver = (appIDValue: string) => {
    appID = appIDValue;
}


// 
registerMsgHandler(RESPONSE_QUERY_SOURCE_ASSETS, (payload) => {
    setReceviedInitialSAMAssetsQuery();
    console.debug('receive RESPONSE_QUERY_SOURCE_ASSETS');
    const data = JSON.parse(payload) as Array<SamSourceAsset>;
    sourceAssetStore.dispatch(assetSliceActions.setAssests(data));
})
registerMsgHandler(RESPONSE_LIST_SOURCE_ASSETS, (payload) => {
    setReceviedInitialSAMAssets();
    console.debug('receive RESPONSE_LIST_SOURCE_ASSETS');
    const data = JSON.parse(payload) as Array<SamSourceAsset>;
    sourceAssetStore.dispatch(assetSliceActions.setAssests(data));
})
registerMsgHandler(NOTIFY_ADD_SOURCE_ASSET, (payload) => {
    console.debug('receive NOTIFY_ADD_SOURCE_ASSET');
    const data = JSON.parse(payload) as SamSourceAsset;
    if (!updateSamItemMatchesAppID(data, appID)) {
        console.debug('No appID match');
        return;
    }
    sourceAssetStore.dispatch(assetSliceActions.addAsset(data));
})
registerMsgHandler(NOTIFY_UPDATE_SOURCE_ASSET, (payload) => {
    console.debug('receive NOTIFY_UPDATE_SOURCE_ASSET');
    const data = JSON.parse(payload) as SamSourceAsset;
    if (!updateSamItemMatchesAppID(data, appID)) {
        console.debug('No appID match');
        sourceAssetStore.dispatch(assetSliceActions.deleteAsset(data.id.value))
        return;
    }
    sourceAssetStore.dispatch(assetSliceActions.updateAsset(data));
    if (appID) {
        sourceAssetStore.dispatch(assetSliceActions.addAsset(data));
    }
})
registerMsgHandler(NOTIFY_REMOVE_SOURCE_ASSET, (payload) => {
    console.debug('receive NOTIFY_REMOVE_SOURCE_ASSET');
    const data = JSON.parse(payload) as SamSourceAsset;
    sourceAssetStore.dispatch(assetSliceActions.deleteAsset(data.id.value));
})

// 
registerMsgHandler(RESPONSE_QUERY_PLAYLIST_DEFS, (payload) => {
    setReceivedInitialSAMPlaylistQuery();
    console.debug('receive RESPONSE_QUERY_PLAYLIST_DEFS');
    const data = JSON.parse(payload) as Array<SamPlayListDef>;
    sourceAssetStore.dispatch(playlistSliceActions.setPlaylists(data))
})
registerMsgHandler(RESPONSE_LIST_PLAYLIST_DEFS, (payload) => {
    setReceivedInitialSAMPlaylist();
    console.debug('receive RESPONSE_LIST_PLAYLIST_DEFS');
    const data = JSON.parse(payload) as Array<SamPlayListDef>;
    sourceAssetStore.dispatch(playlistSliceActions.setPlaylists(data))
})
registerMsgHandler(RESPONSE_FIND_PLAYLIST_DEF, (payload) => {
    console.debug('receive RESPONSE_FIND_PLAYLIST_DEF');
    const data = JSON.parse(payload) as SamPlayListDef;
})
registerMsgHandler(NOTIFY_ADD_PLAYLIST_DEF, (payload) => {
    console.debug('receive NOTIFY_ADD_PLAYLIST_DEF');
    const data = JSON.parse(payload) as SamPlayListDef;
    if (!updateSamItemMatchesAppID(data, appID)) {
        console.debug('No appID match');
        return;
    }
    sourceAssetStore.dispatch(playlistSliceActions.addPlaylist(data))
})
registerMsgHandler(NOTIFY_UPDATE_PLAYLIST_DEF, (payload) => {
    console.debug('receive NOTIFY_UPDATE_PLAYLIST_DEF');
    const data = JSON.parse(payload) as SamPlayListDef;
    if (!updateSamItemMatchesAppID(data, appID)) {
        console.debug('No appID match');
        sourceAssetStore.dispatch(playlistSliceActions.deletePlaylist(data.id.value))
        return;
    }
    sourceAssetStore.dispatch(playlistSliceActions.updatePlaylist(data))
    if (appID) {
        sourceAssetStore.dispatch(playlistSliceActions.addPlaylist(data));
    }
})
registerMsgHandler(NOTIFY_REMOVE_PLAYLIST_DEF, (payload) => {
    console.debug('receive NOTIFY_REMOVE_PLAYLIST_DEF');
    const data = JSON.parse(payload) as SamPlayListDef;
    sourceAssetStore.dispatch(playlistSliceActions.deletePlaylist(data.id.value))
})

registerMsgHandler(RESPONSE_UPDATE_PLAYLIST_DEF, (payload) => {
    console.debug('receive RESPONSE_UPDATE_PLAYLIST_DEF');
    const data = JSON.parse(payload) as SamUsageDataResponse;
    sourceAssetStore.dispatch(playlistSliceActions.receiveUpdateOrDeletePlaylistResponse(data))
})

registerMsgHandler(RESPONSE_REMOVE_PLAYLIST_DEF, (payload) => {
    console.debug('receive RESPONSE_REMOVE_PLAYLIST_DEF');
    const data = JSON.parse(payload) as SamUsageDataResponse;
    sourceAssetStore.dispatch(playlistSliceActions.receiveUpdateOrDeletePlaylistResponse(data))
})

// 
registerMsgHandler(RESPONSE_QUERY_SOURCE_DEFS, (payload) => {
    setReceivedInitialSAMSourceQuery();
    console.debug('receive RESPONSE_QUERY_SOURCE_DEFS');
    const data = JSON.parse(payload) as Array<SamSourceDef>;
    sourceAssetStore.dispatch(sourceSliceActions.setSources(data.filter(s => isSourceAllowedInCurrentApp(s, appID))))
})
registerMsgHandler(RESPONSE_LIST_SOURCE_DEFS, (payload) => {
    setReceivedInitialSAMSource();
    if (appID) {
        return;
    }
    console.debug('receive RESPONSE_LIST_SOURCE_DEFS');
    const data = JSON.parse(payload) as Array<SamSourceDef>;
    sourceAssetStore.dispatch(sourceSliceActions.setSources(filterAppExclusiveSource(data)))
})
registerMsgHandler(RESPONSE_FIND_SOURCE_DEF, (payload) => {
    const data = JSON.parse(payload) as SamSourceDef;
})
registerMsgHandler(NOTIFY_ADD_SOURCE_DEF, (payload) => {
    console.debug('receive NOTIFY_ADD_SOURCE_DEF');
    const data = JSON.parse(payload) as SamSourceDef;
    if (!updateSamItemMatchesAppID(data, appID) || !isSourceAllowedInCurrentApp(data, appID)) {
        console.debug('No appID match');
        return;
    }
    sourceAssetStore.dispatch(sourceSliceActions.addSource(data))
})
registerMsgHandler(NOTIFY_UPDATE_SOURCE_DEF, (payload) => {
    console.debug('receive NOTIFY_UPDATE_SOURCE_DEF');
    const data = JSON.parse(payload) as SamSourceDef;
    if (!updateSamItemMatchesAppID(data, appID) || !isSourceAllowedInCurrentApp(data, appID)) {
        console.debug('No appID match');
        sourceAssetStore.dispatch(sourceSliceActions.deleteSource(data.id.value))
        return;
    }
    sourceAssetStore.dispatch(sourceSliceActions.updateSource(data));
    if (appID) {
        sourceAssetStore.dispatch(sourceSliceActions.addSource(data));
    }
})
registerMsgHandler(NOTIFY_REMOVE_SOURCE_DEF, (payload) => {
    console.debug('receive NOTIFY_REMOVE_SOURCE_DEF');
    const data = JSON.parse(payload) as SamSourceDef;
    sourceAssetStore.dispatch(sourceSliceActions.deleteSource(data.id.value))
})
registerMsgHandler(RESPONSE_UPDATE_SOURCE_DEF, (payload) => {
    console.debug('receive RESPONSE_UPDATE_SOURCE_DEF');
    const data = JSON.parse(payload) as SamUsageDataResponse;
    sourceAssetStore.dispatch(sourceSliceActions.receiveUpdateOrDeletePlaylistResponse(data))
})
registerMsgHandler(RESPONSE_REMOVE_SOURCE_DEF, (payload) => {
    console.debug('receive RESPONSE_REMOVE_SOURCE_DEF');
    const data = JSON.parse(payload) as SamUsageDataResponse;
    sourceAssetStore.dispatch(sourceSliceActions.receiveUpdateOrDeletePlaylistResponse(data))
})

//
registerMsgHandler(RESPONSE_LIST_SOURCE_SPECS, (payload) => {
    setReceivedInitialSourceSpecs();
    console.debug('receive RESPONSE_LIST_SOURCE_SPECS');
    const data = JSON.parse(payload) as Array<SamSourceSpec>;
    sourceAssetStore.dispatch(sourceSliceActions.setSourceSpecs(data))
})
registerMsgHandler(NOTIFY_UPDATE_SOURCE_SPEC, (payload) => {
    console.debug('receive NOTIFY_UPDATE_SOURCE_SPEC');
    const data = JSON.parse(payload) as SamSourceSpec;
    sourceAssetStore.dispatch(sourceSliceActions.addOrUpdateSourceSpec(data))
})