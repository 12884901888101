import { useTranslation } from "react-i18next";
import { IUFormField } from "../uFormUtils";
import UCardSelector from "./UCardSelector";
import UCheckbox from "./UCheckbox";
import UDropdown from "./UDropdown";
import UInput from "./UInput";
import UMultiSelect from "./UMultiSelect";
import UPasswordWidget from "./UPasswordWidget";
import URadioButton from "./URadioButton";
import UTextarea from "./UTextarea";
import UToggle from "./UToggle";
import { IUFormFieldState } from "../Context/UFormContext";

interface IProps {
    field: IUFormField;
    value: any;
    error: any;
    onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

export default function UFieldsCollectionInserter({ field, onItemChange, value, error }: IProps) {
 

    if (field) {
        switch (field.type) {
            case "INPUT":
                return (
                    <UInput
                        key={"collection_field:" + field.title + ":I"}
                        field={field}
                        onItemChange={onItemChange}
                        forceValue={value && value[field.key]}
                        forceError={error && error[field.key]}
                    />
                );
            case "TEXTAREA":
                return (
                    <UTextarea
                        key={"collection_field:" + field.title + ":T"}
                        field={field}
                        onItemChange={onItemChange}
                        forceValue={value && value[field.key]}
                        forceError={error && error[field.key]}
                    />
                );
            case "PASSWORD":
                return (
                    <UPasswordWidget
                        key={"collection_field:" + field.title + ":P"}
                        field={field}
                        onItemChange={onItemChange}
                        forceValue={value && value[field.key]}
                        forceError={error && error[field.key]}
                    />
                );
            case "DROPDOWN":
                return (
                    <UDropdown
                        key={"collection_field:" + field.title + ":D"}
                        field={field}
                        onItemChange={onItemChange}
                        forceValue={value && value[field.key]}
                        forceError={error && error[field.key]}
                    />
                );

            case "MULTISELECT":
                return (
                    <UMultiSelect
                        key={"collection_field:" + field.title + ":MS"}
                        field={field}
                        onItemChange={onItemChange}
                    />
                );
            case "CHECKBOX":
                return (
                    <UCheckbox
                        key={"collection_field:" + field.title + ":C"}
                        field={field}
                        onItemChange={onItemChange}
                        forceValue={value && value[field.key]}
                        forceError={error && error[field.key]}
                    />
                );
            case "RADIO_BUTTON":
                return (
                    <URadioButton
                        key={"collection_field:" + field.title + ":RB"}
                        field={field}
                        onItemChange={onItemChange}
                        forceValue={value && value[field.key]}
                        forceError={error && error[field.key]}
                    />
                );
            case "TOGGLE":
                return (
                    <UToggle
                        key={"collection_field:" + field.title + ":TO"}
                        field={field}
                        onItemChange={onItemChange}
                        forceValue={value && value[field.key]}
                        forceError={error && error[field.key]}
                    />
                );
            case "CARD_SELECTOR":
                return (
                    <UCardSelector
                        key={"collection_field:" + field.title + ":CS"}
                        field={field}
                        onItemChange={onItemChange}
                        forceValue={value && value[field.key]}
                        forceError={error && error[field.key]}
                    />
                );
            default:
                return null;
        }
    }
    return null;



}
