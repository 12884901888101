import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StringID } from 'userful-chronos-app-common-js/dist/models/common';
import { LEDAction } from 'userful-chronos-app-common-js/dist/models/integration/action';
import {
    requestCreateLEDAction, requestUpdateLEDAction,
    requestRemoveLEDAction
} from './msgs/LEDActionsMsgSender';


const initialState: {
    actions: LEDAction[];
    ready: boolean;
} = {
    actions: [],
    ready: false,
};

export const ledActionsSlice = createSlice({
    name: 'ledActionsSlice',
    initialState,
    reducers: {
        setActions: (state, action: PayloadAction<LEDAction[]>) => {
            state.actions = [...action.payload];
            state.ready = true;
        },

        addOrUpdateActions: (state, action: PayloadAction<LEDAction>) => {
            const existingIndex = state.actions.findIndex(item => item.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                state.actions = [
                    ...state.actions.slice(0, existingIndex),
                    { ...action.payload },
                    ...state.actions.slice(existingIndex + 1),
                ];
            } else {
                state.actions = [
                    ...state.actions,
                    { ...action.payload },
                ]
            }
        },

        deleteAction: (state, action: PayloadAction<StringID>) => {
            state.actions = state.actions.filter(item => item.id.value !== action.payload.value);
        },

        requestCreateAction: (state, action: PayloadAction<LEDAction>) => {
            requestCreateLEDAction({ ...action.payload });
        },

        requestUpdateAction: (state, action: PayloadAction<LEDAction>) => {
            requestUpdateLEDAction({ ...action.payload });
        },

        requestRemoveAction: (state, action: PayloadAction<StringID>) => {
            requestRemoveLEDAction({ ...action.payload });
        },

    },
})


export const ledActionActions = ledActionsSlice.actions;

export default ledActionsSlice.reducer
