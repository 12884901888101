import {
    ControlRoomInitData,
    SnapshotComp,
    SnapshotLoadData,
    SnapshotLoadFailure,
} from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";
import mappingStore from "../../mapping/mappingStore";
import { addOperationsCenters, addOperationsCentersLayout, removeOperationsCenters, removeOperationsCentersLayout, setOperationsCenters, updateCurrentOperationsCentersLayout, updateOperationsCentersLayout } from "../operationsCentersSlice";
import {
    RECEIVE_CONTROL_ROOM_ADDED,
    RECEIVE_CONTROL_ROOM_REMOVED,
    RECEIVE_SNAPSHOT_CREATED,
    RECEIVE_SNAPSHOT_LOAD_COMPLETE,
    RECEIVE_SNAPSHOT_LOAD_FAILED,
    RECEIVE_SNAPSHOT_LOAD_STARTED,
    RECEIVE_SNAPSHOT_REMOVED,
    RECEIVE_SNAPSHOT_SAVED,
    RECEIVE_SNAPSHOT_UPDATED,
    REQUEST_LIST_CONTROL_ROOMS,
} from "./msgTypes";
import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

registerMsgHandler(REQUEST_LIST_CONTROL_ROOMS, (payload) => {
    // console.warn("REQUEST_LIST_CONTROL_ROOMS");

    const data = JSON.parse(payload) as ControlRoomInitData[];

    // console.warn(data);

    mappingStore.dispatch(setOperationsCenters(data));
});
registerMsgHandler(RECEIVE_CONTROL_ROOM_ADDED, (payload) => {
    // console.warn("REQUEST_LIST_CONTROL_ROOMS");

    const data = JSON.parse(payload) as ControlRoomInitData;

    // console.warn(data);

    mappingStore.dispatch(addOperationsCenters(data));
});
registerMsgHandler(RECEIVE_CONTROL_ROOM_REMOVED, (payload) => {
    // console.warn("REQUEST_LIST_CONTROL_ROOMS");

    const data = JSON.parse(payload) as StringID;

    // console.warn(data);

    mappingStore.dispatch(removeOperationsCenters(data));
});

// SNAPSHOTS:

registerMsgHandler(RECEIVE_SNAPSHOT_CREATED, (payload) => {
    const snapshotComp = JSON.parse(payload) as SnapshotComp;

    mappingStore.dispatch(addOperationsCentersLayout(snapshotComp));
});
registerMsgHandler(RECEIVE_SNAPSHOT_REMOVED, (payload) => {
    const snapshotID = JSON.parse(payload) as StringID;

    mappingStore.dispatch(removeOperationsCentersLayout(snapshotID));
});
registerMsgHandler(RECEIVE_SNAPSHOT_UPDATED, (payload) => {
    const snapshotComp = JSON.parse(payload) as SnapshotComp;

    mappingStore.dispatch(updateOperationsCentersLayout(snapshotComp));
});
// registerMsgHandler(RECEIVE_SNAPSHOT_SAVED, (payload) => {
//     const snapshotID = JSON.parse(payload) as StringID;
// });
// registerMsgHandler(RECEIVE_SNAPSHOT_LOAD_STARTED, (payload) => {
//     const snapshotID = JSON.parse(payload) as StringID;
// });
registerMsgHandler(RECEIVE_SNAPSHOT_LOAD_COMPLETE, (payload) => {
    const snapshotLoadData = JSON.parse(payload) as SnapshotLoadData;

    mappingStore.dispatch(updateCurrentOperationsCentersLayout(snapshotLoadData));
});
// registerMsgHandler(RECEIVE_SNAPSHOT_LOAD_FAILED, (payload) => {
//     const snapshotLoadFailure = JSON.parse(payload) as SnapshotLoadFailure;
// });
