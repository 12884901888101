
import React from "react";
import { createRoot } from 'react-dom/client';

import App from "./App";

import { Provider } from "react-redux";
import carbonStore, { CarbonContext } from "./store/carbonStore";

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
    <Provider store={carbonStore} context={CarbonContext}>
        <App />
    </Provider>,
);




