import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    NOTIFY_CREATE_MAPPER_LAYOUT_MAPPING,
    NOTIFY_CREATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION,
    NOTIFY_MAPPER_LAYOUT_APPLIED_TO_LAYOUT,
    NOTIFY_REMOVE_MAPPER_LAYOUT_MAPPING,
    NOTIFY_REMOVE_MAPPER_LAYOUT_MAPPINGS_COLLECTION,
    NOTIFY_UPDATE_MAPPER_LAYOUT_CANVAS_SIZE,
    NOTIFY_UPDATE_MAPPER_LAYOUT_GRID,
    NOTIFY_UPDATE_MAPPER_LAYOUT_INSETS,
    NOTIFY_UPDATE_MAPPER_LAYOUT_MAPPING,
    NOTIFY_UPDATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION,
    NOTIFY_UPDATE_MAPPER_LAYOUT_STREAMS,
    RESPONSE_APPLY_MAPPER_LAYOUT,
    RESPONSE_CREATE_MAPPER_LAYOUT,
    RESPONSE_LIST_MAPPER_LAYOUTS,
    RESPONSE_LIST_MAPPING_LAYAOUT_MAPPINGS,
    RESPONSE_LIST_MAPPING_LAYAOUT_MAPPINGS_COLLECTIONS,
    RESPONSE_REMOVE_MAPPER_LAYOUT,
    RESPONSE_UPDATE_MAPPER_LAYOUT_GEOMETRY,
    RESPONSE_UPDATE_MAPPER_LAYOUT_HEADER,
} from "userful-chronos-app-common-js/dist/models/layouts/messages/msgTypes";
import {
    MapperLayout,
    MapperLayoutApplyResult,
    MapperLayoutMapping,
    MapperLayoutMappingsCollection,
} from "userful-chronos-app-common-js/dist/models/layouts/GlobalLayouts";
import mappingStore from "../../mapping/mappingStore";
import {
    addMapperLayout,
    layoutSliceActions,
    removeMapperLayout,
    setMapperLayouts,
    updateMapperLayout,
} from "../layoutsSlice";
import { ConfirmationToastContentStore } from "../../../Container/ConfirmationToastContentStore";
import { toast } from "react-toastify";

registerMsgHandler(RESPONSE_LIST_MAPPER_LAYOUTS, (payload) => {
    const data = JSON.parse(payload) as MapperLayout[];

    mappingStore.dispatch(setMapperLayouts(data));
});
registerMsgHandler(RESPONSE_APPLY_MAPPER_LAYOUT, (payload) => {
    const data = JSON.parse(payload) as MapperLayoutApplyResult;

    const mapper = mappingStore
        .getState()
        .mappingGroupStore.mappingGroups.find((m) => m.id.value === data.mapperID.value);

    if (mapper) {
        const customDisplay = ConfirmationToastContentStore(mapper.name, "Edit");
        toast(customDisplay, { containerId: "confirmationContainer" });
    }
});

registerMsgHandler(RESPONSE_CREATE_MAPPER_LAYOUT, (payload) => {
    const data = JSON.parse(payload) as MapperLayout;

    mappingStore.dispatch(addMapperLayout(data));
});
registerMsgHandler(RESPONSE_REMOVE_MAPPER_LAYOUT, (payload) => {
    const data = JSON.parse(payload) as StringID;

    mappingStore.dispatch(removeMapperLayout(data));
});
registerMsgHandler(RESPONSE_UPDATE_MAPPER_LAYOUT_HEADER, (payload) => {
    const data = JSON.parse(payload) as MapperLayout;

    mappingStore.dispatch(updateMapperLayout(data));
});
registerMsgHandler(RESPONSE_UPDATE_MAPPER_LAYOUT_GEOMETRY, (payload) => {
    const data = JSON.parse(payload) as MapperLayout;

    mappingStore.dispatch(updateMapperLayout(data));
});

registerMsgHandler(NOTIFY_MAPPER_LAYOUT_APPLIED_TO_LAYOUT, (payload) => {
    const data = JSON.parse(payload) as MapperLayout;

    mappingStore.dispatch(updateMapperLayout(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_LAYOUT_CANVAS_SIZE, (payload) => {
    const data = JSON.parse(payload) as MapperLayout;

    mappingStore.dispatch(updateMapperLayout(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_LAYOUT_GRID, (payload) => {
    const data = JSON.parse(payload) as MapperLayout;

    mappingStore.dispatch(updateMapperLayout(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_LAYOUT_INSETS, (payload) => {
    const data = JSON.parse(payload) as MapperLayout;

    mappingStore.dispatch(updateMapperLayout(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_LAYOUT_STREAMS, (payload) => {
    const data = JSON.parse(payload) as MapperLayout;

    mappingStore.dispatch(updateMapperLayout(data));
});

registerMsgHandler(RESPONSE_LIST_MAPPING_LAYAOUT_MAPPINGS, (payload) => {
    console.debug("RESPONSE_LIST_MAPPING_LAYAOUT_MAPPINGS");
    const data = JSON.parse(payload) as MapperLayoutMapping[];
    mappingStore.dispatch(layoutSliceActions.setMapperLayoutMappings(data));
});
registerMsgHandler(NOTIFY_CREATE_MAPPER_LAYOUT_MAPPING, (payload) => {
    console.debug("NOTIFY_CREATE_MAPPER_LAYOUT_MAPPING");
    const data = JSON.parse(payload) as MapperLayoutMapping;
    mappingStore.dispatch(layoutSliceActions.addMapperLayoutMapping(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_LAYOUT_MAPPING, (payload) => {
    console.debug("NOTIFY_UPDATE_MAPPER_LAYOUT_MAPPING");
    const data = JSON.parse(payload) as MapperLayoutMapping;
    mappingStore.dispatch(layoutSliceActions.updateMapperLayoutMapping(data));
});
registerMsgHandler(NOTIFY_REMOVE_MAPPER_LAYOUT_MAPPING, (payload) => {
    console.debug("NOTIFY_REMOVE_MAPPER_LAYOUT_MAPPING");
    const data = JSON.parse(payload) as StringID;
    mappingStore.dispatch(layoutSliceActions.removeMapperLayoutMapping(data));
});

registerMsgHandler(RESPONSE_LIST_MAPPING_LAYAOUT_MAPPINGS_COLLECTIONS, (payload) => {
    console.debug("RESPONSE_LIST_MAPPING_LAYAOUT_MAPPINGS_COLLECTIONS");
    const data = JSON.parse(payload) as MapperLayoutMappingsCollection[];
    mappingStore.dispatch(layoutSliceActions.setMapperLayoutMappingsCollections(data));
});
registerMsgHandler(NOTIFY_CREATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, (payload) => {
    console.debug("NOTIFY_CREATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION");
    const data = JSON.parse(payload) as MapperLayoutMappingsCollection;
    mappingStore.dispatch(layoutSliceActions.addMapperLayoutMappingsCollection(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, (payload) => {
    console.debug("NOTIFY_UPDATE_MAPPER_LAYOUT_MAPPINGS_COLLECTION");
    const data = JSON.parse(payload) as MapperLayoutMappingsCollection;
    mappingStore.dispatch(layoutSliceActions.updateMapperLayoutMappingsCollection(data));
});
registerMsgHandler(NOTIFY_REMOVE_MAPPER_LAYOUT_MAPPINGS_COLLECTION, (payload) => {
    console.debug("NOTIFY_REMOVE_MAPPER_LAYOUT_MAPPINGS_COLLECTION");
    const data = JSON.parse(payload) as StringID;
    mappingStore.dispatch(layoutSliceActions.removeMapperLayoutMappingsCollection(data));
});
