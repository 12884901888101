"use strict";

require("./app/login");
require("./system/license");
require("./system/videoEngines");
require("./system/monitoring");
require("./displays/displays");
require("./displays/cdm");
require("./calibration/calibration");
require("./config/config");
require("./sam/sources");
require("./sam/playlist");
require("./schedule/schedule");
require("./mapper/mapper");
require("./mapper/mapperLayouts");
require("./mapper/passivemapper");
require("./mapper/mapperCollections");
require("./controlroom/controlroom");
require("./channels/channel");
require("./usersmanagement/usersmanagement");
require("./usersmanagement/clearances");