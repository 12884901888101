import {
    faGear,
    faCube,
    faUser,
    faChartSimple,
    faGrid,
    faBriefcase,
    faHexagon,
    faCloud,
    faWindow,
    faFlag,

} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { APP_IDS, APP_NAMES } from "userful-chronos-app-common-js/dist/models/common";
import { getRootPath } from "userful-chronos-app-common-js/dist/routing";

const rootPath = getRootPath();

export const AppConfig = {
    diamond: {
        name: APP_NAMES.ControlRoom,
        title: APP_NAMES.ControlRoom,
        titleIcon: <img alt="D" src="./assets/diamond.svg" />,
        url: rootPath + "decisions",
        helpURI: "/diamond-control-room",
    },
    carbon: {
        title: "Global Cluster Manager",
        titleIcon: <img alt="D" src="./assets/carbon.svg" />,
        url: "",
    },
    controlManager: {
        title: "CommonUI.Applicationheaders.ControlManager",
        titleIcon: <img alt="D" src="./assets/controlRoom.svg" />,
        url: rootPath + "controlroommanager",
    },
    emerald: {
        name: APP_NAMES.Engage,
        titleIcon: <img alt="E" src="./assets/emerald.svg" />,
        url: rootPath + "engage",
        helpURI: "/emerald-corporate-signage",
    },
    topaz: {
        name: APP_NAMES.Trends,
        titleIcon: <img alt="T" src="./assets/topaz.svg" />,
        url: rootPath + "trends",
        helpURI: "/topaz-business-intelligence",
    },
    amethyst: {
        name: APP_NAMES.Artistic,
        titleIcon: <img alt="A" src="./assets/amethyst.svg" />,
        url: rootPath + "artistic",
        helpURI: "/amethyst-artistic",
    },
    sapphire: {
        name: APP_NAMES.Spaces,
        titleIcon: <img alt="S" src="./assets/sapphire.svg" />,
        url: rootPath + "spaces",
        helpURI: "/sappire-meeting-room",
    },
    ruby: {
        name: APP_NAMES.Remote,
        titleIcon: <img alt="R" src="./assets/ruby.svg" />,
        url: rootPath + "remote",
        helpURI: "/ruby-remote-worker",
    },
    config: {
        title: "CommonUI.Applicationheaders.Config",
        titleIcon: <FontAwesomeIcon icon={faGear} />,
        //    titleIcon:( <img alt="config icon" src="./assets/gears.svg"/>),
        url: rootPath + "configuration",
        helpURI: "/system-configuration",
    },
    monitoring: {
        title: "CommonUI.Applicationheaders.Monitoring",
        titleIcon: <FontAwesomeIcon icon={faChartSimple} />,
        url: rootPath + "monitoring",
        helpURI: "/system-monitoring-dashboard",
    },
    sourcesAndDestinations: {
        title: "CommonUI.Applicationheaders.SourcesAndDestinations",
        titleIcon: <FontAwesomeIcon icon={faCube} />,
        url: rootPath + "source-destination-management",
        helpURI: "/source-destination-configuration",
    },
    calibration: {
        title: "CommonUI.Applicationheaders.Calibration",
        titleIcon: <FontAwesomeIcon icon={faGrid} />,
        url: rootPath + "videowall-calibration",
        helpURI: "/calibration",
    },
    userManagement: {
        title: "CommonUI.Applicationheaders.UserManagement",
        titleIcon: <FontAwesomeIcon icon={faUser} />,
        url: rootPath + "usermanagement",
        helpURI: "/usermanagement",
    },
    initialSetup: {
        title: "CommonUI.Applicationheaders.InitialSetup",
        titleIcon: <FontAwesomeIcon icon={faFlag} />,
        url: rootPath + "setup",
        helpURI: "/initial-setup",
    },
    switch: {
        title: APP_NAMES.Switch,
        // titleIcon: <FontAwesomeIcon icon={faCodePullRequest} />,
        titleIcon: <img id="uControlIcon" alt="uControl" src="./assets/uControl.svg" />,
        url: rootPath + "ucontrol",
        helpURI: "/ucontrol",
    },
    uconduct: {
        title: "uConduct",
        titleIcon: <img alt="R" src="./assets/uConduct.svg" />,
        url: rootPath + "uconduct",
        helpURI: "/uconduct",
    },
    adminCenter: {
        title: "CommonUI.Applicationheaders.AdminCenter",
        titleIcon: <FontAwesomeIcon icon={faBriefcase} />,
        url: rootPath + "admin",
        helpURI: "/admin",
    },
    GCM: {
        title: "CommonUI.Applicationheaders.GCM",
        titleIcon: <img alt="Carbon" src="./assets/GMC.svg" />,
        url: rootPath,
        helpURI: "/carbon",
    },
    uClientDebuggingTool: {
        title: "uClient Debugging Tool",
        titleIcon: <FontAwesomeIcon icon={faWindow} />,
        url: rootPath,
        helpURI: "/",
    },
};

export const getPreviousAppFromAppID = (
    appIDValue
): {
    title: string;
    titleIcon: any;
    url: string;
    helpURI: string;
    } => {
    switch (appIDValue) {
        case "uConduct":
            return AppConfig.diamond;
        case "Calibration":
            return AppConfig.sourcesAndDestinations;
        case "Config":
        case "AetherManager":
        case "Monitoring":
            return AppConfig.adminCenter;

        default:
            return null;
    }
};

export const getAppFromAppID = (
    appIDValue
): {
    title?: string;
    name?: string;
    titleIcon: any;
    } => {
    switch (appIDValue) {
        case "uClientDebuggingTool":
            return AppConfig.uClientDebuggingTool;
        case "uConduct":
            return AppConfig.uconduct;
        case APP_IDS.Decisions:
        case "Diamond":
            return AppConfig.diamond;
        case "ControlRoomMgr":
            return AppConfig.controlManager;
        case APP_IDS.Engage:
            return AppConfig.emerald;
        case APP_IDS.Trends:
            return AppConfig.topaz;
        case APP_IDS.Artistic:
            return AppConfig.amethyst;
        case APP_IDS.Spaces:
            return AppConfig.sapphire;
        case APP_IDS.Remote:
            return AppConfig.ruby;
        case "Config":
            return AppConfig.config;
        case "Calibration":
            return AppConfig.calibration;
        case "realm-management":
        case "AetherManager":
            return AppConfig.userManagement;
        case "Setup":
            return AppConfig.initialSetup;
        case "SourcesAndDestinations":
            return AppConfig.sourcesAndDestinations;
        case "Monitoring":
            return AppConfig.monitoring;
        case APP_NAMES.Switch:
            return AppConfig.switch;
        case "adminCenter":
            return AppConfig.adminCenter;
        case APP_IDS.GCM:
            return AppConfig.GCM;
        default:
            return null;
    }
};
