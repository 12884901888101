import { configureStore } from '@reduxjs/toolkit'
import React from 'react'
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux'
import assetSlice from './assetSlice'
import playlistSlice from './playlistSlice'
import sourceSlice from './sourceSlice'

export const SourceAssetContext = React.createContext(null);

const sourceAssetStore = configureStore({
  reducer: {
      assetStore: assetSlice,
      sourceStore: sourceSlice,
      playlistStore: playlistSlice,
  },
})
export default sourceAssetStore;

export type SourceAssetState = ReturnType<typeof sourceAssetStore.getState>;
export type SourceAssetDispatch = typeof sourceAssetStore.dispatch;
export const useSourceAssetSelector: TypedUseSelectorHook<SourceAssetState> = createSelectorHook(SourceAssetContext);
export const useSourceAssetDispatch = createDispatchHook<SourceAssetDispatch>(SourceAssetContext)
