import UFieldHeaderDes from './UFieldHeaderDes';
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable';
import React from 'react';
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import { IUFormField } from "../uFormUtils";

interface IProps {
    field: IUFormField;
    onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
    forceValue?: string | number | boolean | undefined;
    forceError?: string;
}
const UMultiSelect = (props: IProps) => {

    const { t } = useTranslation();
    const { onFieldChange } = useUFormContext();


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: '#1F2747',
            maxHeight: '176px',
            height: '32px',
            padding: '0px 16px',
            background: state.isSelected ? '#F3F4F5' : state.isFocused ? '#F3F4F5' : undefined,
            display: 'flex',
            alignItems: 'center',
            "&:active": {
                background: '#F3F4F5',
            }

        }),
        control: (base, state) => ({
            // none of react-select's styles are passed to <Control />
            ...base,
            width: "528px",
            // height: '36px',
            color: '#1F2747',
            background: ' #FFFFFF',
            boxShadow: 'inset 0px 2px 4px rgba(31, 39, 71, 0.1)',
            borderRadius: '8px',
            transition: 'none',
            display: 'flex',
            border: state.menuIsOpen ? '1px solid #6C89E8' : '1px solid #C6C8CE',
            '&:hover': {
                border: '1px solid #4B5168',
            }
        }),
        valueContainer: base => ({
            ...base,
            display: 'flex',
            padding: '0px 16px',
        }),
    }

    const handleChange = (newValue) => {
        const updatedValue: Array<string> = newValue.map(item => item.id.toString());
        if (props.onItemChange) props.onItemChange({ [props.field.key]: updatedValue }, props.field);
        else onFieldChange({ [props.field.key]: updatedValue }, props.field);
    }

    const getDefaultValue = () => {
        return props.field.defaultValue.map((item) => ({ value: item, label: item, id: item, key: item }));
    }

    const filteredItems = props.field.items.filter(item => !props.field.defaultValue.includes(item.value));

    return (

        <div className="fieldHeaderDiv">
            <UFieldHeaderDes name={t(props.field.title)} description={t(props.field.description)} />
            <CreatableSelect
                styles={customStyles}
                isMulti
                onChange={handleChange}
                defaultValue={getDefaultValue()}
                options={filteredItems}
                menuPosition="fixed"
                components={{ IndicatorSeparator: () => null }}
                inputId="multi-select"
                closeMenuOnSelect={false}
            />
        </div>

    );

}

export default UMultiSelect;