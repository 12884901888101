"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStatusFromChoppingRequest = void 0;
var createStatusFromChoppingRequest = exports.createStatusFromChoppingRequest = function createStatusFromChoppingRequest(request) {
  return {
    id: request.id,
    name: request.name,
    description: request.description,
    activation: "INACTIVE"
  };
};