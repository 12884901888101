"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMonitoringInfo = exports.getCaptureCardInfo = void 0;
var _store = require("../data/store");
var getMonitoringInfo = exports.getMonitoringInfo = function getMonitoringInfo() {
  return {
    id: {
      value: (0, _store.getStandaloneDataStore)().systemID
    },
    backendHardwareInfo: {
      systemInfo: {
        manufacturer: "Userful",
        productName: "Userful Infinity Demo",
        serialNumber: "MXXXX",
        family: "Infinity",
        systemID: (0, _store.getStandaloneDataStore)().systemID,
        skuNumber: "M41XXX"
      },
      biosInfo: {
        version: "Ver. 12.8",
        releaseDate: "10/01/2023",
        romSize: " ",
        vendor: "Userful"
      },
      processorInfo: {
        vendor: "Intel(R) Corporation",
        coreCount: 2,
        coreEnabled: 2,
        version: "Intel(R) Core(TM) i3-6100 CPU @ 3.70GHz",
        threadCount: 4,
        currentSpeed: 3700
      },
      chassisInfo: {
        manufacturer: "HP",
        assetTag: "",
        serialNumber: "MXL7202JPL"
      },
      memoryInfoList: {
        memoryList: [{
          size: "8192 MB",
          serialNumber: "355154B3",
          speed: "2133 MT/s (ns)",
          partNumber: "M378A1K43CB2-CRC",
          manufacturer: "Samsung"
        }],
        numberOfDevices: 2,
        maxCapacity: "64 GB"
      },
      cacheInfoList: {
        cacheList: [{
          level: 1,
          isEnabled: true,
          isSocketed: false,
          operationalMode: "Write Back",
          errorCorrectionType: "Parity",
          installedSize: "6464 KB",
          maxSize: " ",
          associativity: "8-way Set-associative",
          socketDesignation: "L1 Cache",
          systemType: "Data"
        }, {
          level: 1,
          isEnabled: true,
          isSocketed: false,
          operationalMode: "Write Back",
          errorCorrectionType: "Parity",
          installedSize: "6464 KB",
          maxSize: " ",
          associativity: "8-way Set-associative",
          socketDesignation: "L1 Cache",
          systemType: "Instruction"
        }, {
          level: 2,
          isEnabled: true,
          isSocketed: false,
          operationalMode: "Write Back",
          errorCorrectionType: "Single-bit ECC",
          installedSize: "512512 KB",
          maxSize: " ",
          associativity: "4-way Set-associative",
          socketDesignation: "L2 Cache",
          systemType: "Unified"
        }, {
          level: 3,
          isEnabled: true,
          isSocketed: false,
          operationalMode: "Write Back",
          errorCorrectionType: "Multi-bit ECC",
          installedSize: "30723 KB",
          maxSize: " ",
          associativity: "12-way Set-associative",
          socketDesignation: "L3 Cache",
          systemType: "Unified"
        }]
      }
    },
    bootupTime: Math.round(new Date().getTime() / 1000)
  };
};
var getCaptureCardInfo = exports.getCaptureCardInfo = function getCaptureCardInfo() {
  return {
    id: {
      value: (0, _store.getStandaloneDataStore)().systemID
    },
    backendCaptureCardInfo: {
      inputs: [],
      ports: []
    }
  };
};