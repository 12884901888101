import React, { useState } from "react";
import { IUFormField } from "../uFormUtils";
import "./UFormFields.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import UFieldTitle from './UFieldTitle/UFieldTitle';

interface IProps {
     field: IUFormField;
     forceValue?: string | number | boolean | undefined;
     forceError?: string;
     onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

export default function UToggle({ field, forceValue, onItemChange }: IProps) {
     const { t } = useTranslation();

     let value = useUFormContext().state.fieldState[field.key];
     if (forceValue) value = forceValue;
     const { onFieldChange } = useUFormContext();

     return (

          <div className="fieldRowWrapper longGap" onClick={(e) => {
               if (onItemChange) onItemChange({ [field.key]: !value }, field);
               else onFieldChange({ [field.key]: !value }, field);
          }} style={{ cursor: "pointer" }}>
               <div
                    className={`toggle ${value ? "toggleActive" : null}`}
               >
                    <div className="toggleCircle"></div>
               </div>
               <div className="toggleText">{value ? t("Shared.Enabled") : t("Shared.Disabled")}</div>
          </div>

     );
}
