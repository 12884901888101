import { IClusterUnpair, IConstellationInfo, IConstellationInfoUpdate } from "../../model/CarbonExternalModels";
import carbonStore from "../carbonStore";
import { ConstellationsActions } from "./constellationsSlice";

export type TSliceManagementAction = "INTERNAL" | "EXTERNAL";

/**
 * Functions
 */
export const compareConstellations = (constellations: IConstellationInfo[], type: TSliceManagementAction): void => {
    const constellationsObject: { [id: string]: IConstellationInfo } = {};

    for (let i = 0; i < constellations.length; i++) {
        constellationsObject[constellations[i].definition.id.value] = constellations[i];
    }

    carbonStore.dispatch(ConstellationsActions.setConstellations(constellationsObject));
};
export const setConstellations = (constellations: IConstellationInfo[], type: TSliceManagementAction): void => {
    const constellationsObject: { [id: string]: IConstellationInfo } = {};

    for (let i = 0; i < constellations.length; i++) {
        constellationsObject[constellations[i].definition.id.value] = constellations[i];
    }

    carbonStore.dispatch(ConstellationsActions.setConstellations(constellationsObject));
};
export const addConstellation = (constellation: IConstellationInfo, type: TSliceManagementAction): void => {
    carbonStore.dispatch(ConstellationsActions.addConstellation(constellation));
};
export const addMultipleConstellations = (constellations: IConstellationInfo[], type: TSliceManagementAction): void => {
    for (let i = 0; i < constellations.length; i++) {
        addConstellation(constellations[i], type);
    }
};
export const updateConstellationHeader = (constellation: IConstellationInfo, type: TSliceManagementAction): void => {
    carbonStore.dispatch(ConstellationsActions.updateConstellationHeader(constellation));
};
export const updateMultipleConstellationsHeader = (constellations: IConstellationInfo[], type: TSliceManagementAction): void => {
    for (let i = 0; i < constellations.length; i++) {
        updateConstellationHeader(constellations[i], type);
    }
};
export const removeConstellation = (id: string, type: TSliceManagementAction): void => {
    carbonStore.dispatch(ConstellationsActions.removeConstellation(id));
};
export const removeMultipleConstellations = (ids: string[], type: TSliceManagementAction): void => {
    for (let i = 0; i < ids.length; i++) {
        removeConstellation(ids[i], type);
    }
};

// Cluster
export const updateCluster = (clusterInfo: IConstellationInfoUpdate): void => {
    const constellations = carbonStore.getState().constellations.constellations;

    for (const property in constellations) {
        if (property === clusterInfo.id.value) {
            const clusters = [...constellations[property].cluster];

            const foundIndex = clusters.findIndex((c) => c.id.value === clusterInfo.info.id.value);

            if (foundIndex >= 0) {
                clusters[foundIndex] = clusterInfo.info;
            } else {
                clusters.push(clusterInfo.info);
            }

            const constellation: IConstellationInfo = { ...constellations[property], cluster: clusters };
            carbonStore.dispatch(ConstellationsActions.addConstellation(constellation));

            break;
        }
    }
};
export const removeCluster = (clusterUnpair: IClusterUnpair): void => {
    const constellations = carbonStore.getState().constellations.constellations;

    for (const property in constellations) {
        if (property === clusterUnpair.id.value) {
            const clusters = [...constellations[property].cluster];

            const foundIndex = clusters.findIndex((c) => c.id.value === clusterUnpair.clusterID.value);

            if (foundIndex >= 0) {
                clusters.splice(foundIndex, 1);
            }

            const constellation: IConstellationInfo = { ...constellations[property], cluster: clusters };
            carbonStore.dispatch(ConstellationsActions.addConstellation(constellation));

            break;
        }
    }
};
