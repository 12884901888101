"use strict";

var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _MonitoringMsgDefinations = require("../../../message/messageTypeDefinitions/monitoring/MonitoringMsgDefinations");
var _monitoring = require("../../functions/monitoring");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MonitoringMsgDefinations.REQUEST_MONITORING_INFO, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MonitoringMsgDefinations.RESPONSE_MONITORING_INFO, (0, _monitoring.getMonitoringInfo)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MonitoringMsgDefinations.REQUEST_CAPTURE_CARD_INFO, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MonitoringMsgDefinations.RESPOND_CAPTURE_CARD_INFO, (0, _monitoring.getCaptureCardInfo)());
});