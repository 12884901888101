import { faLinkSimple, faMinus, faMultiply, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { IUButton } from "../buttonsUtils";
import {
    faBarsFilter,
    faCircleExclamation,
    faDiamondExclamation,
    faLinkSimpleSlash,
    faMerge,
    faRepeat,
    faRotateRight,
    faRotateLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";

export const closeButton: IUButton = {
    title: "Shared.close",
    hideTitle: true,
    icon: faMultiply,
    variant: "ICON",
};

export const undoButton: IUButton = {
    title: "Shared.undo",
    hideTitle: true,
    icon: faRotateLeft,
    variant: "ICON",
};

export const redoButton: IUButton = {
    title: "Shared.redo",
    hideTitle: true,
    icon: faRotateRight,
    variant: "ICON",
};

export const removeAssetButton: IUButton = {
    title: "Shared.removeAsset",
    hideTitle: true,
    icon: faMultiply,
    variant: "ICON",
};

export const filterButton: IUButton = {
    title: "Shared.filter",
    hideTitle: true,
    icon: faBarsFilter,
    variant: "ICON",
};

export const autoSortButton: IUButton = {
    title: "Shared.autoSort",
    icon: faMerge,
    hideOverlay: true,
    variant: "SECONDARY",
};

export const removeAllAssetButton: IUButton = {
    title: "Shared.removeAllAsset",
    icon: faMultiply,
    variant: "GHOST",
};

export const minusButton: IUButton = {
    title: "Shared.minus",
    hideTitle: true,
    icon: faMinus,
    variant: "SECONDARY",
};

export const preserveRatioButton: IUButton = {
    title: "Shared.preserveRatio",
    hideTitle: true,
    icon: faLinkSimple,
    secondIcon: faLinkSimpleSlash,
    variant: "ICON",
};

export const swapButton: IUButton = {
    title: "Shared.swapValues",
    hideTitle: true,
    icon: faRepeat,
    variant: "ICON",
};

export const plusButton: IUButton = {
    title: "Shared.plus",
    hideTitle: true,
    icon: faPlus,
    variant: "SECONDARY",
};

export const exclamationButton: IUButton = {
    title: "exclamationButton",
    hideTitle: true,
    icon: faCircleExclamation,
    static: true,
    variant: "ERROR",
};

export const warningButton: IUButton = {
    title: "Warning Button",
    hideTitle: true,
    icon: faDiamondExclamation,
    static: true,
    variant: "WARNING",
};

export const cancelButton: IUButton = {
    title: "Shared.cancel",
    hideOverlay: true,
    variant: "SECONDARY",
};

export const reassignDestination: IUButton = {
    title: "Shared.reassignDestination",
    hideOverlay: true,
    variant: "ERROR",
};

export const goToDecisions: IUButton = {
    title: "CommonUI.MappingGroups.ReassignConfirm.goToDecisions",
    hideOverlay: true,
    variant: "PRIMARY",
};

export const updateButton: IUButton = {
    title: "Shared.update",
    hideOverlay: true,
    variant: "SECONDARY",
};

export const deleteButton: IUButton = {
    title: "Shared.delete",
    hideOverlay: true,
    variant: "ERROR",
};

export const trashCanIconButton: IUButton = {
    title: "Shared.delete",
    icon: faTrashCan,
    hideTitle: true,
    variant: "ICON",
};

export const trashCanIconButtonCard: IUButton = {
    title: "Shared.delete",
    icon: faTrashCan,
    hideTitle: true,
    variant: "NEW-ICON",
};

export const discardChangesButton: IUButton = {
    title: "Shared.discardChanges",
    hideOverlay: true,
    variant: "ERROR",
};

export const createButton: IUButton = {
    title: "Shared.close",
    hideOverlay: true,
    variant: "CREATION",
};

export const saveButton: IUButton = {
    title: "Shared.save",
    hideOverlay: true,
    variant: "PRIMARY",
};

export const saveChangesButton: IUButton = {
    title: "Shared.saveChanges",
    hideOverlay: true,
    variant: "PRIMARY",
};

export const saveWithWarningButton: IUButton = {
    title: "Shared.save",
    hideOverlay: true,
    variant: "ERROR",
};

export const saveCloseButton: IUButton = {
    title: "Shared.saveClose",
    hideOverlay: true,
    variant: "PRIMARY",
};
