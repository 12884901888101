"use strict";

var _controlRoomMsgDefinitions = require("../../../message/messageTypeDefinitions/controlroom/controlRoomMsgDefinitions");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _mapperCollections = require("../../functions/mapperCollections");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_controlRoomMsgDefinitions.SEND_LIST_MAPPER_COLLECTION, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_controlRoomMsgDefinitions.RECEIVE_LIST_MAPPER_COLLECTION, (0, _mapperCollections.getStandaloneMapperCollections)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_controlRoomMsgDefinitions.SEND_CREATE_MAPPER_COLLECTION, function (payload) {
  var result = (0, _mapperCollections.createMapperCollection)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_controlRoomMsgDefinitions.RECEIVE_CREATE_MAPPER_COLLECTION, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_controlRoomMsgDefinitions.SEND_UPDATE_MAPPER_COLLECTION, function (payload) {
  var result = (0, _mapperCollections.updateMapperCollectionHeader)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_controlRoomMsgDefinitions.RECEIVE_UPDATE_MAPPER_COLLECTION, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_controlRoomMsgDefinitions.SEND_REMOVE_MAPPER_COLLECTION, function (payload) {
  var result = (0, _mapperCollections.removeMapperCollectiono)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_controlRoomMsgDefinitions.RECEIVE_REMOVE_MAPPER_COLLECTION, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_controlRoomMsgDefinitions.SEND_ATTACH_MAPPER, function (payload) {
  var result = (0, _mapperCollections.attachMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_controlRoomMsgDefinitions.RECEIVE_ATTACH_MAPPER, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_controlRoomMsgDefinitions.SEND_DETACH_MAPPER, function (payload) {
  var result = (0, _mapperCollections.detachMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_controlRoomMsgDefinitions.RECEIVE_DETACH_MAPPER, payload);
});