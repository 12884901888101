import React, { useState } from "react";
import { IUFormField } from "../uFormUtils";
import "./UFormFields.scss";
import { useTranslation } from "react-i18next";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import MeetingRoomBgSelect from "../../Widgets/ColorPicker/MeetingRoomBgSelect";

interface IProps {
     field: IUFormField;
     forceValue?: string | number | boolean | undefined;
     forceError?: string;
     onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

export default function UColorPicker({ field, forceValue, onItemChange }: IProps) {
     const { t } = useTranslation();

     let value = useUFormContext().state.fieldState[field.key];
     if (forceValue) value = forceValue;
     const { onFieldChange } = useUFormContext();

     return (
          <MeetingRoomBgSelect input={value} setInput={(value) => {
               if (onItemChange) onItemChange({ [field.key]: value }, field);
               else onFieldChange({ [field.key]: value }, field);
          }} />
     );
}
