"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS_COLLECTIONS = exports.RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS = exports.REQUEST_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.REQUEST_UPDATE_MAPPER_DESTINATION_MAPPING = exports.REQUEST_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.REQUEST_REMOVE_MAPPER_DESTINATION_MAPPING = exports.REQUEST_LIST_MAPPER_DESTINATION_MAPPINGS_COLLECTIONS = exports.REQUEST_LIST_MAPPER_DESTINATION_MAPPINGS = exports.REQUEST_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.REQUEST_CREATE_MAPPER_DESTINATION_MAPPING = exports.REQUEST_APPLY_MAPPING_DESTINATION_MAPPINGS_COLLECTION = exports.REQUEST_APPLY_MAPPING_DESTINATION_MAPPING = exports.NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPING = exports.NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPING = exports.NOTIFY_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.NOTIFY_CREATE_MAPPER_DESTINATION_MAPPING = exports.NOTIFY_APPLY_MAPPING_DESTINATION_MAPPINGS_COLLECTION = exports.NOTIFY_APPLY_MAPPING_DESTINATION_MAPPING = void 0;
var _messageModel = require("../../messageModel");
var MAPPER_DESTINATION_MAPPING = 3031;
var MAPPER_DESTINATION_MAPPINGS_COLLECTION = 3032;
var REQUEST_LIST_MAPPER_DESTINATION_MAPPINGS = exports.REQUEST_LIST_MAPPER_DESTINATION_MAPPINGS = new _messageModel.RequestMessageDefinition(MAPPER_DESTINATION_MAPPING, 0);
var REQUEST_CREATE_MAPPER_DESTINATION_MAPPING = exports.REQUEST_CREATE_MAPPER_DESTINATION_MAPPING = new _messageModel.RequestMessageDefinition(MAPPER_DESTINATION_MAPPING, 1);
var REQUEST_UPDATE_MAPPER_DESTINATION_MAPPING = exports.REQUEST_UPDATE_MAPPER_DESTINATION_MAPPING = new _messageModel.RequestMessageDefinition(MAPPER_DESTINATION_MAPPING, 2);
var REQUEST_REMOVE_MAPPER_DESTINATION_MAPPING = exports.REQUEST_REMOVE_MAPPER_DESTINATION_MAPPING = new _messageModel.RequestMessageDefinition(MAPPER_DESTINATION_MAPPING, 3);
var REQUEST_APPLY_MAPPING_DESTINATION_MAPPING = exports.REQUEST_APPLY_MAPPING_DESTINATION_MAPPING = new _messageModel.RequestMessageDefinition(MAPPER_DESTINATION_MAPPING, 4);
var RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS = exports.RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS = new _messageModel.RespondMessageDefinition(MAPPER_DESTINATION_MAPPING, 0);
var NOTIFY_CREATE_MAPPER_DESTINATION_MAPPING = exports.NOTIFY_CREATE_MAPPER_DESTINATION_MAPPING = new _messageModel.RespondMessageDefinition(MAPPER_DESTINATION_MAPPING, 1);
var NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPING = exports.NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPING = new _messageModel.RespondMessageDefinition(MAPPER_DESTINATION_MAPPING, 2);
var NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPING = exports.NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPING = new _messageModel.RespondMessageDefinition(MAPPER_DESTINATION_MAPPING, 3);
var NOTIFY_APPLY_MAPPING_DESTINATION_MAPPING = exports.NOTIFY_APPLY_MAPPING_DESTINATION_MAPPING = new _messageModel.RespondMessageDefinition(MAPPER_DESTINATION_MAPPING, 4);
var REQUEST_LIST_MAPPER_DESTINATION_MAPPINGS_COLLECTIONS = exports.REQUEST_LIST_MAPPER_DESTINATION_MAPPINGS_COLLECTIONS = new _messageModel.RequestMessageDefinition(MAPPER_DESTINATION_MAPPINGS_COLLECTION, 0);
var REQUEST_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.REQUEST_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = new _messageModel.RequestMessageDefinition(MAPPER_DESTINATION_MAPPINGS_COLLECTION, 1);
var REQUEST_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.REQUEST_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = new _messageModel.RequestMessageDefinition(MAPPER_DESTINATION_MAPPINGS_COLLECTION, 2);
var REQUEST_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.REQUEST_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = new _messageModel.RequestMessageDefinition(MAPPER_DESTINATION_MAPPINGS_COLLECTION, 3);
var REQUEST_APPLY_MAPPING_DESTINATION_MAPPINGS_COLLECTION = exports.REQUEST_APPLY_MAPPING_DESTINATION_MAPPINGS_COLLECTION = new _messageModel.RequestMessageDefinition(MAPPER_DESTINATION_MAPPINGS_COLLECTION, 4);
var RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS_COLLECTIONS = exports.RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS_COLLECTIONS = new _messageModel.RespondMessageDefinition(MAPPER_DESTINATION_MAPPINGS_COLLECTION, 0);
var NOTIFY_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.NOTIFY_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = new _messageModel.RespondMessageDefinition(MAPPER_DESTINATION_MAPPINGS_COLLECTION, 1);
var NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = new _messageModel.RespondMessageDefinition(MAPPER_DESTINATION_MAPPINGS_COLLECTION, 2);
var NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = exports.NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION = new _messageModel.RespondMessageDefinition(MAPPER_DESTINATION_MAPPINGS_COLLECTION, 3);
var NOTIFY_APPLY_MAPPING_DESTINATION_MAPPINGS_COLLECTION = exports.NOTIFY_APPLY_MAPPING_DESTINATION_MAPPINGS_COLLECTION = new _messageModel.RespondMessageDefinition(MAPPER_DESTINATION_MAPPINGS_COLLECTION, 4);