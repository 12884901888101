"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeScheduleFromEvent = exports.removeEvent = exports.queryStandaloneSchedulables = exports.getStandaloneSchedules = exports.getStandaloneEvents = exports.createAndScheduleEvent = void 0;
var _store = require("../data/store");
var _mappers = require("./mappers");
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var getStandaloneSchedules = exports.getStandaloneSchedules = function getStandaloneSchedules() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().schedules);
};
var getStandaloneEvents = exports.getStandaloneEvents = function getStandaloneEvents() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().scheduleEvents);
};
var queryStandaloneSchedulables = exports.queryStandaloneSchedulables = function queryStandaloneSchedulables() {
  return [].concat(_toConsumableArray((0, _store.getStandaloneDataStore)().systemSchedulables), _toConsumableArray((0, _mappers.getStandalonePassiveMappers)().map(function (item) {
    return {
      id: {
        value: item.id.value,
        type: 'MAPPER'
      },
      name: item.name,
      description: item.description,
      type: "MAPPER"
    };
  })));
};
var createAndScheduleEvent = exports.createAndScheduleEvent = function createAndScheduleEvent(creator) {
  var schedule = {
    id: creator.id,
    name: creator.name,
    description: creator.description,
    linkedEvent: creator.id,
    scheduleData: creator.scheduleData
  };
  var event = {
    id: creator.id,
    name: creator.name,
    description: creator.description,
    priority: creator.priority,
    scheduleID: creator.id,
    schedulableID: creator.schedulableID,
    scheduled: "ACTIVE",
    scheduledInstant: {
      epochMillis: new Date().getTime() / 1000
    },
    executionTimes: 0,
    additionalData: creator.additionalData
  };
  var schedules = getStandaloneSchedules();
  var events = getStandaloneEvents();
  schedules.push(schedule);
  events.push(event);
  (0, _store.updateStandaloneDataStore)({
    schedules: schedules
  });
  (0, _store.updateStandaloneDataStore)({
    scheduleEvents: events
  });
  return {
    schedule: schedule,
    event: event
  };
};
var removeScheduleFromEvent = exports.removeScheduleFromEvent = function removeScheduleFromEvent(event) {
  var schedules = getStandaloneSchedules();
  var foundIndex = schedules.findIndex(function (item) {
    return item.id.value === (event === null || event === void 0 ? void 0 : event.scheduleID.value);
  });
  if (foundIndex >= 0) {
    var result = schedules.splice(foundIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      schedules: schedules
    });
    return result;
  }
  return null;
};
var removeEvent = exports.removeEvent = function removeEvent(id) {
  var events = getStandaloneEvents();
  var foundIndex = events.findIndex(function (item) {
    return item.id.value === id.value;
  });
  if (foundIndex >= 0) {
    var result = events.splice(foundIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      scheduleEvents: events
    });
    return result;
  }
  return null;
};