import { HasCdmOrgData } from "userful-chronos-app-common-js/dist/models/cdm/Common";
import { HasOrgData } from "userful-chronos-app-common-js/dist/models/sam/Common";
import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";

export const updateSamItemMatchesAppID = (update: HasOrgData, appID?: string) => {
    if (!appID || !update.orgData.appIDs.valid || update.orgData.appIDs.appIDs.length < 1) {
        return true;
    }
    const allowedAppIDs = update.orgData.appIDs.appIDs;
    return allowedAppIDs.findIndex(item => item.value === appID) >= 0;
}

export const filterSamItemsByAppID = (updates: HasOrgData[], appID?: string) => {
    if (!appID) {
        return [...updates];
    }
    return updates.filter(item => updateSamItemMatchesAppID(item, appID));
}

export const updateCdmItemMatchesAppID = (update: HasCdmOrgData, appID?: string) => {
    if (!appID || !update.orgData.serverAppIDs.valid) {
        return true;
    }
    const allowedAppIDs = update.orgData.serverAppIDs.appIDs;
    return allowedAppIDs.findIndex(item => item.value === appID) >= 0;
}

export const filterCdmItemsByAppID = (updates: HasCdmOrgData[], appID?: string) => {
    if (!appID) {
        return [...updates];
    }
    return updates.filter(item => updateCdmItemMatchesAppID(item, appID));
}

const APP_EXCLUSIVE_SOURCES = ['Sapphire_Spaces_-_Beta'];

export const filterAppExclusiveSource = (sources: SamSourceDef[]): SamSourceDef[] => sources.filter(source => !APP_EXCLUSIVE_SOURCES.includes(source.type.value));