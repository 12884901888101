"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.interpretErrorTypePreffix = void 0;
// "veo is dumb", so have to guess here
var interpretErrorTypePreffix = exports.interpretErrorTypePreffix = function interpretErrorTypePreffix(error) {
  if (error.debugData && error.debugData.toLowerCase().includes("pulsesrc.c")) {
    return "AUDIO_";
  }
  if (error.playerType === 'SOURCE') {
    // if (error.debugData &&
    //     (error.debugData.toLowerCase().includes("gstsouphttpsrc.c") || error.debugData.toLowerCase().includes("gstrtspsrc.c"))) {
    //     return "SOURCE_URL_"
    // }
    if (error.media && (error.media.toLowerCase().startsWith("rtsp") || error.media.toLowerCase().startsWith("http") || error.media.toLowerCase().startsWith("rtp"))) {
      return "SOURCE_URL_";
    }
    return "SOURCE_";
  }
  if (error.errorType === 'MEDIA_ERROR') {
    return '';
  }
  if (error.errorType !== 'PIPELINE_ERROR') {
    return '';
  }
  if (error.debugData && (error.debugData.toLowerCase().includes("gstsouphttpsrc.c") || error.debugData.toLowerCase().includes("gstrtspsrc.c"))) {
    return "URL_";
  }
  return '';
};