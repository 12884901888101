"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStandaloneData = void 0;
var updateStandaloneData = exports.updateStandaloneData = function updateStandaloneData(data, update) {
  for (var key in update) {
    if (update[key]) {
      data[key] = update[key];
    }
  }
};