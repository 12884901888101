"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateInsetVisibility = exports.updateInsetGeometry = exports.removeControlRoom = exports.getStandaloneSnapshots = exports.getStandaloneControlRoomInitData = exports.getStandaloneControlRoomData = exports.createControlRoom = void 0;
var _globalStates = require("../../globalstates/globalStates");
var _MappingGroups = require("../../models/mapping/MappingGroups");
var _store = require("../data/store");
var _mappers = require("./mappers");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var getStandaloneSnapshots = exports.getStandaloneSnapshots = function getStandaloneSnapshots() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().snapshots);
};
var getStandaloneControlRoomInitData = exports.getStandaloneControlRoomInitData = function getStandaloneControlRoomInitData() {
  var mappers = (0, _mappers.getStandaloneMixerMappers)();
  var snapshots = getStandaloneSnapshots();
  return (0, _store.getStandaloneDataStore)().controlRooms.reduce(function (acc, curr) {
    var foundMapper = mappers.find(function (m) {
      return m.appInstanceID.value === curr.id.value;
    });
    if (foundMapper) {
      var foudnSnapshots = snapshots.filter(function (s) {
        return s.sceneID.value === foundMapper.sceneID.value;
      });
      var initData = _objectSpread(_objectSpread({}, curr), {}, {
        configData: _objectSpread(_objectSpread({}, foundMapper), {}, {
          streams: [(0, _mappers.createMJEPStreamComp)(foundMapper)]
        }),
        snapshots: foudnSnapshots
      });
      acc.push(initData);
    }
    return acc;
  }, []);
};
var getStandaloneControlRoomData = exports.getStandaloneControlRoomData = function getStandaloneControlRoomData() {
  return getStandaloneControlRoomInitData().map(function (c) {
    return {
      id: c.id,
      name: c.configData.name,
      description: c.configData.description
    };
  });
};
var createControlRoom = exports.createControlRoom = function createControlRoom(request) {
  var mapperCreator = _objectSpread(_objectSpread(_objectSpread({}, (0, _MappingGroups.initMappingGroup)()), request), {}, {
    appInstanceID: {
      value: request.id.value,
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    },
    insets: [],
    deploymentHints: request.hints,
    logicalGridData: request.grid,
    uclientLatency: request.uClientLatency,
    streams: [] // to-do: something about live update
  });
  var mapper = (0, _mappers.createMixerMapper)(mapperCreator, null, true);
  var controlRooms = _toConsumableArray((0, _store.getStandaloneDataStore)().controlRooms);
  var data = {
    id: request.id,
    name: request.name,
    description: request.description
  };
  controlRooms.push(data);
  (0, _store.updateStandaloneDataStore)({
    controlRooms: controlRooms
  });
  return _objectSpread(_objectSpread({}, data), {}, {
    configData: mapper,
    snapshots: []
  });
};
var removeControlRoom = exports.removeControlRoom = function removeControlRoom(id) {
  var controlRooms = getStandaloneControlRoomInitData();
  var foundIndex = controlRooms.findIndex(function (c) {
    return c.id.value === id.value;
  });
  if (foundIndex >= 0) {
    var removedMapper = (0, _mappers.removeMapper)(controlRooms[foundIndex].configData.id);
    var controlRoom = controlRooms.splice(foundIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      controlRooms: controlRooms.map(function (c) {
        return {
          id: c.id,
          name: c.configData.name,
          description: c.configData.description
        };
      })
    });
    return {
      id: id,
      mapperID: removedMapper === null || removedMapper === void 0 ? void 0 : removedMapper.id
    };
  }
  return {
    id: id,
    mapperID: id
  };
};
var updateInsetGeometry = exports.updateInsetGeometry = function updateInsetGeometry(request) {
  var mixerMappers = (0, _mappers.getStandaloneMixerMappers)();
  var foundIndex = mixerMappers.findIndex(function (m) {
    return m.id.value === request.mapperID.value;
  });
  if (foundIndex >= 0) {
    var insets = _toConsumableArray(mixerMappers[foundIndex].insets);
    var foundInsetIndex = insets.findIndex(function (i) {
      return i.id.value === request.id.value;
    });
    if (foundInsetIndex >= 0) {
      var update = _objectSpread(_objectSpread({}, insets[foundInsetIndex]), {}, {
        videoData: [_objectSpread(_objectSpread({}, insets[foundInsetIndex].videoData[0]), {}, {
          position: request.positionInfo
        })]
      });
      insets.splice(foundInsetIndex, 1, update);
      mixerMappers.splice(foundIndex, 1, _objectSpread(_objectSpread({}, mixerMappers[foundIndex]), {}, {
        insets: insets
      }));
      (0, _store.updateStandaloneDataStore)({
        mixerMappers: mixerMappers
      });
    }
  }
  return request;
};
var updateInsetVisibility = exports.updateInsetVisibility = function updateInsetVisibility(request) {
  var mixerMappers = (0, _mappers.getStandaloneMixerMappers)();
  var foundIndex = mixerMappers.findIndex(function (m) {
    return m.id.value === request.mapperID.value;
  });
  if (foundIndex >= 0) {
    var insets = _toConsumableArray(mixerMappers[foundIndex].insets);
    var foundInsetIndex = insets.findIndex(function (i) {
      return i.id.value === request.id.value;
    });
    if (foundInsetIndex >= 0) {
      var update = _objectSpread(_objectSpread({}, insets[foundInsetIndex]), {}, {
        videoData: [_objectSpread(_objectSpread({}, insets[foundInsetIndex].videoData[0]), {}, {
          visibility: request.visibility
        })]
      });
      insets.splice(foundInsetIndex, 1, update);
      mixerMappers.splice(foundIndex, 1, _objectSpread(_objectSpread({}, mixerMappers[foundIndex]), {}, {
        insets: insets
      }));
      (0, _store.updateStandaloneDataStore)({
        mixerMappers: mixerMappers
      });
    }
  }
  return request;
};