
import React, { useEffect, useState } from "react";
import { useUFormContext, initIUFormConditions } from "../UForm/Context/UFormContext";
import UFormProvider from "../UForm/Context/UFormProvider";
import UForm from "../UForm/UForm";
import { autoGenerateContextFromIUForm, initIUFormFieldStateFromForm } from "../UForm/uFormUtils";
import { supportFormPreset } from '../UForm/Presets/SupportFormPreset';
import { formType } from 'userful-chronos-app-common-js/dist/models/usermgt/usermgtForm';

interface IProps {

    onSaveForm?(data): void;
    onCancel?(): void;
    openForm?: boolean;

}

export default function SupportForm(props: IProps) {
    return (
        <UFormProvider>
            <SupportFormController
                onSaveForm={props.onSaveForm}
                onCancel={props.onCancel}
                openForm={props.openForm}
            />
        </UFormProvider>
    );
}

function SupportFormController(props: IProps) {


    const {
        setState,
        state: { form, refresh, fieldState },
    } = useUFormContext();

    useEffect(() => {

        if (refresh.type === "CONFIRM" && props.onSaveForm) props.onSaveForm(fieldState);
        else if (refresh.type === "CANCEL" && props.onCancel)
            props.onCancel();

    }, [refresh]);

    useEffect(() => {

        const generateForm = supportFormPreset();
        const { aFieldState, aFieldError } = autoGenerateContextFromIUForm(generateForm);

        setState({
            formConditions: { ...initIUFormConditions },
            fieldState: aFieldState,
            fieldErrors: aFieldError,
            form: generateForm,
        });
    }, [props]);


    if (form) return <UForm form={form} openForm={props.openForm} onCancel={props.onCancel} />;
    return null;
}
