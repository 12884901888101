import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

import {
    HeliosDisplayAll,
    HeliosDisplayBlackout,
    HeliosDisplayBrightness,
    HeliosDisplayColorTemperature,
    HeliosDisplayFreeze,
    HeliosDisplayGamma,
    HeliosIngestInput,
    MegapixelControllerHeader,
} from "userful-chronos-app-common-js/dist/models/integration/megapixel";
import {
    REQUEST_LIST_HELIOS_CONTROLLERS,
    REQUEST_ADD_HELIOS_CONTROLLER,
    REQUEST_UPDATE_HELIOS_CONTROLLER,
    REQUEST_REMOVE_HELIOS_CONTROLLER,
    REQUEST_SET_HELIOS_DISPLAY,
    REQUEST_SET_HELIOS_DISPLAY_BRIGHTNESS,
    REQUEST_SET_HELIOS_DISPLAY_COLORTEMPERATURE,
    REQUEST_SET_HELIOS_DISPLAY_GAMMA,
    REQUEST_SET_HELIOS_DISPLAY_BLACKOUT,
    REQUEST_SET_HELIOS_DISPLAY_FREEZE,
    REQUEST_SET_HELIOS_INGEST_INPUT,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/megapixelMsgDefinitions";

// Controller messages:
export const requestListHeliosControllers = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_HELIOS_CONTROLLERS);
};
export const requestAddHeliosController = (header: MegapixelControllerHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_HELIOS_CONTROLLER, header);
};
export const requestUpdateHeliosController = (header: MegapixelControllerHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_HELIOS_CONTROLLER, header);
};
export const requestRemoveHeliosController = (id: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_HELIOS_CONTROLLER, id);
};

// Display messages:
export const requestSetHeliosDisplay = (display: HeliosDisplayAll) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_HELIOS_DISPLAY, display);
};
export const requestSetHeliosDisplayBrightness = (brightness: HeliosDisplayBrightness) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_HELIOS_DISPLAY_BRIGHTNESS, brightness);
};
export const requestSetHeliosDisplayColorTemperature = (colorTemperature: HeliosDisplayColorTemperature) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_HELIOS_DISPLAY_COLORTEMPERATURE, colorTemperature);
};
export const requestSetHeliosDisplayGamma = (gamma: HeliosDisplayGamma) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_HELIOS_DISPLAY_GAMMA, gamma);
};
export const requestSetHeliosDisplayBlackout = (blackout: HeliosDisplayBlackout) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_HELIOS_DISPLAY_BLACKOUT, blackout);
};
export const requestSetHeliosDisplayFreeze = (freeze: HeliosDisplayFreeze) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_HELIOS_DISPLAY_FREEZE, freeze);
};
export const requestSetHeliosIngestInput = (input: HeliosIngestInput) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_HELIOS_INGEST_INPUT, input);
};
