import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isStandalone } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';

interface IProps {
    imageURI?: string;
}

const StandaloneFeatureNotAvailable = (props: IProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { style, children, ...divProps } = props;
    const { t } = useTranslation();

    if (isStandalone()) {
        return <div  {...divProps} style={{
            ...style,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            color: ' white',
            position: "relative",
            flexGrow: "1",
            fontSize: "14px",
            fontWeight: "600"
        }}>
            {props.imageURI ?
                <>
                    <img src={props.imageURI} style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        right: "0",
                        left: "0",
                    }} />
                    <div style={{  zIndex: '1', padding: "8px", borderRadius: "8px", background: "rgba(209, 62, 44, 0.7)"}}>
                        <FontAwesomeIcon icon={faWarning} style={{ color: 'white', marginRight: '8px'}} />
                        {t("CommonUI.Standalone.featureIsFake")}
                    </div>
                </>
                :
                <>
                    <FontAwesomeIcon icon={faWarning} style={{ color: ' #D13E2C', marginRight: '8px' }} />
                    {t("CommonUI.Standalone.featureNotAvailable")}
                </>
            }
        </div>
    }

    return <>{children}</>;
}

export default StandaloneFeatureNotAvailable;