"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSelection = exports.updateSelectedResolution = exports.updateSelectedEndpoint = exports.updateResolutionForAllOutpus = exports.sanitizeGiadaConfig = exports.limitedTo1080P = exports.giadaGridChanged = exports.getNumRows = exports.getNumOutpus = exports.getNumCols = exports.getGiadaCanvasSize = exports.findSelectedPresets = exports.endpointLimitedTo1080P = exports.createDisplayAtIndex = exports.MIRROR_MODE = exports.GIADA_RESOLUTION_OPTIONS = exports.GIADA_PRESETS = exports.GIADA_NOT_SET = exports.GIADA_ENDPOINT_OPTIONS = exports.DEFAULT_GIADA_SIZE = void 0;
var _common = require("../common");
var _display = require("../display");
var _uuid = require("uuid");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var DEFAULT_WIDTH = 1920;
var DEFAULT_HEIGHT = 1080;
var DEFAULT_GIADA_SIZE = exports.DEFAULT_GIADA_SIZE = {
  width: DEFAULT_WIDTH,
  height: DEFAULT_HEIGHT
};
var GIADA_NOT_SET = exports.GIADA_NOT_SET = {
  horizontalPadding: 0,
  verticalPadding: 0,
  rows: []
};
var positionEqual = function positionEqual(left, right) {
  return left.center.x === right.center.x && left.center.y === right.center.y && left.size.width === right.size.width && left.size.height === right.size.height;
};
var outputEuqal = function outputEuqal(left, right) {
  return left.id.outputID.value === right.id.outputID.value && left.rowIndex === right.rowIndex && left.columnIndex === right.columnIndex && left.outputEndpoint === right.outputEndpoint && positionEqual(left.position, right.position);
};
var giadaGridChanged = exports.giadaGridChanged = function giadaGridChanged(left, right) {
  if (left.horizontalPadding !== right.horizontalPadding || left.verticalPadding != right.verticalPadding || left.rows.length != right.rows.length) {
    return true;
  }
  for (var i = 0; i < left.rows.length; i++) {
    var leftRow = left.rows[i];
    var rightRow = right.rows[i];
    if (leftRow.columns.length !== rightRow.columns.length) {
      return true;
    }
    var _iterator = _createForOfIteratorHelper(leftRow.columns),
      _step;
    try {
      var _loop = function _loop() {
          var leftCol = _step.value;
          var foundRightCol = rightRow.columns.findIndex(function (r) {
            return outputEuqal(leftCol, r);
          });
          if (foundRightCol < 0) {
            return {
              v: true
            };
          }
        },
        _ret;
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        _ret = _loop();
        if (_ret) return _ret.v;
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
  return false;
};
var createDisplayAtIndex = exports.createDisplayAtIndex = function createDisplayAtIndex(rowIndex, columnIndex, width, height, seatID, outputID, outputEndpoint, position) {
  return {
    id: {
      seatID: seatID,
      outputID: {
        value: outputID
      },
      uniqueID: {
        value: (0, _uuid.v4)()
      }
    },
    outputEndpoint: outputEndpoint,
    position: {
      center: {
        x: (position === null || position === void 0 ? void 0 : position.center.x) || width * columnIndex + width / 2,
        y: (position === null || position === void 0 ? void 0 : position.center.y) || height * rowIndex + height / 2
      },
      size: {
        width: width,
        height: height
      },
      rotation: _common.ANGLE_DEGREES_0
    },
    rowIndex: rowIndex,
    columnIndex: columnIndex
  };
};
var GIADA_PRESET_1X4 = {
  horizontalPadding: 0,
  verticalPadding: 0,
  rows: [{
    columns: [createDisplayAtIndex(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "1", 'NONE'), createDisplayAtIndex(0, 1, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "2", 'NONE'), createDisplayAtIndex(0, 2, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "3", 'NONE'), createDisplayAtIndex(0, 3, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "4", 'NONE')]
  }]
};
var GIADA_PRESET_4x1 = {
  horizontalPadding: 0,
  verticalPadding: 0,
  rows: [{
    columns: [createDisplayAtIndex(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "1", 'NONE')]
  }, {
    columns: [createDisplayAtIndex(1, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "2", 'NONE')]
  }, {
    columns: [createDisplayAtIndex(2, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "3", 'NONE')]
  }, {
    columns: [createDisplayAtIndex(3, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "4", 'NONE')]
  }]
};
var GIADA_PRESET_2x2 = {
  horizontalPadding: 0,
  verticalPadding: 0,
  rows: [{
    columns: [createDisplayAtIndex(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "1", 'NONE'), createDisplayAtIndex(0, 1, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "2", 'NONE')]
  }, {
    columns: [createDisplayAtIndex(1, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "3", 'NONE'), createDisplayAtIndex(1, 1, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "4", 'NONE')]
  }]
};
var GIADA_PRESET_1x3 = {
  horizontalPadding: 0,
  verticalPadding: 0,
  rows: [{
    columns: [createDisplayAtIndex(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "1", 'NONE'), createDisplayAtIndex(0, 1, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "2", 'NONE'), createDisplayAtIndex(0, 2, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "3", 'NONE')]
  }]
};
var GIADA_PRESET_3x1 = {
  horizontalPadding: 0,
  verticalPadding: 0,
  rows: [{
    columns: [createDisplayAtIndex(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "1", 'NONE')]
  }, {
    columns: [createDisplayAtIndex(1, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "2", 'NONE')]
  }, {
    columns: [createDisplayAtIndex(2, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "3", 'NONE')]
  }]
};
var GIADA_PRESET_1x2 = {
  horizontalPadding: 0,
  verticalPadding: 0,
  rows: [{
    columns: [createDisplayAtIndex(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "1", 'NONE'), createDisplayAtIndex(0, 1, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "2", 'NONE')]
  }]
};
var GIADA_PRESET_2x1 = {
  horizontalPadding: 0,
  verticalPadding: 0,
  rows: [{
    columns: [createDisplayAtIndex(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "1", 'NONE')]
  }, {
    columns: [createDisplayAtIndex(1, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "2", 'NONE')]
  }]
};
var GIADA_PRESET_1x1 = {
  horizontalPadding: 0,
  verticalPadding: 0,
  rows: [{
    columns: [createDisplayAtIndex(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT, _common.STRINGID_NOT_SET, "1", 'NONE')]
  }]
};
var GIADA_RESOLUTIONS = [{
  width: 960,
  height: 540
}, {
  width: 1280,
  height: 720
}, DEFAULT_GIADA_SIZE
// { width: 2560, height: 1440 },
// { width: 3840, height: 2160 },
];
var GIADA_RESOLUTION_OPTIONS = exports.GIADA_RESOLUTION_OPTIONS = GIADA_RESOLUTIONS.map(function (r) {
  return {
    value: (0, _display.resolutionToString)(r),
    label: (0, _display.resolutionToString)(r)
  };
});
var limitedTo1080P = exports.limitedTo1080P = function limitedTo1080P(output) {
  return endpointLimitedTo1080P(output.outputEndpoint);
};
var endpointLimitedTo1080P = exports.endpointLimitedTo1080P = function endpointLimitedTo1080P(outputEndpoint) {
  return outputEndpoint === 'HDMI1';
};
var GIADA_ENDPOINT_OPTIONS = exports.GIADA_ENDPOINT_OPTIONS = [{
  value: 'HDMI1',
  label: 'CommonUI.uClient.giadaOutputHDMI1'
}, {
  value: 'HDMI2',
  label: 'CommonUI.uClient.giadaOutputHDMI2'
}, {
  value: 'HDMI3',
  label: 'CommonUI.uClient.giadaOutputHDMI3'
}, {
  value: 'HDMI4',
  label: 'CommonUI.uClient.giadaOutputHDMI4'
}];
var MIRROR_MODE = exports.MIRROR_MODE = "GIADA_PRESET_1x1";
var GIADA_PRESETS = exports.GIADA_PRESETS = _defineProperty({
  "GIADA_PRESET_1X4": GIADA_PRESET_1X4,
  "GIADA_PRESET_4x1": GIADA_PRESET_4x1,
  "GIADA_PRESET_2x2": GIADA_PRESET_2x2,
  "GIADA_PRESET_1x3": GIADA_PRESET_1x3,
  "GIADA_PRESET_3x1": GIADA_PRESET_3x1,
  "GIADA_PRESET_1x2": GIADA_PRESET_1x2,
  "GIADA_PRESET_2x1": GIADA_PRESET_2x1
}, MIRROR_MODE, GIADA_PRESET_1x1);
var getNumRows = exports.getNumRows = function getNumRows(data) {
  return data.rows.length;
};
var getNumCols = exports.getNumCols = function getNumCols(data) {
  var _data$rows$;
  return ((_data$rows$ = data.rows[0]) === null || _data$rows$ === void 0 ? void 0 : _data$rows$.columns.length) || 0;
};
var getNumOutpus = exports.getNumOutpus = function getNumOutpus(data) {
  return getNumRows(data) * getNumCols(data);
};
var findSelectedPresets = exports.findSelectedPresets = function findSelectedPresets(data) {
  if (getNumOutpus(data) <= 1) {
    return MIRROR_MODE;
  }
  for (var _i = 0, _Object$keys = Object.keys(GIADA_PRESETS); _i < _Object$keys.length; _i++) {
    var key = _Object$keys[_i];
    var preset = GIADA_PRESETS[key];
    if (getNumRows(data) === getNumRows(preset) && getNumCols(data) === getNumCols(preset)) {
      return key;
    }
  }
  return null;
};
var updateSelection = exports.updateSelection = function updateSelection(old, newPreset) {
  var oldOutputs = old.rows.flatMap(function (r) {
    return r.columns;
  });
  return _objectSpread(_objectSpread({}, old), {}, {
    rows: newPreset.rows.map(function (r) {
      return {
        columns: r.columns.map(function (output) {
          var foundOldOutput = oldOutputs.find(function (o) {
            return o.id.outputID.value === output.id.outputID.value;
          });
          return _objectSpread(_objectSpread({}, output), {}, {
            outputEndpoint: (foundOldOutput === null || foundOldOutput === void 0 ? void 0 : foundOldOutput.outputEndpoint) || 'NONE',
            position: _objectSpread(_objectSpread({}, output.position), {}, {
              size: (foundOldOutput === null || foundOldOutput === void 0 ? void 0 : foundOldOutput.position.size) || DEFAULT_GIADA_SIZE
            })
          });
        })
      };
    })
  });
};
var updateSelectedEndpoint = exports.updateSelectedEndpoint = function updateSelectedEndpoint(data, outputID, outputEndpoint) {
  return _objectSpread(_objectSpread({}, data), {}, {
    rows: data.rows.map(function (r) {
      return {
        columns: r.columns.map(function (output) {
          return _objectSpread(_objectSpread({}, output), {}, {
            position: _objectSpread(_objectSpread({}, output.position), {}, {
              size: outputID === output.id.outputID.value && endpointLimitedTo1080P(outputEndpoint) && output.position.size.height > 1080 ? DEFAULT_GIADA_SIZE : output.position.size
            }),
            outputEndpoint: outputID === output.id.outputID.value ? outputEndpoint : output.outputEndpoint === outputEndpoint ? 'NONE' // output cannot be reused
            : output.outputEndpoint
          });
        })
      };
    })
  });
};
var updateSelectedResolution = exports.updateSelectedResolution = function updateSelectedResolution(data, outputID, resolution) {
  return _objectSpread(_objectSpread({}, data), {}, {
    rows: data.rows.map(function (r) {
      return {
        columns: r.columns.map(function (output) {
          return _objectSpread(_objectSpread({}, output), {}, {
            position: _objectSpread(_objectSpread({}, output.position), {}, {
              size: output.id.outputID.value === outputID ? resolution : output.position.size
            })
          });
        })
      };
    })
  });
};
var updateResolutionForAllOutpus = exports.updateResolutionForAllOutpus = function updateResolutionForAllOutpus(data, resolution) {
  return _objectSpread(_objectSpread({}, data), {}, {
    rows: data.rows.map(function (r) {
      return {
        columns: r.columns.map(function (output) {
          return _objectSpread(_objectSpread({}, output), {}, {
            position: _objectSpread(_objectSpread({}, output.position), {}, {
              size: resolution
            })
          });
        })
      };
    })
  });
};
var getGiadaCanvasSize = exports.getGiadaCanvasSize = function getGiadaCanvasSize(data) {
  var maxWidth = 0;
  var yCursor = 0;
  var _iterator2 = _createForOfIteratorHelper(data.rows),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var r = _step2.value;
      var rowHeight = 0;
      var xCursor = 0;
      var _iterator3 = _createForOfIteratorHelper(r.columns),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var output = _step3.value;
          rowHeight = Math.max(output.position.size.height, rowHeight);
          xCursor = xCursor + output.position.size.width + data.horizontalPadding;
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      maxWidth = Math.max(maxWidth, xCursor);
      yCursor = yCursor + rowHeight + data.verticalPadding;
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
  return {
    width: maxWidth,
    height: yCursor
  };
};

// account for different resolution size and padding
var sanitizeGiadaConfig = exports.sanitizeGiadaConfig = function sanitizeGiadaConfig(data, seatID) {
  var yCursor = 0;
  return _objectSpread(_objectSpread({}, data), {}, {
    rows: data.rows.map(function (r) {
      var rowHeight = 0;
      var xCursor = 0;
      var updatedRow = {
        columns: r.columns.map(function (output) {
          rowHeight = Math.max(output.position.size.height, rowHeight);
          var updatedOutput = _objectSpread(_objectSpread({}, output), {}, {
            id: {
              seatID: seatID,
              outputID: output.id.outputID,
              uniqueID: {
                value: (0, _uuid.v4)()
              } // need to generate new unique ids before sending to backend, otherwise db won't save
            },
            position: _objectSpread(_objectSpread({}, output.position), {}, {
              center: {
                x: xCursor + output.position.size.width / 2,
                y: yCursor + output.position.size.height / 2
              }
            })
          });
          xCursor = xCursor + output.position.size.width + data.horizontalPadding;
          return updatedOutput;
        })
      };
      yCursor = yCursor + rowHeight + data.verticalPadding;
      return updatedRow;
    })
  });
};