import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import {
    RESPOND_LIST_PASSPORTS, RESPOND_ADD_PASSPORT, RESPOND_UPDATE_PASSPORT,
    RESPOND_UPDATE_PASSPORT_WITH_OAUTH, RESPOND_REMOVE_PASSPORT, NOTIFY_PASSPORT_UPDATE, RESPOND_LIST_COLUMNS, RESPOND_LIST_DATA
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/servicenowMsgDefinitions';
import {
    ServicenowColumns, ServicenowChoiceCount,
    ServicenowPassport, ServicenowPassportHeader, ServicenowPassportID
} from "userful-chronos-app-common-js/dist/models/integration/servicenow";
import integrationStore from "../integrationStore";
import { servicenowActions } from "../servicenowSlice";
import { registerErrorMsgHandlerByMsgDefinition } from "userful-chronos-app-common-js/dist/message/messageRegistery";

registerMsgHandler(RESPOND_LIST_PASSPORTS, (payload) => {
    console.warn("receive RESPOND_LIST_PASSPORTS");
    const data = JSON.parse(payload) as ServicenowPassport[];
    integrationStore.dispatch(servicenowActions.setPassports(data));
});

registerMsgHandler(RESPOND_ADD_PASSPORT, (payload) => {
    console.warn("receive RESPOND_ADD_PASSPORT");
    const data = JSON.parse(payload) as ServicenowPassportHeader;
    integrationStore.dispatch(servicenowActions.changeLoadState("RENDER"));
    integrationStore.dispatch(servicenowActions.setOpenForm(false));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_ADD_PASSPORT, (data) => {
    integrationStore.dispatch(servicenowActions.changeLoadState("RENDER"));
});

registerMsgHandler(RESPOND_UPDATE_PASSPORT, (payload) => {
    console.warn("receive RESPOND_UPDATE_PASSPORT");
    const data = JSON.parse(payload) as ServicenowPassportHeader;
    integrationStore.dispatch(servicenowActions.changeLoadState("RENDER"));
    integrationStore.dispatch(servicenowActions.setOpenForm(false));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_UPDATE_PASSPORT, (data) => {
    integrationStore.dispatch(servicenowActions.changeLoadState("RENDER"));
});

registerMsgHandler(RESPOND_UPDATE_PASSPORT_WITH_OAUTH, (payload) => {
    console.warn("receive RESPOND_UPDATE_PASSPORT_WITH_OAUTH");
    const data = JSON.parse(payload) as ServicenowPassportHeader;
    integrationStore.dispatch(servicenowActions.changeLoadState("RENDER"));
    integrationStore.dispatch(servicenowActions.setOpenForm(false));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_UPDATE_PASSPORT_WITH_OAUTH, (data) => {
    integrationStore.dispatch(servicenowActions.changeLoadState("RENDER"));
});

registerMsgHandler(RESPOND_REMOVE_PASSPORT, (payload) => {
    console.warn("receive RESPOND_REMOVE_PASSPORT");
    const data = JSON.parse(payload) as ServicenowPassportID;
    integrationStore.dispatch(servicenowActions.removePassport(data));
});

registerMsgHandler(NOTIFY_PASSPORT_UPDATE, (payload) => {
    console.warn("receive NOTIFY_PASSPORT_UPDATE");
    const data = JSON.parse(payload) as ServicenowPassport;
    integrationStore.dispatch(servicenowActions.addOrUpdatePassport(data));
});

registerMsgHandler(RESPOND_LIST_COLUMNS, (payload) => {
    console.warn("receive RESPOND_LIST_COLUMNS");
    const data = JSON.parse(payload) as ServicenowColumns[];
    integrationStore.dispatch(servicenowActions.setColumns(data));
});

registerMsgHandler(RESPOND_LIST_DATA, (payload) => {
    console.warn("receive RESPOND_LIST_DATA");
    const data = JSON.parse(payload) as ServicenowChoiceCount[];
    if(data.length == 0)
        integrationStore.dispatch(servicenowActions.setPlotOverlay(true));
});