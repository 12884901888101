import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    IBackupAndRestoreFilters,
    IClusterFilters,
    IConstellationFilters,
    IDeviceFilters,
    IFiltersVisibility,
    initIBackupAndRestoreFilters,
    initIClusterFilters,
    initIConstellationFilters,
    initIDeviceFilters,
    initIFiltersVisibility,
    initISearchInput,
    initIUserFilters,
    initIUserGroupFilters,
    ISearchInput,
    IUserFilters,
    IUserGroupFilters,
} from "./filtersUtils";

interface IState {
    constellations: IConstellationFilters;
    users: IUserFilters;
    userGroups: IUserGroupFilters;
    clusters: IClusterFilters;
    devices: IDeviceFilters;
    visibility: IFiltersVisibility;
    backupAndRestore: IBackupAndRestoreFilters;
    search: ISearchInput;
}

const initialState: IState = {
    constellations: initIConstellationFilters(),
    users: initIUserFilters(),
    userGroups: initIUserGroupFilters(),
    clusters: initIClusterFilters(),
    devices: initIDeviceFilters(),
    visibility: initIFiltersVisibility(),
    backupAndRestore: initIBackupAndRestoreFilters(),
    search: initISearchInput(),
};

export const filtersSlice = createSlice({
    name: "filtersSlice",
    initialState,
    reducers: {
        // TODO: Filtering
        setFiltersVisibility(state, action: PayloadAction<IFiltersVisibility>) {
            state.visibility = action.payload;
        },
        setSearchInput(state, action: PayloadAction<ISearchInput>) {
            state.search = action.payload;
        },
    },
});

export const FiltersActions = filtersSlice.actions;

export default filtersSlice.reducer;
