import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import { UserfulSearchBar } from "userful-chronos-common-ui/dist";
import { BackupData, BackupUIWrapper, IConstellationInfo } from "../../model/CarbonExternalModels";
import { AppSliceActions } from "../../store/app/appSlice";
import { useCarbonDispatch, useCarbonSelector, selectFiltersVisibility, selectSearchInput, selectBackupAndRestoreFilters, selectBackups } from "../../store/carbonStore";
import { filterBackupAndRestore } from "../../store/filters/filtersManager";
import { FiltersActions } from "../../store/filters/filtersSlice";

import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import UserfulTable from "../../common/components/UserfulTable/UserfulTable";

import { convertIBackupAndRestoreToTable } from "../../common/components/UserfulTable/UserfulTableConverter";
import { ITable } from "../../common/components/UserfulTable/userfulTableInterface";
import EmptyPlaceholder from "../../common/components/placeholders/EmptyPlaceholder";
import CreateBackupAndRestore from "./CreateBackupAndRestore";

interface IProps {
    constellations: { [id: string]: IConstellationInfo };
}

export default function BackupAndRestoreTable(props: IProps) {
    const dispatch = useCarbonDispatch();

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    // Visibility
    const filtersVisibility = useCarbonSelector(selectFiltersVisibility);

    // Filtering
    const backupAndRestoreFilters = useCarbonSelector(selectBackupAndRestoreFilters);
    const searchInput = useCarbonSelector(selectSearchInput);

    // Data
    const allBackupAndRestore: BackupUIWrapper[] = useCarbonSelector(selectBackups);
    const filteredBackupAndRestore: BackupUIWrapper[] = filterBackupAndRestore(backupAndRestoreFilters, searchInput.backupAndRestore, allBackupAndRestore);

    // console.warn(allBackupAndRestore);

    const table: ITable = convertIBackupAndRestoreToTable(filteredBackupAndRestore);


    // Functions
    const onSearchBarChange = function (input: string) {
        dispatch(FiltersActions.setSearchInput({ ...searchInput, backupAndRestore: input }));
    };

    // Renders
    const insertBody = () => {
        if (allBackupAndRestore.length > 0) {
            if (filteredBackupAndRestore.length > 0) {
                return (
                    <div className="constellationCardsWrapper">
                        {filteredBackupAndRestore.map((s) => {
                            return null;
                        })}
                    </div>
                );
            } else {
                return <EmptyPlaceholder img="./images/notFound.png" title={"No Backups Found"} description={"Please refine your search/filter criteria and try again."} />;
            }
        } else {
            return (
                <EmptyPlaceholder
                    img="./images/noRoomImage.png"
                    title={"No Backups Available"}
                    description={"There are no available backups, click Create New Backup button."}
                    additionalComponents={<CreateBackupAndRestore type={"SECONDARY"} backups={allBackupAndRestore} />}
                />
            );
        }
    };


    // console.warn(permissions);
    if (!permissions.isConstellationViewer) return null;

    return (
        <div className="tableWrapper">
            <div className="tableHeader">
                <div className="horizontalWrapper">
                    <div className="cluster normalGap">
                        {/* <button className="iconButton">
                            <FontAwesomeIcon icon={faBarsFilter} />
                        </button> */}
                        <UserfulSearchBar forceValue={searchInput.clusters} onChange={onSearchBarChange} />
                    </div>
                    <div className="cluster normalGap">
                        <CreateBackupAndRestore type={"PRIMARY"} backups={allBackupAndRestore} />
                    </div>
                </div>
                {/* {filtersVisibility.constellations && <div className="horizontalWrapper"></div>} */}
            </div>

            <div className="tableBodyWrapper">

                <div className="constellationCardsWrapper">
                    <UserfulTable
                        table={table}
                        replace={filteredBackupAndRestore.length === 0 && insertBody()}
                        onRowClick={(id: StringID) => {
                            // dispatch(AppSliceActions.setAppView({ type: "CLUSTER", id: id }));
                        }}
                        stretch={filteredBackupAndRestore.length === 0}
                    />
                </div>
            </div>
        </div>
    );
}
