import React, { LegacyRef } from "react";
import "./Input.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { faCircleQuestion as faRegularCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import UserfulTooltip from "../Widgets/UserfulTooltip";

interface IProps {
    error?: boolean | undefined;
    success?: boolean | undefined;
    filedTitle?: React.ReactNode | undefined;
    help?: React.ReactNode | undefined;
    postChildren?: React.ReactNode | undefined;
    preChildren?: React.ReactNode | undefined;
    insidePreChildren?: React.ReactNode | undefined;
    insidePostChildren?: React.ReactNode | undefined;
}

const Input = React.forwardRef((props: IProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, ref: LegacyRef<HTMLInputElement>) => {

    const insertTitleOrHelp = props.filedTitle || props.help;
    const insertPreChildren = props.preChildren || insertTitleOrHelp;

    return (
        <div className="input-field-main-wrapper">
            {insertPreChildren && <div className={`input-field-header ${props.error ? "input-field-error" : ""}${props.success ? "input-field-success" : ""}`}>
                {insertTitleOrHelp && <div className={`input-field-title-container`}>
                    {props.filedTitle && <p>{props.filedTitle}</p>}{props.help && <UserfulTooltip title={props.help}><FontAwesomeIcon icon={faRegularCircleQuestion} /></UserfulTooltip>}</div>}
                {props.preChildren}
            </div>}
            <div className="input-field-wrapper">
                {props.insidePreChildren}
                <input className="userful-input-field" id={` ${props.error ? "input-field-error" : ""}${props.success ? "input-field-success" : ""}`} {...props} ref={ref} />
                {props.insidePostChildren}
            </div>
            {props.postChildren && <div className={`input-field-container ${props.error ? "input-field-error" : ""}${props.success ? "input-field-success" : ""}`}>
                {props.error && <FontAwesomeIcon icon={faCircleQuestion} />}
                {props.success && <FontAwesomeIcon icon={faCheckCircle} />}
                {props.postChildren}
            </div>}

        </div>
    );
});
export default Input;