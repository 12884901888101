import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

import {
    REQUEST_ADD_PASSPORT, REQUEST_LIST_PASSPORTS, REQUEST_REMOVE_PASSPORT, REQUEST_UPDATE_PASSPORT, REQUEST_VIEW_LIST,
    REQUEST_WORKBOOK_LIST
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/tableauMsgDefinitions';
import { TableauPassportHeader, TableauPassportID, TableauViewListRequest } from 'userful-chronos-app-common-js/dist/models/integration/tableau';
import './tableauMsgReciever';

export const requestListPassports = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_PASSPORTS);
};

export const requestAddPassport = (payload: TableauPassportHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_PASSPORT, payload);
};

export const requestUpdatePassport = (payload: TableauPassportHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PASSPORT, payload);
};

export const requestRemovePassport = (payload: TableauPassportID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_PASSPORT, payload);
};

export const requestListWorkbooks = (payload: TableauPassportID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_WORKBOOK_LIST, payload);
};

export const requestListViewsInWorkbook = (payload: TableauViewListRequest) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_VIEW_LIST, payload);
};