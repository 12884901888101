/**
 * Types
 */

/**
 * Interfaces
 */
export interface IConstellationFilters { }
export interface IUserFilters { }
export interface IUserGroupFilters { }
export interface IClusterFilters { }
export interface IDeviceFilters { }
export interface IBackupAndRestoreFilters { }
export interface IFiltersVisibility {
    constellations: boolean;
    users: boolean;
    userGroups: boolean;
    clusters: boolean;
    devices: boolean;
    backupAndRestore: boolean;
}
export interface ISearchInput {
    constellations: string;
    users: string;
    userGroups: string;
    clusters: string;
    devices: string;
    backupAndRestore: string;
}

/**
 * Initiator
 */
export const initIConstellationFilters = (): IConstellationFilters => {
    return {};
};
export const initIUserGroupFilters = (): IUserGroupFilters => {
    return {};
};
export const initIUserFilters = (): IUserFilters => {
    return {};
};
export const initIClusterFilters = (): IClusterFilters => {
    return {};
};
export const initIDeviceFilters = (): IClusterFilters => {
    return {};
};
export const initIBackupAndRestoreFilters = (): IBackupAndRestoreFilters => {
    return {};
};
export const initIFiltersVisibility = (): IFiltersVisibility => {
    return {
        constellations: false,
        users: false,
        userGroups: false,
        clusters: false,
        devices: false,
        backupAndRestore: false,
    };
};
export const initISearchInput = (): ISearchInput => {
    return {
        constellations: "",
        users: "",
        userGroups: "",
        clusters: "",
        devices: "",
        backupAndRestore: "",
    };
};
