import React, { useState } from 'react';


const CustomMenu = React.forwardRef(
	({ children, style, className, 'aria-labelledby': labeledBy }:any, ref:any) => {
		const [value, setValue] = useState('');

		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
			>
				<ul className="list-unstyled">
					{React.Children.toArray(children).filter(
						(child:any) =>
							!value || child.props.children.toLowerCase().startsWith(value),
					)}
				</ul>
			</div>
		);
	},
);

export default CustomMenu;





// type CustomMenuProps = {
// 	className?: string;
// 	style?: React.CSSProperties;
// 	children?: React.ReactNode;
// 	labeledBy?: string;

// }

// const CustomMenu = React.forwardRef(
// 	(props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
// 		const [value, setValue] = useState('');

// 		return (
// 			<div
// 				ref={ref}
// 				style={props.style}
// 				className={props.className}
// 				aria-labelledby={props.labeledBy}
// 			>
// 				<ul className="list-unstyled">
// 					{React.Children.toArray(props.children).filter(
// 						(child) =>
// 							React.isValidElement(child) && (!value || child.props.children.toLowerCase().startsWith(value)),
// 					)}
// 				</ul>
// 			</div>
// 		);
// 	},
// );

// export default CustomMenu;