import { RequestMessageDefinition, RespondMessageDefinition } from "userful-chronos-app-common-js/dist/message/messageModel";

const UCONDUCT_VIEW = 9200;
const UCONDUCT_CONTAINER = 9201;
const UCONDUCT_WORKSPACE = 9202;

export const REQUEST_LIST_UCONDUCT_VIEWS = new RequestMessageDefinition(UCONDUCT_VIEW, 0);
export const REQUEST_CREATE_UCONDUCT_VIEW = new RequestMessageDefinition(UCONDUCT_VIEW, 1);
export const REQUEST_REMOVE_UCONDUCT_VIEW = new RequestMessageDefinition(UCONDUCT_VIEW, 2);
export const REQUEST_UPDATE_UCONDUCT_VIEW_HEADER = new RequestMessageDefinition(UCONDUCT_VIEW, 3);
export const REQUEST_REMOVE_UCONDUCT_VIEW_GEOMETRY = new RequestMessageDefinition(UCONDUCT_VIEW, 4);
export const REQUEST_LOAD_UCONDUCT_VIEW = new RequestMessageDefinition(UCONDUCT_VIEW, 5);

export const RESPONSE_LIST_UCONDUCT_VIEWS = new RespondMessageDefinition(UCONDUCT_VIEW, 0);
export const RESPONSE_CREATE_UCONDUCT_VIEW = new RespondMessageDefinition(UCONDUCT_VIEW, 1);
export const RESPONSE_REMOVE_UCONDUCT_VIEW = new RespondMessageDefinition(UCONDUCT_VIEW, 2);
export const RESPONSE_UPDATE_UCONDUCT_VIEW_HEADER = new RespondMessageDefinition(UCONDUCT_VIEW, 3);
export const RESPONSE_UPDATE_UCONDUCT_VIEW_GEOMETRY = new RespondMessageDefinition(UCONDUCT_VIEW, 4);
export const RESPONSE_LOAD_UCONDUCT_VIEW = new RespondMessageDefinition(UCONDUCT_VIEW, 5);

export const REQUEST_LIST_UCONDUCT_CONTAINERS = new RequestMessageDefinition(UCONDUCT_CONTAINER, 0);
export const REQUEST_CREATE_UCONDUCT_CONTAINER = new RequestMessageDefinition(UCONDUCT_CONTAINER, 1);
export const REQUEST_REMOVE_UCONDUCT_CONTAINER = new RequestMessageDefinition(UCONDUCT_CONTAINER, 2);
export const REQUEST_UPDATE_UCONDUCT_CONTAINER_HEADER = new RequestMessageDefinition(UCONDUCT_CONTAINER, 3);
export const REQUEST_UPDATE_UCONDUCT_CONTAINER_GEOMETRY = new RequestMessageDefinition(UCONDUCT_CONTAINER, 4);
export const REQUEST_UPDATE_UCONDUCT_CONTAINER_MOVABLE = new RequestMessageDefinition(UCONDUCT_CONTAINER, 5);
export const REQUEST_UPDATE_UCONDUCT_CONTAINER_VISIBILITY = new RequestMessageDefinition(UCONDUCT_CONTAINER, 6);
export const REQUEST_UPDATE_UCONDUCT_CONTAINER_INSET_ID = new RequestMessageDefinition(UCONDUCT_CONTAINER, 7);

export const RESPONSE_LIST_UCONDUCT_CONTAINERS = new RespondMessageDefinition(UCONDUCT_CONTAINER, 0);
export const NOTIFY_CREATE_UCONDUCT_CONTAINER = new RespondMessageDefinition(UCONDUCT_CONTAINER, 1);
export const NOTIFY_REMOVE_UCONDUCT_CONTAINER = new RespondMessageDefinition(UCONDUCT_CONTAINER, 2);
export const NOTIFY_UPDATE_UCONDUCT_CONTAINER = new RespondMessageDefinition(UCONDUCT_CONTAINER, 3);

export const REQUEST_LIST_UCONDUCT_WORKSPACES = new RequestMessageDefinition(UCONDUCT_WORKSPACE, 0);
export const REQUEST_CREATE_UCONDUCT_WORKSPACE = new RequestMessageDefinition(UCONDUCT_WORKSPACE, 1);
export const REQUEST_REMOVE_UCONDUCT_WORKSPACE = new RequestMessageDefinition(UCONDUCT_WORKSPACE, 2);
export const REQUEST_UPDATE_UCONDUCT_WORKSPACE_HEADER = new RequestMessageDefinition(UCONDUCT_WORKSPACE, 3);
export const REQUEST_UPDATE_UCONDUCT_WORKSPACE_CONTAINERS = new RequestMessageDefinition(UCONDUCT_WORKSPACE, 4);
export const REQUEST_APPLY_UCONDUCT_VIEW_TO_WORKSPACE = new RequestMessageDefinition(UCONDUCT_WORKSPACE, 5);

export const RESPONSE_LIST_UCONDUCT_WORKSPACES = new RespondMessageDefinition(UCONDUCT_WORKSPACE, 0);
export const NOTIFY_CREATE_UCONDUCT_WORKSPACE = new RespondMessageDefinition(UCONDUCT_WORKSPACE, 1);
export const NOTIFY_REMOVE_UCONDUCT_WORKSPACE = new RespondMessageDefinition(UCONDUCT_WORKSPACE, 2);
export const NOTIFY_UPDATE_UCONDUCT_WORKSPACE_HEADER = new RespondMessageDefinition(UCONDUCT_WORKSPACE, 3);
export const NOTIFY_UPDATE_UCONDUCT_WORKSPACE_CONTAINERS = new RespondMessageDefinition(UCONDUCT_WORKSPACE, 4);