"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPOND_LIST_CHANNELS = exports.REQUEST_UPDATE_CHANNEL = exports.REQUEST_REMOVE_CHANNEL_USER = exports.REQUEST_REMOVE_CHANNEL_SOURCE_FROM_MAPPER = exports.REQUEST_REMOVE_CHANNEL_SOURCE = exports.REQUEST_REMOVE_CHANNEL = exports.REQUEST_LIST_CHANNELS = exports.REQUEST_ADD_OR_UPDATE_CHANNEL_USER = exports.REQUEST_ADD_OR_UPDATE_CHANNEL_SOURCE = exports.REQUEST_ADD_CHANNEL = exports.NOTIFY_CHANNEL_UPDATED = exports.NOTIFY_CHANNEL_DELETED = exports.NOTIFY_CHANNEL_ADDED = void 0;
var _messageModel = require("../../messageModel");
var CHANNEL = 9100;
var REQUEST_LIST_CHANNELS = exports.REQUEST_LIST_CHANNELS = new _messageModel.RequestMessageDefinition(CHANNEL, 0);
var REQUEST_ADD_CHANNEL = exports.REQUEST_ADD_CHANNEL = new _messageModel.RequestMessageDefinition(CHANNEL, 1);
var REQUEST_UPDATE_CHANNEL = exports.REQUEST_UPDATE_CHANNEL = new _messageModel.RequestMessageDefinition(CHANNEL, 2);
var REQUEST_REMOVE_CHANNEL = exports.REQUEST_REMOVE_CHANNEL = new _messageModel.RequestMessageDefinition(CHANNEL, 3);
var REQUEST_ADD_OR_UPDATE_CHANNEL_SOURCE = exports.REQUEST_ADD_OR_UPDATE_CHANNEL_SOURCE = new _messageModel.RequestMessageDefinition(CHANNEL, 4);
var REQUEST_REMOVE_CHANNEL_SOURCE = exports.REQUEST_REMOVE_CHANNEL_SOURCE = new _messageModel.RequestMessageDefinition(CHANNEL, 5);
var REQUEST_ADD_OR_UPDATE_CHANNEL_USER = exports.REQUEST_ADD_OR_UPDATE_CHANNEL_USER = new _messageModel.RequestMessageDefinition(CHANNEL, 6);
var REQUEST_REMOVE_CHANNEL_USER = exports.REQUEST_REMOVE_CHANNEL_USER = new _messageModel.RequestMessageDefinition(CHANNEL, 7);
var REQUEST_REMOVE_CHANNEL_SOURCE_FROM_MAPPER = exports.REQUEST_REMOVE_CHANNEL_SOURCE_FROM_MAPPER = new _messageModel.RequestMessageDefinition(CHANNEL, 8);
var RESPOND_LIST_CHANNELS = exports.RESPOND_LIST_CHANNELS = new _messageModel.RespondMessageDefinition(CHANNEL, 0);
var NOTIFY_CHANNEL_ADDED = exports.NOTIFY_CHANNEL_ADDED = new _messageModel.RespondMessageDefinition(CHANNEL, 1);
var NOTIFY_CHANNEL_UPDATED = exports.NOTIFY_CHANNEL_UPDATED = new _messageModel.RespondMessageDefinition(CHANNEL, 2);
var NOTIFY_CHANNEL_DELETED = exports.NOTIFY_CHANNEL_DELETED = new _messageModel.RespondMessageDefinition(CHANNEL, 3);