import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { CdmOrgQuery } from 'userful-chronos-app-common-js/dist/models/cdm/Common';
import { CdmPhysicalAudioDestCreator, CdmPhysicalDestHeaderUpdate, CdmPhysicalDestOrgDataUpdate, CdmPhysicalVideoDestCreator, CdmPhysicalDestSecurityDataUpdate } from 'userful-chronos-app-common-js/dist/models/cdm/CdmPhysical'
import { CdmVideoWallDestCreator, CdmVideoWallDestHeaderUpdate, CdmVideoWallDestOrgDataUpdate, CdmVideoWallDestSecurityDataUpdate } from 'userful-chronos-app-common-js/dist/models/cdm/CdmVideoWall';
import { CdmDestinationGroupContentUpdate, CdmDestinationGroupCreator, CdmDestinationGroupHeaderUpdate, CdmDestinationGroupOrgDataUpdate } from 'userful-chronos-app-common-js/dist/models/cdm/CdmGroup';
import {
    REQUEST_CREATE_DESTINATION_GROUP, REQUEST_CREATE_PHYSICAL_AUDIO_DEST,
    REQUEST_CREATE_PHYSICAL_VIDEO_DEST, REQUEST_CREATE_VIDEOWALL_DEST,
    REQUEST_LIST_DESTINATION_SET, REQUEST_LIST_PHYSICAL_ENDPOINT,
    REQUEST_LIST_VIDEOWALL_ENDPOINT, REQUEST_QUERY_DESTINATION_SET, REQUEST_REMOVE_DESTINATION_GROUP, REQUEST_REMOVE_PHYSICAL_AUDIO_DEST,
    REQUEST_REMOVE_PHYSICAL_VIDEO_DEST, REQUEST_REMOVE_VIDEOWALL_DEST, REQUEST_UPDATE_DESTINATION_GROUP_CONTENT,
    REQUEST_UPDATE_DESTINATION_GROUP_HEADER, REQUEST_UPDATE_DESTINATION_GROUP_ORG_DATA,
    REQUEST_UPDATE_PHYSICAL_AUDIO_DEST_HEADER, REQUEST_UPDATE_PHYSICAL_AUDIO_DEST_ORG_DATA, REQUEST_UPDATE_PHYSICAL_AUDIO_DEST_USERFUL_SECUIRTY_DATA,
    REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_HEADER, REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_ORG_DATA, REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_USERFUL_SECURITY_DATA,
    REQUEST_UPDATE_VIDEOWALL_DEST_HEADER, REQUEST_UPDATE_VIDEOWALL_DEST_ORG_DATA, REQUEST_UPDATE_VIDEOWALL_DEST_USERFUL_SECURITY_DATA
} from "./CDMMsgDefinations";
import { hasReceivedInitialDestinations, hasReceivedInitialDestinationsQuery, hasReceivedInitialPhysicalEndPoints, hasReceviedInitialVideoWallsEndPoints } from "../../InitialDataStates";
import { REQUEST_LIST_VIDEOWALLS } from "../../mapping/msgs/calibrationMsgDefinitions";


// Video Wall Calibration
export const requestListVideoWallCalibration = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_VIDEOWALLS);
}

// dest
export const requestListDestinations = (forceUpdate?: boolean) => {
    if (hasReceivedInitialDestinations() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_DESTINATION_SET);
}

export const requestQueryDestinations = (payload: CdmOrgQuery, forceUpdate?: boolean) => {
    if (hasReceivedInitialDestinationsQuery() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_QUERY_DESTINATION_SET, payload);
}

// dest groups

export const requestCreateDestinationGroup = (payload: CdmDestinationGroupCreator) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CREATE_DESTINATION_GROUP, payload);
}
export const requestRemoveDestinationGroup = (payload: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_DESTINATION_GROUP, payload);
}
export const requestUpdateDestinationGroupHeader = (payload: CdmDestinationGroupHeaderUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_DESTINATION_GROUP_HEADER, payload);
}
export const requestUpdateDestinationGroupOrgData = (payload: CdmDestinationGroupOrgDataUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_DESTINATION_GROUP_ORG_DATA, payload);
}
export const requestUpdateDestinationGroupContent = (payload: CdmDestinationGroupContentUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_DESTINATION_GROUP_CONTENT, payload);
}

// dest audio
export const requestCreatePhysicalAudioDest = (payload: CdmPhysicalAudioDestCreator) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CREATE_PHYSICAL_AUDIO_DEST, payload);
}
export const requestPhysicalAudioDest = (payload: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_PHYSICAL_AUDIO_DEST, payload);
}
export const requestUpdatePhysicalAudioDestHeader = (payload: CdmPhysicalDestHeaderUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PHYSICAL_AUDIO_DEST_HEADER, payload);
}
export const requestUpdatePhysicalAudioDestOrgData = (payload: CdmPhysicalDestOrgDataUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PHYSICAL_AUDIO_DEST_ORG_DATA, payload);
}

export const requestUpdatePhysicalAudioDestSecurityData=(payload: CdmPhysicalDestSecurityDataUpdate) =>{
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PHYSICAL_AUDIO_DEST_USERFUL_SECUIRTY_DATA, payload);
}

// dest video
export const requestCreatePhysicalVideoDest = (payload: CdmPhysicalVideoDestCreator) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CREATE_PHYSICAL_VIDEO_DEST, payload);
}
export const requestRemovePhysicalVideoDest = (payload: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_PHYSICAL_VIDEO_DEST, payload);
}
export const requestUpdatePhysicalVideoDestHeader = (payload: CdmPhysicalDestHeaderUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_HEADER, payload);
}
export const requestUpdatePhysicalVideoDestOrgData = (payload: CdmPhysicalDestOrgDataUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_ORG_DATA, payload);
}
export const requestUpdatePhysicalVideoDestSecurityData = (payload: CdmPhysicalDestSecurityDataUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_USERFUL_SECURITY_DATA, payload);
}

// dest videowall
export const requestCreateVideoWallDest = (payload: CdmVideoWallDestCreator) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CREATE_VIDEOWALL_DEST, payload);
}
export const requestRemoveVideoWallDest = (payload: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_VIDEOWALL_DEST, payload);
}
export const requestUpdateVideoWallDestHeader = (payload: CdmVideoWallDestHeaderUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_VIDEOWALL_DEST_HEADER, payload);
}
export const requestUpdateVideoWallDestOrgData = (payload: CdmVideoWallDestOrgDataUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_VIDEOWALL_DEST_ORG_DATA, payload);
}
export const requestUpdateVideoWallDestSecurityData = (payload: CdmVideoWallDestSecurityDataUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_VIDEOWALL_DEST_USERFUL_SECURITY_DATA, payload);
}


export const requestListPhysicalEndpoint = (forceUpdate?: boolean) => {
    if (hasReceivedInitialPhysicalEndPoints() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_PHYSICAL_ENDPOINT);
}

export const requestListVideoWallEndpoint = (forceUpdate?: boolean) => {
    if (hasReceviedInitialVideoWallsEndPoints() && !forceUpdate) {
        return;
    }
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_VIDEOWALL_ENDPOINT);
}