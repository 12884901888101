"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.redirectUriFor = exports.getRootPath = exports.ROUTE_PATH = void 0;
var rootPath = '/';
var APP_RAW_PATH = {
  RUBY: 'remote',
  RUBYSTREAM: 'remotechannelstreams',
  DIAMOND: "decisions",
  SAPPHIRE: "spaces",
  AMETHYST: "artistic",
  TOPAZ: "trends",
  EMERALD: "engage",
  UCONTROL: "ucontrol",
  USER_MANAGEMENT: "/auth/admin/userful/console",
  SYSTEM_CONFIGURATION: "configuration",
  SOURCES_DESTINATIONS: "source-destination-management",
  SYSTEM_MONITORING_DASHBOARD: "monitoring",
  REACT_USER_MANAGEMENT: "usermanagement",
  SETUP: "setup",
  CALIBRATION: "videowall-calibration",
  INTERACTIVE_VIEWER: "interactiveviewer",
  ADMIN: "admin",
  UCONDUCT: "uconduct"
};
try {
  rootPath = new URL(window.location.href).pathname;
  var rootPath_LC = rootPath.toLowerCase();
  for (var _i = 0, _Object$values = Object.values(APP_RAW_PATH); _i < _Object$values.length; _i++) {
    var appPath = _Object$values[_i];
    var foundIndex = rootPath_LC.indexOf(appPath);
    if (foundIndex >= 0) {
      rootPath = rootPath.slice(0, foundIndex);
      break;
    }
  }
  if (!rootPath.endsWith("/")) {
    rootPath = rootPath + "/";
  }
} catch (_unused) {}
var getRootPath = exports.getRootPath = function getRootPath() {
  return rootPath;
};
var ROUTE_PATH = exports.ROUTE_PATH = {
  APP: {
    RUBY: rootPath + APP_RAW_PATH.RUBY,
    DIAMOND: rootPath + APP_RAW_PATH.DIAMOND,
    SAPPHIRE: rootPath + APP_RAW_PATH.SAPPHIRE,
    AMETHYST: rootPath + APP_RAW_PATH.AMETHYST,
    TOPAZ: rootPath + APP_RAW_PATH.TOPAZ,
    EMERALD: rootPath + APP_RAW_PATH.EMERALD,
    UCONTROL: rootPath + APP_RAW_PATH.UCONTROL,
    UCONDUCT: rootPath + APP_RAW_PATH.UCONDUCT
  },
  ADMIN: {
    USER_MANAGEMENT: APP_RAW_PATH.USER_MANAGEMENT,
    SYSTEM_CONFIGURATION: rootPath + APP_RAW_PATH.SYSTEM_CONFIGURATION,
    SOURCES_DESTINATIONS: rootPath + APP_RAW_PATH.SOURCES_DESTINATIONS,
    SYSTEM_MONITORING_DASHBOARD: rootPath + APP_RAW_PATH.SYSTEM_MONITORING_DASHBOARD,
    REACT_USER_MANAGEMENT: rootPath + APP_RAW_PATH.REACT_USER_MANAGEMENT,
    SETUP: rootPath + APP_RAW_PATH.SETUP,
    CALIBRATION: rootPath + APP_RAW_PATH.CALIBRATION
  }
};
var redirectUriFor = exports.redirectUriFor = function redirectUriFor(appID) {
  // ???
  // if (window.location.pathname === "/") {
  //     return window.location.href;
  // }

  // let pathname = window.location.pathname;
  // if (window.location.pathname.endsWith("/")) {
  //     pathname = pathname.substring(0, pathname.length -1); 
  // }

  // if (isPathToAPP(pathname)) {
  //     const appIDPath = "/" + appID.toLocaleLowerCase();
  //     if (pathname === appIDPath) {
  //         return window.location.href;
  //     }

  //     return window.location.protocol + "//" +  window.location.host + appIDPath;
  // }

  return window.location.href;
};

// export const appUri = () => {
//     return window.location.protocol + "//" +  window.location.host;
// }