import { faArrowLeft, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserfulTooltip } from "userful-chronos-common-ui/dist";
import { UserfulUser } from "../../model/CarbonExternalModels";
import { useCarbonDispatch } from "../../store/carbonStore";
import { AppSliceActions } from "../../store/app/appSlice";
import UserDropdown from "./UserDropdown";
import UserForm from "./UserForm";

interface IProps {
    user: UserfulUser;
}

export default function UserPage(props: IProps) {
    const dispatch = useCarbonDispatch();

    if (!props.user) return <div className="genericWrapper">User not found</div>;

    return (
        <div className="genericWrapper">
            <div className="actionsBar">
                <div className="cluster normalGap">
                    <UserfulTooltip title={"Return to Users"}>
                        <button
                            className="iconButton"
                            onClick={(e) => {
                                dispatch(AppSliceActions.setAppView({ type: "USERS", id: { value: "NOT_DEFINED" } }));
                            }}
                            id="user-go-back-button"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                    </UserfulTooltip>
                    <UserDropdown user={props.user.user} />
                </div>

                <div className="cluster normalGap">
                    <UserfulTooltip title={"Delete"}>
                        <button className="iconButton">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </UserfulTooltip>
                </div>
            </div>
        </div>
    );
}
