import { useTranslation } from 'react-i18next';
import React from 'react';

export function ConfirmationToastContentStore(name: string, type: 'Edit' | 'Save' | 'Restore' | 'Custom', text?:string) {


    if (type === "Edit") {

        return (
            <div className="confirmationToastContent">
                <span className="content1">
                    Changes saved to
                </span>
                <span className="content2">
                    {'\u00A0'}{name}.
                </span>
            </div>
        );
    }

    if (type === "Save") {

        return (
            <div className="confirmationToastContent">
                <span className="content2">
                    {name}{'\u00A0'}
                </span>
                <span className="content1">
                    {` has been successfully created.`}
                </span>
            </div>
        );

    }

    if (type === "Restore") {

        return (
            <div className="confirmationToastContent">
                <span className="content2">
                    {name}{'\u00A0'}
                </span>
                <span className="content1">
                    {` has been successfully restored.`}
                </span>
            </div>
        );

    }

    if (type === "Custom") {
        return (
            <div className="confirmationToastContent">
                <span className="content1">
                    Changes saved to
                </span>
                <span className="content2">
                    {'\u00A0'}{name}:
                </span>
                <span className="content1">
                {'\u00A0'}{text}
                </span>
            </div>
        );
    }

}

