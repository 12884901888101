import React, { useState } from "react";
import { IUFormField } from "../uFormUtils";
import "./UFormFields.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";

interface IProps {
     field: IUFormField;
     forceValue?: string | number | boolean | undefined;
     forceError?: string;
     onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

export default function URadioButton({ field, forceValue, onItemChange }: IProps) {
     const { t } = useTranslation();

     let value = useUFormContext().state.fieldState[field.key];
     if (forceValue) value = forceValue;
     const { onFieldChange } = useUFormContext();

     return (
          <div className="fieldColumnWrapper">
               {field.items.map((item) => {
                    return (
                         <div
                              key={"radioButton:" + item.label}
                              className="fieldRowWrapper interactive"
                              onClick={(e) => {
                                   if (onItemChange) onItemChange({ [field.key]: item.value }, field);
                                   else onFieldChange({ [field.key]: item.value }, field);
                              }}
                         >
                              <div className={`radioButton ${value === item.value ? "checked" : null}`}>
                                   {value === item.value ? <FontAwesomeIcon icon={faCircle} /> : null}
                              </div>
                              <div className="mainText">{t(item.label)}</div>
                         </div>
                    );
               })}
          </div>
     );
}
