import { DestinationIDSet } from "userful-chronos-app-common-js/dist/models/cdm/CdmGroup";
import { convertToTopLeftPositionPct, PositionInfoPct, StringID, STRINGID_NOT_SET } from "userful-chronos-app-common-js/dist/models/common";
import { AnyMapper, EdgeMapperComp, InsetComp, MapperCompSurface, MixerMapperComp } from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";

export const updateMappingGroupAtIndex = (state, update: AnyMapper, index: number) => {
     state.mappingGroups = [...state.mappingGroups.slice(0, index), update, ...state.mappingGroups.slice(index + 1)];
};

export const removeFromDestinationIDs = (original: DestinationIDSet, toRemove: DestinationIDSet): DestinationIDSet => {
     return {
          groups: original.groups.filter(item => toRemove.groups.findIndex(removed => item.value === removed.value) < 0),
          videoWalls: original.videoWalls.filter(item => toRemove.videoWalls.findIndex(removed => item.value === removed.value) < 0),
          physicalAudio: original.physicalAudio.filter(item => toRemove.physicalAudio.findIndex(removed => item.value === removed.value) < 0),
          physicalVideo: original.physicalVideo.filter(item => toRemove.physicalVideo.findIndex(removed => item.value === removed.value) < 0),
     }
}

export const flattenDestinationIDSet = (data: DestinationIDSet): StringID[] => ([
     ...data.groups,
     ...data.physicalAudio,
     ...data.physicalVideo,
     ...data.videoWalls
])

export const addToDestinationIDs = (original: DestinationIDSet, toAdd: DestinationIDSet): DestinationIDSet => {
     const removed = removeFromDestinationIDs(original, toAdd);
     return {
          groups: [...removed.groups, ...toAdd.groups],
          videoWalls: [...removed.videoWalls, ...toAdd.videoWalls],
          physicalAudio: [...removed.physicalAudio, ...toAdd.physicalAudio],
          physicalVideo: [...removed.physicalVideo, ...toAdd.physicalVideo],
     }
}

export const deleteDestinations = (original: MapperCompSurface[], toDelete: MapperCompSurface[]): MapperCompSurface[] => {
     if (toDelete.length < 1) {
          return original;
     }
     return original.filter((originalItem) => toDelete.findIndex((item) => item.destinationID === originalItem.destinationID) < 0);
};

export const combineDestinations = (original: MapperCompSurface[], toAdd: MapperCompSurface[]) => {
     if (toAdd.length < 1) {
          return original;
     }
     return [...deleteDestinations(original, toAdd), ...toAdd];
};

export const stringIDSetAreTheSame = (left: StringID[], right: StringID[]) => {
     if (left.length !== right.length) {
          return false;
     }
     for (let i = 0; i < left.length; i++) {
          if (right.findIndex((item) => item.value === left[i].value) < 0) {
               return false;
          }
     }
     return true;
};

// split SestinationIDSet item into individual ids
export const splitDestinationIDSet = (destinationIDs: DestinationIDSet): { destinationIDs: DestinationIDSet, id: StringID }[] => {
     return destinationIDs.groups.map(item => ({ destinationIDs: { groups: [item], videoWalls: [], physicalAudio: [], physicalVideo: [] }, id: item }))
          .concat(destinationIDs.videoWalls.map(item => ({ destinationIDs: { groups: [], videoWalls: [item], physicalAudio: [], physicalVideo: [] }, id: item })))
          .concat(destinationIDs.physicalAudio.map(item => ({ destinationIDs: { groups: [], videoWalls: [], physicalAudio: [item], physicalVideo: [] }, id: item })))
          .concat(destinationIDs.physicalVideo.map(item => ({ destinationIDs: { groups: [], videoWalls: [], physicalAudio: [], physicalVideo: [item] }, id: item })));
}

export const destinationIDSetHasID = (destinationIDs: DestinationIDSet, target: string): boolean => {
     return destinationIDs.groups.findIndex(item => item.value === target) >= 0 ||
          destinationIDs.videoWalls.findIndex(item => item.value === target) >= 0 ||
          destinationIDs.physicalAudio.findIndex(item => item.value === target) >= 0 ||
          destinationIDs.physicalVideo.findIndex(item => item.value === target) >= 0;
}

export const destinationIDSetsAreTheSame = (left: DestinationIDSet, right: DestinationIDSet) => {
     return (
          stringIDSetAreTheSame(left.groups, right.groups) &&
          stringIDSetAreTheSame(left.physicalAudio, right.physicalAudio) &&
          stringIDSetAreTheSame(left.physicalVideo, right.physicalVideo) &&
          stringIDSetAreTheSame(left.videoWalls, right.videoWalls)
     );
};

export const destinationIDSetToMapperCompSurface = (destinations: DestinationIDSet) => {
     return [
          ...destinations.groups.map(
               (id) => ({ id: STRINGID_NOT_SET, destinationID: id.value, surfaceType: "SINGLE" } as MapperCompSurface)
          ),
          ...destinations.videoWalls.map(
               (id) => ({ id: STRINGID_NOT_SET, destinationID: id.value, surfaceType: "SINGLE" } as MapperCompSurface)
          ),
          ...destinations.physicalVideo.map(
               (id) => ({ id: STRINGID_NOT_SET, destinationID: id.value, surfaceType: "SINGLE" } as MapperCompSurface)
          ),
          ...destinations.physicalAudio.map(
               (id) => ({ id: STRINGID_NOT_SET, destinationID: id.value, surfaceType: "SINGLE" } as MapperCompSurface)
          ),
     ];
};

export const addDestinationsToMappingGroupAtIndex = (state, destinations: DestinationIDSet, index: number) => {
     const mapper = state.mappingGroups[index] as AnyMapper;
     const update = {
          ...mapper,
          destinations: combineDestinations(mapper.destinations, destinationIDSetToMapperCompSurface(destinations)),
     } as AnyMapper;
     updateMappingGroupAtIndex(state, update, index);
};

export const removeDestinationsFromMappingGroupAtIndex = (state, destinations: DestinationIDSet, index: number) => {
     const mapper = state.mappingGroups[index] as AnyMapper;
     const update = {
          ...mapper,
          destinations: deleteDestinations(mapper.destinations, destinationIDSetToMapperCompSurface(destinations)),
     } as AnyMapper;
     updateMappingGroupAtIndex(state, update, index);
};

export const combineDestinationIDSet = (left: DestinationIDSet | null, right: DestinationIDSet): DestinationIDSet => {
     if (!left) {
          return { ...right };
     }
     if (!right) {
          return { ...left };
     }
     return {
          groups: [...left.groups, ...right.groups],
          physicalAudio: [...left.physicalAudio, ...right.physicalAudio],
          physicalVideo: [...left.physicalVideo, ...right.physicalVideo],
          videoWalls: [...left.videoWalls, ...right.videoWalls],
     };
};

export const EMPTY_DESTINATIONS = {
     groups: [],
     videoWalls: [],
     physicalVideo: [],
     physicalAudio: [],
} as DestinationIDSet;

export const insetHasPosition = (inset: InsetComp, positionInfo: PositionInfoPct): boolean => {
     if (!inset || !positionInfo || inset.videoData.length < 1) {
          return false;
     }
     const insetPosition = inset.videoData[0].position;
     return (
          insetPosition.center.x === positionInfo.center.x &&
          insetPosition.center.y === positionInfo.center.y &&
          insetPosition.size.width === positionInfo.size.width &&
          insetPosition.size.height === positionInfo.size.height &&
          insetPosition.rotation.radians === positionInfo.rotation.radians
     );
};

export const insetCoveredByPosition = (inset: InsetComp, positionInfo: PositionInfoPct): boolean => {
     if (!inset || !positionInfo || inset.videoData.length < 1) {
          return false;
     }
     const insetPosition = inset.videoData[0].position;
     const insetTopLeftPosition = convertToTopLeftPositionPct(insetPosition);
     const incomingTopLeftPostion = convertToTopLeftPositionPct(positionInfo);
     return (
          insetTopLeftPosition.x >= incomingTopLeftPostion.x &&
          insetTopLeftPosition.y >= incomingTopLeftPostion.y &&
          insetTopLeftPosition.x + insetPosition.size.width <= incomingTopLeftPostion.x + positionInfo.size.width &&
          insetTopLeftPosition.y + insetPosition.size.height <= incomingTopLeftPostion.y + positionInfo.size.height
     );
};


export const NOT_SET_INSET: InsetComp = {
     id: STRINGID_NOT_SET,
     name: "",
     description: "",
     playList: {
          id: STRINGID_NOT_SET,
          name: "",
          description: "",
          sceneID: STRINGID_NOT_SET,
          createdFrom: STRINGID_NOT_SET,
          behaviour: {
               playTimes: {
                    "times": -1
               },
               startBehaviour: "AUTO_START"
          },
          items: []
     },
     audioData: [],
     videoData: [],
     sceneID: STRINGID_NOT_SET
}