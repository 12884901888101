import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./ConfirmationDialog.scss";
import ClickButton from "../Buttons/ClickButton";
import { IUButton } from "../Buttons/buttonsUtils";
import { closeButton, exclamationButton, warningButton } from "../Buttons/Presets/buttonPresets";
import { IConfirmationDialogTrigger, ConfirmationDialogType } from "./confirmationDialogUtils";
import SmartText from "../SmartText/SmartText";
import { ISmartText } from "../SmartText/smartTextUtils";
import DropdownButton from "../Buttons/DropdownButton";
import Button from "../../Button/Button";

export interface IConfirmationDialog {
    type: ConfirmationDialogType;
    title: string;
    trigger: IConfirmationDialogTrigger;
    description?: string;
    smartDescription?: ISmartText;
    buttons?: IUButton[];
}

export default function ConfirmationDialog(props: IConfirmationDialog) {
    const [show, setShow] = useState<boolean>(false);

    const onShow = (e) => {
        setShow(true);
    };

    const onClose = (e) => {
        setShow(false);
        if (props.trigger.onClose) props.trigger.onClose();
    };

    return (
        <>
            {props.trigger.trigger ? <ClickButton button={props.trigger.trigger} onClick={onShow} /> : null}
            {props.trigger.dropdownTrigger ? (
                <DropdownButton button={props.trigger.dropdownTrigger} onClick={onShow} />
            ) : null}

            <Modal
                centered
                show={props.trigger.trigger || props.trigger.dropdownTrigger ? show : props.trigger.value}
                onHide={() => onClose({})}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                {props.type === "ERROR" ? (
                                    <ClickButton button={exclamationButton} />
                                ) : (
                                    <ClickButton button={warningButton} />
                                )}
                                <div className="title">{props.title}</div>
                            </div>
                            <ClickButton button={closeButton} onClick={onClose} />
                        </div>
                        <div className="description">
                            {props.smartDescription ? (
                                <SmartText smartTexts={props.smartDescription} />
                            ) : (
                                props.description
                            )}
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        {props.buttons &&
                            props.buttons.map((button, index) => {
                                return <ClickButton key={index + button.title} button={button} onClick={onClose} />;
                            })}
                    </div>
                </div>
            </Modal>
        </>
    );
}
