import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "./Input";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import Button from "../Button/Button";

interface IProps {
    hideNumbers?: boolean;
    onChange?(value): void;
    minValue?: number;
    maxValue?: number;
    error?: boolean | undefined;
    success?: boolean | undefined;
    filedTitle?: React.ReactNode | undefined;
    help?: React.ReactNode | undefined;
    postChildren?: React.ReactNode | undefined;
    preChildren?: React.ReactNode | undefined;
    forceValue?: string;
}

const InputNumbers = ({ hideNumbers, onChange, minValue, maxValue, ...props }: IProps) => {

    const [value, setValue] = useState(props.forceValue);

    const onChangeValue = (e) => {
        let value = parseFloat(e.target.value);

        if (isNaN(value)) {
            setValue("");
            return;
        }

        setValue(value + "");
    };

    const onMinusClicked = (e) => {
        let returnValue = parseFloat(value) - 1;

        if (isNaN(returnValue)) {
            setValue("0");
            return;
        }

        if (minValue !== undefined && returnValue <= minValue) {
            returnValue = minValue;
        }

        setValue(returnValue + "");
    }
    const onPlusClicked = (e) => {
        let returnValue = parseFloat(value) + 1;

        if (isNaN(returnValue)) {
            setValue("0");
            return;
        }

        if (maxValue !== undefined && returnValue >= maxValue) {
            returnValue = maxValue;
        }

        setValue(returnValue + "");
    }

    useEffect(() => {
        if (onChange) onChange(value);
    }, [value])

    useEffect(() => {
        setValue(props.forceValue);
    }, [props.forceValue])

    return (
        <Input value={value} type="text" pattern="[0-9]*" placeholder={"Enter number here..."} onChange={onChangeValue} {...props}
            insidePreChildren={hideNumbers ? null : <Button variant="secondary" iconOnly onClick={onMinusClicked}><FontAwesomeIcon icon={faMinus} /></Button>}
            insidePostChildren={hideNumbers ? null : <Button variant="secondary" iconOnly onClick={onPlusClicked}><FontAwesomeIcon icon={faPlus} /></Button>} />
    );

};
export default InputNumbers;