import React from "react";
import { useTranslation } from "react-i18next";



const AppAccessDenied = (props) => {
     const { t } = useTranslation();

     return (<div style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
          color: "red",
          fontSize: "16px",
     }}>
          {t("Shared.NoAppPermission")}
     </div>
     );
};

export default AppAccessDenied;
