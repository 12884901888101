import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { propTypes } from 'react-bootstrap/esm/Image';

interface IProps {
    placeholder?: string;
    width?: string;
    getSearchValue?: Function,
    searchValue?: string,
    setSearchValue?: Function,
}

// Define a default UI for filtering
export default function CustomSearch(props: IProps) {
    const { t } = useTranslation();
    const [value, setValue] = useState(props.searchValue);

    const onChange = (value) => {
        props.getSearchValue(value);
    }

    const handleClearSearch = () => {
        props.setSearchValue("");
        setValue("");
    }

    return (
        <div className='table-search app-search'>
            <img alt="search icon" src="./assets/search.svg" className='search-icon' />
            <input
                className="search-input"
                type="input"
                placeholder={t('CommonUI.SearchPlaceholder')}
                style={{ width: '402px' }}
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
           {value && value.length > 0 && <div className='clearIconWrapper' onClick={handleClearSearch} >
                <FontAwesomeIcon icon={faX} className="clear" />
            </div>}
        </div>
    )
}