import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CdmPhysicalAudioDest, CdmPhysicalAudioDestCreator, HasPhysicalEndPointID } from 'userful-chronos-app-common-js/dist/models/cdm/CdmPhysical';
import { HasCdmOrgData } from 'userful-chronos-app-common-js/dist/models/cdm/Common';
import { HasID, HasNameAndDescription, HasUserfulSecurityData, StringID } from 'userful-chronos-app-common-js/dist/models/common';
import { addOrUpdateReducer, removeReducer, sanitizeCdmOrgData, sanitizeCdmOrgDatas } from './CdmStoreUtils';
import { requestCreatePhysicalAudioDest, requestRemovePhysicalVideoDest, requestUpdatePhysicalAudioDestHeader, requestUpdatePhysicalAudioDestOrgData, requestUpdatePhysicalAudioDestSecurityData } from './msgs/MsgSender';

const initialState: {
    audioDestinations: CdmPhysicalAudioDest[],
    ready: boolean;
} = {
    audioDestinations: [],
    ready: false,
};

export const audioDestinationsSlice = createSlice({
    name: 'audioDestinationsSlice',
    initialState,
    reducers: {
        setAudioDestinations: (state, action: PayloadAction<CdmPhysicalAudioDest[]>) => {
            state.ready = true;
            state.audioDestinations = sanitizeCdmOrgDatas(action.payload);
        },
        addOrUpdateAudioDestination: (state, action: PayloadAction<CdmPhysicalAudioDest>) => {
            addOrUpdateReducer(state, sanitizeCdmOrgData(action.payload), 'audioDestinations');
        },
        removeAudioDestination: (state, action: PayloadAction<CdmPhysicalAudioDest>) => {
            removeReducer(state, action.payload, 'audioDestinations');
        },
        createAudioDestinationToServer: (state, action: PayloadAction<CdmPhysicalAudioDestCreator>) => {
            requestCreatePhysicalAudioDest({ ...action.payload });
        },
        updateAudioDestinationToServer: (state, action: PayloadAction<HasID & HasNameAndDescription & HasCdmOrgData & HasUserfulSecurityData>) => {
            const { id, name, description, orgData, userfulSecurityData } = action.payload;
            requestUpdatePhysicalAudioDestHeader({ id, name, description });
            requestUpdatePhysicalAudioDestOrgData({ id, orgData, });
            requestUpdatePhysicalAudioDestSecurityData({id, userfulSecurityData});
        },
        removeAudioDestinationToServer: (state, action: PayloadAction<StringID>) => {
            requestRemovePhysicalVideoDest({ ...action.payload });
        },
    },
})

export const audioDestinationsSliceActions = audioDestinationsSlice.actions;

export default audioDestinationsSlice.reducer