import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./DisconnectOverlay.scss";
import { useTranslation } from 'react-i18next';
import { useAuthenticationContext } from "userful-chronos-common-store/dist/auth";

interface IProps {
     disabled?: boolean;
}

export default function ({ disabled }: IProps) {
     const { t } = useTranslation();

     const {
          state: { connected, eventbus },
     } = useAuthenticationContext();
     const [show, setShow] = useState(false);

     useEffect(() => {
          if (disabled) {
               return;
          } else if (!eventbus) {
               setShow(false);
          } else if (eventbus && !connected) {
               setShow(true);
          } else if (connected) {
               setShow(false);
          }
     }, [connected, eventbus]);

     if (disabled) {
          return null;
     }

     return (
          <>
               <Modal size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={show} style={{ zIndex: Number.MAX_SAFE_INTEGER }}>
                    <Modal.Body>
                         <div className="disconnectOverlay">
                              <div className="lds-ring">
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                              </div>
                              <span>{t('CommonUI.disconnected')}</span>
                         </div>
                    </Modal.Body>
               </Modal>
          </>
     );
}
