"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_START_FILE_CHOPPING_OPERATION = exports.RESPONSE_QUERY_IF_CHOPPING_REQUIRED = exports.RESPONSE_LIST_VIDEOWALLS = exports.RESPONSE_LIST_FILE_CHOPPING_OPERATIONS = exports.RESPONSE_LIST_FILE_ASSETS = exports.RESPONSE_LIST_CHOPPED_ASSETS = exports.REQUEST_START_FILE_CHOPPING_OPERATION = exports.REQUEST_LIST_VIDEOWALLS = exports.REQUEST_LIST_FILE_CHOPPING_OPERATIONS = exports.REQUEST_LIST_FILE_ASSETS = exports.REQUEST_LIST_CHOPPED_ASSETS = exports.REQUEST_IF_CHOPPING_REQUIRED = exports.REQUEST_CANCEL_FILE_CHOPPING_OPERATION = exports.NOTIFY_FILE_CHOPPING_OPERATION_STARTED = exports.NOTIFY_FILE_CHOPPING_OPERATION_COMPLETE = void 0;
var _messageModel = require("../../../messageModel");
var OPS = 4200;
var ASSETS = 4201;
var VIDEOWALL = 4202;
var QUERY = 4203;
var REQUEST_START_FILE_CHOPPING_OPERATION = exports.REQUEST_START_FILE_CHOPPING_OPERATION = new _messageModel.RequestMessageDefinition(OPS, 0);
var REQUEST_CANCEL_FILE_CHOPPING_OPERATION = exports.REQUEST_CANCEL_FILE_CHOPPING_OPERATION = new _messageModel.RequestMessageDefinition(OPS, 1);
var REQUEST_LIST_FILE_CHOPPING_OPERATIONS = exports.REQUEST_LIST_FILE_CHOPPING_OPERATIONS = new _messageModel.RequestMessageDefinition(OPS, 2);
var NOTIFY_FILE_CHOPPING_OPERATION_COMPLETE = exports.NOTIFY_FILE_CHOPPING_OPERATION_COMPLETE = new _messageModel.RespondMessageDefinition(OPS, 0);
var NOTIFY_FILE_CHOPPING_OPERATION_STARTED = exports.NOTIFY_FILE_CHOPPING_OPERATION_STARTED = new _messageModel.RespondMessageDefinition(OPS, 1);
var RESPONSE_LIST_FILE_CHOPPING_OPERATIONS = exports.RESPONSE_LIST_FILE_CHOPPING_OPERATIONS = new _messageModel.RespondMessageDefinition(OPS, 2);
var RESPONSE_START_FILE_CHOPPING_OPERATION = exports.RESPONSE_START_FILE_CHOPPING_OPERATION = new _messageModel.RespondMessageDefinition(OPS, 3);
var REQUEST_LIST_FILE_ASSETS = exports.REQUEST_LIST_FILE_ASSETS = new _messageModel.RequestMessageDefinition(ASSETS, 0);
var REQUEST_LIST_CHOPPED_ASSETS = exports.REQUEST_LIST_CHOPPED_ASSETS = new _messageModel.RequestMessageDefinition(ASSETS, 1);
var RESPONSE_LIST_FILE_ASSETS = exports.RESPONSE_LIST_FILE_ASSETS = new _messageModel.RespondMessageDefinition(ASSETS, 0);
var RESPONSE_LIST_CHOPPED_ASSETS = exports.RESPONSE_LIST_CHOPPED_ASSETS = new _messageModel.RespondMessageDefinition(ASSETS, 1);
var REQUEST_LIST_VIDEOWALLS = exports.REQUEST_LIST_VIDEOWALLS = new _messageModel.RequestMessageDefinition(VIDEOWALL, 0);
var RESPONSE_LIST_VIDEOWALLS = exports.RESPONSE_LIST_VIDEOWALLS = new _messageModel.RespondMessageDefinition(VIDEOWALL, 0);
var REQUEST_IF_CHOPPING_REQUIRED = exports.REQUEST_IF_CHOPPING_REQUIRED = new _messageModel.RequestMessageDefinition(QUERY, 0);
var RESPONSE_QUERY_IF_CHOPPING_REQUIRED = exports.RESPONSE_QUERY_IF_CHOPPING_REQUIRED = new _messageModel.RespondMessageDefinition(QUERY, 0);