import { faCircleExclamation, faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UIReply } from 'userful-chronos-app-common-js/dist/models/common';
import { isStandalone } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import StandaloneFeatureNotAvailable from '../../Widgets/StandaloneFeatureNotAvailable';
import Button from '../../Button/Button';

interface IProps {
    error: UIReply<any>;
    handleClose: () => void;
}

export default function ({ error, handleClose }: IProps) {
    const { t } = useTranslation();

    const requiredSpaceMb = error?.params?.find(p => p.name === 'required-space-mb')?.value;
    const names = error?.params?.filter(p => p.name === 'name').map(p => p.value) || [];
    const number = names.length;

    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                show={!!error}
            >
                <Modal.Header>
                    <div>
                        {!isStandalone() && <>
                            <FontAwesomeIcon icon={faCircleExclamation} style={{ color: 'var(--userful-alert)', marginRight: '8px' }} />
                            {t('Error.uclientStorage.title')}
                        </>}
                    </div>
                    <div className='custom-close-btn' onClick={handleClose}>
                        <FontAwesomeIcon icon={faClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <StandaloneFeatureNotAvailable>
                        <div className='uclientStorageErrorBody'>
                            <div className='errorDesc'>
                                {t('Error.uclientStorage.firmwareDesc')}
                            </div>
                            <div className='errorStep'>
                                <div>1.</div>
                                {t('Error.uclientStorage.firmwareSolution1')}
                            </div>
                            <div className='errorStep'>
                                <div>2.</div>
                                {t('Error.uclientStorage.firmwareSolution2', { requiredSpaceMb, })}
                            </div>
                            <div className='errorDesc'>
                                {t('Error.uclientStorage.invalidUClient', { number, })}
                                <div className='uclientsContainer'>
                                    {names.map((n, i)=> <div className='uclientBanner' key={`bad-uclient-${i}-${n}`}>
                                        {n}
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </StandaloneFeatureNotAvailable>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('Shared.dismiss')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}