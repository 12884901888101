import { IClusterInfo, IConstellationInfo, ClusterDeviceInfo, KeycloakGroup, KeycloakUser } from "../../model/CarbonExternalModels";
import { IBackupAndRestoreFilters, IClusterFilters, IConstellationFilters, IDeviceFilters } from "./filtersUtils";

export const filterUserGroups = (
    filtering: IConstellationFilters,
    searchInput: string,
    elements: KeycloakGroup[]
): KeycloakGroup[] => {
    const returnElements: KeycloakGroup[] = [...elements];
    let removeElements: KeycloakGroup[] = [];

    // Filtering:
    for (let i = 0; i < returnElements.length; i++) {}

    for (let i = 0; i < removeElements.length; i++) {}

    removeElements = [];

    // Search:
    if (searchInput.length > 0) {
        for (let i = 0; i < returnElements.length; i++) {
            const name = returnElements[i].name;

            if (!name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())) {
                removeElements.push(returnElements[i]);
            }
        }
    }

    for (let i = 0; i < removeElements.length; i++) {
        const index = returnElements.findIndex((s) => s.id.value === removeElements[i].id.value);

        if (index >= 0) {
            returnElements.splice(index, 1);
        }
    }

    return returnElements;
};

export const filterUsers = (
    filtering: IConstellationFilters,
    searchInput: string,
    elements: KeycloakUser[]
): KeycloakUser[] => {
    const returnElements: KeycloakUser[] = [...elements];
    let removeElements: KeycloakUser[] = [];

    // Filtering:
    for (let i = 0; i < returnElements.length; i++) {}

    for (let i = 0; i < removeElements.length; i++) {}

    removeElements = [];

    // Search:
    if (searchInput.length > 0) {
        for (let i = 0; i < returnElements.length; i++) {
            const name = returnElements[i].userProfile.userName;

            if (!name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())) {
                removeElements.push(returnElements[i]);
            }
        }
    }

    for (let i = 0; i < removeElements.length; i++) {
        const index = returnElements.findIndex((s) => s.id.value === removeElements[i].id.value);

        if (index >= 0) {
            returnElements.splice(index, 1);
        }
    }

    return returnElements;
};

export const filterConstellations = (
    filtering: IConstellationFilters,
    searchInput: string,
    elements: IConstellationInfo[]
): IConstellationInfo[] => {
    const returnConstellations: IConstellationInfo[] = [...elements];
    let removeConstellations: IConstellationInfo[] = [];

    // Filtering:
    for (let i = 0; i < returnConstellations.length; i++) {}

    for (let i = 0; i < removeConstellations.length; i++) {}

    removeConstellations = [];

    // Search:
    if (searchInput.length > 0) {
        for (let i = 0; i < returnConstellations.length; i++) {
            const name = returnConstellations[i].definition.name;

            if (!name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())) {
                removeConstellations.push(returnConstellations[i]);
            }
        }
    }

    for (let i = 0; i < removeConstellations.length; i++) {
        const index = returnConstellations.findIndex((s) => s.definition.id.value === removeConstellations[i].definition.id.value);

        if (index >= 0) {
            returnConstellations.splice(index, 1);
        }
    }

    return returnConstellations;
};

export const filterBackupAndRestore = (
    filtering: IBackupAndRestoreFilters,
    searchInput: string,
    elements: any[]
): any[] => {
    const returnConstellations: any[] = [...elements];
    let removeConstellations: any[] = [];

    // Filtering:
    for (let i = 0; i < returnConstellations.length; i++) {}

    for (let i = 0; i < removeConstellations.length; i++) {}

    removeConstellations = [];

    // Search:
    if (searchInput.length > 0) {
        for (let i = 0; i < returnConstellations.length; i++) {
            const name = returnConstellations[i].definition.name;

            if (!name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())) {
                removeConstellations.push(returnConstellations[i]);
            }
        }
    }

    for (let i = 0; i < removeConstellations.length; i++) {
        const index = returnConstellations.findIndex((s) => s.definition.id.value === removeConstellations[i].definition.id.value);

        if (index >= 0) {
            returnConstellations.splice(index, 1);
        }
    }

    return returnConstellations;
};

export const filterClusters = (
    filtering: IClusterFilters,
    searchInput: string,
    elements: IClusterInfo[]
): IClusterInfo[] => {
    const returnConstellations: IClusterInfo[] = [...elements];
    let removeConstellations: IClusterInfo[] = [];

    // Filtering:
    for (let i = 0; i < returnConstellations.length; i++) {}

    for (let i = 0; i < removeConstellations.length; i++) {}

    removeConstellations = [];

    // // Search:
    // if (searchInput.length > 0) {
    //     for (let i = 0; i < returnConstellations.length; i++) {
    //         const name = returnConstellations[i].name;

    //         if (!name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())) {
    //             removeConstellations.push(returnConstellations[i]);
    //         }
    //     }
    // }

    for (let i = 0; i < removeConstellations.length; i++) {
        const index = returnConstellations.findIndex((s) => s.id.value === removeConstellations[i].id.value);

        if (index >= 0) {
            returnConstellations.splice(index, 1);
        }
    }

    return returnConstellations;
};

export const filterDevices = (filtering: IDeviceFilters, searchInput: string, elements: ClusterDeviceInfo[]): ClusterDeviceInfo[] => {
    const returnConstellations: ClusterDeviceInfo[] = [...elements];
    let removeConstellations: ClusterDeviceInfo[] = [];

    // Filtering:
    for (let i = 0; i < returnConstellations.length; i++) {}

    for (let i = 0; i < removeConstellations.length; i++) {}

    removeConstellations = [];

    // Search:
    // if (searchInput.length > 0) {
    //     for (let i = 0; i < returnConstellations.length; i++) {
    //         const name = returnConstellations[i].name;

    //         if (!name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())) {
    //             removeConstellations.push(returnConstellations[i]);
    //         }
    //     }
    // }

    for (let i = 0; i < removeConstellations.length; i++) {
        const index = returnConstellations.findIndex((s) => s.id.value === removeConstellations[i].id.value);

        if (index >= 0) {
            returnConstellations.splice(index, 1);
        }
    }

    return returnConstellations;
};

// export const filterServers = (filtering: IServerFilters, searchInput: string, elements: CarbonDevice[]): CarbonDevice[] => {
//     const returnServers: CarbonDevice[] = [...elements];
//     let removeServers: CarbonDevice[] = [];

//     // Filtering:
//     for (let i = 0; i < returnServers.length; i++) {}

//     for (let i = 0; i < removeServers.length; i++) {}

//     removeServers = [];

//     // Search:
//     if (searchInput.length > 0) {
//         for (let i = 0; i < returnServers.length; i++) {
//             const name = returnServers[i].name;

//             if (!name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())) {
//                 removeServers.push(returnServers[i]);
//             }
//         }
//     }

//     for (let i = 0; i < removeServers.length; i++) {
//         const index = returnServers.findIndex((s) => s.id === removeServers[i].id);

//         if (index >= 0) {
//             returnServers.splice(index, 1);
//         }
//     }

//     return returnServers;
// };
