import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from '@fortawesome/pro-regular-svg-icons';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IProps {
    totalPages: number;
    currentPageIndex: number;
    onPageSelect: Function;
    maxPageOptions?: number;
}

export default function PageSelector(props: IProps) {
    const { t } = useTranslation();

    const getPageItem = (pageIndex) => {
        return <Pagination.Item
            active={pageIndex === props.currentPageIndex}
            onClick={() => props.onPageSelect(pageIndex)}
            key={`pagination-item-${pageIndex}`}
        >{pageIndex + 1}</Pagination.Item>
    }

    // black magic
    const maxPageOptions = props.maxPageOptions && Math.max(props.maxPageOptions, 2) || 5;
    const lastIndex = props.totalPages - 1;
    const centerClusterSize = maxPageOptions - 2;
    const leftSideSize = Math.ceil(centerClusterSize / 2);
    let clusterStartIndex = Math.max(0, props.currentPageIndex - leftSideSize + 1);
    let clusterEndIndex = Math.min(lastIndex, clusterStartIndex + centerClusterSize - 1);
    if (clusterStartIndex === 0) {
        clusterEndIndex = Math.min(lastIndex, clusterEndIndex + 1)
    }
    if (clusterEndIndex === lastIndex) {
        clusterStartIndex = Math.max(0, clusterEndIndex - centerClusterSize);
    }

    const options = [];
    for (let i = clusterStartIndex; i <= clusterEndIndex; i++) {
        options.push(getPageItem(i));
    }

    if (clusterStartIndex > 1) {
        options.unshift(<Pagination.Ellipsis disabled key='pagination-left-elipsis' />)
    }
    if (clusterStartIndex > 0) {
        options.unshift(getPageItem(0))
    }
    if (clusterEndIndex < lastIndex - 1) {
        options.push(<Pagination.Ellipsis disabled key='pagination-right-elipsis' />)
    }
    if (clusterEndIndex < lastIndex) {
        options.push(getPageItem(lastIndex))
    }

    return (
        <Pagination>
            {/* <Pagination.First disabled={props.totalPages < 1 || props.currentPageIndex === 0}
                onClick={() => props.onPageSelect(0)}>
                <FontAwesomeIcon icon={faAnglesLeft} />
            </Pagination.First> */}
            <Pagination.Prev disabled={props.totalPages < 1 || props.currentPageIndex === 0}
                onClick={() => props.onPageSelect(props.currentPageIndex - 1)}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </Pagination.Prev>
            {options}
            <Pagination.Next disabled={props.totalPages < 1 || props.currentPageIndex === lastIndex}
                onClick={() => props.onPageSelect(props.currentPageIndex + 1)}>
                <FontAwesomeIcon icon={faAngleRight} />
            </Pagination.Next>
            {/* <Pagination.Last disabled={props.totalPages < 1 || props.currentPageIndex === lastIndex}
                onClick={() => props.onPageSelect(lastIndex)}>
                <FontAwesomeIcon icon={faAnglesRight} />
            </Pagination.Last> */}
        </Pagination>
    );
}