"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStandaloneVideoWallDestinations = exports.getStandaloneDisplayDestinations = exports.getStandaloneDestinationsSet = exports.createDestFromZeroClient = exports.createDestFromUClient = exports.createDestFromOnboards = void 0;
var _common = require("../../models/common");
var _display3 = require("../../models/display");
var _uclient = require("../../models/uclient");
var _zeroClient = require("../../models/zeroClient");
var _store = require("../data/store");
var _displays = require("./displays");
var _videoWalls = require("./videoWalls");
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var emptyCDMOrgData = {
  orgData: {
    tagsSet: {
      valid: false,
      tags: []
    },
    folderPath: {
      valid: false,
      elements: []
    }
  },
  serverAppIDs: {
    valid: false,
    appIDs: []
  }
};
var getUsageForDisplay = function getUsageForDisplay(seatID, systemID) {
  // to-do: get proper usage
  return {
    id: {
      videoEngineID: {
        value: systemID
      },
      seatID: seatID
    },
    records: []
  };
};
var createDestFromOnboards = exports.createDestFromOnboards = function createDestFromOnboards(display, systemID) {
  return {
    id: display.id,
    name: display.name || "Onboard",
    description: display.description || "Onboard",
    availability: display.isActive ? "AVAILABLE" : "NOT_CONNECTED",
    orgData: emptyCDMOrgData,
    endPoint: {
      id: {
        videoEngineID: {
          value: systemID
        },
        seatID: display.id
      },
      name: display.name || "Onboard",
      description: display.description || "Onboard",
      type: "ONBOARD",
      aetherID: {
        value: systemID
      },
      label: {
        value: "NOT_REQUIRED"
      },
      usage: getUsageForDisplay(display.id, systemID)
    },
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)()
  };
};
var createDestFromUClient = exports.createDestFromUClient = function createDestFromUClient(display, systemID) {
  return {
    id: display.id,
    name: display.name || "uClient",
    description: display.description || "uClient",
    availability: (0, _uclient.isUClientLocked)(display) ? "AVAILABLE" : "NOT_CONNECTED",
    orgData: emptyCDMOrgData,
    endPoint: {
      id: {
        videoEngineID: {
          value: systemID
        },
        seatID: display.id
      },
      name: display.name || "uClient",
      description: display.description || "uClient",
      type: "UCLIENT",
      aetherID: {
        value: systemID
      },
      label: {
        value: display.stationLabel.value
      },
      usage: getUsageForDisplay(display.id, systemID)
    },
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)()
  };
};
var createDestFromZeroClient = exports.createDestFromZeroClient = function createDestFromZeroClient(display, systemID) {
  return {
    id: display.id,
    name: display.name || "Zero Client",
    description: display.description || "Zero Client",
    availability: (0, _zeroClient.isZeroClientLocked)(display) ? "AVAILABLE" : "NOT_CONNECTED",
    orgData: emptyCDMOrgData,
    endPoint: {
      id: {
        videoEngineID: {
          value: systemID
        },
        seatID: display.id
      },
      name: display.name || "Zero Client",
      description: display.description || "Zero Client",
      type: "ZERO_CLIENT",
      aetherID: {
        value: systemID
      },
      label: display.stationLabel,
      usage: getUsageForDisplay(display.id, systemID)
    },
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)()
  };
};
var getStandaloneDisplayDestinations = exports.getStandaloneDisplayDestinations = function getStandaloneDisplayDestinations() {
  var dataStore = (0, _store.getStandaloneDataStore)();
  var dest = [];
  var _iterator = _createForOfIteratorHelper((0, _displays.getStandaloneOnboards)()),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var display = _step.value;
      dest.push(createDestFromOnboards(display, dataStore.systemID));
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  var _iterator2 = _createForOfIteratorHelper((0, _displays.getStandaloneUClients)()),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _display = _step2.value;
      dest.push(createDestFromUClient(_display, dataStore.systemID));
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
  var _iterator3 = _createForOfIteratorHelper((0, _displays.getStandaloneZeroClients)()),
    _step3;
  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var _display2 = _step3.value;
      dest.push(createDestFromZeroClient(_display2, dataStore.systemID));
    }
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }
  return dest;
};
var getStandaloneVideoWallDestinations = exports.getStandaloneVideoWallDestinations = function getStandaloneVideoWallDestinations() {
  var dataStore = (0, _store.getStandaloneDataStore)();
  var dest = [];
  var _iterator4 = _createForOfIteratorHelper((0, _videoWalls.getStandaloneVideoWalls)()),
    _step4;
  try {
    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
      var vw = _step4.value;
      dest.push({
        id: vw.id,
        name: vw.name,
        description: "",
        availability: "AVAILABLE",
        orgData: emptyCDMOrgData,
        endPoint: {
          id: {
            videoEngineID: {
              value: dataStore.systemID
            },
            videoWallID: vw.id
          },
          name: vw.name,
          description: vw.description,
          aetherID: {
            value: dataStore.systemID
          },
          artistic: vw.calibrationType === 'ARTISTIC',
          pixelSize: vw.totalSize.size
        },
        displays: vw.displays.filter(function (d) {
          return !(0, _common.isIDNotSet)(d.seatID);
        }).map(function (d) {
          var display = (0, _displays.findDisplayBySeatID)(d.seatID);
          return {
            id: d.seatID,
            endPoint: {
              id: {
                videoEngineID: {
                  value: dataStore.systemID
                },
                seatID: d.seatID
              },
              name: display.name,
              description: display.description,
              type: (0, _display3.getDisplayTypeFromSeatID)(d.seatID),
              aetherID: {
                value: dataStore.systemID
              },
              label: display.stationLabel,
              usage: {
                id: {
                  videoEngineID: {
                    value: dataStore.systemID
                  },
                  seatID: d.seatID
                },
                records: []
              }
            },
            availability: "AVAILABLE",
            positionInfo: d.position
          };
        }),
        userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)()
      });
    }
  } catch (err) {
    _iterator4.e(err);
  } finally {
    _iterator4.f();
  }
  return dest;
};
var getStandaloneDestinationsSet = exports.getStandaloneDestinationsSet = function getStandaloneDestinationsSet() {
  return {
    physicalAudio: [],
    physicalVideo: getStandaloneDisplayDestinations(),
    videoWalls: getStandaloneVideoWallDestinations(),
    groups: []
  };
};