import React, { useState } from "react";
import { IMultiStateToggleChoice, IMultiStateToggleOptions } from "./multiStateToggleUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MultiStateToggle.scss";
import { useTranslation } from "react-i18next";

interface IProps {
     choices: IMultiStateToggleChoice[];
     onChange: Function;
     options?: IMultiStateToggleOptions;
}

export default function MultiStateToggle(props: IProps) {
     const { t } = useTranslation();

     const [choiceIndex, setChoiceIndex] = useState(props.options ? (props.options.initChoice ? props.options.initChoice : 0) : 0);

     const onClick = (choice: IMultiStateToggleChoice, index: number) => {
          props.onChange(choice, index);
          setChoiceIndex(index);
     };

     return (
          <div className="multiStateWrapper">
               {props.choices
                    ? props.choices.map((choice, index) => {
                         if (props.options && props.options.hideOverlay) {
                              return (
                                   <div
                                        key={"ms_" + choice.name}
                                        className={` ${choice.disabled ? "mSbutton-disabled " : "mSbutton"} ${choiceIndex === index ? "active" : ""}`}
                                        onClick={(e) => {
                                             if (!choice.disabled) {
                                                  onClick(choice, index);
                                             }
                                        }}
                                   >
                                        {props.options ? (
                                             props.options.hideName ? null : (
                                                  <p> {choice.displayKey ? choice.displayKey : t(choice.name)}</p>
                                             )
                                        ) : (
                                             <p>{choice.displayKey ? choice.displayKey : t(choice.name)}</p>
                                        )}
                                        {choice.icon ? <FontAwesomeIcon icon={choice.icon} className="mSicon" /> : null}
                                   </div>
                              );
                         } else
                              return (
                                   <OverlayTrigger
                                        key={"ms_" + choice.name}
                                        placement="top"
                                        overlay={<Tooltip>{choice.displayKey ? choice.displayKey : t(choice.name)}</Tooltip>}
                                   >
                                        <div
                                             className={`${choice.disabled ? "mSbutton-disabled " : "mSbutton"} ${choiceIndex === index ? "active" : ""}`}
                                             onClick={(e) => {
                                                  if (!choice.disabled) {
                                                       onClick(choice, index);
                                                  }
                                             }}
                                        >
                                             {props.options ? (
                                                  props.options.hideName ? null : (
                                                       <p>{choice.displayKey ? choice.displayKey : t(choice.name)}</p>
                                                  )
                                             ) : (
                                                  <p>{choice.displayKey ? choice.displayKey : t(choice.name)}</p>
                                             )}
                                             {choice.icon ? <FontAwesomeIcon icon={choice.icon} className="mSicon" /> : null}
                                        </div>
                                   </OverlayTrigger>
                              );
                    })
                    : null}
          </div>
     );
}
