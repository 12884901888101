import React, { useState } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import './sidenav.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMenuItem, MenuItem, MixedMenuItem, SubMenuItem } from '../Interfaces/Interfaces';
import { useTranslation } from 'react-i18next';
import UserfulTooltip from '../Widgets/UserfulTooltip';
import { faAngleDown, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
interface IProps {
    showContent: string;
    handleChangeShowContent: any;
    menuItems: MixedMenuItem[];
    overrideShowContent?: string;
    noTranslate?: boolean;
}

interface OpenSubNav {
    name: string;
    open: boolean;
};

export default function SideNav(props: IProps) {
    const { t } = useTranslation();
    const [openSubNav, setOpenSubNav] = useState<OpenSubNav[]>([]);

    const handleSubMenuItemClick = (name: string) => {
        // Check if name is in openSubNav
        const items = [...openSubNav];
        const resIndex = openSubNav.findIndex(item => item.name === name);
        if (resIndex > -1) {
            items.splice(resIndex, 1, { name, open: !openSubNav[resIndex].open });
            setOpenSubNav(items);
        } else {
            items.push({ name: name, open: false });
            setOpenSubNav(items);
        }
    };

    const isCollapseOpen = (name) => {
        const res = openSubNav.find(ele => ele.name === name);
        if (res) return res.open;
        return true;
    };

    const renderSubMenuItem = (item: MixedMenuItem) => {
        if (isMenuItem(item)) {
            return renderMenuItem(item);
        }
        const subMenuItem = item as SubMenuItem;
        return <div className="subnavcontainer">
            <div className='nav-text-icon-wrapper' onClick={() => handleSubMenuItemClick(subMenuItem.subMenuHeaderTitle)}>
                <div className='nav'>
                    {subMenuItem.icon && <div className="menuIcon"><FontAwesomeIcon
                        icon={subMenuItem.icon.iconName}
                        className="menu-icon"
                        aria-hidden="true"
                        style={{
                            width: subMenuItem.icon.width,
                            height: subMenuItem.icon.height ? subMenuItem.icon.height : "16px",
                        }}
                    /></div>}
                    {props.noTranslate? subMenuItem.subMenuHeaderTitle : t(subMenuItem.subMenuHeaderTitle)}
                </div>
                <div className="arrowDiv">
                    {isCollapseOpen(subMenuItem.subMenuHeaderTitle) ? <FontAwesomeIcon icon={faAngleDown} className="arrowIcon" /> : <FontAwesomeIcon icon={faAngleRight} className="arrowIcon" />}
                </div>
            </div>
            <Collapse in={isCollapseOpen(subMenuItem.subMenuHeaderTitle)}>
                <div className="nav-items-group">
                    {subMenuItem.subMenu.map(
                        menu => (
                            <Nav className="flex-column" id={menu.id || `side-nav-menu-item-${menu.title}`} key={menu.id || `side-nav-menu-item-${menu.title}`}>
                                <UserfulTooltip title={(props.noTranslate? menu.tooltip : t(menu.tooltip)) || ''}>
                                    <Nav.Item className={(props.showContent === menu.title || props.overrideShowContent === menu.title) ? 'active' : ""}>
                                        <Nav.Link className="flex" onClick={() => handleChangeShowContent(menu.title)}>
                                            {menu.icon && <div>
                                                <FontAwesomeIcon
                                                    icon={menu.icon.iconName}
                                                    className="menu-icon"
                                                    aria-hidden="true"
                                                    style={{
                                                        height: menu.icon.height ? menu.icon.height : "auto",
                                                        width: menu.icon.width,
                                                    }}
                                                />
                                            </div>}
                                            {props.noTranslate? menu.title : t(menu.title)}
                                        </Nav.Link>
                                    </Nav.Item>
                                </UserfulTooltip>
                            </Nav>
                        )
                    )}
                </div>
            </Collapse>
        </div>
    }

    const renderMenuItem = (item: MixedMenuItem) => {
        if (!isMenuItem(item)) {
            return renderSubMenuItem(item);
        }
        const menuItem = item as MenuItem;
        return <Nav className="flex-column" id={menuItem.id || `side-nav-menu-item-${menuItem.title}`}>
            <UserfulTooltip title={props.noTranslate? menuItem.tooltip : t(menuItem.tooltip)}>
                <Nav.Item className={props.showContent === menuItem.title ? 'active' : ""}>
                    <Nav.Link className="flex" onClick={() => handleChangeShowContent(menuItem.title)} disabled={menuItem.disable}>
                        <div>
                            <FontAwesomeIcon
                                icon={menuItem.icon.iconName}
                                className="menu-icon"
                                aria-hidden="true"
                                style={{
                                    width: menuItem.icon.width,
                                    height: menuItem.icon.height ? menuItem.icon.height : "16px",
                                }}
                            />
                        </div>
                        {props.noTranslate? menuItem.title : t(menuItem.title)}
                    </Nav.Link>
                </Nav.Item>
            </UserfulTooltip>
        </Nav>
    }

    const { handleChangeShowContent, menuItems } = props;
    return (
        <div className="side-nav">
            {/* <Search /> */}
            <div className="nav-items-group">
                {menuItems.map(
                    (menuItem) => renderMenuItem(menuItem)
                )}
            </div>
        </div>
    )
}

