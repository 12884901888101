import React, { useEffect, useMemo, useRef } from "react";
import { faMinus, faPlus, faCircleQuestion, faCopy } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import { IUFormField } from "../uFormUtils";
import UFieldTitle from "./UFieldTitle/UFieldTitle";
import { SourcePermissionsForm } from "../../sourceassets";
import { ClearanceLevelID, EMPTY_USERFUL_SECURITY_DATA } from "userful-chronos-app-common-js/dist/models/common";

interface IProps {
    field: IUFormField;
    forceValue?: string | number | boolean | undefined;
    forceError?: string;
    onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

export default function UPermissions({ field, onItemChange, forceValue, forceError }: IProps) {
    const { t } = useTranslation();

    // const [refresh, setRefresh] = useState(false);
    const { onFieldChange, state } = useUFormContext();
    const permissions = useRef(state.fieldState[field.key]);

    useEffect(() => {
        permissions.current = [...state.fieldState[field.key]];
    }, [state])

    const onChangePermission = (checkBool: boolean, given: string) => {

        const changedValue = [...permissions.current];

        if (!checkBool) {
            for (let i = 0; i < changedValue.length; i++) {
                const next: ClearanceLevelID = changedValue[i];

                if (next.value === given) {
                    const indexToRemove = changedValue.findIndex((item) => item.value === given);
                    if (indexToRemove !== -1) {
                        changedValue.splice(indexToRemove, 1);
                    }
                }
            }
        } else {
            changedValue.push({ value: given });
        }

        onFieldChange({ [field.key]: changedValue }, field);

        // const updatedDestination = JSON.parse(JSON.stringify(destinationRef.current));
        // const updatedClearanceLevelPermissions = [...updatedDestination.userfulSecurityData.clearanceLevelPermissions];
        // if (checkBool) {
        //     // If checkBool is true, add the value to the array if it doesn't already exist
        //     if (!updatedClearanceLevelPermissions.some(item => item.value === value)) {
        //         updatedClearanceLevelPermissions.push({ value: value });
        //     }
        // } else {
        //     // If checkBool is false, remove the value from the array if it exists
        //     const indexToRemove = updatedClearanceLevelPermissions.findIndex(item => item.value === value);
        //     if (indexToRemove !== -1) {
        //         updatedClearanceLevelPermissions.splice(indexToRemove, 1);
        //     }
        // }
        // // Update the clearanceLevelPermissions in the updated state
        // updatedDestination.userfulSecurityData.clearanceLevelPermissions = updatedClearanceLevelPermissions;
        // setDestination(updatedDestination);
        // destinationRef.current = updatedDestination;
        // props.onChange(updatedDestination);
    };

    return (
        <div className="fieldColumnWrapper">
            <div className="sourcePermissionsFormAdjuster">
                <SourcePermissionsForm
                    data={permissions.current}
                    onChange={(checkBool, value) => onChangePermission(checkBool, value)}
                    hideHeader
                />
            </div>
        </div>
    );
}
