import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

import {
    REQUEST_LIST_SERVERS, REQUEST_ADD_SERVER, REQUEST_UPDATE_SERVER, REQUEST_REMOVE_SERVER,
    REQUEST_LIST_PASSPORTS, REQUEST_ADD_PASSPORT, REQUEST_REMOVE_PASSPORT, REQUEST_UPDATE_PASSPORT,
    REQUEST_SERCHPARAMS
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/epicMsgDefinitions';
import { EpicServerHeader, EpicServerID } from 'userful-chronos-app-common-js/dist/models/integration/epic';
import { EpicADTPassport, EpicADTPassportHeader, EpicADTPassportID, SearchParamID } from 'userful-chronos-app-common-js/dist/models/integration/epicADT';
import './epicMsgReciever';
export const requestListServers = () => {// wait time
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_SERVERS);
};

export const requestAddServer = (payload: EpicServerHeader) => { // wait time
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_SERVER, payload);
};

export const requestUpdateServer = (payload: EpicServerHeader) => { //wai time
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_SERVER, payload);
};

export const requestRemoveServer = (payload: EpicServerID) => { // wait time
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_SERVER, payload);
};

export const requestListPassports = () => { //ADT
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_PASSPORTS);
};

export const requestAddPassport = (payload: EpicADTPassportHeader) => { //ADT
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_PASSPORT, payload);
};

export const requestUpdatePassport = (payload: EpicADTPassportHeader) => { //ADT
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PASSPORT, payload);
};

export const requestRemovePassport = (payload: EpicADTPassportID) => { //ADT
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_PASSPORT, payload);
};

export const requestSearchParams = () => { //ADT
    getGlobalStates().eventBus.sendMsg(REQUEST_SERCHPARAMS);
};