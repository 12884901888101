
import moment from 'moment';
import { SamPlayListItemDef } from 'userful-chronos-app-common-js/dist/models/sam/SAMPlaylist';
import { APP_IDS, APP_NAMES, UserfulSecurityData } from 'userful-chronos-app-common-js/dist/models/common';
import React from 'react';
import { ClearanceLevel } from 'userful-chronos-app-common-js/dist/models/usermgt/clearanceLevel';

export const AppsConfig = [
    {
        id: APP_IDS.Decisions,
        name: APP_NAMES.ControlRoom,
        title: "ChronosLauncher.Diamond.tooltip",
        titleIcon: (<img alt='D' src="./assets/diamond.svg" />),
    },
    {
        id: APP_IDS.Trends,
        name: APP_NAMES.Trends,
        title: "ChronosLauncher.Topaz.tooltip",
        titleIcon: (<img alt='T' src="./assets/topaz.svg" />),
    },
    {
        id: APP_IDS.Spaces,
        name: APP_NAMES.Spaces,
        title: "ChronosLauncher.Sapphire.tooltip",
        titleIcon: (<img alt='S' src="./assets/sapphire.svg" />),
    },
    {
        id: APP_IDS.Artistic,
        name: APP_NAMES.Artistic,
        title: "ChronosLauncher.Amethyst.tooltip",
        titleIcon: (<img alt='A' src="./assets/amethyst.svg" />),
    },
    {
        id: APP_IDS.Engage,
        name: APP_NAMES.Engage,
        title: "ChronosLauncher.Emerald.tooltip",
        titleIcon: (<img alt='E' src="./assets/emerald.svg" />),
    },
    // {
    //     id: APP_IDS.Remote,
    //     name: APP_NAMES.Remote,
    //     title: "ChronosLauncher.Ruby.tooltip",
    //     titleIcon: (<img alt='R' src="./assets/ruby.svg" />),
    // },
    {
        id: APP_IDS.Switch,
        name: APP_NAMES.Switch,
        title: "ChronosLauncher.uControl_Subname",
        // titleIcon: (<FontAwesomeIcon icon={faCodePullRequest} />),
        titleIcon: <img id="uControlIcon" alt="uControl" src="./assets/uControl.svg" />,
    }
]

export const getSecondsFromDuration = (durationValue: any): number => {
    if (!durationValue) {
        return 0;
    }
    if (isNaN(durationValue)) {
        return moment.duration(durationValue).asSeconds();
    }
    return Number(durationValue);
};

export const getPlayListDurationInSeconds = (items: SamPlayListItemDef[]): string => {
    let totalDuration = 0;
    items.forEach(item => {
        let totalItemDuration =
            getSecondsFromDuration(item.behaviour.activationDuration) +
            getSecondsFromDuration(item.behaviour.playDuration);
        // getSecondsFromDuration(item.behaviour.preStartDuration);
        if (item.behaviour.repeatConfig.times.times > 0) {
            totalItemDuration = totalItemDuration * item.behaviour.repeatConfig.times.times;
        }
        if (getSecondsFromDuration(item.behaviour.repeatConfig.duration) > 0) {
            totalItemDuration = Math.min(totalItemDuration, getSecondsFromDuration(item.behaviour.repeatConfig.duration));
        }
        totalDuration += totalItemDuration;
    })
    if (totalDuration > 0) {
        return ` ${moment.duration(totalDuration, 'seconds').humanize()}`;
    }
    return ' Unknown Duration';
}