import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
// import { HTMLInputTypeAttribute } from "react";
import { CreateControlRoomData, SnapshotComp } from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";
import { IUFormConditions, IUFormFieldState, IUFormState, initIUFormConditions } from "./Context/UFormContext";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { MessageDefinition } from "userful-chronos-app-common-js/dist/message/messageModel";
import { SourceSpecAssetSpec } from "userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec";
import { HasOrgData, SamOrgData, SamSourceAssetTypeEnum } from "userful-chronos-app-common-js/dist/models/sam/Common";
import { SamSourceAsset } from "userful-chronos-app-common-js/dist/models/sam/SAMAsset";
import { IUButton, IUDropdownButton } from "../Widgets/Buttons/buttonsUtils";

export interface IUFormOption {
    title: string;
    contentGroups: IUFormContentGroup[];
    description?: string;
    hideController?: IUFormHideController;
}

export interface IUFormHideController {
    fieldKey: string;
    successValues?: any[];
    failureValues?: any[];
}

export interface IUFormContentGroup {
    title: string;
    blocks: IUFormBlock[];
    hide?: boolean;
    hideController?: IUFormHideController;
}

export interface IUFormBlock {
    title?: string;
    description?: string;
    hide?: boolean;
    hideController?: IUFormHideController;
    hideTitle?: boolean;
    fields?: (IUFormField | IUFormFieldCollection)[];
}
export interface IUFormFieldCollection {
    type: FieldCollectionType;
    key: string;
    title: string;
    items: IUFormField[];
    hide?: boolean;
    hideController?: IUFormHideController;
    numberPreserveRatioButton?: boolean | undefined;
    numberSwapValuesButton?: boolean | undefined;
    setTrueByDefault?: boolean | undefined;
    inputIndependentCropping?: boolean | undefined;
    collectionOrientation?: OrientationType | undefined;
    collectionPattern?: number[] | undefined;
    disabled?: boolean;
}
export interface IUFormField {
    type?: FieldType;
    help?: string;
    key?: string;
    title?: string;
    hide?: boolean;
    hideController?: IUFormHideController;
    hideTitle?: boolean | undefined;
    required?: boolean | undefined;
    restrictions?: IRestriction[];
    items?: IItem[] | undefined;
    itemsAllowCreateIfNotFound?: boolean;
    inputType?: any | undefined;
    cardDef?: IUFormCard | undefined;
    defaultValue?: string | number | boolean | string[] | number[] | boolean[] | any | undefined;
    fixedValue?: IUFormFixedValue | undefined; // Do not use
    dictates?: IUFormRelationship | undefined; // Do not use
    multiselect?: boolean; // Only available for select type fields, will do nothing for anything else.
    placeholder?: string | undefined;
    numberPlusMinusButton?: boolean | undefined;
    minLength?: number | undefined;
    maxLength?: number | undefined;
    min?: number | undefined;
    max?: number | undefined;
    step?: number;
    description?: string;
    disabled?: boolean;
    copyOnClick?: boolean;
    sourceAssetType?: SamSourceAssetTypeEnum;
    skipTranslation?: boolean;
    id?: string; // html element id
    data?: SamOrgData | undefined; // sources data,
    width?: number;
    displayCheck?: boolean; // for displaying the checkbox in PasswordWidget
    confirmTitle?: string; // title for confirm password field,
    checkTitle?: string;
    temporaryText?: string;
}

export interface IItem {
    id: string; // Not used at the moment
    label: string;
    value: string; // Is used to identify
    img?: string;
    description?: string;
}

export interface IUFormRelationship {
    dependentsFieldKey: string[];
    value: string | number | boolean | string[] | number[] | boolean[] | any | undefined;
}

export interface IUFormFixedValue {
    startsWith: string | number | boolean | string[] | number[] | boolean[] | any | undefined;
    endsWith: string | number | boolean | string[] | number[] | boolean[] | any | undefined;
}

export interface IRestriction {
    type: RestrictionType;
    value?: string[];
    errorMsg?: string | undefined; // If not provided, default one will show.
}

export interface IUFormCard {
    size: SizeType;
    hideTitle?: boolean;
    multiSelect?: boolean;
    enableUpload?: boolean;
}

export type RestrictionType =
    | "IS_NOT_EMPTY"
    | "IS_EMPTY"
    | "CONTAINS"
    | "DOES_NOT_CONTAIN"
    | "EXACTLY"
    | "STARTS_WITH"
    | "ENDS_WITH"
    | "AlphaNumericCharacters"
    | "Email_Address"
    | "PASSWORD"
    | "Email_Address_Validation"
    | "Phone_Number_Validation"
    | "UNIQUEPASSWORD"
    | "HostName"
    | "Port";
export type RelationshipType = "DICTATES";

export interface IUFormFieldEventReturn {
    key: string;
    value: any;
}

export type ButtonType = "CREATION" | "ERROR" | "GHOST" | "ICON" | "PRIMARY" | "SECONDARY" | "TOGGLE";
export type OrientationType = "TOP-DOWN" | "BOTTOM-UP";
export type FieldType =
    | "INPUT"
    | "TEXTAREA"
    | "CHECKBOX"
    | "RADIO_BUTTON"
    | "DROPDOWN"
    | "TOGGLE"
    | "CARD_SELECTOR"
    | "UPLOAD"
    | "COLOR_PICKER"
    | "TAGS"
    | "PASSWORD"
    | "MULTISELECT"
    | "UniquePassword"
    | "PERMISSIONS";
export type SizeType = "SMALL" | "REGULAR" | "LARGE";
export type FieldCollectionType = "COMPLEX";

/**
 * Main Form Components:
 */

export interface IUForm {
    trigger: IUFormTrigger;
    header: IUFormHeader;
    body: IUFormBody;
    footer: IUFormFooter;
    editForm?: boolean;
}
export interface IUFormTrigger {
    text?: string;
    buttonType?: ButtonType;
    icon?: IconDefinition;
    hideTrigger?: boolean;
    simpleTrigger?: boolean;
    dropdownButton?: IUDropdownButton;
    button?: IUButton;
}

export interface IUFormHeader {
    mainText: string;
    description: string;
    hideCloseButton?: boolean;
}

export interface IUFormBody {
    options: IUFormOption[];
    hideMenu?: boolean;
}

export interface IUFormFooter {
    confirmText: string;
    cancelText?: string;
    discardChanges?: string;
}

/**
 * Form Functions:
 */

export const initIUFormFieldStateFromForm = (givenForm: IUForm): IUFormFieldState => {
    let updatedFieldState: IUFormFieldState = {};

    const givenOptions: IUFormOption[] = givenForm.body.options;

    for (let i = 0; i < givenOptions.length; i++) {
        if (givenOptions[i]) {
            const givenContentGroups: IUFormContentGroup[] = givenOptions[i].contentGroups;

            for (let j = 0; j < givenContentGroups.length; j++) {
                if (givenContentGroups[j]) {
                    const givenBlocks: IUFormBlock[] = givenContentGroups[j].blocks;

                    for (let k = 0; k < givenBlocks.length; k++) {
                        if (givenBlocks[k]) {
                            const givenFields: (IUFormField | IUFormFieldCollection)[] = givenBlocks[k].fields;

                            for (let l = 0; l < givenFields.length; l++) {
                                const givenField = givenFields[l];

                                if (givenField.type === "COMPLEX") {
                                    let items: IUFormFieldState = {};

                                    for (let m = 0; m < givenField.items.length; m++) {
                                        items = {
                                            ...items,
                                            [givenField.items[m].key]: givenField.items[m].defaultValue,
                                        };
                                    }

                                    updatedFieldState = { ...updatedFieldState, [givenField.key]: items };
                                } else {
                                    updatedFieldState = {
                                        ...updatedFieldState,
                                        [givenField.key]: givenField.defaultValue,
                                    };
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return updatedFieldState;
};

export const autoGenerateContextFromIUForm = (
    givenForm: IUForm
): { aFieldState: IUFormFieldState; aFieldError: IUFormFieldState } => {
    let aFieldState: IUFormFieldState = {};
    let aFieldError: IUFormFieldState = {};

    const givenOptions: IUFormOption[] = givenForm.body.options;

    for (let i = 0; i < givenOptions.length; i++) {
        if (givenOptions[i]) {
            const givenContentGroups: IUFormContentGroup[] = givenOptions[i].contentGroups;

            for (let j = 0; j < givenContentGroups.length; j++) {
                if (givenContentGroups[j]) {
                    const givenBlocks: IUFormBlock[] = givenContentGroups[j].blocks;

                    for (let k = 0; k < givenBlocks.length; k++) {
                        if (givenBlocks[k]) {
                            const givenFields: (IUFormField | IUFormFieldCollection)[] = givenBlocks[k].fields;

                            for (let l = 0; l < givenFields.length; l++) {
                                const givenField = givenFields[l];

                                if (givenField.type === "COMPLEX") {
                                    let items: IUFormFieldState = {};
                                    let itemsError: IUFormFieldState = {};

                                    for (let m = 0; m < givenField.items.length; m++) {
                                        items = {
                                            ...items,
                                            [givenField.items[m].key]: givenField.items[m].defaultValue,
                                        };
                                        itemsError = { ...itemsError, [givenField.items[m].key]: "" };
                                    }

                                    aFieldState = { ...aFieldState, [givenField.key]: items };
                                    aFieldError = { ...aFieldError, [givenField.key]: itemsError };
                                } else {
                                    aFieldState = { ...aFieldState, [givenField.key]: givenField.defaultValue };
                                    aFieldError = { ...aFieldError, [givenField.key]: "" };
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return { aFieldState: aFieldState, aFieldError: aFieldError };
};

export const updateIUFormRef = (data: any, form: IUForm): IUForm => {
    const updatedFormConditions: IUFormConditions = { affirmationAllowed: false, equivalenceConfirmed: true };

    const givenOptions: IUFormOption[] = form.body.options;

    for (let i = 0; i < givenOptions.length; i++) {
        if (givenOptions[i]) {
            const givenContentGroups: IUFormContentGroup[] = givenOptions[i].contentGroups;

            for (let j = 0; j < givenContentGroups.length; j++) {
                if (givenContentGroups[j]) {
                    const givenBlocks: IUFormBlock[] = givenContentGroups[j].blocks;

                    for (let k = 0; k < givenBlocks.length; k++) {
                        if (givenBlocks[k]) {
                            const givenFields: (IUFormField | IUFormFieldCollection)[] = givenBlocks[k].fields;

                            for (let l = 0; l < givenFields.length; l++) {
                                const givenField = givenFields[l];

                                if (givenField.type === "COMPLEX") {
                                    if (data.hasOwnProperty(givenField.key)) {
                                        // TODO: Not supported yet
                                    }
                                } else {
                                    if (data.hasOwnProperty(givenField.key)) {
                                        if (givenField.restrictions) {
                                            for (let m = 0; m < givenField.restrictions.length; m++) {
                                                if (givenField.restrictions[m].type === "EXACTLY") {
                                                    const index = givenField.restrictions[m].value.findIndex(
                                                        (r) => r === givenField.defaultValue
                                                    );

                                                    if (index >= 0) {
                                                        givenField.restrictions[m].value.splice(index, 1);
                                                    }

                                                    givenField.restrictions[m].value.push(data[givenField.key]);
                                                }
                                            }
                                        }

                                        givenField.defaultValue = data[givenField.key];
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (updatedFormConditions.equivalenceConfirmed) {
        if (form.editForm) updatedFormConditions.affirmationAllowed = false;
    }

    return form;
};

export const changeForm = (changedField: IUFormField, givenForm: IUForm): IUForm => {
    if (givenForm) {
        let updatedFieldState: IUFormFieldState = {};

        const givenOptions: IUFormOption[] = givenForm.body.options;

        for (let i = 0; i < givenOptions.length; i++) {
            if (givenOptions[i]) {
                const givenContentGroups: IUFormContentGroup[] = givenOptions[i].contentGroups;

                for (let j = 0; j < givenContentGroups.length; j++) {
                    if (givenContentGroups[j]) {
                        const givenBlocks: IUFormBlock[] = givenContentGroups[j].blocks;

                        for (let k = 0; k < givenBlocks.length; k++) {
                            if (givenBlocks[k]) {
                                const givenFields: (IUFormField | IUFormFieldCollection)[] = givenBlocks[k].fields;

                                for (let l = 0; l < givenFields.length; l++) {
                                    if (changedField.key === givenFields[l].key) {
                                        givenFields[l] = changedField;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return givenForm;
    }
    return null;
};

export const getField = (fieldKey: string, givenForm: IUForm): IUFormField | IUFormFieldCollection => {
    if (givenForm) {
        let updatedFieldState: IUFormFieldState = {};

        const givenOptions: IUFormOption[] = givenForm.body.options;

        for (let i = 0; i < givenOptions.length; i++) {
            if (givenOptions[i]) {
                const givenContentGroups: IUFormContentGroup[] = givenOptions[i].contentGroups;

                for (let j = 0; j < givenContentGroups.length; j++) {
                    if (givenContentGroups[j]) {
                        const givenBlocks: IUFormBlock[] = givenContentGroups[j].blocks;

                        for (let k = 0; k < givenBlocks.length; k++) {
                            if (givenBlocks[k]) {
                                const givenFields: (IUFormField | IUFormFieldCollection)[] = givenBlocks[k].fields;

                                for (let l = 0; l < givenFields.length; l++) {
                                    if (fieldKey === givenFields[l].key) {
                                        return givenFields[l];
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null;
};
