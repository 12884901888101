import React from 'react';
import './FieldHeader.scss';

interface IProps {
    name: string;
    description: string;
}

const FieldHeader = (props: IProps) => {

    return (
        <div className="fieldHeaderDiv">
            <div className="headDiv">
                {props.name}
            </div>
            <div className="descriptionDiv">
                {props.description}
            </div>
        </div>
    );
}

export default FieldHeader;