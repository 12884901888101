"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_UPDATE_CHANNEL = exports.RESPONSE_SET_SMTP_CONFIG = exports.RESPONSE_REMOVE_SMTP_CONFIG = exports.RESPONSE_MONITORING_INFO = exports.RESPONSE_LIST_CHANNELS = exports.RESPONSE_GET_SMTP_CONFIG = exports.RESPONSE_GET_CHANNEL = exports.RESPONSE_DELETE_CHANNEL = exports.RESPONSE_CREATE_CHANNEL = exports.RESPOND_CAPTURE_CARD_INFO = exports.REQUEST_UPDATE_CHANNEL = exports.REQUEST_SET_SMTP_CONFIG = exports.REQUEST_REMOVE_SMTP_CONFIG = exports.REQUEST_MONITORING_INFO = exports.REQUEST_LIST_CHANNELS = exports.REQUEST_GET_SMTP_CONFIG = exports.REQUEST_GET_CHANNEL = exports.REQUEST_DELETE_CHANNEL = exports.REQUEST_CREATE_CHANNEL = exports.REQUEST_CAPTURE_CARD_INFO = void 0;
var _messageModel = require("../../messageModel");
var MONITORING_INFO = 1801;
var CAPTURE_CARD_INFO = 1802;
var SMTP_CONFIG = 1803;
var NOTIFICATION_CHANNEL = 1804;

//assets
var REQUEST_MONITORING_INFO = exports.REQUEST_MONITORING_INFO = new _messageModel.RequestMessageDefinition(MONITORING_INFO, 0);
var RESPONSE_MONITORING_INFO = exports.RESPONSE_MONITORING_INFO = new _messageModel.RespondMessageDefinition(MONITORING_INFO, 0);
var REQUEST_CAPTURE_CARD_INFO = exports.REQUEST_CAPTURE_CARD_INFO = new _messageModel.RequestMessageDefinition(CAPTURE_CARD_INFO, 0);
var RESPOND_CAPTURE_CARD_INFO = exports.RESPOND_CAPTURE_CARD_INFO = new _messageModel.RespondMessageDefinition(CAPTURE_CARD_INFO, 0);
var REQUEST_GET_SMTP_CONFIG = exports.REQUEST_GET_SMTP_CONFIG = new _messageModel.RequestMessageDefinition(SMTP_CONFIG, 0);
var RESPONSE_GET_SMTP_CONFIG = exports.RESPONSE_GET_SMTP_CONFIG = new _messageModel.RespondMessageDefinition(SMTP_CONFIG, 0);
var REQUEST_SET_SMTP_CONFIG = exports.REQUEST_SET_SMTP_CONFIG = new _messageModel.RequestMessageDefinition(SMTP_CONFIG, 1);
var RESPONSE_SET_SMTP_CONFIG = exports.RESPONSE_SET_SMTP_CONFIG = new _messageModel.RequestMessageDefinition(SMTP_CONFIG, 1);
var REQUEST_REMOVE_SMTP_CONFIG = exports.REQUEST_REMOVE_SMTP_CONFIG = new _messageModel.RequestMessageDefinition(SMTP_CONFIG, 2);
var RESPONSE_REMOVE_SMTP_CONFIG = exports.RESPONSE_REMOVE_SMTP_CONFIG = new _messageModel.RequestMessageDefinition(SMTP_CONFIG, 2);
var REQUEST_LIST_CHANNELS = exports.REQUEST_LIST_CHANNELS = new _messageModel.RequestMessageDefinition(NOTIFICATION_CHANNEL, 0);
var RESPONSE_LIST_CHANNELS = exports.RESPONSE_LIST_CHANNELS = new _messageModel.RequestMessageDefinition(NOTIFICATION_CHANNEL, 0);
var REQUEST_GET_CHANNEL = exports.REQUEST_GET_CHANNEL = new _messageModel.RequestMessageDefinition(NOTIFICATION_CHANNEL, 1);
var RESPONSE_GET_CHANNEL = exports.RESPONSE_GET_CHANNEL = new _messageModel.RequestMessageDefinition(NOTIFICATION_CHANNEL, 1);
var REQUEST_CREATE_CHANNEL = exports.REQUEST_CREATE_CHANNEL = new _messageModel.RequestMessageDefinition(NOTIFICATION_CHANNEL, 2);
var RESPONSE_CREATE_CHANNEL = exports.RESPONSE_CREATE_CHANNEL = new _messageModel.RequestMessageDefinition(NOTIFICATION_CHANNEL, 2);
var REQUEST_UPDATE_CHANNEL = exports.REQUEST_UPDATE_CHANNEL = new _messageModel.RequestMessageDefinition(NOTIFICATION_CHANNEL, 3);
var RESPONSE_UPDATE_CHANNEL = exports.RESPONSE_UPDATE_CHANNEL = new _messageModel.RequestMessageDefinition(NOTIFICATION_CHANNEL, 3);
var REQUEST_DELETE_CHANNEL = exports.REQUEST_DELETE_CHANNEL = new _messageModel.RequestMessageDefinition(NOTIFICATION_CHANNEL, 4);
var RESPONSE_DELETE_CHANNEL = exports.RESPONSE_DELETE_CHANNEL = new _messageModel.RequestMessageDefinition(NOTIFICATION_CHANNEL, 4);