"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPOND_WORKBOOK_LIST = exports.RESPOND_VIEW_LIST = exports.RESPOND_UPDATE_PASSPORT = exports.RESPOND_REMOVE_PASSPORT = exports.RESPOND_LIST_PASSPORTS = exports.RESPOND_GET_JWT = exports.RESPOND_ADD_PASSPORT = exports.REQUEST_WORKBOOK_LIST = exports.REQUEST_VIEW_LIST = exports.REQUEST_UPDATE_PASSPORT = exports.REQUEST_REMOVE_PASSPORT = exports.REQUEST_LIST_PASSPORTS = exports.REQUEST_GET_JWT = exports.REQUEST_ADD_PASSPORT = exports.NOTIFY_PASSPORT_UPDATE = void 0;
var _messageModel = require("../../messageModel");
var TABLEAU_PASSPORT = 6300;
var TABLEAU_EMBED = 6301;
var REQUEST_LIST_PASSPORTS = exports.REQUEST_LIST_PASSPORTS = new _messageModel.RequestMessageDefinition(TABLEAU_PASSPORT, 0);
var REQUEST_ADD_PASSPORT = exports.REQUEST_ADD_PASSPORT = new _messageModel.RequestMessageDefinition(TABLEAU_PASSPORT, 1);
var REQUEST_UPDATE_PASSPORT = exports.REQUEST_UPDATE_PASSPORT = new _messageModel.RequestMessageDefinition(TABLEAU_PASSPORT, 2);
var REQUEST_REMOVE_PASSPORT = exports.REQUEST_REMOVE_PASSPORT = new _messageModel.RequestMessageDefinition(TABLEAU_PASSPORT, 3);
var RESPOND_LIST_PASSPORTS = exports.RESPOND_LIST_PASSPORTS = new _messageModel.RespondMessageDefinition(TABLEAU_PASSPORT, 0);
var RESPOND_ADD_PASSPORT = exports.RESPOND_ADD_PASSPORT = new _messageModel.RespondMessageDefinition(TABLEAU_PASSPORT, 1);
var RESPOND_UPDATE_PASSPORT = exports.RESPOND_UPDATE_PASSPORT = new _messageModel.RespondMessageDefinition(TABLEAU_PASSPORT, 2);
var RESPOND_REMOVE_PASSPORT = exports.RESPOND_REMOVE_PASSPORT = new _messageModel.RespondMessageDefinition(TABLEAU_PASSPORT, 3);
var NOTIFY_PASSPORT_UPDATE = exports.NOTIFY_PASSPORT_UPDATE = new _messageModel.RespondMessageDefinition(TABLEAU_PASSPORT, 4);
var REQUEST_GET_JWT = exports.REQUEST_GET_JWT = new _messageModel.RequestMessageDefinition(TABLEAU_EMBED, 0);
var REQUEST_WORKBOOK_LIST = exports.REQUEST_WORKBOOK_LIST = new _messageModel.RequestMessageDefinition(TABLEAU_EMBED, 1);
var REQUEST_VIEW_LIST = exports.REQUEST_VIEW_LIST = new _messageModel.RequestMessageDefinition(TABLEAU_EMBED, 2);
var RESPOND_GET_JWT = exports.RESPOND_GET_JWT = new _messageModel.RespondMessageDefinition(TABLEAU_EMBED, 0);
var RESPOND_WORKBOOK_LIST = exports.RESPOND_WORKBOOK_LIST = new _messageModel.RespondMessageDefinition(TABLEAU_EMBED, 1);
var RESPOND_VIEW_LIST = exports.RESPOND_VIEW_LIST = new _messageModel.RespondMessageDefinition(TABLEAU_EMBED, 2);