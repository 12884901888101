"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPOND_UPDATE_SERVER = exports.RESPOND_UPDATE_PASSPORT = exports.RESPOND_SEARCHPARAMS = exports.RESPOND_REMOVE_SERVER = exports.RESPOND_REMOVE_PASSPORT = exports.RESPOND_LIST_SERVERS = exports.RESPOND_LIST_PASSPORTS = exports.RESPOND_ADD_SERVER = exports.RESPOND_ADD_PASSPORT = exports.REQUEST_UPDATE_SERVER = exports.REQUEST_UPDATE_PASSPORT = exports.REQUEST_SERCHPARAMS = exports.REQUEST_REMOVE_SERVER = exports.REQUEST_REMOVE_PASSPORT = exports.REQUEST_LIST_SERVERS = exports.REQUEST_LIST_PASSPORTS = exports.REQUEST_ADD_SERVER = exports.REQUEST_ADD_PASSPORT = exports.NOTIFY_SERVER_UPDATE = exports.NOTIFY_PASSPORT_UPDATE = void 0;
var _messageModel = require("../../messageModel");
var EPIC_OFFSET = 6100;
var EPIC_ADT_OFFSET = 6102;

// epic wait time
var REQUEST_LIST_SERVERS = exports.REQUEST_LIST_SERVERS = new _messageModel.RequestMessageDefinition(EPIC_OFFSET, 0);
var REQUEST_ADD_SERVER = exports.REQUEST_ADD_SERVER = new _messageModel.RequestMessageDefinition(EPIC_OFFSET, 1);
var REQUEST_UPDATE_SERVER = exports.REQUEST_UPDATE_SERVER = new _messageModel.RequestMessageDefinition(EPIC_OFFSET, 2);
var REQUEST_REMOVE_SERVER = exports.REQUEST_REMOVE_SERVER = new _messageModel.RequestMessageDefinition(EPIC_OFFSET, 3);
var RESPOND_LIST_SERVERS = exports.RESPOND_LIST_SERVERS = new _messageModel.RespondMessageDefinition(EPIC_OFFSET, 0);
var RESPOND_ADD_SERVER = exports.RESPOND_ADD_SERVER = new _messageModel.RespondMessageDefinition(EPIC_OFFSET, 1);
var RESPOND_UPDATE_SERVER = exports.RESPOND_UPDATE_SERVER = new _messageModel.RespondMessageDefinition(EPIC_OFFSET, 2);
var RESPOND_REMOVE_SERVER = exports.RESPOND_REMOVE_SERVER = new _messageModel.RespondMessageDefinition(EPIC_OFFSET, 3);
var NOTIFY_SERVER_UPDATE = exports.NOTIFY_SERVER_UPDATE = new _messageModel.RespondMessageDefinition(EPIC_OFFSET, 4);

// epic ADT
var REQUEST_LIST_PASSPORTS = exports.REQUEST_LIST_PASSPORTS = new _messageModel.RequestMessageDefinition(EPIC_ADT_OFFSET, 0);
var REQUEST_ADD_PASSPORT = exports.REQUEST_ADD_PASSPORT = new _messageModel.RequestMessageDefinition(EPIC_ADT_OFFSET, 1);
var REQUEST_UPDATE_PASSPORT = exports.REQUEST_UPDATE_PASSPORT = new _messageModel.RequestMessageDefinition(EPIC_ADT_OFFSET, 2);
var REQUEST_REMOVE_PASSPORT = exports.REQUEST_REMOVE_PASSPORT = new _messageModel.RequestMessageDefinition(EPIC_ADT_OFFSET, 3);
var REQUEST_SERCHPARAMS = exports.REQUEST_SERCHPARAMS = new _messageModel.RequestMessageDefinition(EPIC_ADT_OFFSET, 5);
var RESPOND_LIST_PASSPORTS = exports.RESPOND_LIST_PASSPORTS = new _messageModel.RespondMessageDefinition(EPIC_ADT_OFFSET, 0);
var RESPOND_ADD_PASSPORT = exports.RESPOND_ADD_PASSPORT = new _messageModel.RespondMessageDefinition(EPIC_ADT_OFFSET, 1);
var RESPOND_UPDATE_PASSPORT = exports.RESPOND_UPDATE_PASSPORT = new _messageModel.RespondMessageDefinition(EPIC_ADT_OFFSET, 2);
var RESPOND_REMOVE_PASSPORT = exports.RESPOND_REMOVE_PASSPORT = new _messageModel.RespondMessageDefinition(EPIC_ADT_OFFSET, 3);
var NOTIFY_PASSPORT_UPDATE = exports.NOTIFY_PASSPORT_UPDATE = new _messageModel.RespondMessageDefinition(EPIC_ADT_OFFSET, 4);
var RESPOND_SEARCHPARAMS = exports.RESPOND_SEARCHPARAMS = new _messageModel.RespondMessageDefinition(EPIC_ADT_OFFSET, 5);