import { createContext, useContext } from "react";
import { IUFormField, IUFormFieldCollection, IUForm } from "../uFormUtils";

export interface IUFormConditions {
    affirmationAllowed: boolean; // Allows to proceed with affirmation button.
    equivalenceConfirmed: boolean; // Does not allow to exit without confirmation.
}

export const initIUFormConditions: IUFormConditions = {
    affirmationAllowed: false,
    equivalenceConfirmed: true,
};

export interface IUFormFieldState {
    [key: string]: any;
}

export interface Position {
    x: number;
    y: number;
}

export interface IUFormDragAndDrop {
    mouseDownPosition: Position;
    currentMousePosition: Position;
    target: any;
}

export interface IUFormEventList {
    click: boolean;
}

export interface IUFormState {
    formConditions: IUFormConditions;
    fieldState: IUFormFieldState;
    fieldErrors: IUFormFieldState;
    form: IUForm;
    refresh: IRefresh;
    dragAndDrop: IUFormDragAndDrop;
    click: boolean;
}

export type IRefresh = {
    type: RefreshType;
    count: number;
};

export type RefreshType = "CONFIRM" | "CANCEL" | "UNDEFINED";

export const initIUFormState = (affirmationAllowedByDefault?): IUFormState => {
    const formConditions = initIUFormConditions;
    if (affirmationAllowedByDefault) formConditions.affirmationAllowed = true;

    return {
        formConditions: formConditions,
        fieldState: {},
        fieldErrors: {},
        form: undefined,
        refresh: { type: "UNDEFINED", count: 0 },
        dragAndDrop: { mouseDownPosition: { x: 0, y: 0 }, currentMousePosition: { x: 0, y: 0 }, target: null },
        click: false,
    };
};

export interface IUFormContextProps {
    state: IUFormState;
    setState: (patch: Partial<IUFormState> | ((previousState: IUFormState) => Partial<IUFormState>)) => void;
    onTerminate: () => void;
    onAffirm: () => void;
    onFieldChange: (
        fieldState: IUFormFieldState,
        field: IUFormField | IUFormFieldCollection,
        mousePosition?: Position
    ) => void;
    onDragAndDrop: (dragAndDrop: IUFormDragAndDrop) => void;
    onClick: () => void;
}

export const UFormContext = createContext<IUFormContextProps>({
    state: initIUFormState(),
    setState: () => undefined,
    onTerminate: () => undefined,
    onAffirm: () => undefined,
    onFieldChange: (
        fieldState: IUFormFieldState,
        field: IUFormField | IUFormFieldCollection,
        mousePosition?: Position
    ) => undefined,
    onDragAndDrop: (dragAndDrop: IUFormDragAndDrop) => undefined,
    onClick: () => undefined,
});

export function useUFormContext(): IUFormContextProps {
    const context = useContext(UFormContext);

    if (!context) {
        throw new Error("useUFormContext must be used within a UFormProvider");
    }

    return context;
}
