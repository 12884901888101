import { CdmVideoWallEndPointData } from "userful-chronos-app-common-js/dist/models/cdm/CdmEndPoints";
import { HasPhysicalEndPointID } from "userful-chronos-app-common-js/dist/models/cdm/CdmPhysical";
import { CdmVideoWallDest } from "userful-chronos-app-common-js/dist/models/cdm/CdmVideoWall";
import { HasCdmOrgData } from "userful-chronos-app-common-js/dist/models/cdm/Common";
import { APP_IDS, HasID, StringID } from "userful-chronos-app-common-js/dist/models/common";

export const addOrUpdateReducer = (state, update: HasID, dataName) => {
    const existingIndex = state[dataName].findIndex(item => item.id.value === update.id.value);
    if (existingIndex >= 0) {
        state[dataName] = [
            ...state[dataName].slice(0, existingIndex),
            { ...update },
            ...state[dataName].slice(existingIndex + 1),
        ]
    } else {
        state[dataName] = [
            ...state[dataName],
            { ...update },
        ]
    }
};

export const addOrUpdatePhysicalEndpointsReducer = (state, update: HasPhysicalEndPointID, dataName) => {
    const existingIndex = state[dataName].findIndex(item => item.id.seatID.value === update.id.seatID.value);
    if (existingIndex >= 0) {
        state[dataName] = [
            ...state[dataName].slice(0, existingIndex),
            { ...update },
            ...state[dataName].slice(existingIndex + 1),
        ]
    } else {
        state[dataName] = [
            ...state[dataName],
            { ...update },
        ]
    }
};

export const addOrUpdateVideoWall = (state, update: CdmVideoWallEndPointData) => {
    const existingIndex = state.videowallEndPoints.findIndex(item => item.id.videoWallID.value === update.id.videoWallID.value);
    if (existingIndex >= 0) {
        state.videowallEndPoints = [
            ...state.videowallEndPoints.slice(0, existingIndex),
            { ...update },
            ...state.videowallEndPoints.slice(existingIndex + 1),
        ]
    } else {
        state.videowallEndPoints = [
            ...state.videowallEndPoints,
            { ...update },
        ]
    }
};

export const removeReducer = (state, removed: HasID, dataName) => {
    state[dataName] = state[dataName].filter(item => item.id.value !== removed.id.value);
};

export const removePhysicalEndpointsReducer = (state, removed: HasPhysicalEndPointID, dataName) => {
    state[dataName] = state[dataName].filter(item => item.id.seatID.value !== removed.id.seatID.value);
};

export const removeVideoWall = (state, removed: CdmVideoWallEndPointData, dataName) => {
    state[dataName] = state[dataName].filter(item => item.id.videoWallID.value !== removed.id.videoWallID.value);
};

export const isDestArtisticVideoWall = (data: HasCdmOrgData): boolean => {
    const videoWall = data as CdmVideoWallDest;
    if (videoWall && videoWall.endPoint && videoWall.displays) {
        return videoWall.endPoint.artistic;
    }
    return false;
}

export function sanitizeCdmOrgData<T extends HasCdmOrgData>(data: T): T {
    let updatedAppIDs: StringID[] = [...data.orgData.serverAppIDs.appIDs];
    if (isDestArtisticVideoWall(data)) {
        // artistic video wall is for Amethyst only
        updatedAppIDs = [{ value: APP_IDS.Artistic }, { value: APP_IDS.Decisions }]
    } else if (!data.orgData.serverAppIDs.valid) {
        // treat invalid (initial) app IDs as allow for all apps
        updatedAppIDs = Object.values(APP_IDS).map(item => ({ value: item }));
    }
    return {
        ...data,
        orgData: {
            ...data.orgData,
            serverAppIDs: {
                valid: true,
                appIDs: updatedAppIDs,
            }
        }
    }
}

export function sanitizeCdmOrgDatas<T extends HasCdmOrgData>(data: T[]): T[] {
    return data.map(item => sanitizeCdmOrgData(item));
}