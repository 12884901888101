import { DndContext, UniqueIdentifier, closestCenter } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useEffect, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import Button from "../../Button/Button";
import Paginated from "../../pagination/Paginated";
import SortableItem from "./SortableItem";
import "./SortableMultiSelect.scss";

interface HasName {
    name: string;
}

interface IProps {
    initialOptions: (any & HasName)[];
    appendOptions?: (any & HasName)[]; // only works on new items
    availableOptions?: (any & HasName)[];
    onChange: (options: (any & HasName)[]) => void; // IMPORTANT: this changes items order, not individual item data (which gets reset)
    creatNewItem?: () => any & HasName;
    itemRenderer?: (any) => JSX.Element;
    itemProps?: any;
    limitDragArea?: boolean;
    disabled?: boolean;
    canDelete?: boolean;
    className?: string;
    addButton?: ReactNode;
    paginated?: boolean;
    addButtonID?: string;
    persistentID?: boolean;
}

interface HasUniqueID {
    id: UniqueIdentifier;
    item: any;
}

const toOptions = (items: HasUniqueID[]) => items.map((item) => item.item);

export default function SortableMultiSelect(props: IProps) {

    const getID = (item) =>  {
        if (props.persistentID) {
            return item.id?.value || item.item?.id?.value || uuidv4();
        }
        return uuidv4();
    }

    const [items, setItems] = useState<HasUniqueID[]>(props.initialOptions.map((item) => ({ id: getID(item), item })));

    useEffect(() => {
        if (props.initialOptions) {
            setItems(props.initialOptions.map((item) => ({ id: getID(item), item })));
        }
    }, [props.initialOptions]);
    useEffect(() => {
        if (props.appendOptions) {
            if (props.appendOptions.length > items.length) {
                setItems([...items, ...props.appendOptions.slice(items.length).map((item) => ({ id: getID(item), item }))]);
            }
        }
    }, [props.appendOptions]);
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const getIndex = (id: UniqueIdentifier) => items.findIndex((item) => item.id === id);
    const activeIndex = activeId ? getIndex(activeId) : -1;
    const updateItems = (update: HasUniqueID[]) => {
        setItems(update);
        props.onChange(toOptions(update));
    };
    const handleRemove = (id: UniqueIdentifier) => {
        updateItems(items.filter((item) => item.id !== id));
    };
    const handleCreate = () => {
        const item = props.creatNewItem();
        updateItems([...items, { id:getID(item), item }]);
    };

    const handleAdd = (itemIndex) => {
        const item = props.availableOptions[itemIndex];
        updateItems([...items, { id: getID(item), item}]);
    };

    const itemsRender = () =>
        items.map((value, index) => (
            <SortableItem
                key={value.id}
                id={value.id}
                disabled={props.disabled}
                canDelete={props.canDelete}
                onDelete={handleRemove}
                limitDragArea={props.limitDragArea}
                index={index}
            >
                {props.itemRenderer ? (
                    <props.itemRenderer item={value.item} {...props.itemProps} index={index} disabled={props.disabled} />
                ) : (
                    <div className="sortableItemLabel">{value.item.name}</div>
                )}
            </SortableItem>
        ));

    return (
        <div className={`sortableMultiSelectContainer ${props.className || ""}`}>
            <DndContext
                collisionDetection={closestCenter}
                onDragStart={({ active }) => {
                    if (!active) {
                        return;
                    }

                    setActiveId(active.id);
                }}
                onDragEnd={({ over }) => {
                    setActiveId(null);

                    if (over) {
                        const overIndex = getIndex(over.id);
                        if (activeIndex !== overIndex) {
                            updateItems(arrayMove(items, activeIndex, overIndex));
                        }
                    }
                }}
                onDragCancel={() => setActiveId(null)}
            >
                <div className="sortableMultiSelectItems">
                    <SortableContext items={items}>
                        <Container>{props.paginated ? <Paginated>{itemsRender()}</Paginated> : itemsRender()}</Container>
                    </SortableContext>
                </div>
            </DndContext>
            {props.availableOptions && props.availableOptions.length > 0 && (
                <Dropdown autoClose="outside" onSelect={handleAdd} className="sortableMultiSelectAdd">
                    <Dropdown.Toggle variant="outline-dark" disabled={props.disabled} id="SortableMultiSelectAddMenu">
                        <FontAwesomeIcon icon={faPlus} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{strategy: "fixed"}} renderOnMount={true}>
                        {props.availableOptions.map((item, index) => (
                            <Dropdown.Item eventKey={index} disabled={props.disabled} key={`sortableMultiSelectAvailableItem-${item.name}`}>
                                {item.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {props.creatNewItem && (
                <Button
                    variant="success"
                    onClick={handleCreate}
                    className="sortableMultiSelectAdd"
                    disabled={props.disabled}
                    id={props.addButtonID ? props.addButtonID : "add-multi-select-items-button"}
                >
                    {props.addButton ? props.addButton : <FontAwesomeIcon icon={faPlus} />}
                </Button>
            )}
        </div>
    );
}
