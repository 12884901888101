"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initMapperCollectionComp = void 0;
var _globalStates = require("../../globalstates/globalStates");
var _utils = require("../../utils");
var _common = require("../common");
var _MappingGroups = require("./MappingGroups");
var initMapperCollectionComp = exports.initMapperCollectionComp = function initMapperCollectionComp(appInstanceID, initMappers, gpuID, uclientLatency, frameRate) {
  return {
    id: {
      value: (0, _utils.generateUUID)()
    },
    name: "",
    description: "",
    appInstanceID: appInstanceID || {
      value: (0, _utils.generateUUID)(),
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    },
    mappers: initMappers || [],
    frameRate: frameRate || {
      seconds: 1.0,
      frames: 30
    },
    gpuID: gpuID || _MappingGroups.GPUID_NOT_SET,
    uclientLatency: uclientLatency || 0.25,
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)()
  };
};