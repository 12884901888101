"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSapphirePostLogin = exports.getLauncherPostLogin = exports.getControlRoomPostLogin = exports.getConfigPostLogin = exports.getCalibrationPostLogin = void 0;
var _store = require("../data/store");
var _controlRoom = require("./controlRoom");
var _displays = require("./displays");
var _videoEngines = require("./videoEngines");
var _videoWalls = require("./videoWalls");
var getCalibrationPostLogin = exports.getCalibrationPostLogin = function getCalibrationPostLogin() {
  return {
    id: {
      value: "a0509b2a-5d9e-4d6e-ac1a-6fe9caf22cf2"
    },
    capabilities: {
      capabilities: []
    },
    videoWalls: (0, _videoWalls.getStandaloneVideoWalls)(),
    videoEngines: (0, _videoEngines.getStandadloneVEs)(),
    uclients: (0, _displays.getStandaloneUClients)(),
    zeroClients: (0, _displays.getStandaloneZeroClients)(),
    onboards: (0, _displays.getStandaloneOnboards)()
  };
};
var getLauncherPostLogin = exports.getLauncherPostLogin = function getLauncherPostLogin() {
  return {
    id: {
      value: "616d7a88-c61c-4ae0-9283-956af3507b7e"
    },
    capabilities: (0, _store.getStandaloneDataStore)().userData.capabilities,
    videoEngines: (0, _videoEngines.getStandadloneVEs)()
  };
};
var getControlRoomPostLogin = exports.getControlRoomPostLogin = function getControlRoomPostLogin() {
  return {
    id: {
      value: "616d7a88-c61c-4ae0-9283-956af3507b7e"
    },
    controlRooms: (0, _controlRoom.getStandaloneControlRoomInitData)()
  };
};
var getConfigPostLogin = exports.getConfigPostLogin = function getConfigPostLogin() {
  return {
    id: {
      value: "a0509b2a-5d9e-4d6e-ac1a-6fe9caf22cf2"
    },
    capabilities: {
      capabilities: []
    },
    videoEngines: (0, _videoEngines.getStandadloneVEs)(),
    uclients: (0, _displays.getStandaloneUClients)(),
    zeroClients: (0, _displays.getStandaloneZeroClients)()
  };
};
var getSapphirePostLogin = exports.getSapphirePostLogin = function getSapphirePostLogin() {
  return {
    id: {
      value: "616d7a88-c61c-4ae0-9283-956af3507b7e"
    },
    capabilities: (0, _store.getStandaloneDataStore)().userData.capabilities,
    videoEngines: (0, _videoEngines.getStandadloneVEs)(),
    sapphirePortalBaseURL: "http://localhost"
  };
};