import './dropdownItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface IProps {
    text: string;
    icon: IconProp;
}

export default function DropdownItem(props: IProps) {

    return (
        <div className="dropdownItemWhole">
            <div className="iconDiv">
                <FontAwesomeIcon icon={props.icon} className="iconDropdown" />
            </div>

            <div className="textDiv">
                {props.text}
            </div>
        </div>
    );

}