import { configureStore } from '@reduxjs/toolkit'
import React from 'react'
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux'
import choppedAssetSlice from './choppedAssetSlice'

export const ChoppedAssetContext = React.createContext(null);

const choppedAssetStore = configureStore({
  reducer: {
    choppedAssetStore: choppedAssetSlice,
  },
})
export default choppedAssetStore;

export type State = ReturnType<typeof choppedAssetStore.getState>;
export type Dispatch = typeof choppedAssetStore.dispatch;
export const useChoppedAssetSelector: TypedUseSelectorHook<State> = createSelectorHook(ChoppedAssetContext);
export const useChoppedAssetDispatch = createDispatchHook<Dispatch>(ChoppedAssetContext)
