import { RequestMessageDefinition, RespondMessageDefinition } from "userful-chronos-app-common-js/dist/message/messageModel";

const SAM_ASSET = 4000;
const SAM_ASSET_QUERY = 4001;
const SAM_PLAYLIST_DEF = 4002;
const SAM_PLAYLIST_DEF_QUERY = 4003;
const SAM_SOURCE_DEF = 4004;
const SAM_SOURCE_DEF_QUERY = 4005;
const SAM_SOURCE_SPEC = 4006;

//assets
export const REQUEST_QUERY_SOURCE_ASSETS = new RequestMessageDefinition(SAM_ASSET_QUERY, 0);
export const RESPONSE_QUERY_SOURCE_ASSETS = new RespondMessageDefinition(SAM_ASSET_QUERY, 0);

export const REQUEST_LIST_SOURCE_ASSETS = new RequestMessageDefinition(SAM_ASSET, 0);
export const REQUEST_CREATE_SOURCE_ASSET = new RequestMessageDefinition(SAM_ASSET, 1);
export const REQUEST_UPDATE_SOURCE_ASSET = new RequestMessageDefinition(SAM_ASSET, 2);
export const REQUEST_REMOVE_SOURCE_ASSET = new RequestMessageDefinition(SAM_ASSET, 3);
export const RESPONSE_LIST_SOURCE_ASSETS = new RespondMessageDefinition(SAM_ASSET, 0);
export const NOTIFY_ADD_SOURCE_ASSET = new RespondMessageDefinition(SAM_ASSET, 1);
export const NOTIFY_UPDATE_SOURCE_ASSET = new RespondMessageDefinition(SAM_ASSET, 2);
export const NOTIFY_REMOVE_SOURCE_ASSET = new RespondMessageDefinition(SAM_ASSET, 3);

//playlists
export const REQUEST_QUERY_PLAYLIST_DEFS = new RequestMessageDefinition(SAM_PLAYLIST_DEF_QUERY, 0);
export const RESPONSE_QUERY_PLAYLIST_DEFS = new RespondMessageDefinition(SAM_PLAYLIST_DEF_QUERY, 0);

export const REQUEST_LIST_PLAYLIST_DEFS = new RequestMessageDefinition(SAM_PLAYLIST_DEF, 0);
export const REQUEST_CREATE_PLAYLIST_DEF = new RequestMessageDefinition(SAM_PLAYLIST_DEF, 1);
export const REQUEST_UPDATE_PLAYLIST_DEF = new RequestMessageDefinition(SAM_PLAYLIST_DEF, 2);
export const REQUEST_REMOVE_PLAYLIST_DEF = new RequestMessageDefinition(SAM_PLAYLIST_DEF, 3);
export const REQUEST_FIND_PLAYLIST_DEF = new RequestMessageDefinition(SAM_PLAYLIST_DEF, 4);
export const REQUEST_FORCE_UPDATE_PLAYLIST_DEF = new RequestMessageDefinition(SAM_PLAYLIST_DEF, 5);
export const REQUEST_FORCE_REMOVE_PLAYLIST_DEF = new RequestMessageDefinition(SAM_PLAYLIST_DEF, 6);

export const RESPONSE_LIST_PLAYLIST_DEFS = new RespondMessageDefinition(SAM_PLAYLIST_DEF, 0);
export const RESPONSE_FIND_PLAYLIST_DEF = new RespondMessageDefinition(SAM_PLAYLIST_DEF, 1);
export const NOTIFY_ADD_PLAYLIST_DEF = new RespondMessageDefinition(SAM_PLAYLIST_DEF, 2);
export const NOTIFY_UPDATE_PLAYLIST_DEF = new RespondMessageDefinition(SAM_PLAYLIST_DEF, 3);
export const NOTIFY_REMOVE_PLAYLIST_DEF = new RespondMessageDefinition(SAM_PLAYLIST_DEF, 4);
export const RESPONSE_UPDATE_PLAYLIST_DEF = new RespondMessageDefinition(SAM_PLAYLIST_DEF, 5);
export const RESPONSE_REMOVE_PLAYLIST_DEF = new RespondMessageDefinition(SAM_PLAYLIST_DEF, 6);

//source
export const REQUEST_QUERY_SOURCE_DEFS = new RequestMessageDefinition(SAM_SOURCE_DEF_QUERY, 0);
export const RESPONSE_QUERY_SOURCE_DEFS = new RespondMessageDefinition(SAM_SOURCE_DEF_QUERY, 0);

export const REQUEST_LIST_SOURCE_DEFS = new RequestMessageDefinition(SAM_SOURCE_DEF, 0);
export const REQUEST_CREATE_SOURCE_DEF = new RequestMessageDefinition(SAM_SOURCE_DEF, 1);
export const REQUEST_UPDATE_SOURCE_DEF = new RequestMessageDefinition(SAM_SOURCE_DEF, 2);
export const REQUEST_REMOVE_SOURCE_DEF = new RequestMessageDefinition(SAM_SOURCE_DEF, 3);
export const REQUEST_FIND_SOURCE_DEF = new RequestMessageDefinition(SAM_SOURCE_DEF, 4);
export const REQUEST_FORCE_UPDATE_SOURCE_DEF = new RequestMessageDefinition(SAM_SOURCE_DEF, 5);
export const REQUEST_FORCE_REMOVE_SOURCE_DEF = new RequestMessageDefinition(SAM_SOURCE_DEF, 6);
export const RESPONSE_LIST_SOURCE_DEFS = new RespondMessageDefinition(SAM_SOURCE_DEF, 0);
export const RESPONSE_FIND_SOURCE_DEF = new RespondMessageDefinition(SAM_SOURCE_DEF, 1);
export const NOTIFY_ADD_SOURCE_DEF = new RespondMessageDefinition(SAM_SOURCE_DEF, 2);
export const NOTIFY_UPDATE_SOURCE_DEF = new RespondMessageDefinition(SAM_SOURCE_DEF, 3);
export const NOTIFY_REMOVE_SOURCE_DEF = new RespondMessageDefinition(SAM_SOURCE_DEF, 4);
export const RESPONSE_UPDATE_SOURCE_DEF = new RespondMessageDefinition(SAM_SOURCE_DEF, 5);
export const RESPONSE_REMOVE_SOURCE_DEF = new RespondMessageDefinition(SAM_SOURCE_DEF, 6);

export const REQUEST_LIST_SOURCE_SPECS = new RequestMessageDefinition(SAM_SOURCE_SPEC, 0);
export const RESPONSE_LIST_SOURCE_SPECS = new RespondMessageDefinition(SAM_SOURCE_SPEC, 0);
export const NOTIFY_UPDATE_SOURCE_SPEC = new RespondMessageDefinition(SAM_SOURCE_SPEC, 1);
