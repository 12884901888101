import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    MegapixelControllerHeader,
    OmnisDisplayAll,
    OmnisDisplayBlackout,
    OmnisDisplayBrightness,
    OmnisDisplayFreeze,
    OmnisDisplayGamma,
    OmnisDisplayGammaMax,
    OmnisDisplayGammaMin,
} from "userful-chronos-app-common-js/dist/models/integration/megapixel";
import {
    REQUEST_LIST_OMNIS_CONTROLLERS,
    REQUEST_ADD_OMNIS_CONTROLLER,
    REQUEST_UPDATE_OMNIS_CONTROLLER,
    REQUEST_REMOVE_OMNIS_CONTROLLER,
    REQUEST_SET_OMNIS_DISPLAY,
    REQUEST_SET_OMNIS_DISPLAY_BRIGHTNESS,
    REQUEST_SET_OMNIS_DISPLAY_GAMMAMIN,
    REQUEST_SET_OMNIS_DISPLAY_GAMMA,
    REQUEST_SET_OMNIS_DISPLAY_GAMMAMAX,
    REQUEST_SET_OMNIS_DISPLAY_BLACKOUT,
    REQUEST_SET_OMNIS_DISPLAY_FREEZE,
    REQUEST_REBOOT_OMNIS_CONTROLLER,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/megapixelMsgDefinitions";

// Controller messages:
export const requestListOmnisControllers = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_OMNIS_CONTROLLERS);
};
export const requestAddOmnisController = (header: MegapixelControllerHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_OMNIS_CONTROLLER, header);
};
export const requestUpdateOmnisController = (header: MegapixelControllerHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_OMNIS_CONTROLLER, header);
};
export const requestRemoveOmnisController = (id: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_OMNIS_CONTROLLER, id);
};

// Display messages:
export const requestSetOmnisDisplay = (display: OmnisDisplayAll) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_OMNIS_DISPLAY, display);
};
export const requestSetOmnisDisplayBrightness = (brightness: OmnisDisplayBrightness) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_OMNIS_DISPLAY_BRIGHTNESS, brightness);
};
export const requestSetOmnisDisplayGamaMin = (gamma: OmnisDisplayGammaMin) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_OMNIS_DISPLAY_GAMMAMIN, gamma);
};
export const requestSetOmnisDisplayGamma = (gamma: OmnisDisplayGamma) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_OMNIS_DISPLAY_GAMMA, gamma);
};
export const requestSetOmnisDisplayGammaMax = (gamma: OmnisDisplayGammaMax) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_OMNIS_DISPLAY_GAMMAMAX, gamma);
};
export const requestSetOmnisDisplayBlackout = (blackout: OmnisDisplayBlackout) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_OMNIS_DISPLAY_BLACKOUT, blackout);
};
export const requestSetOmnisDisplayFreeze = (freeze: OmnisDisplayFreeze) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_SET_OMNIS_DISPLAY_FREEZE, freeze);
};
export const requestRebootOmnisController = (id: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REBOOT_OMNIS_CONTROLLER, id);
};
