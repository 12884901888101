import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EpicServerHeader, EpicServerID, EpicServer, LoadState, EpicServerHeaderDefault, 
    PassportFormState, EpicForm, initEpicForm } from 'userful-chronos-app-common-js/dist/models/integration/epic';
import {
    requestListServers, requestAddServer, requestUpdateServer, requestRemoveServer, requestListPassports, requestAddPassport, requestUpdatePassport,
    requestRemovePassport, requestSearchParams
} from './msgs/epicMsgSender';
import {EpicADTPassport, EpicADTPassportID, EpicADTPassportHeader, SearchParamID} from 'userful-chronos-app-common-js/dist/models/integration/epicADT';

const initialState: {
    epicServers: EpicServer[];
    epicADTPassports: EpicADTPassport[];
    loadState: LoadState;
    passport: EpicForm;
    copyPassport: EpicForm;
    formType: PassportFormState;
    openForm: boolean;
    searchParams: string;
} = {
    epicServers: [],
    epicADTPassports: [],
    loadState: 'RENDER',
    passport: initEpicForm(),
    copyPassport: initEpicForm(),
    formType: 'Create',
    openForm: false,
    searchParams: ""
};

export const epicSlice = createSlice({
    name: 'epicSlice',
    initialState,
    reducers: {

        setServers: (state, action: PayloadAction<EpicServer[]>) => { // wait time servers
            state.epicServers = action.payload;
        },
        setADTPassports: (state, action: PayloadAction<EpicADTPassport[]>) => { // epic ADT passports
            state.epicADTPassports = action.payload;
        },
        addOrUpdateServer: (state, action: PayloadAction<EpicServer>) => { // wait time servers
            const foundIndex = state.epicServers.findIndex(item => item.id.value === action.payload.id.value);
            if (foundIndex < 0) {
                state.epicServers = [
                    ...state.epicServers,
                    { ...action.payload },
                ];
            } else {
                state.epicServers = [
                    ...state.epicServers.slice(0, foundIndex),
                    { ...action.payload },
                    ...state.epicServers.slice(foundIndex + 1),
                ];
            }
        },
        addOrUpdateADTPassport: (state, action: PayloadAction<EpicADTPassport>) => { // epic ADT passports
            const foundIndex = state.epicADTPassports.findIndex(item => item.id.value === action.payload.id.value);
            if (foundIndex < 0) {
                state.epicADTPassports = [
                    ...state.epicADTPassports,
                    { ...action.payload },
                ];
            } else {
                state.epicADTPassports = [
                    ...state.epicADTPassports.slice(0, foundIndex),
                    { ...action.payload },
                    ...state.epicADTPassports.slice(foundIndex + 1),
                ];
            }
        },
        removeServer: (state, action: PayloadAction<EpicServerID>) => { // wait time
            state.epicServers = state.epicServers.filter(item => item.id.value !== action.payload.value);
        },
        removePassport: (state, action: PayloadAction<EpicADTPassportID>) => { // ADT Passport
            state.epicADTPassports = state.epicADTPassports.filter(item => item.id.value !== action.payload.value);
        },
        getServersFromServer: (state, action: PayloadAction<{}>) => { // wait time
            requestListServers();
        },
        getPassportsFromServer: (state, action: PayloadAction<{}>) => { // adt
            requestListPassports();
        },
        addServerToServer: (state, action: PayloadAction<EpicServerHeader>) => { // wait time server
            requestAddServer(action.payload);
        },
        addPassportToServer: (state, action: PayloadAction<EpicADTPassportHeader>) => { // add adt passport
            requestAddPassport(action.payload);
        },
        updateServerToServer: (state, action: PayloadAction<EpicServerHeader>) => { // update wait time
            requestUpdateServer(action.payload);
        },
        updatePassportToServer: (state, action: PayloadAction<EpicADTPassportHeader>) => { // update adt passport
            requestUpdatePassport(action.payload);
        },
        removeServerToServer: (state, action: PayloadAction<EpicServerID>) => { // remove wait time 
            requestRemoveServer(action.payload);
        },
        removePassportToServer: (state, action: PayloadAction<EpicADTPassportID>) => { // remove wait time 
            requestRemovePassport(action.payload);
        },
        getSearchParamsFromServer: (state, action: PayloadAction<{}>) => { // adt get search params
            requestSearchParams();
        },
        changeLoadState: (state, action: PayloadAction<LoadState>) => {
            state.loadState = action.payload;
        },
        setPassport: (state, action: PayloadAction<EpicForm>) => {
            state.passport = action.payload;
        },
        setCopyPassport: (state, action: PayloadAction<EpicForm>) => {
            state.copyPassport = action.payload;
        },
        setFormType: (state, action: PayloadAction<PassportFormState>) => {
            state.formType = action.payload;
        },
        setOpenForm: (state, action: PayloadAction<boolean>) => {
            state.openForm = action.payload;
        },
        setSearchParams: (state, action: PayloadAction<string>) => {
            state.searchParams = action.payload;
            state.passport = {...state.passport, instanceID: action.payload};
            state.copyPassport = {...state.copyPassport, instanceID: action.payload};

        },
    },
})

export const epicActions = epicSlice.actions;

export default epicSlice.reducer

