import React, { ReactElement, ReactNode } from "react";
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from "react-bootstrap";

interface IProps {
    title: ReactNode;
    children: ReactElement;
    onMouseEnter?: (e) => void;
    onMouseLeave?: (e) => void;
}

export default function UserfulTooltip({
    title,
    children,
    onMouseEnter,
    onMouseLeave,
    ...props
}: IProps & Omit<OverlayTriggerProps, "overlay" | "children">) {
    if (!title) {
        return children;
    }

    return (
        <OverlayTrigger
            placement="top"
            {...props}
            overlay={
                <Tooltip onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
                    {title}
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    );
}