import { faAngleDown, faMultiply } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { KeycloakGroup, KeycloakUser } from "../../../model/CarbonExternalModels";
import { useCarbonSelector, selectUserGroups } from "../../../store/carbonStore";
import { Button, UserfulTooltip } from "userful-chronos-common-ui/dist";
import { faUserGroup } from "@fortawesome/pro-solid-svg-icons";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

interface IProps {
    user: KeycloakUser;
    onChange(data): void;
}

export default function UserGroupMultiselect(props: IProps) {
    const userGroups = useCarbonSelector(selectUserGroups);

    const allGroups: KeycloakGroup[] = [];
    for (const property in userGroups) {
        allGroups.push(userGroups[property].group);
    }

    const toggleSelect = (id: StringID) => {
        const ids: StringID[] = props.user.groups.map((i) => i);

        const foundIndex = ids.findIndex((i) => i.value === id.value);

        if (foundIndex >= 0) {
            ids.splice(foundIndex, 1);
        } else {
            ids.push(id);
        }

        props.onChange({ groups: ids });
    };

    return (
        <Dropdown bsPrefix="multiselectDropdownWrapper">
            <Dropdown.Toggle bsPrefix="multiselectDropdown" as={Button}>
                <div className="multiselectContainer">
                    {props.user.groups.map((g) => {
                        const group = userGroups[g.value];

                        if (group) {
                            return (
                                <>
                                    <div
                                        key={g.value}
                                        className="tagNameObject tagNameObjectRemove"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {group.group.name}
                                        <UserfulTooltip title={"Remove"}>
                                            <div className="tagRemover" onClick={(e) => toggleSelect(g)}>
                                                <FontAwesomeIcon icon={faMultiply} />
                                            </div>
                                        </UserfulTooltip>
                                    </div>
                                </>
                            );
                        } else return null;
                    })}
                </div>
                <FontAwesomeIcon icon={faAngleDown} />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="multiselectDropdownMenuSwitch"
                onKeyDown={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.stopPropagation()}
            >
                {allGroups.map((c) => {
                    const foundGroup = props.user.groups.find((g) => g.value === c.id.value);


                    if (!foundGroup) {
                        return (
                            <Dropdown.Item
                                key={c.id.value}
                                className="dropdownItem"
                                onClick={() => {
                                    toggleSelect(c.id);
                                }}
                            >
                                <div className="icon-container">
                                    <FontAwesomeIcon className="blue" icon={faUserGroup} />
                                </div>
                                <div className="textInside">{c.name}</div>
                            </Dropdown.Item>
                        );
                    }
                    return null;
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}
