import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './HelpInfo.scss';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { Placement } from 'react-bootstrap/esm/types';

interface IProps {
    title: string;
    className?: string;
    placement?: Placement;
}

export default function HelpInfo(props: IProps) {

    if (!props.title) {
        return null;
    }
    const popover = (
        <Tooltip id={`popover-info-icon`}>
            {props.title}
        </Tooltip>
    );

    return (
        <OverlayTrigger trigger={['hover', 'focus']} overlay={popover} placement={props.placement}>
            <div className={`infoIcon  ${props.className ? props.className : ''}`}>
                <FontAwesomeIcon icon={faCircleInfo} />
            </div>
        </OverlayTrigger>
    );
}