import { faCircleNotch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import VideoThumbnail from 'react-video-thumbnail';
import { SamSourceAsset } from "userful-chronos-app-common-js/dist/models/sam/SAMAsset";
import useBackgroundUtils from "./BackgourndUtils";
import BackgroundDeletion from "./BackgroundDeletion";

interface IProps {
    asset:SamSourceAsset;
    selectedValue: string;
    onSelected: (value:string)=>void;
}

export default function BackGround(props: IProps) {
 
    const [videoThumbnailData, setVideoThumbnailData] = useState();
    const [loaded, setLoaded] = useState(false);


    
    const handleThumbnail = (thumbnail) => {
        setVideoThumbnailData(thumbnail);
        setLoaded(true);
    }

    const {getAssetURL, getAssetFullURL, isAssetVideo} = useBackgroundUtils();
    
    const assetURL = getAssetURL(props.asset);
    const assetFullURL = getAssetFullURL(props.asset);
    const selected:boolean = assetURL === props.selectedValue;
    return (
        <div className={`background ${selected && "selected"}`} onClick={() => props.onSelected(assetURL)}>
            <FontAwesomeIcon icon={faCircleNotch} spin className="loading" style={{display: loaded ? "none" : "block"}}/>
            {isAssetVideo(props.asset) ? 
                <div style={{width: "100%", height: "100%"}}>
                    <div style={{display:"none"}}>
                        <VideoThumbnail
                            videoUrl={assetFullURL}
                            cors={true}
                            snapshotAtTime={3}
                            renderThumbnail={false}
                            thumbnailHandler={handleThumbnail}
                            // width={170}
                            // height={130}
                        />
                    </div>                    
                    <img src={videoThumbnailData} style={{display: loaded ? "block" : "none"}} className="preview" height="128" alt="meeting room background" />
                </div>
            :
                <img src={assetFullURL} style={{display: loaded ? "block" : "none"}} className="preview" height="128" alt="meeting room background" onLoad={() => setLoaded(true)}/>
            }
            <BackgroundDeletion asset={props.asset}/>
        </div>);
}