"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestUpdateMapperDestinationMappingsCollection = exports.requestUpdateMapperDestinationMapping = exports.requestRemoveMapperDestinationMappingsCollection = exports.requestRemoveMapperDestinationMapping = exports.requestListMapperDestinationMappingsCollections = exports.requestListMapperDestinationMapping = exports.requestCreateMapperDestinationMappingsCollection = exports.requestCreateMapperDestinationMapping = exports.requestApplyMapperDestinationMappingsCollection = exports.requestApplyMapperDestinationMapping = void 0;
var _globalStates = require("../../../globalstates/globalStates");
var _DestinationsMappingMsgDefinitions = require("./DestinationsMappingMsgDefinitions");
var requestListMapperDestinationMapping = exports.requestListMapperDestinationMapping = function requestListMapperDestinationMapping() {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_DestinationsMappingMsgDefinitions.REQUEST_LIST_MAPPER_DESTINATION_MAPPINGS);
};
var requestCreateMapperDestinationMapping = exports.requestCreateMapperDestinationMapping = function requestCreateMapperDestinationMapping(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_DestinationsMappingMsgDefinitions.REQUEST_CREATE_MAPPER_DESTINATION_MAPPING, data);
};
var requestRemoveMapperDestinationMapping = exports.requestRemoveMapperDestinationMapping = function requestRemoveMapperDestinationMapping(id) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_DestinationsMappingMsgDefinitions.REQUEST_REMOVE_MAPPER_DESTINATION_MAPPING, id);
};
var requestUpdateMapperDestinationMapping = exports.requestUpdateMapperDestinationMapping = function requestUpdateMapperDestinationMapping(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_DestinationsMappingMsgDefinitions.REQUEST_UPDATE_MAPPER_DESTINATION_MAPPING, data);
};
var requestApplyMapperDestinationMapping = exports.requestApplyMapperDestinationMapping = function requestApplyMapperDestinationMapping(id) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_DestinationsMappingMsgDefinitions.REQUEST_APPLY_MAPPING_DESTINATION_MAPPING, id);
};
var requestListMapperDestinationMappingsCollections = exports.requestListMapperDestinationMappingsCollections = function requestListMapperDestinationMappingsCollections() {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_DestinationsMappingMsgDefinitions.REQUEST_LIST_MAPPER_DESTINATION_MAPPINGS_COLLECTIONS);
};
var requestCreateMapperDestinationMappingsCollection = exports.requestCreateMapperDestinationMappingsCollection = function requestCreateMapperDestinationMappingsCollection(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_DestinationsMappingMsgDefinitions.REQUEST_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, data);
};
var requestRemoveMapperDestinationMappingsCollection = exports.requestRemoveMapperDestinationMappingsCollection = function requestRemoveMapperDestinationMappingsCollection(id) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_DestinationsMappingMsgDefinitions.REQUEST_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, id);
};
var requestUpdateMapperDestinationMappingsCollection = exports.requestUpdateMapperDestinationMappingsCollection = function requestUpdateMapperDestinationMappingsCollection(data) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_DestinationsMappingMsgDefinitions.REQUEST_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, data);
};
var requestApplyMapperDestinationMappingsCollection = exports.requestApplyMapperDestinationMappingsCollection = function requestApplyMapperDestinationMappingsCollection(id) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_DestinationsMappingMsgDefinitions.REQUEST_APPLY_MAPPING_DESTINATION_MAPPINGS_COLLECTION, id);
};