import {
    registerErrorMsgHandlerByMsgDefinition,
    registerMsgHandler,
} from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { APP_IDS, StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    AppInstanceID,
    MapperCompDestinationsUpdate,
    MapperCompHeaderUpdate,
    MapperCompInsetsUpdateResponse,
    MapperCompLogicalGridUpdate,
    MixerMapperComp,
    MapperCompLatencyUpdate,
    MapperSettingsUpdate,
} from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";
import { setReceivedMapper } from "../../InitialDataStates";
import { mappingGroupsSliceActions } from "../mappingGroupsSlice";
import mappingStore from "../mappingStore";
import {
    NOTIFY_CREATE_MAPPER,
    NOTIFY_REMOVE_MAPPER,
    NOTIFY_UPDATE_LOGICAL_GRID,
    NOTIFY_UPDATE_MAPPER,
    NOTIFY_UPDATE_MAPPER_DESTINATIONS,
    NOTIFY_UPDATE_MAPPER_HEADER,
    NOTIFY_UPDATE_MAPPER_INSETS,
    RESPONSE_LIST_MAPPERS,
    NOTIFY_UPDATE_LATENCY,
    NOTIFY_EDGE_MAPPER_INSET_UPDATED,
    NOTIFY_UPDATE_EDGE_MAPPER_DESTINATIONS,
    NOTIFY_UPDATE_MAPPER_SETTINGS,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/mapping/MappingMsgDefinations";

let appID = null;
export const setAppIDForMappingReceiver = (appIDValue: string) => {
    appID = appIDValue;
};

const RESTRICTED_APPIDS = [
    APP_IDS.Artistic,
    APP_IDS.Decisions,
    APP_IDS.Engage,
    APP_IDS.Remote,
    APP_IDS.Spaces,
    APP_IDS.Trends,
    APP_IDS.Switch,
];

const appIDMatch = (instanceID: AppInstanceID) =>
    instanceID.appID.value === appID || !RESTRICTED_APPIDS.includes(instanceID.appID.value);

registerMsgHandler(RESPONSE_LIST_MAPPERS, (payload) => {
    setReceivedMapper();
    console.debug("receive RESPONSE_LIST_MAPPERS " + appID);
    const data = JSON.parse(payload) as MixerMapperComp[];
    mappingStore.dispatch(
        mappingGroupsSliceActions.setMappingGroups(appID ? data.filter((item) => appIDMatch(item.appInstanceID)) : data)
    );
});

registerMsgHandler(NOTIFY_CREATE_MAPPER, (payload) => {
    console.debug("receive NOTIFY_CREATE_MAPPER");
    const data = JSON.parse(payload) as MixerMapperComp;
    if (appID && !appIDMatch(data.appInstanceID)) {
        return;
    }
    mappingStore.dispatch(mappingGroupsSliceActions.addMappingGroup(data));
});

registerMsgHandler(NOTIFY_UPDATE_MAPPER, (payload) => {
    console.debug("receive NOTIFY_UPDATE_MAPPER");
    const data = JSON.parse(payload) as MixerMapperComp;
    if (appID && !appIDMatch(data.appInstanceID)) {
        return;
    }
    mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroup(data));
});

registerMsgHandler(NOTIFY_UPDATE_MAPPER_HEADER, (payload) => {
    console.debug("receive NOTIFY_UPDATE_MAPPER_HEADER");
    const data = JSON.parse(payload) as MapperCompHeaderUpdate;
    mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroupHeader(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_INSETS, (payload) => {
    console.debug("receive NOTIFY_UPDATE_MAPPER_INSETS");
    const data = JSON.parse(payload) as MapperCompInsetsUpdateResponse;
    mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroupInsetIDs(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_DESTINATIONS, (payload) => {
    console.debug("receive NOTIFY_UPDATE_MAPPER_DESTINATIONS");
    const data = JSON.parse(payload) as MapperCompDestinationsUpdate;
    mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroupDestinations(data));
    mappingStore.dispatch(mappingGroupsSliceActions.removeBusyDestinations(data));
});
registerMsgHandler(NOTIFY_UPDATE_EDGE_MAPPER_DESTINATIONS, (payload) => {
    console.debug("receive NOTIFY_UPDATE_EDGE_MAPPER_DESTINATIONS");
    const data = JSON.parse(payload) as MapperCompDestinationsUpdate;
    mappingStore.dispatch(mappingGroupsSliceActions.removeBusyDestinations(data));
});

registerMsgHandler(NOTIFY_UPDATE_LOGICAL_GRID, (payload) => {
    console.debug("receive NOTIFY_UPDATE_LOGICAL_GRID");
    const data = JSON.parse(payload) as MapperCompLogicalGridUpdate;
    mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroupLogicalGrid(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_SETTINGS, (payload) => {
    console.debug("receive NOTIFY_UPDATE_MAPPER_SETTINGS");
    const data = JSON.parse(payload) as MapperSettingsUpdate;
    mappingStore.dispatch(mappingGroupsSliceActions.resetPendingDest());
    mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroupSettings(data));
});
registerMsgHandler(NOTIFY_UPDATE_LATENCY, (payload) => {
    console.debug("receive  NOTIFY_UPDATE_LATENCY");
    const data = JSON.parse(payload) as MapperCompLatencyUpdate;
    mappingStore.dispatch(mappingGroupsSliceActions.updateMappingGroupLatency(data));
});

registerMsgHandler(NOTIFY_REMOVE_MAPPER, (payload) => {
    console.debug("receive NOTIFY_REMOVE_MAPPER");
    const data = JSON.parse(payload) as StringID;
    mappingStore.dispatch(mappingGroupsSliceActions.removeMappingGroup(data));
});

registerErrorMsgHandlerByMsgDefinition(NOTIFY_UPDATE_MAPPER_INSETS, (data) => {
    mappingStore.dispatch(mappingGroupsSliceActions.resetPendingInset());
});

registerErrorMsgHandlerByMsgDefinition(NOTIFY_EDGE_MAPPER_INSET_UPDATED, (data) => {
    mappingStore.dispatch(mappingGroupsSliceActions.resetPendingInset());
});

registerErrorMsgHandlerByMsgDefinition(NOTIFY_UPDATE_EDGE_MAPPER_DESTINATIONS, (data) => {
    mappingStore.dispatch(mappingGroupsSliceActions.resetPendingDest());
});
registerErrorMsgHandlerByMsgDefinition(NOTIFY_UPDATE_MAPPER_DESTINATIONS, (data) => {
    mappingStore.dispatch(mappingGroupsSliceActions.resetPendingDest());
});

