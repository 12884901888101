import { AxiosResponse } from 'axios';
import { apiGet, apiPost, apiDelete, apiPut, getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import { GET_SAML_INTEGRATIONS, PUT_SAML_INTEGRATIONS, POST_SAML_INTEGRATIONS, DELETE_SAML_INTEGRATIONS, GET_SYNC_USERS, GET_KEYCLOAK_STATUS } from './samlPath';
import usermgtStore from '../usermgtStore';
import { usermgtSliceActions } from '../usermgtSlice';
import { samlSliceActions } from '../samlSlice';
import {
    SamlIdentityProvider, UpdateSaml, DeleteSaml,
    AddSaml, ResponseCreateSamlIdentityProvider, SyncSaml, SamlProvider
} from 'userful-chronos-app-common-js/dist/models/usermgt/saml';
import { toast } from "react-toastify";
import { ConfirmationToastContentStore } from '../../../Container/ConfirmationToastContentStore';
import { KeycloakConfigInfo } from 'userful-chronos-app-common-js/dist/models/preLoginData';

const errorHandler = (err) => {
    console.debug("Error calling init app api ");
    console.debug(err);
}

const getError = (response: any) => {
    return ({
        success: false,
        arg: response.data.arg ? response.data.arg : "",
        errorCode: response.data.errorCode ? response.data.errorCode : "",
        moduleID: response.data.moduleID ? response.data.moduleID : "",
        severity: response.data.severity ? response.data.severity : '',
        msg: response.data.unlocalizedMsg ? response.data.unlocalizedMsg : ""
    }
    );
}


export const checkReponseIsValid = (name: string, response: AxiosResponse): boolean => {
    if (response.status >= 400) {
        console.log(`${name} error: ${response.status}`);
        return false;
    }
    if (!response.data || response.data.severity !== "SUCCESS") {
        console.log(`${name} empty response`);
        return false;
    }
    return true;
}

export const getSAMLDataFromServer = (token: string) => {

    const t1 = getGlobalStates().host + GET_SAML_INTEGRATIONS;

    apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(samlSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(samlSliceActions.setSAMLData(response.data.arg));
        usermgtStore.dispatch(samlSliceActions.setLoadBool(false));
        usermgtStore.dispatch(samlSliceActions.setSaveBool(false));
        console.debug(`receive authorize`, response.data);
    }).catch(() => console.warn("failed"));
}

export const getFailoverStatusFromServer = (token: string) => {

    const t1 = getGlobalStates().host + GET_KEYCLOAK_STATUS;

    apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            return;
        }
        const data: KeycloakConfigInfo = response.data.arg;
        usermgtStore.dispatch(samlSliceActions.setFailoverStatus(data.failoverNode));
        console.debug(`receive authorize`, response.data);
    }).catch(() => console.warn("failed"));
}

export const addSAMLDataToServer = (token: string, data: AddSaml) => {

    const t1 = getGlobalStates().host + PUT_SAML_INTEGRATIONS;

    apiPut(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('configure SAML ', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(samlSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(samlSliceActions.addSAMLData(response.data.arg));
        usermgtStore.dispatch(samlSliceActions.setLoadBool(false));
        usermgtStore.dispatch(samlSliceActions.setOverlay(false));
        usermgtStore.dispatch(samlSliceActions.setSaveBool(false));
        console.debug(`configure SAML `, response.data);
        const updatedData = (response.data.arg as SamlProvider);
        const displayName = updatedData.samlConfig.filter((config) => config.id.value === updatedData.id.value)[0].displayName;
        const customDisplay = ConfirmationToastContentStore(displayName, "Save");
        toast(customDisplay, { containerId: 'confirmationContainer' });
        // for displaying if there is error while syncing users
        const syncData = response.data.arg;
        const syncResult = syncData.status.error === true ? syncData.status.message : null;
        if (syncResult) {
            const displayError = ConfirmationToastContentStore(syncData.provider.alias, "Custom", syncResult);
            toast(displayError, { containerId: 'confirmationContainer' });
        }
    }).catch(() => console.warn("failed"));
}

export const updateSAMLDataToServer = (token: string, data: UpdateSaml) => {

    const t1 = getGlobalStates().host + POST_SAML_INTEGRATIONS;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('update SAML ', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(samlSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(samlSliceActions.addSAMLData(response.data.arg));
        usermgtStore.dispatch(samlSliceActions.setLoadBool(false));
        usermgtStore.dispatch(samlSliceActions.setOverlay(false));
        usermgtStore.dispatch(samlSliceActions.setSaveBool(false));
        console.debug(`update SAML`, response.data.arg);
        const customDisplay = ConfirmationToastContentStore(data.displayName, "Edit");
        toast(customDisplay, { containerId: 'confirmationContainer' });
        // for displaying if there is error while syncing users
        const syncData = response.data.arg;
        const syncResult = syncData.status.error === true ? syncData.status.message : null;
        if (syncResult) {
            const displayError = ConfirmationToastContentStore(syncData.provider.alias, "Custom", syncResult);
            toast(displayError, { containerId: 'confirmationContainer' });
        }
    }).catch(() => console.warn("failed"));
}

export const deleteSAMLDataToServer = (token: string, data: DeleteSaml) => {

    const t1 = getGlobalStates().host + DELETE_SAML_INTEGRATIONS;

    apiDelete(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        data: data
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(samlSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(samlSliceActions.removeSAMLData(data));
        usermgtStore.dispatch(samlSliceActions.setLoadBool(false));
        usermgtStore.dispatch(samlSliceActions.setSaveBool(false));
        console.debug(`receive authorize`, response.data);
    }).catch(() => console.warn("failed"));
}

export const syncSAMLDataToServer = (token: string, data: SyncSaml) => {

    const t1 = getGlobalStates().host + GET_SYNC_USERS;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(samlSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(samlSliceActions.addSAMLData(response.data.arg));
        usermgtStore.dispatch(samlSliceActions.setLoadBool(false));
        usermgtStore.dispatch(samlSliceActions.setSaveBool(false));
        usermgtStore.dispatch(samlSliceActions.setOverlay(false));
        console.debug(`receive authorize`, response.data);
        // for displaying if there is error while syncing users
        const syncData = response.data.arg;
        const syncResult = syncData.status.error === true ? syncData.status.message : null;
        if (syncResult) {
            const displayError = ConfirmationToastContentStore(syncData.provider.alias, "Custom", syncResult);
            toast(displayError, { containerId: 'confirmationContainer' });
        }
    }).catch(() => console.warn("failed"));

    
}