"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPOND_UPDATE_PASSPORT_WITH_OAUTH = exports.RESPOND_UPDATE_PASSPORT = exports.RESPOND_REMOVE_PASSPORT = exports.RESPOND_LIST_WORKSPACE = exports.RESPOND_LIST_REPORT = exports.RESPOND_LIST_PASSPORTS = exports.RESPOND_CODE_CHALLENGE = exports.RESPOND_ADD_PASSPORT = exports.REQUEST_UPDATE_PASSPORT_WITH_OAUTH = exports.REQUEST_UPDATE_PASSPORT = exports.REQUEST_REMOVE_PASSPORT = exports.REQUEST_LIST_WORKSPACE = exports.REQUEST_LIST_REPORT = exports.REQUEST_LIST_PASSPORTS = exports.REQUEST_CODE_CHALLENGE = exports.REQUEST_ADD_PASSPORT = exports.NOTIFY_PASSPORT_UPDATE = void 0;
var _messageModel = require("../../messageModel");
var POWERBI_OFFSET = 6200;
var POWERBI_UTILS = 6201;
var POWERBI_DATA = 6202;
var REQUEST_LIST_PASSPORTS = exports.REQUEST_LIST_PASSPORTS = new _messageModel.RequestMessageDefinition(POWERBI_OFFSET, 0);
var REQUEST_ADD_PASSPORT = exports.REQUEST_ADD_PASSPORT = new _messageModel.RequestMessageDefinition(POWERBI_OFFSET, 1);
var REQUEST_UPDATE_PASSPORT = exports.REQUEST_UPDATE_PASSPORT = new _messageModel.RequestMessageDefinition(POWERBI_OFFSET, 2);
var REQUEST_UPDATE_PASSPORT_WITH_OAUTH = exports.REQUEST_UPDATE_PASSPORT_WITH_OAUTH = new _messageModel.RequestMessageDefinition(POWERBI_OFFSET, 3);
var REQUEST_REMOVE_PASSPORT = exports.REQUEST_REMOVE_PASSPORT = new _messageModel.RequestMessageDefinition(POWERBI_OFFSET, 4);
var RESPOND_LIST_PASSPORTS = exports.RESPOND_LIST_PASSPORTS = new _messageModel.RespondMessageDefinition(POWERBI_OFFSET, 0);
var RESPOND_ADD_PASSPORT = exports.RESPOND_ADD_PASSPORT = new _messageModel.RespondMessageDefinition(POWERBI_OFFSET, 1);
var RESPOND_UPDATE_PASSPORT = exports.RESPOND_UPDATE_PASSPORT = new _messageModel.RespondMessageDefinition(POWERBI_OFFSET, 2);
var RESPOND_UPDATE_PASSPORT_WITH_OAUTH = exports.RESPOND_UPDATE_PASSPORT_WITH_OAUTH = new _messageModel.RespondMessageDefinition(POWERBI_OFFSET, 3);
var RESPOND_REMOVE_PASSPORT = exports.RESPOND_REMOVE_PASSPORT = new _messageModel.RespondMessageDefinition(POWERBI_OFFSET, 4);
var NOTIFY_PASSPORT_UPDATE = exports.NOTIFY_PASSPORT_UPDATE = new _messageModel.RespondMessageDefinition(POWERBI_OFFSET, 5);
var REQUEST_CODE_CHALLENGE = exports.REQUEST_CODE_CHALLENGE = new _messageModel.RequestMessageDefinition(POWERBI_UTILS, 0);
var RESPOND_CODE_CHALLENGE = exports.RESPOND_CODE_CHALLENGE = new _messageModel.RespondMessageDefinition(POWERBI_UTILS, 0);
var REQUEST_LIST_WORKSPACE = exports.REQUEST_LIST_WORKSPACE = new _messageModel.RequestMessageDefinition(POWERBI_DATA, 0);
var RESPOND_LIST_WORKSPACE = exports.RESPOND_LIST_WORKSPACE = new _messageModel.RespondMessageDefinition(POWERBI_DATA, 0);
var REQUEST_LIST_REPORT = exports.REQUEST_LIST_REPORT = new _messageModel.RequestMessageDefinition(POWERBI_DATA, 1);
var RESPOND_LIST_REPORT = exports.RESPOND_LIST_REPORT = new _messageModel.RespondMessageDefinition(POWERBI_DATA, 1);