import { TFunction } from "react-i18next";
import i18next from 'i18next';
import { SamSourceAssetIDParam } from "userful-chronos-app-common-js/dist/models/sam/SAMAsset";
import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { SamSourceSpec } from "userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec";
import { SourceDefParam, SourceSpec, SourceSpecParam } from "userful-chronos-app-common-js/dist/models/source"
import { trimValue } from '../../Utils/UiUtils';
import { APP_IDS } from "userful-chronos-app-common-js/dist/models/common";
import { ClearanceData, ClearanceLevel } from "userful-chronos-app-common-js/dist/models/usermgt/clearanceLevel";
import { ClearanceLevelID, UserfulSecurityData } from "userful-chronos-app-common-js/dist/models/common";
import { SourceClearanceTable } from 'userful-chronos-app-common-js/dist/models/sam/SAMSource';
import { getBlockedSourceTypesFromApp } from 'userful-chronos-app-common-js/dist/models/sam/AppSourceTypeFilter';
import { EMPTY_USERFUL_SECURITY_DATA } from "userful-chronos-app-common-js/dist/models/common";

export const convertSourceDefToFormData = (sourceDef: SamSourceDef, sourceSpecs: Array<SamSourceSpec>) => {
    let formData = {} as any;
    if (!sourceDef) {
        return formData;
    }
    const selectedSourceSpec = sourceSpecs.find(spec => spec.header.id.value === sourceDef.type.value);
    if (!selectedSourceSpec) {
        return formData;
    }
    selectedSourceSpec.params.forEach(param => {
        if (param.default) {
            formData[param.name] = findDefaultParamValue(param);
        }
    });
    sourceDef.params.forEach(param => {
        if (!handleFileDirSelect(formData, param, selectedSourceSpec)) {
            formData[param.name] = param.value;
        }
    })
    return formData;
}

export const convertSourceDefToAssetsData = (source: SamSourceDef): Array<SamSourceAssetIDParam> => {
    if (!source) {
        return [];
    }
    return source.assets.map(item => ({
        paramName: item.paramName,
        sourceAssets: item.sourceAssets.map(asset => asset.id),
    }))
}

export const findDefaultParamValue = (param: SourceSpecParam) => {
    if (param.default) {
        return param.default;
    }
    if (param.name === 'Icon' && param.options.length > 0) { // special case for icon
        return param.options[0];
    }
    return "";
}

/**
 * special case for fire/dir select
 * @param formData 
 * @param param 
 * @param spec 
 * @returns 
 */
const handleFileDirSelect = (formData, param: SourceDefParam, spec: SourceSpec): boolean => {
    if (!spec) {
        return false;
    }
    const isFileDirSelect = spec.params.find(paramSpec => paramSpec.name === param.name && paramSpec.type === "FILE_DIR_SELECT");
    if (!isFileDirSelect) {
        return false;
    }
    const dir = [];
    const file = [];
    const selectedFileDir = JSON.parse(param.value);
    selectedFileDir.forEach(item => {
        if (item.type === "file") {
            file.push(item.name);
        } else if (item.type === "dir") {
            dir.push({ name: item.name, path: item.value })
        }
    });
    formData[param.name] = { file, dir };
    return true;
}

const lookupTranslation = (paramName: string, spec: SamSourceSpec, t: TFunction, postfix: string): string => {
    const specNameKey = spec.header.id.value.replaceAll('.', '_');
    const paramNameKey = paramName.replaceAll('.', '_').toLowerCase(); // normalize to lower case so we don't have to create duplicate entries
    if (i18next.exists(`SourceSpecs.${specNameKey}.${paramNameKey}${postfix}`)) {
        return t(`SourceSpecs.${specNameKey}.${paramNameKey}${postfix}`)
    }
    if (i18next.exists(`SourceSpecs.shared.${paramNameKey}${postfix}`)) {
        return t(`SourceSpecs.shared.${paramNameKey}${postfix}`)
    }
    console.debug(`Cannot find translation for ${specNameKey}.${paramNameKey}${postfix}`);
    return '';
}

export const getTranslatedParamName = (param: SourceSpecParam, spec: SamSourceSpec, t: TFunction): string => {
    if (!spec || !t || !i18next.isInitialized) {
        return param.name;
    }
    return lookupTranslation(param.name, spec, t, '') || param.name;
}

export const getTranslatedParamNameFromName = (paramName: string, spec: SamSourceSpec, t: TFunction): string => {
    if (!spec || !t || !i18next.isInitialized) {
        return paramName;
    }
    return lookupTranslation(paramName, spec, t, '') || paramName;
}


export const getTranslatedParamRadioOption = (param: SourceSpecParam, spec: SamSourceSpec, t: TFunction, option: string): string => {
    if (!spec || !t || !i18next.isInitialized) {
        return option;
    }
    return lookupTranslation(param.name, spec, t, `_${option}`) || option;
}

export const getTranslatedParamHelp = (param: SourceSpecParam, spec: SamSourceSpec, t: TFunction): string => {
    if (!spec || !t || !i18next.isInitialized) {
        return param.help;
    }
    return lookupTranslation(param.name, spec, t, `_help`) || param.help;
}

export const getTranslatedParamInlineHelp = (param: SourceSpecParam, spec: SamSourceSpec, t: TFunction): string => {
    if (!spec || !t || !i18next.isInitialized) {
        return param.help;
    }
    return lookupTranslation(param.name, spec, t, `_inline_help`) || '';
}

export const getTranslatedParamHelpFromName = (paramName: string, spec: SamSourceSpec, t: TFunction): string => {
    if (!spec || !t || !i18next.isInitialized) {
        return '';
    }
    return lookupTranslation(paramName, spec, t, `_help`) || '';
}

// noTrim is required if user wants to enter space in between words
export const convertFormDataInputToSourceParam = (sourceInput, noTrim?:boolean) => {

    const params = [];
    Object.keys(sourceInput).forEach(key => {

        if (sourceInput[key]?.file || sourceInput[key]?.dir) {
            // special case for file/dir select
            const valueArray = [];
            let index = 0;
            if (sourceInput[key]?.file) {
                sourceInput[key]?.file.forEach(filePath => {
                    valueArray.push({
                        type: "file",
                        name: filePath,
                        value: filePath,
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                        zOrder: index,
                        pixelWidth: 0,
                        pixelHeight: 0,
                    });
                    index++;
                });
            }
            if (sourceInput[key]?.dir) {
                sourceInput[key]?.dir.forEach(dirItem => {
                    valueArray.push({
                        type: "dir",
                        name: dirItem.name,
                        value: dirItem.path,
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                        zOrder: index,
                        pixelWidth: 0,
                        pixelHeight: 0,
                    });
                    index++;
                });
            }
            params.push({
                name: key,
                value: JSON.stringify(valueArray),
                type: "STRING",
            })
        }

        else {
            params.push({
                name: key,
                value: noTrim ? sourceInput[key] : trimValue(`${sourceInput[key]}`),
                type: "STRING",
            })
        }
    });
    return params;
}

export const RESTRICTED_SOURCE_SPECS = [
    // do not allow creating these manually
    "Emerald_Signage",
    "V4L_Capture",
    "Sapphire_Spaces_-_Beta",
    "Control_Center",
    "uClient_Local_Browser",
];

const _getBlockedSourceSpecByAppIDs = (appID: string) => {
    switch (appID) {
        case APP_IDS.Decisions:
            return ["Emerald_Signage", "V4L_Capture", "Control_Center", "uClient_Local_Browser"];
        case APP_IDS.Trends:
            return ["Emerald_Signage", "Sapphire_Spaces_-_Beta", "V4L_Capture", "Control_Center", "uClient_Local_Browser"];
        case APP_IDS.Switch:
            return ["Emerald_Signage", "Sapphire_Spaces_-_Beta", "V4L_Capture", "Control_Center", "uClient_Local_Browser"];
        case APP_IDS.Config:
            return ["Emerald_Signage", "Sapphire_Spaces_-_Beta", "V4L_Capture", "Control_Center", "uClient_Local_Browser"];
        case APP_IDS.Artistic:
            return [...RESTRICTED_SOURCE_SPECS, "Tableau", "Epic", "Power_BI"]
    }
    return RESTRICTED_SOURCE_SPECS;
};

export const getBlockedSourceSpecByAppIDs = (appID: string) => {
    return [..._getBlockedSourceSpecByAppIDs(appID), ...getBlockedSourceTypesFromApp(appID)];
};

const sourceSpecFilter = (blockedSourceSpecs: string[], specs: SamSourceSpec[], source?: SamSourceDef, disableSourceTypeSelection?: boolean) => {
    if (source && source.type && (disableSourceTypeSelection || blockedSourceSpecs.includes(source.type.value))) {
        // show only the selected source spec if it's restricted
        const foundSpec = specs.find((spec) => spec.header.id.value === source.type.value);
        if (foundSpec) {
            return [foundSpec];
        }
    }
    return specs.filter((spec) => !blockedSourceSpecs.includes(spec.header.id.value));
};

export const GetListSources = (sourceSpecs: SamSourceSpec[], appID?: string, source?: SamSourceDef, disableSourceTypeSelection?: boolean) => {
    const BLOCKE_SOURCE_SPECS = getBlockedSourceSpecByAppIDs(appID);
    const sourceTypeOptions = sourceSpecFilter(BLOCKE_SOURCE_SPECS, sourceSpecs, source, disableSourceTypeSelection);
    return sourceTypeOptions;
}

export const getSourceLevelData = (data: UserfulSecurityData, levels: ClearanceData[]) => {

    return data.clearanceLevelPermissions.map((item: ClearanceLevelID) => levels.filter((level) => level.clearanceLevel.id.value === item.value)[0]);

}

export function getLevelTabledata(data: ClearanceLevel[], clearanceLevelIDs: ClearanceLevelID[]): SourceClearanceTable[] {
    // all the id values that content has
    const allIDValues = clearanceLevelIDs.map((id) => id.value);
    // all level data name and ids
    const levelDataNameID = data.map((level) => ({ name: level.name, id: level.id }));

    const result = levelDataNameID.reduce((acc, item) => {
        const nameParts = item.name.split('_');
        const name = nameParts[0]; // Extract the "anything" part from "anything_edit" or "anything_execute"
        const action = nameParts[1]; // Extract the "edit" or "execute" part

        if (!acc[name]) {
            acc[name] = { name, edit: null, execute: null };
        }

        if (action === 'edit') {
            acc[name].edit = { id: item.id, check: allIDValues.includes(item.id.value) };
        } else if (action === 'execute') {
            acc[name].execute = { id: item.id, check: allIDValues.includes(item.id.value) };
        }

        return acc;
    }, {});

    const resultArray: SourceClearanceTable[] = Object.values(result);
    return resultArray;
}

export const getSecurityData = (contentData: UserfulSecurityData, levels: ClearanceLevel[], onCreation: boolean, getGlobalStates?:Function): UserfulSecurityData => {
    const allIDs = levels.map((level: ClearanceLevel) => level.id.value);
    // this is to send only those clearance levels that actually exist and filter all others
    const updateData = { ...contentData, clearanceLevelPermissions: contentData.clearanceLevelPermissions.filter((level) => allIDs.includes(level.value)) };
    const securityBool = updateData.clearanceLevelPermissions.length !== 0;
    const userfulSecurityData: UserfulSecurityData = onCreation // only on creation you can set the userName and userID
        ? securityBool
            ? {
                ...updateData,
                userName: getGlobalStates().userData.userName,
                userID: getGlobalStates().userData.userID.value,
                securityDataSet: true,
            }
            : EMPTY_USERFUL_SECURITY_DATA()
        : { ...updateData, securityDataSet: securityBool }

    return userfulSecurityData;
}