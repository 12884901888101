import axios, { AxiosPromise, AxiosResponse } from "axios";

import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { HealthStatus } from "../../auth/GCMKeyclockConfig";
import { BackupData, CreateClusterBackup, ICarbon, IConstellationDefinition } from "../../model/CarbonExternalModels";
import carbonStore from "../../store/carbonStore";
import { setConstellations } from "../../store/constellations/constellationsManager";
import { ConstellationsActions } from "../../store/constellations/constellationsSlice";
import { AppSliceActions } from "../../store/app/appSlice";
import { toast } from "react-toastify";
import { BackupsActions } from "../../store/backup/backupSlice";
import { convertBackupDataToBackupUIWrapper } from "../../store/backup/backupUtils";

export const currentURL = process.env.REACT_APP_URL || window.location.origin;

const defaultPath = "/api/secure/constellation";
const backupPath = defaultPath + "/cluster/backup";

export const requestInitStatus = (): AxiosPromise => {
    // return axios.get(`${getGlobalStates().host}${Carbon_INIT_STATUS_PATH}`); // Replace with AWS

    console.error(currentURL);

    return axios.get(currentURL + "/api/open/config");
};

// MSGS MANAGER
export const getError = (response: any) => {
    return {
        success: false,
        arg: response.data.arg ? response.data.arg : "",
        errorCode: response.data.errorCode ? response.data.errorCode : "",
        moduleID: response.data.moduleID ? response.data.moduleID : "",
        severity: response.data.severity ? response.data.severity : "",
        msg: response.data.unlocalizedMsg ? response.data.unlocalizedMsg : "",
    };
};

export const checkResponseIsValid = (name: string, response: AxiosResponse): boolean => {
    if (response.status >= 400) {
        console.log(`${name} error: ${response.status}`);
        return false;
    }
    if (!response.data || response.data.severity !== "SUCCESS") {
        console.log(`${name} empty response`);
        return false;
    }
    return true;
};

export const apiGetBackups = (token: string) => {
    const t1 = currentURL + defaultPath + "/cluster/backup/list";

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .get(t1, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            const data: BackupData[] = response.data.arg;

            // console.warn(data);

            carbonStore.dispatch(BackupsActions.setBackups(data.map((d) => convertBackupDataToBackupUIWrapper(d))));

            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err?.response?.status  === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};

export const apiGetConstellations = (token: string) => {
    const t1 = currentURL + defaultPath;

    carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .get(t1, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            const data: ICarbon = response.data.arg;
            setConstellations(data.constellation, "EXTERNAL");

            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err?.response?.status  === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        });
};

export const apiCreateBackup = (token: string, backup: CreateClusterBackup) => {
    const t1 = currentURL + backupPath + "/create";

    // carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, backup, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }

            // console.warn(response.data.arg);

            toast('ℹ️ Your backup request is being processed. You will receive a confirmation once it is completed.', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });

            // const data: ICarbon = response.data.arg;

            // setConstellations(data.constellation, "EXTERNAL");

            // carbonStore.dispatch(AppSliceActions.setAppStatus("IDLE"));
        })
        .catch((err) => {
            if (err?.response?.status  === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);

            toast('❌ Backup Failed', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};

export const apiDeleteBackup = (token: string, backup: BackupData) => {
    const t1 = currentURL + backupPath + "/delete";

    // carbonStore.dispatch(AppSliceActions.setAppStatus("LOADING"));

    axios
        .post(t1, backup, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }

            const id: StringID = response.data.arg;

            carbonStore.dispatch(BackupsActions.removeBackup(id));
        })
        .catch((err) => {
            if (err?.response?.status  === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};

export const apiGetHealth = (token: string) => {
    const t1 = currentURL + defaultPath + "/health";
    axios
        .get(t1, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);
                return;
            }
            console.debug(`receive authorize`, response.data);
            const data: HealthStatus = response.data.arg;
            console.warn(data);
        })
        .catch((err) => {
            if (err?.response?.status  === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};

export const apiGetDashboards = (token: string) => {
    const t1 = currentURL + defaultPath + "/cluster/dashboards";
    axios
        .get(t1, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);
                return;
            }
            console.debug(`receive authorize`, response.data);
            const dashboard = response.data.arg;
            carbonStore.dispatch(ConstellationsActions.addOrUpdateDashboards(dashboard));

        })
        .catch((err) => {
            if (err?.response?.status  === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};

export const apiCreateConstellation = (token: string, constellationDefinition: IConstellationDefinition) => {
    const t1 = currentURL + defaultPath + "/create";

    axios
        .post(t1, constellationDefinition, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            const data: ICarbon = response.data.arg;
            setConstellations(data.constellation, "EXTERNAL");
        })
        .catch((err) => {
            if (err?.response?.status  === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};

export const apiUpdateConstellation = (token: string, constellationDefinition: IConstellationDefinition) => {
    const t1 = currentURL + defaultPath + "/update";
    axios
        .post(t1, constellationDefinition, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            const data: ICarbon = response.data.arg;
            setConstellations(data.constellation, "EXTERNAL");
        })
        .catch((err) => {
            if (err?.response?.status  === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};

export const apiDeleteConstellation = (token: string, constellationID: StringID) => {
    const t1 = currentURL + defaultPath + "/delete";
    axios
        .post(t1, constellationID, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            const data: ICarbon = response.data.arg;
            setConstellations(data.constellation, "EXTERNAL");
        })
        .catch((err) => {
            if (err?.response?.status  === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};

export const getPairingCode = (token: string, constellationID: StringID) => {
    const t1 = currentURL + defaultPath + "/code";
    axios
        .post(t1, constellationID, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!checkResponseIsValid("authorize", response)) {
                const errorMessage = getError(response);
                console.error("failed", errorMessage);

                return;
            }
            const data = response.data.arg;
            if (data)
                carbonStore.dispatch(ConstellationsActions.setPairingCode(data.value));
        })
        .catch((err) => {
            if (err?.response?.status  === 401) {
                carbonStore.dispatch(AppSliceActions.setLogout(true));
            }
            console.warn("failed", err);
            toast('❌ ' + err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
        });
};

export const getBackupDownload = async (token: string, backup: BackupData) => {
    const t1 = currentURL + backupPath + "/download";

    try {
        const response = await axios.post(t1, backup, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'arraybuffer',  // This ensures that the response is a byte array
        });

        const byteArray = new Uint8Array(response.data);

        return byteArray;  // Return the byte array to be written as a file

    } catch (err) {
        if (err?.response?.status  === 401) {
            carbonStore.dispatch(AppSliceActions.setLogout(true));
        }
        console.warn("failed", err);
        toast('❌ ' + err.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        throw err;  // Re-throw the error if needed for further handling
    }
};
