"use strict";

var _UClientsMsgDefinations = require("../../../message/messageTypeDefinitions/displays/UClientsMsgDefinations");
var _ZeroClientsMsgDefinations = require("../../../message/messageTypeDefinitions/displays/ZeroClientsMsgDefinations");
var _displays = require("../../functions/displays");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _store = require("../../data/store");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_UClientsMsgDefinations.REQUEST_LIST_UCLIENTS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.RESPONSE_LIST_UCLIENTS, (0, _displays.getStandaloneUClients)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_UClientsMsgDefinations.REQUEST_FIRMWARE_UPDATE, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.RESPONSE_FIRMWARE_CONFIG, (0, _store.getStandaloneDataStore)().uclientFirmwareConfig);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.RESPONSE_UCLIENT_APP_VERSION, (0, _store.getStandaloneDataStore)().uclientAppVersin);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_UClientsMsgDefinations.REQUEST_LIST_UCLIENTS_FOR_VE, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.RESPONSE_LIST_UCLIENTS_FOR_VE, (0, _displays.getStandaloneUClients)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_ZeroClientsMsgDefinations.REQUEST_LIST_ZERO_CLIENTS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_ZeroClientsMsgDefinations.RESPONSE_LIST_ZERO_CLIENTS, (0, _displays.getStandaloneZeroClients)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_ZeroClientsMsgDefinations.REQUEST_LIST_ZERO_CLIENTS_FOR_VE, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_ZeroClientsMsgDefinations.RESPONSE_LIST_ZERO_CLIENTS_FOR_VE, (0, _displays.getStandaloneZeroClients)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_UClientsMsgDefinations.REQUEST_UPDATE_LOCK_STATUS, function (payload) {
  var result = (0, _displays.updateLockStatus)(payload);
  if (result) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.NOTIFY_UCLIENT_UPDATED, result, null, 1000);
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_UClientsMsgDefinations.REQUEST_UPDATE_SETTINGS, function (payload) {
  var result = (0, _displays.updateDetails)(payload);
  if (result) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.NOTIFY_UCLIENT_UPDATED, result, null, 1000);
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_UClientsMsgDefinations.REQUEST_UPDATE_STATION_ID, function (payload) {
  var result = (0, _displays.updateStationID)(payload);
  if (result) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.NOTIFY_UCLIENT_UPDATED, result.result, null, 1000);
    if (result.other) {
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.NOTIFY_UCLIENT_UPDATED, result.other, null, 1000);
    }
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_UClientsMsgDefinations.REQUEST_UPDATE_RESOLUTION, function (payload) {
  var result = (0, _displays.updateResolution)(payload);
  if (result) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.NOTIFY_UCLIENT_UPDATED, result, null, 1000);
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_UClientsMsgDefinations.REQUEST_UPDATE_UCLIENT_LOCK_TASK_MODE, function (payload) {
  var result = (0, _displays.updateLockTaskMode)(payload);
  if (result) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.NOTIFY_UCLIENT_UPDATED, result, null, 1000);
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_UClientsMsgDefinations.REQUEST_UCLIENT_REBOOT, function (payload) {
  var foundUClient = (0, _displays.getStandaloneUClients)().find(function (item) {
    return item.id.value === payload.value;
  });
  if (foundUClient) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.NOTIFY_UCLIENT_REMOVED, payload, null, 1000);
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_UClientsMsgDefinations.NOTIFY_UCLIENT_ADDED, foundUClient, null, 2000);
  }
});