import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BackUpKey, ErrorType } from "../model/backupRestore";
interface InitialState {
    error: ErrorType<any>,
    errorBool: boolean,
    downloadInProgressID: BackUpKey[]
}

const initialState: InitialState = {
    error: null,
    errorBool: false,
    downloadInProgressID: []
};

const carbonBackupSlice = createSlice({
    name: "carbonBackup",
    initialState,
    reducers: {

        changeError: (state, action: PayloadAction<ErrorType<any>>) => {
            state.error = action.payload;
        },
        changeErrorBool: (state, action: PayloadAction<boolean>) => {
            state.errorBool = action.payload;
        },
        addDownloadInProgresID(state, action: PayloadAction<BackUpKey>) {
            state.downloadInProgressID = [...state.downloadInProgressID, action.payload];

            console.info("downloadInProgressID added: " +  action.payload.value);
        },
        removeDownloadInProgresID(state, action: PayloadAction<BackUpKey>) {
            state.downloadInProgressID = state.downloadInProgressID.filter((id) => id.value !== action.payload.value);

            console.info("downloadInProgressID removed: " +  action.payload.value);
        }
    }
});

export const carbonBackupActions = carbonBackupSlice.actions;

export default carbonBackupSlice.reducer;