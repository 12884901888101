import React from "react";
import { IUFormTrigger } from "../uFormUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../Button/Button";
import { useTranslation } from "react-i18next";
import DropdownButton from "../../Widgets/Buttons/DropdownButton";
import ClickButton from "../../Widgets/Buttons/ClickButton";

interface IProps {
    trigger: IUFormTrigger;
    onClick: Function;
}

export default function UFormTrigger(props: IProps) {
    const { t } = useTranslation();

    if (props.trigger.dropdownButton) {
        return <DropdownButton onClick={(e) => props.onClick()} button={props.trigger.dropdownButton} />;
    } else if (props.trigger.button) {
        return <ClickButton onClick={(e) => props.onClick()} button={props.trigger.button} />;
    }

    return !props.trigger.simpleTrigger ? (
        <Button variant={props.trigger.buttonType.toLocaleLowerCase()} onClick={(e) => props.onClick()}>
            {props.trigger.icon ? <FontAwesomeIcon icon={props.trigger.icon}/> : null}
            <span>{t(props.trigger.text)}</span>
        </Button>
    ) : (
        <div className="dropdownItem" style={{ cursor: "pointer" }} onClick={(e) => props.onClick()}>
            {props.trigger.icon && <FontAwesomeIcon icon={props.trigger.icon} />}
            <div className="textInside">{t(props.trigger.text)}</div>
        </div>
    );
}
