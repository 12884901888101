import React, { useState } from 'react';
import { Dropdown, Row } from 'react-bootstrap';
import "./Paginated.scss";
import PageSelector from './PageSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

interface IProps {
    defaultItemsPerPage?: number;
    children?: React.ReactChild | React.ReactChild[];
    noData?: JSX.Element;
    className?: string;
    maxPageOptions?: number;
}

/**
 * This is for array of components, not tables
 */
export default function Paginated(props: IProps) {
    const { t } = useTranslation();


    const useLocalStorage = (storageKey, fallbackState) => {
        const [value, setValue] = React.useState(
            localStorage.getItem(storageKey) ?? fallbackState
        );

        React.useEffect(() => {
            localStorage.setItem(storageKey, value);
        }, [value, storageKey]);

        return [value, setValue];
    };

    const defaultItemsPerPage = props.defaultItemsPerPage || 5;

    const [itemsPerPage, setItemsPerPage] = useLocalStorage("itemsPerPage", defaultItemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const startIndex = currentPage * itemsPerPage; // inclusive
    const endIndex = startIndex + itemsPerPage; // exclusive

    let items: React.ReactChild[] = [];
    if (props.children) {
        if (Array.isArray(props.children)) {
            items = props.children as React.ReactChild[];
        } else {
            items = [props.children];
        }
    }



    const handlePageSizeSelect = (value) => {
        setItemsPerPage(Number(value));
        setCurrentPage(0);
    }


    const pageContent = (items: React.ReactChild[]) => {
        if (items.length < 1) {
            return props.noData || null;
        }
        if (items.length < itemsPerPage) {
            return props.children;
        }
        return (items.slice(startIndex, endIndex));
    };

    const pageStart = Math.min(items.length, startIndex + 1);
    const pageEnd = Math.min(items.length, endIndex);
    const totalPages: number = Math.ceil(items.length / itemsPerPage);

    return (

        <div className={`paginationContainer ${props.className || ''}`}>
            
                <Row className='paginationBody'>
                    {
                        pageContent(items)
                    }
                </Row>
            {totalPages > 0 && <div className="paginationFooter">
                <div className="sizeSelector">
                    <Dropdown onSelect={handlePageSizeSelect}>
                        <Dropdown.Toggle variant="light" id="pagination-size-dropdown" className="displayFontHeavy">
                            {itemsPerPage} {t('CommonUI.perPage')} <FontAwesomeIcon icon={faAngleDown} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {props.defaultItemsPerPage && props.defaultItemsPerPage < 5 &&
                                <Dropdown.Item eventKey={props.defaultItemsPerPage}>{props.defaultItemsPerPage} {t('CommonUI.perPage')}</Dropdown.Item>
                            }
                            {/* <Dropdown.Item eventKey={5}>5 {t('CommonUI.perPage')}</Dropdown.Item> */}
                            <Dropdown.Item eventKey={10}>10 {t('CommonUI.perPage')}</Dropdown.Item>
                            <Dropdown.Item eventKey={25}>25 {t('CommonUI.perPage')}</Dropdown.Item>
                            <Dropdown.Item eventKey={50}>50 {t('CommonUI.perPage')}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <span>{pageStart} to {pageEnd} of {items.length}</span>
                </div>
                <div className="pageSelector">
                    <PageSelector
                        totalPages={totalPages}
                        currentPageIndex={currentPage}
                        onPageSelect={setCurrentPage}
                        maxPageOptions={props.maxPageOptions}
                    />
                </div>
            </div>}
        </div>

    )
}