"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserRoles = exports.gcmUserPermissions = void 0;
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var FILTERED_ROLES = ["uma_authorization", "offline_access", "openid", "profile", "email"];
var gcmUserPermissions = exports.gcmUserPermissions = function gcmUserPermissions(keycloak) {
  var isUserManager = ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.USER_MANAGER);
  var isUserCreator = ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.USER_CREATOR);
  var isUserSuppressor = ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.USER_SUPPRESSOR);
  var isUserViewer = isUserManager || isUserCreator || isUserSuppressor || ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.USER_VIEWER);
  var isClusterManager = ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.CLUSTER_MANAGER);
  var isClusterSetup = ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.CLUSTER_SETUP);
  var isClusterSuppressor = ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.CLUSTER_SUPPRESSOR);
  var isClusterViewer = isClusterManager || isClusterSetup || isClusterSuppressor || ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.CLUSTER_VIEWER);
  var isConstellationManager = ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.CONSTELLETION_MANAGER);
  var isConstellationCreator = ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.CONSTELLETION_CREATOR);
  var isConstellationSuppressor = ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.CONSTELLETION_SUPPRESSOR);
  var isConstellationViewer = isConstellationManager || isConstellationCreator || isConstellationSuppressor || isClusterViewer || ROLES.hasRealmRole(keycloak, ROLES.GCM_REALM.CONSTELLETION_VIEWER);
  var permissions = {
    isConstellationManager: isConstellationManager,
    isConstellationCreator: isConstellationCreator,
    isConstellationSuppressor: isConstellationSuppressor,
    isConstellationViewer: isConstellationViewer,
    isClusterManager: isClusterManager,
    isClusterSetup: isClusterSetup,
    isClusterSuppressor: isClusterSuppressor,
    isClusterViewer: isClusterViewer,
    isUserManager: isUserManager,
    isUserCreator: isUserCreator,
    isUserSuppressor: isUserSuppressor,
    isUserViewer: isUserViewer
  };
  return permissions;
};
var getUserRoles = exports.getUserRoles = function getUserRoles(keycloak) {
  var roles = keycloak.tokenParsed.realm_access.roles;
  return roles.filter(function (role) {
    return !FILTERED_ROLES.includes(role);
  });
};
var ROLES = {
  GCM_REALM: {
    ADMIN: "admin",
    ADMIN_VIEWER: "admin_viewer",
    USER_VIEWER: "UserViewer",
    USER_SUPPRESSOR: "UserSuppressor",
    USER_CREATOR: "UserCreator",
    USER_MANAGER: "UserManager",
    CLUSTER_VIEWER: "ClusterViewer",
    CLUSTER_SUPPRESSOR: "ClusterSuppressor",
    CLUSTER_SETUP: "ClusterSetup",
    CLUSTER_MANAGER: "ClusterManager",
    CONSTELLETION_CREATOR: "ConstellationCreator",
    CONSTELLETION_SUPPRESSOR: "ConstellationSuppressor",
    CONSTELLETION_VIEWER: "ConstellationViewer",
    CONSTELLETION_MANAGER: "ConstellationManager"
  },
  hasCapability: function hasCapability(capabilities, capabilityName) {
    if (!capabilities) {
      return false;
    }
    var _iterator = _createForOfIteratorHelper(capabilities.capabilities),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var capabiliy = _step.value;
        if (capabiliy.name === capabilityName) {
          return true;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return false;
  },
  hasRole: function hasRole(userRoles, role) {
    return userRoles.includes(role);
  },
  hasRealmRole: function hasRealmRole(keycloak, role) {
    return keycloak.hasRealmRole(role);
  },
  hasResourceRole: function hasResourceRole(keycloak, role, resource) {
    return keycloak.hasResourceRole(role, resource);
  }
};