"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.servicenowThumbnailImages = exports.getDataPayload = exports.generateOpenURLServicenow = exports.ServicenowPassportHeaderDefault = exports.ServicenowPassportDefault = exports.NOT_SET = void 0;
var _uuid = require("uuid");
var NOT_SET = exports.NOT_SET = "NOT_SET";
var ServicenowPassportHeaderDefault = exports.ServicenowPassportHeaderDefault = function ServicenowPassportHeaderDefault() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    authCode: "",
    clientID: "",
    clientSecret: "",
    instanceURL: ""
  };
};
var ServicenowPassportDefault = exports.ServicenowPassportDefault = function ServicenowPassportDefault() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    isConnected: false,
    clientID: "",
    clientSecret: "",
    instanceURL: ""
  };
};
var generateOpenURLServicenow = exports.generateOpenURLServicenow = function generateOpenURLServicenow(passport, currentURL) {
  return passport.instanceURL + '/oauth_auth.do' + '?client_id=' + passport.clientID + '&response_type=code' + '&redirect_uri=https://integrations.userful.com/oauth2/servicenow' + "&state=".concat(currentURL);
};
var servicenowThumbnailImages = exports.servicenowThumbnailImages = [{
  value: "/usr/share/userful-plugin-servicenow/icons/now-thumbnail.png",
  fileName: "now-thumbnail.png"
}, {
  value: "/usr/share/userful-plugin-servicenow/icons/system-logo.png",
  fileName: "system-logo.png"
}];
var getDataPayload = exports.getDataPayload = function getDataPayload(serverID, startDate, endDate, tableName, columnName) {
  return {
    id: {
      value: serverID
    },
    startDate: startDate,
    endDate: endDate,
    tableName: tableName,
    columnName: columnName
  };
};