import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { requestCreateSourceAssets, requestCreateSourceDef, requestRemoveSourceAssets, requestUpdateSourceAssets } from './msgs/MsgSender';
import { SamSourceAsset } from 'userful-chronos-app-common-js/dist/models/sam/SAMAsset';
import { createAssetFromURL, createWebBrowserSourceFromAsset } from 'userful-chronos-app-common-js/dist/models/sam/SAMUtils';
import { sortByName } from './sourceAssetsUtils';
import { ConfirmationToastContentStore } from '../../Container/ConfirmationToastContentStore';
import { toast } from "react-toastify";

let pendingBrowserSourceAssets: { [key: string]: SamSourceAsset; } = {};

const initialState: {
    assets: Array<SamSourceAsset>,
    ready: boolean;
    expectedConfirmation: string | null;
} = {
    assets: [],
    ready: false,
    expectedConfirmation: null
};

export const assetSlice = createSlice({
    name: 'assetSlice',
    initialState,
    reducers: {
        addOrUpdateAssetToServer: (state, action: PayloadAction<SamSourceAsset>) => {
            const existingIndex = state.assets.findIndex(item => item.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                requestUpdateSourceAssets(action.payload);
            } else {
                requestCreateSourceAssets(action.payload);
            }
            state.expectedConfirmation = action.payload.id.value;
        },
        deleteAssetToServer: (state, action: PayloadAction<string>) => {
            requestRemoveSourceAssets({ value: action.payload });
        },

        setAssests: (state, action: PayloadAction<Array<SamSourceAsset>>) => {
            state.ready = true;
            console.debug(`Got ${action.payload.length} assets`);
            state.assets = sortByName(action.payload);
        },

        addAsset: (state, action: PayloadAction<SamSourceAsset>) => {
            const idValue = action.payload.id.value;
            const existingIndex = state.assets.findIndex(item => item.id.value === idValue);
            if (existingIndex < 0) {
                state.assets = sortByName([
                    ...state.assets,
                    { ...action.payload },
                ]);
                if (state.expectedConfirmation === action.payload.id.value) {
                    const customDisplay = ConfirmationToastContentStore(action.payload.name, "Save");
                    toast(customDisplay, { containerId: 'confirmationContainer' });
                    state.expectedConfirmation = null;
                }
            }
            if (pendingBrowserSourceAssets[idValue]) {
                console.debug('Processing pending browser source asset');
                const source = createWebBrowserSourceFromAsset({
                    name: action.payload.name,
                    description: action.payload.description,
                    asset: { ...pendingBrowserSourceAssets[idValue] },
                })
                requestCreateSourceDef(source)
                delete pendingBrowserSourceAssets[idValue];
            }
        },

        updateAsset: (state, action: PayloadAction<SamSourceAsset>) => {
            const idValue = action.payload.id.value;
            const existingIndex = state.assets.findIndex(item => item.id.value === idValue);
            if (existingIndex >= 0) {
                state.assets = sortByName([
                    ...state.assets.slice(0, existingIndex),
                    { ...action.payload },
                    ...state.assets.slice(existingIndex + 1),
                ])
                if (state.expectedConfirmation === action.payload.id.value) {
                    const customDisplay = ConfirmationToastContentStore(action.payload.name, "Edit");
                    toast(customDisplay, { containerId: 'confirmationContainer' });
                    state.expectedConfirmation = null;
                }
            }
        },

        createSourceFromURL: (state, action: PayloadAction<{
            name: string;
            description: string;
            url: string;
            appID?: string;
        }>) => {
            const asset = createAssetFromURL({
                name: action.payload.name,
                description: action.payload.description,
                url: action.payload.url,
                appID: action.payload.appID,
            });
            pendingBrowserSourceAssets[asset.id.value] = asset;
            requestCreateSourceAssets(asset);
        },

        deleteAsset: (state, action: PayloadAction<string>) => {
            state.assets = sortByName(state.assets.filter(item => item.id.value !== action.payload));
        },
    },
})

export const assetSliceActions = assetSlice.actions;

export default assetSlice.reducer