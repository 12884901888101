import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    HeliosController,
    HeliosDisplayAll,
    HeliosDisplayBlackout,
    HeliosDisplayBrightness,
    HeliosDisplayColorTemperature,
    HeliosDisplayFreeze,
    HeliosDisplayGamma,
    HeliosIngestInput,
    MegapixelControllerHeader,
    OmnisController,
    OmnisDisplayAll,
    OmnisDisplayBlackout,
    OmnisDisplayBrightness,
    OmnisDisplayFreeze,
    OmnisDisplayGamma,
    OmnisDisplayGammaMax,
    OmnisDisplayGammaMin,
} from "userful-chronos-app-common-js/dist/models/integration/megapixel";
import {
    requestAddHeliosController,
    requestRemoveHeliosController,
    requestSetHeliosDisplay,
    requestSetHeliosDisplayBlackout,
    requestSetHeliosDisplayBrightness,
    requestSetHeliosDisplayColorTemperature,
    requestSetHeliosDisplayFreeze,
    requestSetHeliosDisplayGamma,
    requestSetHeliosIngestInput,
    requestUpdateHeliosController,
} from "./msgs/HeliosMsgSender";
import {
    requestSetOmnisDisplay,
    requestSetOmnisDisplayBrightness,
    requestSetOmnisDisplayGamaMin,
    requestSetOmnisDisplayGamma,
    requestSetOmnisDisplayGammaMax,
    requestSetOmnisDisplayBlackout,
    requestSetOmnisDisplayFreeze,
    requestRebootOmnisController,
    requestAddOmnisController,
    requestUpdateOmnisController,
    requestRemoveOmnisController,
} from "./msgs/OmnisMsgSender";

export interface IMegapixelState {
    heliosControllers: HeliosController[];
    omnisControllers: OmnisController[];
    selectedHeliosController: HeliosController;
    selectedOmniosController: OmnisController;
}

const initialState: IMegapixelState = {
    heliosControllers: [],
    omnisControllers: [],
    selectedHeliosController: undefined,
    selectedOmniosController: undefined,
};

export const megapixelSlice = createSlice({
    name: "megapixelSlice",
    initialState,
    reducers: {
        setSelectedHeliosController: (state, action: PayloadAction<HeliosController>) => {
            state.selectedHeliosController = action.payload;
        },
        setSelectedOmnisController: (state, action: PayloadAction<OmnisController>) => {
            state.selectedOmniosController = action.payload;
        },
        // Controllers
        setHeliosControllers: (state, action: PayloadAction<HeliosController[]>) => {
            state.heliosControllers = action.payload;
        },
        setOmnisControllers: (state, action: PayloadAction<OmnisController[]>) => {
            state.omnisControllers = action.payload;
        },
        addOrUpdateHeliosControllers: (state, action: PayloadAction<HeliosController>) => {
            const foundIndex = state.heliosControllers.findIndex((item) => item.id.value === action.payload.id.value);
            if (foundIndex < 0) {
                state.heliosControllers = [...state.heliosControllers, { ...action.payload }];
            } else {
                state.heliosControllers = [
                    ...state.heliosControllers.slice(0, foundIndex),
                    { ...action.payload },
                    ...state.heliosControllers.slice(foundIndex + 1),
                ];
            }
            if (state.selectedHeliosController?.id.value === action.payload.id.value) {
                state.selectedHeliosController = { ...action.payload };
            }
        },
        addOrUpdateOmnisControllers: (state, action: PayloadAction<OmnisController>) => {
            const foundIndex = state.omnisControllers.findIndex((item) => item.id.value === action.payload.id.value);
            if (foundIndex < 0) {
                state.omnisControllers = [...state.omnisControllers, { ...action.payload }];
            } else {
                state.omnisControllers = [
                    ...state.omnisControllers.slice(0, foundIndex),
                    { ...action.payload },
                    ...state.omnisControllers.slice(foundIndex + 1),
                ];
            }
            if (state.selectedOmniosController?.id.value === action.payload.id.value) {
                state.selectedOmniosController = { ...action.payload };
            }
        },
        removeHeliosController: (state, action: PayloadAction<StringID>) => {
            const index = state.heliosControllers.findIndex((item) => item.id.value === action.payload.value);
            if (index >= 0) {
                state.heliosControllers.splice(index, 1);
            }
            if (state.selectedHeliosController?.id.value === action.payload.value) {
                state.selectedHeliosController = undefined;
            }
        },
        removeOmnisController: (state, action: PayloadAction<StringID>) => {
            const index = state.omnisControllers.findIndex((item) => item.id.value === action.payload.value);
            if (index >= 0) {
                state.omnisControllers.splice(index, 1);
            }
            if (state.selectedOmniosController?.id.value === action.payload.value) {
                state.selectedOmniosController = undefined;
            }
        },

        // Helios Display
        addHeliosControllerToServer: (state, action: PayloadAction<MegapixelControllerHeader>) => {
            requestAddHeliosController(action.payload);
        },
        updateHeliosControllerToServer: (state, action: PayloadAction<MegapixelControllerHeader>) => {
            requestUpdateHeliosController(action.payload);
        },
        removeHeliosControllerToServer: (state, action: PayloadAction<StringID>) => {
            requestRemoveHeliosController(action.payload);
        },
        setHeliosDisplay: (state, action: PayloadAction<HeliosDisplayAll>) => {
            requestSetHeliosDisplay(action.payload);
        },
        setHeliosDisplayBrightness: (state, action: PayloadAction<HeliosDisplayBrightness>) => {
            requestSetHeliosDisplayBrightness(action.payload);
        },
        setHeliosDisplayColorTemperature: (state, action: PayloadAction<HeliosDisplayColorTemperature>) => {
            requestSetHeliosDisplayColorTemperature(action.payload);
        },
        setHeliosDisplayGamma: (state, action: PayloadAction<HeliosDisplayGamma>) => {
            requestSetHeliosDisplayGamma(action.payload);
        },
        setHeliosDisplayBlackout: (state, action: PayloadAction<HeliosDisplayBlackout>) => {
            requestSetHeliosDisplayBlackout(action.payload);
        },
        setHeliosDisplayFreeze: (state, action: PayloadAction<HeliosDisplayFreeze>) => {
            requestSetHeliosDisplayFreeze(action.payload);
        },
        setHeliosIngestInput: (state, action: PayloadAction<HeliosIngestInput>) => {
            requestSetHeliosIngestInput(action.payload);
        },

        // Omnis Display
        addOmnisControllerToServer: (state, action: PayloadAction<MegapixelControllerHeader>) => {
            requestAddOmnisController(action.payload);
        },
        updateOmnisControllerToServer: (state, action: PayloadAction<MegapixelControllerHeader>) => {
            requestUpdateOmnisController(action.payload);
        },
        removeOmnisControllerToServer: (state, action: PayloadAction<StringID>) => {
            requestRemoveOmnisController(action.payload);
        },
        setOmnisDisplay: (state, action: PayloadAction<OmnisDisplayAll>) => {
            requestSetOmnisDisplay(action.payload);
        },
        setOmnisDisplayBrightness: (state, action: PayloadAction<OmnisDisplayBrightness>) => {
            requestSetOmnisDisplayBrightness(action.payload);
        },
        setOmnisDisplayGamaMin: (state, action: PayloadAction<OmnisDisplayGammaMin>) => {
            requestSetOmnisDisplayGamaMin(action.payload);
        },
        setOmnisDisplayGamma: (state, action: PayloadAction<OmnisDisplayGamma>) => {
            requestSetOmnisDisplayGamma(action.payload);
        },
        setOmnisDisplayGammaMax: (state, action: PayloadAction<OmnisDisplayGammaMax>) => {
            requestSetOmnisDisplayGammaMax(action.payload);
        },
        setOmnisDisplayBlackout: (state, action: PayloadAction<OmnisDisplayBlackout>) => {
            requestSetOmnisDisplayBlackout(action.payload);
        },
        setOmnisDisplayFreeze: (state, action: PayloadAction<OmnisDisplayFreeze>) => {
            requestSetOmnisDisplayFreeze(action.payload);
        },
        rebootOmnisController: (state, action: PayloadAction<StringID>) => {
            requestRebootOmnisController(action.payload);
        },
    },
});

export const megapixelActions = megapixelSlice.actions;

export default megapixelSlice.reducer;
