import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faGripDots, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import UserfulTooltip from '../UserfulTooltip';


interface IProps {
    id: string | number;
    children: ReactNode,
    onDelete?: (id: UniqueIdentifier) => void;
    limitDragArea?: boolean;
    index: number;
    disabled?: boolean;
    canDelete?: boolean;
}

export default function SortableItem(props: IProps) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        props.onDelete(props.id);
    }

    const listenersProps = props.limitDragArea ? {} : listeners;

    return (
        <div ref={setNodeRef} style={style}{...attributes} className="sortableItemContainer">
            <div {...listenersProps} className="sortableItem" >
                {props.children}
            </div>
            <div className="sortableItemActions">
                {props.limitDragArea && !props.disabled &&
                    <UserfulTooltip title="Drag to re-order">
                        <div {...listeners} className="draggableDrag" id={`reorder-dragging-handle-${props.index}`}>
                            <FontAwesomeIcon icon={faGripDots} />
                        </div>
                    </UserfulTooltip>}
                {props.onDelete && props.canDelete &&
                    <UserfulTooltip title='Remove item'>
                        <div onClick={handleDelete} className="draggableDelete" onMouseUp={e => e.stopPropagation()}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </UserfulTooltip>}
            </div>

        </div>
    );
}