"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_LIST_VIDEO_ENGINES = exports.RESPOND_LIST_VIDEO_ENGINES = exports.REQUEST_UPDATE_VE_IDENTITY = exports.REQUEST_UPDATE_GPU = exports.REQUEST_REMOVE_VIDEO_ENGINE = exports.REQUEST_LIST_VIDEO_ENGINES = exports.REQUEST_LIST_AETHER_VIDEO_ENGINES = exports.NOTIFY_VIDEO_ENGINE_UPDATED = exports.NOTIFY_VIDEO_ENGINE_STATE_UPDATED = exports.NOTIFY_VIDEO_ENGINE_REMOVED = exports.NOTIFY_VIDEO_ENGINE_IDENTITY_UPDATED = exports.NOTIFY_VIDEO_ENGINE_ADDED = exports.NOTIFY_VIDEO_AETHER_ENGINE_REMOVED = exports.NOTIFY_VIDEO_AETHER_ENGINE_ADDED = exports.NOTIFY_GPU_UPDATED = void 0;
var _messageModel = require("../../messageModel");
var VIDEO_ENGINE = 3012;
var REQUEST_LIST_VIDEO_ENGINES = exports.REQUEST_LIST_VIDEO_ENGINES = new _messageModel.RequestMessageDefinition(VIDEO_ENGINE, 2);
var NOTIFY_VIDEO_ENGINE_ADDED = exports.NOTIFY_VIDEO_ENGINE_ADDED = new _messageModel.RespondMessageDefinition(VIDEO_ENGINE, 0);
var NOTIFY_VIDEO_ENGINE_UPDATED = exports.NOTIFY_VIDEO_ENGINE_UPDATED = new _messageModel.RespondMessageDefinition(VIDEO_ENGINE, 1);
var NOTIFY_VIDEO_ENGINE_REMOVED = exports.NOTIFY_VIDEO_ENGINE_REMOVED = new _messageModel.RespondMessageDefinition(VIDEO_ENGINE, 3);
var RESPONSE_LIST_VIDEO_ENGINES = exports.RESPONSE_LIST_VIDEO_ENGINES = new _messageModel.RespondMessageDefinition(VIDEO_ENGINE, 4);
var AETHER_VE = 2020;
var REQUEST_UPDATE_VE_IDENTITY = exports.REQUEST_UPDATE_VE_IDENTITY = new _messageModel.RequestMessageDefinition(AETHER_VE, 0);
var REQUEST_LIST_AETHER_VIDEO_ENGINES = exports.REQUEST_LIST_AETHER_VIDEO_ENGINES = new _messageModel.RequestMessageDefinition(AETHER_VE, 1);
var REQUEST_REMOVE_VIDEO_ENGINE = exports.REQUEST_REMOVE_VIDEO_ENGINE = new _messageModel.RequestMessageDefinition(AETHER_VE, 2);
var REQUEST_UPDATE_GPU = exports.REQUEST_UPDATE_GPU = new _messageModel.RequestMessageDefinition(AETHER_VE, 3);
var NOTIFY_VIDEO_AETHER_ENGINE_ADDED = exports.NOTIFY_VIDEO_AETHER_ENGINE_ADDED = new _messageModel.RespondMessageDefinition(AETHER_VE, 0);
var NOTIFY_VIDEO_AETHER_ENGINE_REMOVED = exports.NOTIFY_VIDEO_AETHER_ENGINE_REMOVED = new _messageModel.RespondMessageDefinition(AETHER_VE, 1);
var NOTIFY_VIDEO_ENGINE_STATE_UPDATED = exports.NOTIFY_VIDEO_ENGINE_STATE_UPDATED = new _messageModel.RespondMessageDefinition(AETHER_VE, 2);
var NOTIFY_VIDEO_ENGINE_IDENTITY_UPDATED = exports.NOTIFY_VIDEO_ENGINE_IDENTITY_UPDATED = new _messageModel.RespondMessageDefinition(AETHER_VE, 3);
var RESPOND_LIST_VIDEO_ENGINES = exports.RESPOND_LIST_VIDEO_ENGINES = new _messageModel.RespondMessageDefinition(AETHER_VE, 4);
var NOTIFY_GPU_UPDATED = exports.NOTIFY_GPU_UPDATED = new _messageModel.RespondMessageDefinition(AETHER_VE, 5);