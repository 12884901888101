"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateUUID = exports.equalFunctionForStringID = void 0;
exports.removeItemFromArray = removeItemFromArray;
exports.safeJSONParse = void 0;
var _uuid = require("uuid");
function removeItemFromArray(arr, value, equalFunction) {
  var index;
  if (equalFunction) {
    for (index = 0; index < arr.length; index++) {
      if (equalFunction(value, arr[index])) {
        break;
      }
      if (index >= arr.length) {
        // Couldn't find the item.
        index = -1;
      }
    }
  } else {
    index = arr.indexOf(value);
  }
  if (index >= 0) {} else {}
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
var generateUUID = exports.generateUUID = function generateUUID() {
  return (0, _uuid.v4)();
};
var equalFunctionForStringID = exports.equalFunctionForStringID = function equalFunctionForStringID(v1, v2) {
  return v1.value === v2.value;
};
var safeJSONParse = exports.safeJSONParse = function safeJSONParse(data) {
  try {
    return JSON.parse(data);
  } catch (_) {}
  return null;
};