import React, { useState } from "react";
import { IUFormField } from "../uFormUtils";
import "./UFormFields.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "../../Calendar/view/customStyles";
import UFieldTitle from "./UFieldTitle/UFieldTitle";
import { Tag } from "userful-chronos-app-common-js/dist/models/sam/Common";

interface IProps {
     field: IUFormField;
     forceValue?: string | number | boolean | undefined;
     forceError?: string;
     onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

export default function UTags({ field, forceValue, onItemChange }: IProps) {
     const { t } = useTranslation();

     let value = useUFormContext().state.fieldState[field.key];
     if (forceValue) value = forceValue;
     const { onFieldChange } = useUFormContext();

     // const sourceTags = props.sourcesData?.map((source) => source.orgData.tagsSet.tags).reduce((prev, cur) => [...prev, ...cur]);

     // const handleTagChange = (newValue) => {
     //      const updatedTags = newValue.map(
     //           (item) =>
     //           ({
     //                name: item.label,
     //                value: item.value,
     //                type: "STRING",
     //           } as Tag)
     //      );
     //      props.onChange({
     //           ...props.data.orgData,
     //           tagsSet: {
     //                valid: true,
     //                tags: updatedTags,
     //           },
     //      });
     // };

     const tagDefaultOptions = field.data.tagsSet.tags.map((item) => ({
          value: item.value,
          label: item.name,
     }));

     const tagOptions =
          field.items &&
          field.items.map((item) => ({
               value: item.value,
               label: item.label,
          }));

     const uniqueTags = [];

     const unique =
          tagOptions &&
          tagOptions.filter((ele) => {
               const isDuplicate = uniqueTags.includes(ele.value);
               if (!isDuplicate) {
                    uniqueTags.push(ele.value);
                    return true;
               } else return false;
          });

     const uniqueTagsOption =
          tagOptions &&
          tagOptions.filter((ele) => {
               uniqueTags.includes(ele.value);
          });

     return (
          <div className="fieldColumnWrapper">
               <UFieldTitle
                    title={field.title}
                    help={field.help}
                    required={field.required}
                    length={length}
                    maxLength={field.maxLength}
                    minLength={field.minLength}
               />
               {/* <CreatableSelect
                    styles={customStyles}
                    isMulti
                    onChange={handleTagChange}
                    defaultValue={tagDefaultOptions}
                    options={unique}
                    menuPosition="fixed"
                    components={{ IndicatorSeparator: () => null }}
               /> */}
          </div>
     );
}
