"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateVideoWalls = exports.removeVideoWall = exports.getStandaloneVideoWalls = exports.addVideoWalls = void 0;
var _store = require("../data/store");
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var getStandaloneVideoWalls = exports.getStandaloneVideoWalls = function getStandaloneVideoWalls() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().videoWalls);
};
var addVideoWalls = exports.addVideoWalls = function addVideoWalls(vw) {
  var videoWalls = getStandaloneVideoWalls();
  videoWalls.push(vw);
  (0, _store.updateStandaloneDataStore)({
    videoWalls: videoWalls
  });
  return _toConsumableArray(videoWalls);
};
var updateVideoWalls = exports.updateVideoWalls = function updateVideoWalls(vw) {
  var videoWalls = getStandaloneVideoWalls();
  var foundIndex = videoWalls.findIndex(function (item) {
    return item.id.value === vw.id.value;
  });
  if (foundIndex >= 0) {
    videoWalls.splice(foundIndex, 1, vw);
    (0, _store.updateStandaloneDataStore)({
      videoWalls: videoWalls
    });
  }
  return _toConsumableArray(videoWalls);
};
var removeVideoWall = exports.removeVideoWall = function removeVideoWall(id) {
  var videoWalls = getStandaloneVideoWalls();
  var foundIndex = videoWalls.findIndex(function (item) {
    return item.id.value === id.value;
  });
  if (foundIndex >= 0) {
    var result = videoWalls.splice(foundIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      videoWalls: videoWalls
    });
    return result;
  }
  return null;
};