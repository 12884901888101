import React, { ReactNode, useEffect } from "react";
import { Provider } from "react-redux";
import { createSamOrgQueryForApp } from "userful-chronos-app-common-js/dist/models/sam/SAMUtils";
import {
    requestListPlayListDefs,
    requestListSourceDefs,
    requestListSourceSpecs,
    requestQueryPlaylistDefsAssets,
    requestQuerySourceDefs,
} from "../sourceassets/msgs/MsgSender";
import mappingStore, { MappingGroupContext } from "./mappingStore";
import "./msgs/MappingSamMsgReceiver";
import { setAppIDForMappingSamReceiver } from "./msgs/MappingSamMsgReceiver";
import "./msgs/MappingCdmMsgReceiver";
import "./msgs/MappingMsgReceiver";
import {
    requestListMappingGroups,
    requestListOperationsCenters,
    requestListUConductViews,
    requestListMapperLayouts,
    requestListUConductContainers,
    requestListUConductWorkspaces,
} from "./msgs/MsgSender";
import { requestListDestinations } from "../destinations/msgs/MsgSender";
import { setAppIDForMappingReceiver } from "./msgs/MappingMsgReceiver";
import { mappingGroupsSliceActions } from "./mappingGroupsSlice";
import { requestListUC } from "../displays/msgs/UClientsMsgSender";
import "../layouts/msgs/layoutsMsgReceiver";
import { requestListMapperLayoutMapping } from 'userful-chronos-app-common-js/dist/models/layouts/messages/MapperLayoutMsgSender';

interface IProps {
    appID?: string;
    children: ReactNode;
}

function MappingGroupsProvider(props: IProps) {
    useEffect(() => {
        mappingStore.dispatch(mappingGroupsSliceActions.setEdgePlayerMode(false));
        mappingStore.dispatch(mappingGroupsSliceActions.clearMappingGroups());

        requestListSourceSpecs();
        requestListUC();

        if (props.appID) {
            setAppIDForMappingSamReceiver(props.appID);
            setAppIDForMappingReceiver(props.appID);
            // destination query doesn't work right now for auto-created destinations
            const samOrgQuery = createSamOrgQueryForApp(props.appID);
            requestQuerySourceDefs(samOrgQuery);
            requestQueryPlaylistDefsAssets(samOrgQuery);
            requestListMappingGroups(true);
            // setAppIDForMappingCdmReceiver(props.appID);
            // const cdmOrgQuery = createCdmOrgQueryForApp(props.appID);
            // requestQueryDestinations(cdmOrgQuery);
        } else {
            requestListSourceDefs();
            requestListPlayListDefs();
            requestListMappingGroups(true);
            // requestListDestinations();
        }
        // requestListVideoWalls();
        requestListMapperLayouts();
        requestListMapperLayoutMapping();

        // UConduct:
        requestListUConductViews();
        requestListUConductContainers();
        requestListUConductWorkspaces();

        requestListOperationsCenters();
        requestListDestinations();
        requestListSourceSpecs();
    }, []);

    return (
        <Provider store={mappingStore} context={MappingGroupContext}>
            {props.children}
        </Provider>
    );
}

export default MappingGroupsProvider;
