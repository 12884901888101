"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userPermissions = exports.hasClearanceLevelForAction = exports.getUserRoles = void 0;
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var FILTERED_ROLES = ["uma_authorization", "offline_access", "openid", "profile", "email", "default-roles-userful"];
var hasClearanceLevelForAction = exports.hasClearanceLevelForAction = function hasClearanceLevelForAction(data, authUserID, permissions, actions, actionName) {
  if (!data) {
    // security data missing due to source already deleted
    return true;
  }
  // if user is the owner of the source
  if (authUserID.value === data.userID) {
    return true;
  }
  // the content is open to all, it means no clearance levels are attached
  if (data.clearanceLevelPermissions.length === 0) return true;
  var clData = permissions.clearanceData;
  var matchingClearance = data.clearanceLevelPermissions.filter(function (clID) {
    var match = clData.filter(function (i) {
      return i.clearanceLevel.id.value === clID.value;
    });
    if (match.length === 0) return false;else {
      var matchedClearanceLevel = match[0];
      var action = actions.find(function (a) {
        return a.name === actionName;
      });
      var allActionIDs = matchedClearanceLevel.clearanceLevel.actions.map(function (action) {
        return action.value;
      });
      return allActionIDs.includes(action.id.value);
    }
  });
  return matchingClearance.length > 0;
};
var userPermissions = exports.userPermissions = function userPermissions(capabilities, clearanceData, keycloak) {
  var isAdminUser = ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ADMIN);
  var canOverrideSecurityDataOwnerShip = ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS.OVERRIDE_SECURITY_DATA_OWNERSHIP, "userful-chronos");
  var isAmethystAdmin = isAdminUser || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ARTISTIC_ADMIN) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ARTISTIC);
  var isAmethystUser = isAmethystAdmin || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ARTISTIC_USER) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ARTISTIC);
  var isDiamondAdmin = isAdminUser || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.DIAMOND_ADMIN) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.DIAMOND);
  var isDiamondOperator = isDiamondAdmin || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.DIAMOND_OPERATOR) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.DIAMOND);
  var isDiamondUser = isDiamondOperator || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.DIAMOND_USER) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.DIAMOND);
  var isRubyAdmin = isAdminUser || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.REMOTE_ADMIN) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.REMOTE);
  var isRubyUser = isRubyAdmin || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.REMOTE_USER) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.REMOTE);
  var isEmeraldAdmin = isAdminUser || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ENGAGE_ADMIN) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ENGAGE);
  var isEmeraldUser = isEmeraldAdmin || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ENGAGE_USER) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ENGAGE);
  var isSapphireAdmin = isAdminUser || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.SPACE_ADMIN) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.SPACES);
  var isSapphireUser = isSapphireAdmin || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.SPACE_USER) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.SPACES);
  var isTopazAdmin = isAdminUser || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.TRENDS_ADMIN) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.TRENDS);
  var isTopazUser = isTopazAdmin || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.TRENDS_USER) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.TRENDS);

  // to view the mapper
  var isMapperViewer = ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS.MAPPER_VIEWER, "userful-chronos");
  // to create the mapper
  var isMapperCreator = ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS.MAPPER_CREATOR, "userful-chronos");
  // edit the mapper only, NOT delete
  var isMapperUpdater = ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS.MAPPER_UPDATER, "userful-chronos");
  // deleting the mapper
  var isMapperSuppressor = ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS.MAPPER_SUPPRESSOR, "userful-chronos");
  // all permissions
  var isMapperAdmin = isMapperViewer && isMapperCreator && isMapperUpdater && isMapperSuppressor;
  // for deleting the source
  var isSourceSuppressor = ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS.SOURCE_SUPPRESSOR, "userful-chronos");
  var isControlRoomViewer = isDiamondAdmin || ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS.CONTROL_ROOM_VIEWER, "userful-chronos");
  var isControlRoomCreator = isDiamondAdmin || ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS.CONTROL_ROOM_CREATOR, "userful-chronos");
  var isControlRoomUpdater = isDiamondAdmin || ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS.CONTROL_ROOM_UPDATER, "userful-chronos");
  var isRubyAllowed = ROLES.hasCapability(capabilities, ROLES.CAPABILITIES.REMOTE) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.REMOTE);
  var isTopazAllowed = ROLES.hasCapability(capabilities, ROLES.CAPABILITIES.TRENDS) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.TRENDS);
  var isDiamondAllowed = ROLES.hasCapability(capabilities, ROLES.CAPABILITIES.DIAMOND) && (ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.DIAMOND) || ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS_AETHER.CONTROL_ROOM, "userful-chronos-aether") || ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS_AETHER.CONTROL_ROOM_MANAGER, "userful-chronos-aether"));
  var isEmeraldAllowed = ROLES.hasCapability(capabilities, ROLES.CAPABILITIES.ENGAGE) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ENGAGE);
  var isSapphireAllowed = ROLES.hasCapability(capabilities, ROLES.CAPABILITIES.SPACES) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.SPACES);
  var isAmethystAllowed = ROLES.hasCapability(capabilities, ROLES.CAPABILITIES.ARTISTIC) && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.ARTISTIC);
  var isUConductAllowed = ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.UCONDUCT);
  var hasMappingGroupsAccess = ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS_AETHER.MAPPING_GROUPS, "userful-chronos-aether") && ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS.MAPPER_VIEWER, "userful-chronos");
  var hasCmsToolAccess = isEmeraldUser && ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS_AETHER.CMS_TOOL, "userful-chronos-aether");
  var isMappingGroupEditor = hasMappingGroupsAccess && isMapperCreator && isMapperUpdater && isMapperViewer;
  var canEditMappingGroup = isAdminUser || isMappingGroupEditor && ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS_AETHER.EDIT_MAPPING_GROUP, "userful-chronos-aether");
  var canEditTextOverlay = isAdminUser || isMappingGroupEditor && ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS_AETHER.EDIT_TEXT_OVERLAY, "userful-chronos-aether");
  var canEditPlaylist = isAdminUser || isMappingGroupEditor && ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS_AETHER.EDIT_PLAYLIST, "userful-chronos-aether");
  var canEditMultiWindow = isAdminUser || isMappingGroupEditor && ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS_AETHER.EDIT_MULTI_WINDOW, "userful-chronos-aether");
  var isTabletCtrlAdmin = isAdminUser || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.TABLET_CONTROLLER_ADMIN);
  var isTabletCtrlUser = isTabletCtrlAdmin || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.TABLET_CONTROLLER);
  var isUserManagementAdmin = ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.USER_MANAGEMENT_ADMIN);
  var canAccessSystemConfig = ROLES.hasResourceRole(keycloak, ROLES.USERFUL_CHRONOS_AETHER.SYSTEM_CONFIGURATION, "userful-chronos-aether");
  var isSystemConfigAdmin = canAccessSystemConfig && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.SYSTEM_CONFIG_ADMIN);
  var isSystemConfigViewer = isSystemConfigAdmin || canAccessSystemConfig && ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.SYSTEM_CONFIG_VIEWER);
  var hasMonitoringDashboardAccess = ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.MONITORING_DASHBOARD_ACCESS);
  var hasSourcesDestinationsAccess = ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.SOURCES_DESTINATIONS_ACCESS);
  var isUConductAdmin = isAdminUser || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.UCONDUCT_ADMIN);
  var isUConductUser = isUConductAdmin || ROLES.hasRealmRole(keycloak, ROLES.USERFUL_REALM.UCONDUCT_USER);
  var permissions = {
    isAdminUser: isAdminUser,
    isAmethystAdmin: isAmethystAdmin,
    isAmethystUser: isAmethystUser,
    isDiamondAdmin: isDiamondAdmin,
    isDiamondOperator: isDiamondOperator,
    isDiamondUser: isDiamondUser,
    isEmeraldAdmin: isEmeraldAdmin,
    isEmeraldUser: isEmeraldUser,
    isRubyAdmin: isRubyAdmin,
    isRubyUser: isRubyUser,
    isSapphireAdmin: isSapphireAdmin,
    isSapphireUser: isSapphireUser,
    isTopazAdmin: isTopazAdmin,
    isTopazUser: isTopazUser,
    isMapperViewer: isMapperViewer,
    isMapperCreator: isMapperCreator,
    isMapperUpdater: isMapperUpdater,
    isControlRoomViewer: isControlRoomViewer,
    isControlRoomCreator: isControlRoomCreator,
    isControlRoomUpdater: isControlRoomUpdater,
    isUserManagementAdmin: isUserManagementAdmin,
    isMapperSuppressor: isMapperSuppressor,
    isMapperAdmin: isMapperAdmin,
    isSourceSuppressor: isSourceSuppressor,
    isRubyAllowed: isRubyAllowed,
    isTopazAllowed: isTopazAllowed,
    isDiamondAllowed: isDiamondAllowed,
    isEmeraldAllowed: isEmeraldAllowed,
    isSapphireAllowed: isSapphireAllowed,
    isAmethystAllowed: isAmethystAllowed,
    hasMappingGroupsAccess: hasMappingGroupsAccess,
    hasCmsToolAccess: hasCmsToolAccess,
    canEditMappingGroup: canEditMappingGroup,
    canEditTextOverlay: canEditTextOverlay,
    canEditPlaylist: canEditPlaylist,
    canEditMultiWindow: canEditMultiWindow,
    isTabletCtrlUser: isTabletCtrlUser,
    isTabletCtrlAdmin: isTabletCtrlAdmin,
    isSystemConfigAdmin: isSystemConfigAdmin,
    isSystemConfigViewer: isSystemConfigViewer,
    hasMonitoringDashboardAccess: hasMonitoringDashboardAccess,
    hasSourcesDestinationsAccess: hasSourcesDestinationsAccess,
    clearanceData: clearanceData,
    canOverrideSecurityDataOwnerShip: canOverrideSecurityDataOwnerShip,
    isUConductAllowed: isUConductAllowed,
    isUConductAdmin: isUConductAdmin,
    isUConductUser: isUConductUser
  };
  return permissions;
};
var getUserRoles = exports.getUserRoles = function getUserRoles(keycloak) {
  var roles = keycloak.tokenParsed.realm_access.roles;
  return roles.filter(function (role) {
    return !FILTERED_ROLES.includes(role);
  });
};
var ROLES = {
  CAPABILITIES: {
    DIAMOND: "li-diamond-allow",
    ENGAGE: "li-emerald-allow",
    SPACES: "li-sapphire-allow",
    TRENDS: "li-topaz-allow",
    REMOTE: "li-ruby-allow",
    ARTISTIC: "li-amethyst-allow"
  },
  USERFUL_REALM: {
    ADMIN: "admin",
    USER_MANAGEMENT_ADMIN: "user_management_admin",
    ARTISTIC_ADMIN: "artistic_admin",
    ARTISTIC_USER: "artistic_user",
    DIAMOND_ADMIN: "decisions_admin",
    DIAMOND_OPERATOR: "decisions_operator",
    DIAMOND_USER: "decisions_user",
    ENGAGE_ADMIN: "engage_admin",
    ENGAGE_USER: "engage_user",
    REMOTE_ADMIN: "remote_admin",
    REMOTE_USER: "remote_user",
    SPACE_ADMIN: "spaces_admin",
    SPACE_USER: "spaces_user",
    TRENDS_ADMIN: "trends_admin",
    TRENDS_USER: "trends_user",
    DIAMOND: "Decisions",
    ENGAGE: "Engage",
    SPACES: "Spaces",
    TRENDS: "Trends",
    REMOTE: "Remote",
    ARTISTIC: "Artistic",
    UCONDUCT: "UConduct",
    TABLET_CONTROLLER: "TabletController",
    TABLET_CONTROLLER_ADMIN: "tablet_ctrl",
    SYSTEM_CONFIG_ADMIN: "system_config_admin",
    SYSTEM_CONFIG_VIEWER: "system_config_viewer",
    MONITORING_DASHBOARD_ACCESS: "monitoring_dashboard",
    SOURCES_DESTINATIONS_ACCESS: "sources_destinations_admin",
    UCONDUCT_USER: "uconduct_user",
    UCONDUCT_ADMIN: "uconduct_admin"
  },
  USERFUL_CHRONOS: {
    POWER_MANGER: "PowerManager",
    CHANNEL_VIEWER: "ChannelViewer",
    MEETING_ROOM_VIEWER: "MeetingRoomViewer",
    CHANNEL_CREATOR: "ChannelCreator",
    FAIL_OVER_MANAGER: "FailoverManager",
    CHANNEL_SUPPRESSOR: "ChannelSuppressor",
    MAPPER_CREATOR: "MapperCreator",
    MAPPER_VIEWER: "MapperViewer",
    MAPPER_UPDATER: "MapperUpdater",
    MAPPER_SUPPRESSOR: "MapperSuppressor",
    SOURCE_SUPPRESSOR: "SourceSuppressor",
    REMOTE_SUPPORT: "RemoteSupport",
    GENERAL_SETTING_MANAGER: "GeneralSettingManager",
    BROADCAST_MANAGER: "BroadcastManager",
    MEETING_ROOM_CREATOR: "MeetingRoomCreator",
    ENDPOINT_MANAGER: "EndPointManager",
    LICENSE_UPDATER: "LicenseUpdater",
    CHANNEL_UPDATER: "ChannelUpdater",
    MEETING_ROOM_UPDATER: "MeetingRoomUpdater",
    LICENSE_VIEWER: "LicenseViewer",
    LICENSE_MANAGER: "LicenseManager",
    SYSTEM_MONITORING_DASHBOARD: "SystemMonitoringDashboard",
    PLAYLIST_VIEWER: "SamPlayListDefViewer",
    PLAYLIST_CREATOR: "SamPlayListDefCreator",
    PLAYLIST_UPDATER: "SamPlayListDefUpdater",
    PLAYLIST_SUPPRESSOR: "SamPlayListDefSuppressor",
    CONTROL_ROOM_CREATOR: "ControlRoomCreator",
    CONTROL_ROOM_UPDATER: "ControlRoomUpdater",
    CONTROL_ROOM_VIEWER: "ControlRoomViewer",
    OVERRIDE_SECURITY_DATA_OWNERSHIP: "OverrideSecurityDataOwnership"
  },
  USERFUL_CHRONOS_AETHER: {
    LAUNCHER: "Launcher",
    CMS_TOOL: "CmsTool",
    CONTROL_ROOM: "ControlRoom",
    MEETING_ROOM: "MeetingRooms",
    MAPPING_GROUPS: "MappingGroups",
    CONTROL_ROOM_MANAGER: "ControlRoomMgr",
    SYSTEM_CONFIGURATION: "SystemConfiguration",
    SOURCES_DESTINATIONS: "SourcesDestinations",
    EDIT_TEXT_OVERLAY: "EditTextOverlay",
    EDIT_MAPPING_GROUP: "EditMappingGroup",
    EDIT_PLAYLIST: "EditPlaylist",
    EDIT_MULTI_WINDOW: "EditMultiWindow"
  },
  hasCapability: function hasCapability(capabilities, capabilityName) {
    if (!capabilities) {
      return false;
    }
    var _iterator = _createForOfIteratorHelper(capabilities.capabilities),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var capabiliy = _step.value;
        if (capabiliy.name === capabilityName) {
          return true;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return false;
  },
  hasRole: function hasRole(userRoles, role) {
    return userRoles.includes(role);
  },
  hasRealmRole: function hasRealmRole(keycloak, role) {
    return keycloak.hasRealmRole(role);
  },
  hasResourceRole: function hasResourceRole(keycloak, role, resource) {
    return keycloak.hasResourceRole(role, resource);
  }
};