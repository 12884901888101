import React, { ReactNode, useEffect } from 'react';
import { Provider } from 'react-redux';
import choppedAssetStore, { ChoppedAssetContext } from './choppedAssetStore';
import './msgs/choppedAssetMsgReceiver';
import { requestListChoppedAssets, requestListCurrentChoppingRequests } from './msgs/choppedAssetMsgSender';

interface IProps {
  children: ReactNode,
}

function ChoppedAssetsProvider(props: IProps) {
  useEffect(() => {
    requestListChoppedAssets();
    requestListCurrentChoppingRequests();
  }, []);

  return (
    <Provider store={choppedAssetStore} context={ChoppedAssetContext}>
      {props.children}
    </Provider>
  );
}

export default ChoppedAssetsProvider;