import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    TableauPassport, TableauLoadState, TableauPassportFormState, TableauWorkbook, TableauView, TableauPassportID, TableauPassportHeader,
    TableauViewListRequest, initTableauPassport
} from 'userful-chronos-app-common-js/dist/models/integration/tableau';
import {
    requestListPassports, requestAddPassport, requestRemovePassport, requestUpdatePassport, requestListViewsInWorkbook, requestListWorkbooks
} from './msgs/tableauMsgSender';

const initialState: {
    tableauPassports: TableauPassport[];
    loadState: TableauLoadState;
    passport: TableauPassport;
    copyPassport: TableauPassport;
    formType: TableauPassportFormState;
    openForm: boolean;
    tableauWorkbooks: TableauWorkbook[],
    tableauViews: TableauView[]
} = {
    tableauPassports: [],
    loadState: 'RENDER',
    passport: initTableauPassport(),
    copyPassport: initTableauPassport(),
    formType: 'Create',
    openForm: false,
    tableauWorkbooks: [],
    tableauViews: []
};

export const tableauSlice = createSlice({
    name: 'tableauSlice',
    initialState,
    reducers: {

        setPassports: (state, action: PayloadAction<TableauPassport[]>) => {
            state.tableauPassports = action.payload;
        },
        addOrUpdatePassport: (state, action: PayloadAction<TableauPassport>) => {
            const foundIndex = state.tableauPassports.findIndex(item => item.id.value === action.payload.id.value);
            if (foundIndex < 0) {
                state.tableauPassports = [
                    ...state.tableauPassports,
                    { ...action.payload },
                ];
            } else {
                state.tableauPassports = [
                    ...state.tableauPassports.slice(0, foundIndex),
                    { ...action.payload },
                    ...state.tableauPassports.slice(foundIndex + 1),
                ];
            }
        },
        removePassport: (state, action: PayloadAction<TableauPassportID>) => {
            state.tableauPassports = state.tableauPassports.filter(item => item.id.value !== action.payload.value);
        },
        getPassportsFromServer: (state, action: PayloadAction<{}>) => {
            requestListPassports();
        },
        addPassportToServer: (state, action: PayloadAction<TableauPassportHeader>) => {
            requestAddPassport(action.payload);
        },
        updatePassportToServer: (state, action: PayloadAction<TableauPassportHeader>) => {
            requestUpdatePassport(action.payload);
        },
        removePassportToServer: (state, action: PayloadAction<TableauPassportID>) => {
            requestRemovePassport(action.payload);
        },
        changeLoadState: (state, action: PayloadAction<TableauLoadState>) => {
            state.loadState = action.payload;
        },
        setPassport: (state, action: PayloadAction<TableauPassport>) => {
            state.passport = action.payload;
        },
        setCopyPassport: (state, action: PayloadAction<TableauPassport>) => {
            state.copyPassport = action.payload;
        },
        setFormType: (state, action: PayloadAction<TableauPassportFormState>) => {
            state.formType = action.payload;
        },
        setOpenForm: (state, action: PayloadAction<boolean>) => {
            state.openForm = action.payload;
        },
        requestWorkbooks: (state, action: PayloadAction<TableauPassportID>) => {
            requestListWorkbooks(action.payload);
        },
        requestViews: (state, action: PayloadAction<TableauViewListRequest>) => {
            requestListViewsInWorkbook(action.payload);
        },
        setWorkbooks: (state, action: PayloadAction<TableauWorkbook[]>) => {
            state.tableauWorkbooks = action.payload;
        },
        setViews: (state, action: PayloadAction<TableauView[]>) => {
            state.tableauViews = action.payload;
        },
    },
})

export const tableauActions = tableauSlice.actions;

export default tableauSlice.reducer;