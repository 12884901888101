"use strict";

var _MappingMsgDefinations = require("../../../message/messageTypeDefinitions/mapping/MappingMsgDefinations");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _mappers = require("../../functions/mappers");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_LIST_MAPPERS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPONSE_LIST_MAPPERS, (0, _mappers.getStandaloneMixerMappers)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_CREATE_MAPPER, function (payload) {
  var result = (0, _mappers.createMixerMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_CREATE_MAPPER, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_REMOVE_MAPPER, function (payload) {
  var result = (0, _mappers.removeMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_REMOVE_MAPPER, result.id);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_UPDATE_MAPPER_HEADER, function (payload) {
  var result = (0, _mappers.updateMapperHeader)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_MAPPER_HEADER, payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_MAPPER, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_UPDATE_MAPPER_INSETS, function (payload) {
  var result = (0, _mappers.updateMixerMapperInsets)(payload);
  var response = {
    id: result.id,
    addedInsets: result.insets.filter(function (item) {
      return payload.addInsets.findIndex(function (added) {
        return added.id.value === item.id.value;
      }) >= 0;
    }),
    removedInsets: payload.removeInsets
  };
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_MAPPER_INSETS, response);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_MAPPER, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_UPDATE_MAPPER_DESTINATIONS, function (payload) {
  var result = (0, _mappers.updateMapperDestinations)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_MAPPER_DESTINATIONS, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_UPDATE_LOGICAL_GRID, function (payload) {
  var result = (0, _mappers.updateMapperLogicalGrid)(payload);
  if (result) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_LOGICAL_GRID, payload);
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_MAPPER, result);
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_UPDATE_LATENCY, function (payload) {
  console.error("REQUEST_UPDATE_LATENCY not implemented");
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_UPDATE_INSET_GEOMETRY, function (payload) {
  var result = (0, _mappers.updateMapperInsetGeometry)(payload);
  if (result) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_INSET_GEOMETRY, payload);
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_CREATE_MAPPER_HLS_STREAM, function (payload) {
  var result = (0, _mappers.addHLSStream)(payload);
  if (result) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPOND_MAPPER_HLS_STREAM, result.streams.find(function (s) {
      return s.streamData.type === 'HLS';
    }));
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_MAPPER, result);
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_REMOVE_MAPPER_HLS_STREAM, function (payload) {
  var result = (0, _mappers.removeHLSStream)(payload);
  if (result) {
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPOND_MAPPER_HLS_STREAM_REMOVED, payload);
    (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_MAPPER, result);
  }
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_LIST_EDGE_MAPPERS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.RESPONSE_LIST_EDGE_MAPPERS, (0, _mappers.getStandaloneEdgeMappers)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_CREATE_EDGE_MAPPER, function (payload) {
  var result = (0, _mappers.createEdgeMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_CREATE_EDGE_MAPPER, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_REMOVE_EDGE_MAPPER, function (payload) {
  var result = (0, _mappers.removeMapper)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_REMOVE_EDGE_MAPPER, result.id);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_UPDATE_EDGE_MAPPER_HEADER, function (payload) {
  var result = (0, _mappers.updateMapperHeader)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_EDGE_MAPPER_HEADER, payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_EDGE_MAPPER, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_UPDATE_EDGE_MAPPER_INSET, function (payload) {
  var result = (0, _mappers.updateEdgeMapperInsets)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_EDGE_MAPPER_INSET_UPDATED, payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_EDGE_MAPPER, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_REMOVE_EDGE_MAPPER_INSET, function (payload) {
  var result = (0, _mappers.removeEdgeMapperInset)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_EDGE_MAPPER_INSET_REMOVED, result.id);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_EDGE_MAPPER, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_MappingMsgDefinations.REQUEST_UPDATE_EDGE_MAPPER_DESTINATIONS, function (payload) {
  var result = (0, _mappers.updateMapperDestinations)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_MappingMsgDefinations.NOTIFY_UPDATE_EDGE_MAPPER_DESTINATIONS, payload);
});