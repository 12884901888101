import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import useBackgroundUtils from './BackgourndUtils';
import { useTranslation } from 'react-i18next';
import { SamSourceAsset } from 'userful-chronos-app-common-js/dist/models/sam/SAMAsset';
import { useSourceAssetDispatch, assetSliceActions } from 'userful-chronos-common-store/dist/sourceassets';
import { faClose, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';

interface IProps {
    asset: SamSourceAsset,
}

export default function BackgroundDeletion(props: IProps) {
    const { t } = useTranslation();

    const dispatch = useSourceAssetDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        setShow(true);
        e.stopPropagation();
    }

    const { isBackGroundInUsed } = useBackgroundUtils();

    const handleDelete = (e) => {
        dispatch(assetSliceActions.deleteAssetToServer(props.asset.id.value));
        setShow(false);
        e.stopPropagation();
    }

    return (
        <>
            <div
                className='backgroundDeleteIcon'
                id={`bg-select-${props.asset.id.value}`}
                onClick={handleShow}
            >
                <FontAwesomeIcon icon={faTrashCan} style={{ pointerEvents: "none" }} />
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {isBackGroundInUsed(props.asset) ?
                    <div>
                        <div className='modal-header-title' style={{ marginBottom: '0px' }}>
                            <Modal.Header>
                                <div>
                                    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: ' #D13E2C', marginRight: '8px' }} />
                                </div>
                                <div className='modal-close-btn'>
                                    <FontAwesomeIcon icon={faClose} onClick={handleClose} className="custom-close-btn" />
                                </div>
                            </Modal.Header>
                        </div>

                        <Modal.Body>({`${props.asset.name}`}) {t("SapphireApp.backgroundInUse")}. <br />
                            {t("SapphireApp.backgroundInUse2")}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={e => { handleClose(); e.stopPropagation(); }}>
                                {t("Shared.close")}
                            </Button>
                        </Modal.Footer>
                    </div>
                    :
                    <div>
                        <div className='modal-header-title' style={{ marginBottom: '0px' }}>
                            <Modal.Header>
                                <div>
                                    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: ' #D13E2C', marginRight: '8px' }} />
                                    {t("SapphireApp.deleteBackground")}?
                                </div>
                                <div className='modal-close-btn'>
                                    <FontAwesomeIcon icon={faClose} onClick={handleClose} className="custom-close-btn" />
                                </div>
                            </Modal.Header>
                        </div>

                        <Modal.Body>{t("SapphireApp.deleteBackgroundConfirm")} {`${props.asset.name}`}? </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={e => { handleClose(); e.stopPropagation(); }}>
                                {t("Shared.cancel")}
                            </Button>
                            <Button variant="danger" onClick={handleDelete}>
                                {t("Shared.delete")}
                            </Button>
                        </Modal.Footer>
                    </div>
                }
            </Modal>
        </>)
}