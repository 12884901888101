"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPlaylistNamesFromUsageData = exports.getAppNamesFromUsageData = void 0;
var _common = require("../common");
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
// 0, 1, 2 in recipes

/**
 * Returns translation keys for apps
 * @param data 
 * @returns 
 */
var getAppNamesFromUsageData = exports.getAppNamesFromUsageData = function getAppNamesFromUsageData(data) {
  return data.usageData.reduce(function (prev, curr) {
    if ((0, _common.isIDNotSet)(curr.id)) {
      // means playlist item
      return prev;
    }
    var item = (0, _common.isIDNotSet)(curr.context.appContext.appName) ? _common.APP_NAMES.ControlRoom // should be fixed in backend
    : "".concat(_common.APP_NAMES[curr.context.appContext.appName.value], " ").concat(curr.context.appContext.name);
    if (prev.includes(item)) {
      return prev;
    }
    return [].concat(_toConsumableArray(prev), [item]);
  }, []);
};

/**
* Returns playlistNames
* @param data 
* @returns 
*/
var getPlaylistNamesFromUsageData = exports.getPlaylistNamesFromUsageData = function getPlaylistNamesFromUsageData(data) {
  return data.usageData.reduce(function (prev, curr) {
    if (!(0, _common.isIDNotSet)(curr.id)) {
      // means mapper item
      return prev;
    }
    if (prev.includes(curr.context.name)) {
      return prev;
    }
    return [].concat(_toConsumableArray(prev), [curr.context.name]);
  }, []);
};