import { faArrowLeft, faCodeFork, faCube, faCucumber, faDisplay, faHardDrive, faMicrochip, faServer, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserfulTooltip } from "userful-chronos-common-ui/dist";
import { IClusterInfo, IConstellationInfo } from "../../../model/CarbonExternalModels";
import { AppSliceActions } from "../../../store/app/appSlice";
import { selectClusterTabsView, useCarbonDispatch, useCarbonSelector } from "../../../store/carbonStore";
import ClustersDropdown from "./clustersDropdown/ClustersDropdown";
import { useState } from "react";
import DevicesTable from "../../devices/table/DevicesTable";

import "./ClusterPage.scss"
import { getCPUUsage, getGPUUsage } from "../../../common/functions/formaters";

interface IProps {
    foundObject: { cluster: IClusterInfo; constellation: IConstellationInfo };
}

type TCluster = "OVERVIEW" | "DEVICES"

export default function ClusterPage({ foundObject }: IProps) {

    // const [clusterView, setClusterView] = useState<TCluster>("OVERVIEW");
    const clusterView: TCluster = useCarbonSelector(selectClusterTabsView);

    const dispatch = useCarbonDispatch();

    let totalUClients = 0;
    let totalGPULoad = 0;
    let totalCPUUsage = 0;

    let averageGPULoad = 0;
    let averageCPUUsage = 0;

    for (let i = 0; i < foundObject.cluster.clusterDeviceInfo.length; i++) {
        const clusterDeviceInfo = foundObject.cluster.clusterDeviceInfo[i];

        totalUClients += clusterDeviceInfo.endpoint.offline + clusterDeviceInfo.endpoint.online;
        totalGPULoad += getGPUUsage(clusterDeviceInfo.deviceUsage.gpu);
        totalCPUUsage += getCPUUsage(clusterDeviceInfo.deviceUsage.cpu);
    }

    averageGPULoad = Math.round(totalGPULoad / foundObject.cluster.clusterDeviceInfo.length);
    averageCPUUsage = Math.round(totalCPUUsage / foundObject.cluster.clusterDeviceInfo.length);

    const insertClusterOverview = () => {
        return <div className="cluster-overview">
            <div className="cluster-overview-container"><p className="cluster-container-header-text">Mapping Groups</p><div className="cluster-container-body"><FontAwesomeIcon icon={faCodeFork} /><p>{foundObject.cluster.mappingGroups}</p></div></div>
            <div className="cluster-overview-container"><p className="cluster-container-header-text">Devices</p><div className="cluster-container-body"><FontAwesomeIcon icon={faServer} /><p>{foundObject.cluster.clusterDeviceInfo.length}</p></div></div>
            <div className="cluster-overview-container"><p className="cluster-container-header-text">uClient Players</p><div className="cluster-container-body"><FontAwesomeIcon icon={faHardDrive} /><p>{totalUClients}</p></div></div>
            <div className="cluster-overview-container"><p className="cluster-container-header-text">Active Sources</p><div className="cluster-container-body"><FontAwesomeIcon icon={faCube} /><p>{foundObject.cluster.activeSources}</p></div></div>
            <div className="cluster-overview-container"><p className="cluster-container-header-text">Active Destinations</p><div className="cluster-container-body"><FontAwesomeIcon icon={faDisplay} /><p>{foundObject.cluster.activeDestinations}</p></div></div>
            <div className="cluster-overview-container"><p className="cluster-container-header-text">Average GPU Load</p><div className="cluster-container-body"><FontAwesomeIcon icon={faMicrochip} /><p>{averageGPULoad}%</p></div></div>
            <div className="cluster-overview-container"><p className="cluster-container-header-text">Average CPU Usage</p><div className="cluster-container-body"><FontAwesomeIcon icon={faMicrochip} /><p>{averageCPUUsage}%</p></div></div>
        </div>
    }

    return (
        <div className="genericWrapper">
            <div className="actionsBar">
                <div className="cluster normalGap">
                    <UserfulTooltip title={"Return to Clusters"}>
                        <button
                            className="iconButton"
                            onClick={(e) => {
                                dispatch(AppSliceActions.setAppView({ type: "CONSTELLATION", id: foundObject.constellation.definition.id }));
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                    </UserfulTooltip>
                    <ClustersDropdown foundObject={foundObject} />
                    <div className="multiStateWrapper">
                        <div
                            className={`mSbutton ${clusterView === "OVERVIEW" ? "active" : ""}`}
                            onClick={(e) => {
                                dispatch(AppSliceActions.setClusterTabsView("OVERVIEW"));
                            }}
                        >
                            <p>Content Overview</p>
                        </div>

                        <div
                            className={`mSbutton ${clusterView === "DEVICES" ? "active" : ""}`}
                            onClick={(e) => {
                                dispatch(AppSliceActions.setClusterTabsView("DEVICES"));
                            }}
                        >
                            <p>Device List</p>
                        </div>
                    </div>
                </div>
                <div className="cluster normalGap">
                    {/* <UserfulTooltip title={"Delete"}>
                        <button className="iconButton">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </UserfulTooltip> */}
                </div>
            </div>
            <div className="cluster-body">
                {clusterView === "OVERVIEW" && insertClusterOverview()}
                {clusterView === "DEVICES" && <DevicesTable foundObject={foundObject} />}
            </div>
        </div>
    );
}
