
import { IUForm, IUFormTrigger, IUFormOption, IUFormField, IUFormBlock, IUFormContentGroup, IUFormFieldCollection } from "../uFormUtils";

export const supportFormPreset = (

): IUForm => {
    let trigger: IUFormTrigger = {
        hideTrigger: true
    };

    const generateSettingsOption = (): IUFormOption => {

        const fullName: IUFormField = {
            title: "ConfigApp.Support.ReportPanel.FullName",
            type: "INPUT",
            key: "fullName",
            inputType: "text",
            placeholder: 'ConfigApp.Support.ReportPanel.FullNamePlaceholder',
            maxLength: 60,
            defaultValue: "",
            required: true
        };

        const emailAddress: IUFormField = {
            title: "ConfigApp.Support.ReportPanel.EmailAddress",
            type: "INPUT",
            key: "emailAddress",
            inputType: "text",
            restrictions: [{ type: "Email_Address_Validation", errorMsg: 'ConfigApp.Support.EmailAddressError' }],
            placeholder: 'UserManagement.EmailAddressPlaceholder',
            defaultValue: "",
            required: true
        };

        const phoneNumber: IUFormField = {
            title: 'ConfigApp.Support.ReportPanel.PhoneNum',
            type: "INPUT",
            key: "phoneNumber",
            inputType: "text",
            restrictions: [{ type: "Phone_Number_Validation", errorMsg: 'ConfigApp.Support.PhoneNumberError' }],
            placeholder: 'ConfigApp.Support.ReportPanel.PhoneNumDesc',
            defaultValue: "",
            required: true
        };


        const description: IUFormField = {
            title: "ConfigApp.Support.ReportPanel.Explain",
            type: "TEXTAREA",
            key: "description",
            placeholder: "ConfigApp.Support.ReportPanel.ExplainDesc",
            maxLength: 500,
            defaultValue: "",
            restrictions: [{ type: "IS_EMPTY", errorMsg: "ConfigApp.Support.DescriptionError" }],
            required: true
        };

        const fieldBlock: IUFormBlock = {
            title: 'ConfigApp.Support.ReportPanel.ContactSupport',
            description: 'ConfigApp.Support.ReportPanel.ContactSupportDescription',
            fields: [fullName, emailAddress, phoneNumber, description],
        };

        const supportContentGroup: IUFormContentGroup = { title: "SupportForm", blocks: [fieldBlock] };

        return {
            title: "",
            contentGroups: [supportContentGroup],
        };
    };

    return {
        trigger: trigger,
        header: {
            mainText: 'ConfigApp.Support.ReportPanel.header',
            description: 'ConfigApp.Support.ReportPanel.description',
        },
        body: {
            options: [generateSettingsOption()],
            hideMenu: true,
        },
        footer: {
            confirmText: "Shared.save",
            cancelText: "Shared.Cancel",
        },
    };
};
