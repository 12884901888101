import InitLoading from "../../common/InitLoading/InitLoading";
import { TAppStatus } from "../../store/app/appUtils";
import { useCarbonSelector, selectAppStatus } from "../../store/carbonStore";
import "./AppStatus.scss";

export default function AppStatus() {
    const appStatus: TAppStatus = useCarbonSelector(selectAppStatus);
    if (appStatus === "IDLE") return null;

    return (
        <div className="appStatus">
            <InitLoading />
        </div>
    );
}
