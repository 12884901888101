import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "./Input";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import Button from "../Button/Button";

interface IProps {
    onChange?(value: any): void;
    error?: boolean | undefined;
    success?: boolean | undefined;
    filedTitle?: React.ReactNode | undefined;
    help?: React.ReactNode | undefined;
    postChildren?: React.ReactNode | undefined;
    preChildren?: React.ReactNode | undefined;
}

const InputPassword = ({onChange, ...props}: IProps) => {

    const [value, setValue] = useState("");
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        if(onChange) onChange(value);
    }, [value])

    return (
        <Input value={value} type={show ? "text" : "password"} placeholder={"Enter password here..."} onChange={(e) => setValue(e.target.value)} {...props}
            insidePostChildren={<Button variant="secondary" iconOnly onClick={(e) => setShow(!show)}><FontAwesomeIcon icon={show ? faEyeSlash : faEye} /></Button>} />
    );

};
export default InputPassword;