import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    NOTIFY_CREATE_MAPPER_DESTINATION_MAPPING,
    NOTIFY_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION,
    NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPING, NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION,
    NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPING, NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION,
    RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS,
    RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS_COLLECTIONS
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/mapping/DestinationsMappingMsgDefinitions';

import mappingStore from "../mappingStore";
import { destinationSliceActions } from "../destinationMappingSlice";
import { MapperDestinationMapping, MapperDestinationMappingsCollection } from "userful-chronos-app-common-js/dist/models/mapping/DestinationMapping";


registerMsgHandler(RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS, (payload) => {
    console.debug('RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS');
    const data = JSON.parse(payload) as MapperDestinationMapping[];
    mappingStore.dispatch(destinationSliceActions.setMapperDestinationMappings(data));
});
registerMsgHandler(NOTIFY_CREATE_MAPPER_DESTINATION_MAPPING, (payload) => {
    console.debug('NOTIFY_CREATE_MAPPER_DESTINATION_MAPPING');
    const data = JSON.parse(payload) as MapperDestinationMapping;
    mappingStore.dispatch(destinationSliceActions.addMapperDestinationMapping(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPING, (payload) => {
    console.debug('NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPING');
    const data = JSON.parse(payload) as MapperDestinationMapping;
    mappingStore.dispatch(destinationSliceActions.updateMapperDestinationMapping(data));
});
registerMsgHandler(NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPING, (payload) => {
    console.debug('NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPING');
    const data = JSON.parse(payload) as StringID;
    mappingStore.dispatch(destinationSliceActions.removeMapperDestinationMapping(data));
});

registerMsgHandler(RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS_COLLECTIONS, (payload) => {
    console.debug('RESPONSE_LIST_MAPPING_DESTINATION_MAPPINGS_COLLECTIONS');
    const data = JSON.parse(payload) as MapperDestinationMappingsCollection[];
    mappingStore.dispatch(destinationSliceActions.setMapperDestinationMappingsCollections(data));
});
registerMsgHandler(NOTIFY_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, (payload) => {
    console.debug('NOTIFY_CREATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION');
    const data = JSON.parse(payload) as MapperDestinationMappingsCollection;
    mappingStore.dispatch(destinationSliceActions.addMapperDestinationMappingsCollection(data));
});
registerMsgHandler(NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, (payload) => {
    console.debug('NOTIFY_UPDATE_MAPPER_DESTINATION_MAPPINGS_COLLECTION');
    const data = JSON.parse(payload) as MapperDestinationMappingsCollection;
    mappingStore.dispatch(destinationSliceActions.updateMapperDestinationMappingsCollection(data));
});
registerMsgHandler(NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION, (payload) => {
    console.debug('NOTIFY_REMOVE_MAPPER_DESTINATION_MAPPINGS_COLLECTION');
    const data = JSON.parse(payload) as StringID;
    mappingStore.dispatch(destinationSliceActions.removeMapperDestinationMappingsCollection(data));
});