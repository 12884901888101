import React, { useState, useEffect } from "react";
import { IItem, IUFormField } from "../uFormUtils";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UFieldTitle from "./UFieldTitle/UFieldTitle";
import "./UFormFields.scss";
import { useTranslation } from "react-i18next";
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { SamSourceAsset } from "userful-chronos-app-common-js/dist/models/sam/SAMAsset";
import { useSourceAssetSelector } from "userful-chronos-common-store/dist/sourceassets";
import "./UDropdown.scss";

interface IProps {
    field: IUFormField;
    forceValue?: string | number | boolean | undefined;
    forceError?: string;
    onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
    hideTitle?: boolean;
}

export default function UDropdown({ field, forceValue, onItemChange, hideTitle }: IProps) {
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState("");
    const [inputClick, setInputClick] = useState(false);
    const [show, setShow] = useState(false);

    const existingAssets: SamSourceAsset[] = field.sourceAssetType
        ? useSourceAssetSelector((state) => state.assetStore.assets)
        : [];

    let value = useUFormContext().state.fieldState[field.key];
    if (forceValue) value = forceValue;
    const { onFieldChange } = useUFormContext();

    const { click } = useUFormContext().state;

    const onInputChange = (e) => {
        setInputValue(e.target.value);
        setShow(true);
    };

    const onInputClick = (e) => {
        setInputValue("");
        setInputClick(true);
        setShow(true);
    };

    const onDropdownItemClicked = (item: IItem) => {
        if (onItemChange) onItemChange({ [field.key]: item.value }, field);
        else onFieldChange({ [field.key]: item.value }, field);
    };

    const onDropdownAssetClicked = (item: SamSourceAsset) => {
        if (onItemChange) onItemChange({ [field.key]: item.id.value }, field);
        else onFieldChange({ [field.key]: item.id.value }, field);
    };

    useEffect(() => {
        if (field.sourceAssetType && existingAssets.length >= 1) {
            const asset = existingAssets.find((a) => a.id.value === value);
            if (asset) setInputValue(field.skipTranslation ? asset.name : t(asset.name));
        } else if (field.items.length >= 1) {
            const item = field.items.find((i) => i.value === value);
            if (item) setInputValue(field.skipTranslation ? item.label : t(item.label));
        }
    }, [value]);

    useEffect(() => {
        if (!inputClick) {
            if (field.sourceAssetType && existingAssets.length >= 1) {
                setShow(false);
                const asset = existingAssets.find((a) => a.id.value === value);
                if (asset) setInputValue(field.skipTranslation ? asset.name : t(asset.name));
            } else if (field.items.length >= 1) {
                setShow(false);
                const item = field.items.find((i) => i.value === value);
                if (item) setInputValue(field.skipTranslation ? item.label : t(item.label));
            }
        } else setInputClick(false);
    }, [click]);

    const insertItems = () => {
        if (field.sourceAssetType && existingAssets.length >= 1) {
            const filteredAssets = existingAssets.filter((asset) => asset.type === field.sourceAssetType);
            const searchedAssets = filteredAssets.filter((asset) =>
                asset.name.toLowerCase().includes(inputValue.toLowerCase())
            );

            if (searchedAssets.length >= 1) {
                return searchedAssets.map((item) => {
                    return (
                        <Dropdown.Item
                            key={field.title + "_" + item.id}
                            className={`uformDropdownItem ${item.id.value === value ? "dropSelected" : ""}`}
                            onClick={(e) => onDropdownAssetClicked(item)}
                        >
                            <div className="textInside">{field.skipTranslation ? item.name : t(item.name)}</div>
                            {item.description ? <div className="textDescription">{t(item.description)}</div> : null}
                        </Dropdown.Item>
                    );
                });
            } else {
                return (
                    <Dropdown.Item key={field.title + "_" + "Empty"} className="uformDropdownEmptyItem">
                        <div className="textInside">{t("Shared.notFound")}</div>
                    </Dropdown.Item>
                );
            }
        } else if (field.items.length >= 1) {
            const searchedItems = field.items.filter((item) =>
                (field.skipTranslation ? item.label : t(item.label)).toLowerCase().includes(inputValue.toLowerCase())
            );

            if (searchedItems.length >= 1) {
                return searchedItems.map((item) => {
                    return (
                        <Dropdown.Item
                            key={field.title + "_" + item.value}
                            className={`uformDropdownItem ${item.value === value ? "dropSelected" : ""}`}
                            onClick={(e) => onDropdownItemClicked(item)}
                        >
                            <div className="textInside">{field.skipTranslation ? item.label : t(item.label)}</div>
                            {item.description ? <div className="textDescription">{t(item.description)}</div> : null}
                        </Dropdown.Item>
                    );
                });
            } else {
                if (field.itemsAllowCreateIfNotFound) {
                    return (
                        <Dropdown.Item
                            key={field.title + "_" + "Create"}
                            className="uformDropdownItem"
                            onClick={(e) => {
                                console.log("NOT IMPLEMENTED YET");
                            }}
                        >
                            <div className="textInside">{t("Shared.Create") + " " + inputValue}</div>
                        </Dropdown.Item>
                    );
                }
                return (
                    <Dropdown.Item key={field.title + "_" + "Empty"} className="uformDropdownEmptyItem">
                        <div className="textInside">{t("Shared.notFound")}</div>
                    </Dropdown.Item>
                );
            }
        } else {
            return (
                <Dropdown.Item key={field.title + "_" + "Empty"} className="uformDropdownEmptyItem">
                    <div className="textInside">{t("Shared.nothingHere")}</div>
                </Dropdown.Item>
            );
        }
    };

//     console.warn(inputValue);

    return (
        <div className="fieldColumnWrapper">
            {!hideTitle && (
                <UFieldTitle
                    title={field.title}
                    help={field.help}
                    required={field.required}
                    length={length}
                    maxLength={field.maxLength}
                    minLength={field.minLength}
                />
            )}

            <Dropdown bsPrefix="UFormFullWidthWrapper" id="customDropdown">
                <Dropdown.Toggle
                    bsPrefix="uformDropdownButtonWithInput"
                    disabled={field.disabled}
                    id={field.id || "dropdownToggle"}
                >
                    <input
                        className="uInput"
                        onChange={onInputChange}
                        onClick={onInputClick}
                        type="text"
                        value={inputValue}
                        placeholder={t("Shared.choose") + " " + t(field.title)}
                        disabled={field.disabled}
                        style={{ cursor: "pointer" }}
                    />
                    <div className="relativeItem">
                        <FontAwesomeIcon className="UFormDropdownIcon" icon={faChevronDown} onClick={onInputClick} />
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="uformDropdownMenu" show={show}>
                    {insertItems()}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
