"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEnabledFontSettingsOfStyle = exports.fontStyleToSettings = exports.fontSettingsToStyle = exports.TEXTOVERLAY_DEFAULT = exports.FONT_STYLE_TO_SETTING_MAP = exports.DEFAULT_TEXTOVERLAY_CONFIG = void 0;
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
;
var FONT_STYLE_TO_SETTING_MAP = exports.FONT_STYLE_TO_SETTING_MAP = {
  'Demi Bold Italic': {
    bold: true,
    italic: true,
    condensed: false
  },
  'Demi Italic': {
    bold: false,
    italic: true,
    condensed: false
  },
  'Light Italic': {
    bold: false,
    italic: true,
    condensed: false
  },
  'Demi Bold': {
    bold: true,
    italic: false,
    condensed: false
  },
  'Book Oblique': {
    bold: false,
    italic: true,
    condensed: false
  },
  'Demi Oblique': {
    bold: false,
    italic: true,
    condensed: false
  },
  'Regular Oblique': {
    bold: false,
    italic: true,
    condensed: false
  },
  'Medium Italic': {
    bold: false,
    italic: true,
    condensed: false
  },
  'medium': {
    bold: false,
    italic: false,
    condensed: false
  },
  'Condensed Bold Oblique': {
    bold: true,
    italic: true,
    condensed: true
  },
  'BoldOblique': {
    bold: true,
    italic: true,
    condensed: false
  },
  'Bold Oblique': {
    bold: true,
    italic: true,
    condensed: false
  },
  'Condensed Oblique': {
    bold: false,
    italic: true,
    condensed: true
  },
  'Oblique': {
    bold: false,
    italic: true,
    condensed: false
  },
  'ExtraLight': {
    bold: false,
    italic: false,
    condensed: false
  },
  'Roman': {
    bold: false,
    italic: false,
    condensed: false
  },
  'Book': {
    bold: false,
    italic: false,
    condensed: false
  },
  'Bold': {
    bold: true,
    italic: false,
    condensed: false
  },
  'Bold Condensed': {
    bold: true,
    italic: false,
    condensed: true
  },
  'Bold Condensed Italic': {
    bold: true,
    italic: true,
    condensed: true
  },
  'Bold Italic': {
    bold: true,
    italic: true,
    condensed: false
  },
  'Condensed': {
    bold: false,
    italic: false,
    condensed: true
  },
  'Condensed Bold': {
    bold: true,
    italic: false,
    condensed: true
  },
  'Condensed Bold Italic': {
    bold: true,
    italic: true,
    condensed: true
  },
  'Condensed Italic': {
    bold: false,
    italic: true,
    condensed: true
  },
  'Italic': {
    bold: false,
    italic: true,
    condensed: false
  },
  'Regular': {
    bold: false,
    italic: false,
    condensed: false
  },
  'Regular Condensed': {
    bold: false,
    italic: false,
    condensed: true
  },
  'Regular Condensed Italic': {
    bold: false,
    italic: true,
    condensed: true
  },
  'Regular Italic': {
    bold: false,
    italic: true,
    condensed: false
  },
  'Thin': {
    bold: false,
    italic: false,
    condensed: false
  },
  'Demi': {
    bold: false,
    italic: false,
    condensed: false
  },
  'Light': {
    bold: false,
    italic: false,
    condensed: false
  },
  'Demi Light': {
    bold: false,
    italic: false,
    condensed: false
  },
  'Black': {
    bold: true,
    italic: false,
    condensed: false
  }
};
var fontSettingsToStyle = exports.fontSettingsToStyle = function fontSettingsToStyle(setting, fontName, availableFonts) {
  var foundFont = availableFonts.find(function (font) {
    return font.fontName.toLowerCase() === fontName.toLowerCase();
  });
  if (!foundFont || foundFont.styles.length < 1) {
    return {
      styleName: 'REGULAR'
    };
  }
  var _iterator = _createForOfIteratorHelper(foundFont.styles),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var style = _step.value;
      if (FONT_STYLE_TO_SETTING_MAP[style.styleName] && FONT_STYLE_TO_SETTING_MAP[style.styleName].bold === setting.bold && FONT_STYLE_TO_SETTING_MAP[style.styleName].italic === setting.italic && FONT_STYLE_TO_SETTING_MAP[style.styleName].condensed === setting.condensed) {
        return style;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return foundFont.styles[0];
};

// check if a font support bold/italic/condensed
var getEnabledFontSettingsOfStyle = exports.getEnabledFontSettingsOfStyle = function getEnabledFontSettingsOfStyle(fontName, availableFonts) {
  var foundFont = availableFonts.find(function (font) {
    return font.fontName.toLowerCase() === fontName.toLowerCase();
  });
  if (!foundFont || foundFont.styles.length < 2) {
    return {
      bold: false,
      italic: false,
      condensed: false
    };
  }
  var bold = foundFont.styles.findIndex(function (style) {
    return FONT_STYLE_TO_SETTING_MAP[style.styleName] && FONT_STYLE_TO_SETTING_MAP[style.styleName].bold;
  }) >= 0 && foundFont.styles.findIndex(function (style) {
    return FONT_STYLE_TO_SETTING_MAP[style.styleName] && !FONT_STYLE_TO_SETTING_MAP[style.styleName].bold;
  }) >= 0;
  var italic = foundFont.styles.findIndex(function (style) {
    return FONT_STYLE_TO_SETTING_MAP[style.styleName] && FONT_STYLE_TO_SETTING_MAP[style.styleName].italic;
  }) >= 0 && foundFont.styles.findIndex(function (style) {
    return FONT_STYLE_TO_SETTING_MAP[style.styleName] && !FONT_STYLE_TO_SETTING_MAP[style.styleName].italic;
  }) >= 0;
  var condensed = foundFont.styles.findIndex(function (style) {
    return FONT_STYLE_TO_SETTING_MAP[style.styleName] && FONT_STYLE_TO_SETTING_MAP[style.styleName].condensed;
  }) >= 0 && foundFont.styles.findIndex(function (style) {
    return FONT_STYLE_TO_SETTING_MAP[style.styleName] && !FONT_STYLE_TO_SETTING_MAP[style.styleName].condensed;
  }) >= 0;
  return {
    bold: bold,
    italic: italic,
    condensed: condensed
  };
};
var fontStyleToSettings = exports.fontStyleToSettings = function fontStyleToSettings(styleName) {
  return FONT_STYLE_TO_SETTING_MAP[styleName] || FONT_STYLE_TO_SETTING_MAP['Regular'];
};
;
var DEFAULT_TEXTOVERLAY_CONFIG = exports.DEFAULT_TEXTOVERLAY_CONFIG = {
  colour: 'ORANGE',
  font: {
    fontName: "Sans"
  },
  style: {
    styleName: 'Regular'
  },
  textSize: 32,
  shadow: true,
  outline: true,
  outlineColour: 'BLACK',
  xpos: 0.05,
  ypos: 0.05
};
var TEXTOVERLAY_DEFAULT = exports.TEXTOVERLAY_DEFAULT = {
  text: '',
  visibility: "INVISIBLE",
  config: DEFAULT_TEXTOVERLAY_CONFIG
};