import React, { forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import './DraggableAndResizable.scss';

export interface Position {
    top: number;
    left: number;
}

export interface Size {
    width: number;
    height: number;
}

export interface CursorPosition {
    x: number;
    y: number;
}

interface IProps {
    children: ReactNode;
    onShow?: Function;
    onHide?: Function;    
    minWidth?: number;
    minHeight?: number;
    initWidth?: number;
    initHeight?: number;
    initTop?: number;
    initLeft?: number;
    className?: string;
    autoZoomTarget?: HTMLElement;
    style?: Partial<React.CSSProperties>;
    onDragStop?: (position: Position, size: Size) => void;
    title?: ReactNode;
    onMove?: (position: Position) => void;
    onResize?: (size: Size) => void;
    headers?: ReactNode;
    noHeader?: boolean;
    disable?: boolean;
}

const DraggableAndResizable: React.ForwardRefRenderFunction<HTMLDivElement, IProps> = ({
    children,
    minWidth = 100,
    minHeight = 50,
    initWidth = 300,
    initHeight = 300,
    initTop = 10,
    initLeft = 10,
    ...props }: IProps, ref) => {
    const [position, setPostion] = useState<Position>({ top: initTop, left: initLeft });
    const [size, setSize] = useState<Size>({ width: initWidth, height: initHeight });
    const containerRef = useRef<HTMLDivElement>(null);
    const cursorPositionRef = useRef<CursorPosition>(null);
    const sizeRef = useRef<Size>(null);
    const [isDragging, setIsDragging] = useState(false);

    const callbackDragStopped = () => {
        if (props.onDragStop) {
            props.onDragStop(
                {
                    left: containerRef.current.offsetLeft,
                    top: containerRef.current.offsetTop,
                },
                {
                    width: containerRef.current.clientWidth,
                    height: containerRef.current.clientHeight,
                }
            );
        }
    };

    const startDragWithCtrl = (e: React.MouseEvent) => {
        if (e.ctrlKey) {
            startDrag(e);
        }
    }

    const startDrag = (e: React.MouseEvent) => {
        if (props.disable || !containerRef.current || e.nativeEvent.button === 2) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
        const event = e || window.event as MouseEvent;
        cursorPositionRef.current = {
            x: event.clientX,
            y: event.clientY,
        }
        window.addEventListener('mouseup', stopDrag);
        window.addEventListener('mousemove', doDrag);
    }

    const doDrag = (e: MouseEvent) => {
        if (!containerRef.current) {
            return;
        }
        const event = e || window.event as MouseEvent;
        const left = cursorPositionRef.current.x - event.clientX;
        const top = cursorPositionRef.current.y - event.clientY;
        cursorPositionRef.current = {
            x: event.clientX,
            y: event.clientY,
        }
        const updateTop = containerRef.current.offsetTop - top;
        const updateLeft = containerRef.current.offsetLeft - left;
        setPostion({
            top: updateTop,
            left: updateLeft,
        });
        if (props.onMove) {
            props.onMove({ top: updateTop, left: updateLeft });
        }
    }

    const stopDrag = () => {
        window.removeEventListener('mouseup', stopDrag);
        window.removeEventListener('mousemove', doDrag);
        callbackDragStopped();
        setIsDragging(false);
    }

    const startResize = (e: React.MouseEvent, resizeX: boolean, resizeY: boolean) => {
        if (props.disable || !containerRef.current || e.nativeEvent.button === 2) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
        cursorPositionRef.current = {
            x: resizeX ? e.clientX : -1,
            y: resizeY ? e.clientY : -1,
        }
        sizeRef.current = {
            width: Math.max(minWidth, size.width),
            height: Math.max(minHeight, size.height),
        }
        window.addEventListener('mouseup', stopResize);
        window.addEventListener('mousemove', doResize);
    }

    const doResize = (e: MouseEvent) => {
        const width = cursorPositionRef.current.x > 0 ?
            Math.max(sizeRef.current.width + e.clientX - cursorPositionRef.current.x, minWidth)
            :
            sizeRef.current.width;
        const height = cursorPositionRef.current.y > 0 ?
            Math.max(sizeRef.current.height + e.clientY - cursorPositionRef.current.y, minHeight)
            :
            sizeRef.current.height;
        setSize({
            width,
            height,
        });
        if (props.onResize) {
            props.onResize({ width, height });
        }
    }

    const stopResize = () => {
        window.removeEventListener('mouseup', stopResize);
        window.removeEventListener('mousemove', doResize);
        callbackDragStopped();
        setIsDragging(false);
    }

    useImperativeHandle(ref, () => containerRef.current);

    useEffect(() => {
        if(props.onShow) {
            props.onShow();
        }
        return () => {
            if (props.onHide) {
                props.onHide();
            }
        };
    },[])

    return <div className={`dnrContainer ${props.className ?? ''}`}
        style={{
            ...(props.style ?? {}),
            top: `${position.top}px`,
            left: `${position.left}px`,
            width: `${size.width}px`,
            height: `${size.height}px`,
            minWidth: `${minWidth}px`,
            minHeight: `${minHeight}px`,
        }}
        ref={containerRef}
        onMouseDown={startDragWithCtrl}
    >
        {!props.noHeader && <div className='header' onMouseDown={startDrag}>
            <div className="headerTitle">
                {props.title}
            </div>
            <div className="headerActions">
                {props.headers}
            </div>
        </div>}
        <div className="resizeRight" onMouseDown={(e) => startResize(e, true, false)} />
        <div className="resizeBottom" onMouseDown={(e) => startResize(e, false, true)} />
        <div className="resizeBoth" onMouseDown={(e) => startResize(e, true, true)} />
        {children}
        {isDragging && <div className='draggingMask' onMouseMove={(e) => e.preventDefault()} />}
    </div>
}

export default forwardRef(DraggableAndResizable);