export interface MenuItem {
    title: string,
    tooltip?: string,
    icon?: {
        iconName: any,
        width?: string,
        height?: string,
    },
    id?: string;
    disable?:boolean;
}

export interface SubMenuItem {
    subMenuHeaderTitle: string,
    icon?: {
        iconName: any,
        width?: string,
        height?: string,
    },
    tooltip?: string,
    subMenu: MenuItem[],
    disable?:boolean;
};

export type subMenuItems = Array<SubMenuItem>;

export type MixedMenuItem = MenuItem | SubMenuItem;

export const isMenuItem = (item: MixedMenuItem) => {
    return !(item as SubMenuItem).subMenuHeaderTitle;
}