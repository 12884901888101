import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { IndeterminateCheckbox } from "../../Widgets/Check/IndeterminateCheckbox";


const columnHelper = createColumnHelper<any>()
export const SelectionColumn = columnHelper.display({
    id: 'select',
    header: ({ table }) => (
        <div className='tableCheckboxHeader'>
            <IndeterminateCheckbox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
            />
        </div>
    ),
    cell: ({ row }) => (
        <div className="tableSelectColumn">
            <IndeterminateCheckbox
                checked={row.getIsSelected()}
                disabled={!row.getCanSelect()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
            />
        </div>
    ),
});
