import { BackupData, BackupUIWrapper, TBackupStatus } from "../../model/CarbonExternalModels"
import carbonStore from "../carbonStore";

export const convertBackupDataToBackupUIWrapper = (backupData: BackupData): BackupUIWrapper => {

    const constellation = carbonStore.getState().constellations.constellations[backupData.constellationID.value];
    const clusters = constellation.cluster;

    const foundCluster = clusters.find(cluster => cluster.id.value === backupData.clusterID.value);

    let status: TBackupStatus = "CREATED";
    if (!foundCluster.ip) status = "ERROR";

    return {
        ...backupData,
        status
    }
}