export function isPasswordValid(password: string): boolean {
    if (password === "") return true;

    // Check if the password is at least 8 characters long
    if (password.length < 8) {
        return false;
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
        return false;
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
        return false;
    }

    // Check for at least one number
    if (!/\d/.test(password)) {
        return false;
    }

    // Check for at least one symbol (non-alphanumeric character)
    if (!/[!@#$%^&*()-=_+{}[\]:;<>,.?/~]/.test(password)) {
        return false;
    }

    // If all criteria are met, the password is valid
    return true;
}

export function isEmailValid(email: string): boolean {
    // Regular expression for a basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the regular expression
    return emailRegex.test(email);
}
