export const path = "/kc";
export const auth_path = `${path}/auth/usd`;

// this provides only those clearance levels that user has access to
export const GET_CLEARANCE_DATA = `${auth_path}/clearancedata`;
export const GET_CLEARANCE_ACTION = `${auth_path}/clearanceaction`;
// this provides all the list of clearance levels
export const GET_ALL_CLEARANCE_LEVELS = `${auth_path}/clearancelevel`;
// list of filtered clearance levels needed for all content except sources and destinations 
export const GET_FILTERED_CLEARANCE_LEVELS = `${auth_path}/filteredclearancelevel`;

export const POST_ADD_CLEARANCE_LEVEL = `${auth_path}/clearancedata/add`;
export const POST_UPDATE_CLEARANCE_LEVEL = `${auth_path}/clearancedata/update`;
export const POST_DELETE_CLEARANCE_LEVEL = `${auth_path}/clearancedata/delete`;
