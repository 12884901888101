"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SNAP_SHOTS = exports.PASSIVE_MAPPERS = exports.MIXER_MAPPERS = exports.MAPPER_COLLECTIONS = exports.EDGE_MAPPERS = exports.CONTROL_ROOM = void 0;
var MIXER_MAPPERS = exports.MIXER_MAPPERS = [];
var EDGE_MAPPERS = exports.EDGE_MAPPERS = [];
var CONTROL_ROOM = exports.CONTROL_ROOM = [];
var SNAP_SHOTS = exports.SNAP_SHOTS = [];
var PASSIVE_MAPPERS = exports.PASSIVE_MAPPERS = [];
var MAPPER_COLLECTIONS = exports.MAPPER_COLLECTIONS = [];