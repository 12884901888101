export const copyTextToClipboard = (text: string): Promise<void> => {
    if ('clipboard' in navigator) {
        return navigator.clipboard.writeText(text);
    } else {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        return new Promise((resolve, reject) => {
            try {
                document.execCommand('copy', true, text);
                if (textArea) {
                    textArea.remove();
                }
                resolve();
            } catch (e) {
                reject(e);
            }
        })
    }
}


export function trimValue(value: string): string {
    if (!value) {
        return "";
    }
    // Trim front and last spaces
    const trimmedValue = value.trim();

    // Check if name is just spaces
    if (/^\s*$/.test(trimmedValue)) {
        return "";
    } else {
        return trimmedValue;
    }
}
