import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    OmnisController,
    MegapixelControllerHeader,
    OmnisDisplayAll,
} from "userful-chronos-app-common-js/dist/models/integration/megapixel";
import {
    NOTIFY_CONTROLLER_OMNIS_UPDATE,
    RESPONSE_ADD_OMNIS_CONTROLLER,
    RESPONSE_LIST_OMNIS_CONTROLLERS,
    RESPONSE_REBOOT_OMNIS_CONTROLLER,
    RESPONSE_REMOVE_OMNIS_CONTROLLER,
    RESPONSE_SET_OMNIS_DISPLAY,
    RESPONSE_SET_OMNIS_DISPLAY_BLACKOUT,
    RESPONSE_SET_OMNIS_DISPLAY_BRIGHTNESS,
    RESPONSE_SET_OMNIS_DISPLAY_FREEZE,
    RESPONSE_SET_OMNIS_DISPLAY_GAMMA,
    RESPONSE_SET_OMNIS_DISPLAY_GAMMAMAX,
    RESPONSE_SET_OMNIS_DISPLAY_GAMMAMIN,
    RESPONSE_UPDATE_OMNIS_CONTROLLER,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/megapixelMsgDefinitions";
import integrationStore from "../integrationStore";
import { megapixelActions } from "../megapixelSlice";

registerMsgHandler(RESPONSE_LIST_OMNIS_CONTROLLERS, (payload) => {
    console.debug("receive RESPONSE_LIST_OMNIS_CONTROLLERS");
    const data = JSON.parse(payload) as OmnisController[];
    integrationStore.dispatch(megapixelActions.setOmnisControllers(data));
});
registerMsgHandler(RESPONSE_ADD_OMNIS_CONTROLLER, (payload) => {
    console.debug("receive RESPONSE_ADD_OMNIS_CONTROLLER");
    const data = JSON.parse(payload) as MegapixelControllerHeader;
});
registerMsgHandler(RESPONSE_UPDATE_OMNIS_CONTROLLER, (payload) => {
    console.debug("receive RESPONSE_UPDATE_OMNIS_CONTROLLER");
    const data = JSON.parse(payload) as MegapixelControllerHeader;
});
registerMsgHandler(RESPONSE_REMOVE_OMNIS_CONTROLLER, (payload) => {
    console.debug("receive RESPONSE_REMOVE_OMNIS_CONTROLLER");
    const data = JSON.parse(payload) as StringID;
    integrationStore.dispatch(megapixelActions.removeOmnisController(data));
});
registerMsgHandler(NOTIFY_CONTROLLER_OMNIS_UPDATE, (payload) => {
    console.debug("receive NOTIFY_CONTROLLER_OMNIS_UPDATE");
    const data = JSON.parse(payload) as OmnisController;
    integrationStore.dispatch(megapixelActions.addOrUpdateOmnisControllers(data));
});

registerMsgHandler(RESPONSE_SET_OMNIS_DISPLAY, (payload) => {
    console.debug("receive RESPONSE_SET_OMNIS_DISPLAY");
    const data = JSON.parse(payload) as OmnisDisplayAll;
});
registerMsgHandler(RESPONSE_SET_OMNIS_DISPLAY_BRIGHTNESS, (payload) => {
    console.debug("receive RESPONSE_SET_OMNIS_DISPLAY_BRIGHTNESS");
    const data = JSON.parse(payload) as OmnisDisplayAll;
});
registerMsgHandler(RESPONSE_SET_OMNIS_DISPLAY_GAMMAMIN, (payload) => {
    console.debug("receive RESPONSE_SET_OMNIS_DISPLAY_GAMMAMIN");
    const data = JSON.parse(payload) as OmnisDisplayAll;
});
registerMsgHandler(RESPONSE_SET_OMNIS_DISPLAY_GAMMA, (payload) => {
    console.debug("receive RESPONSE_SET_OMNIS_DISPLAY_GAMMA");
    const data = JSON.parse(payload) as OmnisDisplayAll;
});
registerMsgHandler(RESPONSE_SET_OMNIS_DISPLAY_GAMMAMAX, (payload) => {
    console.debug("receive RESPONSE_SET_OMNIS_DISPLAY_GAMMAMAX");
    const data = JSON.parse(payload) as OmnisDisplayAll;
});
registerMsgHandler(RESPONSE_SET_OMNIS_DISPLAY_BLACKOUT, (payload) => {
    console.debug("receive RESPONSE_SET_OMNIS_DISPLAY_BLACKOUT");
    const data = JSON.parse(payload) as OmnisDisplayAll;
});
registerMsgHandler(RESPONSE_SET_OMNIS_DISPLAY_FREEZE, (payload) => {
    console.debug("receive RESPONSE_SET_OMNIS_DISPLAY_FREEZE");
    const data = JSON.parse(payload) as OmnisDisplayAll;
});
registerMsgHandler(RESPONSE_REBOOT_OMNIS_CONTROLLER, (payload) => {
    console.debug("receive RESPONSE_REBOOT_OMNIS_CONTROLLER");
    const data = JSON.parse(payload) as boolean;
});
