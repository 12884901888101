"use strict";

var _CDMMsgDefinations = require("../../../message/messageTypeDefinitions/cdm/CDMMsgDefinations");
var _cdm = require("../../functions/cdm");
var _videoWalls = require("../../functions/videoWalls");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_CDMMsgDefinations.REQUEST_LIST_VIDEOWALLS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_CDMMsgDefinations.RESPONSE_LIST_VIDEOWALLS, (0, _videoWalls.getStandaloneVideoWalls)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_CDMMsgDefinations.REQUEST_LIST_DESTINATION_SET, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_CDMMsgDefinations.RESPONSE_LIST_DESTINATION_SET, (0, _cdm.getStandaloneDestinationsSet)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_CDMMsgDefinations.REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_HEADER, function (payload) {
  (0, _StandaloneMsgHandler.respondFeatureNotAvailable)();
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_CDMMsgDefinations.REQUEST_UPDATE_VIDEOWALL_DEST_HEADER, function (payload) {
  (0, _StandaloneMsgHandler.respondFeatureNotAvailable)();
});