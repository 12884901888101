import { IUFormConditions, IUFormFieldState } from "./Context/UFormContext";
import {
    IUForm,
    IUFormField,
    IUFormOption,
    IUFormContentGroup,
    IUFormBlock,
    IUFormFieldCollection,
} from "./uFormUtils";
import validator from "email-validator";
import { trimValue } from "../Utils/UiUtils";
import { objectEqual } from "../utils";
import { synchronizeArrays } from "../Utils/objectComparison";

const updateFieldData = (currentFieldState: IUFormFieldState, givenFieldState: IUFormFieldState): IUFormFieldState => {
    // let updatedFieldData: IUFormFieldState = { ...currentFieldState };

    // for (const property in givenFieldState) {
    //     if (updatedFieldData.hasOwnProperty(property)) {
    //         if (Array.isArray(updatedFieldData[property])) {
    //             const givenArray = givenFieldState[property];
    //             const currentArray = [...updatedFieldData[property]];

    //             console.warn(givenArray);
    //             console.warn(currentArray);

    //             const updatedArray = synchronizeArrays(currentArray, givenArray);

    //             console.warn(updatedArray);

    //             updatedFieldData[property] = updatedArray;
    //         } else {
    //             updatedFieldData[property] = givenFieldState[property];
    //         }
    //     }
    // }

    return { ...currentFieldState, ...givenFieldState };
};

const updateFieldErrors = (currentFieldErrors: IUFormFieldState): IUFormFieldState => {
    let updatedFieldErrors: IUFormFieldState = { ...currentFieldErrors };

    for (const property in updatedFieldErrors) {
        if (typeof updatedFieldErrors[property] === "object") {
            for (const secondProperty in updatedFieldErrors[property]) {
                updatedFieldErrors[property][secondProperty] = "";
            }
        } else {
            updatedFieldErrors[property] = "";
        }
    }

    return updatedFieldErrors;
};

export const updateIUForm = (
    givenFieldState: IUFormFieldState,
    fieldState: IUFormFieldState,
    fieldErrors: IUFormFieldState,
    form: IUForm
): {
    updatedFormConditions: IUFormConditions;
    updatedFieldState: IUFormFieldState;
    updatedFieldErrors: IUFormFieldState;
} => {
    let updatedFieldData: IUFormFieldState = updateFieldData(fieldState, givenFieldState);
    let updatedFieldErrors: IUFormFieldState = updateFieldErrors(fieldErrors);

    const updatedFormConditions: IUFormConditions = { affirmationAllowed: true, equivalenceConfirmed: true };
    const fields: IUFormField[] = [];

    const givenOptions: IUFormOption[] = form.body.options;

    for (let i = 0; i < givenOptions.length; i++) {
        if (givenOptions[i]) {
            const givenContentGroups: IUFormContentGroup[] = givenOptions[i].contentGroups;

            for (let j = 0; j < givenContentGroups.length; j++) {
                if (givenContentGroups[j]) {
                    const givenBlocks: IUFormBlock[] = givenContentGroups[j].blocks;

                    // HIDES OR SHOWS THIS CONTENT GROUP
                    if (givenContentGroups[j].hideController) {
                        const hideController = givenContentGroups[j].hideController;
                        let hide = true;

                        if (updatedFieldData.hasOwnProperty(hideController.fieldKey)) {
                            if (hideController.successValues) {
                                for (let k = 0; k < hideController.successValues.length; k++) {
                                    if (updatedFieldData[hideController.fieldKey] === hideController.successValues[k]) {
                                        hide = false;
                                    }
                                }
                            } else if (hideController.failureValues) {
                                hide = false;

                                for (let k = 0; k < hideController.failureValues.length; k++) {
                                    if (updatedFieldData[hideController.fieldKey] === hideController.failureValues[k]) {
                                        hide = true;
                                    }
                                }
                            }
                        }

                        givenContentGroups[j].hide = hide;
                    }

                    if (!givenContentGroups[j].hide)
                        for (let k = 0; k < givenBlocks.length; k++) {
                            if (givenBlocks[k]) {
                                const givenFields: (IUFormField | IUFormFieldCollection)[] = givenBlocks[k].fields;

                                // HIDES OR SHOWS THIS BLOCK
                                if (givenBlocks[k].hideController) {
                                    const hideController = givenBlocks[k].hideController;
                                    let hide = true;

                                    if (updatedFieldData.hasOwnProperty(hideController.fieldKey)) {
                                        if (hideController.successValues) {
                                            for (let l = 0; l < hideController.successValues.length; l++) {
                                                if (
                                                    updatedFieldData[hideController.fieldKey] ===
                                                    hideController.successValues[l]
                                                ) {
                                                    hide = false;
                                                }
                                            }
                                        } else if (hideController.failureValues) {
                                            hide = false;

                                            for (let l = 0; l < hideController.failureValues.length; l++) {
                                                if (
                                                    updatedFieldData[hideController.fieldKey] ===
                                                    hideController.failureValues[l]
                                                ) {
                                                    hide = true;
                                                }
                                            }
                                        }
                                    }

                                    givenBlocks[k].hide = hide;
                                }
                                if (!givenBlocks[k].hide)
                                    for (let l = 0; l < givenFields.length; l++) {
                                        if (givenFields[l]) {
                                            const givenField = givenFields[l];

                                            // HIDES OR SHOWS THIS BLOCK
                                            if (givenFields[l].hideController) {
                                                const hideController = givenFields[l].hideController;
                                                let hide = true;

                                                if (updatedFieldData.hasOwnProperty(hideController.fieldKey)) {
                                                    if (hideController.successValues) {
                                                        for (let m = 0; m < hideController.successValues.length; m++) {
                                                            if (
                                                                updatedFieldData[hideController.fieldKey] ===
                                                                hideController.successValues[m]
                                                            ) {
                                                                hide = false;
                                                            }
                                                        }
                                                    } else if (hideController.failureValues) {
                                                        hide = false;

                                                        for (let m = 0; m < hideController.failureValues.length; m++) {
                                                            if (
                                                                updatedFieldData[hideController.fieldKey] ===
                                                                hideController.failureValues[m]
                                                            ) {
                                                                hide = true;
                                                            }
                                                        }
                                                    }
                                                }

                                                givenFields[l].hide = hide;
                                            }

                                            if (!givenFields[l].hide) {
                                                if (givenField.type === "COMPLEX") {
                                                    if (updatedFieldData.hasOwnProperty(givenField.key)) {
                                                        const item: IUFormFieldState = updatedFieldData[givenField.key];

                                                        for (const property in item) {
                                                            const value = item[property] + "";
                                                            const index = givenField.items.findIndex(
                                                                (i) => i.key === property
                                                            );

                                                            if (index >= 0) {
                                                                if (givenField.items[index].required) {
                                                                    if (value) {
                                                                        if ((value + "").length <= 0) {
                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        } else if (
                                                                            +value < givenField.items[index].min
                                                                        ) {
                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        } else if (
                                                                            +value > givenField.items[index].max
                                                                        ) {
                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        }
                                                                    }
                                                                }

                                                                if (value !== givenField.items[index].defaultValue) {
                                                                    updatedFormConditions.equivalenceConfirmed = false;
                                                                }

                                                                if (givenField.items[index].restrictions) {
                                                                    for (
                                                                        let m = 0;
                                                                        m < givenField.items[index].restrictions.length;
                                                                        m++
                                                                    ) {
                                                                        const restriction =
                                                                            givenField.items[index].restrictions[m];

                                                                        if (restriction.type === "EXACTLY") {
                                                                            const secondIndex =
                                                                                restriction.value.findIndex(
                                                                                    (name) => name === value
                                                                                );

                                                                            if (secondIndex >= 0) {
                                                                                if (
                                                                                    value !==
                                                                                    givenField.items[index].defaultValue
                                                                                ) {
                                                                                    if (restriction.errorMsg)
                                                                                        updatedFieldErrors[
                                                                                            givenField.key
                                                                                        ][givenField.items[index].key] =
                                                                                            restriction.errorMsg;
                                                                                    else
                                                                                        updatedFieldErrors[
                                                                                            givenField.key
                                                                                        ][givenField.items[index].key] =
                                                                                            "Shared.standardError";

                                                                                    updatedFormConditions.affirmationAllowed =
                                                                                        false;
                                                                                }
                                                                            }
                                                                        } else if (
                                                                            restriction.type === "IS_NOT_EMPTY"
                                                                        ) {
                                                                            if (value.length >= 1) {
                                                                                if (restriction.errorMsg)
                                                                                    updatedFieldErrors[givenField.key][
                                                                                        givenField.items[index].key
                                                                                    ] = restriction.errorMsg;
                                                                                else
                                                                                    updatedFieldErrors[givenField.key][
                                                                                        givenField.items[index].key
                                                                                    ] = "Shared.standardError";

                                                                                updatedFormConditions.affirmationAllowed =
                                                                                    false;
                                                                            }
                                                                        } else if (restriction.type === "IS_EMPTY") {
                                                                            if (!value || value.length <= 0) {
                                                                                if (restriction.errorMsg)
                                                                                    updatedFieldErrors[givenField.key][
                                                                                        givenField.items[index].key
                                                                                    ] = restriction.errorMsg;
                                                                                else
                                                                                    updatedFieldErrors[givenField.key][
                                                                                        givenField.items[index].key
                                                                                    ] = "Shared.standardError";

                                                                                updatedFormConditions.affirmationAllowed =
                                                                                    false;
                                                                            }
                                                                        } else if (restriction.type === "CONTAINS") {
                                                                            const secondIndex =
                                                                                restriction.value.findIndex((name) =>
                                                                                    value.includes(name)
                                                                                );

                                                                            if (secondIndex >= 0) {
                                                                                if (
                                                                                    value !==
                                                                                    givenField.items[index].defaultValue
                                                                                ) {
                                                                                    if (restriction.errorMsg)
                                                                                        updatedFieldErrors[
                                                                                            givenField.key
                                                                                        ][givenField.items[index].key] =
                                                                                            restriction.errorMsg;
                                                                                    else
                                                                                        updatedFieldErrors[
                                                                                            givenField.key
                                                                                        ][givenField.items[index].key] =
                                                                                            "Shared.standardError";

                                                                                    updatedFormConditions.affirmationAllowed =
                                                                                        false;
                                                                                }
                                                                            }
                                                                        } else if (
                                                                            restriction.type === "DOES_NOT_CONTAIN"
                                                                        ) {
                                                                            const secondIndex =
                                                                                restriction.value.findIndex((name) =>
                                                                                    value.includes(name)
                                                                                );

                                                                            if (secondIndex === -1) {
                                                                                if (
                                                                                    value !==
                                                                                    givenField.items[index].defaultValue
                                                                                ) {
                                                                                    if (restriction.errorMsg)
                                                                                        updatedFieldErrors[
                                                                                            givenField.key
                                                                                        ][givenField.items[index].key] =
                                                                                            restriction.errorMsg;
                                                                                    else
                                                                                        updatedFieldErrors[
                                                                                            givenField.key
                                                                                        ][givenField.items[index].key] =
                                                                                            "Shared.standardError";

                                                                                    updatedFormConditions.affirmationAllowed =
                                                                                        false;
                                                                                }
                                                                            }
                                                                        } else if (restriction.type === "STARTS_WITH") {
                                                                            const secondIndex =
                                                                                restriction.value.findIndex((name) =>
                                                                                    value.startsWith(name)
                                                                                );

                                                                            if (secondIndex >= 0) {
                                                                                if (
                                                                                    value !==
                                                                                    givenField.items[index].defaultValue
                                                                                ) {
                                                                                    if (restriction.errorMsg)
                                                                                        updatedFieldErrors[
                                                                                            givenField.key
                                                                                        ][givenField.items[index].key] =
                                                                                            restriction.errorMsg;
                                                                                    else
                                                                                        updatedFieldErrors[
                                                                                            givenField.key
                                                                                        ][givenField.items[index].key] =
                                                                                            "Shared.standardError";

                                                                                    updatedFormConditions.affirmationAllowed =
                                                                                        false;
                                                                                }
                                                                            }
                                                                        } else if (restriction.type === "ENDS_WITH") {
                                                                            const secondIndex =
                                                                                restriction.value.findIndex((name) =>
                                                                                    value.endsWith(name)
                                                                                );

                                                                            if (secondIndex >= 0) {
                                                                                if (
                                                                                    value !==
                                                                                    givenField.items[index].defaultValue
                                                                                ) {
                                                                                    if (restriction.errorMsg)
                                                                                        updatedFieldErrors[
                                                                                            givenField.key
                                                                                        ][givenField.items[index].key] =
                                                                                            restriction.errorMsg;
                                                                                    else
                                                                                        updatedFieldErrors[
                                                                                            givenField.key
                                                                                        ][givenField.items[index].key] =
                                                                                            "Shared.standardError";

                                                                                    updatedFormConditions.affirmationAllowed =
                                                                                        false;
                                                                                }
                                                                            }
                                                                        }
                                                                        // if input should just contain alpha numeric characters and no spaces
                                                                        else if (
                                                                            restriction.type ===
                                                                            "AlphaNumericCharacters"
                                                                        ) {
                                                                            if (
                                                                                restriction.value.findIndex(
                                                                                    (item) => item === value
                                                                                ) >= 0 ||
                                                                                value.length === 0 ||
                                                                                !/^[a-zA-Z0-9]+$/.test(value)
                                                                            ) {
                                                                                if (restriction.errorMsg)
                                                                                    updatedFieldErrors[givenField.key][
                                                                                        givenField.items[index].key
                                                                                    ] = restriction.errorMsg;
                                                                                else
                                                                                    updatedFieldErrors[givenField.key][
                                                                                        givenField.items[index].key
                                                                                    ] = "Shared.standardError";

                                                                                updatedFormConditions.affirmationAllowed =
                                                                                    false;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    fields.push(givenField);
                                                    if (updatedFieldData.hasOwnProperty(givenField.key)) {
                                                        const value = updatedFieldData[givenField.key];
                                                        if (givenField.required) {
                                                            if (value) {
                                                                if (
                                                                    givenField.cardDef &&
                                                                    givenField.cardDef.multiSelect
                                                                ) {
                                                                    if (value.length <= 0)
                                                                        updatedFormConditions.affirmationAllowed =
                                                                            false;
                                                                }

                                                                if ((value + "").length <= 0) {
                                                                    // console.warn("(value + '').length <= 0");
                                                                    updatedFormConditions.affirmationAllowed = false;
                                                                } else if (+value < givenField.min) {
                                                                    // console.warn("+value < givenField.min");
                                                                    updatedFormConditions.affirmationAllowed = false;
                                                                } else if (+value > givenField.max) {
                                                                    // console.warn("+value > givenField.max");
                                                                    updatedFormConditions.affirmationAllowed = false;
                                                                }
                                                            } else {
                                                                updatedFormConditions.affirmationAllowed = false;
                                                            }
                                                        }

                                                        if (value !== givenField.defaultValue) {
                                                            updatedFormConditions.equivalenceConfirmed = false;
                                                        }

                                                        if (givenField.restrictions) {
                                                            for (let m = 0; m < givenField.restrictions.length; m++) {
                                                                const restriction = givenField.restrictions[m];
                                                                if (restriction.type === "EXACTLY") {
                                                                    const secondIndex = restriction.value.findIndex(
                                                                        (name) => name === value
                                                                    );

                                                                    if (secondIndex >= 0) {
                                                                        if (value !== givenField.defaultValue) {
                                                                            if (restriction.errorMsg)
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    restriction.errorMsg;
                                                                            else
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    "Shared.standardError";

                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        }
                                                                    }
                                                                } else if (restriction.type === "IS_NOT_EMPTY") {
                                                                    if (value.length >= 1) {
                                                                        if (restriction.errorMsg)
                                                                            updatedFieldErrors[givenField.key] =
                                                                                restriction.errorMsg;
                                                                        else
                                                                            updatedFieldErrors[givenField.key] =
                                                                                "Shared.standardError";

                                                                        updatedFormConditions.affirmationAllowed =
                                                                            false;
                                                                    }
                                                                } else if (restriction.type === "IS_EMPTY") {
                                                                    if (trimValue(value).length <= 0) {
                                                                        if (restriction.errorMsg)
                                                                            updatedFieldErrors[givenField.key] =
                                                                                restriction.errorMsg;
                                                                        else
                                                                            updatedFieldErrors[givenField.key] =
                                                                                "Shared.standardError";

                                                                        updatedFormConditions.affirmationAllowed =
                                                                            false;
                                                                    }
                                                                } else if (restriction.type === "PASSWORD") {
                                                                    if (
                                                                        value[`Password Error`] ||
                                                                        value[`Confirm Password Error`] ||
                                                                        value[`Password`].length === 0 ||
                                                                        value[`Confirm Password`].length === 0
                                                                    ) {
                                                                        if (restriction.errorMsg)
                                                                            updatedFieldErrors[givenField.key] =
                                                                                restriction.errorMsg;
                                                                        else
                                                                            updatedFieldErrors[givenField.key] =
                                                                                "Shared.standardError";

                                                                        updatedFormConditions.affirmationAllowed =
                                                                            false;
                                                                    }
                                                                } else if (restriction.type === "UNIQUEPASSWORD") {
                                                                    const hasSpaceRegex = /\s/;

                                                                    if (hasSpaceRegex.test(value)) {
                                                                        if (restriction.errorMsg)
                                                                            updatedFieldErrors[givenField.key] =
                                                                                restriction.errorMsg;
                                                                        else
                                                                            updatedFieldErrors[givenField.key] =
                                                                                "Shared.standardError";

                                                                        updatedFormConditions.affirmationAllowed =
                                                                            false;
                                                                    }
                                                                } else if (restriction.type === "HostName") {
                                                                    // Regular expression for hostname validation
                                                                    const hostnameRegex = /^[a-zA-Z0-9.-]+$/;
                                                                    // Regular expression for IP address validation
                                                                    const ipAddressRegex =
                                                                        /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;

                                                                    if (
                                                                        !hostnameRegex.test(value) &&
                                                                        !ipAddressRegex.test(value) &&
                                                                        value.length > 0
                                                                    ) {
                                                                        if (restriction.errorMsg)
                                                                            updatedFieldErrors[givenField.key] =
                                                                                restriction.errorMsg;
                                                                        else
                                                                            updatedFieldErrors[givenField.key] =
                                                                                "Shared.standardError";

                                                                        updatedFormConditions.affirmationAllowed =
                                                                            false;
                                                                    }
                                                                } else if (restriction.type === "Port") {
                                                                    const parsedPort = parseInt(value, 10);

                                                                    if (
                                                                        isNaN(parsedPort) ||
                                                                        !Number.isInteger(parsedPort) ||
                                                                        /\D/.test(value) ||
                                                                        parsedPort < 0 ||
                                                                        parsedPort > 65535
                                                                    ) {
                                                                        if (restriction.errorMsg)
                                                                            updatedFieldErrors[givenField.key] =
                                                                                restriction.errorMsg;
                                                                        else
                                                                            updatedFieldErrors[givenField.key] =
                                                                                "Shared.standardError";

                                                                        updatedFormConditions.affirmationAllowed =
                                                                            false;
                                                                    }
                                                                } else if (restriction.type === "CONTAINS") {
                                                                    const secondIndex = restriction.value.findIndex(
                                                                        (name) => name.includes(value)
                                                                    );

                                                                    if (secondIndex >= 0) {
                                                                        if (value !== givenField.defaultValue) {
                                                                            if (restriction.errorMsg)
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    restriction.errorMsg;
                                                                            else
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    "Shared.standardError";

                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        }
                                                                    }
                                                                } else if (restriction.type === "DOES_NOT_CONTAIN") {
                                                                    const secondIndex = restriction.value.findIndex(
                                                                        (name) => name.includes(value)
                                                                    );

                                                                    if (secondIndex === -1) {
                                                                        if (value !== givenField.defaultValue) {
                                                                            if (restriction.errorMsg)
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    restriction.errorMsg;
                                                                            else
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    "Shared.standardError";

                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        }
                                                                    }
                                                                } else if (restriction.type === "STARTS_WITH") {
                                                                    const secondIndex = restriction.value.findIndex(
                                                                        (name) => name.startsWith(value)
                                                                    );

                                                                    if (secondIndex >= 0) {
                                                                        if (value !== givenField.defaultValue) {
                                                                            if (restriction.errorMsg)
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    restriction.errorMsg;
                                                                            else
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    "Shared.standardError";

                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        }
                                                                    }
                                                                } else if (restriction.type === "ENDS_WITH") {
                                                                    const secondIndex = restriction.value.findIndex(
                                                                        (name) => name.endsWith(value)
                                                                    );

                                                                    if (secondIndex >= 0) {
                                                                        if (value !== givenField.defaultValue) {
                                                                            if (restriction.errorMsg)
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    restriction.errorMsg;
                                                                            else
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    "Shared.standardError";

                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        }
                                                                    }
                                                                }

                                                                // if input should just contain alpha numeric characters and no spaces
                                                                else if (
                                                                    restriction.type === "AlphaNumericCharacters"
                                                                ) {
                                                                    if (
                                                                        restriction.value.findIndex(
                                                                            (item) => item === value
                                                                        ) >= 0 ||
                                                                        value.length === 0 ||
                                                                        !/^[a-zA-Z0-9]+$/.test(value)
                                                                    ) {
                                                                        if (value !== givenField.defaultValue) {
                                                                            if (restriction.errorMsg)
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    restriction.errorMsg;
                                                                            else
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    "Shared.standardError";

                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        }
                                                                    }
                                                                }

                                                                // if input should just contain alpha numeric characters and no spaces
                                                                else if (restriction.type === "Email_Address") {
                                                                    if (
                                                                        value !== "" &&
                                                                        value !== null &&
                                                                        (!validator.validate(value) ||
                                                                            restriction.value.findIndex(
                                                                                (item) => item === value
                                                                            ) >= 0)
                                                                    ) {
                                                                        if (value !== givenField.defaultValue) {
                                                                            if (restriction.errorMsg)
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    restriction.errorMsg;
                                                                            else
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    "Shared.standardError";

                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        }
                                                                    }
                                                                }

                                                                // only email validator
                                                                else if (
                                                                    restriction.type === "Email_Address_Validation"
                                                                ) {
                                                                    if (!validator.validate(value)) {
                                                                        if (value !== givenField.defaultValue) {
                                                                            if (restriction.errorMsg)
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    restriction.errorMsg;
                                                                            else
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    "Shared.standardError";

                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        }
                                                                    }
                                                                }

                                                                // phone number validator
                                                                else if (
                                                                    restriction.type === "Phone_Number_Validation"
                                                                ) {
                                                                    if (!/^\d+$/.test(value)) {
                                                                        if (value !== givenField.defaultValue) {
                                                                            if (restriction.errorMsg)
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    restriction.errorMsg;
                                                                            else
                                                                                updatedFieldErrors[givenField.key] =
                                                                                    "Shared.standardError";

                                                                            updatedFormConditions.affirmationAllowed =
                                                                                false;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                            }
                        }
                }
            }
        }
    }

    if (updatedFormConditions.equivalenceConfirmed) {
        if (form.editForm) updatedFormConditions.affirmationAllowed = false;
    }

    return {
        updatedFormConditions: updatedFormConditions,
        updatedFieldState: updatedFieldData,
        updatedFieldErrors: updatedFieldErrors,
    };
};
