
import { FileExtension } from "userful-chronos-app-common-js/dist/models/sam/SAMUtils";


export default function UploadFileExtensionValidation(filename: string) {

    var fileExt = filename.split('.');
    if (fileExt.length > 1 && FileExtension.includes(fileExt.pop().toLowerCase()) && fileExt[0] !== "")
        return true;
    

    return false;


}