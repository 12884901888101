import { AxiosResponse } from 'axios';
import { apiGet, apiPost, getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import { POST_UPDATE_GROUP, POST_ASSIGN_USERS_TO_GROUPS, GET_USERMGT_DATA, POST_ADD_USER, POST_UPDATE_USER, POST_UPDATE_PASSWORD, POST_DELETE_USER, POST_DISABLE_USER, POST_ASSIGN_GROUPS_TO_USER, POST_ENABLE_USER, POST_DELETE_GROUP, POST_COPY_GROUP } from './apiPaths';
import { UserManagementData } from 'userful-chronos-app-common-js/dist/models/usermgt/usermgt';
import { GET_LOGIN_EVENTS, GET_ADMIN_EVENTS, POST_ADD_GROUP } from './apiPaths';
import { usermgtSliceActions } from '../usermgtSlice';
import usermgtStore from '../usermgtStore';
import { UpdateKeycloakGroup, KeycloakGroupID, AddKeycloakUser, UpdateKeycloakUser, KeycloakUserPasswordReset, KeycloakUserID, AssignGroupsToUser, AddKeycloakGroup, CopyGroup, AssignUsersToGroup } from 'userful-chronos-app-common-js/dist/models/usermgt/usermgt';
import { toast } from "react-toastify";
import { ConfirmationToastContentStore } from '../../../Container/ConfirmationToastContentStore';
import { EventLogRequest } from 'userful-chronos-app-common-js/dist/models/monitoring/Logs';

const errorHandler = (err) => {
    console.debug("Error calling init app api ");
    console.debug(err);
}

const getError = (response: any) => {
    return ({
        success: false,
        arg: response.data.arg ? response.data.arg : "",
        errorCode: response.data.errorCode ? response.data.errorCode : "",
        moduleID: response.data.moduleID ? response.data.moduleID : "",
        severity: response.data.severity ? response.data.severity : '',
        msg: response.data.unlocalizedMsg ? response.data.unlocalizedMsg : ""
    }
    );
}


export const checkReponseIsValid = (name: string, response: AxiosResponse): boolean => {
    if (response.status >= 400) {
        console.log(`${name} error: ${response.status}`);
        return false;
    }
    if (!response.data || response.data.severity !== "SUCCESS") {
        console.log(`${name} empty response`);
        return false;
    }
    return true;
}

export const getUserManagementDataFromServer = (token: string) => {

    const t1 = getGlobalStates().host + GET_USERMGT_DATA;

    apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            return;
        }
        console.debug(`receive authorize`, response.data);
        usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
        usermgtStore.dispatch(usermgtSliceActions.setInitBool(true));
    }).catch((err) => console.warn("failed", err));
}

export const getLoginEvents = (data: EventLogRequest, token: string) => {

    const t1 = getGlobalStates().host + GET_LOGIN_EVENTS;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            return;
        }
        console.debug(`receive authorize`, response.data);
        usermgtStore.dispatch(usermgtSliceActions.recieveLoginEvents(response.data.arg));
    }).catch((err) => console.warn("failed", err));
}

export const getAdminEvents = (data: EventLogRequest, token: string) => {

    const t1 = getGlobalStates().host + GET_ADMIN_EVENTS;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            return;
        }
        console.debug(`receive authorize`, response.data);
        usermgtStore.dispatch(usermgtSliceActions.recieveAdminEvents(response.data.arg));
    }).catch((err) => console.warn("failed", err));
}



export const addUser = (userData: AddKeycloakUser, token: string) => {

    const t1 = getGlobalStates().host + POST_ADD_USER;

    apiPost(t1, userData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(usermgtSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            usermgtStore.dispatch(usermgtSliceActions.altLoadState("SUCCESS"));
            const customDisplay = ConfirmationToastContentStore(userData.userProfile.userName, "Save");
            toast(customDisplay, { containerId: 'confirmationContainer' });

        }).catch((err) => console.warn("failed to add user", err));

}


export const editUser = (userData: UpdateKeycloakUser, token: string) => {

    const t1 = getGlobalStates().host + POST_UPDATE_USER;

    apiPost(t1, userData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(usermgtSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            usermgtStore.dispatch(usermgtSliceActions.altLoadState("SUCCESS"));
            const customDisplay = ConfirmationToastContentStore(userData.userProfile.userName, "Edit");
            toast(customDisplay, { containerId: 'confirmationContainer' });
        }).catch((err) => console.warn("failed to edit user", err));

}

export const resetPassword = (userData: KeycloakUserPasswordReset, token: string) => {

    const t1 = getGlobalStates().host + POST_UPDATE_PASSWORD;

    apiPost(t1, userData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(usermgtSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            usermgtStore.dispatch(usermgtSliceActions.altLoadState("SUCCESS"));
        }).catch((err) => console.warn("failed to edit user", err));

}

export const deleteUser = (userData: KeycloakUserID, token: string) => {

    const t1 = getGlobalStates().host + POST_DELETE_USER;

    apiPost(t1, userData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(usermgtSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            usermgtStore.dispatch(usermgtSliceActions.altLoadState("SUCCESS"));
        }).catch((err) => console.warn("failed to delete user", err));

}

export const disableUser = (userData: KeycloakUserID, token: string) => {

    const t1 = getGlobalStates().host + POST_DISABLE_USER;

    apiPost(t1, userData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(usermgtSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            usermgtStore.dispatch(usermgtSliceActions.altLoadState("SUCCESS"));
        }).catch((err) => console.warn("failed to disable user", err));

}

export const enableUser = (userData: KeycloakUserID, token: string) => {

    const t1 = getGlobalStates().host + POST_ENABLE_USER;

    apiPost(t1, userData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(usermgtSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            usermgtStore.dispatch(usermgtSliceActions.altLoadState("SUCCESS"));
        }).catch((err) => console.warn("failed to disable user", err));

}



export const assignGroupsToUser = (userData: AssignGroupsToUser, token: string) => {

    const t1 = getGlobalStates().host + POST_ASSIGN_GROUPS_TO_USER;

    apiPost(t1, userData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            const userName = (response.data.arg as UserManagementData).users.filter((user) => user.id.value === userData.userID.value)[0].userProfile.userName;
            const customDisplay = ConfirmationToastContentStore(userName, "Edit");
            toast(customDisplay, { containerId: 'confirmationContainer' });
        }).catch((err) => console.warn("failed to assign groups to user", err));

}

export const addGroup = (groupData: AddKeycloakGroup, token: string) => {

    const t1 = getGlobalStates().host + POST_ADD_GROUP;

    apiPost(t1, groupData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(usermgtSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            usermgtStore.dispatch(usermgtSliceActions.altLoadState("SUCCESS"));
            const customDisplay = ConfirmationToastContentStore(groupData.name, "Save");
            toast(customDisplay, { containerId: 'confirmationContainer' });
        }).catch((err) => console.warn("failed to add group", err));

}

export const deletegroup = (groupData: KeycloakGroupID, token: string) => {

    const t1 = getGlobalStates().host + POST_DELETE_GROUP;

    apiPost(t1, groupData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(usermgtSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            usermgtStore.dispatch(usermgtSliceActions.altLoadState("SUCCESS"));
        }).catch((err) => console.warn("failed to delete group", err));

}

export const copyGroup = (data: CopyGroup, token: string) => {

    const t1 = getGlobalStates().host + POST_COPY_GROUP;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(usermgtSliceActions.altLoadState("FAILED"));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            usermgtStore.dispatch(usermgtSliceActions.altLoadState("SUCCESS"));
            const customDisplay = ConfirmationToastContentStore(data.name, "Save");
            toast(customDisplay, { containerId: 'confirmationContainer' });
        }).catch((err) => console.warn("failed to copy group", err));

}

export const assignUsersToGroup = (data: AssignUsersToGroup, token: string) => {
    const t1 = getGlobalStates().host + POST_ASSIGN_USERS_TO_GROUPS;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                return;
            }
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            const groupName = (response.data.arg as UserManagementData).groups.filter((group) => group.id.value === data.groupID.value)[0].name;
            const customDisplay = ConfirmationToastContentStore(groupName, "Edit");
            toast(customDisplay, { containerId: 'confirmationContainer' });
        }).catch((err) => console.warn("failed to assign users", err));

}

export const updateGroup = (data: UpdateKeycloakGroup, token: string) => {
    const t1 = getGlobalStates().host + POST_UPDATE_GROUP;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response) => {
            if (!checkReponseIsValid('authorize', response)) {
                const getErrorMessage = getError(response);
                usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
                usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
                usermgtStore.dispatch(usermgtSliceActions.altLoadState("FAILED"));
                return;
            }
            // usermgtStore.dispatch(usermgtSliceActions.afterSaveDisplayEditPage());
            console.debug(`receive authorize`, response.data);
            usermgtStore.dispatch(usermgtSliceActions.recieveUseMangementData(response.data.arg));
            usermgtStore.dispatch(usermgtSliceActions.altLoadState("SUCCESS"));
            const getName = response.data.arg.groups.find((group) => group.id.value === data.id.value).name;
            const customDisplay = ConfirmationToastContentStore(getName, "Edit");
            toast(customDisplay, { containerId: 'confirmationContainer' });

        }).catch((err) => console.warn("failed to update group", err));

}

