import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";

import { useCarbonDispatch,} from "../../../store/carbonStore";
import { KeycloakUser } from "../../../model/CarbonExternalModels";
import UserForm from "../UserForm";
import UserDelete from "../userDelete/UserDelete";
import UserDisable from "../userDisable/UserDisable";
import UserPassword from "../userPassword/UserPassword";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { Button } from "userful-chronos-common-ui/dist";

interface IProps {
    user: KeycloakUser;
}

export default function UserActions(props: IProps) {
    const dispatch = useCarbonDispatch();

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    if (!permissions.isUserSuppressor && !permissions.isUserCreator) return null;

    return (
        <Dropdown  className="userfulTableActions" onMouseUp={(e) => e.stopPropagation()}>
            <Dropdown.Toggle bsPrefix="userfulTableActionsToggle" as={Button}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="dropdownMenuSwitch"
                onKeyDown={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.stopPropagation()}
            >
                {(props.user.userProfile.userName !== "admin" || !permissions.isUserCreator) && (
                    <UserForm type={"DROPDOWN"} user={props.user} permissions={permissions} />
                )}
                <UserPassword user={props.user} permissions={permissions} />
                {props.user.userProfile.userName !== "admin" && !permissions.isUserCreator && <hr />}
                <UserDisable user={props.user} permissions={permissions} />
                <UserDelete user={props.user} permissions={permissions} />
            </Dropdown.Menu>
        </Dropdown>
    );
}
