import React, { useState } from 'react';
import FieldHeader from './FieldHeader';
import { useTranslation } from 'react-i18next'
import Switch from 'react-switch';
import { propTypes } from 'react-bootstrap/esm/Image';

interface IProps {
    check: boolean;
    name: string;
    description: string;
    className?: string;
    handleClick?: any;
}

const CustomSwitch = (props: IProps) => {

    const { t } = useTranslation();

    // console.warn(props.check)

    return (
        <div className={`custom-switch-main-wrapper ${props.check ? "selectInput" : ''} ${props.className}`} onClick={props.handleClick}>
            <FieldHeader name={props.name} description={props.description} />
            <div className="custom-toggle-switch-wrapper">
                <Switch
                    onChange={(e) => {}}
                    checked={props.check}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={16}
                    width={28}
                    onHandleColor='#FFFFFF'
                    onColor='#23A866'
                    offColor='#C6C8CE'
                    offHandleColor='#FFFFFF'
                    className="toggle-switch"
                />
                {/* {props.check && <span>{t('UserManagement.Enabled')}</span>} */}
            </div>
        </div>

    );
}

export default CustomSwitch;