import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import mappingStore from "../mappingStore";
import {
     RECEIVE_CREATE_MAPPER_COLLECTION, RECEIVE_LIST_MAPPER_COLLECTION,
     RECEIVE_REMOVE_MAPPER_COLLECTION, RECEIVE_UPDATE_MAPPER_COLLECTION
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/controlroom/controlRoomMsgDefinitions";
import { mapperCollectionsActions } from "../mapperCollectionsSlice";
import { MapperCollectionComp } from "userful-chronos-app-common-js/dist/models/mapping/MapperCollectionComp";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";


registerMsgHandler(RECEIVE_LIST_MAPPER_COLLECTION, (payload) => {
     console.debug("receive RECEIVE_LIST_MAPPER_COLLECTION");
     const data = JSON.parse(payload) as MapperCollectionComp[];
     mappingStore.dispatch(
          mapperCollectionsActions.setMapperCollections(data)
     );
});

registerMsgHandler(RECEIVE_CREATE_MAPPER_COLLECTION, (payload) => {
     console.debug("receive RECEIVE_CREATE_MAPPER_COLLECTION");
     const data = JSON.parse(payload) as MapperCollectionComp;
     mappingStore.dispatch(
          mapperCollectionsActions.addMapperCollection(data)
     );
});

registerMsgHandler(RECEIVE_UPDATE_MAPPER_COLLECTION, (payload) => {
     console.debug("receive RECEIVE_UPDATE_MAPPER_COLLECTION");
     const data = JSON.parse(payload) as MapperCollectionComp;
     mappingStore.dispatch(
          mapperCollectionsActions.updateMapperCollection(data)
     );
});

registerMsgHandler(RECEIVE_REMOVE_MAPPER_COLLECTION, (payload) => {
     console.debug("receive RECEIVE_REMOVE_MAPPER_COLLECTION");
     const data = JSON.parse(payload) as StringID;
     mappingStore.dispatch(
          mapperCollectionsActions.removeMapperCollection(data)
     );
});
