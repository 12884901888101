import React, { ForwardedRef } from 'react';

const CustomToggle = React.forwardRef(({ children, onClick, className }:any, ref:ForwardedRef<any>) => (
	<div
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
		className={className}
		ref={ref}
	>
		{children}
	</div>
));

export default CustomToggle;

export const CustomSelectToggle = React.forwardRef(({ children, onClick, className }:any, ref:ForwardedRef<any>) => (
	<div
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
		className={className}
		ref={ref}
	>
		{children}
	</div>
));





















// type CustomToggleProps = {
//     children?: React.ReactNode;
//     onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {};
//     className?: string;
// }

// const CustomToggle = React.forwardRef(
//     (props: CustomToggleProps, ref: React.Ref<HTMLDivElement>) => (
// 	<div
// 		// href="/#"
// 		onClick={(e) => {
// 			e.preventDefault();
// 			props.onClick(e);
// 		}}
// 		className={props.className}
// 		ref={ref}
// 	>
// 		{props.children}
// 	</div>
// ));

// export default CustomToggle;


// type CustomSelectToggleProps = {
//     children?: React.ReactNode;
//     onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {};
//     className?: string;
// }

// export const CustomSelectToggle = React.forwardRef((props: CustomSelectToggleProps, ref: React.Ref<HTMLDivElement>) => (
// 	<div
// 		onClick={(e) => {
// 			e.preventDefault();
// 			props.onClick(e);
// 		}}
// 		className={props.className}
// 		ref={ref}
// 	>
// 		{props.children}
// 	</div>
// ));
