"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEND_UPDATE_MULTI_INSET_VISIBILITY = exports.SEND_UPDATE_MULTI_INSET_TEXT_OVERLAY_VISIBILITY = exports.SEND_UPDATE_MULTI_INSET_TEXT_OVERLAY = exports.SEND_UPDATE_MULTI_INSET_GEOMETRY = exports.SEND_UPDATE_MULTI_INSET_FROZEN = exports.SEND_UPDATE_MULTI_INSET_AUDIO_SETTINGS = exports.SEND_UPDATE_MAPPER_GRID = exports.SEND_UPDATE_MAPPER_COLLECTION = exports.SEND_UPDATE_INSET_VISIBILITY = exports.SEND_UPDATE_INSET_MOVABLE = exports.SEND_UPDATE_INSET_HEADER = exports.SEND_UPDATE_INSET_GEOMETRY = exports.SEND_UPDATE_INSET_AUDIO_SETTINGS = exports.SEND_SNAPSHOT_UPDATE = exports.SEND_SNAPSHOT_SAVE = exports.SEND_SNAPSHOT_REMOVE = exports.SEND_SNAPSHOT_LOAD = exports.SEND_SNAPSHOT_CREATE = exports.SEND_REMOVE_MAPPER_COLLECTION = exports.SEND_LIST_MAPPER_COLLECTION = exports.SEND_GRID = exports.SEND_DETACH_MAPPER = exports.SEND_CREATE_MAPPER_COLLECTION = exports.SEND_CONTROL_ROOM_REMOVED = exports.SEND_CONTROL_ROOM_ADDED = exports.SEND_ATTACH_MAPPER = exports.RECEIVE_UPDATE_MAPPER_INSETS = exports.RECEIVE_UPDATE_MAPPER_COLLECTION = exports.RECEIVE_UPDATE_INSET_VISIBILITY = exports.RECEIVE_UPDATE_INSET_MOVABLE = exports.RECEIVE_UPDATE_INSET_HEADER = exports.RECEIVE_UPDATE_INSET_GEOMETRY = exports.RECEIVE_UPDATE_INSET_AUDIO_SETTINGS = exports.RECEIVE_SNAPSHOT_UPDATED = exports.RECEIVE_SNAPSHOT_SAVED = exports.RECEIVE_SNAPSHOT_REMOVED = exports.RECEIVE_SNAPSHOT_LOAD_STARTED = exports.RECEIVE_SNAPSHOT_LOAD_FAILED = exports.RECEIVE_SNAPSHOT_LOAD_COMPLETE = exports.RECEIVE_SNAPSHOT_CREATED = exports.RECEIVE_REMOVE_MAPPER_COLLECTION = exports.RECEIVE_REMOVE_INSET = exports.RECEIVE_LIST_MAPPER_COLLECTION = exports.RECEIVE_GRID = exports.RECEIVE_DETACH_MAPPER = exports.RECEIVE_CREATE_MAPPER_COLLECTION = exports.RECEIVE_CREATE_INSET = exports.RECEIVE_CONTROL_ROOM_REMOVED = exports.RECEIVE_CONTROL_ROOM_ADDED = exports.RECEIVE_CONTROL_ROOM = exports.RECEIVE_ATTACH_MAPPER = void 0;
var _messageModel = require("../../messageModel");
// CATEGORIES: 

var INSET = 3000;
var SNAPSHOT = 3004;
var SOURCE_DEF = 3005;
var MAPPER = 3015;
var GRID = 2301;
var CR_MANAGER = 2400;
var SURFACE = 3011;
var COLLECTION = 3026;
// MAPPER

var SEND_UPDATE_MAPPER_GRID = exports.SEND_UPDATE_MAPPER_GRID = new _messageModel.RequestMessageDefinition(MAPPER, 9);
// export const SEND_UPDATE_MAPPER_DESTINATIONS = new RequestMessageDefinition(MAPPER, 5);

var RECEIVE_UPDATE_MAPPER_INSETS = exports.RECEIVE_UPDATE_MAPPER_INSETS = new _messageModel.RequestMessageDefinition(MAPPER, 4);
// export const RECEIVE_UPDATE_MAPPER_DESTINATIONS = new RequestMessageDefinition(MAPPER, 5);

// CONTROL ROOM

var RECEIVE_CONTROL_ROOM = exports.RECEIVE_CONTROL_ROOM = new _messageModel.RespondMessageDefinition(10, 1);
var SEND_CONTROL_ROOM_ADDED = exports.SEND_CONTROL_ROOM_ADDED = new _messageModel.RequestMessageDefinition(CR_MANAGER, 0);
// export const SEND_CONTROL_ROOM_UPDATED = new RequestMessageDefinition(CR_MANAGER, 1);
var SEND_CONTROL_ROOM_REMOVED = exports.SEND_CONTROL_ROOM_REMOVED = new _messageModel.RequestMessageDefinition(CR_MANAGER, 2);
var RECEIVE_CONTROL_ROOM_ADDED = exports.RECEIVE_CONTROL_ROOM_ADDED = new _messageModel.RespondMessageDefinition(CR_MANAGER, 0);
// export const RECEIVE_CONTROL_ROOM_UPDATED = new RespondMessageDefinition(CR_MANAGER, 1);
var RECEIVE_CONTROL_ROOM_REMOVED = exports.RECEIVE_CONTROL_ROOM_REMOVED = new _messageModel.RespondMessageDefinition(CR_MANAGER, 2);

// SURFACE
// export const SEND_LIST_SURFACES = new RequestMessageDefinition(SURFACE, 6);
// export const RECEIVE_LIST_SURFACES = new RespondMessageDefinition(SURFACE, 6);

// GRID

// export const RECEIVE_GRID = new RespondMessageDefinition(GRID, 0);
var RECEIVE_GRID = exports.RECEIVE_GRID = new _messageModel.RespondMessageDefinition(MAPPER, 9);
var SEND_GRID = exports.SEND_GRID = new _messageModel.RequestMessageDefinition(GRID, 0);

// INSET

// export const SEND_CREATE_INSET = new RequestMessageDefinition(INSET, 11);
// export const SEND_REMOVE_INSET = new RequestMessageDefinition(INSET, 1);
var SEND_UPDATE_INSET_GEOMETRY = exports.SEND_UPDATE_INSET_GEOMETRY = new _messageModel.RequestMessageDefinition(INSET, 2);
var SEND_UPDATE_INSET_AUDIO_SETTINGS = exports.SEND_UPDATE_INSET_AUDIO_SETTINGS = new _messageModel.RequestMessageDefinition(INSET, 3);
// export const SEND_UPDATE_INSET_TEXT_OVERLAY = new RequestMessageDefinition(INSET, 4);
var SEND_UPDATE_INSET_HEADER = exports.SEND_UPDATE_INSET_HEADER = new _messageModel.RequestMessageDefinition(INSET, 5);
var SEND_UPDATE_INSET_VISIBILITY = exports.SEND_UPDATE_INSET_VISIBILITY = new _messageModel.RequestMessageDefinition(INSET, 6);
// export const SEND_UPDATE_INSET_TEXT_OVERLAY_VISIBILITY = new RequestMessageDefinition(INSET, 7);
// export const SEND_LIST_INSETS = new RequestMessageDefinition(INSET, 8);
// export const SEND_LIST_INSETS_FOR_SCENES = new RequestMessageDefinition(INSET, 9);
var SEND_UPDATE_INSET_MOVABLE = exports.SEND_UPDATE_INSET_MOVABLE = new _messageModel.RequestMessageDefinition(INSET, 10);
var SEND_UPDATE_MULTI_INSET_GEOMETRY = exports.SEND_UPDATE_MULTI_INSET_GEOMETRY = new _messageModel.RequestMessageDefinition(INSET, 17);
var SEND_UPDATE_MULTI_INSET_AUDIO_SETTINGS = exports.SEND_UPDATE_MULTI_INSET_AUDIO_SETTINGS = new _messageModel.RequestMessageDefinition(INSET, 18);
var SEND_UPDATE_MULTI_INSET_TEXT_OVERLAY = exports.SEND_UPDATE_MULTI_INSET_TEXT_OVERLAY = new _messageModel.RequestMessageDefinition(INSET, 19);
var SEND_UPDATE_MULTI_INSET_VISIBILITY = exports.SEND_UPDATE_MULTI_INSET_VISIBILITY = new _messageModel.RequestMessageDefinition(INSET, 20);
var SEND_UPDATE_MULTI_INSET_TEXT_OVERLAY_VISIBILITY = exports.SEND_UPDATE_MULTI_INSET_TEXT_OVERLAY_VISIBILITY = new _messageModel.RequestMessageDefinition(INSET, 21);
var SEND_UPDATE_MULTI_INSET_FROZEN = exports.SEND_UPDATE_MULTI_INSET_FROZEN = new _messageModel.RequestMessageDefinition(INSET, 22);
var RECEIVE_CREATE_INSET = exports.RECEIVE_CREATE_INSET = new _messageModel.RespondMessageDefinition(INSET, 0);
var RECEIVE_REMOVE_INSET = exports.RECEIVE_REMOVE_INSET = new _messageModel.RespondMessageDefinition(INSET, 1);
var RECEIVE_UPDATE_INSET_GEOMETRY = exports.RECEIVE_UPDATE_INSET_GEOMETRY = new _messageModel.RespondMessageDefinition(INSET, 2);
var RECEIVE_UPDATE_INSET_AUDIO_SETTINGS = exports.RECEIVE_UPDATE_INSET_AUDIO_SETTINGS = new _messageModel.RespondMessageDefinition(INSET, 3);
// export const RECEIVE_UPDATE_INSET_TEXT_OVERLAY = new RespondMessageDefinition(INSET, 4);
var RECEIVE_UPDATE_INSET_HEADER = exports.RECEIVE_UPDATE_INSET_HEADER = new _messageModel.RespondMessageDefinition(INSET, 5);
var RECEIVE_UPDATE_INSET_VISIBILITY = exports.RECEIVE_UPDATE_INSET_VISIBILITY = new _messageModel.RespondMessageDefinition(INSET, 6);
// export const RECEIVE_UPDATE_INSET_TEXT_OVERLAY_VISIBILITY = new RespondMessageDefinition(INSET, 7);
// export const RECEIVE_LIST_INSETS = new RespondMessageDefinition(INSET, 8);
// export const RECEIVE_LIST_INSETS_FOR_SCENES = new RespondMessageDefinition(INSET, 9);
var RECEIVE_UPDATE_INSET_MOVABLE = exports.RECEIVE_UPDATE_INSET_MOVABLE = new _messageModel.RespondMessageDefinition(INSET, 10);

// SOURCE_DEF

// export const SEND_CREATE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 0);
// export const SEND_UPDATE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 1);
// export const SEND_DELETE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 2);

// export const RECEIVE_CREATE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 1);
// export const RECEIVE_UPDATE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 2);
// export const RECEIVE_DELETE_SOURCE = new RequestMessageDefinition(SOURCE_DEF, 3);

// SNAPSHOT

var SEND_SNAPSHOT_CREATE = exports.SEND_SNAPSHOT_CREATE = new _messageModel.RespondMessageDefinition(SNAPSHOT, 0);
var SEND_SNAPSHOT_REMOVE = exports.SEND_SNAPSHOT_REMOVE = new _messageModel.RespondMessageDefinition(SNAPSHOT, 1);
var SEND_SNAPSHOT_UPDATE = exports.SEND_SNAPSHOT_UPDATE = new _messageModel.RespondMessageDefinition(SNAPSHOT, 2);
var SEND_SNAPSHOT_LOAD = exports.SEND_SNAPSHOT_LOAD = new _messageModel.RespondMessageDefinition(SNAPSHOT, 3);
var SEND_SNAPSHOT_SAVE = exports.SEND_SNAPSHOT_SAVE = new _messageModel.RespondMessageDefinition(SNAPSHOT, 4);
var RECEIVE_SNAPSHOT_CREATED = exports.RECEIVE_SNAPSHOT_CREATED = new _messageModel.RespondMessageDefinition(SNAPSHOT, 0);
var RECEIVE_SNAPSHOT_REMOVED = exports.RECEIVE_SNAPSHOT_REMOVED = new _messageModel.RespondMessageDefinition(SNAPSHOT, 1);
var RECEIVE_SNAPSHOT_UPDATED = exports.RECEIVE_SNAPSHOT_UPDATED = new _messageModel.RespondMessageDefinition(SNAPSHOT, 2);
var RECEIVE_SNAPSHOT_SAVED = exports.RECEIVE_SNAPSHOT_SAVED = new _messageModel.RespondMessageDefinition(SNAPSHOT, 3);
var RECEIVE_SNAPSHOT_LOAD_STARTED = exports.RECEIVE_SNAPSHOT_LOAD_STARTED = new _messageModel.RespondMessageDefinition(SNAPSHOT, 4);
var RECEIVE_SNAPSHOT_LOAD_COMPLETE = exports.RECEIVE_SNAPSHOT_LOAD_COMPLETE = new _messageModel.RespondMessageDefinition(SNAPSHOT, 5);
var RECEIVE_SNAPSHOT_LOAD_FAILED = exports.RECEIVE_SNAPSHOT_LOAD_FAILED = new _messageModel.RespondMessageDefinition(SNAPSHOT, 6);

// COLLECTION

var SEND_CREATE_MAPPER_COLLECTION = exports.SEND_CREATE_MAPPER_COLLECTION = new _messageModel.RequestMessageDefinition(COLLECTION, 0);
var SEND_UPDATE_MAPPER_COLLECTION = exports.SEND_UPDATE_MAPPER_COLLECTION = new _messageModel.RequestMessageDefinition(COLLECTION, 1);
var SEND_REMOVE_MAPPER_COLLECTION = exports.SEND_REMOVE_MAPPER_COLLECTION = new _messageModel.RequestMessageDefinition(COLLECTION, 2);
var SEND_ATTACH_MAPPER = exports.SEND_ATTACH_MAPPER = new _messageModel.RequestMessageDefinition(COLLECTION, 3);
var SEND_DETACH_MAPPER = exports.SEND_DETACH_MAPPER = new _messageModel.RequestMessageDefinition(COLLECTION, 4);
var SEND_LIST_MAPPER_COLLECTION = exports.SEND_LIST_MAPPER_COLLECTION = new _messageModel.RequestMessageDefinition(COLLECTION, 5);
var RECEIVE_CREATE_MAPPER_COLLECTION = exports.RECEIVE_CREATE_MAPPER_COLLECTION = new _messageModel.RespondMessageDefinition(COLLECTION, 0);
var RECEIVE_UPDATE_MAPPER_COLLECTION = exports.RECEIVE_UPDATE_MAPPER_COLLECTION = new _messageModel.RespondMessageDefinition(COLLECTION, 1);
var RECEIVE_REMOVE_MAPPER_COLLECTION = exports.RECEIVE_REMOVE_MAPPER_COLLECTION = new _messageModel.RespondMessageDefinition(COLLECTION, 2);
var RECEIVE_ATTACH_MAPPER = exports.RECEIVE_ATTACH_MAPPER = new _messageModel.RespondMessageDefinition(COLLECTION, 3);
var RECEIVE_DETACH_MAPPER = exports.RECEIVE_DETACH_MAPPER = new _messageModel.RespondMessageDefinition(COLLECTION, 4);
var RECEIVE_LIST_MAPPER_COLLECTION = exports.RECEIVE_LIST_MAPPER_COLLECTION = new _messageModel.RespondMessageDefinition(COLLECTION, 5);