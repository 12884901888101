import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { requestCreatePlayListDef, requestRemovePlayListDef, requestUpdatePlayListDef } from './msgs/MsgSender';
import { SamPlayListDef } from 'userful-chronos-app-common-js/dist/models/sam/SAMPlaylist';
import { sortByName } from './sourceAssetsUtils';
import { SamUsageData, SamUsageDataResponse } from 'userful-chronos-app-common-js/dist/models/sam/Common';
import { ConfirmationToastContentStore } from '../../Container/ConfirmationToastContentStore';
import { toast } from "react-toastify";

const initialState: {
    playlists: Array<SamPlayListDef>;
    ready: boolean;
    pendingUpdatePlaylist: SamUsageDataResponse & { receivedResponse: boolean }, // expected responses when updating/deleteing
    pendingDeletePlaylist: SamUsageDataResponse & { receivedResponse: boolean },
    expectedConfirmation: string | null;
} = {
    playlists: [],
    ready: false,
    pendingUpdatePlaylist: null,
    pendingDeletePlaylist: null,
    expectedConfirmation: null
};

export const playlistSlice = createSlice({
    name: 'playlistSlice',
    initialState,
    reducers: {
        addOrUpdatePlaylistToServer: (state, action: PayloadAction<SamPlayListDef>) => {
            const existingIndex = state.playlists.findIndex(item => item.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                state.pendingUpdatePlaylist = { itemID: action.payload.id.value, usageData: [], receivedResponse: false };
                requestUpdatePlayListDef(action.payload, false);
            } else {
                requestCreatePlayListDef(action.payload);
                state.pendingUpdatePlaylist = { itemID: action.payload.id.value, usageData: [], receivedResponse: true };
            }
            state.expectedConfirmation = action.payload.id.value;
        },
        forceUpdatePlaylistToServer: (state, action: PayloadAction<SamPlayListDef>) => {
            state.pendingUpdatePlaylist = null;
            requestUpdatePlayListDef(action.payload, true);

        },
        deletePlaylistToServer: (state, action: PayloadAction<string>) => {
            state.pendingDeletePlaylist = { itemID: action.payload, usageData: [], receivedResponse: false };
            requestRemovePlayListDef({ value: action.payload }, false);
        },
        forceDeletePlaylistToServer: (state, action: PayloadAction<string>) => {
            state.pendingDeletePlaylist = null;
            requestRemovePlayListDef({ value: action.payload }, true);
        },
        clearPendingUpdateOrDelete: (state) => {
            state.pendingDeletePlaylist = null;
            state.pendingUpdatePlaylist = null;
        },
        receiveUpdateOrDeletePlaylistResponse: (state, action: PayloadAction<SamUsageDataResponse>) => {
            if (state.pendingDeletePlaylist && state.pendingDeletePlaylist.itemID === action.payload.itemID) {
                state.pendingDeletePlaylist = { ...action.payload, receivedResponse: true };
            } else if (state.pendingUpdatePlaylist && state.pendingUpdatePlaylist.itemID === action.payload.itemID) {
                state.pendingUpdatePlaylist = { ...action.payload, receivedResponse: true };
            }
        },
        setPlaylists: (state, action: PayloadAction<Array<SamPlayListDef>>) => {
            state.ready = true;
            console.debug(`Got ${action.payload.length} playlists`);
            state.playlists = sortByName(action.payload);
        },

        addPlaylist: (state, action: PayloadAction<SamPlayListDef>) => {
            const existingIndex = state.playlists.findIndex(item => item.id.value === action.payload.id.value);
            if (existingIndex < 0) {
                state.playlists = sortByName([
                    ...state.playlists,
                    { ...action.payload },
                ]);
                if (state.expectedConfirmation === action.payload.id.value) {
                    const customDisplay = ConfirmationToastContentStore(action.payload.name, "Save");
                    toast(customDisplay, { containerId: 'confirmationContainer' });
                    state.expectedConfirmation = null;
                }
            }
        },

        updatePlaylist: (state, action: PayloadAction<SamPlayListDef>) => {
            const existingIndex = state.playlists.findIndex(item => item.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                state.playlists = sortByName([
                    ...state.playlists.slice(0, existingIndex),
                    { ...action.payload },
                    ...state.playlists.slice(existingIndex + 1),
                ]);
                if (state.expectedConfirmation === action.payload.id.value) {
                    const customDisplay = ConfirmationToastContentStore(action.payload.name, "Edit");
                    toast(customDisplay, { containerId: 'confirmationContainer' });
                    state.expectedConfirmation = null;
                }
            }
        },

        deletePlaylist: (state, action: PayloadAction<string>) => {
            state.playlists = sortByName(state.playlists.filter(item => item.id.value !== action.payload));
        },
    },
})

export const playlistSliceActions = playlistSlice.actions;

export default playlistSlice.reducer