"use strict";

var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _usersmanagement = require("../../functions/usersmanagement");
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/umd', function (method, data) {
  if (method === 'get') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.getUserManagementData)())
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/uf/', function (method, data) {
  if (method === 'get') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.getUserExternAuth)())
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/event/login', function (method, data) {
  if (method === 'get') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.getLoginEvents)())
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/event/admin', function (method, data) {
  if (method === 'get') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.getAdminEvents)())
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/open/authorizesetup', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.getAuthSuccessMsg)())
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/init/setup/open/status', function (method, data) {
  if (method === 'get') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.getOpenStatus)())
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/init/setup/auth/status', function (method, data) {
  if (method === 'get') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.getAuthStatus)())
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/user/add', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.addUser)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/user/update', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.updateUser)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/user/delete', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.deleteUser)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/user/enable', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.enableOrDisableUser)(data, true))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/user/disable', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.enableOrDisableUser)(data, false))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/user/resetpassword', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.resetPassword)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/group/add', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.addGroup)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/group/copy', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.copyGroup)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/group/delete', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.deletegroup)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/group/update', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.updateGroup)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/group/assignusers', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.assignUsersToGroup)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/kc/auth/group/assigngroups', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)((0, _usersmanagement.assignGroupToUser)(data))
    };
  }
});
(0, _StandaloneMsgHandler.registerStandaloneAPIHandler)('/init/setup/auth/setup', function (method, data) {
  if (method === 'post') {
    return {
      response: (0, _StandaloneMsgHandler.createUIReply)('')
    };
  }
});