"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortVideoWallDestinations = exports.sortPhysicalDestinations = exports.hasDestination = exports.groupHasVideoWallDestination = exports.groupHasVideoDestination = exports.groupHasAudioDestination = exports.getUsageForVideoWallDestination = exports.getUsageForPhysicalDestination = exports.getUsage = exports.getRemoveDestinations = exports.getFiltered = exports.filterVideosInGroup = exports.filterVideoWallsInGroup = exports.filterAudiosInGroup = exports.filterArtisticVideoWalls = exports.buildMappingGroupDestinations = void 0;
var _display = require("../display");
var _MappingGroups = require("../mapping/MappingGroups");
var _CdmGroup = require("./CdmGroup");
var _common = require("../common");
var _globalStates = require("../../globalstates/globalStates");
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var isVideoWallDestArtistic = function isVideoWallDestArtistic(dest) {
  return dest.endPoint.artistic;
};
var buildMappingGroupDestinationsFor = function buildMappingGroupDestinationsFor(destinations, mapping, destinationType) {
  return destinations.filter(function (dest) {
    return mapping.destinations.findIndex(function (item) {
      return item.destinationID === dest.id.value;
    }) >= 0;
  }).map(function (item) {
    return {
      data: item,
      destinationType: destinationType
    };
  });
};
var hasDestination = exports.hasDestination = function hasDestination(destination, dest) {
  return destination.findIndex(function (item) {
    return item.destinationID === dest.id.value;
  }) >= 0;
};
var getFiltered = exports.getFiltered = function getFiltered(dest, mappingGroups) {
  return dest.filter(function (item) {
    return item.availability !== 'NOT_CONNECTED' && mappingGroups.findIndex(function (mappingGroup) {
      return hasDestination(mappingGroup.destinations, item);
    }) < 0;
  });
};
var groupHasVideoDestination = exports.groupHasVideoDestination = function groupHasVideoDestination(group, dest) {
  return group.video.findIndex(function (item) {
    return item.endPoint.id.seatID.value === dest.endPoint.id.seatID.value && item.id.value !== dest.id.value;
  }) >= 0;
};
var groupHasAudioDestination = exports.groupHasAudioDestination = function groupHasAudioDestination(group, dest) {
  return group.audio.findIndex(function (item) {
    return item.endPoint.id.seatID.value === dest.endPoint.id.seatID.value && item.id.value !== dest.id.value;
  }) >= 0;
};
var groupHasVideoWallDestination = exports.groupHasVideoWallDestination = function groupHasVideoWallDestination(group, dest) {
  return group.videoWalls.findIndex(function (item) {
    return item.endPoint.id.videoWallID.value === dest.endPoint.id.videoWallID.value && item.id.value !== dest.id.value;
  }) >= 0;
};
var filterVideosInGroup = exports.filterVideosInGroup = function filterVideosInGroup(dest, groups) {
  return (0, _CdmGroup.filterTemporaryGroupVideos)(dest.filter(function (item) {
    return groups.findIndex(function (group) {
      return groupHasVideoDestination(group, item);
    }) < 0;
  }), groups);
};
var filterAudiosInGroup = exports.filterAudiosInGroup = function filterAudiosInGroup(dest, groups) {
  return (0, _CdmGroup.filterTemporaryGroupAudios)(dest.filter(function (item) {
    return groups.findIndex(function (group) {
      return groupHasAudioDestination(group, item);
    }) < 0;
  }), groups);
};
var filterVideoWallsInGroup = exports.filterVideoWallsInGroup = function filterVideoWallsInGroup(dest, groups) {
  return (0, _CdmGroup.filterTemporaryGroupVideoWalls)(dest.filter(function (item) {
    return groups.findIndex(function (group) {
      return groupHasVideoWallDestination(group, item);
    }) < 0;
  }), groups);
};
var filterArtisticVideoWalls = exports.filterArtisticVideoWalls = function filterArtisticVideoWalls(dest, appID) {
  if (appID === _common.APP_IDS.Artistic || appID === _common.APP_IDS.Decisions) {
    return dest;
  }
  return dest.filter(function (item) {
    return !isVideoWallDestArtistic(item);
  });
};
var sortPhysicalDestinations = exports.sortPhysicalDestinations = function sortPhysicalDestinations(destinations) {
  var sorted = _toConsumableArray(destinations);
  sorted.sort(function (a, b) {
    var labelA = (0, _display.prettyPrintStationLabel)(a.endPoint.label);
    var labelB = (0, _display.prettyPrintStationLabel)(b.endPoint.label);
    return labelA.localeCompare(labelB, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });
  return sorted;
};
var sortVideoWallDestinations = exports.sortVideoWallDestinations = function sortVideoWallDestinations(videoWalls) {
  var sorted = _toConsumableArray(videoWalls);
  sorted.sort(function (a, b) {
    return a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });
  return sorted;
};
var getUsageForPhysicalDestination = exports.getUsageForPhysicalDestination = function getUsageForPhysicalDestination(dest, currentMappingGroup) {
  var currentAppID = (0, _globalStates.getGlobalStates)().appID.value;
  var inUse = dest.endPoint.usage.records.length > 0;
  if (!currentMappingGroup && !currentAppID) {
    return inUse ? "CommonUI.MappingGroups.anApp" : null;
  }
  if (currentMappingGroup && inUse) {
    var activeSceneID = dest.endPoint.usage.records[0].sceneID;
    if (activeSceneID.value !== currentMappingGroup.sceneID.value) {
      // being used by another mapper
      return "".concat(dest.endPoint.usage.records[0].appInstanceData.name);
    } else {
      return null;
    }
  }
  if (currentAppID && inUse) {
    // no mapping group means it not mapped
    var activeAppID = dest.endPoint.usage.records[0].appInstanceData.id.appID.value;
    if (activeAppID !== currentAppID) {
      // being used by another app
      return "".concat(dest.endPoint.usage.records[0].appInstanceData.name, " (").concat(_common.APP_NAMES[activeAppID], ")");
    } else {
      return "".concat(dest.endPoint.usage.records[0].appInstanceData.name);
    }
  }
  return null;
};
var getUsageForVideoWallDestination = exports.getUsageForVideoWallDestination = function getUsageForVideoWallDestination(dest, currentMappingGroup) {
  var displaysUsage = dest.displays.reduce(function (acc, curr) {
    var displayUsage = getUsageForPhysicalDestination(curr, currentMappingGroup);
    if (displayUsage) {
      acc.push(displayUsage);
    }
    return _toConsumableArray(new Set(acc));
  }, []).join(", ");
  return displaysUsage;
};
var getUsage = exports.getUsage = function getUsage(dest, currentMappingGroup) {
  if (dest.displays) {
    return getUsageForVideoWallDestination(dest, currentMappingGroup);
  }
  if (dest.endPoint.type) {
    return getUsageForPhysicalDestination(dest, currentMappingGroup);
  }
  return (0, _MappingGroups.isDestinationInUse)(dest) ? "CommonUI.MappingGroups.anApp" : null;
};
var getRemoveDestinations = exports.getRemoveDestinations = function getRemoveDestinations(disableMirroring, destination, destinationType, existingDestinations) {
  var ids = {
    groups: [],
    videoWalls: [],
    physicalAudio: [],
    physicalVideo: []
  };
  if (existingDestinations.length > 0) {
    if (disableMirroring) {
      ids = {
        groups: existingDestinations.filter(function (item) {
          return item.destinationType === 'groups';
        }).map(function (item) {
          return item.data.id;
        }),
        videoWalls: existingDestinations.filter(function (item) {
          return item.destinationType === 'videoWalls';
        }).map(function (item) {
          return item.data.id;
        }),
        physicalVideo: existingDestinations.filter(function (item) {
          return item.destinationType === 'physicalVideo';
        }).map(function (item) {
          return item.data.id;
        }),
        physicalAudio: []
      };
    } else if (destinationType === "videoWalls") {
      var videoWallDestination = destination;
      existingDestinations.forEach(function (item) {
        switch (item.destinationType) {
          case 'physicalVideo':
            {
              var existingDestination = item.data;
              if (videoWallDestination.displays && videoWallDestination.displays.length > 0) {
                videoWallDestination.displays.forEach(function (d) {
                  if (existingDestination.endPoint.aetherID.value === d.endPoint.aetherID.value && existingDestination.endPoint.id.seatID.value === d.endPoint.id.seatID.value && existingDestination.endPoint.id.videoEngineID.value === d.endPoint.id.videoEngineID.value) {
                    ids.physicalVideo.push(existingDestination.id);
                  }
                });
              }
              break;
            }
          case 'groups':
            break;
          case 'videoWalls':
            break;
          case 'physicalAudio':
            break;
        }
      });
    }
  }
  return ids;
};
var buildMappingGroupDestinations = exports.buildMappingGroupDestinations = function buildMappingGroupDestinations(groups, physicalVideo, physicalAudio, videoWalls, mappingGroup, displays) {
  return [].concat(_toConsumableArray(buildMappingGroupDestinationsFor(groups, mappingGroup, "groups")), _toConsumableArray(buildMappingGroupDestinationsFor(physicalVideo, mappingGroup, "physicalVideo")), _toConsumableArray(buildMappingGroupDestinationsFor(physicalAudio, mappingGroup, "physicalAudio")), _toConsumableArray(buildMappingGroupDestinationsFor(videoWalls, mappingGroup, "videoWalls")));
  // return addInUseDisplaysInVideoWall(videoWalls, mappingGroup, displays, match);
};