import React from "react";

interface DebouncedInputProps {
    value: string;
    onChange: (value: string) => void;
    debounce?: number;
    type?: "text" | "number"; // Optional 'type' prop with specific allowed values
    min?: number; // Optional 'min' prop for number inputs
    max?: number; // Optional 'max' prop for number inputs
    placeholder?: string; // Optional 'placeholder' prop
    list?: string; // Optional 'list' prop
}

const DebouncedInput: React.FC<DebouncedInputProps> = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}) => {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <input
            {...props}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    );
}

export default DebouncedInput;