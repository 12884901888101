"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseSourceToPowerBIData = exports.initSamSourceDefCreator = exports.initSamSourceAsset = exports.initSamSource = exports.initSamPowerBI = exports.initSamPlaylistItem = exports.initSamPlaylist = exports.initSamOrgData = exports.initSamFileUploadMetaData = exports.initSamEpic = exports.initAuditData = exports.createWebBrowserSourceFromURL = exports.createWebBrowserSourceFromAsset = exports.createSapphireSource = exports.createSamOrgQueryForApp = exports.createPowerBiSource = exports.createEmeraldSource = exports.createCdmOrgQueryForApp = exports.createAssetFromURL = exports.convertSourceDefToAssetsData = exports.FileExtension = void 0;
var _uuid = require("uuid");
var _globalStates = require("../../globalstates/globalStates");
var _common = require("../common");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var Apps = [_common.APP_IDS.Decisions, _common.APP_IDS.Trends, _common.APP_IDS.Artistic, _common.APP_IDS.Switch];
var FileExtension = exports.FileExtension = ['avi', 'mkv', 'mp4', 'ogv', 'mov', 'webm', 'gif', 'png', 'tif', 'tiff', 'jpe', 'jpg', 'jpeg', 'webp', '3gp', 'aa', 'aac', 'aax', 'act', 'aiff', 'alac', 'amr', 'ape', 'au', 'awb', 'dss', 'dvf', 'flac', 'gsm',
// audios
'iklax', 'ivs', 'm4a', 'm4b', 'm4p', 'mmf', 'movpkg', 'mp3', 'mpc', 'msv', 'nmf', 'ogg', 'oga', 'mogg', 'ra', 'rm', 'raw', 'rf64', 'sln', 'tta', 'voc', 'vox', 'wav', 'wma', 'wv', 'webm', '8svx', 'cda'];
var initSamOrgData = exports.initSamOrgData = function initSamOrgData() {
  for (var _len = arguments.length, appIDs = new Array(_len), _key = 0; _key < _len; _key++) {
    appIDs[_key] = arguments[_key];
  }
  var filteredAppIDs = appIDs && appIDs.filter(function (a) {
    return a;
  }); // if you doesn't pass a value, it passes in [undefined]
  var orgData = {
    appIDs: {
      valid: true,
      appIDs: filteredAppIDs && filteredAppIDs.length > 0 ? filteredAppIDs.map(function (a) {
        return {
          value: a
        };
      }) : Apps.map(function (app) {
        return {
          value: app
        };
      })
    },
    tagsSet: {
      valid: false,
      tags: []
    },
    folderPath: {
      valid: false,
      elements: []
    },
    sourceSpecsSet: {
      valid: false,
      sourceSpecIDs: []
    },
    videoEnginesSet: {
      valid: false,
      videoEngineIDs: []
    }
  };
  return orgData;
};
var initAuditData = exports.initAuditData = function initAuditData() {
  return {
    records: []
  };
};
var initSamSourceAsset = exports.initSamSourceAsset = function initSamSourceAsset(appID) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    type: "MEDIA_FILE",
    assetData: {
      '@type': "file",
      filePath: ""
    },
    orgData: initSamOrgData(appID),
    auditData: initAuditData()
  };
};
var initSamFileUploadMetaData = exports.initSamFileUploadMetaData = function initSamFileUploadMetaData(appID) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    orgData: initSamOrgData(appID),
    videoEngineSet: {
      valid: true,
      videoEngineIDs: [{
        value: (0, _globalStates.getGlobalStates)().systemID
      }]
    }
  };
};
var initSamSourceDefCreator = exports.initSamSourceDefCreator = function initSamSourceDefCreator(appID) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    type: {
      value: "",
      name: ""
    },
    assets: [],
    params: [],
    displayResolution: {
      width: 1920,
      height: 1080
    },
    orgData: initSamOrgData(appID),
    outputType: 'VIDEO_AND_AUDIO',
    controlMode: 'NONE',
    scaling: 'INSIDE',
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)(),
    sharingMode: 'ALWAYS_SHARE_SOURCE',
    appInstanceID: {
      value: (0, _globalStates.getGlobalStates)().systemID,
      appID: {
        value: appID || (0, _globalStates.getGlobalStates)().appID.value
      }
    }
  };
};
var initSamSource = exports.initSamSource = function initSamSource(appID) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    type: {
      value: "",
      name: ""
    },
    assets: [],
    params: [],
    displayResolution: {
      width: 1920,
      height: 1080
    },
    orgData: initSamOrgData(appID),
    auditData: initAuditData(),
    outputType: 'VIDEO_AND_AUDIO',
    controlMode: 'NONE',
    scaling: 'INSIDE',
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)(),
    sharingMode: 'ALWAYS_SHARE_SOURCE',
    appInstanceID: {
      value: (0, _globalStates.getGlobalStates)().systemID,
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    }
  };
};
var initSamEpic = exports.initSamEpic = function initSamEpic(appID) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    type: {
      value: "Epic",
      name: "Epic"
    },
    assets: [],
    params: [{
      name: "EPIC passport",
      value: null,
      type: "STRING"
    }, {
      name: "Minutes to look back",
      value: "1440",
      type: "STRING"
    }, {
      name: "Include longest current wait time",
      value: "false",
      type: "STRING"
    }, {
      name: "Departments",
      value: "",
      type: "STRING"
    }, {
      name: "framerate",
      value: "10",
      type: "STRING"
    }, {
      name: "Data refresh rate",
      value: "30",
      type: "STRING"
    }, {
      type: "STRING",
      name: "Icon",
      value: "/usr/share/userful-plugin-epic/icons/epic-thumbnail.png"
    }, {
      name: "Graph title",
      value: "",
      type: "STRING"
    }],
    displayResolution: {
      width: 1920,
      height: 1080
    },
    orgData: initSamOrgData(_common.APP_IDS.Decisions, _common.APP_IDS.Trends),
    auditData: initAuditData(),
    outputType: 'VIDEO_AND_AUDIO',
    controlMode: 'NONE',
    scaling: 'INSIDE',
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)(),
    sharingMode: 'ALWAYS_SHARE_SOURCE',
    appInstanceID: {
      value: (0, _globalStates.getGlobalStates)().systemID,
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    }
  };
};
var initSamPowerBI = exports.initSamPowerBI = function initSamPowerBI(appID) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    type: {
      value: "Power_BI",
      name: "Power_BI"
    },
    assets: [],
    params: [{
      name: "Power BI passport",
      value: null,
      type: "STRING"
    }, {
      name: "Refresh Interval (min)",
      value: "0",
      type: "STRING"
    }, {
      name: "Reload Interval (min)",
      value: "0",
      type: "STRING"
    }, {
      name: "Timeout (min)",
      value: "2",
      type: "STRING"
    }, {
      name: "framerate",
      value: "10",
      type: "STRING"
    }, {
      type: "STRING",
      name: "Icon",
      value: "/usr/share/userful-plugin-powerbi/icons/bi-thumbnail.png"
    }, {
      name: "Report ID",
      value: null,
      type: "STRING"
    }, {
      name: "Workspace ID",
      value: null,
      type: "STRING"
    }],
    displayResolution: {
      width: 1920,
      height: 1080
    },
    orgData: initSamOrgData(_common.APP_IDS.Decisions, _common.APP_IDS.Trends),
    auditData: initAuditData(),
    outputType: 'VIDEO_AND_AUDIO',
    controlMode: 'NONE',
    scaling: 'INSIDE',
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)(),
    sharingMode: 'ALWAYS_SHARE_SOURCE',
    appInstanceID: {
      value: (0, _globalStates.getGlobalStates)().systemID,
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    }
  };
};
var initSamPlaylist = exports.initSamPlaylist = function initSamPlaylist(appID) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    behaviour: {
      playTimes: {
        times: -1
      },
      startBehaviour: 'AUTO_START'
    },
    items: [],
    orgData: initSamOrgData(appID),
    auditData: initAuditData(),
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)()
  };
};
var initSamPlaylistItem = exports.initSamPlaylistItem = function initSamPlaylistItem() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    sourceDef: null,
    behaviour: {
      activationDuration: 0,
      preStartDuration: 0,
      playDuration: 0,
      repeatConfig: {
        duration: 0,
        times: {
          times: 1
        }
      },
      persistenceType: 'KEEP_RUNNING'
    }
  };
};
var createSamOrgQueryForApp = exports.createSamOrgQueryForApp = function createSamOrgQueryForApp(appID) {
  return {
    appIDsQuery: {
      required: true,
      appIDs: [{
        value: appID
      }]
    },
    folderPathQuery: {
      required: false,
      elements: []
    },
    tagsQuery: {
      required: false,
      matchingTags: []
    },
    sourceSpecsQuery: {
      required: false,
      sourceSpecIDs: []
    },
    videoEnginesQuery: {
      required: false,
      videoEngineIDs: []
    }
  };
};
var createCdmOrgQueryForApp = exports.createCdmOrgQueryForApp = function createCdmOrgQueryForApp(appID) {
  return {
    appIDsQuery: {
      required: true,
      appIDs: [{
        value: appID
      }]
    },
    folderPathQuery: {
      required: false,
      elements: []
    },
    tagsQuery: {
      required: false,
      matchingTags: []
    },
    aetherIDsQuery: {
      required: false,
      aetherIDs: []
    },
    videoEngineIDsQuery: {
      required: false,
      videoEngineIDs: []
    }
  };
};

// helper method for apps like sapphire
var createAssetFromURL = exports.createAssetFromURL = function createAssetFromURL(data) {
  var defaultAsset = initSamSourceAsset(data.appID);
  return _objectSpread(_objectSpread({}, defaultAsset), {}, {
    name: data.name,
    description: data.description,
    type: 'URL',
    assetData: {
      '@type': 'url',
      url: data.url
    }
  });
};

// helper method for apps like ruby
var createWebBrowserSourceFromURL = exports.createWebBrowserSourceFromURL = function createWebBrowserSourceFromURL(data) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: data.name,
    description: data.description,
    type: {
      value: "Web_Browser",
      name: "Web_Browser"
    },
    assets: [],
    params: [{
      name: "Icon",
      value: "/usr/share/userful-plugin-webbrowser/icons/webbrowser.png",
      type: "STRING"
    }, {
      name: "Browser",
      value: "Chrome: Kiosk",
      type: "STRING"
    }, {
      name: "Refresh Interval",
      value: "0",
      type: "STRING"
    }, {
      name: "framerate",
      value: "30",
      type: "STRING"
    }, {
      name: "Show mouse pointer",
      value: "false",
      type: "STRING"
    }, {
      name: "Session Persistence",
      value: "Discard",
      type: "STRING"
    }, {
      name: "URL",
      value: data.url,
      type: "STRING"
    }],
    displayResolution: {
      width: 1920,
      height: 1080
    },
    orgData: initSamOrgData(data.appID),
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE",
    scaling: 'INSIDE',
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)(),
    sharingMode: 'ALWAYS_SHARE_SOURCE',
    appInstanceID: {
      value: (0, _globalStates.getGlobalStates)().systemID,
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    }
  };
};
var createPowerBiSource = exports.createPowerBiSource = function createPowerBiSource(data, displayResolution, appID) {
  return {
    id: {
      value: data.id || (0, _uuid.v4)()
    },
    name: data.name,
    description: data.description,
    type: {
      value: "Power_BI",
      name: "Power_BI"
    },
    assets: [],
    params: [{
      name: "Icon",
      value: "/usr/share/userful-plugin-powerbi/icons/bi-thumbnail.png",
      type: "STRING"
    }, {
      name: "Power BI Email",
      value: data.email,
      type: "STRING"
    }, {
      name: "Power BI Password",
      value: data.password,
      type: "STRING"
    }, {
      name: "Client ID",
      value: data.clientID,
      type: "STRING"
    }, {
      name: "Report URL",
      value: data.reportURL,
      type: "STRING"
    }],
    displayResolution: displayResolution,
    orgData: initSamOrgData(appID),
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE",
    scaling: 'INSIDE',
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)(),
    sharingMode: 'ALWAYS_SHARE_SOURCE',
    appInstanceID: {
      value: (0, _globalStates.getGlobalStates)().systemID,
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    }
  };
};
var findParamValueWithName = function findParamValueWithName(name, params) {
  var foundParam = params.find(function (item) {
    return item.name === name;
  });
  return foundParam ? foundParam.value : '';
};
var parseSourceToPowerBIData = exports.parseSourceToPowerBIData = function parseSourceToPowerBIData(source) {
  if (!source || source.type.value !== 'Power_BI') {
    return {
      name: '',
      description: '',
      email: '',
      password: '',
      clientID: '',
      reportURL: ''
    };
  }
  return {
    id: source.id.value,
    name: source.name,
    description: source.description,
    email: findParamValueWithName('Power BI Email', source.params),
    password: findParamValueWithName('Power BI Password', source.params),
    clientID: findParamValueWithName('Client ID', source.params),
    reportURL: findParamValueWithName('Report URL', source.params)
  };
};

// helper method for apps like ruby
var createWebBrowserSourceFromAsset = exports.createWebBrowserSourceFromAsset = function createWebBrowserSourceFromAsset(data) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: data.name,
    description: data.description,
    type: {
      value: "Web_Browser",
      name: "Web_Browser"
    },
    assets: [{
      paramName: "URL",
      sourceAssets: [{
        value: data.asset.id.value
      }]
    }],
    params: [{
      name: "Icon",
      value: "/usr/share/userful-plugin-webbrowser/icons/webbrowser.png",
      type: "STRING"
    }, {
      name: "Browser",
      value: "Chrome: Kiosk",
      type: "STRING"
    }, {
      name: "Refresh Interval",
      value: "0",
      type: "STRING"
    }, {
      name: "framerate",
      value: "30",
      type: "STRING"
    }, {
      name: "Show mouse pointer",
      value: "false",
      type: "STRING"
    }, {
      name: "Session Persistence",
      value: "Retain",
      type: "STRING"
    }],
    displayResolution: {
      width: 1920,
      height: 1080
    },
    orgData: data.asset.orgData,
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE",
    scaling: 'INSIDE',
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)(),
    sharingMode: 'ALWAYS_SHARE_SOURCE',
    appInstanceID: {
      value: (0, _globalStates.getGlobalStates)().systemID,
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    }
  };
};
var createEmeraldSource = exports.createEmeraldSource = function createEmeraldSource(data) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: data.name || 'Engage Source',
    description: data.description || '',
    type: {
      value: "Emerald_Signage",
      name: "Emerald_Signage"
    },
    assets: [],
    params: [{
      name: "Icon",
      value: "/usr/share/userful-plugin-emerald-signage/icons/engage-signage.png",
      type: "STRING"
    }, {
      name: "Emerald Signage Manual Setup",
      value: "Emerald Signage requires you to first launch the session to get the pairing code then go to <a href=\"https://emeraldsignage.userful.com\" target=\"_blank\">https://emeraldsignage.userful.com</a>",
      type: "STRING"
    }, {
      name: "Refresh Interval",
      value: "0",
      type: "STRING"
    }, {
      name: "framerate",
      value: "30",
      type: "STRING"
    }, {
      name: "Show mouse pointer",
      value: "false",
      type: "STRING"
    }, {
      name: "Session Persistence",
      value: "Retain",
      type: "STRING"
    }],
    displayResolution: {
      width: 1920,
      height: 1080
    },
    orgData: initSamOrgData(data.appID || _common.APP_IDS.Engage),
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE",
    scaling: 'INSIDE',
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)(),
    sharingMode: 'UNIQUE_SOURCE',
    appInstanceID: {
      value: (0, _globalStates.getGlobalStates)().systemID,
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    }
  };
};
var createSapphireSource = exports.createSapphireSource = function createSapphireSource(data) {
  var background;
  if (data.background) {
    background = "file:" + data.background;
  } else {
    background = "color:" + data.solidBackground;
  }
  return {
    id: {
      value: data.id || (0, _uuid.v4)()
    },
    name: data.name,
    description: data.description || '',
    type: {
      value: "Sapphire_Spaces_-_Beta",
      name: "Sapphire_Spaces_-_Beta"
    },
    assets: [],
    params: [{
      name: "Icon",
      value: "/usr/share/userful-plugin-sapphire/icons/sapphire.png",
      type: "STRING"
    }, {
      name: "Meeting Room Name",
      value: data.name,
      type: "STRING"
    }, {
      name: "Air Gapped",
      value: data.airgapped ? "true" : "false",
      type: "STRING"
    }, {
      name: "RoomOS Device",
      value: data.roomosdevice,
      type: "STRING"
    }, {
      name: "Webex Auth Token",
      value: data.webexAuthToken,
      type: "STRING"
    }, {
      name: "Global State Link",
      value: data.globalStateLink,
      type: "STRING"
    }, {
      name: "Link",
      value: data.link,
      type: "STRING"
    }, {
      name: "Message",
      value: data.message,
      type: "STRING"
    }, {
      name: "Background",
      value: background,
      type: "STRING"
    }, {
      name: "Simplified",
      value: data.simplified ? "true" : "false",
      type: "STRING"
    }, {
      name: "Webex Unique ID",
      value: data.webexuniqueid,
      type: "STRING"
    }, {
      name: "Turn Server ID",
      value: data.turnServerID,
      type: "STRING"
    }, {
      name: "Turn Server URL",
      value: data.turnServerUrl,
      type: "STRING"
    }, {
      name: "Turn Server Username",
      value: data.turnServerUsername,
      type: "STRING"
    }, {
      name: "Turn Server Password",
      value: data.turnServerPassword,
      type: "STRING"
    }],
    displayResolution: {
      width: data.width,
      height: data.height
    },
    orgData: data.screenCastSource ? initSamOrgData(data.appID || _common.APP_IDS.Decisions) : initSamOrgData(data.appID || _common.APP_IDS.Spaces),
    outputType: "VIDEO_AND_AUDIO",
    controlMode: "NONE",
    scaling: 'FULL',
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)(),
    sharingMode: 'UNIQUE_SOURCE',
    appInstanceID: {
      value: (0, _globalStates.getGlobalStates)().systemID,
      appID: {
        value: (0, _globalStates.getGlobalStates)().appID.value
      }
    }
  };
};
var convertSourceDefToAssetsData = exports.convertSourceDefToAssetsData = function convertSourceDefToAssetsData(source) {
  if (!source) {
    return [];
  }
  return source.assets.map(function (item) {
    return {
      paramName: item.paramName,
      sourceAssets: item.sourceAssets.map(function (asset) {
        return asset.id;
      })
    };
  });
};