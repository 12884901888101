import { ReactNode } from "react";
import DraggableAndResizable, { Position, Size } from "./DraggableAndResizable";
import ReactPortal from "./ReactPortal";

interface IProps {
    children: ReactNode;
    show: boolean;
    onShow?: Function;
    onHide?: Function;    
    minWidth?: number;
    minHeight?: number;
    initWidth?: number;
    initHeight?: number;
    initTop?: number;
    initLeft?: number;
    className?: string;
    autoZoomTarget?: HTMLElement;
    style?: Partial<React.CSSProperties>;
    onDragStop?: (position: Position, size: Size) => void;
    title?: ReactNode;
    onMove?: (position: Position) => void;
    onResize?: (size: Size) => void;
    headers?: ReactNode;
    noHeader?: boolean;
    disable?: boolean;
}

export default function DraggableAndResizablePopup(props:IProps) {
    if (!props.show) {
        return null;
    } 

    return (
        <ReactPortal wrapperId="react-portal-modal-container">
            <DraggableAndResizable {...props}>
                {props.children}
            </DraggableAndResizable>
            
        </ReactPortal>
    );
}