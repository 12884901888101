import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

import {
    REQUEST_LIST_PASSPORTS, REQUEST_ADD_PASSPORT, REQUEST_UPDATE_PASSPORT_WITH_OAUTH, REQUEST_UPDATE_PASSPORT, REQUEST_REMOVE_PASSPORT,
     REQUEST_LIST_COLUMNS, REQUEST_LIST_DATA
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/servicenowMsgDefinitions';
import { ServicenowColumnsRequest, ServicenowPassportHeader, ServicenowPassportID, ServicenowDataRequest } from 'userful-chronos-app-common-js/dist/models/integration/servicenow';
import './servicenowMsgReciever';

export const requestListPassports = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_PASSPORTS);
};

export const requestAddPassport = (payload: ServicenowPassportHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_PASSPORT, payload);
};

export const requestUpdatePassportWithOAuth = (payload: ServicenowPassportHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PASSPORT_WITH_OAUTH, payload);
};

export const requestUpdatePassport = (payload: ServicenowPassportHeader) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_PASSPORT, payload);
};

export const requestRemovePassport = (payload: ServicenowPassportID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_PASSPORT, payload);
};

export const requestColumns = (payload: ServicenowColumnsRequest) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_COLUMNS, payload);
}

export const requestEmbedData = (payload: ServicenowDataRequest) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_DATA, payload);
}