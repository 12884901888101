import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserfulTooltip } from "userful-chronos-common-ui/dist";
import { UserfulGroup, UpdateKeycloakGroup, KeycloakGroup } from "../../model/CarbonExternalModels";
import { selectUserGroupTab, selectUserGroups, useCarbonDispatch, useCarbonSelector } from "../../store/carbonStore";
import { AppSliceActions } from "../../store/app/appSlice";
import UserGroupDropdown from "./UserGroupDropdown";
import UserGroupRoles from "./userGroupTabs/UserGroupRoles";
import UserGroupUsers from "./userGroupTabs/UserGroupUsers";
import UserGroupDetails from "./userGroupTabs/UserGroupDetails";
import { useEffect, useState } from "react";
import { initKeycloakGroup } from "../../store/userManagement/userManagementUtils";
import { objectEqual } from "../../common/functions/objectComparison";
import UserGroupDelete from "./userGroupDelete/UserGroupDelete";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { apiUpdateUserGroup } from "../../messages/api/gcmUserManagement";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";

interface IProps {
    group: UserfulGroup;
}

export default function UserGroupPage(props: IProps) {
    const dispatch = useCarbonDispatch();

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    const [originalGroup, setOriginalGroup] = useState<KeycloakGroup>(props.group ? props.group.group : initKeycloakGroup());
    const [currentGroup, setCurrentGroup] = useState<KeycloakGroup>(originalGroup);

    const groups: { [id: string]: UserfulGroup } = useCarbonSelector(selectUserGroups);
    const allGroups: UserfulGroup[] = [];

    for (const property in groups) {
        allGroups.push(groups[property]);
    }

    let nameError =
        (allGroups.find((c) => c.group.name === currentGroup.name) && currentGroup.name !== originalGroup.name) || currentGroup.name.length === 0
            ? "Please make sure the name is unique and is not empty."
            : "";

    const hasChanges = !objectEqual(originalGroup, currentGroup) && nameError.length === 0;

    useEffect(() => {
        nameError = "";
    }, [])

    useEffect(() => {
        setOriginalGroup(props.group ? props.group.group : initKeycloakGroup());
        setCurrentGroup(props.group ? props.group.group : initKeycloakGroup());
    }, [props]);

    // FUNCTIONS
    const detailsChange = (data) => {
        setCurrentGroup({ ...currentGroup, ...data });
    };
    const rolesChange = (data) => {
        setCurrentGroup({ ...currentGroup, ...data });
    };
    const usersChange = (data) => {
        setCurrentGroup({ ...currentGroup, ...data });
    };

    const onDiscardChanges = (e) => {
        setCurrentGroup(originalGroup);
    };
    const onSaveChanges = (e) => {
        const updateKeycloakGroup: UpdateKeycloakGroup = {
            id: currentGroup.id,
            displayName: currentGroup.displayName,
            description: currentGroup.description,
            members: currentGroup.members,
            name: currentGroup.name,
            realmRoles: currentGroup.roles,
        };

        apiUpdateUserGroup(getGlobalStates().keycloak.token, updateKeycloakGroup);
    };

    const tab = useCarbonSelector(selectUserGroupTab);

    if (!props.group) return <div className="genericWrapper">Group not found</div>;
    return (
        <div className="genericWrapper">
            <div className="actionsBar">
                <div className="cluster normalGap">
                    <UserfulTooltip title={"Return to permission sets"}>
                        <button
                            className="iconButton"
                            onClick={(e) => {
                                dispatch(
                                    AppSliceActions.setAppView({ type: "USER_GROUPS", id: { value: "NOT_DEFINED" } })
                                );
                            }}
                            id="group-go-back-button"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                    </UserfulTooltip>
                    <UserGroupDropdown group={props.group.group} />
                    <div className="multiStateWrapper">
                        <div
                            className={`mSbutton ${tab === "Roles" ? "active" : null}`}
                            onClick={(e) => {
                                if (tab !== "Roles") dispatch(AppSliceActions.setUserGroupTabs("Roles"));
                            }}
                        >
                            <p>Roles</p>
                        </div>

                        <div
                            className={`mSbutton ${tab === "Users" ? "active" : null}`}
                            onClick={(e) => {
                                if (tab !== "Users") dispatch(AppSliceActions.setUserGroupTabs("Users"));
                            }}
                        >
                            <p>Users</p>
                        </div>
                        <div
                            className={`mSbutton ${tab === "Details" ? "active" : null}`}
                            onClick={(e) => {
                                if (tab !== "Details") dispatch(AppSliceActions.setUserGroupTabs("Details"));
                            }}
                        >
                            <p>Details</p>
                        </div>
                    </div>
                </div>

                {props.group.group.editable && (
                    <div className="cluster normalGap">
                        <UserGroupDelete group={props.group.group} type="ICON" permissions={permissions} />
                    </div>
                )}
            </div>
            <div className="page-wrapper">
                {tab === "Roles" && (
                    <UserGroupRoles group={currentGroup} onFieldChange={rolesChange} permissions={permissions} />
                )}
                {tab === "Users" && (
                    <UserGroupUsers group={currentGroup} onFieldChange={usersChange} permissions={permissions} />
                )}
                {tab === "Details" && (
                    <UserGroupDetails
                        group={currentGroup}
                        onFieldChange={detailsChange}
                        nameError={nameError}
                        permissions={permissions}
                    />
                )}
            </div>
            <div className="userfulFormFooter">
                <button className="secondaryUserfulButton" disabled={!hasChanges} onClick={onDiscardChanges}>
                    Discard Changes
                </button>
                <button className="primaryUserfulButton" disabled={!hasChanges} onClick={onSaveChanges}>
                    Save Changes
                </button>
            </div>
        </div>
    );
}
