import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import { isIDNotSet, StringID } from "userful-chronos-app-common-js/dist/models/common"
import { UclientData } from "userful-chronos-app-common-js/dist/models/uclient"
import { ZeroClientData } from "userful-chronos-app-common-js/dist/models/zeroClient"

export const expectZCStatus = (
    ...statuses: Array<Array<string>>): Array<Function> => {
    return statuses.map(status => ((zcData: ZeroClientData) => zcData.transientData &&
        zcData.transientData.usbcData &&
        status.includes(zcData.transientData.usbcData.status)))
}

export const expectUCStatus = (
    ...statuses: Array<Array<string>>): Array<Function> => {
    return statuses.map(status => (
        (ucData: UclientData) => ucData.transientData &&
            ucData.transientData.connectionStatus &&
            status.includes(ucData.transientData.connectionStatus.connectionStatus)
    ));
}

export const displayHasSameIDOrVEAsUpdates = (display: UclientData | ZeroClientData, updates: UclientData[] | ZeroClientData[]): boolean => {
    if (updates.length < 1) {
        return false;
    }
    const foundIndex = updates.findIndex(item => item.id.value === display.id.value || item.activeOn.value === display.activeOn.value);
    return foundIndex >= 0;
}

export const getVEIDForClient = (display: UclientData | ZeroClientData): StringID => {
    if (display.lockStatus === 'LOCKED' && !isIDNotSet(display.lockedTo)) {
        return display.lockedTo;
    }
    if (!isIDNotSet(display.activeOn)) {
        return display.activeOn;
    }
    return {
        value: getGlobalStates().systemID
    }
}