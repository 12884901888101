"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initTableauPassport = exports.initPATCredentials = exports.initConnectedAppCredentials = exports.initBasicCredentials = exports.TableauAuthOptions = void 0;
var _uuid = require("uuid");
var initBasicCredentials = exports.initBasicCredentials = function initBasicCredentials() {
  return {
    username: "",
    password: ""
  };
};
var initPATCredentials = exports.initPATCredentials = function initPATCredentials() {
  return {
    username: "",
    tokenName: "",
    tokenSecret: ""
  };
};
var TableauAuthOptions = exports.TableauAuthOptions = [{
  value: 'ADMIN_BASIC',
  label: 'Admin Password',
  id: 'ADMIN_BASIC'
}, {
  value: 'ADMIN_PAT',
  label: 'Admin PAT',
  id: 'ADMIN_PAT'
}, {
  value: 'CONNECTED_APP',
  label: 'Connected App',
  id: 'CONNECTED_APP'
}, {
  value: 'PUBLIC',
  label: 'Public',
  id: 'PUBLIC'
}];
var initConnectedAppCredentials = exports.initConnectedAppCredentials = function initConnectedAppCredentials() {
  return {
    username: "",
    clientID: "",
    secretID: "",
    secretValue: ""
  };
};
var initTableauPassport = exports.initTableauPassport = function initTableauPassport() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    isConnected: false,
    serverInfo: {
      productVersionValue: "",
      productVersionBuild: "",
      restAPIVersion: ""
    },
    tableauServer: "",
    tableauSiteName: "",
    tableauSiteID: "",
    authenticationType: 'CONNECTED_APP',
    adminBasicCreds: {
      username: "",
      password: ""
    },
    adminPATCreds: {
      username: "",
      tokenName: "",
      tokenSecret: ""
    },
    connectedAppCreds: {
      username: "",
      clientID: "",
      secretID: "",
      secretValue: ""
    }
  };
};