import React, { ReactNode, useEffect } from 'react';
import { Provider } from 'react-redux';
import { createCdmOrgQueryForApp, createSamOrgQueryForApp } from 'userful-chronos-app-common-js/dist/models/sam/SAMUtils';
import {
  requestListPlayListDefs, requestListSourceDefs, requestListSourceSpecs,
  requestQueryPlaylistDefsAssets, requestQuerySourceDefs
} from '../sourceassets/msgs/MsgSender';
import mappingStore, { MappingGroupContext } from './mappingStore';
import './msgs/MappingSamMsgReceiver';
import { setAppIDForMappingSamReceiver } from './msgs/MappingSamMsgReceiver';
import './msgs/MappingCdmMsgReceiver';
import './msgs/EdgeMappingMsgReceiver';
import { requestListEdgeMappingGroups } from './msgs/MsgSender';
import { requestListDestinations } from '../destinations/msgs/MsgSender';
import { setAppIDForMappingReceiver } from './msgs/EdgeMappingMsgReceiver';
import { mappingGroupsSliceActions } from './mappingGroupsSlice';
import { requestListUC } from '../displays/msgs/UClientsMsgSender';



interface IProps {
  appID?: string;
  children: ReactNode,
}

function EdgeMappingGroupsProvider(props: IProps) {
  useEffect(() => {
    mappingStore.dispatch(mappingGroupsSliceActions.setEdgePlayerMode(true));
    mappingStore.dispatch(mappingGroupsSliceActions.clearMappingGroups());

    requestListSourceSpecs();
    requestListUC();

    if (props.appID) {
      setAppIDForMappingSamReceiver(props.appID);
      setAppIDForMappingReceiver(props.appID);
      const samOrgQuery = createSamOrgQueryForApp(props.appID);
      requestQuerySourceDefs(samOrgQuery);
      requestQueryPlaylistDefsAssets(samOrgQuery);
      requestListEdgeMappingGroups();
    } else {
      requestListSourceDefs();
      requestListPlayListDefs();
      requestListEdgeMappingGroups();
    }
    requestListDestinations();
    requestListSourceSpecs();
  }, []);

  return (
    <Provider store={mappingStore} context={MappingGroupContext}>
      {props.children}
    </Provider>
  );
}

export default EdgeMappingGroupsProvider;