import React, { useEffect, useState } from "react";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { useAuthenticationContext } from "userful-chronos-common-store/dist/auth";
import "./footer.scss";
import { useTranslation } from 'react-i18next';

interface VersionProps {
     version: string;
}

const currentYear = new Date().getFullYear();


export default function Footer(props: VersionProps) {
     const { t } = useTranslation();

     const {
          state: { userData },
     } = useAuthenticationContext();

     const getVersion = () => {
          if (getGlobalStates().preLoginData && getGlobalStates().preLoginData.softwareInfo.umxVersion) {
               return getGlobalStates().preLoginData.softwareInfo.umxVersion;
          }
          return props.version;
     };

     const [currentVersion, setCurrentVersion] = useState(getVersion());

     useEffect(() => {
          if (userData) {
               setCurrentVersion(userData.branding.appInfo.productVersion);
          }
     }, [userData]);


     return (
          <footer>
               <div>Userful {currentVersion} {t('CommonUI.copyright')} © 2005-{currentYear} Userful Corp. {t('CommonUI.copyright2')}</div>
          </footer>
     );
}
