import Form from 'react-bootstrap/Form';
import './UPasswordWidget.scss';
import { InputGroup } from 'react-bootstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { IUFormFieldState, useUFormContext } from "../Context/UFormContext";
import { IUFormField } from "../uFormUtils";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from 'react';
import UFieldTitle from "./UFieldTitle/UFieldTitle";

interface IProps {
    field: IUFormField;
    forceValue?: string | number | boolean | undefined;
    forceError?: string;
    onItemChange?: (fieldState: IUFormFieldState, field: IUFormField) => void;
}

function UniquePasswordWidget(props: IProps) {

    const { t } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onSubmitForm = (event: any) => {
        event.preventDefault();
    }

    let value = useUFormContext().state.fieldState[props.field.key];
    let error = useUFormContext().state.fieldErrors[props.field.key];

    const { onFieldChange } = useUFormContext();

    const onInputChange = (e) => {

        const targetValue: string = e.target.value;
        if (props.onItemChange) props.onItemChange({ [props.field.key]: targetValue }, props.field);
        else onFieldChange({ [props.field.key]: targetValue }, props.field);

    }

    return (
        <>
            <div className='userManagementTextWidget' style={{ width: props.field.width }}>
                <UFieldTitle
                    title={props.field.title}
                    help={props.field.help}
                    required={props.field.required}
                />

                <Form className='formcontrolPasswordWidget' onSubmit={onSubmitForm}>
                    <Form.Group className='form-group'>
                        <InputGroup>
                            <Form.Control
                                as="input"
                                value={props.forceValue ? props.forceValue : value ? value : value === 0 ? value : ""}
                                onChange={onInputChange}
                                autoComplete="off"
                                maxLength={200}
                                id={props.field.id || ''}
                                width={props.field.width}
                                type={showPassword ? 'text' : 'password'} />
                            <InputGroup.Text onClick={toggleShowPassword}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </InputGroup.Text>
                        </InputGroup>
                        {(error && error.length > 0) && <div className="invalidInputOne"> {t(error)}</div>}

                    </Form.Group>
                </Form>

            </div >


        </>
    );
}

export default UniquePasswordWidget;