
import './UFieldHeaderDes.scss';
import React from 'react';

interface IProps {
    name: string;
    description: string;
}

function UFieldHeaderDes(props: IProps) {

    return (
        <div className="fieldHeaderDiv">
            <div className="headDiv">
                {props.name}
            </div>
            <div className="descriptionDiv">
                {props.description}
            </div>
        </div>
    );
}

export default UFieldHeaderDes;