import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CdmDestinationGroup, CdmDestinationGroupContentUpdate, CdmDestinationGroupCreator } from 'userful-chronos-app-common-js/dist/models/cdm/CdmGroup';
import { HasCdmOrgData } from 'userful-chronos-app-common-js/dist/models/cdm/Common';
import { HasID, HasNameAndDescription, StringID } from 'userful-chronos-app-common-js/dist/models/common';
import { addOrUpdateReducer, removeReducer, sanitizeCdmOrgData, sanitizeCdmOrgDatas } from './CdmStoreUtils';
import { requestCreateDestinationGroup, requestRemoveDestinationGroup, requestUpdateDestinationGroupContent, requestUpdateDestinationGroupHeader, requestUpdateDestinationGroupOrgData } from './msgs/MsgSender';

const initialState: {
    destinationGroups: CdmDestinationGroup[],
    ready: boolean;
} = {
    destinationGroups: [],
    ready: false,
};

export const destinationGroupsSlice = createSlice({
    name: 'destinationGroupsSlice',
    initialState,
    reducers: {
        setDestinationGroups: (state, action: PayloadAction<CdmDestinationGroup[]>) => {
            state.ready = true;
            state.destinationGroups = sanitizeCdmOrgDatas(action.payload);
        },
        addOrUpdateDestinationGroup: (state, action: PayloadAction<CdmDestinationGroup>) => {
            addOrUpdateReducer(state, sanitizeCdmOrgData(action.payload), 'destinationGroups');
        },
        removeDestinationGroup: (state, action: PayloadAction<CdmDestinationGroup>) => {
            removeReducer(state, action.payload, 'destinationGroups');
        },
        createDestinationGroupToServer: (state, action: PayloadAction<CdmDestinationGroupCreator>) => {
            requestCreateDestinationGroup({ ...action.payload });
        },
        updateDestinationGroupToServer: (state, action: PayloadAction<{
            detailsUpdate: HasID & HasNameAndDescription & HasCdmOrgData,
            contentData: CdmDestinationGroupContentUpdate,
        }>) => {
            const { id, name, description, orgData } = action.payload.detailsUpdate;
            requestUpdateDestinationGroupHeader({ id, name, description });
            requestUpdateDestinationGroupOrgData({ id, orgData, });
            requestUpdateDestinationGroupContent({ ...action.payload.contentData });
        },
        removeDestinationGroupToServer: (state, action: PayloadAction<StringID>) => {
            requestRemoveDestinationGroup({ ...action.payload });
        },
    },
})

export const destinationGroupsSliceActions = destinationGroupsSlice.actions;

export default destinationGroupsSlice.reducer