let receivedInitialDestinations = false;
export const setReceivedInitialDestinations = () => receivedInitialDestinations = true;
export const hasReceivedInitialDestinations = () => (receivedInitialDestinations);
let receivedInitialDestinationsQuery = false;
export const setReceivedInitialDestinationsQuery = () => receivedInitialDestinationsQuery = true;
export const hasReceivedInitialDestinationsQuery = () => (receivedInitialDestinationsQuery);
let receivedInitialPhysicalEndPoints = false;
export const setReceivedInitialPhysicalEndPoints = () => receivedInitialPhysicalEndPoints = true;
export const hasReceivedInitialPhysicalEndPoints = () => (receivedInitialPhysicalEndPoints);
let receviedInitialVideoWallsEndPoints = false;
export const setReceviedInitialVideoWallsEndPoints = () => receviedInitialVideoWallsEndPoints = true;
export const hasReceviedInitialVideoWallsEndPoints = () => (receviedInitialVideoWallsEndPoints);


let receivedInitialSourceSpecs = false;
export const setReceivedInitialSourceSpecs = () => receivedInitialSourceSpecs = true;
export const hasReceivedInitialSourceSpecs = () => (receivedInitialSourceSpecs);
let receivedInitialSAMSource = false;
export const setReceivedInitialSAMSource = () => receivedInitialSAMSource = true;
export const hasReceivedInitialSAMSource = () => (receivedInitialSAMSource);
let receivedInitialSAMSourceQuery = false;
export const setReceivedInitialSAMSourceQuery = () => receivedInitialSAMSourceQuery = true;
export const hasReceivedInitialSAMSourceQuery = () => (receivedInitialSAMSourceQuery);
let receivedInitialSAMPlaylist = false;
export const setReceivedInitialSAMPlaylist = () => receivedInitialSAMPlaylist = true;
export const hasReceivedInitialSAMPlaylist = () => (receivedInitialSAMPlaylist);
let receivedInitialSAMPlaylistQuery = false;
export const setReceivedInitialSAMPlaylistQuery = () => receivedInitialSAMPlaylistQuery = true;
export const hasReceivedInitialSAMPlaylistQuery = () => (receivedInitialSAMPlaylistQuery);
let receviedInitialSAMAssets = false;
export const setReceviedInitialSAMAssets = () => receviedInitialSAMAssets = true;
export const hasReceviedInitialSAMAssets = () => (receviedInitialSAMAssets);
let receviedInitialSAMAssetsQuery = false;
export const setReceviedInitialSAMAssetsQuery = () => receviedInitialSAMAssetsQuery = true;
export const hasReceviedInitialSAMAssetsQuery = () => (receviedInitialSAMAssetsQuery);

let receviedInitialSchedules = false;
export const setReceviedInitialSchedules = () => receviedInitialSchedules = true;
export const hasReceviedInitialSchedules = () => (receviedInitialSchedules);
let receviedInitialSchedulesQuery = false;
export const setReceviedInitialSchedulesQuery = () => receviedInitialSchedulesQuery = true;
export const hasReceviedInitialSchedulesQuery = () => (receviedInitialSchedulesQuery);

let receivedInitialMapper = false;
export const setReceivedMapper = () => receivedInitialMapper = true;
export const hasReceivedMapper = () => (receivedInitialMapper);
