import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { PositionInfoPct, StringID, STRINGID_NOT_SET, UpdateSecurityData } from "userful-chronos-app-common-js/dist/models/common";
import {
    InsetComp,
    InsetCompGeometry,
    MapperCompCreator,
    MapperCompDestinationsUpdate,
    MapperCompHeaderUpdate,
    MapperCompInsetsUpdate,
    MapperCompLogicalGridUpdate,
    MapperCompLatencyUpdate,
    EdgeMapperCompCreator,
    EdgeInsetCompUpdater,
    MapperSettingsUpdate,
} from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";
import { hasReceivedMapper } from "../../InitialDataStates";
import {
    REQUEST_CREATE_MAPPER,
    REQUEST_LIST_MAPPERS,
    REQUEST_REMOVE_MAPPER,
    REQUEST_UPDATE_INSET_GEOMETRY,
    REQUEST_UPDATE_LOGICAL_GRID,
    REQUEST_UPDATE_MAPPER_DESTINATIONS,
    REQUEST_UPDATE_MAPPER_HEADER,
    REQUEST_UPDATE_MAPPER_USERFUL_SECURITY_DATA,
    REQUEST_UPDATE_MAPPER_INSETS,
    REQUEST_UPDATE_LATENCY,
    REQUEST_LIST_EDGE_MAPPERS,
    REQUEST_CREATE_EDGE_MAPPER,
    REQUEST_REMOVE_EDGE_MAPPER,
    REQUEST_UPDATE_EDGE_MAPPER_HEADER,
    REQUEST_UPDATE_EDGE_MAPPER_USERFUL_SECURITY_DATA,
    REQUEST_UPDATE_EDGE_MAPPER_DESTINATIONS,
    REQUEST_UPDATE_EDGE_MAPPER_INSET,
    REQUEST_REMOVE_EDGE_MAPPER_INSET,
    REQUEST_UPDATE_MAPPER_SETTINGS,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/mapping/MappingMsgDefinations";
import { REQUEST_LIST_VIDEOWALLS } from "./calibrationMsgDefinitions";
import { REQUEST_REMOVE_CHANNEL_SOURCE_FROM_MAPPER } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/ruby/rubyMsgDefinitions";
import { REQUEST_LIST_CONTROL_ROOMS } from "../../operationCenter/msgs/msgTypes";
import { REQUEST_LIST_MAPPER_LAYOUTS } from "userful-chronos-app-common-js/dist/models/layouts/messages/msgTypes";
import { REQUEST_LIST_UCONDUCT_CONTAINERS, REQUEST_LIST_UCONDUCT_VIEWS, REQUEST_LIST_UCONDUCT_WORKSPACES } from "../../uconduct/msgs/msgTypes";

// calibraion wall
export const requestListVideoWalls = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_VIDEOWALLS);
};
// operations centers
export const requestListOperationsCenters = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_CONTROL_ROOMS);
};
// mapper layouts
export const requestListMapperLayouts = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_MAPPER_LAYOUTS);
}
// uConduct
export const requestListUConductViews = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_UCONDUCT_VIEWS);
};
export const requestListUConductContainers = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_UCONDUCT_CONTAINERS);
};
export const requestListUConductWorkspaces = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_UCONDUCT_WORKSPACES);
};
// groups
export const requestListMappingGroups = (forceUpdate?: boolean) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_MAPPERS);
};
export const requestCreateMappingGroup = (payload: MapperCompCreator) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CREATE_MAPPER, payload);
};
export const requestRemoveMappingGroup = (payload: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_MAPPER, payload);
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_CHANNEL_SOURCE_FROM_MAPPER, payload);
};
export const requestUpdateMappingGroupHeader = (payload: MapperCompHeaderUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_MAPPER_HEADER, payload);
};
export const requestUpdateMappingGroupSecurityData = (payload: UpdateSecurityData) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_MAPPER_USERFUL_SECURITY_DATA, payload);
};
export const requestUpdateMappingGroupSettings= (payload: MapperSettingsUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_MAPPER_SETTINGS, payload);
};
export const requestUpdateMappingGroupSources = (payload: MapperCompInsetsUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_MAPPER_INSETS, payload);
};
export const requestUpdateMappingGroupDestinations = (payload: MapperCompDestinationsUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_MAPPER_DESTINATIONS, payload);
};
export const requestUpdateMappingGroupLogicalGrid = (payload: MapperCompLogicalGridUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_LOGICAL_GRID, payload);
};
export const requestUpdateMappingGroupLatency = (payload: MapperCompLatencyUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_LATENCY, payload);
};

export const requestUpdateMappingGroupInsetPosition = (
    id: StringID,
    videoMixerID: StringID,
    mapperID: StringID,
    positionInfo: PositionInfoPct
) => {
    const payload: InsetCompGeometry = {
        id,
        mapperID,
        positionInfo,
    };
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_INSET_GEOMETRY, payload);
};

export const requestListEdgeMappingGroups = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_EDGE_MAPPERS);
};
export const requestCreateEdgeMappingGroup = (payload: EdgeMapperCompCreator) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_CREATE_EDGE_MAPPER, payload);
};
export const requestRemoveEdgeMappingGroup = (payload: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_EDGE_MAPPER, payload);
};
export const requestUpdateEdgeMappingGroupHeader = (payload: MapperCompHeaderUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_EDGE_MAPPER_HEADER, payload);
};
export const requestUpdateEdgeMappingGroupSecurityData = (payload: UpdateSecurityData) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_EDGE_MAPPER_USERFUL_SECURITY_DATA, payload);
};
export const requestUpdateEdgeMappingGroupSources = (payload: EdgeInsetCompUpdater) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_EDGE_MAPPER_INSET, payload);
};
export const requestRemoteEdgeMappingGroupSources = (payload: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_EDGE_MAPPER_INSET, payload);
};
export const requestUpdateEdgeMappingGroupDestinations = (payload: MapperCompDestinationsUpdate) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_EDGE_MAPPER_DESTINATIONS, payload);
};
