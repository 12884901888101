import "./noSource.scss";
import Button from '../../Button/Button';
import React from 'react';
interface IProps {
    headerName: any;
    headerDescription?: any;
    buttonName?: any;
    headerNameShow: boolean;
    headerDescriptionShow: boolean;
    buttonNameShow: boolean;
    onClick?: any;

}

function NoSourcePlaceholder(props: IProps) {

    return (

        <div className="overallNoSource">
            <div className="overallSubNoSource">
                <div className="noSourceImageDiv">
                    <img src="./images/noRoom.svg" alt='no-user' />
                </div>

                <div className="noSourceDesDiv">
                    <div className="noSourceDesOneDiv">
                        {props.headerName}

                    </div>

                    {props.headerDescriptionShow && <div className="noSourceDesTwoDiv">
                        {props.headerDescription}

                    </div>}
                </div>

                {props.buttonNameShow && <Button onClick={props.onClick} variant={"secondary"} id="create-source-center-button">
                    {props.buttonName}
                </Button>}
            </div>

        </div>

    );
}

export default NoSourcePlaceholder;