export const path = "/kc/auth";
export const authorize_microsoft_identity_provider_path = `${path}/microsoft`;
export const keycloak_path = `${path}/auth/status`;

export const GET_MICROSOFT_INTEGRATIONS = `${authorize_microsoft_identity_provider_path}`;
export const PUT_MICROSOFT_INTEGRATIONS = `${authorize_microsoft_identity_provider_path}`;
export const POST_MICROSOFT_INTEGRATIONS = `${authorize_microsoft_identity_provider_path}`;
export const DELETE_MICROSOFT_INTEGRATIONS = `${authorize_microsoft_identity_provider_path}`;
export const GET_MICROSOFT_SYNC_USERS = `${authorize_microsoft_identity_provider_path}/syncUsers`;

export const GET_KEYCLOAK_STATUS = `${keycloak_path}`;