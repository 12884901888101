import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import {
    RESPOND_LIST_PASSPORTS, RESPOND_ADD_PASSPORT, RESPOND_UPDATE_PASSPORT,
    RESPOND_REMOVE_PASSPORT, NOTIFY_PASSPORT_UPDATE,
    RESPOND_WORKBOOK_LIST, RESPOND_VIEW_LIST
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/tableauMsgDefinitions';
import { TableauPassport, TableauPassportHeader, TableauPassportID, TableauView, TableauWorkbook } from "userful-chronos-app-common-js/dist/models/integration/tableau";
import integrationStore from "../integrationStore";
import { tableauActions } from "../tableauSlice";
import { registerErrorMsgHandlerByMsgDefinition } from "userful-chronos-app-common-js/dist/message/messageRegistery";

registerMsgHandler(RESPOND_LIST_PASSPORTS, (payload) => {
    console.warn("receive RESPOND_LIST_PASSPORTS");
    const data = JSON.parse(payload) as TableauPassport[];
    integrationStore.dispatch(tableauActions.setPassports(data));
});

registerMsgHandler(RESPOND_ADD_PASSPORT, (payload) => {
    console.warn("receive RESPOND_ADD_PASSPORT");
    const data = JSON.parse(payload) as TableauPassportHeader;
    integrationStore.dispatch(tableauActions.changeLoadState("RENDER"));
    integrationStore.dispatch(tableauActions.setOpenForm(false));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_ADD_PASSPORT, (data) => {
    integrationStore.dispatch(tableauActions.changeLoadState("RENDER"));
});

registerMsgHandler(RESPOND_UPDATE_PASSPORT, (payload) => {
    console.warn("receive RESPOND_UPDATE_PASSPORT");
    const data = JSON.parse(payload) as TableauPassportHeader;
    integrationStore.dispatch(tableauActions.changeLoadState("RENDER"));
    integrationStore.dispatch(tableauActions.setOpenForm(false));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_UPDATE_PASSPORT, (data) => {
    integrationStore.dispatch(tableauActions.changeLoadState("RENDER"));
});

registerMsgHandler(RESPOND_REMOVE_PASSPORT, (payload) => {
    console.warn("receive RESPOND_REMOVE_PASSPORT");
    const data = JSON.parse(payload) as TableauPassportID;
    integrationStore.dispatch(tableauActions.removePassport(data));
});

registerMsgHandler(NOTIFY_PASSPORT_UPDATE, (payload) => {
    console.warn("receive NOTIFY_PASSPORT_UPDATE");
    const data = JSON.parse(payload) as TableauPassport;
    integrationStore.dispatch(tableauActions.addOrUpdatePassport(data));
});

registerMsgHandler(RESPOND_WORKBOOK_LIST, (payload) => {
    console.warn("receive RESPOND_WORKBOOK_LIST");
    const data = JSON.parse(payload) as TableauWorkbook[];
    integrationStore.dispatch(tableauActions.setWorkbooks(data));
});

registerMsgHandler(RESPOND_VIEW_LIST, (payload) => {
    console.warn("receive RESPOND_VIEW_LIST");
    const data = JSON.parse(payload) as TableauView[];
    integrationStore.dispatch(tableauActions.setViews(data));
});