"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_ACCESS_TOKEN_REQUEST = exports.SECURITY_SERVICE_DESC_REQUEST = exports.REQUEST_PRE_LOGIN = exports.REQUEST_POST_LOGIN = exports.REQUEST_DISCONNECT = void 0;
var _messageModel = require("../../messageModel");
var REQUEST_PRE_LOGIN = exports.REQUEST_PRE_LOGIN = new _messageModel.RequestMessageDefinition(10, 0);
var REQUEST_POST_LOGIN = exports.REQUEST_POST_LOGIN = new _messageModel.RequestMessageDefinition(10, 1);
var REQUEST_DISCONNECT = exports.REQUEST_DISCONNECT = new _messageModel.RequestMessageDefinition(10, 2);
var SECURITY_SERVICE_DESC_REQUEST = exports.SECURITY_SERVICE_DESC_REQUEST = new _messageModel.RequestMessageDefinition(62, 0);
var SET_ACCESS_TOKEN_REQUEST = exports.SET_ACCESS_TOKEN_REQUEST = new _messageModel.RequestMessageDefinition(62, 1);