import React from 'react';
import './search.scss';
import { useTranslation } from 'react-i18next';
import { faMagnifyingGlass, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
    className?: string,
    width?: string,
    placeholder?: string,
    onChange?: Function,
    value?: string,
    onRefresh: Function,
}

export default function Search(props: IProps) {
    const { className, width, placeholder, onChange, value, onRefresh } = props;

    const searchClass = className ? className : "";
    const { t } = useTranslation();
    return (
        <div className={`${searchClass} app-search search`}>
            <div style={{position: "absolute", width: '16px', height: '16px'}}>
                <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
            </div>
            <input
                className="search-input"
                type="input"
                placeholder={placeholder || t('CommonUI.SearchPlaceholder')}
                style={{ width: width || "402px" }}
                value={value || ""}
                onChange={(e) => { onChange(e) }}
            />
            {value && value !== "" && <FontAwesomeIcon icon={faXmark} className='go-icon clear' onClick={() => onRefresh()} />}
        </div>
    )
}