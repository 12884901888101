import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";

import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    HeliosController,
    MegapixelControllerHeader,
    HeliosDisplayAll,
    HeliosDisplayBrightness,
    HeliosDisplayColorTemperature,
    HeliosDisplayGamma,
    HeliosDisplayBlackout,
    HeliosDisplayFreeze,
    HeliosIngestInput,
} from "userful-chronos-app-common-js/dist/models/integration/megapixel";
import integrationStore from "../integrationStore";
import { megapixelActions } from "../megapixelSlice";
import {
    RESPONSE_LIST_HELIOS_CONTROLLERS,
    RESPONSE_ADD_HELIOS_CONTROLLER,
    RESPONSE_UPDATE_HELIOS_CONTROLLER,
    RESPONSE_REMOVE_HELIOS_CONTROLLER,
    NOTIFY_CONTROLLER_HELIOS_UPDATE,
    RESPONSE_SET_HELIOS_DISPLAY,
    RESPONSE_SET_HELIOS_DISPLAY_BRIGHTNESS,
    RESPONSE_SET_HELIOS_DISPLAY_COLORTEMPERATURE,
    RESPONSE_SET_HELIOS_DISPLAY_GAMMA,
    RESPONSE_SET_HELIOS_DISPLAY_BLACKOUT,
    RESPONSE_SET_HELIOS_DISPLAY_FREEZE,
    RESPONSE_SET_HELIOS_INGEST_INPUT,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/megapixelMsgDefinitions";

registerMsgHandler(RESPONSE_LIST_HELIOS_CONTROLLERS, (payload) => {
    console.debug("receive RESPONSE_LIST_HELIOS_CONTROLLERS");
    const data = JSON.parse(payload) as HeliosController[];
    integrationStore.dispatch(megapixelActions.setHeliosControllers(data));
});
registerMsgHandler(RESPONSE_ADD_HELIOS_CONTROLLER, (payload) => {
    console.debug("receive RESPONSE_ADD_HELIOS_CONTROLLER");
    const data = JSON.parse(payload) as MegapixelControllerHeader;
});
registerMsgHandler(RESPONSE_UPDATE_HELIOS_CONTROLLER, (payload) => {
    console.debug("receive RESPONSE_UPDATE_HELIOS_CONTROLLER");
    const data = JSON.parse(payload) as MegapixelControllerHeader;
});
registerMsgHandler(RESPONSE_REMOVE_HELIOS_CONTROLLER, (payload) => {
    console.debug("receive RESPONSE_REMOVE_HELIOS_CONTROLLER");
    const data = JSON.parse(payload) as StringID;
    integrationStore.dispatch(megapixelActions.removeHeliosController(data));
});
registerMsgHandler(NOTIFY_CONTROLLER_HELIOS_UPDATE, (payload) => {
    console.debug("receive NOTIFY_CONTROLLER_HELIOS_UPDATE");
    const data = JSON.parse(payload) as HeliosController;
    integrationStore.dispatch(megapixelActions.addOrUpdateHeliosControllers(data));
});
registerMsgHandler(RESPONSE_SET_HELIOS_DISPLAY, (payload) => {
    console.debug("receive RESPONSE_SET_HELIOS_DISPLAY");
    const data = JSON.parse(payload) as HeliosDisplayAll;
});
registerMsgHandler(RESPONSE_SET_HELIOS_DISPLAY_BRIGHTNESS, (payload) => {
    console.debug("receive RESPONSE_SET_HELIOS_DISPLAY_BRIGHTNESS");
    const data = JSON.parse(payload) as HeliosDisplayBrightness;
});
registerMsgHandler(RESPONSE_SET_HELIOS_DISPLAY_COLORTEMPERATURE, (payload) => {
    console.debug("receive RESPONSE_SET_HELIOS_DISPLAY_COLORTEMPERATURE");
    const data = JSON.parse(payload) as HeliosDisplayColorTemperature;
});
registerMsgHandler(RESPONSE_SET_HELIOS_DISPLAY_GAMMA, (payload) => {
    console.debug("receive RESPONSE_SET_HELIOS_DISPLAY_GAMMA");
    const data = JSON.parse(payload) as HeliosDisplayGamma;
});
registerMsgHandler(RESPONSE_SET_HELIOS_DISPLAY_BLACKOUT, (payload) => {
    console.debug("receive RESPONSE_SET_HELIOS_DISPLAY_BLACKOUT");
    const data = JSON.parse(payload) as HeliosDisplayBlackout;
});
registerMsgHandler(RESPONSE_SET_HELIOS_DISPLAY_FREEZE, (payload) => {
    console.debug("receive RESPONSE_SET_HELIOS_DISPLAY_FREEZE");
    const data = JSON.parse(payload) as HeliosDisplayFreeze;
});
registerMsgHandler(RESPONSE_SET_HELIOS_INGEST_INPUT, (payload) => {
    console.debug("receive RESPONSE_SET_HELIOS_INGEST_INPUT");
    const data = JSON.parse(payload) as HeliosIngestInput;
});
