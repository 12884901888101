"use strict";

var _login = require("../../../message/messageTypeDefinitions/request/login");
var _login2 = require("../../../message/messageTypeDefinitions/respond/login");
var _common = require("../../../models/common");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _postlogin = require("../../functions/postlogin");
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_login.REQUEST_POST_LOGIN, function (payload) {
  var appID = payload.clientAppName;
  switch (appID) {
    case _common.APP_IDS.Calibration:
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_login2.RESPOND_POST_LOGIN, (0, _postlogin.getCalibrationPostLogin)());
      break;
    case _common.APP_IDS.Launcher:
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_login2.RESPOND_POST_LOGIN, (0, _postlogin.getLauncherPostLogin)());
      break;
    case _common.APP_IDS.Trends:
    case _common.APP_IDS.Artistic:
    case _common.APP_IDS.Engage:
    case _common.APP_IDS.Remote:
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_login2.RESPOND_POST_LOGIN, (0, _postlogin.getLauncherPostLogin)());
      break;
    case _common.APP_IDS.Decisions:
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_login2.RESPOND_POST_LOGIN, (0, _postlogin.getControlRoomPostLogin)());
      break;
    case _common.APP_IDS.Config:
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_login2.RESPOND_POST_LOGIN, (0, _postlogin.getConfigPostLogin)());
      break;
    case _common.APP_IDS.Spaces:
      (0, _StandaloneMsgHandler.respondStandaloneRequest)(_login2.RESPOND_POST_LOGIN, (0, _postlogin.getSapphirePostLogin)());
      break;
    default:
      console.error("Postlogin data for ".concat(appID, " is not implemented"));
  }
});