import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeycloakGroup, KeycloakRole } from "../../../model/CarbonExternalModels";
import { selectRoles, useCarbonSelector } from "../../../store/carbonStore";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { faMinus } from "@fortawesome/pro-solid-svg-icons";
import { UserPermission } from "../../../model/CarbonPermissionModels";

interface IProps {
    group: KeycloakGroup;
    onFieldChange(data): void;
    permissions: UserPermission;
}

export default function UserGroupRoles({ group, onFieldChange, permissions }: IProps) {
    const canChange = group.editable && permissions.isUserCreator;

    console.warn(group.roles);

    const roles = useCarbonSelector(selectRoles);
    const allRoles: KeycloakRole[] = [];
    for (const property in roles) {
        allRoles.push(roles[property]);
    }

    const selectAll = group.roles.length === allRoles.length;
    const atLeastOneSelected = group.roles.length > 0;

    const toggleSelectAll = (e) => {
        let ids: StringID[] = [];

        if (!atLeastOneSelected) {
            for (let i = 0; i < allRoles.length; i++) {
                const newIDs = recursiveToggleSelect(allRoles[i], []);

                ids = [...ids, ...newIDs];
            }
        }
        if (canChange) onFieldChange({ roles: ids });
    };

    const findParent = (roleID: StringID, role: KeycloakRole): KeycloakRole => {
        let parent = null;

        if (roleID.value === role.id.value) return null;

        for (let i = 0; i < role.composites.length; i++) {
            if (role.composites[i].id.value === roleID.value) return role;

            parent = findParent(roleID, role.composites[i]);
            if (parent) break;
        }

        return parent;
    };

    const recursiveToggleSelectUp = (r: KeycloakRole, allSelectedIDs: StringID[]): StringID[] => {
        const newSelectedIDs: StringID[] = allSelectedIDs;

        let parent = null;
        for (let i = 0; i < allRoles.length; i++) {
            parent = findParent(r.id, allRoles[i]);
            if (parent) break;
        }
        if (parent) {
            const foundParentIndex = newSelectedIDs.findIndex((i) => i.value === parent.id.value);

            if (foundParentIndex >= 0) {
                newSelectedIDs.splice(foundParentIndex, 1);

                recursiveToggleSelectUp(parent, allSelectedIDs);
            }
        }

        return newSelectedIDs;
    };

    const recursiveToggleSelect = (r: KeycloakRole, allSelectedIDs: StringID[], negate?: boolean): StringID[] => {
        const newSelectedIDs: StringID[] = allSelectedIDs;

        // See if the role is already assigned
        const foundIndex = newSelectedIDs.findIndex((i) => i.value === r.id.value);

        if (foundIndex >= 0 || negate) {
            if (foundIndex >= 0) newSelectedIDs.splice(foundIndex, 1);

            if (r.composites && r.composites.length > 0) {
                for (let i = 0; i < r.composites.length; i++) {
                    recursiveToggleSelect(r.composites[i], newSelectedIDs, true);
                }
            }

            recursiveToggleSelectUp(r, allSelectedIDs);
        } else {
            let atLeastOneChild = false;
            if (r.composites && r.composites.length > 0) {
                for (let i = 0; i < r.composites.length; i++) {
                    atLeastOneChild = allSelectedIDs.find((id) => id.value === r.composites[i].id.value) ? true : false;
                    if (atLeastOneChild) break;
                }
            }

            if (atLeastOneChild) {
                if (r.composites && r.composites.length > 0) {
                    for (let i = 0; i < r.composites.length; i++) {
                        // console.warn(r.composites[i].name);
                        recursiveToggleSelect(r.composites[i], newSelectedIDs, true);
                    }
                }

                recursiveToggleSelectUp(r, allSelectedIDs);
            } else {
                newSelectedIDs.push(r.id);
                if (r.composites && r.composites.length > 0) {
                    for (let i = 0; i < r.composites.length; i++) {
                        recursiveToggleSelect(r.composites[i], newSelectedIDs);
                    }
                }
            }
        }

        return newSelectedIDs;
    };

    const toggleSelect = (r: KeycloakRole) => {
        const allSelectedIDs: StringID[] = group.roles.map((i) => i);

        const newSelectedIDs: StringID[] = recursiveToggleSelect(r, allSelectedIDs);

        if (canChange) onFieldChange({ roles: newSelectedIDs });
    };

    const insertRole = (r: KeycloakRole) => {
        const selected = group.roles.find((role) => role.value === r.id.value);
        const composite = r.composites.length >= 1;

        if (composite) {
            let compositesSelected = 0;
            for (let i = 0; i < r.composites.length; i++) {
                if (group.roles.find((role) => role.value === r.composites[i].id.value)) {
                    compositesSelected++;
                }
            }

            const selectAll = compositesSelected === r.composites.length;
            const atLeastOneSelected = compositesSelected > 0;

            return (
                <div className="compositeContainer">
                    <div
                        className={`fieldRowWrapper ${canChange ? "" : "disabled"}`}
                        onClick={(e) => {
                            if (canChange) toggleSelect(r);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <div className={`checkbox ${selectAll || atLeastOneSelected ? "checked" : null} ${canChange ? "" : "disabled"}`}>
                            {selectAll ? <FontAwesomeIcon icon={faCheck} /> : null}
                            {atLeastOneSelected && !selectAll ? <FontAwesomeIcon icon={faMinus} /> : null}
                        </div>
                        <div className="fieldColumnWrapper shortGap">
                            <div className="mainText">{r.displayName}</div>
                            <div className="secondaryText">{r.description}</div>
                        </div>
                    </div>
                    <div style={{ marginLeft: 32 }} className="rolesContainer">
                        {r.composites.map((role) => {
                            return insertRole(role);
                        })}
                    </div>
                </div>
            );
        }
        return (
            <div className="compositeContainer">
                <div
                    key={r.id.value}
                    className={`fieldRowWrapper ${canChange ? "" : "disabled"}`}
                    onClick={(e) => {
                        if (canChange) toggleSelect(r);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <div className={`checkbox ${selected ? "checked" : ""} ${canChange ? "" : "disabled"}`}>{selected ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
                    <div className="fieldColumnWrapper shortGap">
                        <div className="mainText">{r.displayName}</div>
                        <div className="secondaryText">{r.description}</div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="userfulFormBody">
            <div className="userfulFormHeader">
                <div className="userfulFormRow">
                    <h1 className="userfulFormHeaderTitle">Application Roles</h1>
                </div>
                <div className="userfulFormRow">
                    <h3 className="userfulFormHeaderDescription">Assign a set of roles to this permissions set.</h3>
                </div>
            </div>

            <div className="container">
                <div className={`fieldRowWrapper ${canChange ? "" : "disabled"}`} onClick={toggleSelectAll} style={{ cursor: "pointer" }}>
                    <div className={`checkbox ${selectAll || atLeastOneSelected ? "checked" : null} ${canChange ? "" : "disabled"}`}>
                        {selectAll ? <FontAwesomeIcon icon={faCheck} /> : null}
                        {atLeastOneSelected && !selectAll ? <FontAwesomeIcon icon={faMinus} /> : null}
                    </div>
                    <div className="fieldColumnWrapper shortGap">
                        <div className="mainText">Select All</div>
                    </div>
                </div>
                <hr />
                <div className="rolesContainer">
                    {allRoles.map((r) => {
                        return insertRole(r);
                    })}
                </div>
            </div>
        </div>
    );
}
