import React from "react";
import { saveButton, cancelButton, discardChangesButton } from "../../Widgets/Buttons/Presets/buttonPresets";
import ConfirmationDialog from "../../Widgets/ConfirmationDialog/ConfirmationDialog";
import { IConfirmationDialogTrigger } from "../../Widgets/ConfirmationDialog/confirmationDialogUtils";
import { useTranslation } from "react-i18next";

interface IProps {
     onConfirm(e): void;
     trigger?: IConfirmationDialogTrigger;
}

export default function UFormConfirmationDialog(props: IProps) {
     const { t } = useTranslation();

     return (
          <ConfirmationDialog
               type="WARNING"
               title={t("Shared.discardChanges")}
               description={t("Shared.discardChangesConfirm")}
               trigger={props.trigger}
               buttons={[{ ...discardChangesButton, onClick: props.onConfirm }, cancelButton]}
          />
     );
}
