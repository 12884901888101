"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_UPDATE_CALIBRATION_GEOMETRY = exports.RESPONSE_START_CALIBRATION_SESSION = exports.RESPONSE_ERROR = exports.RESPONSE_END_CALIBRATION_SESSION = exports.RESPONSE_DISPLAYS = exports.REQUEST_VIDEO_WALL_UPDATE = exports.REQUEST_VIDEO_WALL_REMOVE = exports.REQUEST_VIDEO_WALL_CREATE = exports.REQUEST_UPDATE_CALIBRATION_PATTERN = exports.REQUEST_UPDATE_CALIBRATION_GEOMETRY = exports.REQUEST_START_CALIBRATION_SESSION = exports.REQUEST_END_CALIBRATION_SESSION_AND_UPDATE = exports.REQUEST_END_CALIBRATION_SESSION_AND_CREATE = exports.REQUEST_END_CALIBRATION_SESSION = exports.NOTIFY_VIDEO_WALL_UPDATED = exports.NOTIFY_VIDEO_WALL_REMOVED = exports.NOTIFY_VIDEO_WALL_CREATED = void 0;
var _messageModel = require("../../messageModel");
var ACTIVE = 2100;
var VIDEO_WALL = 2101;
var DISPLAYS = 3010;
var REQUEST_START_CALIBRATION_SESSION = exports.REQUEST_START_CALIBRATION_SESSION = new _messageModel.RequestMessageDefinition(ACTIVE, 0);
var REQUEST_END_CALIBRATION_SESSION = exports.REQUEST_END_CALIBRATION_SESSION = new _messageModel.RequestMessageDefinition(ACTIVE, 1);
var REQUEST_UPDATE_CALIBRATION_PATTERN = exports.REQUEST_UPDATE_CALIBRATION_PATTERN = new _messageModel.RequestMessageDefinition(ACTIVE, 2);
var REQUEST_UPDATE_CALIBRATION_GEOMETRY = exports.REQUEST_UPDATE_CALIBRATION_GEOMETRY = new _messageModel.RequestMessageDefinition(ACTIVE, 3);
var REQUEST_END_CALIBRATION_SESSION_AND_UPDATE = exports.REQUEST_END_CALIBRATION_SESSION_AND_UPDATE = new _messageModel.RequestMessageDefinition(ACTIVE, 4);
var REQUEST_END_CALIBRATION_SESSION_AND_CREATE = exports.REQUEST_END_CALIBRATION_SESSION_AND_CREATE = new _messageModel.RequestMessageDefinition(ACTIVE, 5);
var RESPONSE_START_CALIBRATION_SESSION = exports.RESPONSE_START_CALIBRATION_SESSION = new _messageModel.RespondMessageDefinition(ACTIVE, 0);
var RESPONSE_END_CALIBRATION_SESSION = exports.RESPONSE_END_CALIBRATION_SESSION = new _messageModel.RespondMessageDefinition(ACTIVE, 1);
var RESPONSE_UPDATE_CALIBRATION_GEOMETRY = exports.RESPONSE_UPDATE_CALIBRATION_GEOMETRY = new _messageModel.RespondMessageDefinition(ACTIVE, 2);
var RESPONSE_ERROR = exports.RESPONSE_ERROR = new _messageModel.RespondMessageDefinition(VIDEO_WALL, 999);
var REQUEST_VIDEO_WALL_CREATE = exports.REQUEST_VIDEO_WALL_CREATE = new _messageModel.RequestMessageDefinition(VIDEO_WALL, 0);
var REQUEST_VIDEO_WALL_REMOVE = exports.REQUEST_VIDEO_WALL_REMOVE = new _messageModel.RequestMessageDefinition(VIDEO_WALL, 1);
var REQUEST_VIDEO_WALL_UPDATE = exports.REQUEST_VIDEO_WALL_UPDATE = new _messageModel.RequestMessageDefinition(VIDEO_WALL, 2);
var NOTIFY_VIDEO_WALL_CREATED = exports.NOTIFY_VIDEO_WALL_CREATED = new _messageModel.RespondMessageDefinition(VIDEO_WALL, 0);
var NOTIFY_VIDEO_WALL_REMOVED = exports.NOTIFY_VIDEO_WALL_REMOVED = new _messageModel.RespondMessageDefinition(VIDEO_WALL, 1);
var NOTIFY_VIDEO_WALL_UPDATED = exports.NOTIFY_VIDEO_WALL_UPDATED = new _messageModel.RespondMessageDefinition(VIDEO_WALL, 2);
var RESPONSE_DISPLAYS = exports.RESPONSE_DISPLAYS = new _messageModel.RespondMessageDefinition(DISPLAYS, 4);