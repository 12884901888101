import React, { useCallback, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./uploadFileSource.scss";
import { apiPost, getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { useDropzone } from "react-dropzone";
import { SamOrgData } from "userful-chronos-app-common-js/dist/models/sam/Common";
import { toast } from "react-toastify";
import UploadFileProgress from "./UploadFileProgress";
import { initSamFileUploadMetaData } from "userful-chronos-app-common-js/dist/models/sam/SAMUtils";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import TagForm from '../SAM/TagForm';
import AppPermissionForm from '../SAM/AppPermissionForm';
import UploadFileExtensionValidation from "./uploadFileExtensionValidation";
import HelpInfo from "../Widgets/HelpInfo/HelpInfo";
import { APP_IDS } from "userful-chronos-app-common-js/dist/models/common";
import { faClose, faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";
import "./../UForm/UFormFields/UFormFields.scss";
import { SamFileUploadMetaData } from "userful-chronos-app-common-js/dist/models/sam/SAMAsset";
import { getRootPath } from "userful-chronos-app-common-js/dist/routing";

type Iprops = {
    handleCloseUploadFile?: Function;
    inSource?: boolean;
    updateSourceAsset?: Function;
    hideTitle?: boolean;
};

const uploadProgressOptions = {
    type: toast.TYPE.INFO,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
    newestOnTop: true,
    closeOnClick: false,
    draggable: false,
    containerId: "uploadFileContainer",
};

interface FileState {
    file: File,
    fileName: string,
    metadata: SamFileUploadMetaData,
}

export default function UploadFile(props: Iprops) {
    const UPLOAD_URL = `${getGlobalStates().host}/sam/upload`;
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<FileState[]>([]);

    const handleShowUploadFile = () => {
        setSelectedFiles([]);
        setIsFilePicked(false);
    };

    const onChange = (e) => {
        if (!selectedFiles[0]) {
            return;
        }
        const update = [...selectedFiles];
        update.splice(0, 1, {
            ...selectedFiles[0],
            metadata: {
                ...selectedFiles[0].metadata,
                [e.target.name]: e.target.value,
            }
        });
        setSelectedFiles(update);
    };

    const onChangeFilename = (e) => {
        if (!selectedFiles[0]) {
            return;
        }
        const update = [...selectedFiles];
        update.splice(0, 1, {
            ...selectedFiles[0],
            fileName: e.target.value,
        });
        setSelectedFiles(update);
    };

    const onOrgDataChange = (orgData: SamOrgData) => {
        if (!selectedFiles[0]) {
            return;
        }
        const update = selectedFiles.map(f => ({
            ...f,
            metadata: {
                ...f.metadata,
                orgData,
            }
        }))
        setSelectedFiles(update);
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const validFiles = acceptedFiles.filter(item => UploadFileExtensionValidation(item.name));
        if (validFiles.length < 1) {
            toast.error(t("Sources&Destinations.Sources.UploadFile.invalidFiles"), {
                toastId: "file-upload-invalid-files",
                containerId: 'uploadFileContainer'
            })
        } else {
            toast.dismiss("file-upload-invalid-files");
        }
        setSelectedFiles(validFiles.map(f => ({
            file: f,
            fileName: f.name,
            metadata: {
                ...initSamFileUploadMetaData(),
                id: { value: uuidv4() },
                name: f.name,
                description: `Uploaded on ${new Date().toLocaleString()}`,
            }
        })))
        setIsFilePicked(validFiles.length > 0);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const { t } = useTranslation();

    const handleSubmission = () => {
        for (const selected of selectedFiles) {
            const formData = new FormData();
            formData.append("file", selected.file);
            formData.append("fileName", selected.fileName);
            formData.append("metadata", JSON.stringify(selected.metadata));
            const toastId = toast(<UploadFileProgress progress={0} filename={selected.fileName} />, { ...uploadProgressOptions, autoClose: 5000 });
            apiPost(UPLOAD_URL, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${getGlobalStates().keycloak.token}`,
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                    toast.update(toastId, {
                        render: <UploadFileProgress progress={progress} filename={selected.fileName} />,
                        containerId: "uploadFileContainer",
                    });
                },
            })
                .then((response) => {
                    if (response.status < 300) {
                        toast.update(toastId, {
                            type: toast.TYPE.SUCCESS,
                            render: t("Sources&Destinations.Sources.UploadFile.successMsg", { filename: selected.fileName }),
                            containerId: "uploadFileContainer",
                        });
                        if (props.updateSourceAsset) {
                            props.updateSourceAsset(selected.metadata.id.value);
                        }
                    } else {
                        toast.update(toastId, {
                            type: toast.TYPE.ERROR,
                            render: t("Sources&Destinations.Sources.UploadFile.failMsg", { filename: selected.fileName }),
                            containerId: "uploadFileContainer",
                        });
                    }
                })
                .catch((err) => {
                    toast.update(toastId, {
                        type: toast.TYPE.ERROR,
                        render: `Failed to upload ${selected.fileName}: ${err.message}`,
                        containerId: "uploadFileContainer",
                    });
                });
        }
        handleShowUploadFile();
    };

    return (
        <>
            {!props.inSource && (
                <div className={`${props.hideTitle ? "uUploadWrapper" : "upload-main-container"}`}>
                    <div className={`${props.hideTitle ? "uUploadFilesWrapper" : "source-creation-wrapper"}`}>
                        {!props.hideTitle && (
                            <div className="hero-wrapper">
                                <div className="hero-text-wrapper">
                                    <div className="displayInRow">
                                        <div className="hero-text">{t("Sources&Destinations.Sources.UploadFile.title")}</div>
                                        <HelpInfo className="hero-text-icon" title={t("CommonUI.Sources.allowedExtensions")} placement="right" />
                                    </div>
                                    <span className="heading2" style={{ fontWeight: "500" }}>
                                        {t("Sources&Destinations.Sources.UploadFile.description")}
                                    </span>
                                </div>
                                <div className="custom-close-btn" onClick={() => props.handleCloseUploadFile()}>
                                    <FontAwesomeIcon icon={faClose} />
                                </div>
                            </div>
                        )}

                        <div className="source-form-wrapper">
                            {isFilePicked && selectedFiles.length > 0 ? (
                                <div>
                                    <div className="uformBlockInfo uploadMargin-bottom">
                                        <div className="uformBlockInfoTitle">{t("Sources&Destinations.Sources.UploadFile.details")}</div>
                                        <div className="uformBlockInfoDesc">{t("Sources&Destinations.Sources.UploadFile.detailsDescription")}</div>
                                    </div>
                                    {selectedFiles.length === 1 && <>
                                        <Form.Group className="mb-3" controlId="assetName">
                                            <Form.Label>{t("Sources&Destinations.Sources.UploadFile.AssetName")}</Form.Label>
                                            <Form.Control type="text" placeholder={t("Sources&Destinations.Sources.UploadFile.VideoFilePlaceholder")}
                                                value={selectedFiles[0].metadata.name} name="name" onChange={onChange}
                                                isInvalid={!selectedFiles[0].metadata.name} />
                                            <Form.Control.Feedback type="invalid">{t("Sources&Destinations.Sources.UploadFile.AssetNamerequired")}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="assetName">
                                            <Form.Label>{t("Sources&Destinations.Sources.UploadFile.FileName")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t("Sources&Destinations.Sources.UploadFile.VideoFilePlaceholder")}
                                                value={selectedFiles[0].fileName}
                                                name="name"
                                                onChange={onChangeFilename}
                                                isInvalid={!UploadFileExtensionValidation(selectedFiles[0].fileName)}
                                            />
                                            <Form.Control.Feedback type="invalid">{t("Sources&Destinations.Sources.UploadFile.FileNameRequired")}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="assetdescription">
                                            <Form.Label>{t("Sources&Destinations.Sources.UploadFile.Description")} </Form.Label>
                                            <Form.Control type="text" placeholder={t("Sources&Destinations.Sources.UploadFile.Descriptionplaceholder")}
                                                value={selectedFiles[0].metadata.description} name="description" onChange={onChange} />

                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <TagForm data={selectedFiles[0].metadata} onChange={onOrgDataChange} hideApps={[APP_IDS.Engage, APP_IDS.Remote, APP_IDS.Spaces]} />
                                        </Form.Group>
                                        <div className="fieldColumnWrapper longGap">
                                            <div className="fieldColumnWrapper">
                                                <div className="uploadFileBlockHeader">{t("Sources&Destinations.Sources.UploadFile.File")}</div>
                                                <input className="uInput" value={selectedFiles[0].file.name} disabled />
                                            </div>
                                            <div className="fieldColumnWrapper">
                                                <div className="uploadFileBlockHeader">{t("Sources&Destinations.Sources.UploadFile.Type")}</div>
                                                <input className="uInput" value={selectedFiles[0].file.type} disabled />
                                            </div>
                                            <div className="fieldColumnWrapper">
                                                <div className="uploadFileBlockHeader">{t("Sources&Destinations.Sources.UploadFile.Size")}</div>
                                                <input className="uInput" value={Math.round(selectedFiles[0].file.size / 1024) + " KB"} disabled />
                                            </div>
                                        </div>
                                    </>}
                                    {selectedFiles.length > 1 &&
                                        <div className="fieldColumnWrapper longGap">
                                            <div className="fieldColumnWrapper">
                                                <div className="uploadFileBlockHeader">{t("Sources&Destinations.Sources.UploadFile.Files")}</div>
                                                {selectedFiles.map(f => <li key={`file-list-${f.metadata.id.value}`}>{f.file.name} {`(${f.file.type})`}</li>)}
                                            </div>
                                            <div className="fieldColumnWrapper">
                                                <div className="uploadFileBlockHeader">{t("Sources&Destinations.Sources.UploadFile.Size")}</div>
                                                <div className="uInput">{Math.round(selectedFiles.reduce((acc, cur) => { return acc + cur.file.size }, 0) / 1024) + " KB"}</div>
                                            </div>
                                        </div>

                                    }
                                    {/* <div className="uploadFileSpace">
                                        <AppPermissionForm data={selectedFiles[0].metadata} onChange={onOrgDataChange} hideApps={[APP_IDS.Engage, APP_IDS.Remote, APP_IDS.Spaces]} />
                                    </div> */}
                                </div>
                            ) : (
                                <div className="upload-files-area" {...getRootProps()} style={{ cursor: "pointer" }}>
                                    {isDragActive && <div className="fileDraggingOverlay">{t("Sources&Destinations.Sources.UploadFile.DropFileHere")}</div>}
                                    <div>{!props.hideTitle && <div className="text-heading">{t("Sources&Destinations.Sources.UploadFile.Addfile")}</div>}</div>
                                    <div className="upload-files">
                                        <FontAwesomeIcon icon={faCloudArrowUp} />
                                        <div>{t("Sources&Destinations.Sources.UploadFile.title")}</div>
                                        <span>{t("Sources&Destinations.Sources.UploadFile.DragandDrop")}</span>
                                        <span style={{ color: "#1F2747" }}>
                                            {t("Sources&Destinations.Sources.UploadFile.or")}
                                            <label className="custom-file-upload" style={{ marginLeft: "4px" }}>
                                                {t("Sources&Destinations.Sources.UploadFile.BrowseManually")}
                                            </label>
                                        </span>
                                    </div>
                                    <input type="file" {...getInputProps()} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="button-wrapper-upload">
                        <Button variant="primary" onClick={handleSubmission} disabled={!(isFilePicked && UploadFileExtensionValidation(selectedFiles[0].fileName))}>
                            {t("Sources&Destinations.Sources.UploadFile.title")}
                        </Button>
                        <Button variant="secondary" onClick={() => props.handleCloseUploadFile()}>
                            {t("Sources&Destinations.Sources.UploadFile.Cancel")}
                        </Button>
                    </div>
                </div>
            )}
            {props.inSource && (
                <div className={`${props.hideTitle ? "uUploadWrapper" : "upload-main-container-source"}`}>
                    <div className={`${props.hideTitle ? "uUploadFilesWrapper" : isFilePicked && selectedFiles.length > 0 ? "source-creation-wrapper-upload" : "source-creation-wrapper"}`}>
                        <div className="source-form-wrapper">
                            {isFilePicked && selectedFiles.length > 0 ? (
                                <div>
                                    {selectedFiles.length === 1 && <Form.Group className="mb-3" controlId="assetName">
                                        <Form.Label>{t("Sources&Destinations.Sources.UploadFile.FileName")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t("Sources&Destinations.Sources.UploadFile.VideoFilePlaceholder")}
                                            value={selectedFiles[0].fileName}
                                            name="name"
                                            onChange={onChangeFilename}
                                            isInvalid={!UploadFileExtensionValidation(selectedFiles[0].fileName)}
                                        />
                                        <Form.Control.Feedback type="invalid">{t("Sources&Destinations.Sources.UploadFile.FileNameRequired")}</Form.Control.Feedback>
                                    </Form.Group>}
                                    {selectedFiles.length > 1 && <Form.Group className="mb-3" controlId="assetName">
                                        <Form.Label>{t("Sources&Destinations.Sources.UploadFile.Files")}</Form.Label>
                                        {selectedFiles.map(f => <li key={`file-list-${f.metadata.id.value}`}>{f.file.name} {`(${f.file.type})`}</li>)}
                                        <Form.Control.Feedback type="invalid">{t("Sources&Destinations.Sources.UploadFile.FileNameRequired")}</Form.Control.Feedback>
                                    </Form.Group>}
                                </div>
                            ) : (
                                <div className="upload-files-area" {...getRootProps()}>
                                    {isDragActive && <div className="fileDraggingOverlay">{t("Sources&Destinations.Sources.UploadFile.DropFileHere")}</div>}
                                    <div>{!props.hideTitle && <div className="text-heading">{t("Sources&Destinations.Sources.UploadFile.Addfile")}</div>}</div>
                                    <div className="upload-files">
                                        <FontAwesomeIcon icon={faCloudArrowUp} />
                                        <div>{t("Sources&Destinations.Sources.UploadFile.title")}</div>
                                        <span>{t("Sources&Destinations.Sources.UploadFile.DragandDrop")}</span>
                                        <span style={{ color: "#1F2747" }}>
                                            {t("Sources&Destinations.Sources.UploadFile.or")}
                                            <label className="custom-file-upload" style={{ marginLeft: "4px" }}>
                                                {t("Sources&Destinations.Sources.UploadFile.BrowseManually")}
                                            </label>
                                        </span>
                                    </div>
                                    <input type="file" {...getInputProps()} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="button-wrapper-upload-source">
                        <Button variant="primary" onClick={handleSubmission} disabled={!(isFilePicked && UploadFileExtensionValidation(selectedFiles[0].fileName))}>
                            {t("Sources&Destinations.Sources.UploadFile.title")}
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={(e) => {
                                handleShowUploadFile();
                                e.preventDefault();
                            }}
                        >
                            {t("Sources&Destinations.Sources.UploadFile.Cancel")}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}
