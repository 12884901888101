"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_LIST_LED_ACTIONS = exports.REQUEST_UPDATE_LED_ACTION = exports.REQUEST_REMOVE_LED_ACTION = exports.REQUEST_LIST_LED_ACTIONS = exports.REQUEST_CREATE_LED_ACTION = exports.NOTIFY_LED_ACTION_UPDATED = exports.NOTIFY_LED_ACTION_REMOVED = exports.NOTIFY_LED_ACTION_CREATED = void 0;
var _messageModel = require("../../messageModel");
var LED_ACTIONS = 6600;
var REQUEST_CREATE_LED_ACTION = exports.REQUEST_CREATE_LED_ACTION = new _messageModel.RequestMessageDefinition(LED_ACTIONS, 0);
var REQUEST_REMOVE_LED_ACTION = exports.REQUEST_REMOVE_LED_ACTION = new _messageModel.RequestMessageDefinition(LED_ACTIONS, 1);
var REQUEST_UPDATE_LED_ACTION = exports.REQUEST_UPDATE_LED_ACTION = new _messageModel.RequestMessageDefinition(LED_ACTIONS, 2);
var REQUEST_LIST_LED_ACTIONS = exports.REQUEST_LIST_LED_ACTIONS = new _messageModel.RequestMessageDefinition(LED_ACTIONS, 3);
var NOTIFY_LED_ACTION_CREATED = exports.NOTIFY_LED_ACTION_CREATED = new _messageModel.RespondMessageDefinition(LED_ACTIONS, 0);
var NOTIFY_LED_ACTION_REMOVED = exports.NOTIFY_LED_ACTION_REMOVED = new _messageModel.RespondMessageDefinition(LED_ACTIONS, 1);
var NOTIFY_LED_ACTION_UPDATED = exports.NOTIFY_LED_ACTION_UPDATED = new _messageModel.RespondMessageDefinition(LED_ACTIONS, 2);
var RESPONSE_LIST_LED_ACTIONS = exports.RESPONSE_LIST_LED_ACTIONS = new _messageModel.RespondMessageDefinition(LED_ACTIONS, 3);