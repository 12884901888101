"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPOND_UPDATE_PASSPORT_WITH_OAUTH = exports.RESPOND_UPDATE_PASSPORT = exports.RESPOND_REMOVE_PASSPORT = exports.RESPOND_LIST_PASSPORTS = exports.RESPOND_LIST_DATA = exports.RESPOND_LIST_COLUMNS = exports.RESPOND_ADD_PASSPORT = exports.REQUEST_UPDATE_PASSPORT_WITH_OAUTH = exports.REQUEST_UPDATE_PASSPORT = exports.REQUEST_REMOVE_PASSPORT = exports.REQUEST_LIST_PASSPORTS = exports.REQUEST_LIST_DATA = exports.REQUEST_LIST_COLUMNS = exports.REQUEST_ADD_PASSPORT = exports.NOTIFY_PASSPORT_UPDATE = void 0;
var _messageModel = require("../../messageModel");
var SERVICENOW_OFFSET = 6400;
var SERVICENOW_DATA = 6401;
var REQUEST_LIST_PASSPORTS = exports.REQUEST_LIST_PASSPORTS = new _messageModel.RequestMessageDefinition(SERVICENOW_OFFSET, 0);
var REQUEST_ADD_PASSPORT = exports.REQUEST_ADD_PASSPORT = new _messageModel.RequestMessageDefinition(SERVICENOW_OFFSET, 1);
var REQUEST_UPDATE_PASSPORT = exports.REQUEST_UPDATE_PASSPORT = new _messageModel.RequestMessageDefinition(SERVICENOW_OFFSET, 2);
var REQUEST_UPDATE_PASSPORT_WITH_OAUTH = exports.REQUEST_UPDATE_PASSPORT_WITH_OAUTH = new _messageModel.RequestMessageDefinition(SERVICENOW_OFFSET, 3);
var REQUEST_REMOVE_PASSPORT = exports.REQUEST_REMOVE_PASSPORT = new _messageModel.RequestMessageDefinition(SERVICENOW_OFFSET, 4);
var RESPOND_LIST_PASSPORTS = exports.RESPOND_LIST_PASSPORTS = new _messageModel.RespondMessageDefinition(SERVICENOW_OFFSET, 0);
var RESPOND_ADD_PASSPORT = exports.RESPOND_ADD_PASSPORT = new _messageModel.RespondMessageDefinition(SERVICENOW_OFFSET, 1);
var RESPOND_UPDATE_PASSPORT = exports.RESPOND_UPDATE_PASSPORT = new _messageModel.RespondMessageDefinition(SERVICENOW_OFFSET, 2);
var RESPOND_UPDATE_PASSPORT_WITH_OAUTH = exports.RESPOND_UPDATE_PASSPORT_WITH_OAUTH = new _messageModel.RespondMessageDefinition(SERVICENOW_OFFSET, 3);
var RESPOND_REMOVE_PASSPORT = exports.RESPOND_REMOVE_PASSPORT = new _messageModel.RespondMessageDefinition(SERVICENOW_OFFSET, 4);
var NOTIFY_PASSPORT_UPDATE = exports.NOTIFY_PASSPORT_UPDATE = new _messageModel.RespondMessageDefinition(SERVICENOW_OFFSET, 5);
var REQUEST_LIST_DATA = exports.REQUEST_LIST_DATA = new _messageModel.RequestMessageDefinition(SERVICENOW_DATA, 0);
var RESPOND_LIST_DATA = exports.RESPOND_LIST_DATA = new _messageModel.RespondMessageDefinition(SERVICENOW_DATA, 0);
var REQUEST_LIST_COLUMNS = exports.REQUEST_LIST_COLUMNS = new _messageModel.RequestMessageDefinition(SERVICENOW_DATA, 1);
var RESPOND_LIST_COLUMNS = exports.RESPOND_LIST_COLUMNS = new _messageModel.RespondMessageDefinition(SERVICENOW_DATA, 1);