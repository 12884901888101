"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VIDEO_ENGINES = void 0;
var _common = require("./common");
var VIDEO_ENGINES = exports.VIDEO_ENGINES = [{
  id: {
    value: _common.STANDALONE_SYSTEMID
  },
  name: "Demo system",
  description: "",
  activation: "ACTIVE",
  gpus: [{
    id: {
      videoEngineID: {
        value: _common.STANDALONE_SYSTEMID
      },
      gpuSlotID: {
        value: "0000:01:00.0"
      }
    },
    gpuData: {
      id: {
        value: "0000:01:00.0"
      },
      enabled: true,
      driver: "nvidia",
      model: "NVIDIA GeForce GTX 1050",
      type: "NVIDIA"
    },
    latency: -0.002
  }]
}];