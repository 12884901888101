import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import UFormBody from "./UFormBody/UFormBody";
import UFormFooter from "./UFormFooter/UFormFooter";
import UFormHeader from "./UFormHeader/UFormHeader";
import UFormTrigger from "./UFormTrigger/UFormTrigger";
import { IUForm } from "./uFormUtils";

import "./UForm.scss";
import UFormConfirmationDialog from "./UFormConfirmationDialog/UFormConfirmationDialog";
import { IUFormDragAndDrop, useUFormContext } from "./Context/UFormContext";

export interface IProps {
    form: IUForm;
    onSaveForm?(): void;
    openForm?: boolean;
    onCancel?: Function;
}

export default function UForm({ form, onSaveForm, openForm, onCancel }: IProps) {
    const [show, setShow] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const {
        state: {
            formConditions: { equivalenceConfirmed },
        },
        onAffirm,
        onTerminate,
        onClick,
    } = useUFormContext();

    const dragAndDrop: IUFormDragAndDrop = useUFormContext().state.dragAndDrop;
    const onDragAndDrop = useUFormContext().onDragAndDrop;

    const handleOpenForm = () => {
        setShow(true);
    };

    const onHide = () => {
        if (!equivalenceConfirmed) setShowConfirmationDialog(true);
        else {
            handleCloseForm();
        }
    };

    const onDialogClose = () => {
        if (!equivalenceConfirmed) setShowConfirmationDialog(false);
    };

    const handleCloseForm = () => {
        onTerminate();
        setShow(false);
    };

    const handleSaveForm = () => {
        onAffirm();
        if (onSaveForm) onSaveForm();
        setShow(false);
    };

    useEffect(() => {
        if (openForm) setShow(openForm);
    }, [openForm]);

    return form.editForm ? (
        <>
            <div className="uFormCentered">
                <div className="uFormEditWrapper">
                    <div className="uForm uFormEdit">
                        <UFormHeader header={form.header} onClose={handleCloseForm} />
                        <UFormBody body={form.body} />
                    </div>
                </div>
            </div>
            <UFormFooter footer={form.footer} onCancel={handleCloseForm} onConfirm={handleSaveForm} />

            <UFormConfirmationDialog
                onConfirm={(e) => handleCloseForm()}
                trigger={{ value: showConfirmationDialog, onClose: onDialogClose }}
            />
        </>
    ) : (
        <>
            {!form.trigger.hideTrigger ? <UFormTrigger trigger={form.trigger} onClick={handleOpenForm} /> : null}
            <Offcanvas
                show={form.trigger.hideTrigger ? true : show}
                onHide={onHide}
                placement="end"
                onClick={(e) => onClick()}
            >
                <Offcanvas.Body
                    onMouseLeave={(e) => {
                        onDragAndDrop({ ...dragAndDrop, target: null });
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                    onKeyUp={(e) => e.stopPropagation()}
                    onKeyPress={(e) => e.stopPropagation()}
                >
                    <div className="uForm" id="uForm">
                        <UFormHeader header={form.header} onClose={handleCloseForm} />
                        <UFormBody body={form.body} />
                    </div>
                    <UFormFooter footer={form.footer} onCancel={handleCloseForm} onConfirm={handleSaveForm} />
                </Offcanvas.Body>
            </Offcanvas>
            <UFormConfirmationDialog
                onConfirm={(e) => handleCloseForm()}
                trigger={{ value: showConfirmationDialog, onClose: onDialogClose }}
            />
        </>
    );
}
